import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AssessmentService } from '../../services/assessment.service';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Location } from '@angular/common';
import { SharedService } from '../../services/shared.service';
import { PrivilegeService } from '../../services/privilage.service';
import { Privilege } from '../../model/Privillage';

@Component({
  selector: 'app-cardiology-detail',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './cardiology-detail.component.html',
  styleUrl: './cardiology-detail.component.css'
})
export class CardiologyDetailComponent {

  id: any;
  athleteReport: any;
  athleteId: any;
  selectdata: any;
  detail: any;
  role: any;
  userId: any;
  privilege: Privilege;
  IsAdmin: boolean = false;
  isScienceStaff: boolean = false;
  coa800mRun: any;
  coaYoYoTest: any;
  coaRAST: any;
  coaRAST2: any;
  coaRAST3: any;
  coaRAST4: any;
  coaRAST5: any;
  coaRAST6: any;
  coaFatigueIndex: any;
  athleteFullName: any;
  date: any;
  assessmentDate: any;

  constructor(private router: Router,
    private route: ActivatedRoute, private sharedService: SharedService, private priService: PrivilegeService,
    private assessmentService: AssessmentService, private location: Location) {
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
      this.athleteId = params['athleteId'];
      console.log(this.id, "ID");
    });
    this.privilege = this.priService.getPrivileges();
    this.IsAdmin = this.privilege.isAdmin;
    this.isScienceStaff=this.privilege.isScienceStaff
  }

  activities = [
    { name: 'Sports Activity', percentage: 82 },
    { name: 'Technique Analysis', percentage: 42 },
    { name: 'Performance Analysis', percentage: 100 }
  ];

  // Dynamically return the color class based on percentage
  getProgressColor(percentage: number): string {
    if (percentage >= 75) {
      return 'bg-green';
    } else if (percentage >= 40) {
      return 'bg-yellow';
    } else {
      return 'bg-red';
    }
  }

  ngOnInit(): void {
    this.sharedService.detail$.subscribe((data: any) => {
      this.detail = data;
      this.role = this.detail.user.usrRole;
      this.userId = this.detail.user.usrId;
    });

    const getAthleteReport = (this.IsAdmin || this.isScienceStaff ) 
      ? this.assessmentService.getCardioByTest(this.id)
      : this.assessmentService.getCardioByTestAssessor(this.id);

    getAthleteReport.subscribe((response: any) => {
      this.athleteReport = response;
      this.selectdata = response.filter((assessment: any) =>
        (this.IsAdmin || this.isScienceStaff )  ? assessment.athlete == this.athleteId : assessment.athlete == this.athleteId && assessment.assessorId == this.userId
      );

      this.selectdata.forEach((assessment: any) => {
        switch (assessment.testColumnName) {
          case "coa800mRun":
            this.coa800mRun = assessment.matchedColumnData;
            break;
          case "coaYoYoTest":
            this.coaYoYoTest = assessment.matchedColumnData;
            break;
          case "coaRAST":
            this.coaRAST = assessment.matchedColumnData;
            break;
          case "coaRAST2":
            this.coaRAST2 = assessment.matchedColumnData;
            break;
          case "coaRAST3":
            this.coaRAST3 = assessment.matchedColumnData;
            break;
          case "coaRAST4":
            this.coaRAST4 = assessment.matchedColumnData;
            break;
          case "coaRAST5":
            this.coaRAST5 = assessment.matchedColumnData;
            break;
          case "coaRAST6":
            this.coaRAST6 = assessment.matchedColumnData;
            break;
          case "coaFatigueIndex":
            this.coaFatigueIndex = assessment.matchedColumnData;
            break;
        }
      });

      // Assign common athlete information
      if (this.selectdata.length > 0) {
        const firstData = this.selectdata[0];
        this.athleteFullName = firstData.athleteFullName;
        this.date = firstData.date;
        this.assessmentDate = firstData.assessmentDate;
        this.athleteId = firstData.athlete;
      }
    });
  }





  downloadPDF(): void {
    const DATA = document.getElementById('pdf-content');
    if (DATA) {
      html2canvas(DATA).then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgProps = pdf.getImageProperties(imgData);
        const imgWidth = pdfWidth * 0.8; // 80% of the page width
        const imgHeight = (imgProps.height * imgWidth) / imgProps.width;
        const margin = (pdfWidth - imgWidth) / 2;
        const topMargin = 20; // Add top margin

        pdf.addImage(imgData, 'PNG', margin, topMargin, imgWidth, imgHeight);
        pdf.save('Cognitive-details.pdf');
      });
    }
  }

  onBack(): void {
    this.location.back();
  }

}
