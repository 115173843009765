import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router, RouterLink, RouterOutlet } from '@angular/router';
import { AssessmentService } from '../../../services/assessment.service';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NotificationService } from '../../../services/Notifications.service';
import { NgToastModule } from 'ng-angular-popup';
import { UserRegisterService } from '../../../services/user-register.service';
import { SharedService } from '../../../services/shared.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-physicalfitness-editform',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterOutlet, RouterLink, HttpClientModule, NgToastModule],
  templateUrl: './physicalfitness-editform.component.html',
  styleUrls: ['./physicalfitness-editform.component.css'] // Fixed typo from 'styleUrl' to 'styleUrls'
})
export class PhysicalfitnessEditformComponent {
  formSubmitted: boolean = false;
  isReadOnly: boolean = false;
  selectedAthleteId: any;
  athletes: any[] = [];
  assessment: any = {
    pfaDate: null,
    pfaAssessor: '',
    pfaAthlete: null,
    pfaSpeed: null,
    pfaAgility: null,
    pfaEndurance: null,
    pfaStrength: null,
    pfaFlexibility: null,
    pfaReactionTime: null,
    pfaInterpretation: '',
    pfaRecommendations: '',
    pfaFollowUpDate: null,
    pfaAssessorName: '',
  };
  UserId: any;
  name: any;
  id: any;
  selectedFiles: FileList | null = null;
  ImageUrl: any;
  Status:any;
  showImage: boolean = false;
  Response: any;
  detail: any;
  testNames: any;
  teamId: any;
  athleteId:any
  
  constructor(
    private assessmentService: AssessmentService,
    private userRegisterService: UserRegisterService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private sharedService: SharedService,
    private router: Router,
    private notify: NotificationService,private sanitizer: DomSanitizer,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      // Safe to use window, document, etc.
      this.isReadOnly = window.localStorage.getItem('athelete') !== null;
    }

    this.sharedService.detail$.subscribe((data: any) => {
      this.UserId = data.user.usrId;
      this.name = data.user.usrFullName;
      
    });

    this.route.queryParams.subscribe(params => {
      this.athleteId = params['athleteId'];
      this.teamId = params['teamId'];
  
      if (this.athleteId) {
        this.UserId = this.athleteId; 
        this.assessment.pfaAthlete = this.athleteId; // Set the athlete ID here
        this.getProfileImage(); 
      } else {
        console.log('Athlete ID not found in query params');
      }
  
      console.log(this.athleteId, "Athlete ID");
      console.log(this.teamId, "Team ID");
  
      this.FetchAthelete();
    });
     this.getTests();
    
  }
  getProfileImage() {
    this.ImageUrl = null;
    this.showImage = false;
    this.assessmentService.getImage(this.athleteId).subscribe(
      (imageBlob) => {
        if (imageBlob) {
          const objectURL = URL.createObjectURL(imageBlob);
          this.ImageUrl = this.sanitizer.bypassSecurityTrustUrl(objectURL);
          this.showImage = true;
          console.log(this.ImageUrl, "ImageUrl");
        } else {
          this.notify.failed("Invalid athlete number.");
        }
      },
    );
  }
  onPlayerChange(event: any) {
    console.log(event.target.value, "athlete");
  }

  onSubmit(): void {
    this.assessment.pfaAssessor = this.UserId;
    if (this.selectedAthleteId) {
      this.assessment.pfaAthlete = this.selectedAthleteId;
    }

     {
      this.assessmentService.addPhysicalFitnessDetail([this.assessment]).subscribe(
        response => {
          console.log('Assessment submitted', response);
          this.formSubmitted = true;
          this.notify.success("Data updated successfully");
          setTimeout(() => {
            this.router.navigate(['physicalfitnesslist']);
          }, 1000);
        },
        error => {
          console.error('Error submitting assessment', error);
          this.notify.failed("Error submitting assessment");
        }
      );
    } 
  }

  isFormValid(): boolean {
    return Object.keys(this.assessment).every(key => {
      if (['pfaAssessorName', 'pfaAthleteName'].includes(key)) return true;
      return this.assessment[key] !== '' && this.assessment[key] !== null;
    });
  }

  fetchAthletes(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement) {
      const query = inputElement.value;
      this.assessmentService.getAthlete(query).subscribe(
        data => {
          this.athletes = data;
        },
        error => {
          console.error('Error fetching athletes', error);
        }
      );
    }
  }

  onAthleteSelected(event: Event): void {
    const inputElement = event.target as HTMLInputElement | null;
    if (inputElement) {
      const selectedAthlete = this.athletes.find(athlete => athlete.usrFullName === inputElement.value);
      if (selectedAthlete) {
        this.selectedAthleteId = selectedAthlete.usrId;
      }
    }
  }

  FetchAthelete() {
    if (this.id) {
      this.assessmentService.fetchAtletefitness(this.id).subscribe(
        (response: any) => {
          if (response && response.length > 0) {
            const data = response[0];
            this.assessment = {
              ...this.assessment,
              pfaId: data.pfaId,
              pfaDate: data.pfaDate ? data.pfaDate.split('T')[0] : '',
              pfaAssessor: data.pfaAssessor,
              pfaAthlete: data.pfaAthlete,
              pfaSpeed: data.pfaSpeed,
              pfaAgility: data.pfaAgility,
              pfaEndurance: data.pfaEndurance,
              pfaStrength: data.pfaStrength,
              pfaFlexibility: data.pfaFlexibility,
              pfaReactionTime: data.pfaReactionTime,
              pfaInterpretation: data.pfaInterpretation,
              pfaRecommendations: data.pfaRecommendations,
              pfaFollowUpDate: data.pfaFollowUpDate ? data.pfaFollowUpDate.split('T')[0] : '',
              pfaAssessorName: data.assessorName,
              pfaAthleteName: data.athleteName,
              pfaCreatedOn: new Date(data.pfaCreatedOn),
              pfaUpdatedOn: new Date(data.pfaUpdatedOn)
            };
          }
        },
        (error: any) => {
          console.error('Error fetching assessment data', error);
        }
      );
    }
  }

  onFileSelected(event: any) {
    this.selectedFiles = event.target.files;
  }



  getTests() {
    this.userRegisterService.getTests(this.teamId).subscribe(response => {
      this.Response = response;

     this.testNames = response.map((item: any) => item.testName);

      console.log(this.Response,"Response");
    });
  }


}