import { ChangeDetectorRef, Component, NgZone } from '@angular/core';
import { AssessmentService } from '../../../services/assessment.service';
import { NotificationService } from '../../../services/Notifications.service';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Router, RouterLink, ActivatedRoute} from '@angular/router';
import { NgToastModule } from 'ng-angular-popup';

@Component({
  selector: 'app-add-organization',
  standalone: true,
  imports: [CommonModule, FormsModule,NgToastModule,RouterLink],
  templateUrl: './add-organization.component.html',
  styleUrl: './add-organization.component.css'
})
export class AddOrganizationComponent {
  selectedFile: File | undefined ;
  orgName: string = '';
  selectedOrgId: string | null = null;
  orgLogo:any;
  isEditMode: boolean = false;

  constructor(

    private assessmentService: AssessmentService,
    private notify: NotificationService,
    private router:Router,
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,private ngZone: NgZone
  ) {
  }

  ngOnInit() {
    // Check if orgId and orgName are present in query parameters
    this.route.queryParams.subscribe(params => {
      if (params['id'] && params['orgName']) {
        this.isEditMode = true;
        this.selectedOrgId = params['id']; // Get the organization ID
        this.orgName = params['orgName'];  // Prefill the organization name
      }
    });
  }

  onSubmit() {
    if (this.orgName && (this.isEditMode || this.selectedFile)) {
        const formData = new FormData();
        if (this.selectedFile) {
            formData.append('files', this.selectedFile);
        } else{
            formData.append('files', '');
        }
        if (this.isEditMode && this.selectedOrgId) {
            formData.append('orgId', this.selectedOrgId);
          } else {
            formData.append('orgId', '0');
          }
        formData.append('orgName', this.orgName); 

        this.assessmentService.uploadOrganizationLogo(formData).subscribe(
            (response) => {
                if (response.statuscode === 200) {
                    this.notify.success(response.message);
                    setTimeout(() => {
                        this.router.navigate(['/organization']);
                    }, 1000);
                } else {
                    this.notify.failed(response.message);
                 
                }
            },
            (error) => {
                console.error('Error creating organization:', error);
            }
        );
    } else {
        alert('Please enter an organization name and select a file.');
    }
}


onFileSelected(event: any) {
    const file: File = event.target.files[0];
    if (file) {
        // Validate file type (only allow jpg and png)
        const fileType = file.type;
        if (fileType === 'image/jpg' || fileType === 'image/png' || fileType === 'image/jpeg') {
            this.selectedFile = file; // Store the selected file
            console.log('Selected file:', this.selectedFile);
        } else {
          this.ngZone.run(() => {

            this.notify.failed('Only jpg and png files are allowed.');
          
            this.cdRef.detectChanges();
           
          });
         
        }
    }
}


}
