import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Router, RouterLink, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms'; // Import FormsModule
import { SharedService } from '../../services/shared.service';
import { AssessmentService } from '../../services/assessment.service';
import { NotificationService } from '../../services/Notifications.service';
import { DomSanitizer } from '@angular/platform-browser';
import { NgToastModule } from 'ng-angular-popup';
 
@Component({
  selector: 'app-cognative-form',
  standalone: true,
  imports: [CommonModule, RouterModule, RouterLink, FormsModule,NgToastModule], // Add FormsModule here
  templateUrl: './cognative-form.component.html',
  styleUrls: ['./cognative-form.component.css']
})
export class CognativeFormComponent {
  formSubmitted: boolean = false;
  assessment: any = {
    cnaId:0,
    cnaAthlete: "",
    cnaRulerDrop: "",
    cnaBlazepods: "",
    cnaBlazepodsTime: "",
    cnaWallToss: "",
    cnaPlateTaping: "",
    cnaAssessor:'',
    cnaCreatedBy: '',
    //cnaCreatedOn: '',
    // cnaUpdatedBy: 0,
    // cnaUpdatedOn: 0
  };

  detail: any;
  UserId: any;
  ImageUrl: any;
  showImage: boolean = false;
 
  constructor(private router: Router,
    private sharedService: SharedService,
    private assessmentService: AssessmentService,
    private notify: NotificationService,private sanitizer: DomSanitizer) {}
 
  ngOnInit() {
    this.sharedService.detail$.subscribe((data: any) => {
      this.detail = data;
      this.UserId = this.detail.user.usrId;
    });
  }
  getProfileImage() {
    this.ImageUrl = null;
    this.showImage = false;
  
    this.assessmentService.getImage(this.UserId).subscribe(
      (imageBlob) => {
        if (imageBlob) {
          const objectURL = URL.createObjectURL(imageBlob);
          this.ImageUrl = this.sanitizer.bypassSecurityTrustUrl(objectURL);
          this.showImage = true; 
          console.log(this.ImageUrl, "ImageUrl");
        } else {
          this.notify.failed("invalid athlete number.");
        }
      },
      (error) => {
        this.ImageUrl = null;
        this.showImage = false;
        this.notify.failed("invalid athlete number");
        console.error('Error fetching image', error);
      }
    );
  }
  onSubmit(): void {
    this.assessment.cnaAssessor = this.UserId;
    this.assessment.cnaCreatedBy = this.UserId;
    this.assessmentService.addcognitive([this.assessment]).subscribe(response => {
      console.log('Assessment submitted', response);
      this.formSubmitted = true;
      this.notify.success("Form submitted successfully");
      setTimeout(() => {
        this.router.navigate(['cognitivelist']);
      }, 1000);
    }, error => {
      console.log('Error submitting assessment', error);
    });
  }
 
  isFormValid(): boolean {
    return Object.keys(this.assessment).every(key => {
      return this.assessment[key] !== '' && this.assessment[key] !== null;
    });
  }
}