<div class="body-wrapper">
    <div class="team-single">
        <div class="btn-class mb-3">
            <button type="button" class="accept" (click)="downloadPDF()">Download</button>
        </div>
        <!-- <h4 class="font-size38 sm-font-size32 xs-font-size30">{{player[0]?.athleteName}}</h4> -->
        <div class="contact-info-section margin-40px-tb" *ngFor="let athlete of athleteReport">

            <div id="pdf-content">
                <div class="row">
                    <div class="col-lg-4 col-md-5 xs-margin-30px-bottom">
                        <div class="col-lg-12">
                            <div class="card mb-4">
                                <div class="content-header">
                                    <h2 class="sub-title">Biomechanics Analysis</h2>
                                </div>
                                <div class="content-body">
                                    <div class="mb-4">
                                        <div class="clearfix">
                                            <div class="d-flex justify-content-between align-items-center mb-3">
                                                <p class="small-text">Sports Activity</p>
                                                <strong>82%</strong>
                                            </div>

                                        </div>
                                        <div class="progress progress-xs">
                                            <div class="progress-bar bg-green" role="progressbar" style="width: 82%"
                                                aria-valuenow="42" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div class="mb-4">
                                        <div class="clearfix">
                                            <div class="d-flex justify-content-between align-items-center mb-3">
                                                <p class="small-text">Technique Analysis</p>
                                                <strong>42%</strong>
                                            </div>

                                        </div>
                                        <div class="progress progress-xs">
                                            <div class="progress-bar bg-yellow" role="progressbar" style="width: 49%"
                                                aria-valuenow="42" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div class="mb-4">
                                        <div class="clearfix">
                                            <div class="d-flex justify-content-between align-items-center mb-3">
                                                <p class="small-text">Performance Analysis</p>
                                                <strong>12%</strong>
                                            </div>

                                        </div>
                                        <div class="progress progress-xs">
                                            <div class="progress-bar bg-red" role="progressbar" style="width: 12%"
                                                aria-valuenow="42" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="bg-light-blue">
                                <h4 class="side-Title">{{athlete.assessorName}}</h4>
                                <p class="side-content">Assessor</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-8 card col-md-7">
                        <div class="card-body">
                            <div class="team-single-text padding-50px-left sm-no-padding-left">
                                <div class="mobile-res d-flex justify-content-between mb-4">
                                    <div>
                                        <h4 class="Title">{{athlete.athleteName}}</h4>
                                        <a href="">{{athlete.usrEmail}}</a>
                                    </div>
                                    <div>
                                        <h4 class="sub-title">{{athlete.bmaCreatedOn | date: 'dd-MM-yyyy'}}</h4>
                                        <p>Assessment Date</p>
                                    </div>
                                </div>
                                <div class="contact-info-section margin-40px-tb">
                                    <ul class="list-style9 no-margin">
                                        <li>

                                            <div class="row">
                                                <div class="col-md-5 col-5">

                                                    <strong class="margin-10px-left ">ForceOutput:</strong>
                                                </div>
                                                <div class="col-md-7 col-7">
                                                    <p>{{athlete.bmaForceOutput}}</p>
                                                </div>
                                            </div>

                                        </li>
                                        <li>

                                            <div class="row">
                                                <div class="col-md-5 col-5">
                                                    <strong class="margin-10px-left ">Power Output:</strong>
                                                </div>
                                                <div class="col-md-7 col-7">
                                                    <p>{{athlete.bmaPowerOutput}}</p>
                                                </div>
                                            </div>

                                        </li>
                                        <li>

                                            <div class="row">
                                                <div class="col-md-5 col-5">
                                                    <strong class="margin-10px-left ">Symmetry:</strong>
                                                </div>
                                                <div class="col-md-7 col-7">
                                                    <p>{{athlete.bmaSymmetry}}</p>
                                                </div>
                                            </div>

                                        </li>
                                        <li>

                                            <div class="row">
                                                <div class="col-md-5 col-5">
                                                    <strong class="margin-10px-left ">Imbalance:</strong>
                                                </div>
                                                <div class="col-md-7 col-7">
                                                    <p>{{athlete.bmaImbalance}}</p>
                                                </div>
                                            </div>

                                        </li>
                                        <li>

                                            <div class="row">
                                                <div class="col-md-5 col-5">
                                                    <strong class="margin-10px-left ">Interpretation of
                                                        Results:</strong>
                                                </div>
                                                <div class="col-md-7 col-7">
                                                    <p>{{athlete.bmaInterpreationResults}}</p>
                                                </div>
                                            </div>

                                        </li>
                                        <li>
                                        <li>

                                            <div class="row">
                                                <div class="col-md-5 col-5">
                                                    <strong class="margin-10px-left ">Recommendations:</strong>
                                                </div>
                                                <div class="col-md-7 col-7">
                                                    <p>{{athlete.bmaRecommendations}}</p>
                                                </div>
                                            </div>

                                        </li>
                                        <li>
                                        <li>

                                            <div class="row">
                                                <div class="col-md-5 col-5">
                                                    <strong class="margin-10px-left ">Follow-up Date:</strong>
                                                </div>
                                                <div class="col-md-7 col-7">
                                                    <p>{{athlete.bmaFollowUpDate | date: 'dd-MM-yyyy'}}</p>
                                                </div>
                                            </div>

                                        </li>

                                    </ul>
                                </div>


                            </div>
                        </div>
                    </div>

                    <div class="col-md-12">

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>