import { Injectable } from '@angular/core';
import { User } from '../model/User';
import { PrivilegeAccess } from '../model/PrivlageAcess';
import { PrivilegeEnum } from '../model/PrivilageEnum';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  
  private user: User | null = null;
  private privileges: PrivilegeAccess[] = [];
  private ramPrivilege: PrivilegeEnum | undefined;

  constructor() { }

  setUser(user: User): void {
    this.user = user;
  }

  getUser(): User | null {
    return this.user;
  }

  clearUser(): void {
    this.user = null;
  }

  setUserPrivileges(privileges: PrivilegeAccess[]) {
    this.privileges = privileges;
    console.log(this.privileges,"privilage");
  }

  getUserPrivileges(): PrivilegeAccess[] {
    return this.privileges;
  }

  setRamPrivilege(privilege: PrivilegeEnum): void {
    this.ramPrivilege = privilege;
  }

  getRamPrivilege(): PrivilegeEnum | undefined {
    return this.ramPrivilege;
  }
}
