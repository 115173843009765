// PhysiologyDetailListComponent
import { AgGridAngular } from "ag-grid-angular";
import { ColDef } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { Component, NgZone } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ActivatedRoute, Router, RouterLink, RouterModule, RouterOutlet } from "@angular/router";
import { AssessmentService } from "../../../services/assessment.service";
import { PrivilegeService } from "../../../services/privilage.service";
import { Privilege } from "../../../model/Privillage";
import { UserService } from "../../../services/user.service";

// Row Data Interface
interface IRow {
  playername: string;
  Assessor: string;
  date: string;
  priority: string;
}
 
@Component({
  selector: 'app-physiology-detail-list',
  standalone: true,
  imports: [CommonModule, RouterModule, RouterLink, AgGridAngular],
  templateUrl: './physiology-detail-list.component.html',
  styleUrls: ['./physiology-detail-list.component.css'] // Corrected 'styleUrl' to 'styleUrls'
})
export class PhysiologyDetailListComponent {
  name: any;
  user: any;
  players: any;
  playerId: any;
  isPlayer: boolean = false;
  isAdmin: boolean = false; 
  privilege:Privilege;
  Isplayer: boolean;
  Admin: boolean;
  OrgAdmin: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private assessmentService: AssessmentService,
    private ngZone: NgZone,private priService:PrivilegeService,private userService:UserService
  ) {
    this.route.queryParams.subscribe(params => {
      this.playerId = params['id'] || history.state.playerId;
      console.log(this.playerId, "ID");
    })
    this.privilege = this.priService.getPrivileges();
    this.Isplayer = this.privilege.isPlayer;
    this.Admin = this.privilege.isAdmin;
    this.OrgAdmin = this.privilege.isOrgAdmin;
  }

  rowData: IRow[] = [];
  colDefs: ColDef[] = [];
  defaultColDef: ColDef = {
    flex: 1,
    sortable: true,
    filter: true,
  };

  ngOnInit() {
    this.user = this.userService.getUser();
   

    if (this.playerId) {
      this.assessmentService.getphysiologyByAthleteId(this.playerId).subscribe((res: any) => {
        console.log(res, 'playerid');
        this.players = res;
        this.name = res[0].athleteName;
        this.rowData = res.map((item: any) => ({
          id: `${item.plaId}`,
          playerId: `${item.plaAthlete}`,
          playername: `${item.athleteName}`,
          Assessor: `${item.assessorName}`,
          result: `${item.plaTestResults}`,
          Recommendation: `${item.plaRecommendations}`,
          HeartRateVariablity:`${item.plaHeartRateVariablity}`,
          date: new Date(item.plaDate).toLocaleDateString('en-US'),
          priority: this.getPriority(item)
        }));

        this.colDefs = this.getColumnDefinitions();
      }, error => {
        console.error('Error submitting assessment', error);
      });
    } else {
      console.error('Player ID not found');
    }
  }

  themeClass = "ag-theme-quartz";

  getPriority(item: any): string {
    if (item.pfaSpeed > 0 || item.pfaAgility > 0) {
      return 'High';
    } else {
      return 'Low';
    }
  }

  getColumnDefinitions(): ColDef[] {
    const columns: ColDef[] = [
      //{ field: "playername" }, 
      { field: "Assessor" },
      { field: "result" },
      {field:"HeartRateVariablity"},
      { field: "Recommendation" },
      { field: "date" },
      // { field: "priority",
      //   cellStyle: params => {
      //     if (params.value === 'Low') {
      //       return { color: 'red' };
      //     } else {
      //       return { color: 'green' };
      //     }
      //   }
      // },
      {
        headerName: 'Action',
        cellRenderer: (_params: any) => {
          const container = document.createElement('div');
          container.style.display = 'flex';
          container.style.justifyContent = 'space-around';
 
          const viewButton = document.createElement('button');
          viewButton.classList.add('btn');
          viewButton.innerHTML = '<i class="fa fa-eye"></i>';
          viewButton.addEventListener('click', () => {
         
              if (this.isPlayer) {
                this.ngZone.run(() => {
                this.router.navigate(['/physiologydetail']);
                })
              } else {
                this.ngZone.run(() => {
                this.router.navigate(['/physiologydetail'], {
                  queryParams: { id: _params.data.id },
                  state: { playerId: _params.data.id }
                });
              })
              }
          
          });
 
          const editButton = document.createElement('button');
          editButton.classList.add('btn');
          editButton.innerHTML = '<i class="fa fa-edit"></i>';
          editButton.addEventListener('click', () => {
            this.ngZone.run(() => {
              this.router.navigate(['/physiology'], {
                queryParams: { id: _params.data.id }
              });
            });
            // this.router.navigate(['/physiologyeditform'], { queryParams: { id: _params.data.id } });
          });
 
          container.appendChild(viewButton);
          if (!this.Isplayer && !this.OrgAdmin) {
          container.appendChild(editButton);
          }
 
          return container;
        },
        width: 120
      }
    ];

    if (this.isAdmin) {
      return columns;
    } else if (this.isPlayer) {
      return columns.filter(col => col.field !== 'playername');
    } else {
      return columns.filter(col => col.field !== 'Assessor');
    }
  }
}
