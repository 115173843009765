<div class="container" [ngClass]="{'secActive': formSubmitted}">
  <header>Physical Fitness</header><br>
  <form (ngSubmit)="onSubmit()" #assessmentForm="ngForm">
    <div class="form first">
      <div class="details personal">
       <div class="fields">
          <div class="input-field">
            <div *ngIf="showImage; else noImageTemplate">
              <img [src]="ImageUrl" alt="Athlete Profile Image" class="profile-image"/>
            </div>
            
            <!-- Template for when no image is available -->
            <ng-template #noImageTemplate>
              <p>No profile image available.</p>
            </ng-template>
            <label>Player ID</label>
            <input
              type="text"
              placeholder="Enter Player ID"
              required
              [(ngModel)]="assessment.pfaAthlete"
              name="playerName"
              list="athleteList"
              (change)="onAthleteSelected($event)"
              #playerName="ngModel"
              [ngClass]="{
                'invalid-field': assessmentForm.submitted && playerName.invalid,
                'has-value': assessment.pfaAthlete
              }"
            >
            <datalist id="athleteList">
              <option *ngFor="let athlete of athletes" [value]="athlete.usrFullName"></option>
            </datalist>
            <div *ngIf="assessmentForm.submitted && playerName.invalid" class="error-message">
              <!-- Player Name error message -->
            </div>
          </div>
        </div><br>
        <div class="fields">
          <div class="input-field">
            <label>Date of Assessment</label>
            <input
              type="date"
              placeholder="Enter date of assessment"
              required
              [(ngModel)]="assessment.pfaDate"
              name="pfaDate"
              #pfaDate="ngModel"
              [ngClass]="{
                'invalid-field': assessmentForm.submitted && pfaDate.invalid,
                'has-value': assessment.pfaDate
              }"
            >
            <div *ngIf="assessmentForm.submitted && pfaDate.invalid" class="error-message">
            </div>
          </div>
        </div>

        <div class="details">
          <span class="title">Test Results</span>
          <div class="fields">
            
            <div *ngFor="let testName of testNames" class="input-field">
              <!-- Speed Test -->
              <div *ngIf="testName === 'Speed Test'">
                <label>Speed Test</label>
                <input
                  type="number"
                  placeholder="Enter Speed Test Results"
                  required
                  [(ngModel)]="assessment.pfaSpeed"
                  name="pfaSpeed"
                  #pfaSpeed="ngModel"
                  [ngClass]="{
                    'invalid-field': assessmentForm.submitted && pfaSpeed.invalid,
                    'has-value': assessment.pfaSpeed
                  }"
                />
                <div *ngIf="assessmentForm.submitted && pfaSpeed.invalid" class="error-message">
                  <!-- Speed Test error message -->
                </div>
              </div>
        
              <!-- Agility Test -->
              <div *ngIf="testName === 'Agility Test'">
                <label>Agility Test</label>
                <input
                  type="number"
                  placeholder="Enter Agility Test Results"
                  required
                  [(ngModel)]="assessment.pfaAgility"
                  name="pfaAgility"
                  #pfaAgility="ngModel"
                  [ngClass]="{
                    'invalid-field': assessmentForm.submitted && pfaAgility.invalid,
                    'has-value': assessment.pfaAgility
                  }"
                />
                <div *ngIf="assessmentForm.submitted && pfaAgility.invalid" class="error-message">
                  <!-- Agility Test error message -->
                </div>
              </div>
        
              <!-- Endurance Test -->
              <div *ngIf="testName === 'Endurance Test'">
                <label>Endurance Test</label>
                <input
                  type="number"
                  placeholder="Enter Endurance Test Results"
                  required
                  [(ngModel)]="assessment.pfaEndurance"
                  name="pfaEndurance"
                  #pfaEndurance="ngModel"
                  [ngClass]="{
                    'invalid-field': assessmentForm.submitted && pfaEndurance.invalid,
                    'has-value': assessment.pfaEndurance
                  }"
                />
                <div *ngIf="assessmentForm.submitted && pfaEndurance.invalid" class="error-message">
                  <!-- Endurance Test error message -->
                </div>
              </div>
        
              <!-- Strength Test -->
              <div *ngIf="testName === 'Strength Test'">
                <label>Strength Test</label>
                <input
                  type="number"
                  placeholder="Enter Strength Test Results"
                  required
                  [(ngModel)]="assessment.pfaStrength"
                  name="pfaStrength"
                  #pfaStrength="ngModel"
                  [ngClass]="{
                    'invalid-field': assessmentForm.submitted && pfaStrength.invalid,
                    'has-value': assessment.pfaStrength
                  }"
                />
                <div *ngIf="assessmentForm.submitted && pfaStrength.invalid" class="error-message">
                  <!-- Strength Test error message -->
                </div>
              </div>
        
              <!-- Flexibility Test -->
              <div *ngIf="testName === 'Flexibility Test'">
                <label>Flexibility Test</label>
                <input
                  type="number"
                  placeholder="Enter Flexibility Test Results"
                  required
                  [(ngModel)]="assessment.pfaFlexibility"
                  name="pfaFlexibility"
                  #pfaFlexibility="ngModel"
                  [ngClass]="{
                    'invalid-field': assessmentForm.submitted && pfaFlexibility.invalid,
                    'has-value': assessment.pfaFlexibility
                  }"
                />
                <div *ngIf="assessmentForm.submitted && pfaFlexibility.invalid" class="error-message">
                  <!-- Flexibility Test error message -->
                </div>
              </div>
        
              <!-- Reaction Time Test -->
              <div *ngIf="testName === 'Reaction Time Test'">
                <label>Reaction Time Test</label>
                <input
                  type="number"
                  placeholder="Enter Reaction Time Test Results"
                  required
                  [(ngModel)]="assessment.pfaReactionTime"
                  name="pfaReactionTime"
                  #pfaReactionTime="ngModel"
                  [ngClass]="{
                    'invalid-field': assessmentForm.submitted && pfaReactionTime.invalid,
                    'has-value': assessment.pfaReactionTime
                  }"
                />
                <div *ngIf="assessmentForm.submitted && pfaReactionTime.invalid" class="error-message">
                  <!-- Reaction Time Test error message -->
                </div>
              </div>
            </div>
          </div>
        </div>  
        <br>
        <div class="fields">
          <div class="input-field">
            <label>Interpretation of Results</label>
            <textarea
              type="text"
              placeholder="Enter Interpretation of Results"
              required
              [(ngModel)]="assessment.pfaInterpretation"
              name="pfaInterpretation"
              #pfaInterpretation="ngModel"
              [ngClass]="{
                'invalid-field': assessmentForm.submitted && pfaInterpretation.invalid,
                'has-value': assessment.pfaInterpretation
              }"
            ></textarea>
            <div *ngIf="assessmentForm.submitted && pfaInterpretation.invalid" class="error-message">
              <!-- Interpretation of Results error message -->
            </div>
          </div>
          <div class="input-field">
            <label>Recommendations</label>
            <textarea
              type="text"
              placeholder="Enter Recommendations"
              required
              [(ngModel)]="assessment.pfaRecommendations"
              name="pfaRecommendations"
              #pfaRecommendations="ngModel"
              [ngClass]="{
                'invalid-field': assessmentForm.submitted && pfaRecommendations.invalid,
                'has-value': assessment.pfaRecommendations
              }"
            ></textarea>
            <div *ngIf="assessmentForm.submitted && pfaRecommendations.invalid" class="error-message">
              <!-- Recommendations error message -->
            </div>
          </div>
          <div class="input-field">
            <label>Follow-up Date</label>
            <input
              type="date"
              placeholder="Enter Follow-up Date"
              required
              [(ngModel)]="assessment.pfaFollowUpDate"
              name="pfaFollowUpDate"
              #pfaFollowUpDate="ngModel"
              [ngClass]="{
                'invalid-field': assessmentForm.submitted && pfaFollowUpDate.invalid,
                'has-value': assessment.pfaFollowUpDate
              }"
            >
            <div *ngIf="assessmentForm.submitted && pfaFollowUpDate.invalid" class="error-message">
              <!-- Follow-up Date error message -->
            </div>
          </div>
        </div>
        <div class="buttons">
          <button type="submit" class="submit">
            <span class="btnText">Submit</span>
            <i class="uil uil-navigator"></i>
          </button>
        </div>
      </div>
      
    </div>
  </form>
</div>
<div class="toaster">

<ng-toast />
  
</div>
