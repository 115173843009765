export interface Formula {
  formula: string;
  description: string;
  inputs: string[];
}

export const formulas: { [key: string]: Formula } = {

  "pfaHeight": {
    formula: "0.02*(x**2) + 0.5*x + 30",
    description: "pfaHeight: The height of the individual in the assessment.",
    inputs: ["pfaHeight"]
  },
  "pfaWeight": {
    formula: "0.02*(x**2) + 0.3*x + 50",
    description: "pfaWeight: The weight of the individual in the assessment.",
    inputs: ["pfaWeight"]
  },
  "pfaBMI": {
    formula: "-0.01*(x**2) + 1.2*x + 25",
    description: "pfaBMI: Body Mass Index in the assessment.",
    inputs: ["pfaBMI"]
  },
  "pfaSpeed": {
    formula: "0.03*(x**2) + 0.8*x + 5",
    description: "pfaSpeed: Speed recorded during the assessment.",
    inputs: ["pfaSpeed"]
  },
  "pfaYoYoTest": {
    formula: "0.04*(x**2) + 0.7*x + 3",
    description: "pfaYoYoTest: Performance in the Yo-Yo test during the assessment.",
    inputs: ["pfaYoYoTest"]
  },
  "pfaSingleLegBalanceLeftEyesOpen": {
    formula: "0.01*(x**2) + 0.5*x + 7",
    description: "pfaSingleLegBalanceLeftEyesOpen: Balance on one leg (left) with eyes open.",
    inputs: ["pfaSingleLegBalanceLeftEyesOpen"]
  },
  "pfaSingleLegBalanceLeftEyesClose": {
    formula: "0.01*(x**2) + 0.4*x + 6",
    description: "pfaSingleLegBalanceLeftEyesClose: Balance on one leg (left) with eyes closed.",
    inputs: ["pfaSingleLegBalanceLeftEyesClose"]
  },
  "pfaSingleLegBalanceRightEyesOpen": {
    formula: "0.01*(x**2) + 0.5*x + 7",
    description: "pfaSingleLegBalanceRightEyesOpen: Balance on one leg (right) with eyes open.",
    inputs: ["pfaSingleLegBalanceRightEyesOpen"]
  },
  "pfaSingleLegBalanceRightEyesClose": {
    formula: "0.01*(x**2) + 0.4*x + 6",
    description: "pfaSingleLegBalanceRightEyesClose: Balance on one leg (right) with eyes closed.",
    inputs: ["pfaSingleLegBalanceRightEyesClose"]
  },
  "pfaYBalanceTest1": {
    formula: "0.03*(x**2) + 0.6*x + 8",
    description: "pfaYBalanceTest1: Performance in the Y Balance Test (first direction).",
    inputs: ["pfaYBalanceTest1"]
  },
  "pfaYBalanceTest2": {
    formula: "0.03*(x**2) + 0.6*x + 8",
    description: "pfaYBalanceTest2: Performance in the Y Balance Test (second direction).",
    inputs: ["pfaYBalanceTest2"]
  },
  "pfaYBalanceTest3": {
    formula: "0.03*(x**2) + 0.6*x + 8",
    description: "pfaYBalanceTest3: Performance in the Y Balance Test (third direction).",
    inputs: ["pfaYBalanceTest3"]
  },
  "pfaStarExcursionTest1": {
    formula: "0.03*(x**2) + 0.7*x + 7",
    description: "pfaStarExcursionTest1: Performance in the Star Excursion Test (first direction).",
    inputs: ["pfaStarExcursionTest1"]
  },
  "pfaStarExcursionTest2": {
    formula: "0.03*(x**2) + 0.7*x + 7",
    description: "pfaStarExcursionTest2: Performance in the Star Excursion Test (second direction).",
    inputs: ["pfaStarExcursionTest2"]
  },
  "pfaStarExcursionTest3": {
    formula: "0.03*(x**2) + 0.7*x + 7",
    description: "pfaStarExcursionTest3: Performance in the Star Excursion Test (third direction).",
    inputs: ["pfaStarExcursionTest3"]
  },
  "pfaPushups": {
    formula: "0.02*(x**2) + 0.4*x + 6",
    description: "pfaPushups: Performance in a push-up test.",
    inputs: ["pfaPushups"]
  },
  "pfaPullups": {
    formula: "0.02*(x**2) + 0.5*x + 5",
    description: "pfaPullups: Performance in a pull-up test.",
    inputs: ["pfaPullups"]
  },
  "pfaPullAndHang": {
    formula: "0.01*(x**2) + 0.6*x + 4",
    description: "pfaPullAndHang: Duration of hanging during a pull-up test.",
    inputs: ["pfaPullAndHang"]
  },
  "pfaSquat": {
    formula: "0.02*(x**2) + 0.3*x + 5",
    description: "pfaSquat: Performance in a squat test.",
    inputs: ["pfaSquat"]
  },
  "pfaSingleLegSquat": {
    formula: "0.02*(x**2) + 0.4*x + 6",
    description: "pfaSingleLegSquat: Performance in a single-leg squat test.",
    inputs: ["pfaSingleLegSquat"]
  },
  "pfaPartialCurlUp": {
    formula: "0.01*(x**2) + 0.5*x + 4",
    description: "pfaPartialCurlUp: Performance in a partial curl-up test.",
    inputs: ["pfaPartialCurlUp"]
  },
  "pfaGripStrengthRight": {
    formula: "0.02*(x**2) + 0.3*x + 4",
    description: "pfaGripStrengthRight: Grip strength measured in the right hand.",
    inputs: ["pfaGripStrengthRight"]
  },
  "pfaGripStrengthLeft": {
    formula: "0.02*(x**2) + 0.3*x + 4",
    description: "pfaGripStrengthLeft: Grip strength measured in the left hand.",
    inputs: ["pfaGripStrengthLeft"]
  },
  "pfaSitNReach": {
    formula: "0.03*(x**2) + 0.6*x + 7",
    description: "pfaSitNReach: Performance in a sit-and-reach flexibility test.",
    inputs: ["pfaSitNReach"]
  },
  "pfaAppleysScratchTest": {
    formula: "0.02*(x**2) + 0.5*x + 6",
    description: "pfaAppleysScratchTest: Performance in the Apley's Scratch Test for shoulder flexibility.",
    inputs: ["pfaAppleysScratchTest"]
  },
  "pfa50mSprint": {
    formula: "0.04*(x**2) + 0.9*x + 2",
    description: "pfa50mSprint: Time taken to complete a 50-meter sprint.",
    inputs: ["pfa50mSprint"]
  },
  "pfa30mSprint": {
    formula: "0.04*(x**2) + 0.8*x + 4",
    description: "pfa30mSprint: Time taken to complete a 30-meter sprint.",
    inputs: ["pfa30mSprint"]
  },
  "pfa10mShuttleRun": {
    formula: "0.05*(x**2) + 0.6*x + 5",
    description: "pfa10mShuttleRun: Time taken to complete a 10-meter shuttle run.",
    inputs: ["pfa10mShuttleRun"]
  },
  "pfaTTest": {
    formula: "0.04*(x**2) + 0.7*x + 3",
    description: "pfaTTest: Time taken to complete a T-Test agility test.",
    inputs: ["pfaTTest"]
  },
  "pfaStandingBroadJump": {
    formula: "0.03*(x**2) + 0.6*x + 10",
    description: "pfaStandingBroadJump: Distance jumped in a standing broad jump.",
    inputs: ["pfaStandingBroadJump"]
  },
  "pfaVerticalJump": {
    formula: "0.03*(x**2) + 0.7*x + 8",
    description: "pfaVerticalJump: Height achieved in a vertical jump test.",
    inputs: ["pfaVerticalJump"]
  },
  "pfaSeatedMedBallThrow": {
    formula: "0.02*(x**2) + 0.5*x + 6",
    description: "pfaSeatedMedBallThrow: Distance achieved in a seated med ball throw.",
    inputs: ["pfaSeatedMedBallThrow"]
  },
  "pfaUpperBodyForce": {
    formula: "0.02*(x**2) + 0.6*x + 5",
    description: "pfaUpperBodyForce: The force exerted by the upper body in a strength test.",
    inputs: ["pfaUpperBodyForce"]
  },
  "pfaUpperBodyPower": {
    formula: "0.03*(x**2) + 0.7*x + 10",
    description: "pfaUpperBodyPower: The power generated by the upper body during explosive movements.",
    inputs: ["pfaUpperBodyPower"]
  },
  "pfaLowerBodyPower": {
    formula: "0.04*(x**2) + 0.8*x + 12",
    description: "pfaLowerBodyPower: The power generated by the lower body during explosive movements.",
    inputs: ["pfaLowerBodyPower"]
  },
  "pfaLowerBodyForce": {
    formula: "0.03*(x**2) + 0.5*x + 8",
    description: "pfaLowerBodyForce: The force exerted by the lower body in a strength test.",
    inputs: ["pfaLowerBodyForce"]
  },
  "pfaLevel": {
    formula: "0.05*(x**2) + 0.3*x + 15",
    description: "pfaLevel: The fitness level of the individual based on various performance metrics.",
    inputs: ["pfaLevel"]
  },
  "pfaVO2": {
    formula: "0.02*(x**2) + 0.4*x + 25",
    description: "pfaVO2: The VO2 max value representing aerobic capacity during the assessment.",
    inputs: ["pfaVO2"]
  },

  //Nutrition
  "naBodyWeight": {
    formula: "0.02*(x**2) + 0.5*x + 45",
    description: "naBodyWeight: Body weight of the individual based on height.",
    inputs: ["naBodyWeight"]
  },
  "naBodyHeight": {
    formula: "0.03*(x**2) + 0.7*x + 20",
    description: "naBodyHeight: Height of the individual based on age and lifestyle.",
    inputs: ["naBodyHeight"]
  },
  "naBMI": {
    formula: "-0.01*(x**2) + 1.5*x + 18",
    description: "naBMI: Body Mass Index of the individual.",
    inputs: ["naBMI"]
  },
  "naBodyFat": {
    formula: "0.01*(x**2) + 0.2*x + 8",
    description: "naBodyFat: Body fat percentage of the individual.",
    inputs: ["naBodyFat"]
  },
  "naFatFreeWeight": {
    formula: "0.02*(x**2) + 0.3*x + 30",
    description: "naFatFreeWeight: Fat-free weight of the individual.",
    inputs: ["naFatFreeWeight"]
  },
  "naSubcutaneousFat": {
    formula: "0.01*(x**2) + 0.4*x + 10",
    description: "naSubcutaneousFat: Subcutaneous fat percentage of the individual.",
    inputs: ["naSubcutaneousFat"]
  },
  "naVisceralFat": {
    formula: "0.02*(x**2) + 0.5*x + 5",
    description: "naVisceralFat: Visceral fat percentage of the individual.",
    inputs: ["naVisceralFat"]
  },
  "naBodyWater": {
    formula: "0.02*(x**2) + 0.6*x + 45",
    description: "naBodyWater: Body water percentage of the individual.",
    inputs: ["naBodyWater"]
  },
  "naSkeletalMuscle": {
    formula: "0.01*(x**2) + 0.4*x + 20",
    description: "naSkeletalMuscle: Skeletal muscle percentage of the individual.",
    inputs: ["naSkeletalMuscle"]
  },
  "naLeanMass": {
    formula: "0.02*(x**2) + 0.3*x + 25",
    description: "naLeanMass: Lean mass percentage of the individual.",
    inputs: ["naLeanMass"]
  },
  "naBoneMass": {
    formula: "0.01*(x**2) + 0.2*x + 10",
    description: "naBoneMass: Bone mass percentage of the individual.",
    inputs: ["naBoneMass"]
  },
  "naProtein": {
    formula: "0.01*(x**2) + 0.3*x + 18",
    description: "naProtein: Protein intake (in grams) based on weight.",
    inputs: ["naProtein"]
  },
  "naBMR": {
    formula: "0.03*(x**2) + 0.5*x + 1500",
    description: "naBMR: Basal Metabolic Rate in calories.",
    inputs: ["naBMR"]
  },
  "naMetabolicAge": {
    formula: "-0.02*(x**2) + 1.1*x + 25",
    description: "naMetabolicAge: The metabolic age of the individual based on various parameters.",
    inputs: ["naMetabolicAge"]
  },
  "naHealthScore": {
    formula: "0.01*(x**2) + 0.3*x + 75",
    description: "naHealthScore: The health score based on various metrics such as BMI, fat percentage, etc.",
    inputs: ["naHealthScore"]
  },
  //Cardio
  "coa800mRun": {
    formula: "0.05*(x**2) + 0.6*x + 5",
    description: "coa800mRun: Time taken to complete an 800-meter run.",
    inputs: ["coa800mRun"]
  },
  "coaYoYoTest": {
    formula: "0.04*(x**2) + 0.7*x + 3",
    description: "coaYoYoTest: Performance in the Yo-Yo endurance test.",
    inputs: ["coaYoYoTest"]
  },
  "coaRAST": {
    formula: "0.03*(x**2) + 0.8*x + 2",
    description: "coaRAST: Performance in the Running-Based Anaerobic Sprint Test (RAST).",
    inputs: ["coaRAST"]
  },
  "coaRAST2": {
    formula: "0.03*(x**2) + 0.8*x + 2.5",
    description: "coaRAST2: Performance in the second set of RAST.",
    inputs: ["coaRAST2"]
  },
  "coaRAST3": {
    formula: "0.03*(x**2) + 0.8*x + 3",
    description: "coaRAST3: Performance in the third set of RAST.",
    inputs: ["coaRAST3"]
  },
  "coaRAST4": {
    formula: "0.03*(x**2) + 0.8*x + 3.5",
    description: "coaRAST4: Performance in the fourth set of RAST.",
    inputs: ["coaRAST4"]
  },
  "coaRAST5": {
    formula: "0.03*(x**2) + 0.8*x + 4",
    description: "coaRAST5: Performance in the fifth set of RAST.",
    inputs: ["coaRAST5"]
  },
  "coaRAST6": {
    formula: "0.03*(x**2) + 0.8*x + 4.5",
    description: "coaRAST6: Performance in the sixth set of RAST.",
    inputs: ["coaRAST6"]
  },
  "coaFatigueIndex": {
    formula: "0.02*(x**2) + 0.5*x + 1",
    description: "coaFatigueIndex: Fatigue index calculated from the RAST test.",
    inputs: ["coaFatigueIndex"]
  },

  //Cognitive

  "cnaRulerDrop": {
    formula: "0.1*(x**2) + 0.5*x + 2",
    description: "cnaRulerDrop: Time or score in the ruler drop test, assessing reaction time.",
    inputs: ["cnaRulerDrop"]
  },
  "cnaBlazepods": {
    formula: "0.2*(x**2) + 0.4*x + 1.5",
    description: "cnaBlazepods: Performance in the Blazepods reaction time training system.",
    inputs: ["cnaBlazepods"]
  },
  "cnaBlazepodsTime": {
    formula: "0.15*(x**2) + 0.6*x + 2.2",
    description: "cnaBlazepodsTime: Time taken to complete a Blazepods test.",
    inputs: ["cnaBlazepodsTime"]
  },
  "cnaWallToss": {
    formula: "0.12*(x**2) + 0.45*x + 1.8",
    description: "cnaWallToss: Performance in the wall toss test, assessing hand-eye coordination.",
    inputs: ["cnaWallToss"]
  },
  "cnaPlateTaping": {
    formula: "0.08*(x**2) + 0.5*x + 2.5",
    description: "cnaPlateTaping: Performance in the plate tapping test, assessing cognitive processing speed.",
    inputs: ["cnaPlateTaping"]
  },

  //Sports Analysis
  "spaAverageTorqueCR": {
    formula: "0.03*(x**2) + 0.7*x + 20",
    description: "spaAverageTorqueCR: Average torque for concentric right leg.",
    inputs: ["spaAverageTorqueCR"]
  },
  "spaAverageTorqueCL": {
    formula: "0.03*(x**2) + 0.7*x + 20",
    description: "spaAverageTorqueCL: Average torque for concentric left leg.",
    inputs: ["spaAverageTorqueCL"]
  },
  "spaPeakTorqueCR": {
    formula: "0.05*(x**2) + 0.8*x + 25",
    description: "spaPeakTorqueCR: Peak torque for concentric right leg.",
    inputs: ["spaPeakTorqueCR"]
  },
  "spaPeakTorqueCL": {
    formula: "0.05*(x**2) + 0.8*x + 25",
    description: "spaPeakTorqueCL: Peak torque for concentric left leg.",
    inputs: ["spaPeakTorqueCL"]
  },
  "spaAverageTorqueIR": {
    formula: "0.02*(x**2) + 0.6*x + 18",
    description: "spaAverageTorqueIR: Average torque for isometric right leg.",
    inputs: ["spaAverageTorqueIR"]
  },
  "spaAverageTorqueIL": {
    formula: "0.02*(x**2) + 0.6*x + 18",
    description: "spaAverageTorqueIL: Average torque for isometric left leg.",
    inputs: ["spaAverageTorqueIL"]
  },
  "spaPeakTorqueIR": {
    formula: "0.04*(x**2) + 0.9*x + 22",
    description: "spaPeakTorqueIR: Peak torque for isometric right leg.",
    inputs: ["spaPeakTorqueIR"]
  },
  "spaPeakTorqueIL": {
    formula: "0.04*(x**2) + 0.9*x + 22",
    description: "spaPeakTorqueIL: Peak torque for isometric left leg.",
    inputs: ["spaPeakTorqueIL"]
  },
  "spaJumpHeight": {
    formula: "0.03*(x**2) + 0.6*x + 12",
    description: "spaJumpHeight: Height achieved during a jump test.",
    inputs: ["spaJumpHeight"]
  },
  "spaFlightTime": {
    formula: "0.02*(x**2) + 0.4*x + 5",
    description: "spaFlightTime: Time spent in air during a jump.",
    inputs: ["spaFlightTime"]
  },
  "spaVelocity": {
    formula: "0.03*(x**2) + 0.5*x + 8",
    description: "spaVelocity: Velocity recorded during performance.",
    inputs: ["spaVelocity"]
  },
  "spaForce": {
    formula: "0.04*(x**2) + 0.7*x + 15",
    description: "spaForce: Force exerted during the assessment.",
    inputs: ["spaForce"]
  },
  "spaPower": {
    formula: "0.05*(x**2) + 0.8*x + 10",
    description: "spaPower: Power output during the assessment.",
    inputs: ["spaPower"]
  }


  // // VO2 Max
  // "Distance Covered": {
  //   formula: "0.05*(x**2) + 1.2*x + 10",
  //   description: "Distance Covered: Distance travelled over time or task.",
  //   inputs: ["distanceCovered"]
  // },
  // "Level": {
  //   formula: "-0.01*(x**2) + 1.5*x + 5",
  //   description: "Level: Level or difficulty of the task or activity.",
  //   inputs: ["level"]
  // },
  // "VO2 Max (ml/kg/min)": {
  //   formula: "0.03*(x**2) + 0.8*x + 12",
  //   description: "VO2 Max: The maximum volume of oxygen the body can utilize per kilogram of body weight per minute.",
  //   inputs: ["vo2Max"]
  // },
  // "FlexibilitySitReach": {
  //   formula: "0.02*(x**2) + 0.6*x + 3",
  //   description: "FlexibilitySitReach: The maximum volume of oxygen the body can utilize per kilogram of body weight per minute.",
  //   inputs: ["FlexibilitySitReach"]
  // },
  // "Hits": {
  //   formula: "-0.00000117*(x**2) + 0.000406*x + 5.8",
  //   description: "Hits: The number of successful hits or actions in a task.",
  //   inputs: ["hits"]
  // },
  // "Miss": {
  //   formula: "-0.02*(x**2) + 0.5*x + 8",
  //   description: "Miss: The number of unsuccessful attempts in a task.",
  //   inputs: ["miss"]
  // },
  // "Average Reaction": {
  //   formula: "0.01*(x**2) + 0.8*x + 5",
  //   description: "Average Reaction: The average time taken for a reaction to stimuli.",
  //   inputs: ["averageReaction"]
  // },
  // "Strike": {
  //   formula: "0.015*(x**2) + 0.7*x + 6",
  //   description: "Strike: The quality or effectiveness of a strike.",
  //   inputs: ["strike"]
  // },
  // "Catch/min": {
  //   formula: "0.02*(x**2) + 0.6*x + 3",
  //   description: "Catch/min: The number of successful catches per minute.",
  //   inputs: ["catchPerMinute"]
  // },
  // "Deep Squat": {
  //   formula: "0.03*(x**2) + 0.4*x + 10",
  //   description: "Deep Squat: Performance in a deep squat exercise.",
  //   inputs: ["deepSquat"]
  // },
  // "Inline Lunge": {
  //   formula: "0.03*(x**2) + 0.35*x + 9",
  //   description: "Inline Lunge: Performance in an inline lunge exercise.",
  //   inputs: ["inlineLunge"]
  // },
  // "Hurdle Step": {
  //   formula: "0.02*(x**2) + 0.6*x + 3",
  //   description: "Hurdle Step: Performance in a hurdle step exercise.",
  //   inputs: ["hurdleStep"]
  // },
  // "Active SLR": {
  //   formula: "0.01*(x**2) + 0.5*x + 7",
  //   description: "Active SLR: Active straight leg raise test performance.",
  //   inputs: ["activeSLR"]
  // },
  // "Shoulder Mobility": {
  //   formula: "-0.005*(x**2) + 0.4*x + 7",
  //   description: "Shoulder Mobility: A measure of shoulder flexibility.",
  //   inputs: ["shoulderMobility"]
  // },
  // "Push Up": {
  //   formula: "-0.006*(x**2) + 0.35*x + 6",
  //   description: "Push Up: Performance in a push-up test.",
  //   inputs: ["pushUp"]
  // },
  // "Rotatory Stability": {
  //   formula: "-0.007*(x**2) + 0.3*x + 5",
  //   description: "Rotatory Stability: Performance in a stability test.",
  //   inputs: ["rotatoryStability"]
  // },
  // "Total (21)": {
  //   formula: "-0.007*(x**2) + 0.28*x + 4",
  //   description: "Total (21): Overall score or result from 21 measures.",
  //   inputs: ["total21"]
  // },
  // "Average Torque": {
  //   formula: "-0.01*(x**2) + 0.3*x + 6",
  //   description: "Average Torque: The average rotational force during a task.",
  //   inputs: ["averageTorque"]
  // },
  // "Peak Torque": {
  //   formula: "0.02*(x**2) + 0.7*x + 10",
  //   description: "Peak Torque: The highest rotational force produced.",
  //   inputs: ["peakTorque"]
  // },
  // "Med Ball Throw (m)": {
  //   formula: "0.01*(x**2) + 0.6*x + 3",
  //   description: "Med Ball Throw: The distance thrown with a medicine ball.",
  //   inputs: ["medBallThrow"]
  // },
  // "Force (N)": {
  //   formula: "0.015*(x**2) + 0.5*x + 4",
  //   description: "Force: The amount of force generated.",
  //   inputs: ["force"]
  // },
  // "Power (W)": {
  //   formula: "0.02*(x**2) + 0.4*x + 5",
  //   description: "Power: The rate at which work is done, measured in watts.",
  //   inputs: ["power"]
  // },
  // "Jump Height (m)": {
  //   formula: "0.03*(x**2) + 0.3*x + 6",
  //   description: "Jump Height: The height achieved in a vertical jump.",
  //   inputs: ["jumpHeight"]
  // },
  // "Weight": {
  //   formula: "0.02*(x**2) + 0.25*x + 5",
  //   description: "Weight: The body weight of the individual.",
  //   inputs: ["weight"]
  // },
  // "BMI": {
  //   formula: "-0.02*(x**2) + 1.5*x + 20",
  //   description: "BMI: Body Mass Index, an estimate of body fat based on weight and height.",
  //   inputs: ["bmi"]
  // },
  // "Body Fat": {
  //   formula: "0.015*(x**2) + 0.4*x + 6",
  //   description: "Body Fat: The percentage of body weight composed of fat.",
  //   inputs: ["bodyFat"]
  // },
  // "Fat Free Weight": {
  //   formula: "0.02*(x**2) + 0.8*x + 5",
  //   description: "Fat Free Weight: The weight of the body excluding fat.",
  //   inputs: ["fatFreeWeight"]
  // },
  // "Subcutaneous Fat": {
  //   formula: "0.02*(x**2) + 0.75*x + 4",
  //   description: "Subcutaneous Fat: The fat stored under the skin.",
  //   inputs: ["subcutaneousFat"]
  // },
  // "Visceral Fat": {
  //   formula: "0.03*(x**2) + 0.5*x + 6",
  //   description: "Visceral Fat: The fat stored around the organs.",
  //   inputs: ["visceralFat"]
  // },
  // "Body Water": {
  //   formula: "0.02*(x**2) + 1.5*x + 20",
  //   description: "Body Water: The total amount of water in the body.",
  //   inputs: ["bodyWater"]
  // },
  // "Skeletal Muscle": {
  //   formula: "-0.03*(x**2) + 0.8*x + 25",
  //   description: "Skeletal Muscle: The muscle mass attached to bones, involved in movement.",
  //   inputs: ["skeletalMuscle"]
  // },
  // "Lean Mass": {
  //   formula: "0.02*(x**2) + 0.7*x + 8",
  //   description: "Lean Mass: The total weight of the body excluding fat.",
  //   inputs: ["leanMass"]
  // },
  // "Bone Mass": {
  //   formula: "-0.02*(x**2) + 1.2*x + 15",
  //   description: "Bone Mass: The weight of the bones in the body.",
  //   inputs: ["boneMass"]
  // },
  // "Protein": {
  //   formula: "-0.015*(x**2) + 0.9*x + 10",
  //   description: "Protein: The amount of protein in the body.",
  //   inputs: ["protein"]
  // },
  // "BMR": {
  //   formula: "-0.02*(x**2) + 1.5*x + 20",
  //   description: "BMR: Basal Metabolic Rate, the number of calories required to keep the body functioning at rest.",
  //   inputs: ["bmr"]
  // },
  // "Metabolic Age": {
  //   formula: "-0.03*(x**2) + 1.2*x + 15",
  //   description: "Metabolic Age: Age based on metabolic rate.",
  //   inputs: ["metabolicAge"]
  // },
  // "Health Score": {
  //   formula: "0.01*(x**2) + 0.9*x + 25",
  //   description: "Health Score: A composite measure of health based on various factors.",
  //   inputs: ["healthScore"]
  // },
  // "Height": {
  //   formula: "0.02*(x**2) + 0.6*x + 30",
  //   description: "Height: The height of an individual.",
  //   inputs: ["height"]
  // },
  // "Special Formula": {
  //   formula: "0.03*(x**2) - 0.5*x + 1.2",
  //   description: "Special Formula: For unknown or special use cases.",
  //   inputs: ["specialFormula"]
  // }
};
