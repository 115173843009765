import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { AgGridAngular } from 'ag-grid-angular';
import { NgToastModule } from 'ng-angular-popup';
import { AssessmentService } from '../../../services/assessment.service';
import { NotificationService } from '../../../services/Notifications.service';
import { UserRegisterService } from '../../../services/user-register.service';

@Component({
  selector: 'app-edit-team',
  standalone: true,
  imports: [CommonModule, RouterOutlet, AgGridAngular, RouterLink, RouterModule, FormsModule,NgToastModule,NgSelectModule],
  templateUrl: './edit-team.component.html',
  styleUrl: './edit-team.component.css'
})
export class EditTeamComponent {

  teamId:any;
  orgId:any;
  teamName:any;
  teamSport:any;
  SportId: any;
  teams: any[]=[];
  sportName: any[]=[];



  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private assessmentService: AssessmentService,
    private notify: NotificationService,
    private userRegisterService: UserRegisterService,
  ) {
    this.route.queryParams.subscribe((params:any) => {
      this.teamId = params['teamId'];
      this.orgId = params['teamOrganization'];
      this.teamName = params['teamName'];
      this.SportId = params['SportId'];

    });
  }

  ngOnInit(): void {
 
    this.loadTeams();
  }

 

  loadTeams() {
    this.userRegisterService.getOrgTeams(this.orgId).subscribe(
      (data) => {
        this.teams = data;
        this.sportName=this.teams.filter(team => team.teamId ==  this.teamId);

        console.log( this.sportName, "this.teams");
      },
      (error) => {
        console.error('Error fetching teams:', error);
      }
    );
  }
  onSubmit(){
      this.assessmentService.AddTeam(this.teamId, this.orgId,this.teamName,this.SportId).subscribe(
        
        (response) => {
          if (response.data[0] == 0) {
            this.notify.failed(response.message); 
        } else {
          this.notify.success(response.message);
          setTimeout(() => {
  
            this.router.navigate(['/team'], { 
              queryParams: { 
                orgId: this.orgId 
              }
            }
          );
            
          },
          
          1000);
        }
      },
        error => {
          this.notify.failed(error.error.message);
          console.error("error",error.error.message);
          // Handle error
        }
      );
    } 
  }


