import { AssessmentService } from '../../services/assessment.service';
import { SharedService } from '../../services/shared.service';
import { NotificationService } from '../../services/Notifications.service';
 
import { UserRegisterService } from '../../services/user-register.service';
import { ChatService } from '../../services/chat.service';
import { ActivatedRoute, Router, RouterLink, RouterOutlet } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, NgZone, OnInit, PLATFORM_ID } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { NgToastModule } from 'ng-angular-popup';
import { DomSanitizer } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { PrivilegeService } from '../../services/privilage.service';
import { Privilege } from '../../model/Privillage';
import { catchError, forkJoin, of, switchMap } from 'rxjs';
import { SupportStaffService } from '../../services/support-staff.service';
@Component({
  selector: 'app-pending-asessment-form',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterOutlet, RouterLink, HttpClientModule, NgToastModule],
  templateUrl: './pending-asessment-form.component.html',
  styleUrls: ['./pending-asessment-form.component.css']
})
export class PendingAsessmentFormComponent implements OnInit {
  UserId: any;
  name: any;
  athleteId: any;
  teamId: any;
  ImageUrl: any;
  showImage: boolean = false;
  edit: boolean = false;
 
 
  groupedFields: { [key: string]: any } = {
    playerId: null,
    assessmentDate: null
  };
  formData: { [key: string]: any } = {
  };
  originalAssessments: any[] = [];
  roleId: any;
  assesserId: any;
  assessment: any;
  id: any;
  asmId: any;
  catId: any;
  chat: any;
  athletedata: any;
  apiResponse: any;
  msg: any;
  assessmentsData: any[] = [];
  summary: any = {
    ausId: 0,
    ausAssessment: '',
    ausAthlete: '',
    ausSummary: '',
    ausActionPlan: ''
  };
  Plan: any;
  selectdata: any;
  category: any;
  scoreOptions = [
    { value: 1, label: 'Score 1' },
    { value: 2, label: 'Score 2' },
    { value: 3, label: 'Score 3' }
  ];
  selectTest: any;
  playerList: any[]=[];
  rowData: any[]=[];
  playerName: any;
  privilege:Privilege
  IsAdmin: boolean;
  image: any;
  orgId:number=0;
  jsonData: any;
  sport: any;
  assessor: number = 0;
  Assessor: boolean;
  NutritionId: number | undefined;
  PhysicalFitnessId: number | undefined;
  CognitiveId: number | undefined;
  CardioId: number | undefined;
  InjuryId: number | undefined;
  SportsId: number | undefined;
  SleepId: number | undefined;
  HydrationId: number | undefined;
  HoursId: number | undefined;
  LevelOneId: number | undefined;
  columnsToValidate: string[] = [];

  constructor(
    private assessmentService: AssessmentService,private supportStaffService: SupportStaffService,
    private route: ActivatedRoute,
    private sharedService: SharedService,
    private router: Router,
    private location: Location,
    private notify: NotificationService, private chatService: ChatService,private priService:PrivilegeService,
    private sanitizer: DomSanitizer, private userRegisterService: UserRegisterService,private cdRef: ChangeDetectorRef,private ngZone: NgZone,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.privilege = this.priService.getPrivileges();
    this.IsAdmin = this.privilege.isAdmin;
    this.Assessor = this.privilege.isAssessor;
    if (this.assesserId) {
      this.userRegisterService.fetchUser(this.assesserId).subscribe(
        (data) => {
          this.roleId = data[0].usrRole;
        })
    }
  }
  today: string = new Date().toISOString().split('T')[0];

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.athleteId = params['athleteId'];
      this.teamId = params['teamId'];
      this.assesserId = 0;
      this.id = params['id'];
      this.category = +params['category'];
      this.formData['playerId'] = this.athleteId;
      this.getProfileImage();
    });
    this.supportStaffService.getSupportStaff().subscribe();
    this.NutritionId = this.supportStaffService.getSsIdBySsName("Nutrition");
    this.PhysicalFitnessId = this.supportStaffService.getSsIdBySsName("PhysicalFitness");
    this.CognitiveId = this.supportStaffService.getSsIdBySsName("Cognitive");
    this.CardioId = this.supportStaffService.getSsIdBySsName("Cardio");
    this.InjuryId = this.supportStaffService.getSsIdBySsName("Injury Prevention Analysis");
    this.SportsId = this.supportStaffService.getSsIdBySsName("Sports Performance Analysis");
    this.SleepId = this.supportStaffService.getSsIdBySsName("Sleep questionnaire");
    this.HydrationId = this.supportStaffService.getSsIdBySsName("Hydration questionaire");
    this.HoursId = this.supportStaffService.getSsIdBySsName("24 Hours Recall questionaire");
    this.LevelOneId = this.supportStaffService.getSsIdBySsName("Level One");
  
    this.sharedService.detail$.subscribe((data: any) => {
      this.UserId = data.user.usrId;
      this.name = data.user.usrFullName;
      this.roleId = data.user.usrRole;
 
      if (!this.IsAdmin) {
        this.assesserId = this.UserId;
      }
 
      if (this.id) {
        this.loadAssessmentDetails(this.id); // Load details when in edit mode
      }
    });
    //this.getAllAssessmentData()
    this.assessmentService.getTestDetails(this.teamId, this.assesserId).subscribe(
      (data) => {
        // Define the test names to exclude
        const excludedTestNames = [
          "24 Hours Recall questionaire",
          "Hydration questionaire",
          "Sleep questionnaire"
        ];
    
        // Use a Set to track unique test names
        const uniqueTestNames = new Set<string>();
    
        // Filter and modify the assessments based on specified conditions
        this.selectTest = data
          .map((assessment: any) => {
            // Remove "Isometric" and "Concentric" prefixes if they exist in the testName
            assessment.testName = assessment.testName
              .replace(/^Isometric\s*/, "")
              .replace(/^Concentric\s*/, "");
            return assessment;
          })
          .filter((assessment: any) =>
            assessment.testCategory === this.category &&
            !excludedTestNames.includes(assessment.testName) // Exclude specific test names
          );
    
        // Further filter based on astAssessment ID
        this.selectdata = this.selectTest.filter((assessment: any) =>
          assessment.astAssessment == this.id
        );
    
        // Group the filtered data by subheading
        this.groupFieldsBySubHeading(this.selectdata);
    
        // Get the asmId from the first assessment
        this.asmId = this.selectTest.length > 0 ? this.selectTest[0].astAssessment : null;
      },
      (error: any) => {
        // Handle error if needed
      }
    );
    
    
    this.GetPlayer();
}
 
  GetPlayer(){
    this.assessmentService.getPlayerByAssessmentId(this.id).subscribe(response => {
      this.selectdata = response.filter((assessment: any) => assessment.aplCategory == this.category);
      // Create a map to store unique assessments based on PlayerId (or any unique identifier)
      const uniqueAssessments = new Map<number, any>();
 
      // Filter the response to only include unique assessments
      this.selectdata.forEach((item: any) => {
        // Only add the item if it does not exist in the map
        if (!uniqueAssessments.has(item.aplPlayer)) {
          uniqueAssessments.set(item.aplPlayer, {
            "Player Name": `${item.usrFullName}`,
            Name: `${item.usrFullName}`,
            aplPlayer: `${item.aplPlayer}`,
            teamId: `${item.teamId}`,
            aplAssessment: `${item.aplAssessment}`,
            "Email": `${item.usrEmail}`,
            PlayerEmail: `${item.usrEmail}`,
            "Phone No": `${item.usrPhoneNo}`,
            PhoneNo: `${item.usrPhoneNo}`,
            "DOB": new Date(item.usrDoB).toLocaleDateString(),
            "status": `${item.aplStatus}`,
            "sport":`${item.asiPrimarySport}`
          });
        }
      });
 
      // Convert the unique assessments map back to an array for your rowData
      this.rowData = Array.from(uniqueAssessments.values());
      this.playerName=  this.rowData.filter((assessment: any) => assessment.aplPlayer === this.athleteId);
      this.sport=this.playerName[0].sport;
      this.playerList = this.rowData;
    }, error => {
      console.error('Error submitting assessment', error);
    });
  }
 
  // Fetch assessment data and populate form in edit mode
  loadAssessmentDetails(id: string) {
    this.assessmentService.getcardioById(id).subscribe(
      (response: any) => {
        this.populateFormForEdit(response); // Populate the form with fetched data
      },
      (error: any) => {
        console.error('Error fetching assessment details:', error);
      }
    );
  }
 
  // Group fields by subHeading and initialize formData with testColumnName for a new form
  groupFieldsBySubHeading(data: any) {
    this.groupedFields = data.reduce((acc: any, item: any) => {
        const subHeading = item.testSubHeading;
        if (!acc[subHeading]) {
            acc[subHeading] = [];
        }
        acc[subHeading].push(item);

        // Initialize formData with testColumnName and store the column names to check later
        this.formData[item.testColumnName] = null; // or any default value for new form
        this.columnsToValidate = this.columnsToValidate || []; // Initialize if not set
        this.columnsToValidate.push(item.testColumnName); // Track columns for validation

        return acc;
    }, {});

    this.getCardiology();
    this.getfitness();
    this.getCognitive();
    this.GetLevelOne();
    this.getinjuryanalysis();
    this.GetAthelete();
    this.getSportsPerformanceAnalysis();
}

 
  populateFormForEdit(assessmentData: any) {
 
    // Iterate over each key in the assessmentData and assign it to formData
    for (const key in assessmentData) {
      if (assessmentData.hasOwnProperty(key)) {
        const trimmedKey = key.trim();
        // Check if the key corresponds to any testColumnName
        if (this.formData[trimmedKey] !== undefined) {
          this.formData[trimmedKey] = assessmentData[key];
        }
      }
    }
  }
  onBack():void{
    this.location.back();
  }
  isFormValid(): boolean {
    // Check if formData or updateDate is missing
    if (!this.formData || !this.formData['updateDate']) {
        return false; // Return false if formData or updateDate is missing
    }

    // Validate all fields specified in columnsToValidate
    const allFieldsValid = this.columnsToValidate.every(column => {
        return this.formData[column] !== '' && this.formData[column] !== null && this.formData[column] !== undefined;
    });

    return allFieldsValid; // Return true only if all fields are filled
}



  
  // Submit the form data
  onSubmit() {
    if (!this.isFormValid()) {
      // Run within Angular's zone to display error message immediately
      this.ngZone.run(() => {
        this.notify.failed("Fill all required details");
        this.cdRef.detectChanges(); // Force view update
      });
      return; 
    }
    if (this.category == this.PhysicalFitnessId) {
      this.formData['pfaId'] =this.IsAdmin ? this.assessor : this.formData['pfaId'];
      this.formData['pfaDate'] = this.formData['assessmentDate'];
      this.formData['pfaAssessor'] = this.IsAdmin ? this.assessor : this.assesserId;
      this.formData['pfaAthlete'] = this.athleteId;
      this.formData['pfaFollowUpDate'] = this.formData['assessmentDate'];
      this.formData['pfaCreatedBy'] = this.assesserId;
      this.formData['pfaCreatedOn'] = this.formData['assessmentDate'];
      this.formData['pfaUpdatedBy'] = this.assesserId;
      this.formData['pfaUpdatedOn'] = this.formData['updateDate'];
      this.formData['pfaAssessment'] = this.id;
      // this.catId = 4;
      this.assessmentService.addPhysicalFitnessDetail([this.formData]).subscribe(
        response => {
 
          this.assessmentService.updateAssessmentStatus(this.formData['pfaAthlete'], this.id, this.category,this.assesserId).subscribe(
            response => {
 
            });
          this.checkUserSummary()
          this.notify.success("Data updated successfully");
         
        },
        error => {
          this.notify.failed("Error submitting assessment");
          console.error('Error submitting assessment', error);
        }
      );
    } else if (this.category == this.CardioId) {
      this.formData['coaId'] = this.IsAdmin ? this.assessor : this.formData['coaId'];
      this.formData['coaAthlete'] = this.athleteId;
      this.formData['coaAssessor'] = this.IsAdmin ? this.assessor : this.assesserId;
      this.formData['coaCreatedBy'] = this.assesserId;
      this.formData['coaCreatedOn'] = this.formData['assessmentDate'];
      this.formData['coaUpdatedBy'] = this.assesserId;
      this.formData['coaUpdatedOn'] = this.formData['updateDate'];
      this.formData['coaDate'] = this.formData['assessmentDate'];
      this.formData['coaAssessment'] = this.id;
      // this.catId = 7;
      this.assessmentService.addcordio([this.formData]).subscribe(
        response => {
          this.assessmentService.updateAssessmentStatus(this.formData['coaAthlete'], this.id, this.category,this.assesserId).subscribe(
            response => {
              this.checkUserSummary()
            });
          this.notify.success("Data updated successfully");
         
        },
        error => {
          this.notify.failed("Error submitting assessment");
          console.error('Error submitting assessment', error);
        }
      );
    } else if (this.category == this.CognitiveId) {
      this.formData['cnaId'] = this.IsAdmin ? this.assessor : this.formData['cnaId'];
      this.formData['cnaAthlete'] = this.athleteId;
      this.formData['cnaAssessor'] = this.IsAdmin ? this.assessor : this.assesserId;
      this.formData['cnaCreatedBy'] = this.assesserId;
      this.formData['cnaCreatedOn'] = this.formData['assessmentDate'];
      this.formData['cnaUpdatedBy'] = this.assesserId;
      this.formData['cnaUpdatedOn'] = this.formData['updateDate'];
      this.formData['cnaAssessment'] = this.id;
      this.formData['cnaDate'] = this.formData['assessmentDate'];
      // this.catId = 8;
      this.assessmentService.addcognitive([this.formData]).subscribe(
        response => {
          this.assessmentService.updateAssessmentStatus(this.formData['cnaAthlete'], this.id, this.category,this.assesserId).subscribe(
            response => {
              this.checkUserSummary()
            });
          this.notify.success("Data updated successfully");
       
        },
        error => {
          this.notify.failed("Error submitting assessment");
          console.error('Error submitting assessment', error);
        }
      );
    } else if (this.category == this.LevelOneId) {
      this.formData['loaAthlete'] = this.athleteId;
      this.formData['loaAsessmentDate'] = this.formData['assessmentDate'];
      this.formData['loaAssessor'] = this.assesserId;
      this.formData['loaAssessment'] = this.id;
      this.formData['sportName'] = this.sport;
      // this.catId = 9;
      this.assessmentService.addLevelOneDetail([this.formData]).subscribe(
        response => {
          this.assessmentService.updateAssessmentStatus(this.formData['loaAthlete'], this.id,this.category,this.assesserId).subscribe(
            response => {
              this.checkUserSummary()
            });
          this.notify.success("Data updated successfully");
         
        },
        error => {
          this.notify.failed("Error submitting assessment");
          console.error('Error submitting assessment', error);
        }
      );
    }
    else if(this.category == this.InjuryId){
      this.formData['ipaId'] =this.IsAdmin ? this.assessor : this.formData['ipaId'];
      this.formData['ipaAthlete'] =this.athleteId;
      this.formData['ipaAssessor'] = this.IsAdmin ? this.assessor : this.assesserId;
      this.formData['ipaAssessment'] = this.id;
      this.formData['ipaAssessmentDate'] = this.formData['assessmentDate'];
      this.formData['ipaCreatedOn'] = this.formData['assessmentDate'];
      this.formData['ipaUpdatedOn'] = this.formData['updateDate'];
      // this.catId = 10;
      this.assessmentService.addIPADetails([this.formData]).subscribe(
        response => {
          this.assessmentService.updateAssessmentStatus(this.formData['ipaAthlete'], this.id, this.category,this.assesserId).subscribe(
            response => {
              
            });
            this.checkUserSummary()
          this.notify.success("Data updated successfully");
         
        },
        error => {
          this.notify.failed("Error submitting assessment");
          console.error('Error submitting assessment', error);
        }
      );
    }
    else if(this.category == this.SportsId){
      this.formData['spaId'] = this.IsAdmin ? this.assessor : this.formData['spaId'];
      this.formData['spaAthlete'] = this.athleteId;
      this.formData['spaAssessor'] = this.IsAdmin ? this.assessor : this.assesserId;
      this.formData['spaAssessment'] = this.id;
      this.formData['spaDate'] = this.formData['assessmentDate'];
      this.formData['spaCreatedOn'] = this.formData['assessmentDate'];
      this.formData['spaUpdatedOn'] = this.formData['updateDate'];
      // this.catId=11;
      this.assessmentService.SaveSportsperformance([this.formData]).subscribe(
        response => {
          this.assessmentService.updateAssessmentStatus(this.formData['spaAthlete'],this.id,this.category,this.assesserId).subscribe(
            response => {
              this.checkUserSummary()
            });
            this.notify.success("Data updated successfully");
        },
        error => {
          this.notify.failed("Error submitting assessment");
          console.error('Error submitting assessment', error);
        }
      );
    } else {
      this.formData['naId'] = this.IsAdmin ? this.assessor : this.formData['naId'];
      this.formData['naDate'] = this.formData['assessmentDate'];
      this.formData['naAssessment'] = this.id;
      this.formData['naAssessor'] = this.IsAdmin ? this.assessor : this.assesserId;
      this.formData['naAthlete'] = this.athleteId;
      this.formData['naCreatedBy'] = this.assesserId;
      this.formData['naCreatedOn'] = this.formData['assessmentDate'];
      this.formData['naUpdatedBy'] = this.assesserId;
      this.formData['naUpdatedOn'] = this.formData['updateDate'];
      this.assessmentService.addnutritional([this.formData]).subscribe(
        response => {
          this.assessmentService.updateAssessmentStatus(this.formData['naAthlete'],this.id,this.category,this.assesserId).subscribe(
            response => {
             
            });
            this.checkUserSummary()
            this.notify.success("Data updated successfully");
        },
        error => {
          this.notify.failed("Error submitting assessment");
          console.error('Error submitting assessment', error);
        }
      );
    }
 
  }
 
  getProfileImage() {
    this.ImageUrl = null;
    this.showImage = false;
    this.assessmentService.getEmptyImage(this.athleteId).subscribe(
      (response) => {
        if (response) {
          this.showImage = true;
           this.image = "assets/image/profileimg.jpg"
        }
      },
      (error) => {
        this.assessmentService.getImage(this.athleteId).subscribe(
          (imageBlob) => {
        const objectURL = URL.createObjectURL(imageBlob);
        this.ImageUrl = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        this.image = this.ImageUrl.changingThisBreaksApplicationSecurity
        this.showImage = true;
          }
      );
        console.error('Error fetching image', error);
      }
    );
  }
 
 
  getCardiology() {
   
    this.clearPreviousData()
    if (this.id && this.category == 7) {
      if(this.IsAdmin){
      this.assessmentService.getCardioByTest(this.id).subscribe((response: any) => {
        if (response && response.length > 0) {
          this.edit=true;
          if (this.IsAdmin) {
            this.selectdata =response.filter((assessment: any) => assessment.athlete == this.athleteId)
          } else {
            this.selectdata = response.filter((assessment: any) => assessment.athlete == this.athleteId && assessment.assessorId== this.UserId );
          }
          this.formData = {};
  
          // Loop through selectdata and assign values dynamically
          this.selectdata.forEach((assessment: any) => {
            const columnName = assessment.testColumnName;
            const columnValue = assessment.matchedColumnData;
            this.formData[columnName] = columnValue;
          });
          const data =   this.selectdata[0];
          this.formData['coaId'] = data.coaId;
          this.formData['coaAthlete'] = data.athlete;
          this.formData['coaCreatedOn'] = data.assessmentDate ;  
          this.formData['assessmentDate'] = data.assessmentDate ? data.assessmentDate.split('T')[0] : '';
          this.formData['updateDate'] = data.updateDate ? data.updateDate.split('T')[0] : 'mm/dd/yyyy';
          this.formData['coaAssessor'] = data.assessorId;
          this.formData['coaCreatedBy'] = data.coaCreatedBy;
          this.assessmentsData.push(data);
        } else{
          this.edit=false;
        }
        this.getProfileImage();
      },
        (error: any) => {
          console.error('Error fetching assessment data', error);
        }
      );
    }else{
      this.assessmentService.getCardioByTestAssessor(this.id).subscribe((response: any) => {
        if (response && response.length > 0) {
          this.edit=true;
          if (this.IsAdmin) {
            this.selectdata =response.filter((assessment: any) => assessment.athlete == this.athleteId)
          } else {
            this.selectdata = response.filter((assessment: any) => assessment.athlete == this.athleteId && assessment.assessorId== this.UserId );
          }
          this.formData = {};
  
          // Loop through selectdata and assign values dynamically
          this.selectdata.forEach((assessment: any) => {
            const columnName = assessment.testColumnName;
            const columnValue = assessment.matchedColumnData;
            this.formData[columnName] = columnValue;
          });
          const data =   this.selectdata[0];
          this.formData['coaId'] = data.coaId;
          this.formData['coaAthlete'] = data.athlete;
          this.formData['coaCreatedOn'] = data.assessmentDate ;  
          this.formData['assessmentDate'] = data.assessmentDate ? data.assessmentDate.split('T')[0] : '';
          this.formData['updateDate'] = data.updateDate ? data.updateDate.split('T')[0] : 'mm/dd/yyyy';
          this.formData['coaAssessor'] = data.assessorId;
          this.formData['coaCreatedBy'] = data.coaCreatedBy;
          this.assessmentsData.push(data);
        } else{
          this.edit=false;
        }
        this.getProfileImage();
      },
        (error: any) => {
          console.error('Error fetching assessment data', error);
        }
      );
    }
    }
  }
  GetAthelete() {
    this.clearPreviousData();
    if (this.id && this.category == 5) {
      if(this.IsAdmin){
        this.assessmentService.getNutritionalByTest(this.id).subscribe(
          (response: any) => {
            if (response && response.length > 0) {
              this.edit = true;
    
              if (this.IsAdmin) {
                this.selectdata =response.filter((assessment: any) => assessment.athlete == this.athleteId)
              } else {
                this.selectdata = response.filter((assessment: any) => assessment.athlete == this.athleteId && assessment.assessorId== this.UserId );
              }
    
              // Initialize formData object
              this.formData = {};
    
              // Loop through selectdata and assign values dynamically
              this.selectdata.forEach((assessment: any) => {
                const columnName = assessment.testColumnName;
                const columnValue = assessment.matchedColumnData;
                this.formData[columnName] = columnValue;
              });
    
              // Assign other necessary fields that might not come in dynamic data
              const data = this.selectdata[0]; // assuming all entries have the same base info
              this.formData['naId'] = data.assessmentId;
              this.formData['naDate'] = data.assessmentDate;
              this.formData['assessmentDate'] = data.assessmentDate ? data.assessmentDate.split('T')[0] : '';
              this.formData['naAssessor'] = data.assessorId;
              this.formData['naAthlete'] = data.athlete;
              this.formData['naAssessorName'] = data.assessorFullName || '';
              this.formData['naAthleteName'] = data.athleteFullName || '';
              this.formData['updateDate'] = data.updateDate ? data.updateDate.split('T')[0] : 'mm/dd/yyyy';
              
            } else {
              this.edit = false;
            }
          },
          (error: any) => {
            console.error("Error fetching Assessment data", error);
          }
        );
      }else{
        this.assessmentService.getNutritionalByTestAssessor(this.id).subscribe(
          (response: any) => {
            if (response && response.length > 0) {
              this.edit = true;
    
              if (this.IsAdmin) {
                this.selectdata =response.filter((assessment: any) => assessment.athlete == this.athleteId)
              } else {
                this.selectdata = response.filter((assessment: any) => assessment.athlete == this.athleteId && assessment.assessorId== this.UserId );
              }
    
              // Initialize formData object
              this.formData = {};
    
              // Loop through selectdata and assign values dynamically
              this.selectdata.forEach((assessment: any) => {
                const columnName = assessment.testColumnName;
                const columnValue = assessment.matchedColumnData;
                this.formData[columnName] = columnValue;
              });
    
              // Assign other necessary fields that might not come in dynamic data
              const data = this.selectdata[0]; // assuming all entries have the same base info
              this.formData['naId'] = data.assessmentId;
              this.formData['naDate'] = data.assessmentDate;
              this.formData['assessmentDate'] = data.assessmentDate ? data.assessmentDate.split('T')[0] : '';
              this.formData['naAssessor'] = data.assessorId;
              this.formData['naAthlete'] = data.athlete;
              this.formData['naAssessorName'] = data.assessorFullName || '';
              this.formData['naAthleteName'] = data.athleteFullName || '';
              this.formData['updateDate'] = data.updateDate ? data.updateDate.split('T')[0] : 'mm/dd/yyyy';
              
            } else {
              this.edit = false;
            }
          },
          (error: any) => {
            console.error("Error fetching Assessment data", error);
          }
        );
      }
      
    }
  }
  
  getfitness() {
    this.clearPreviousData()
    if (this.id && this.category == 4) {
      if(this.IsAdmin){
        this.assessmentService.getFitnessByTest(this.id).subscribe((response: any) => {
          if (response && response.length > 0) {
            this.edit=true;
            if (this.IsAdmin) {
              this.selectdata =response.filter((assessment: any) => assessment.athlete == this.athleteId)
            } else {
              this.selectdata = response.filter((assessment: any) => assessment.athlete == this.athleteId && assessment.assessorId== this.UserId );
            }
            this.formData = {};
    
            // Loop through selectdata and assign values dynamically
            this.selectdata.forEach((assessment: any) => {
              const columnName = assessment.testColumnName;
              const columnValue = assessment.matchedColumnData;
              this.formData[columnName] = columnValue;
            });
            const data =   this.selectdata[0];
            this.formData['pfaId'] = data.pfaId;
            this.formData['pfaAthlete'] = data.athlete;
            this.formData['assessmentDate'] = data.assessmentDate ? data.assessmentDate.split('T')[0] : '';
            this.formData['pfaDate'] = data.assessmentDate ? data.assessmentDate.split('T')[0] : '';
            this.formData['pfaAssessor'] = data.assessorId;
            this.formData['usrDoB'] = data.usrDoB ? data.usrDoB.split('T')[0] : '';
            this.formData['updateDate'] = data.updateDate ? data.updateDate.split('T')[0] : 'mm/dd/yyyy';
            this.formData['usrGender'] = data.usrGender;
            this.formData['athleteName'] = data.athleteName;
            this.formData['assessorName'] = data.assessorName;
            this.assessmentsData.push(data);
          } else{
            this.edit=false;
          }
          this.getProfileImage();
        },
          (error: any) => {
            console.error('Error fetching assessment data', error);
          }
        );
      }else{
        this.assessmentService.getFitnessByTestAssessor(this.id).subscribe((response: any) => {
          if (response && response.length > 0) {
            this.edit=true;
            if (this.IsAdmin) {
              this.selectdata =response.filter((assessment: any) => assessment.athlete == this.athleteId)
            } else {
              this.selectdata = response.filter((assessment: any) => assessment.athlete == this.athleteId && assessment.assessorId== this.UserId );
            }
            this.formData = {};
    
            // Loop through selectdata and assign values dynamically
            this.selectdata.forEach((assessment: any) => {
              const columnName = assessment.testColumnName;
              const columnValue = assessment.matchedColumnData;
              this.formData[columnName] = columnValue;
            });
            const data =   this.selectdata[0];
            this.formData['pfaId'] = data.pfaId;
            this.formData['pfaAthlete'] = data.athlete;
            this.formData['assessmentDate'] = data.assessmentDate ? data.assessmentDate.split('T')[0] : '';
            this.formData['pfaDate'] = data.assessmentDate ? data.assessmentDate.split('T')[0] : '';
            this.formData['pfaAssessor'] = data.assessorId;
            this.formData['usrDoB'] = data.usrDoB ? data.usrDoB.split('T')[0] : '';
            this.formData['updateDate'] = data.updateDate ? data.updateDate.split('T')[0] : 'mm/dd/yyyy';
            this.formData['usrGender'] = data.usrGender;
            this.formData['athleteName'] = data.athleteName;
            this.formData['assessorName'] = data.assessorName;
            this.assessmentsData.push(data);
          } else{
            this.edit=false;
          }
          this.getProfileImage();
        },
          (error: any) => {
            console.error('Error fetching assessment data', error);
          }
        );
      }
      
    }
  }
//   getCognitive() {
//     this.clearPreviousData();
//     if (this.id && this.category == 8) {
//         this.assessmentService.getCognitiveByTest(this.id).subscribe(
//             (response: any) => {
//                 if (response && response.length > 0) {
//                     this.edit = true;
//                     this.originalAssessments = [...response];
//                     if (this.IsAdmin) {
//                       this.selectdata =response.filter((assessment: any) => assessment.athlete == this.athleteId)
//                     } else {
//                       this.selectdata = response.filter((assessment: any) => assessment.athlete == this.athleteId && assessment.assessorId== this.UserId );
//                     }
//                     const data = this.selectdata[0];
//                     this.populateForm(data);
//                     this.assessmentsData.push(data);
//                 } else {
//                     this.edit = false;
//                 }

//                 this.getProfileImage();
//             },
//             (error: any) => {
//                 console.error('Error fetching assessment data', error);
//             }
//         );
//     }
// }
getCognitive() {
   
  this.clearPreviousData()
  if (this.id && this.category == 8) {
    if(this.IsAdmin){
    this.assessmentService.getCognitiveByTest(this.id).subscribe((response: any) => {
      if (response && response.length > 0) {
        this.edit=true;
        if (this.IsAdmin) {
          this.selectdata =response.filter((assessment: any) => assessment.athlete == this.athleteId)
        } else {
          this.selectdata = response.filter((assessment: any) => assessment.athlete == this.athleteId && assessment.assessorId== this.UserId );
        }
        this.formData = {};

        // Loop through selectdata and assign values dynamically
        this.selectdata.forEach((assessment: any) => {
          const columnName = assessment.testColumnName;
          const columnValue = assessment.matchedColumnData;
          this.formData[columnName] = columnValue;
        });
        const data =   this.selectdata[0];
        this.formData['cnaId'] = data.cnaId;
        this.formData['cnaAthlete'] = data.athlete;
        this.formData['assessmentDate'] = data.assessmentDate ? data.assessmentDate.split('T')[0] : '';
        this.formData['cnaAssessor'] = data.assessorId;
        this.formData['updateDate'] = data.updateDate ? data.updateDate.split('T')[0] : 'mm/dd/yyyy';
        this.assessmentsData.push(data);
      } else{
        this.edit=false;
      }
      this.getProfileImage();
    },
      (error: any) => {
        console.error('Error fetching assessment data', error);
      }
    );
  }else{
    this.assessmentService.getCognitiveByTestAssessor(this.id).subscribe((response: any) => {
      if (response && response.length > 0) {
        this.edit=true;
        if (this.IsAdmin) {
          this.selectdata =response.filter((assessment: any) => assessment.athlete == this.athleteId)
        } else {
          this.selectdata = response.filter((assessment: any) => assessment.athlete == this.athleteId && assessment.assessorId== this.UserId );
        }
        this.formData = {};

        // Loop through selectdata and assign values dynamically
        this.selectdata.forEach((assessment: any) => {
          const columnName = assessment.testColumnName;
          const columnValue = assessment.matchedColumnData;
          this.formData[columnName] = columnValue;
        });
        const data =   this.selectdata[0];
        this.formData['cnaId'] = data.cnaId;
        this.formData['cnaAthlete'] = data.athlete;
        this.formData['assessmentDate'] = data.assessmentDate ? data.assessmentDate.split('T')[0] : '';
        this.formData['cnaAssessor'] = data.assessorId;
        this.formData['updateDate'] = data.updateDate ? data.updateDate.split('T')[0] : 'mm/dd/yyyy';
        this.assessmentsData.push(data);
      } else{
        this.edit=false;
      }
      this.getProfileImage();
    },
      (error: any) => {
        console.error('Error fetching assessment data', error);
      }
    );
  }
  }
}
combineData(assessments: any[]): any[] {
  if (assessments.length < 2) {
      return assessments;
  }

  const combinedData: { [key: string]: any } = {};
  const keys = Object.keys(assessments[0]);

  keys.forEach((key) => {
      // Skip merging for specific fields
      if (key === 'cnaId' || key === 'cnaAssessor') return;

      // Filter out null values for the current key across all assessments
      const nonNullValues = assessments
          .map(assessment => assessment[key])
          .filter(value => value !== null);

      // If we have non-null values, take the last one
      if (nonNullValues.length > 0) {
          combinedData[key] = nonNullValues[nonNullValues.length - 1];
      } else {
          // If all values are null, set it to null
          combinedData[key] = null;
      }
  });

  return [combinedData];
}




populateForm(data: any) {
    this.formData['cnaId'] = data.cnaId;
    this.formData['cnaAthlete'] = data.cnaAthlete;
    this.formData['assessmentDate'] = data.assessmentDate ? data.assessmentDate.split('T')[0] : '';
    this.formData['assessorId'] = data.assessorId;
}

// Prepare data for submission, only updating fields that changed
splitCombinedData(combinedData: any) {
  return this.originalAssessments.map((original) => {
    const updatedData = { ...original };
    for (let key in combinedData) {
      if (combinedData.hasOwnProperty(key)) {
        // Update if combinedData[key] is different from original[key]
        if (combinedData[key] !== original[key]) {
          updatedData[key] = combinedData[key];
        }
      }
    }
    return updatedData;
  });
}
formIsValid(): boolean {
  // Add your form validation logic here
  return true; // Placeholder, replace with actual validation
}
// Function to send updated assessments to the database
updateAssessmentsInDatabase(updatedAssessments: any[]) {
  updatedAssessments.forEach(assessment => {
    console.log(assessment, "assessment");
    // this.assessmentService.updateCognitiveAssessment(assessment).subscribe(
    //   (response: any) => {
    //     console.log('Assessment updated successfully', response);
    //   },
    //   (error: any) => {
    //     console.error('Error updating assessment', error);
    //   }
    // );
  });
}

  GetLevelOne() {
    this.clearPreviousData()
    this.assessmentService.getLevelOneByAsm(this.athleteId, this.id).subscribe((response: any) => {
      if (response && response.length > 0) {
        this.edit=true;
        if (this.IsAdmin) {
          this.selectdata = response.filter((assessment: any) => assessment.loaAssessor == this.assesserId);
        } else {
          this.selectdata = response.filter((assessment: any) => assessment.loaAssessor === this.UserId);
        }
        const data =   this.selectdata[0];
        this.formData['loaId'] = data.loaId;
        this.formData['loaName'] = data.loaName;
        this.formData['loaAthlete'] = data.loaAthlete;
        this.formData['loaRegisterNumber'] = data.loaRegisterNumber;
        this.formData['aggAge'] = data.aggAge;
        this.formData['sportName'] = data.sportName;
        this.formData['aggGender'] = data.aggGender;
        this.formData['genderName'] = data.genderName;
        this.formData['loaHeight'] = data.loaHeight;
        this.formData['loaWeight'] = data.loaWeight;
        this.formData['loaRulerDrop'] = data.loaRulerDrop;
        this.formData['loaHandGrip'] = data.loaHandGrip;
        this.formData['loaSeatedMedBall'] = data.loaSeatedMedBall;
        this.formData['loaSitnReach'] = data.loaSitnReach;
        this.formData['loaVerticalJump'] = data.loaVerticalJump;
        this.formData['loaWallToss'] = data.loaWallToss;
        this.formData['loa30mSprint'] = data.loa30mSprint;
        this.formData['loa6x10ShuttleRun'] = data.loa6x10ShuttleRun;
        this.formData['loa800mRun'] = data.loa800mRun;
        this.formData['loaAsessmentDate'] = data.loaAsessmentDate ? data.loaAsessmentDate.split('T')[0] : '';
        this.formData['assessmentDate'] = data.loaAsessmentDate ? data.loaAsessmentDate.split('T')[0] : '';
      }
      else{
        this.edit=false;
      }
    },
      (error: any) => {
        console.error('Error fetching assessment data', error);
      }
    );
 
  }
  getSportsPerformanceAnalysis(){
    this.clearPreviousData()
    if(this.id && this.category == 11){
      if(this.IsAdmin){
        this.assessmentService.getSportsPerformanceByTest(this.id).subscribe((response: any) => {
          if (response && response.length > 0) {
            this.edit=true;
            if (this.IsAdmin) {
              this.selectdata =response.filter((assessment: any) => assessment.athlete == this.athleteId)
            } else {
              this.selectdata = response.filter((assessment: any) => assessment.athlete == this.athleteId && assessment.assessorId== this.UserId );
            }
            this.formData = {};
      
            // Loop through selectdata and assign values dynamically
            this.selectdata.forEach((assessment: any) => {
              const columnName = assessment.testColumnName;
              const columnValue = assessment.matchedColumnData;
              this.formData[columnName] = columnValue;
            });
            const data =   this.selectdata[0];
            this.formData['spaId']= data.spaId;
            this.formData['spaAthlete']= data.athlete;
            this.formData['spaAssessor'] = data.assessorId;
            this.formData['spaAssessment'] = data.assessmentId;
            this.formData['spaDate'] = data.assessmentDate ; // Convert date format
            this.formData['assessmentDate']= data.assessmentDate ? data.assessmentDate.split('T')[0] : '';
            this.formData['updateDate'] = data.updateDate ? data.updateDate.split('T')[0] : 'mm/dd/yyyy';
            this.assessmentsData.push(data);
          }
          else{
            this.edit=false;
          }
          this.getProfileImage() ;
        },
        (error: any) => {
          console.error('Error fetching assessment data', error);
        }
      );
      
      }else{
        this.assessmentService.getSportsPerformanceByTestAssessor(this.id).subscribe((response: any) => {
          if (response && response.length > 0) {
            this.edit=true;
            if (this.IsAdmin) {
              this.selectdata =response.filter((assessment: any) => assessment.athlete == this.athleteId)
            } else {
              this.selectdata = response.filter((assessment: any) => assessment.athlete == this.athleteId && assessment.assessorId== this.UserId );
            }
            this.formData = {};
      
            // Loop through selectdata and assign values dynamically
            this.selectdata.forEach((assessment: any) => {
              const columnName = assessment.testColumnName;
              const columnValue = assessment.matchedColumnData;
              this.formData[columnName] = columnValue;
            });
            const data =   this.selectdata[0];
            this.formData['spaId']= data.spaId;
            this.formData['spaAthlete']= data.spaAthlete;
            this.formData['spaAssessor'] = data.spaAssessor;
            this.formData['spaAssessment'] = data.spaAssessment;
            this.formData['spaDate'] = data.assessmentDate ; // Convert date format
            this.formData['assessmentDate']= data.assessmentDate ? data.assessmentDate.split('T')[0] : '';
            this.formData['updateDate'] = data.updateDate ? data.updateDate.split('T')[0] : 'mm/dd/yyyy';
            this.assessmentsData.push(data);
          }
          else{
            this.edit=false;
          }
          this.getProfileImage() ;
        },
        (error: any) => {
          console.error('Error fetching assessment data', error);
        }
      );
      
      }
    }
  }
  getinjuryanalysis() {
    this.clearPreviousData()
    if (this.id && this.category == 10) {
      if(this.IsAdmin){
        this.assessmentService.getInjuryByTest(this.id).subscribe((response: any) => {
          if (response && response.length > 0) {
            this.edit=true;
         
            if (this.IsAdmin) {
              this.selectdata =response.filter((assessment: any) => assessment.athlete == this.athleteId)
            } else {
              this.selectdata = response.filter((assessment: any) => assessment.athlete == this.athleteId);
            }
           
            this.formData = {};
    
            // Loop through selectdata and assign values dynamically
            this.selectdata.forEach((assessment: any) => {
              const columnName = assessment.testColumnName;
              const columnValue = assessment.matchedColumnData;
              this.formData[columnName] = columnValue;
            });
            const data =   this.selectdata[0];
            this.formData['ipaId'] = data.ipaId;
            this.formData['ipaAthlete'] = data.athlete;
            this.formData['ipaAssessor'] = data.assessorId;
            this.formData['athleteName']=data.athleteName;
            this.formData['ipaAssessmentDate'] = data.assessmentDate ; // Convert date format
            this.formData['assessmentDate'] = data.assessmentDate ? data.assessmentDate.split('T')[0] : '';
            this.formData['updateDate'] = data.updateDate ? data.updateDate.split('T')[0] : 'mm/dd/yyyy';
            this.formData['ipaTotalScore'] = data.ipaTotalScore;
            this.assessmentsData.push(data);
          } else{
            this.edit=false;
          }
          this.getProfileImage();
        },
          (error: any) => {
           
            console.error('Error fetching assessment data', error);
          }
        );
      }else{
        this.assessmentService.getInjuryByTestAssessor(this.id).subscribe((response: any) => {
          if (response && response.length > 0) {
            this.edit=true;
         
            if (this.IsAdmin) {
              this.selectdata =response.filter((assessment: any) => assessment.athlete == this.athleteId)
            } else {
              this.selectdata = response.filter((assessment: any) => assessment.athlete == this.athleteId && assessment.assessorId== this.UserId );
            }
           
            this.formData = {};
    
            // Loop through selectdata and assign values dynamically
            this.selectdata.forEach((assessment: any) => {
              const columnName = assessment.testColumnName;
              const columnValue = assessment.matchedColumnData;
              this.formData[columnName] = columnValue;
            });
            const data =   this.selectdata[0];
            this.formData['ipaId'] = data.ipaId;
            this.formData['ipaAthlete'] = data.athlete;
            this.formData['ipaAssessor'] = data.assessorId;
            this.formData['athleteName']=data.athleteName;
            this.formData['ipaAssessmentDate'] = data.assessmentDate ; // Convert date format
            this.formData['assessmentDate'] = data.assessmentDate ? data.assessmentDate.split('T')[0] : '';
            this.formData['updateDate'] = data.updateDate ? data.updateDate.split('T')[0] : 'mm/dd/yyyy';
            this.formData['ipaTotalScore'] = data.ipaTotalScore;
            this.assessmentsData.push(data);
          } else{
            this.edit=false;
          }
          this.getProfileImage();
        },
          (error: any) => {
           
            console.error('Error fetching assessment data', error);
          }
        );
      }
      
    }
  }
 
  checkUserSummary() {
    this.assessmentService.checkUserSummary(this.athleteId, this.id).subscribe((response: any) => {
      if (!response) {
        // this.getCognitive();
        // this.getfitness();
        // this.getCardiology();
        // this.sendMessage() ;
      } else {
        this.getAllAssessmentData()
      }
 
    }, error => {
 
      console.error('Error getting details ', error);
    });
  }
  onAthleteChange(event: any) {
    const selectedValue = event.target.value;
    const selectedPlayer = this.playerList.find(player => player.aplPlayer === selectedValue);
    if (selectedPlayer) {
      this.id = selectedPlayer.aplAssessment;
      this.athleteId =selectedPlayer.aplPlayer;
      this.clearProfileImage();
      this.clearPreviousData();
      this.getProfileImage();
      this.getCardiology()
      this.getfitness()
      this.getCognitive()
      this.GetLevelOne()
      this.getinjuryanalysis()
      this. GetAthelete()
      this.getSportsPerformanceAnalysis()
      this.GetPlayer();
 
      };
    }
    clearPreviousData() {
   
      this.formData = {};
      this.selectdata = [];
      this.assessmentsData = []; 
   
  }
  clearProfileImage() {
    this.ImageUrl = null;   // Remove previous image URL
    this.showImage = false; // Hide image
}
  sendMessage() {
  //   const assessmentDataJson = JSON.stringify(this.jsonData);
  //   console.log(assessmentDataJson, "full data")
  //   const message = "in this data analyse and give me the overall summery of his performence without mention mark in three lines in one paragraph";
  //   const combinedMessage = `
  //   {
  //     "data": ${assessmentDataJson},
  //     "message": "${message}"
  //   }
  // `;
  //   console.log(combinedMessage, "message")
  //   this.chatService.getChatResponse(combinedMessage).subscribe(async chatResponse => {
  //     const chatContent = chatResponse?.choices?.[0]?.message?.content;
  //     // this.chat = chatContent;
  //     // console.log(this.chat, "chat")
  //     if (chatContent) {
 
  //       try {
  //         this.chat = chatContent;
  //         console.log(this.chat, "chat")
  //         this.getActionPlan();
  //       } catch (error) {
  //         this.chat = chatContent;
  //         console.error('Error parsing chat response JSON:', error);
  //       }
  //     } else {
  //       console.error('Unexpected chat response structure:', chatResponse);
  //     }
 
  //   });
  }
  getActionPlan() {
  //   const assessmentDataJson = JSON.stringify(this.jsonData);
  //   console.log(assessmentDataJson, "full data")
  //   const message = "in this data analyse and give me the overall Key insights on injury assessments for the player based on provided data including isokinectic assessments And key insights and performance comparisons for the player based on the provided data without mention mark in three lines in one paragraph";
  //   const combinedMessage = `
  //   {
  //     "data": ${assessmentDataJson},
  //     "message": "${message}"
  //   }
  // `;
  //   console.log(combinedMessage, "message")
  //   this.chatService.getChatResponse(combinedMessage).subscribe(async chatResponse => {
  //     const chatContent = chatResponse?.choices?.[0]?.message?.content;
  //     // this.chat = chatContent;
  //     // console.log(this.chat, "chat")
  //     if (chatContent) {
 
  //       try {
  //         this.Plan = chatContent;
  //         console.log(this.Plan, "plan")
  //         this.saveSummary();
  //       } catch (error) {
  //         this.chat = chatContent;
  //         console.error('Error parsing chat response JSON:', error);
  //       }
  //     } else {
  //       console.error('Unexpected chat response structure:', chatResponse);
  //     }
 
  //   });
  }
  saveSummary() {
    this.summary.ausAssessment = this.id;
    this.summary.ausAthlete = this.athleteId;
    this.summary.ausSummary = this.chat;
    this.summary.ausActionPlan = this.Plan;
    this.assessmentService.saveSummary([this.summary]).subscribe(response => {
      this.notify.success("Form submitted successfully");
      setTimeout(() => {
        this.router.navigate(['pendingform']);
      }, 1000);
    }, error => {
      console.error('Error submitting assessment', error);
 
    });
  }
  getAllAssessmentData() {
    forkJoin({
      fitnessData: this.assessmentService.getFitnessByAsm(this.athleteId, this.id),
      cognitiveData: this.assessmentService.getCognitiveByAsm(this.athleteId, this.id),
      injuryPreventionData: this.assessmentService.getInjuryByAsm(this.athleteId, this.id),
      nutritionData: this.assessmentService.getNutritionByAsm(this.athleteId, this.id),
      sportsPerformanceData: this.assessmentService.getSportsperformanceByAsm(this.athleteId, this.id),
      cardioData: this.assessmentService.getCardioByAsm(this.athleteId, this.id)
    }).subscribe(
      (result: any) => {
        const formattedData = this.formatAssessmentData(result);
        this.jsonData=formattedData;
        this.sendMessage();
      },
      (error) => {
        console.error('Error fetching data:', error);
      }
    );
  }

  formatAssessmentData(data: any) {
    return {
      fitness: data.fitnessData,         
      cognitive: data.cognitiveData,  
      injuryPrevention: data.injuryPreventionData,
      nutrition: data.nutritionData, 
      sportsPerformance: data.sportsPerformanceData, 
      cardio: data.cardioData 
    };
  }
}