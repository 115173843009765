<div class="container" [ngClass]="{'secActive': formSubmitted}">
  <header>Physical Fitness</header><br>
  <form (ngSubmit)="onSubmit()" #assessmentForm="ngForm">
    <div class="form first">
      <div class="details personal">
        <img *ngIf="showImage && ImageUrl" src="{{this.ImageUrl.changingThisBreaksApplicationSecurity}}">
        <div class="details">
          <span class="title">Player Details</span>
          <div class="fields">
            <div class="input-field">
              <label>Player Id</label>
              <input type="number" placeholder="Enter Player Id" required [(ngModel)]="assessment.pfaAthlete"
                name="pfaAthlete" #pfaAthlete="ngModel" (blur)="getProfileImage()" [ngClass]="{
                  'invalid-field': assessmentForm.submitted && pfaAthlete.invalid,
                  'has-value': assessment.pfaAthlete
                }">
              <div *ngIf="assessmentForm.submitted && pfaAthlete.invalid" class="error-message">
                <!-- Speed Test error message -->
              </div>
            </div>
            <div class="input-field">
              <label>Date Of Assessment</label>
              <input type="date" placeholder="Enter Date Of Assessment" required [(ngModel)]="assessment.pfaDate"
                name="pfaDate" #pfaDate="ngModel" [ngClass]="{
                  'invalid-field': assessmentForm.submitted && pfaDate.invalid,
                  'has-value': assessment.pfaDate
                }">
              <div *ngIf="assessmentForm.submitted && pfaDate.invalid" class="error-message">
                <!-- Agility Test error message -->
              </div>
            </div>

          </div>
        </div>
        <br>
        <div class="details">
          <span class="title">Anthropometry</span>
          <div class="fields">
            <div class="input-field">
              <label>Height <span>(Cm)</span></label>
              <input type="number" placeholder="Enter Height" required [(ngModel)]="assessment.pfaHeight"
                name="pfaHeight" #pfaHeight="ngModel" [ngClass]="{
                  'invalid-field': assessmentForm.submitted && pfaHeight.invalid,
                  'has-value': assessment.pfaHeight
                }">
              <div *ngIf="assessmentForm.submitted && pfaHeight.invalid" class="error-message">
                <!-- Speed Test error message -->
              </div>
            </div>
            <div class="input-field">
              <label>Weight <span>(Kgs)</span></label>
              <input type="number" placeholder="Enter Weight" required [(ngModel)]="assessment.pfaWeight"
                name="pfaWeight" #pfaWeight="ngModel" [ngClass]="{
                  'invalid-field': assessmentForm.submitted && pfaWeight.invalid,
                  'has-value': assessment.pfaWeight
                }">
              <div *ngIf="assessmentForm.submitted && pfaWeight.invalid" class="error-message">
                <!-- Agility Test error message -->
              </div>
            </div>
            <div class="input-field">
              <label>BMI <span>(Value)</span></label>
              <input type="number" placeholder="Enter BMI Test Results" required [(ngModel)]="assessment.pfaBMI"
                name="pfaBMI" #pfaBMI="ngModel" [ngClass]="{
                  'invalid-field': assessmentForm.submitted && pfaBMI.invalid,
                  'has-value': assessment.pfaBMI
                }">
              <div *ngIf="assessmentForm.submitted && pfaBMI.invalid" class="error-message">
                <!-- Endurance Test error message -->
              </div>
            </div>
          </div>
        </div>
        <div class="details">
          <span class="title">Balance Test</span>
          <div class="fields">
            <div class="input-field">
              <label>Single Leg Balance (eyes open)</label>
              <input type="number" placeholder="Enter Single Leg Balance (eyes open)" required
                [(ngModel)]="assessment.pfaSingleLegBalanceEyesOpen" name="pfaSingleLegBalanceEyesOpen"
                #pfaSingleLegBalanceEyesOpen="ngModel" [ngClass]="{
                  'invalid-field': assessmentForm.submitted && pfaSingleLegBalanceEyesOpen.invalid,
                  'has-value': assessment.pfaSingleLegBalanceEyesOpen
                }">
              <div *ngIf="assessmentForm.submitted && pfaSingleLegBalanceEyesOpen.invalid" class="error-message">
                <!-- Speed Test error message -->
              </div>
            </div>
            <div class="input-field">
              <label>Single Leg Balance (eyes closed)</label>
              <input type="number" placeholder="Enter Single Leg Balance (eyes closed)" required
                [(ngModel)]="assessment.pfaSingleLegBalanceEyesClose" name="pfaSingleLegBalanceEyesClose"
                #pfaSingleLegBalanceEyesClose="ngModel" [ngClass]="{
                  'invalid-field': assessmentForm.submitted && pfaSingleLegBalanceEyesClose.invalid,
                  'has-value': assessment.pfaSingleLegBalanceEyesClose
                }">
              <div *ngIf="assessmentForm.submitted && pfaSingleLegBalanceEyesClose.invalid" class="error-message">
                <!-- Agility Test error message -->
              </div>
            </div>
          </div>
        </div>

        <div class="details">
          <div class="fields">
            <div class="input-field">
              <label>Y-Balance Test-1 <span>(Cm)</span></label>
              <input type="number" placeholder="Enter pfaYBalanceTest1" required
                [(ngModel)]="assessment.pfaYBalanceTest1" name="pfaYBalanceTest1" #pfaYBalanceTest1="ngModel" [ngClass]="{
                  'invalid-field': assessmentForm.submitted && pfaYBalanceTest1.invalid,
                  'has-value': assessment.pfaYBalanceTest1
                }">
              <div *ngIf="assessmentForm.submitted && pfaYBalanceTest1.invalid" class="error-message">
                <!-- Speed Test error message -->
              </div>
            </div>
            <div class="input-field">
              <label>Y-Balance Test-2 <span>(Cm)</span></label>
              <input type="number" placeholder="Enter Y-Balance Test-2" required
                [(ngModel)]="assessment.pfaYBalanceTest2" name="pfaYBalanceTest2" #pfaYBalanceTest2="ngModel" [ngClass]="{
                  'invalid-field': assessmentForm.submitted && pfaYBalanceTest2.invalid,
                  'has-value': assessment.pfaYBalanceTest2
                }">
              <div *ngIf="assessmentForm.submitted && pfaYBalanceTest2.invalid" class="error-message">
                <!-- Agility Test error message -->
              </div>
            </div>
            <div class="input-field">
              <label>Y-Balance Test-3 <span>(Cm)</span></label>
              <input type="number" placeholder="Enter Y-Balance Test-3" required
                [(ngModel)]="assessment.pfaYBalanceTest3" name="Y-Balance Test-3" #pfaYBalanceTest3="ngModel" [ngClass]="{
                  'invalid-field': assessmentForm.submitted && pfaYBalanceTest3.invalid,
                  'has-value': assessment.pfaYBalanceTest3
                }">
              <div *ngIf="assessmentForm.submitted && pfaYBalanceTest3.invalid" class="error-message">
                <!-- Endurance Test error message -->
              </div>
            </div>
            <div class="input-field">
              <label>Star Excursion Test-1 <span>(Cm)</span></label>
              <input type="number" placeholder="Enter pfaStarExcursionTest1" required
                [(ngModel)]="assessment.pfaStarExcursionTest1" name="pfaStarExcursionTest1"
                #pfaStarExcursionTest1="ngModel" [ngClass]="{
                  'invalid-field': assessmentForm.submitted && pfaStarExcursionTest1.invalid,
                  'has-value': assessment.pfaStarExcursionTest1
                }">
              <div *ngIf="assessmentForm.submitted && pfaStarExcursionTest1.invalid" class="error-message">
                <!-- Endurance Test error message -->
              </div>
            </div>
            <div class="input-field">
              <label>Star Excursion Test-2 <span>(Cm)</span></label>
              <input type="number" placeholder="Enter pfaStarExcursionTest2" required
                [(ngModel)]="assessment.pfaStarExcursionTest2" name="pfaStarExcursionTest2"
                #pfaStarExcursionTest2="ngModel" [ngClass]="{
                  'invalid-field': assessmentForm.submitted && pfaStarExcursionTest2.invalid,
                  'has-value': assessment.pfaStarExcursionTest2
                }">
              <div *ngIf="assessmentForm.submitted && pfaStarExcursionTest2.invalid" class="error-message">
                <!-- Endurance Test error message -->
              </div>
            </div>
            <div class="input-field">
              <label> Star Excursion Test-3 <span>(Cm)</span></label>
              <input type="number" placeholder="Enter Star Excursion Test-3 " required
                [(ngModel)]="assessment.pfaStarExcursionTest3" name="pfaStarExcursionTest3"
                #pfaStarExcursionTest3="ngModel" [ngClass]="{
                  'invalid-field': assessmentForm.submitted && pfaStarExcursionTest3.invalid,
                  'has-value': assessment.pfaStarExcursionTest3
                }">
              <div *ngIf="assessmentForm.submitted && pfaStarExcursionTest3.invalid" class="error-message">
                <!-- Endurance Test error message -->
              </div>
            </div>
          </div>
        </div>
        <div class="details">
          <span class="title">Strength Test-Upper Body</span>
          <div class="fields">
            <div class="input-field">
              <label>Push Ups</label>
              <input type="number" placeholder="Enter Push Ups" required [(ngModel)]="assessment.pfaPushups"
                name="pfaPushups" #pfaPushups="ngModel" [ngClass]="{
                  'invalid-field': assessmentForm.submitted && pfaPushups.invalid,
                  'has-value': assessment.pfaPushups
                }">
              <div *ngIf="assessmentForm.submitted && pfaPushups.invalid" class="error-message">
                <!-- Speed Test error message -->
              </div>
            </div>
            <div class="input-field">
              <label>Pull Ups</label>
              <input type="number" placeholder="Enter Pull Ups" required [(ngModel)]="assessment.pfaPullups"
                name="pfaPullups" #pfaPullups="ngModel" [ngClass]="{
                  'invalid-field': assessmentForm.submitted && pfaPullups.invalid,
                  'has-value': assessment.pfaPullups
                }">
              <div *ngIf="assessmentForm.submitted && pfaPullups.invalid" class="error-message">
                <!-- Agility Test error message -->
              </div>
            </div>
            <div class="input-field">
              <label>Pull and Hang</label>
              <input type="number" placeholder="Enter Pull and Hang Tests" required
                [(ngModel)]="assessment.pfaPullAndHang" name="pfaPullAndHang" #pfaPullAndHang="ngModel" [ngClass]="{
                  'invalid-field': assessmentForm.submitted && pfaPullAndHang.invalid,
                  'has-value': assessment.pfaPullAndHang
                }">
              <div *ngIf="assessmentForm.submitted && pfaPullAndHang.invalid" class="error-message">
                <!-- Endurance Test error message -->
              </div>
            </div>
          </div>
        </div>
        <br>
        <div class="details">
          <span class="title">Strength Test-Lower Body</span>
          <div class="fields">
            <div class="input-field">
              <label>Squat</label>
              <input type="number" placeholder="Enter Squat Results" required [(ngModel)]="assessment.pfaSquat"
                name="pfaSquat" #pfaSquat="ngModel" [ngClass]="{
                  'invalid-field': assessmentForm.submitted && pfaSquat.invalid,
                  'has-value': assessment.pfaSquat
                }">
              <div *ngIf="assessmentForm.submitted && pfaSquat.invalid" class="error-message">
                <!-- Speed Test error message -->
              </div>
            </div>
            <div class="input-field">
              <label>Single Leg Squad</label>
              <input type="number" placeholder="Enter Single Leg Squad " required
                [(ngModel)]="assessment.pfaSingleLegSquat" name="pfaSingleLegSquat" #pfaSingleLegSquat="ngModel"
                [ngClass]="{
                  'invalid-field': assessmentForm.submitted && pfaSingleLegSquat.invalid,
                  'has-value': assessment.pfaSingleLegSquat
                }">
              <div *ngIf="assessmentForm.submitted && pfaSingleLegSquat.invalid" class="error-message">
                <!-- Agility Test error message -->
              </div>
            </div>
          </div>
        </div>
        <br>

        <div class="details">
          <span class="title">Abdominal/Curl Strength</span>
          <div class="fields">
            <div class="input-field">
              <label>Partial Curl-up</label>
              <input type="number" placeholder="Enter Partial Curl-up Tests" required
                [(ngModel)]="assessment.pfaPartialCurlUp" name="pfaPartialCurlUp" #pfaPartialCurlUp="ngModel" [ngClass]="{
                  'invalid-field': assessmentForm.submitted && pfaPartialCurlUp.invalid,
                  'has-value': assessment.pfaPartialCurlUp
                }">
              <div *ngIf="assessmentForm.submitted && pfaPartialCurlUp.invalid" class="error-message">
                <!-- Speed Test error message -->
              </div>
            </div>
          </div>
        </div>


        <div class="details">
          <span class="title">Grip Strength </span>
          <div class="fields">
            <div class="input-field">
              <label>Right <span>(Kgs)</span></label>
              <input type="number" placeholder="Enter Grip Strength Right" required
                [(ngModel)]="assessment.pfaGripStrengthRight" name="pfaGripStrengthRight"
                #pfaGripStrengthRight="ngModel" [ngClass]="{
                  'invalid-field': assessmentForm.submitted && pfaGripStrengthRight.invalid,
                  'has-value': assessment.pfaGripStrengthRight
                }">
              <div *ngIf="assessmentForm.submitted && pfaGripStrengthRight.invalid" class="error-message">
                <!-- Speed Test error message -->
              </div>
            </div>

            <div class="input-field">
              <label>Left <span>(Kgs)</span></label>
              <input type="number" placeholder="Enter Grip Strength Left" required
                [(ngModel)]="assessment.pfaGripStrengthLeft" name="pfaGripStrengthLeft" #pfaGripStrengthLeft="ngModel"
                [ngClass]="{
                  'invalid-field': assessmentForm.submitted && pfaGripStrengthLeft.invalid,
                  'has-value': assessment.pfapfaGripStrengthLeft
                }">
              <div *ngIf="assessmentForm.submitted && pfaGripStrengthLeft.invalid" class="error-message">
                <!-- Speed Test error message -->
              </div>
            </div>
          </div>
        </div>


        <div class="details">
          <span class="title">Flexibility Test</span>
          <div class="fields">
            <div class="input-field">
              <label>Sit n Reach <span>(Cm)</span></label>
              <input type="number" placeholder="Enter Sit n Reach " required [(ngModel)]="assessment.pfaSitNReach"
                name="pfaSitNReach" #pfaSitNReach="ngModel" [ngClass]="{
                  'invalid-field': assessmentForm.submitted && pfaSitNReach.invalid,
                  'has-value': assessment.pfaSitNReach
                }">
              <div *ngIf="assessmentForm.submitted && pfaSitNReach.invalid" class="error-message">
              </div>
            </div>

            <div class="input-field">
              <label>Appleys Scratch Test <span>(Cm)</span></label>
              <input type="number" placeholder="Enter Speed Test Results" required
                [(ngModel)]="assessment.pfaAppleysScratchTest" name="pfaAppleysScratchTest"
                #pfaAppleysScratchTest="ngModel" [ngClass]="{
                  'invalid-field': assessmentForm.submitted && pfaAppleysScratchTest.invalid,
                  'has-value': assessment.pfaAppleysScratchTest
                }">
              <div *ngIf="assessmentForm.submitted && pfaAppleysScratchTest.invalid" class="error-message">
                <!-- Speed Test error message -->
              </div>
            </div>  
          </div>
        </div>

        <div class="details">
          <span class="title">Speed</span>
          <div class="fields">
            <div class="input-field">
              <label>50m Sprint <span>(Sec)</span></label>
              <input type="number" placeholder="Enter 50m Sprint" required
                [(ngModel)]="assessment.pfa50mSprint" name="pfa50mSprint"
                #pfa50mSprint="ngModel" [ngClass]="{
                  'invalid-field': assessmentForm.submitted && pfa50mSprint.invalid,
                  'has-value': assessment.pfa50mSprint
                }">
              <div *ngIf="assessmentForm.submitted && pfa50mSprint.invalid" class="error-message">
                <!-- Speed Test error message -->
              </div>
            </div>

            <div class="input-field">
              <label>30m Sprint <span>(Sec)</span></label>
              <input type="number" placeholder="Enter 30m Sprint" required
                [(ngModel)]="assessment.pfa30mSprint" name="pfa30mSprint" #pfa30mSprint="ngModel"
                [ngClass]="{
                  'invalid-field': assessmentForm.submitted && pfa30mSprint.invalid,
                  'has-value': assessment.pfapfa30mSprint
                }">
              <div *ngIf="assessmentForm.submitted && pfa30mSprint.invalid" class="error-message">
                <!-- Speed Test error message -->
              </div>
            </div>
          </div>
        </div>
        <div class="details">
          <span class="title">Agility</span>
          <div class="fields">
            <div class="input-field">
              <label>6*10m ShuttleRun <span>(Sec)</span></label>
              <input type="number" placeholder="Enter 6*10m ShuttleRun" required
                [(ngModel)]="assessment.pfa10mShuttleRun" name="pfa10mShuttleRun"
                #pfa10mShuttleRun="ngModel" [ngClass]="{
                  'invalid-field': assessmentForm.submitted && pfa10mShuttleRun.invalid,
                  'has-value': assessment.pfa10mShuttleRun
                }">
              <div *ngIf="assessmentForm.submitted && pfa10mShuttleRun.invalid" class="error-message">
                <!-- Speed Test error message -->
              </div>
            </div>

            <div class="input-field">
              <label>T-Test <span>(Sec)</span></label>
              <input type="number" placeholder="Enter T-Test" required
                [(ngModel)]="assessment.pfaTTest" name="pfaTTest" #pfaTTest="ngModel"
                [ngClass]="{
                  'invalid-field': assessmentForm.submitted && pfaTTest.invalid,
                  'has-value': assessment.pfapfaTTest
                }">
              <div *ngIf="assessmentForm.submitted && pfaTTest.invalid" class="error-message">
                <!-- Speed Test error message -->
              </div>
            </div>
          </div>
        </div>
        <div class="details">
          <span class="title">Lower Body Power</span>
          <div class="fields">
            <div class="input-field">
              <label>Standing BroadJump <span>(feet)</span></label>
              <input type="number" placeholder="Enter Standing BroadJump" required
                [(ngModel)]="assessment.pfaStandingBroadJump" name="pfaStandingBroadJump"
                #pfaStandingBroadJump="ngModel" [ngClass]="{
                  'invalid-field': assessmentForm.submitted && pfaStandingBroadJump.invalid,
                  'has-value': assessment.pfaStandingBroadJump
                }">
              <div *ngIf="assessmentForm.submitted && pfaStandingBroadJump.invalid" class="error-message">
                <!-- Speed Test error message -->
              </div>
            </div>

            <div class="input-field">
              <label>Vertical Jump <span>(Cm)</span></label>
              <input type="number" placeholder="Enter Vertical Jump" required
                [(ngModel)]="assessment.pfaVerticalJump" name="pfaVerticalJump" #pfaVerticalJump="ngModel"
                [ngClass]="{
                  'invalid-field': assessmentForm.submitted && pfaVerticalJump.invalid,
                  'has-value': assessment.pfapfaVerticalJump
                }">
              <div *ngIf="assessmentForm.submitted && pfaVerticalJump.invalid" class="error-message">
                <!-- Speed Test error message -->
              </div>
            </div>
          </div>
        </div>
        <div class="details">
          <span class="title">Upper Body Power</span>
          <div class="fields">
            <div class="input-field">
              <label>Seated MedBall Throw <span>(Meter)</span></label>
              <input type="number" placeholder="Enter Seated MedBall Throw" required
                [(ngModel)]="assessment.pfaSeatedMedBallThrow" name="pfaSeatedMedBallThrow"
                #pfaSeatedMedBallThrow="ngModel" [ngClass]="{
                  'invalid-field': assessmentForm.submitted && pfaSeatedMedBallThrow.invalid,
                  'has-value': assessment.pfaSeatedMedBallThrow
                }">
              <div *ngIf="assessmentForm.submitted && pfaSeatedMedBallThrow.invalid" class="error-message">
                <!-- Speed Test error message -->
              </div>
            </div>

            <div class="input-field">
              <label>FollowUp Date</label>
              <input type="date" placeholder="Enter Date Of Assessment" required
                [(ngModel)]="assessment.pfaFollowUpDate" name="pfaFollowUpDate" #pfaFollowUpDate="ngModel" [ngClass]="{
                  'invalid-field': assessmentForm.submitted && pfaFollowUpDate.invalid,
                  'has-value': assessment.pfaFollowUpDate
                }">
              <div *ngIf="assessmentForm.submitted && pfaFollowUpDate.invalid" class="error-message">
                <!-- Agility Test error message -->
              </div>
            </div>
          </div>
        </div>
        <!--
        <div class="fields">
          <div class="input-field">
            <label>Interpretation of Results</label>
            <textarea
              type="text"
              placeholder="Enter Interpretation of Results"
              required
              [(ngModel)]="assessment.pfaInterpretation"
              name="pfaInterpretation"
              #pfaInterpretation="ngModel"
              [ngClass]="{
                'invalid-field': assessmentForm.submitted && pfaInterpretation.invalid,
                'has-value': assessment.pfaInterpretation
              }"
            ></textarea>
            <div *ngIf="assessmentForm.submitted && pfaInterpretation.invalid" class="error-message">
 
            </div>
          </div>
          <div class="input-field">
            <label>Recommendations</label>
            <textarea
              type="text"
              placeholder="Enter Recommendations"
              required
              [(ngModel)]="assessment.pfaRecommendations"
              name="pfaRecommendations"
              #pfaRecommendations="ngModel"
              [ngClass]="{
                'invalid-field': assessmentForm.submitted && pfaRecommendations.invalid,
                'has-value': assessment.pfaRecommendations
              }"
            ></textarea>
            <div *ngIf="assessmentForm.submitted && pfaRecommendations.invalid" class="error-message">
            </div>
          </div>
          <div class="input-field">
            <label>Follow-up Date</label>
            <input
              type="date"
              placeholder="Enter Follow-up Date"
              required
              [(ngModel)]="assessment.pfaFollowUpDate"
              name="pfaFollowUpDate"
              #pfaFollowUpDate="ngModel"
              [ngClass]="{
                'invalid-field': assessmentForm.submitted && pfaFollowUpDate.invalid,
                'has-value': assessment.pfaFollowUpDate
              }"
            >
            <div *ngIf="assessmentForm.submitted && pfaFollowUpDate.invalid" class="error-message">
           
            </div>
          </div>
        </div> -->
        <div class="buttons">
          <button type="submit" class="submit">
            <span class="btnText">Submit</span>
            <i class="uil uil-navigator"></i>
          </button>
        </div>
      </div>

    </div>
  </form>
</div>
<div class="toaster">

  <ng-toast />

</div>