
  <div class="site" id="page">
    <div class="container">
      <div class="form-box">
        <div class="progress">
          <div class="back-icon">
            <a *ngIf="this.orgId === undefined" href="/">
              <i class="fa fa-arrow-left" aria-hidden="true"></i>
            </a>
            <a *ngIf="this.orgId !== undefined" href="registerrequest">
              <i class="fa fa-arrow-left" aria-hidden="true"></i>
            </a>
          </div>
          <div class="side-bar">
            <div class="logo">
              <a *ngIf="this.orgId === undefined" href="/">
                <span>sports</span>
                design
              </a>
              <a *ngIf="this.orgId !== undefined" href="registerrequest">
                <span>sports</span>
                design
              </a>
            </div>
          </div>
        </div>

        <form (ngSubmit)="onSubmit()" #registerform="ngForm"> <!-- Bind the onSubmit method to the form submit event -->
          <div class="form-one form-step active">
            <div class="bg-svg"></div>
            <h2 *ngIf="roleName; else noRole">{{ roleName }} Registration</h2>
            <ng-template #noRole>
              <h2>Science Staff Registration</h2>
            </ng-template>


           


            <div class="form-custom form-container">

              <div class="input-fields">
                <label>Full Name <span class="required">*</span></label>

                <input type="text" pattern="[A-Za-z\s]*" [(ngModel)]="trainerDetail.usrFullName" name="usrFullName"
                  placeholder="Full Name" required #usrFullName="ngModel" [ngClass]="{
                                     'invalid-field': registerform.submitted && usrFullName.invalid,
                                     'has-value': trainerDetail.usrFullName
                                   }" inputmode="text" onkeypress="return /[a-zA-Z\s]/.test(event.key)">
              </div>


              

              <div class="input-fields">
                <label>Date of Birth <span class="required">*</span></label>
                <input type="date" [(ngModel)]="trainerDetail.usrDoB" name="usrDoB" required #usrDoB="ngModel"
                  [ngClass]="{
                                'invalid-field': registerform.submitted && usrDoB.invalid,
                                'has-value': trainerDetail.usrDoB
                              }" [max]="today">
              </div>
              <div class="input-fields">
                <label>Gender <span class="required">*</span></label>

                <select [(ngModel)]="trainerDetail.usrGender" name="usrGender" required #usrGender="ngModel" [ngClass]="{
                                  'invalid-field': registerform.submitted && usrGender.invalid,
                                  'has-value': trainerDetail.usrGender
                                }">
                  <option value="" disabled selected>Select gender</option>
                  <option value="1">Male</option>
                  <option value="2">Female</option>
                  <option value="3">Others</option>
                </select>
              </div>
              <div class="input-fields">
                <label>Religion <span class="required">*</span></label>

                <input type="text" placeholder="Enter Religion" required [(ngModel)]="trainerDetail.usrReligion"
                  name="usrReligion" #usrReligion="ngModel" [ngClass]="{
                                'invalid-field': registerform.submitted && usrReligion.invalid,
                                'has-value': trainerDetail.usrReligion
                              }" inputmode="text" pattern="[A-Za-z\s]*"
                  onkeypress="return /[a-zA-Z\s]/.test(event.key)">

                <!-- Error Message -->
                <div *ngIf="usrReligion.errors?.['pattern'] && usrReligion.touched" class="error-message">
                  Only letters are allowed.
                </div>
              </div>


              <div class="input-fields">
                <label>Category <span class="required">*</span></label>

                <select required required [(ngModel)]="trainerDetail.usrCaste" name="usrCaste" required #usrCaste="ngModel" [ngClass]="{
                  'invalid-field': registerform.submitted && usrCaste.invalid,
                  'has-value': trainerDetail.usrCaste
                }">
                  <option value="" disabled selected>Select Category</option>
                  <option value="1">General</option>
                  <option value="2">SC</option>
                  <option value="3">ST</option>
                  <option value="4">OBC</option>
                </select>
              </div>

              <div class="input-fields">
                <label> Address <span class="required">*</span></label>

                
                <input type="text" [(ngModel)]="trainerDetail.usrAddress" name="usrAddress" placeholder="Address"
                  required #usrAddress="ngModel" [ngClass]="{
                                  'invalid-field': registerform.submitted && usrAddress.invalid,
                                  'has-value': trainerDetail.usrAddress
                                }">
              </div>
              <div class="input-fields">
                <label>Phone Number <span class="required">*</span></label>

                <input type="tel" [(ngModel)]="trainerDetail.usrPhoneNo" name="usrPhoneNo"
                  placeholder="Enter Phone number" required #usrPhoneNo="ngModel" [ngClass]="{
                                'invalid-field': registerform.submitted && usrPhoneNo.invalid,
                                'has-value': trainerDetail.usrPhoneNo
                              }" pattern="^[1-9][0-9]*$" (input)="validatePhoneNumber($event)" minlength="10"
                  maxlength="10">

                <!-- Error Message for invalid length -->
                <div *ngIf="usrPhoneNo.errors?.['minlength'] && usrPhoneNo.touched" class="error-message">
                  Phone number must be 10 digits long.
                </div>
              </div>

              <div class="input-fields">
                <label>Email Address <span class="required">*</span></label>
              
                <input 
                  type="email" 
                  [(ngModel)]="trainerDetail.usrEmail" 
                  name="usrEmail" 
                  placeholder="Enter Email Address" 
                  required 
                  pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$" 
                  #usrEmail="ngModel" 
                  [ngClass]="{
                    'invalid-field': registerform.submitted && usrEmail.invalid,
                    'has-value': trainerDetail.usrEmail
                  }">
              
                <!-- Validation message -->
                <div *ngIf="registerform.submitted && usrEmail.invalid" class="error-message">
                  <span *ngIf="usrEmail.errors?.['pattern']">Please enter a valid email address.</span>
                </div>
              </div>
              
              
              <div class="input-fields">
                <label>Create Password <span class="required">*</span></label>
                <div class="password-wrapper">
                  <input [type]="showPassword ? 'text' : 'password'" placeholder="Enter Password" required
                    [(ngModel)]="trainerDetail.usrPassword" name="usrPassword" #usrPassword="ngModel" [ngClass]="{
                      'invalid-field': registerform.submitted && usrPassword.invalid,
                      'has-value': trainerDetail.usrPassword
                    }">
                  <span class="eye-icon" (click)="showPassword = !showPassword">
                    <i [class]="showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
                  </span>
                </div>
              </div>
              
              <div class="input-fields">
                <label>Confirm Password <span class="required">*</span></label>
                <div class="password-wrapper">
                  <input [type]="showConfirmPassword ? 'text' : 'password'" placeholder="Confirm Password" required
                    [(ngModel)]="confirmPassword" name="confirmPassword" #confirmPasswordField="ngModel" [ngClass]="{
                      'invalid-field': registerform.submitted && (isConfirmPasswordEmpty || passwordsDoNotMatch),
                      'has-value': confirmPassword
                    }">
                  <span class="eye-icon" (click)="showConfirmPassword = !showConfirmPassword">
                    <i [class]="showConfirmPassword ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
                  </span>
                </div>
                <!-- Display error message if passwords do not match -->
                <div *ngIf="registerform.submitted && passwordsDoNotMatch && !isConfirmPasswordEmpty" class="error-message">
                  Passwords do not match.
                </div>
              </div>
              
              <div class="input-fields">
                <label>Emergency Contact Name <span class="required">*</span></label>

                <input type="text" [(ngModel)]="trainerDetail.usrECName" name="usrECName" placeholder="Enter Name"
                  required #usrECName="ngModel" [ngClass]="{
                                  'invalid-field': registerform.submitted && usrECName.invalid,
                                  'has-value': trainerDetail.usrECName
                                }" inputmode="text" pattern="[A-Za-z\s]*"
                  onkeypress="return /[a-zA-Z\s]/.test(event.key)">
                              
                <!-- Error Message -->
                <div *ngIf="usrECName.errors?.['pattern'] && usrECName.touched" class="error-message">
                  Only letters are allowed.
                </div>
              </div>
              <div class="input-fields">
                <label>Relationship <span class="required">*</span></label>

                <input type="text" [(ngModel)]="trainerDetail.usrECRelationship" name="usrECRelationship"
                  placeholder="Enter Relationship" required #usrECRelationship="ngModel" [ngClass]="{
                                   'invalid-field': registerform.submitted && usrECRelationship.invalid,
                                   'has-value': trainerDetail.usrECRelationship
                                 }" inputmode="text" pattern="[A-Za-z\s]*"
                  onkeypress="return /[a-zA-Z\s]/.test(event.key)">

                <!-- Error Message -->
                <div *ngIf="usrECRelationship.errors?.['pattern'] && usrECRelationship.touched" class="error-message">
                  Only letters are allowed.
                </div>
              </div>

              <div class="input-fields">
                <label>Emergency Contact Phone Number <span class="required">*</span></label>

                <input type="tel" [(ngModel)]="trainerDetail.usrECPhoneNo" name="usrECPhoneNo"
                  placeholder="Enter Phone Number" required #usrECPhoneNo="ngModel" [ngClass]="{
                                  'invalid-field': registerform.submitted && usrECPhoneNo.invalid,
                                  'has-value': trainerDetail.usrECPhoneNo
                                }" (input)="validateEcPhoneNumber($event)" pattern="^[1-9][0-9]*$" minlength="10"
                  maxlength="10">

                <!-- Error Message -->
                <div
                  *ngIf="(usrECPhoneNo.errors?.['minlength'] && usrECPhoneNo.touched) || (usrECPhoneNo.errors?.['required'] && usrECPhoneNo.touched)"
                  class="error-message">
                  Phone number must be 10 digits long.
                </div>
              </div>
              <div class="input-fields" *ngIf="this.roleId==6">
                <label>Sports Coach <span class="required">*</span></label>
                
                <select [(ngModel)]="trainerDetail.usrSport" name="usrSport" required #usrSport="ngModel" 
                        [ngClass]="{
                          'invalid-field': registerform.submitted && usrSport.invalid,
                          'has-value': trainerDetail.usrSport
                        }">
                  <option value="" disabled>Select Sport</option>
                  <option *ngFor="let sport of Sports" [value]="sport.sportId">{{ sport.sportName }}</option>
                </select>
                
               </div>
              
              

              <div class="input-fields" *ngIf="isSupport">
                <label>Support Staff <span class="required">*</span></label>

                <select (change)="onSupportStaffChange($event)" [(ngModel)]="trainerDetail.usrSupport" name="usrSupport"
                  required #usrSupport="ngModel" [ngClass]="{
                                  'invalid-field': registerform.submitted && usrSupport.invalid,
                                  'has-value': trainerDetail.usrSupport
                                }">
                  <option value="" disabled>Select Support Staff</option>
                  <option *ngFor="let supportstaff of Supportstaff" [value]="supportstaff.ssId">{{ supportstaff.ssName
                    }}</option>
                </select>
              </div>

              <div class="input-fields">
                <label>Organization <span class="required">*</span></label>
                <select [(ngModel)]="trainerDetail.usrOrganization" name="usrOrganization" required
                  #usrOrganization="ngModel" [ngClass]="{
                              'invalid-field': registerform.submitted && usrOrganization.invalid,
                              'has-value': trainerDetail.usrOrganization
                            }" [disabled]="orgId !== '0'&& orgId !==  undefined">
                        
                  <option value="" disabled>Select Organization</option>
                  <option *ngFor="let organization of Orgainsation" [value]="organization.orgId">
                    {{ organization.orgName }}
                  </option>
                </select>
              
                
              </div>
              
              <!-- <div class="input-fields">
                            <label>Profile Image </label>
                            <input type="file" class="form-control"  [(ngModel)]="trainerDetail.usrprofile" name="usrprofile" placeholder="Enter Phone Number" required
                            #usrprofile="ngModel"
                            [ngClass]="{
                              'invalid-field': registerform.submitted && usrprofile.invalid,
                              'has-value': trainerDetail.usrprofile
                            }" />
                          </div> -->
              <re-captcha *ngIf="this.orgId === undefined" (resolved)="resolvedCaptcha($event)"
                siteKey="6LfWeiAqAAAAABk1FIax7vg1M55Nj8sVOKtWrpdv"></re-captcha>

            </div>
            <br>

            <div class="btn-group">
              <button  type="submit" class="btn-submit">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="toaster">
    <ng-toast></ng-toast>
  </div>
