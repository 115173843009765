<div class="body-wrapper">  
  <div class="row flex-grow">
    <div class="col-12 grid-margin stretch-card">
      <div class="card card-rounded">
        <div class="card-body">
          <div class="d-sm-flex justify-content-between align-items-start">
            <div>
              <h4 class="card-title card-title-dash">Cognitive Assessment</h4>
              <p class="card-subtitle card-subtitle-dash">Cognitive  Details</p>
            </div>

            <div>
              <div class="select-wrapper">
                <select class="form-select" [(ngModel)]="selectedStatus" (change)="getPendingAssessment()">
                  <option disabled value="">Select option</option>
                  <option value="1">Completed</option>
                  <option value="0">Pending</option>
                </select>
              </div>
            </div>
          </div>
 
          <div class="content" style="width: 100%; height: 100%;">
              <!-- The AG Grid component, with Dimensions, CSS Theme, Row Data, and Column Definition -->
              <ag-grid-angular
              class="ag-theme-quartz"
              style="height: 500px;"
              [rowData]="rowData"
              [columnDefs]="colDefs"
              [defaultColDef]="defaultColDef"
              [pagination]="true"
            />
            </div>
 
 
 
        </div>
      </div>
    </div>
  </div>
</div>