<div class="body-wrapper" *ngIf="currentSection === 1">

    <div class="row">
        <div class="col d-flex justify-content-start">
            <button type="button" class="back btn btn-primary me-2" (click)="back()">
                <span class="btnText">Back</span>
            </button>
        </div>
    </div>
    <div class="mt-3">
        <div class="row justify-content-center">
            <div class="col-md-12">
                <div class="border rounded bg-white shadow-sm">
                    <div class="question p-4 border-bottom">
                     
                        <div class="d-flex justify-content-between">
                            <p><strong>Player ID:</strong> {{ athleteId }}</p>
                            <div *ngIf="mode === 'edit'" class="dropdown-wrapper mb-2">
                               
                                <div class="select-wrapper d-flex align-items-center">
                                    <label for="athleteDropdown" class="player-label">Select Player:</label>
                                    <ng-select  [(ngModel)]="athleteId" [items]="playerList" bindLabel="displayLabel" bindValue="aplPlayer" name="athleteId"
                                    (change)="onAthleteChange($event)" [searchable]="false" [clearable]="false" class="ng-select-custom">
                                    <ng-template ng-label-tmp let-item="item">
                                      <span>{{ item.displayLabel }}</span>
                                      <i class="fa fa-chevron-down"></i>
                                    </ng-template>
                                    <ng-template ng-option-tmp let-item="item">
                                      <span>{{ item.displayLabel }}</span>
                                  </ng-template>
                                  </ng-select>
                                </div>
                            </div>
                        </div>
                        <div class="row align-items-start">
                            <!-- Image column -->
                            <div class="d-flex gap-4">
                                
                                <div style="min-width: 150px; display: flex; align-items: start;">
                                    <img *ngIf="showImage && image" src="{{image}}" class="custom-img-width">
                                </div>
                                <div>
                                  <div class="profile d-flex align-items-center mb-4">
                                     <div class="icon-bg">
                                        <i class='bx bx-user'></i>
                                     </div>
                                     <div>
                                    <h4 *ngIf="Admin || sciStaff">{{ playerName[0].Name }}</h4>
                                    <p>Player Name</p>
                                </div>
                                </div>
                                <div class="profile d-flex align-items-center mb-4">
                                    <div class="icon-bg">
                                        <i class='bx bx-envelope'></i>
                                     </div>
                                     <div>
                                    <h4 *ngIf="Admin || sciStaff">{{ playerName[0].PlayerEmail }}</h4>
                                    <p>Player Email</p>
                                </div>
                                </div>
                                <div class="profile d-flex align-items-center mb-4">
                                    <div class="icon-bg">
                                        <i class='bx bx-phone'></i>
                                     </div>
                                     <div>
                                    <h4 *ngIf="Admin || sciStaff">{{ playerName[0].PhoneNo }}</h4>
                                    <p>Player Phone No </p>
                                    </div>
                                </div>
                                <div class="input-field">
                                    <label>Test Taken Date</label>
                                    <input class="w-100"
                                    [disabled]="mode === 'view'"
                                    type="date"
                                    name="sarCreateOn"
                                    [(ngModel)]="formValues.sleep.sarCreateOn" 
                                    required
                                    [min]="this.minDate"
                                    [max]="this.maxDate"
                                    (focus)="setDateConstraints()"
                                  />
                                  <div *ngIf="invalid" class="error-message">
                                    Date is required.
                                </div>
                                  </div>
                                  </div>
                            </div>

                            <!-- Dropdown column (only visible in edit mode, aligned right) -->
                    

                        </div>

                        <!-- Player ID column (always below the image) -->
                        <div class="row mt-2">
                            <div class="d-flex justify-content-between align-items-center mt-4">
                                <h4>Sleep and Rest</h4>
                            </div>
                        </div>

                        <div class="question1 p-4 border-bottom">

                            <div class="d-flex align-items-center mb-3">
                                <h3 class="text-danger me-2">1.</h3>
                                <h5>How many hours of sleep do you usually get per night?</h5>
                            </div>
                            <div class="form-check mb-2"
                                *ngFor="let option of ['Less than 5 hours', '5-6 hours', '6-7 hours', '7-8 hours', 'More than 8 hours']">
                                <label class="radio">
                                    <input class="form-check-input" type="radio" name="sleepHours"
                                        [(ngModel)]="formValues.sleep.hours" [value]="option"
                                        [disabled]="mode === 'view'">
                                    <span>{{ option }}</span>
                                </label>
                            </div>

                            <div class="d-flex align-items-center mb-3">
                                <h3 class="text-danger me-2">2.</h3>
                                <h5>How would you rate the quality of your sleep?</h5>
                            </div>
                            <div class="form-check mb-2"
                                *ngFor="let option of ['Poor', 'Fair', 'Good', 'Very Good', 'Excellent']">
                                <label class="radio">
                                    <input class="form-check-input" type="radio" name="sleepQuality"
                                        [(ngModel)]="formValues.sleep.quality" [value]="option"
                                        [disabled]="mode === 'view'">
                                    <span>{{ option }}</span>
                                </label>
                            </div>


                            <div class="d-flex align-items-center mb-3">
                                <h3 class="text-danger me-2">3.</h3>
                                <h5>Do you take naps during the day to help with recovery?</h5>
                            </div>
                            <div class="form-check mb-2">
                                <label class="radio">
                                    <input class="form-check-input" type="radio" name="naps"
                                        [(ngModel)]="formValues.sleep.naps" value="Yes" [disabled]="mode === 'view'">
                                    <span>Yes</span>
                                </label>
                            </div>
                            <div class="form-check mb-2">
                                <label class="radio">
                                    <input class="form-check-input" type="radio" name="naps"
                                        [(ngModel)]="formValues.sleep.naps" value="No" [disabled]="mode === 'view'">
                                    <span>No</span>
                                </label>
                            </div>

                            <div *ngIf="formValues.sleep.naps === 'Yes'">
                                <div class="d-flex align-items-center mb-3">
                                    <h3 class="text-danger me-2">4.</h3>
                                    <h5>If yes, how long are your naps?</h5>
                                </div>
                                <div class="form-check mb-2"
                                    *ngFor="let option of ['Less than 20 minutes', '20-40 minutes', '40-60 minutes', 'More than 60 minutes']">
                                    <label class="radio">
                                        <input class="form-check-input" type="radio" name="napDuration"
                                            [(ngModel)]="formValues.sleep.napDuration" [value]="option"
                                            [disabled]="mode === 'view'">
                                        <span>{{ option }}</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-end p-3 bg-white">

                            <div *ngIf="mode === 'edit'">
                                <button class="btn btn-primary" type="button" (click)="submit()"
                                    [disabled]="!isSectionComplete(1)">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="toaster">
        <ng-toast />
    </div>