import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Component, NgModule, NgZone } from '@angular/core';
import { ActivatedRoute, Router, RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { User } from '../../model/User';
import { PrivilegeEnum } from '../../model/PrivilageEnum';
import { AssessmentService } from '../../services/assessment.service';
import { SharedService } from '../../services/shared.service';
import { Location } from '@angular/common';

interface IRow {}
@Component({
  selector: 'app-csshealth-fitness-detail',
  standalone: true,
  imports: [CommonModule, RouterLink, RouterModule, HttpClientModule, AgGridAngular],
  templateUrl: './csshealth-fitness-detail.component.html',
  styleUrl: './csshealth-fitness-detail.component.css'
})
export class CSSHealthFitnessDetailComponent {
  Response: any;
  detail: any;
  Id: any;
  Status: any;
  isTableVisible: boolean = false;
  coachData: any;
  public asmId: any;
  public testNames: any;
  public assesserName: any;
  public CoachName: any;
  public teamName: any;
  public created: any;
  public CoachId: any;
  assessments: any;
  user: User | null = null;
  groupedData: { [key: string]: any[] } = {};

  themeClass = 'ag-theme-quartz';
  rowData: IRow[] = [];
  colDefs: ColDef[] = [];
  ramPrivilege: PrivilegeEnum | undefined;
  defaultColDef: ColDef = {
    flex: 1,
    sortable: true,
    filter: true,
  };
  Isplayer: boolean | undefined;
  Admin: boolean | undefined;
  org: any;
  role: any;
  category: any;
  test: any;
  selectdata: any;
  status: any;
  selectedStatus: string='0';
  constructor(
    private assessmentService: AssessmentService,
    private sharedService: SharedService,
    private ngZone: NgZone, 
    private router: Router ,private route: ActivatedRoute,private location:Location
  ) {
    this.route.queryParams.subscribe(params => {
      this.asmId = params['asmId'];
      this.category = params['category'];
      this.status = params['selectStatus'];
 
    });
  }

  ngOnInit() {
    this.sharedService.detail$.subscribe((data: any) => {
      this.detail = data;
      this.Id = this.detail.user.usrId;
      this.role=this.detail.user.usrRole;
      this.Status = 0;
    });

    this.getPendingAssessment();
    this.assessmentService.getPlayerByAssessmentId(this.asmId).subscribe(response => {
      this.selectdata = response.filter((assessment: any) => assessment.aplCategory == this.category);
      const uniqueAssessments = new Map<number, any>();
      this.selectdata.forEach((item: any) => {
        // Only add the item if it does not exist in the map
        if (!uniqueAssessments.has(item.aplPlayer)) {
          uniqueAssessments.set(item.aplPlayer, {
            "Player Name": `${item.usrFullName}`,
        PlayerId:`${item.aplPlayer}`,
        teamId:`${item.teamId}`,
        orgId:`${item.usrOrganization}`,
        id:`${item.coaId}`,
        "Email" :`${item.usrEmail}`,
        "Phone No": `${item.usrPhoneNo}`,
        "DOB": new Date(item.usrDoB).toLocaleDateString(),
        "status": `${item.aplStatus}`, 
        
          });
        }
      });
      this.rowData = Array.from(uniqueAssessments.values());
   

 
      this.colDefs = this.getColumnDefinitions();
    }, error => {
      console.error('Error submitting assessment', error);
    });
  }

  onBack():void{
    this.router.navigate(['/Fitness'], {
      queryParams: {
        statusId: this.status
      }
    });
  }

  getPendingAssessment() {

      this.assessmentService.getAssessmentTest(this.asmId).subscribe(
        (response) => {
          this.test=response;
          this.groupDataBySsName();
          console.log(response,"test")
          const uniqueAssessments = new Map<number, any>();
        },
        (error) => {
          console.error('Error retrieving pending assessments', error);
        }
      );

  }
  groupDataBySsName() {
    this.groupedData = this.test.reduce((acc: { [x: string]: { [y: string]: any[]; }; }, item: { ssName: string; assesserName: string; }) => {
      if (!acc[item.ssName]) {
        acc[item.ssName] = {};
      }
      if (!acc[item.ssName][item.assesserName]) {
        acc[item.ssName][item.assesserName] = [];
      }
      acc[item.ssName][item.assesserName].push(item);
      return acc;
    }, {});
  }
  
  getColumnDefinitions(): ColDef[] {
    const columns: ColDef[] = [
      { field: 'Player Name' },
      { field: 'Email' },
      { field: 'DOB'},
      { field: 'Phone No' },
      {
        headerName: 'Action',
        cellRenderer: (_params: any) => {
          const container = document.createElement('div');
          container.style.display = 'flex';
          container.style.justifyContent = 'space-around';
 
          // View button with eye icon
          const viewButton = document.createElement('button');
          viewButton.classList.add('btn');
          viewButton.innerHTML = '<i class="fa fa-eye"></i>';
          if (_params.data.status == 0) {
            viewButton.style.cssText = 'color: grey; cursor: not-allowed; opacity: 0.6;';
          }
          viewButton.addEventListener('click', () => {
            this.ngZone.run(() => {
              if (_params.data.status == 1) {
                this.router.navigate(['/report'], {
                  queryParams: {
                    id: _params.data.PlayerId,
                    orgId: _params.data.orgId,
                    selectStatus: this.selectedStatus
                  }
                });
              }
            });
          });

          // const editButton = document.createElement('button');
          // editButton.classList.add('btn');
          // editButton.innerHTML = '<i class="fa fa-edit"></i>';
          // editButton.addEventListener('click', () => {
          //   this.ngZone.run(() => {
          //     this.router.navigate(['/CardiologyForm'], {
          //       queryParams: { id: _params.data.id }
          //     });
          //   });
          // });
 
           container.appendChild(viewButton);
          // container.appendChild(editButton);
          
 
          return container;
        },
        width: 120
      }
    
    ];
 
    if (this.Admin) {
      return columns;
    } else if (this.Isplayer) {
      return columns.filter(col => col.field !== 'playername');
    } else {
      return columns.filter(col => col.field !== 'Assessor');
    }
  }
 
}
