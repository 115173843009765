import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment.prod';
import { SharedService } from './shared.service'; 

@Injectable({
  providedIn: 'root'
})
export class UserRegisterService {
 
  constructor(private http: HttpClient, private sharedService: SharedService) {}

  private getAuthHeaders(): HttpHeaders {
    const token = this.sharedService.getToken();
    return new HttpHeaders({
      'Authorization': token ? `Bearer ${token}` : ''
    });
  }

  getAllUsers(newStatus: number, org: number): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getUserUrl}`;
    const params = new HttpParams()
      .set('newStatus', newStatus.toString())
      .set('org', org.toString());
    
    return this.http.get<any>(url, { headers: this.getAuthHeaders(), params });
  }

  updateUserStatus(userId: number, isActive: number): Observable<any> {
    const url = `${environment.BaseUrl}${environment.updateUser}?usrId=${userId}&newStatus=${isActive}`;
    return this.http.put(url, {}, { headers: this.getAuthHeaders() });
  }

  fetchUserById(id: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.userByid}${id}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }

  getOrgTeams(orgId: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.orgTeams}${orgId}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }
 
  userRegister(formData: any): Observable<any> {
    return this.http.post<any>(
      `${environment.BaseUrl}${environment.Plyerregister}`,
      formData,
      { headers: this.getAuthHeaders() }
    );
  }

  fetchUser(id: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.userRegistrationURL}${id}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }

  getPlayersByCoachId(CoachId: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getPlayersByCoachId}${CoachId}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }

  getTests(teamid: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getTestsName}${teamid}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }

  updateProfile(formData: any): Observable<any> {
    return this.http.post<any>(
      `${environment.BaseUrl}${environment.updateProfileDetails}`,
      formData,
      { headers: this.getAuthHeaders() }
    );
  }

  changePassword(userId: any, oldPassword: any, newPassword: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.changePassword}?userId=${userId}&oldPassword=${oldPassword}&newPassword=${newPassword}`;
    return this.http.post(url, {}, { headers: this.getAuthHeaders() });
  }
}
