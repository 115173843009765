<div class="body-wrapper">
  <div>
    <button class="btn btn-primary" (click)="onBack()">Back</button>
  </div>
  <div class="row flex-grow">
    <div class="col-12 grid-margin stretch-card">
      <div class="card card-rounded">
        <div class="card-body">
          <div class="d-sm-flex justify-content-between align-items-start mb-4">
            <div>
              <h4 class="card-title card-title-dash">Assign Team</h4>
            </div>
          </div>
          <div class="d-sm-flex flex-row justify-content-between mb-4">
            <div class="d-flex gap-4 flex-row">
              <div class="select-wrapper">

                <label for="orgSelect" class="form-label">Select Organization</label>
               
                <ng-select id="orgSelect" class="ng-select-custom" [items]="dropdownItems" bindLabel="orgName"
                  bindValue="orgId" [(ngModel)]="selectedOrg" (change)="onSelectOrg()" [searchable]="false"
                  [clearable]="false">
                  <ng-template ng-label-tmp let-item="item">
                    <!-- <img src="/assets/org.png" alt="Organization" style="width: 20px; height: 20px; margin-right: 5px;"> -->
                    <span>{{ item.orgName }}</span>
                    <i class="fa fa-chevron-down"></i>
                  </ng-template>

                  <ng-template ng-option-tmp let-item="item">
                    <span [ngClass]="{'disabled-option': item.disabled}">{{ item.orgName }}</span>
                  </ng-template>
                </ng-select>

              </div>
              <div class="select-wrapper">
               
                <label for="teamSelect" class="form-label">Select Team</label>
                <ng-select id="teamSelect" class="ng-select-custom" [items]="orgTeams" bindLabel="teamName"
                  bindValue="teamId" [(ngModel)]="selectedTeam" (change)="onSelectTeam()" [searchable]="false"
                  [clearable]="false">

                  <ng-template ng-label-tmp let-item="item">
                    <!-- <img src="/assets/team.png"  style="width: 20px; height: 20px; margin-right: 5px;"> -->

                    <span>{{ item.teamName }}</span>
                    <i class="fa fa-chevron-down"></i>
                  </ng-template>

                  <ng-template ng-option-tmp let-item="item">
                    <span [ngClass]="{'disabled-option': item.disabled}">{{ item.teamName }}</span>
                  </ng-template>
                </ng-select>

              </div>
              <div class="select-wrapper">
           
                <label for="coachSelect" class="form-label">Select Coach</label>
                <ng-select id="coachSelect" class="ng-select-custom" [items]="Trainer" bindLabel="usrFullName"
                  bindValue="usrId" [(ngModel)]="selectedCoach" [searchable]="false" [clearable]="false">
                  <ng-template ng-label-tmp let-item="item">
                    <!-- <img src="/assets/coach.png"  style="width: 15px; height: 15px; margin-right: 5px;"> -->
                    <span>{{ item.usrFullName }}</span>
                    <i class="fa fa-chevron-down"></i>
                  </ng-template>

                  <ng-template ng-option-tmp let-item="item">
                    <span [ngClass]="{ 'disabled-option': item.disabled }">{{ item.usrFullName }}</span>
                  </ng-template>
                </ng-select>

              </div>
            </div>
            <div class="mt-4">
              <button type="button" class="btn btn-primary" (click)="updateSelection()" [disabled]="!isFormValid()">
                Update
              </button>
            </div>

          </div>
          <div class="content" style="width: 100%; height: 100%;">
            <ag-grid-angular class="ag-theme-quartz" style="height: 500px;" [rowData]="rowData" [columnDefs]="colDefs"
              [defaultColDef]="defaultColDef" [gridOptions]="gridOptions" [pagination]="true"
              (gridReady)="onGridReady($event)">
            </ag-grid-angular>

          </div>
          <br>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="toaster">
  <ng-toast></ng-toast>
</div>