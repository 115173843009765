import { Injectable } from '@angular/core';
import { formulas } from '../model/polynomial-formula.module';

@Injectable({
  providedIn: 'root'
})
export class PolynomialService {

  constructor() { }
  calculate(test: string, inputType: string | null, value: number | null): number {
    const formulaObj = formulas[test];
  
    // Check if formula exists for the given test
    if (!formulaObj) {
      throw new Error(`Formula for test ${test} not found`);
    }
  
    // Return 0 or any default value if value is null or undefined
    if (value === null || value === undefined) {
      return 0; // You can change this to any default value if needed
    }
  
    let formula = formulaObj.formula;
  
    // Replace 'x' with the provided value
    if (formula.includes("x")) {
      formula = formula.replace(/x/g, `${value}`);
    }
  
    // Handle multi-input formulas (like BodyCompositionBMI)
    if (inputType && Array.isArray(value)) {
      value.forEach((val, index) => {
        formula = formula.replace(new RegExp(`x${index + 1}`, 'g'), `${val}`);
      });
    }
  
    // Evaluating the formula using the replaced value
    try {
      return this.evaluateFormula(formula);
    } catch (error) {
      console.error(`Error evaluating formula: ${error}`);
      return 0;
    }
  }

  getDescription(test: string): string {
    return formulas[test]?.description || 'No description available';
  }

  // Method to safely evaluate the formula
  private evaluateFormula(formula: string): number {
    try {
      // Replace the 'x' or x1, x2 placeholders with actual values
      // Use the Function constructor to evaluate the formula safely
      return new Function('return ' + formula)();
    } catch (error) {
      console.error(`Error in formula: ${error}`);
      return 0;
    }
  }
}