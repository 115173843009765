import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { RoleService } from './role.service';
import { Privilege } from '../model/Privillage';

@Injectable({
  providedIn: 'root'
})
export class PrivilegeService {
  private privilege: Privilege;
  usrRole: any;
  private isBrowser: boolean;

  constructor(
    private roleService: RoleService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    // Initialize privileges from sessionStorage if available and in a browser environment
    if (this.isBrowser) {
      const storedPrivilege = sessionStorage.getItem('privilege');
      if (storedPrivilege) {
        this.privilege = JSON.parse(storedPrivilege);
      } else {
        this.privilege = new Privilege();
      }
    } else {
      this.privilege = new Privilege();
    }
  }

  getPrivilege(data: any) {
    this.usrRole = data.user.usrRole;

    this.roleService.getRoles().subscribe(
      roles => {
        const role = roles.find(r => r.roleId === this.usrRole);
        if (role) {
          this.resetPrivileges();
          switch (role.roleId) {
            case 1:
              this.privilege.isPlayer = true;
              break;
            case 2:
              this.privilege.isAdmin = true;
              break;
            case 3:
              this.privilege.isAssessor = true;
              break;
            case 4:
              this.privilege.isManager = true;
              break;
            // case 5:
            //   this.privilege.isBioMechanical = true;
            //   this.privilege.isScienceStaff = true;
            //   break;
            // case 6:
            //   this.privilege.isPhysiotherapy = true;
            //   this.privilege.isScienceStaff = true;
            //   break;
            // case 7:
            //   this.privilege.isPhysiology = true;
            //   this.privilege.isScienceStaff = true;
            //   break;
            case 5:
              // this.privilege.isPhysicalFitness = true;
              this.privilege.isScienceStaff = true;
              break;
            // case 9:
            //   this.privilege.isNutrition = true;
            //   this.privilege.isScienceStaff = true;
            //   break;
            // case 10:
            //   this.privilege.isVideoAnalysis = true;
            //   this.privilege.isScienceStaff = true;
            //   break;
              case 6:
              this.privilege.isCoach = true;
              break;
            case 7:
              this.privilege.isOrgAdmin = true;
              break;
            // case 13:
            //   this.privilege.isCardio = true;
            //   this.privilege.isScienceStaff = true;
            //   break;
            //   case 14:
            //   this.privilege.isCognitive = true;
            //   this.privilege.isScienceStaff = true;
            //   break;
            //   case 15:
            //     this.privilege.isLevelOne=true;
            //     this.privilege.isScienceStaff = true;
            //     break;
            default:
              break;
          }
          this.savePrivilegesToSessionStorage();
        }
      },
      error => {
        console.error('Error fetching roles:', error);
      }
    );
  }

  private resetPrivileges(): void {
    this.privilege = new Privilege();
  }

  private savePrivilegesToSessionStorage(): void {
    if (this.isBrowser) {
      sessionStorage.setItem('privilege', JSON.stringify(this.privilege));
    }
  }

  getPrivileges(): Privilege {
    return this.privilege;
  }
}
