<!-- <div *ngIf="isLoading" class="loading-spinner">
    
    <div class="spinner"></div>
</div> -->
<div class="body-wrapper" [ngClass]="{ 'blurred': isLoading }">
    <div class="drop-items d-flex justify-content-end mb-3 gap-4 align-items-end">
        <div class="select-wrapper">
            <h4>Organization</h4>
            <ng-select
            [(ngModel)]="selectedOrg"
            [items]="organizationOptions"
            bindLabel="orgName"
            bindValue="orgId"
            placeholder="Select Organization"
            [searchable]="false"
            [clearable]="false"
            class="ng-select-custom"
            (change)="selectOrganisation()"
          >
            <!-- Custom label template -->
            <ng-template ng-label-tmp let-item="item">
              <span>{{ item.orgName }}</span>
              <i class="fa fa-chevron-down ml-2"></i>
            </ng-template>
            <!-- Optional custom dropdown item template -->
            <ng-template ng-option-tmp let-item="item">
              <span>{{ item.orgName }}</span>
            </ng-template>            
          </ng-select>
          
        </div>
        <div class="d-flex justify-content-end">
            <div class="select-wrapper">
                <h4>Team</h4>
                <ng-select
                id="teamSelect"
                [(ngModel)]="selectedTeamId"
                [items]="teamOptions"
                bindLabel="teamName"
                bindValue="teamId"
                placeholder="Select Team"
                [searchable]="false"
                [clearable]="false"
                [disabled]="selectedOrg === '0'"
                class="ng-select-custom"
                (change)="selectTeams()"
                >
                 <!-- Custom label template -->
                <ng-template ng-label-tmp let-item="item">
                <span>{{ item.teamName }}</span>
                <i class="fa fa-chevron-down ml-2"></i>
              </ng-template>
              <!-- Optional custom dropdown item template -->
              <ng-template ng-option-tmp let-item="item">
                <span>{{ item.teamName }}</span>
              </ng-template>         
                </ng-select>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="row">
            <div class="col-sm-8 flex-column d-flex stretch-card">
                <div class="row">
                    <div class="col-lg-4 d-flex grid-margin stretch-card">
                        <div class="card player-diffrence-border">
                            <div class="card-body">
                                <h2 class="text-dark mb-2 font-weight-bold"> {{ overallCount }}</h2>
                                <h4 class="card-title mb-2">Overall Assessment</h4>

                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 d-flex grid-margin stretch-card">
                        <div class="card player-diffrence-border">
                            <div class="card-body">
                                <h2 class="text-dark mb-2 font-weight-bold"> {{ completedCount }}</h2>
                                <h4 class="card-title mb-2">Completed Assessment</h4>

                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 d-flex grid-margin stretch-card">
                        <div class="card player-diffrence-border">
                            <div class="card-body">
                                <h2 class="text-dark mb-2 font-weight-bold"> {{ pendingCount }}</h2>
                                <h4 class="card-title mb-2">Pending Assessment</h4>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="content" style="width: 100%; height: 100%;">
        <!-- The AG Grid component, with Dimensions, CSS Theme, Row Data, and Column Definition -->
        <ag-grid-angular class="ag-theme-quartz" style="height: 500px;" (gridReady)="onGridReady($event)"
            [rowData]="rowData" [columnDefs]="colDefs" [defaultColDef]="defaultColDef" [pagination]="true" />
    </div>
</div>