import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink, RouterOutlet } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { NgToastModule } from 'ng-angular-popup';
import { AssessmentService } from '../../../services/assessment.service';
import { NotificationService } from '../../../services/Notifications.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-physiotherapy-editform',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterOutlet, RouterLink, HttpClientModule, NgToastModule],
  templateUrl: './physiotherapy-editform.component.html',
  styleUrls: ['./physiotherapy-editform.component.css']
})
export class PhysiotherapyEditformComponent implements OnInit {
  formSubmitted: boolean = false;
  personalDetailsFilled: boolean = false;
  physiotherapy: boolean = false;
  physiology: boolean = false;
  isReadOnly: boolean = false;
  assessment: any = {
    ptaAthlete: '',
    ptaDate: '',
    ptaAssessor: '',
    ptaInjuryHistory: '',
    ptaCurrentPainLevel: '',
    ptaRangeOfMotion: '',
    ptaFlexibility: '',
    ptaMuscleStrength: '',
    ptaBalance: '',
    ptaTreatmentPlan: '',
    ptaFollowUp: '',
  };
  athletes: any[] = [];
  selectedAthleteId: string | null = null;  // To store the selected athlete's ID
  id: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private assessmentService: AssessmentService,
    private notify: NotificationService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
      console.log(this.id, "ID");
    });
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      // Safe to use window, document, etc.
      this.isReadOnly = localStorage.getItem('athelete') !== '';
    } else {
      this.isReadOnly = false;
    }

    this.FetchAthelete();
  }

  onPlayerChange(event: any) {
    console.log(event.target.value, "athlete");
  }

  checkPersonalDetailsFilled(): void {
    const personalDetailsInputs = document.querySelectorAll(".form.first input");
    this.personalDetailsFilled = Array.from(personalDetailsInputs).every(input => {
      const inputElement = input as HTMLInputElement;
      return inputElement.value.trim() !== '';
    });
  }

  formSubmit(): void {
    this.checkPersonalDetailsFilled();
    if (this.personalDetailsFilled) {
      console.log(this.personalDetailsFilled);
      this.formSubmitted = true;
    }
  }

  onSubmit(): void {
    if (this.isFormValid()) {
      this.assessmentService.addPhysiotherapy([this.assessment]).subscribe(
        response => {
          console.log('Assessment submitted', response);
          this.formSubmitted = true;
          this.notify.success("Data updated successfully");
          setTimeout(() => {
            this.router.navigate(['physiotherapylist']);
          }, 2000);
        },
        error => {
          console.error('Error submitting assessment', error);
        }
      );
    } else {
      console.log('Form is not valid');
    }
  }

  isFormValid(): boolean {
    return Object.keys(this.assessment).every(key => {
      // Exclude 'OtherNutrientLevels' from validation
      if (key === 'OtherNutrientLevels') return true;
      return this.assessment[key] !== '' && this.assessment[key] !== null;
    });
  }

  fetchAthletes(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement) {
      const query = inputElement.value;
      this.assessmentService.getAthlete(query).subscribe(
        data => {
          this.athletes = data;
          console.log(this.athletes);
        },
        error => {
          console.error('Error fetching athletes', error);
        }
      );
    }
  }

  onAthleteSelected(event: Event): void {
    const inputElement = event.target as HTMLInputElement | null;
    if (inputElement) {
      const selectedAthlete = this.athletes.find(athlete => athlete.usrFullName === inputElement.value);
      if (selectedAthlete) {
        this.selectedAthleteId = selectedAthlete.usrId;
      }
    }
  }

  FetchAthelete() {
    if (this.id) {
      this.assessmentService.fetchphysiotherapybyid(this.id).subscribe(
        (response: any) => {
          if (response && response.length > 0) {
            console.log(response, 'data');
            const data = response[0];
            this.assessment.ptaId = data.ptaId;
            this.assessment.ptaDate = data.ptaDate ? data.ptaDate.split('T')[0] : '';
            this.assessment.ptaAssessorName = data.assessorName;
            this.assessment.ptaAthleteName = data.athleteName;
            this.assessment.ptaAssessor = data.ptaAssessor;
            this.assessment.ptaAthlete = data.ptaAthlete;
            this.assessment.ptaInjuryHistory = data.ptaInjuryHistory;
            this.assessment.ptaCurrentPainLevel = data.ptaCurrentPainLevel;
            this.assessment.ptaRangeOfMotion = data.ptaRangeOfMotion;
            this.assessment.ptaFlexibility = data.ptaFlexibility;
            this.assessment.ptaMuscleStrength = data.ptaMuscleStrength;
            this.assessment.ptaBalance = data.ptaBalance;
            this.assessment.ptaTreatmentPlan = data.ptaTreatmentPlan;
            this.assessment.ptaFollowUp = data.ptaFollowUp ? data.ptaFollowUp.split('T')[0] : '';
            this.assessment.ptaCreatedBy = data.ptaCreatedBy;
            this.assessment.ptaCreatedOn = new Date(data.ptaCreatedOn);
            this.assessment.ptaModifiedBy = data.ptaModifiedBy;
            this.assessment.ptaModifiedOn = new Date(data.ptaModifiedOn);
          }
        },
        (error: any) => {
          console.error('Error fetching assessment data', error);
        }
      );
    }
  }
}
