import { ChangeDetectorRef, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { NgToastService } from 'ng-angular-popup';
import { DynamicColumnService } from '../../services/dynamic-column.service';
import { NotificationService } from '../../services/Notifications.service';

@Component({
  selector: 'app-assessment',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterOutlet, RouterLink, HttpClientModule],
  templateUrl: './assessment.component.html',
  styleUrls: ['./assessment.component.css']
})
export class AssessmentComponent {
  columnData: any = {
    tableName: '',
    columnName: '',
    dataType: ''
  };

  constructor(
    private dynamicColumnService: DynamicColumnService,
    private cdr: ChangeDetectorRef,
    private toastService: NgToastService,private notify:NotificationService,private router: Router,
  ) {}

  onSubmit(): void {
    this.dynamicColumnService.addColumns(this.columnData).subscribe(
      (data: any) => {
       
        this.resetForm();
        this.notify.success("Form submitted successfully");
        setTimeout(() => {
          this.router.navigate(['physiologyeditform']);
        }, 1000);
      },
      (error) => {
        console.error('Error adding column', error);
        
      }
    );
  }

  private resetForm(): void {
    this.columnData = {
      tableName: '',
      columnName: '',
      dataType: ''
    };
    this.cdr.detectChanges();
  }
}
