import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AssessmentService } from '../../../services/assessment.service';
import { CommonModule } from '@angular/common';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Location } from '@angular/common';
import { Privilege } from '../../../model/Privillage';
import { SharedService } from '../../../services/shared.service';
import { PrivilegeService } from '../../../services/privilage.service';

@Component({
  selector: 'app-physicalfitness-detail',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './physicalfitness-detail.component.html',
  styleUrl: './physicalfitness-detail.component.css'
})
export class PhysicalfitnessDetailComponent implements OnInit {
  selectedDate: string = ''; // Holds the selected date from the date picker
  id: any;
  athleteReport: any;
  athleteId: any;
  privilege: Privilege;
  IsAdmin: boolean = false;
  isScienceStaff: boolean = false;
  role: any;
  detail: any;
  userId: any;
  selectdata: any;
  athleteFullName: any;
  date: any;
  assessmentDate: any;
  pfaHeight: any;
  pfaWeight: any;
  pfaBMI: any;
  pfaSpeed: any;
  pfaYoYoTest: any;
  pfaSingleLegBalanceLeftEyesOpen: any;
  pfaSingleLegBalanceLeftEyesClose: any;
  pfaSingleLegBalanceRightEyesOpen: any;
  pfaSingleLegBalanceRightEyesClose: any;
  pfaYBalanceTest1: any;
  pfaYBalanceTest2: any;
  pfaYBalanceTest3: any;
  pfaStarExcursionTest1: any;
  pfaStarExcursionTest2: any;
  pfaStarExcursionTest3: any;
  pfaPushups: any;
  pfaPullups: any;
  pfaPullAndHang: any;
  pfaSquat: any;
  pfaSingleLegSquat: any;
  pfaPartialCurlUp: any;
  pfaGripStrengthRight: any;
  pfaGripStrengthLeft: any;
  pfaSitNReach: any;
  pfaAppleysScratchTest: any;
  pfa50mSprint: any;
  pfa30mSprint: any;
  pfa10mShuttleRun: any;
  pfaTTest: any;
  pfaStandingBroadJump: any;
  pfaVerticalJump: any;
  pfaSeatedMedBallThrow: any;
  pfaUpperBodyForce: any;
  pfaUpperBodyPower: any;
  pfaLowerBodyPower: any;
  pfaLowerBodyForce: any;
  pfaLevel: any;
  pfaVO2: any;

  constructor(private router: Router, private route: ActivatedRoute, private assessmentService: AssessmentService, private location: Location,
    private sharedService: SharedService, private priService: PrivilegeService
  ) {
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
      this.athleteId = params['athleteId'];
      console.log(this.id, "ID");
    });
    this.privilege = this.priService.getPrivileges();
    this.IsAdmin = this.privilege.isAdmin;
    this.isScienceStaff=this.privilege.isScienceStaff
  }

  activities = [
    { name: 'Sports Activity', percentage: 82 },
    { name: 'Technique Analysis', percentage: 42 },
    { name: 'Performance Analysis', percentage: 100 }
  ];
 
  // Dynamically return the color class based on percentage
  getProgressColor(percentage: number): string {
    if (percentage >= 75) {
      return 'bg-green';
    } else if (percentage >= 40) {
      return 'bg-yellow';
    } else {
      return 'bg-red';
    }
  }

  ngOnInit(): void {
    this.sharedService.detail$.subscribe((data: any) => {
      this.detail = data;
      this.role = this.detail.user.usrRole;
      this.userId = this.detail.user.usrId;
    });

    const getAthleteReport = (this.IsAdmin || this.isScienceStaff ) 
      ? this.assessmentService.getFitnessByTest(this.id)
      : this.assessmentService.getFitnessByTestAssessor(this.id);

    getAthleteReport.subscribe((response: any) => {
      this.athleteReport = response;
      this.selectdata = response.filter((assessment: any) =>
        (this.IsAdmin || this.isScienceStaff )  ? assessment.athlete == this.athleteId : assessment.athlete == this.athleteId && assessment.assessorId == this.userId
      );

      this.selectdata.forEach((assessment: any) => {
        switch (assessment.testColumnName) {
          case "pfaHeight":
            this.pfaHeight = assessment.matchedColumnData;
            break;
          case "pfaWeight":
            this.pfaWeight = assessment.matchedColumnData;
            break;
          case "pfaBMI":
            this.pfaBMI = assessment.matchedColumnData;
            break;
          case "pfaSpeed":
            this.pfaSpeed = assessment.matchedColumnData;
            break;
          case "pfaYoYoTest":
            this.pfaYoYoTest = assessment.matchedColumnData;
            break;
          case "pfaSingleLegBalanceLeftEyesOpen":
            this.pfaSingleLegBalanceLeftEyesOpen = assessment.matchedColumnData;
            break;
          case "pfaSingleLegBalanceLeftEyesClose":
            this.pfaSingleLegBalanceLeftEyesClose = assessment.matchedColumnData;
            break;
          case "pfaSingleLegBalanceRightEyesOpen":
            this.pfaSingleLegBalanceRightEyesOpen = assessment.matchedColumnData;
            break;
          case "pfaSingleLegBalanceRightEyesClose":
            this.pfaSingleLegBalanceRightEyesClose = assessment.matchedColumnData;
            break;
          case "pfaYBalanceTest1":
            this.pfaYBalanceTest1 = assessment.matchedColumnData;
            break;
          case "pfaYBalanceTest2":
            this.pfaYBalanceTest2 = assessment.matchedColumnData;
            break;
          case "pfaYBalanceTest3":
            this.pfaYBalanceTest3 = assessment.matchedColumnData;
            break;
          case "pfaStarExcursionTest1":
            this.pfaStarExcursionTest1 = assessment.matchedColumnData;
            break;
          case "pfaStarExcursionTest2":
            this.pfaStarExcursionTest2 = assessment.matchedColumnData;
            break;
          case "pfaStarExcursionTest3":
            this.pfaStarExcursionTest3 = assessment.matchedColumnData;
            break;
          case "pfaPushups":
            this.pfaPushups = assessment.matchedColumnData;
            break;
          case "pfaPullups":
            this.pfaPullups = assessment.matchedColumnData;
            break;
          case "pfaPullAndHang":
            this.pfaPullAndHang = assessment.matchedColumnData;
            break;
          case "pfaSquat":
            this.pfaSquat = assessment.matchedColumnData;
            break;
          case "pfaSingleLegSquat":
            this.pfaSingleLegSquat = assessment.matchedColumnData;
            break;
          case "pfaPartialCurlUp":
            this.pfaPartialCurlUp = assessment.matchedColumnData;
            break;
          case "pfaGripStrengthRight":
            this.pfaGripStrengthRight = assessment.matchedColumnData;
            break;
          case "pfaGripStrengthLeft":
            this.pfaGripStrengthLeft = assessment.matchedColumnData;
            break;
          case "pfaSitNReach":
            this.pfaSitNReach = assessment.matchedColumnData;
            break;
          case "pfaAppleysScratchTest":
            this.pfaAppleysScratchTest = assessment.matchedColumnData;
            break;
          case "pfa50mSprint":
            this.pfa50mSprint = assessment.matchedColumnData;
            break;
          case "pfa30mSprint":
            this.pfa30mSprint = assessment.matchedColumnData;
            break;
          case "pfa10mShuttleRun":
            this.pfa10mShuttleRun = assessment.matchedColumnData;
            break;
          case "pfaTTest":
            this.pfaTTest = assessment.matchedColumnData;
            break;
          case "pfaStandingBroadJump":
            this.pfaStandingBroadJump = assessment.matchedColumnData;
            break;
          case "pfaVerticalJump":
            this.pfaVerticalJump = assessment.matchedColumnData;
            break;
          case "pfaSeatedMedBallThrow":
            this.pfaSeatedMedBallThrow = assessment.matchedColumnData;
            break;
          case "pfaUpperBodyForce":
            this.pfaUpperBodyForce = assessment.matchedColumnData;
            break;
          case "pfaUpperBodyPower":
            this.pfaUpperBodyPower = assessment.matchedColumnData;
            break;
          case "pfaLowerBodyPower":
            this.pfaLowerBodyPower = assessment.matchedColumnData;
            break;
          case "pfaLowerBodyForce":
            this.pfaLowerBodyForce = assessment.matchedColumnData;
            break;
          case "pfaLevel":
            this.pfaLevel = assessment.matchedColumnData;
            break;
          case "pfaVO2":
            this.pfaVO2 = assessment.matchedColumnData;
            break;
        }
      });

      // Assign common athlete information
      if (this.selectdata.length > 0) {
        const firstData = this.selectdata[0];
        this.athleteFullName = firstData.athleteFullName;
        this.date = firstData.date;
        this.assessmentDate = firstData.assessmentDate;
        this.athleteId = firstData.athlete;
      }
    });
  }

  downloadPDF(): void {
    const DATA = document.getElementById('pdf-content');
    if (DATA) {
      html2canvas(DATA).then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgProps = pdf.getImageProperties(imgData);
        const imgWidth = pdfWidth * 0.8; // 80% of the page width
        const imgHeight = (imgProps.height * imgWidth) / imgProps.width;
        const margin = (pdfWidth - imgWidth) / 2;
        const topMargin = 20; // Add top margin

        pdf.addImage(imgData, 'PNG', margin, topMargin, imgWidth, imgHeight);
        pdf.save('PhysicalFitness.pdf');
      });
    }
  }

  onBack(): void {
    this.location.back();
  }

}