import { AgGridAngular } from "ag-grid-angular";
import { ColDef } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { Component, NgZone } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ActivatedRoute, Router, RouterLink, RouterModule, RouterOutlet } from "@angular/router";
import { AssessmentService } from "../../../services/assessment.service";
import { Privilege } from "../../../model/Privillage";
import { PrivilegeService } from "../../../services/privilage.service";
import { User } from "../../../model/User";
import { UserService } from "../../../services/user.service";
  // Row Data: The data to be displayed.
  interface IRow {
    playername: string;
    Assessor: string;
    date: string;
    vuUploader : string;

   
  }

@Component({
  selector: 'app-video-analysis-detail-list',
  standalone: true,
  imports: [CommonModule,RouterModule,RouterLink,AgGridAngular],
  templateUrl: './video-analysis-detail-list.component.html',
  styleUrl: './video-analysis-detail-list.component.css'
})
export class VideoAnalysisDetailListComponent  {
  user: User | null = null;
  isAdmin: boolean = false;
  isPlayer: boolean = false;
 
  themeClass = 'ag-theme-quartz';
 
  rowData: IRow[] = [];
 
  colDefs: ColDef[] = [];
 
  defaultColDef: ColDef = {
    flex: 1,
    sortable: true,
    filter: true,
  };
  id: any;
  privilege: Privilege;
  Isplayer: boolean;
  Admin: boolean;
 
  constructor(private router: Router, private route: ActivatedRoute,private ngZone: NgZone,private assessmentService: AssessmentService,private priService:PrivilegeService,private userService:UserService) {

    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
      console.log(this.id,"ID");
    });
    this.privilege = this.priService.getPrivileges();
    this.Isplayer = this.privilege.isPlayer;
    this.Admin = this.privilege.isAdmin;
  }
 
  ngOnInit() {


    
    this.user = this.userService.getUser();
    if(this.id){
    this.assessmentService.getVideoAnalysisAthelete(this.id).subscribe((response:any) => {
      console.log('Assessment submitted', response);
 
      this.rowData = response.map((item:any) => ({
        vuUploader:`${item.vuUploader}`,
        playername: `${item.usrFullName}`,
        vuId:`${item.vuId}`,
        Assessor: `Assessor ${item.vuTitle}`,
        Speed: `${item.vuTechniqueAnalysis}`,
        Strength: `${item.vuPerformanceAnalysis}`,
        ReactionTime: `${item.vuPerformanceAnalysis}`,
        date: new Date(item.vuDate).toLocaleDateString('en-US'),
      
      }));
 
      this.colDefs = this.getColumnDefinitions();
    }, (error: any) => {
      console.error('Error submitting assessment', error);
    });
 
  }
  }
 
  getPriority(item: any): string {
    if (item.vuTitle > 0 || item.vuTitle > 0) {
      return 'High';
    } else {
      return 'Low';
    }
  }
 
  getColumnDefinitions(): ColDef[] {
    const columns: ColDef[] = [
      { field: 'playername' },
      { field: 'Assessor' },
      { field: 'Speed' },
      { field: 'Strength' },
      { field: 'ReactionTime' },
      { field: 'date' },
 
      {
        headerName: 'Action',
        cellRenderer: (_params: any) => {
          const container = document.createElement('div');
          container.style.display = 'flex';
          container.style.justifyContent = 'space-around';
 
          // View button with eye icon
          const viewButton = document.createElement('button');
          viewButton.classList.add('btn');
          viewButton.innerHTML = '<i class="fa fa-eye"></i>';
          viewButton.addEventListener('click', () => {this.ngZone.run(() => {
            this.router.navigate(['/videoanalysisdetail'], {queryParams: { id: _params.data.vuId } })
          })
          });
 
          // Edit button with edit icon
          const editButton = document.createElement('button');
          editButton.classList.add('btn');
          editButton.innerHTML = '<i class="fa fa-edit"></i>';
          editButton.addEventListener('click', () => {
            this.ngZone.run(() => {
            this.router.navigate(['/VideoAnaalysisedit'], {queryParams: { id: _params.data.vuId } })
            })
          }); 
          container.appendChild(viewButton);
          if (!this.Isplayer) {
          container.appendChild(editButton);
          }
          return container;
        },
        width: 120
      }
    ];
 
    if (this.isAdmin) {
      return columns;
    } else if (this.isPlayer) {
      return columns.filter(col => col.field !== 'playername');
    } else {
      return columns.filter(col => col.field !== 'Assessor');
    }
  }
}
