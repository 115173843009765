import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators'; // Import map operator
import { environment } from '../../environments/environment.prod';
import { SharedService } from './shared.service';

interface ColumnInfo {
  columnName: string;
  dataType: string;
}

@Injectable({
  providedIn: 'root'
})
export class DynamicColumnService {
  private columnNameMap: { [key: string]: string } = {};

  constructor(private http: HttpClient, private sharedService: SharedService) { }

  private getAuthHeaders(): HttpHeaders {
    const token = this.sharedService.getToken();
    return new HttpHeaders({
      'Authorization': token ? `Bearer ${token}` : ''
    });
  }

  getColumns(tableName: string): Observable<ColumnInfo[]> {
    return this.http.get<ColumnInfo[]>(`${environment.BaseUrl}${environment.getcolumnName}${tableName}`, { headers: this.getAuthHeaders() });
  }

  private filterColumns(columns: ColumnInfo[]): ColumnInfo[] {
    const excludeColumns = ['tstId', 'plaCreatedOn', 'plaUpdatedBy', 'plaCreatedBy', 'plaUpdatedOn'];
    return columns.filter(column => !excludeColumns.includes(column.columnName));
  }

  private cutFirstThreeLetters(columnName: string): string {
    return columnName.length > 3 ? columnName.substring(3) : columnName;
  }

  private mapDataTypeToInputType(dataType: string): string {
    switch (dataType.toLowerCase()) {
      case 'nvarchar':
        return 'text';
      case 'int':
      case 'float':
      case 'double':
        return 'number';
      case 'smalldatetime':
        return 'date';
      default:
        return 'text';
    }
  }

  getProcessedColumns(tableName: string): Observable<ColumnInfo[]> {
    return this.getColumns(tableName).pipe(
      map((columns: ColumnInfo[]) => {
        // Save the column name mapping
        this.columnNameMap = this.getColumnNameMap(columns);
        return this.filterColumns(columns).map(column => ({
          ...column,
          columnName: this.cutFirstThreeLetters(column.columnName),
          inputType: this.mapDataTypeToInputType(column.dataType)
        }));
      })
    );
  }

  addColumns(columnData: any): Observable<ColumnInfo[]> {
    return this.http.post<ColumnInfo[]>(`${environment.BaseUrl}${environment.addcolumn}`, columnData, { headers: this.getAuthHeaders() });
  }

  postFormData(tableName: string, data: any): Observable<any> {
    const processedData = this.processColumnNames(data);
    const requestPayload = {
      tableName: tableName,
      data: processedData
    };
    console.log(requestPayload);
    return this.http.post<any>(`${environment.BaseUrl}${environment.saveDynamicdata}${tableName}`, processedData, { headers: this.getAuthHeaders() });
  }

  // postFormData(tableName: string, data: any): Observable<any> {
  //   // Serialize the data object to JSON
  //   const jsonData = JSON.stringify(data);
  
  //   // Create the request payload
  //   const requestPayload = {
  //     tableName: tableName,
  //     data: jsonData
  //   };
  
  //   // Log the request payload for debugging
  //   console.log(requestPayload);
  
  //   // Send the POST request with the payload
  //   return this.http.post<any>(`${environment.BaseUrl}${environment.saveDynamicdata}/add-data`, requestPayload);
  // }
  // Process the column names by prepending the first three letters back
  private processColumnNames(data: any): any {
    const processedData: any = {};

    Object.keys(data).forEach(key => {
      // Find the original column name from the map
      const originalName = this.columnNameMap[key] || key;
      processedData[originalName] = data[key];
    });

    return processedData;
  }

  // Create a mapping of original column names to the modified column names
  private getColumnNameMap(columns: ColumnInfo[]): { [key: string]: string } {
    const map: { [key: string]: string } = {};
    columns.forEach(column => {
      const originalName = column.columnName;
      const modifiedName = this.cutFirstThreeLetters(originalName);
      map[modifiedName] = originalName;
    });
    return map;
  }
}
