<div class="body-wrapper">
    <div class="container emp-profile">
        <div class="tab-content profile-tab" id="myTabContent">
            <div class="tab-pane fade show active" id="home" role="tabpanel">
                <div class="container">

                    <div class="row">
                        <div class="col d-flex justify-content-start">
                            <button type="button" class="btn btn-primary me-2" (click)="onBack()">
                                <span class="btnText">Back</span>
                            </button>
                        </div>
                    </div>
                    <br>
                    <div class="mt-3">
                        <div class="row justify-content-center">
                            <div class="col-md-12">
                                <div class="border rounded bg-white shadow-sm">
                                    <div class="question p-4 border-bottom">
                                        <div class="d-flex justify-content-between">    
                                            <p><strong>Player ID:</strong> {{ athleteId }}</p>
                                            <div *ngIf="mode === 'edit'" class="dropdown-wrapper mb-2">
                                                <div class="select-wrapper d-flex align-items-center">
                                                    <label for="athleteDropdown" class="player-label">Select
                                                        Player:</label>
                                                    <ng-select  [(ngModel)]="athleteId" [items]="playerList" bindLabel="displayLabel" bindValue="PlayerId" name="athleteId"
                                                    (change)="onAthleteChange($event)" [searchable]="false" [clearable]="false" class="ng-select-custom">
                                                    <ng-template ng-label-tmp let-item="item">
                                                      <span>{{ item.displayLabel }}</span>
                                                      <i class="fa fa-chevron-down"></i>
                                                    </ng-template>
                                                    <ng-template ng-option-tmp let-item="item">
                                                      <span>{{ item.displayLabel }}</span>
                                                  </ng-template>
                                                  </ng-select>
                                                </div>
                                            </div>
                                            </div>
                                            <div class="row align-items-start">
                                                <div class="d-flex gap-4">
                                                    <div style="min-width: 150px; display: flex; align-items: start;">
                                                        <img *ngIf="showImage && image" src="{{image}}" class="custom-img-width">
                                                        </div>
                                                        <div>
                                                            <div class="profile d-flex align-items-center mb-4">
                                                                <div class="icon-bg">
                                                                    <i class='bx bx-user'></i>
                                                                 </div>
                                                                 <div>
                                                                <h4 *ngIf="Admin || sciStaff">{{
                                                                playerName[0]?.Name }}</h4>
                                                                <p>Player Name</p>
                                                            </div>
                                                                </div>
                                                                <div class="profile d-flex align-items-center mb-4">
                                                                    <div class="icon-bg">
                                                                        <i class='bx bx-envelope'></i>
                                                                     </div>
                                                                     <div>
                                                                    <h4 *ngIf="Admin || sciStaff">{{
                                                                playerName[0].PlayerEmail }}</h4>
                                                                <p>Player Email</p>
                                                            </div>
                                                                    </div>
                                                                    <div class="profile d-flex align-items-center mb-4">
                                                                        <div class="icon-bg">
                                                                            <i class='bx bx-phone'></i>
                                                                         </div>
                                                                         <div>
                                                            <h4 *ngIf="Admin || sciStaff">{{
                                                                playerName[0].PhoneNo }}</h4>
                                                                <p>Player phone number</p>
                                                                </div>
                                                            </div>
                                                                <div class="input-field">
                                                                    <label>Test Taken Date</label>
                                                                    <input class="w-100" [disabled]="mode === 'view'"
                                                                    type="date"
                                                                    name="rcaCreatedOn"
                                                                    [(ngModel)]="formData.rcaCreatedOn " 
                                                                    required
                                                                    [min]="this.minDate"
                                                                    [max]="this.maxDate"
                                                                    (focus)="setDateConstraints()"
                                                                  />
                                                                  <div *ngIf="invalid" class="error-message">
                                                                    Date is required.
                                                                </div>
                                                                  </div>
    
                                                        </div>

                                                    </div>
                                                </div>
                                               


                                      


                                                <div class="d-flex justify-content-between align-items-center">
                                                    <h3>24 Hour Recall Assessment</h3>
                                                </div>






                                                <!-- Heading aligned to the start (left) -->
                                                <h4 class="mb-4"> General Question</h4>
                                                <form class="form" novalidate (ngSubmit)="onSubmit()">
                                                    <div class="row mb-3">
                                                        <div class="col">
                                                            <label for="rcaQ1" class="form-label">
                                                                a. What time did you wake up yesterday? <span
                                                                    class="required">*</span>
                                                            </label>
                                                            <input type="time" id="time" [value]="formData.rcaQ1"
                                                                [disabled]="mode === 'view'" class="form-control"
                                                                [(ngModel)]="formData.rcaQ1" name="rcaQ1"
                                                                placeholder="Enter your time" required>
                                                        </div>
                                                    </div>
                                                    <div class="row mb-3">
                                                        <div class="col">
                                                            <label for="rcaQ2" class="form-label">
                                                                b. What time did you go to bed last night? <span
                                                                    class="required">*</span>
                                                            </label>
                                                            <input type="time" id="rcaQ2" class="form-control"
                                                                [disabled]="mode === 'view'"
                                                                [(ngModel)]="formData.rcaQ2" name="rcaQ2"
                                                                placeholder="Enter your time" required>
                                                        </div>
                                                    </div>
                                                    <div class="row mb-3">
                                                        <div class="col">
                                                            <label for="question" class="form-label">
                                                                c. Did you have any unusual activities or events
                                                                yesterday that might have influenced your eating habits?
                                                                <span class="required">*</span>
                                                            </label>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio" 
                                                                name="rcaQ3" id="rcaQ3" [(ngModel)]="formData.rcaQ3" 
                                                                value="Yes" [disabled]="mode === 'view'">
                                                                <label class="form-check-label" 
                                                                for="rcaQ3">Yes</label>
                                                            </div>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio" 
                                                                name="rcaQ3" id="rcaQ3No" [(ngModel)]="formData.rcaQ3" 
                                                                value="No" [disabled]="mode === 'view'">
                                                                <label class="form-check-label" 
                                                                for="rcaQ3No">No</label>
                                                            </div>


                                                            <div class="input-fields"> 
                                                                <div *ngIf="formData.rcaQ3 === 'Yes'">
                                                                    <input type="text" id="rcaQ4" class="form-control" 
                                                                    [(ngModel)]="formData.rcaQ4" name="rcaQ4" 
                                                                    [disabled]="mode === 'view'" #rcaQ4="ngModel" 
                                                                    placeholder="Please explain" required [ngClass]="{ 
                                                                        'invalid-field': rcaQ4.invalid
                                                                        }">
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>


                                                    <h4 class="mb-4"> Meal and Snack Intake</h4>

                                                    <div class="row mb-3">
                                                        <div class="col">
                                                            <label for="breakfast" class="form-label">a.
                                                                Breakfast<span
                                                                class="required">*</span></label>

                                                            <!-- Time Input -->
                                                            <div class="mb-2">
                                                                <label for="breakfastTime" class="form-label">Time:
                                                                    </label>
                                                                <input type="time" id="rcaQ5" class="form-control"
                                                                    [(ngModel)]="formData.rcaQ5" name="rcaQ5" required
                                                                    [disabled]="mode === 'view'">
                                                            </div>

                                                            <!-- Food/Beverage Consumed Input -->
                                                            <div class="mb-2">
                                                                <label for="foodBeverage"
                                                                    class="form-label">Food/Beverage Consumed: <span
                                                                        class="required">*</span> </label>
                                                                <input type="text" id="rcaQ6" class="form-control"
                                                                    [(ngModel)]="formData.rcaQ6" name="rcaQ6"
                                                                    [disabled]="mode === 'view'"
                                                                    placeholder="Enter food/beverage" required
                                                                    (keypress)="validateAlphabet($event)">
                                                            </div>

                                                            <!-- Quantity Input -->
                                                            <div class="mb-2">
                                                                <label for="quantity" class="form-label">Quantity:<span
                                                                        class="required">*</span> </label>
                                                                <input type="text" id="rcaQ7" class="form-control"
                                                                    [(ngModel)]="formData.rcaQ7" name="rcaQ7"
                                                                    [disabled]="mode === 'view'"
                                                                    placeholder="Enter quantity" required
                                                                    (keypress)="validateNumber($event)">
                                                            </div>

                                                            <!-- Additional Details Input -->
                                                            <div class="mb-2">
                                                                <label for="additionalDetails" class="form-label">Any
                                                                    additional details (e.g., condiments, sides,
                                                                    drinks):</label>
                                                                <textarea id="rcaQ8" class="form-control"
                                                                    [(ngModel)]="formData.rcaQ8" name="rcaQ8"
                                                                    [disabled]="mode === 'view'"
                                                                    placeholder="Enter additional details"
                                                                    rows="3"></textarea>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="row mb-3">
                                                        <div class="col">
                                                            <label for="breakfast" class="form-label">b. Morning
                                                                Snack</label>

                                                            <!-- Time Input -->
                                                            <div class="mb-2">
                                                                <label for="breakfastTime" class="form-label">Time:</label>
                                                                <input type="time" id="rcaQ9" class="form-control"
                                                                    [disabled]="mode === 'view'"
                                                                    [(ngModel)]="formData.rcaQ9" name="rcaQ9">
                                                            </div>

                                                            <!-- Food/Beverage Consumed Input -->
                                                            <div class="mb-2">
                                                                <label for="foodBeverage"
                                                                    class="form-label">Food/Beverage Consumed:</label>
                                                                <input type="text" id="rcaQ10" class="form-control"
                                                                    [(ngModel)]="formData.rcaQ10" name="rcaQ10"
                                                                    [disabled]="mode === 'view'"
                                                                    placeholder="Enter food/beverage"
                                                                    (keypress)="validateAlphabet($event)">
                                                            </div>

                                                            <!-- Quantity Input -->
                                                            <div class="mb-2">
                                                                <label for="quantity" class="form-label">Quantity:</label>
                                                                <input type="text" id="rcaQ11" class="form-control"
                                                                    [(ngModel)]="formData.rcaQ11" name="rcaQ11"
                                                                    [disabled]="mode === 'view'"
                                                                    placeholder="Enter quantity"
                                                                    (keypress)="validateNumber($event)">
                                                            </div>

                                                            <!-- Additional Details Input -->
                                                            <div class="mb-2">
                                                                <label for="additionalDetails" class="form-label">Any
                                                                    additional details (e.g., condiments, sides,
                                                                    drinks):</label>
                                                                <textarea id="rcaQ12" class="form-control"
                                                                    [(ngModel)]="formData.rcaQ12" name="rcaQ12"
                                                                    [disabled]="mode === 'view'"
                                                                    placeholder="Enter additional details"
                                                                    rows="3"></textarea>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div class="row mb-3">
                                                        <div class="col">
                                                            <label for="breakfast" class="form-label">c. Lunch<span
                                                                class="required">*</span></label>

                                                            <!-- Time Input -->
                                                            <div class="mb-2">
                                                                <label for="breakfastTime" class="form-label">Time:<span
                                                                        class="required">*</span></label>
                                                                <input type="time" id="rcaQ13" class="form-control"
                                                                    [disabled]="mode === 'view'"
                                                                    [(ngModel)]="formData.rcaQ13" name="rcaQ13"
                                                                    required>
                                                            </div>

                                                            <!-- Food/Beverage Consumed Input -->
                                                            <div class="mb-2">
                                                                <label for="foodBeverage"
                                                                    class="form-label">Food/Beverage Consumed:<span
                                                                        class="required">*</span></label>
                                                                <input type="text" id="rcaQ14" class="form-control"
                                                                    [disabled]="mode === 'view'"
                                                                    [(ngModel)]="formData.rcaQ14" name="rcaQ14"
                                                                    placeholder="Enter food/beverage" required
                                                                    (keypress)="validateAlphabet($event)">
                                                            </div>

                                                            <!-- Quantity Input -->
                                                            <div class="mb-2">
                                                                <label for="quantity" class="form-label">Quantity:<span
                                                                        class="required">*</span></label>
                                                                <input type="text" id="rcaQ15" class="form-control"
                                                                    [disabled]="mode === 'view'"
                                                                    [(ngModel)]="formData.rcaQ15" name="rcaQ15"
                                                                    placeholder="Enter quantity" required
                                                                    (keypress)="validateNumber($event)">
                                                            </div>

                                                            <!-- Additional Details Input -->
                                                            <div class="mb-2">
                                                                <label for="additionalDetails" class="form-label">Any
                                                                    additional details (e.g., condiments, sides,
                                                                    drinks):</label>
                                                                <textarea id="rcaQ16" class="form-control"
                                                                    [(ngModel)]="formData.rcaQ16" name="rcaQ16"
                                                                    placeholder="Enter additional details"
                                                                    [disabled]="mode === 'view'" rows="3"></textarea>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="row mb-3">
                                                        <div class="col">
                                                            <label for="breakfast" class="form-label">d. Afternoon
                                                                Snack</label>

                                                            <!-- Time Input -->
                                                            <div class="mb-2">
                                                                <label for="breakfastTime" class="form-label">Time:</label>
                                                                <input type="time" id="rcaQ17"
                                                                    [(ngModel)]="formData.rcaQ17" name="rcaQ17"
                                                                    [disabled]="mode === 'view'" class="form-control"
                                                                >
                                                            </div>

                                                            <!-- Food/Beverage Consumed Input -->
                                                            <div class="mb-2">
                                                                <label for="foodBeverage"
                                                                    class="form-label">Food/Beverage Consumed:</label>
                                                                <input type="text" id="rcaQ18"
                                                                    [(ngModel)]="formData.rcaQ18" name="rcaQ18"
                                                                    [disabled]="mode === 'view'" class="form-control"
                                                                    placeholder="Enter food/beverage"
                                                                    (keypress)="validateAlphabet($event)">
                                                            </div>

                                                            <!-- Quantity Input -->
                                                            <div class="mb-2">
                                                                <label for="quantity" class="form-label">Quantity:</label>
                                                                <input type="text" id="rcaQ19"
                                                                    [(ngModel)]="formData.rcaQ19" name="rcaQ19"
                                                                    [disabled]="mode === 'view'" class="form-control"
                                                                    placeholder="Enter quantity"
                                                                    (keypress)="validateNumber($event)">
                                                            </div>

                                                            <!-- Additional Details Input -->
                                                            <div class="mb-2">
                                                                <label for="additionalDetails" class="form-label">Any
                                                                    additional details (e.g., condiments, sides,
                                                                    drinks):</label>
                                                                <textarea id="rcaQ20" [(ngModel)]="formData.rcaQ20"
                                                                    [disabled]="mode === 'view'" name="rcaQ20"
                                                                    class="form-control"
                                                                    placeholder="Enter additional details"
                                                                    rows="3"></textarea>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="row mb-3">
                                                        <div class="col">
                                                            <label for="breakfast" class="form-label">e. Dinner<span
                                                                class="required">*</span></label>

                                                            <!-- Time Input -->
                                                            <div class="mb-2">
                                                                <label for="breakfastTime" class="form-label">Time:<span
                                                                        class="required">*</span></label>
                                                                <input type="time" id="rcaQ21"
                                                                    [(ngModel)]="formData.rcaQ21" name="rcaQ21"
                                                                    [disabled]="mode === 'view'" class="form-control"
                                                                    required>
                                                            </div>

                                                            <!-- Food/Beverage Consumed Input -->
                                                            <div class="mb-2">
                                                                <label for="foodBeverage"
                                                                    class="form-label">Food/Beverage Consumed:<span
                                                                        class="required">*</span></label>
                                                                <input type="text" id="rcaQ22"
                                                                    [(ngModel)]="formData.rcaQ22" name="rcaQ22"
                                                                    [disabled]="mode === 'view'" class="form-control"
                                                                    placeholder="Enter food/beverage" required
                                                                    (keypress)="validateAlphabet($event)">
                                                            </div>

                                                            <!-- Quantity Input -->
                                                            <div class="mb-2">
                                                                <label for="quantity" class="form-label">Quantity:<span
                                                                        class="required">*</span></label>
                                                                <input type="text" id="rcaQ23"
                                                                    [(ngModel)]="formData.rcaQ23" name="rcaQ23"
                                                                    [disabled]="mode === 'view'" class="form-control"
                                                                    placeholder="Enter quantity" required
                                                                    (keypress)="validateNumber($event)">
                                                            </div>

                                                            <!-- Additional Details Input -->
                                                            <div class="mb-2">
                                                                <label for="additionalDetails" class="form-label">Any
                                                                    additional details (e.g., condiments, sides,
                                                                    drinks):</label>
                                                                <textarea id="rcaQ24" [(ngModel)]="formData.rcaQ24"
                                                                    [disabled]="mode === 'view'" name="rcaQ24"
                                                                    class="form-control"
                                                                    placeholder="Enter additional details"
                                                                    rows="3"></textarea>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="row mb-3">
                                                        <div class="col">
                                                            <label for="breakfast" class="form-label">f. Evening
                                                                Snack</label>

                                                            <!-- Time Input -->
                                                            <div class="mb-2">
                                                                <label for="breakfastTime" class="form-label">Time:</label>
                                                                <input type="time" id="rcaQ25"
                                                                    [(ngModel)]="formData.rcaQ25" name="rcaQ25"
                                                                    [disabled]="mode === 'view'" class="form-control"
                                                                >
                                                            </div>

                                                            <!-- Food/Beverage Consumed Input -->
                                                            <div class="mb-2">
                                                                <label for="foodBeverage"
                                                                    class="form-label">Food/Beverage Consumed:</label>
                                                                <input type="text" id="rcaQ26"
                                                                    [(ngModel)]="formData.rcaQ26" name="rcaQ26"
                                                                    [disabled]="mode === 'view'" class="form-control"
                                                                    placeholder="Enter food/beverage"
                                                                    (keypress)="validateAlphabet($event)">
                                                            </div>

                                                            <!-- Quantity Input -->
                                                            <div class="mb-2">
                                                                <label for="quantity" class="form-label">Quantity:</label>
                                                                <input type="text" id="rcaQ27"
                                                                    [(ngModel)]="formData.rcaQ27" name="rcaQ27"
                                                                    [disabled]="mode === 'view'" class="form-control"
                                                                    placeholder="Enter quantity"
                                                                    (keypress)="validateNumber($event)">
                                                            </div>

                                                            <!-- Additional Details Input -->
                                                            <div class="mb-2">
                                                                <label for="additionalDetails" class="form-label">Any
                                                                    additional details (e.g., condiments, sides,
                                                                    drinks):</label>
                                                                <textarea id="rcaQ28" [(ngModel)]="formData.rcaQ28"
                                                                    [disabled]="mode === 'view'" name="rcaQ28"
                                                                    class="form-control"
                                                                    placeholder="Enter additional details"
                                                                    rows="3"></textarea>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <h4 class="mb-4"> Beverages</h4>
                                                    <div class="row mb-3">
                                                        <div class="col">
                                                            <label for="question" class="form-label">
                                                                a. How much water did you drink yesterday?<span
                                                                    class="required">*</span>
                                                            </label>
                                                            <!-- Radio buttons for water consumption -->
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio"
                                                                    name="rcaQ29" id="rcaQ29" value="Less than 1 liter"
                                                                    [disabled]="mode === 'view'"
                                                                    [(ngModel)]="formData.rcaQ29">
                                                                <label class="form-check-label"
                                                                    for="lessThan1Liter">Less than 1 liter</label>
                                                            </div>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio"
                                                                    name="rcaQ29" id="rcaQ29" value="1-2 liters"
                                                                    [disabled]="mode === 'view'"
                                                                    [(ngModel)]="formData.rcaQ29">
                                                                <label class="form-check-label" for="1to2Liters">1-2
                                                                    liters</label>
                                                            </div>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio"
                                                                    name="rcaQ29" id="rcaQ29" value="2-3 liters"
                                                                    [disabled]="mode === 'view'"
                                                                    [(ngModel)]="formData.rcaQ29">
                                                                <label class="form-check-label" for="2to3Liters">2-3
                                                                    liters</label>
                                                            </div>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio"
                                                                    name="rcaQ29" id="rcaQ29" value="More than 3 liters"
                                                                    [disabled]="mode === 'view'"
                                                                    [(ngModel)]="formData.rcaQ29">
                                                                <label class="form-check-label"
                                                                    for="moreThan3Liters">More than 3 liters</label>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div class="row mb-3">
                                                        <div class="col">
                                                            <label for="question" class="form-label">
                                                                b. Did you consume any of the following beverages
                                                                yesterday? (Check all that apply)
                                                            </label>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="rcaQ30" id="rcaQ30" value="Coffee"
                                                                    [disabled]="mode === 'view'"
                                                                    [(ngModel)]="formData.rcaQ30"
                                                                    (ngModelChange)="onBeverageChange('Coffee', formData.rcaQ30)">
                                                                <label class="form-check-label"
                                                                    for="rcaQ30">Coffee</label>

                                                                <br>

                                                                <div *ngIf="formData.rcaQ30 === 'Coffee' || formData.rcaQ30 === 'Yes'" class="row">
                                                                    <div class="col-auto">


                                                                        <div class="form-check">
                                                                            <input class="form-check-input"
                                                                                type="checkbox" name="rcaQ31"
                                                                                id="rcaQ31" value="withmilk"
                                                                                [disabled]="mode === 'view'"
                                                                                [(ngModel)]="formData.rcaQ31"
                                                                                (ngModelChange)="onBeverageChange('withmilk', formData.rcaQ31)">
                                                                            <label class="form-check-label"
                                                                                for="rcaQ31">with milk</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-auto">

                                                                        <div class="form-check">

                                                                            <input class="form-check-input"
                                                                                type="checkbox" name="rcaQ32"
                                                                                id="rcaQ32" value="Black"
                                                                                [disabled]="mode === 'view'"
                                                                                [(ngModel)]="formData.rcaQ32"
                                                                                (ngModelChange)="onBeverageChange('Black', formData.rcaQ32)">
                                                                            <label class="form-check-label"
                                                                                for="rcaQ32">Black</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-auto">

                                                                        <div class="form-check">

                                                                            <input class="form-check-input"
                                                                                type="checkbox" name="rcaQ33"
                                                                                id="rcaQ33" value="withSugar"
                                                                                [disabled]="mode === 'view'"
                                                                                [(ngModel)]="formData.rcaQ33"
                                                                                (ngModelChange)="onBeverageChange('withSugar', formData.rcaQ33)">
                                                                            <label class="form-check-label"
                                                                                for="rcaQ33">with Sugar</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="rcaQ34" id="rcaQ34" value="Tea"
                                                                    [disabled]="mode === 'view'"
                                                                    [(ngModel)]="formData.rcaQ34"
                                                                    (ngModelChange)="onBeverageChange('Tea', formData.rcaQ34)">
                                                                <label class="form-check-label" for="rcaQ34">Tea</label>


                                                                <div *ngIf="formData.rcaQ34 === 'Tea'  || formData.rcaQ34 === 'Yes'" class="row">
                                                                    <div class="col-auto">
                                                                        <div class="form-check">
                                                                            <input class="form-check-input"
                                                                                type="checkbox" name="rcaQ35"
                                                                                id="rcaQ35" value="withmilks"
                                                                                [disabled]="mode === 'view'"
                                                                                [(ngModel)]="formData.rcaQ35"
                                                                                (ngModelChange)="onBeverageChange('withmilks', formData.rcaQ35)">
                                                                            <label class="form-check-label"
                                                                                for="rcaQ35">with milk</label>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-auto">

                                                                        <div class="form-check">

                                                                            <input class="form-check-input"
                                                                                type="checkbox" name="rcaQ36"
                                                                                id="rcaQ36" value="Blacks"
                                                                                [disabled]="mode === 'view'"
                                                                                [(ngModel)]="formData.rcaQ36"
                                                                                (ngModelChange)="onBeverageChange('Blacks', formData.rcaQ36)">
                                                                            <label class="form-check-label"
                                                                                for="rcaQ36">Black</label>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-auto">

                                                                        <div class="form-check">

                                                                            <input class="form-check-input"
                                                                                type="checkbox" name="rcaQ37"
                                                                                id="rcaQ37" value="withSugars"
                                                                                [disabled]="mode === 'view'"
                                                                                [(ngModel)]="formData.rcaQ37"
                                                                                (ngModelChange)="onBeverageChange('withSugars', formData.rcaQ37)">
                                                                            <label class="form-check-label"
                                                                                for="rcaQ37">with Sugar</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="rcaQ38" id="sports-drinks"
                                                                    [disabled]="mode === 'view'" value="Sports Drinks"
                                                                    [(ngModel)]="formData.rcaQ38"
                                                                    (ngModelChange)="onBeverageChange('Sports Drinks', formData.rcaQ38)">
                                                                <label class="form-check-label"
                                                                    for="sports-drinks">Sports Drink</label>
                                                            </div>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="rcaQ39" id="Soft-drinks"
                                                                    [disabled]="mode === 'view'" value="Soft Drinks"
                                                                    [(ngModel)]="formData.rcaQ39"
                                                                    (ngModelChange)="onBeverageChange('Soft Drinks', formData.rcaQ39)">
                                                                <label class="form-check-label" for="sports-drinks">Soft
                                                                    Drink</label>

                                                                <div *ngIf="formData.rcaQ39 === 'Soft Drinks'  || formData.rcaQ39 === 'Yes'"
                                                                    class="row">
                                                                    <div class="col-auto">
                                                                        <div class="form-check">
                                                                            <input class="form-check-input"
                                                                                type="checkbox" name="rcaQ40"
                                                                                id="rcaQ40" value="Regular"
                                                                                [disabled]="mode === 'view'"
                                                                                [(ngModel)]="formData.rcaQ40"
                                                                                (ngModelChange)="onBeverageChange('Regular', formData.rcaQ40)">
                                                                            <label class="form-check-label"
                                                                                for="rcaQ40">Regular</label>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-auto">
                                                                        <div class="form-check">
                                                                            <input class="form-check-input"
                                                                                type="checkbox" name="rcaQ41"
                                                                                id="rcaQ41" value="Diet"
                                                                                [disabled]="mode === 'view'"
                                                                                [(ngModel)]="formData.rcaQ41"
                                                                                (ngModelChange)="onBeverageChange('Diet', formData.rcaQ41)">
                                                                            <label class="form-check-label"
                                                                                for="rcaQ41">Diet</label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="rcaQ42" id="rcaQ42" value="Juice"
                                                                    [disabled]="mode === 'view'"
                                                                    [(ngModel)]="formData.rcaQ42"
                                                                    (ngModelChange)="onBeverageChange('Juice', formData.rcaQ42)">
                                                                <label class="form-check-label"
                                                                    for="rcaQ42">Juice</label>
                                                            </div>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="rcaQ43" id="rcaQ43" value="Alcohol"
                                                                    [disabled]="mode === 'view'"
                                                                    [(ngModel)]="formData.rcaQ43"
                                                                    (ngModelChange)="onBeverageChange('Alcohol', formData.rcaQ43)">
                                                                <label class="form-check-label"
                                                                    for="rcaQ43">Alcohol</label>
                                                            </div>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="rcaQ44" id="rcaQ44" value="Milk"
                                                                    [disabled]="mode === 'view'"
                                                                    [(ngModel)]="formData.rcaQ44"
                                                                    (ngModelChange)="onBeverageChange('Milk', formData.rcaQ44)">
                                                                <label class="form-check-label"
                                                                    for="rcaQ44">Milk</label>

                                                                <div *ngIf="formData.rcaQ44 === 'Milk'  || formData.rcaQ44 === 'Yes'" class="row">
                                                                    <div class="col-auto">
                                                                        <div class="form-check">
                                                                            <input class="form-check-input"
                                                                                type="checkbox" name="rcaQ45"
                                                                                id="rcaQ45" value="Whole"
                                                                                [disabled]="mode === 'view'"
                                                                                [(ngModel)]="formData.rcaQ45"
                                                                                (ngModelChange)="onBeverageChange('Whole', formData.rcaQ45)">
                                                                            <label class="form-check-label"
                                                                                for="rcaQ45">Whole</label>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-auto">

                                                                        <div class="form-check">

                                                                            <input class="form-check-input"
                                                                                type="checkbox" name="rcaQ46"
                                                                                id="rcaQ46" value="Skimmed"
                                                                                [disabled]="mode === 'view'"
                                                                                [(ngModel)]="formData.rcaQ46"
                                                                                (ngModelChange)="onBeverageChange('Skimmed', formData.rcaQ46)">
                                                                            <label class="form-check-label"
                                                                                for="rcaQ46">Skimmed</label>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-auto">

                                                                        <div class="form-check">

                                                                            <input class="form-check-input"
                                                                                type="checkbox" name="rcaQ47"
                                                                                id="rcaQ47" value="Almond"
                                                                                [disabled]="mode === 'view'"
                                                                                [(ngModel)]="formData.rcaQ47"
                                                                                (ngModelChange)="onBeverageChange('Almond', formData.rcaQ33)">
                                                                            <label class="form-check-label"
                                                                                for="rcaQ47">Almond</label>
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>







                                                    <h4 class="mb-4"> Additional Nutrition Information</h4>

                                                    <div class="row mb-3">
                                                        <div class="col">
                                                            <label for="question" class="form-label">
                                                                a. Did you consume any supplements (protein, vitamins,
                                                                etc.)? If yes, please specify the type and dosage.
                                                                <span class="required">*</span>
                                                            </label>

                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio"
                                                                    name="rcaQ49" id="rcaQ48"
                                                                    [disabled]="mode === 'view'"
                                                                    [(ngModel)]="formData.rcaQ49" value="Yes">
                                                                <label class="form-check-label" for="rcaQ49">Yes</label>
                                                            </div>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio"
                                                                    name="rcaQ49" id="rcaQ49"
                                                                    [disabled]="mode === 'view'"
                                                                    [(ngModel)]="formData.rcaQ49" value="No">
                                                                <label class="form-check-label" for="rcaQ49">No</label>
                                                            </div>

                                                            <div class="input-fields">
                                                                <div *ngIf="formData.rcaQ49 === 'Yes'">
                                                                    <input type="text" id="rcaQ50" class="form-control"
                                                                        [(ngModel)]="formData.rcaQ50" name="rcaQ50"
                                                                        [disabled]="mode === 'view'" #rcaQ50="ngModel"
                                                                        placeholder="Please explain" required [ngClass]="{
                                                                            'invalid-field': rcaQ50.invalid,
                                                                          }">
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>


                                                    <div class="row mb-3">
                                                        <div class="col">
                                                            <label for="question" class="form-label">
                                                                b. How would you rate the balance of your meals (e.g.,
                                                                protein, carbs, fats)?
                                                                <span class="required">*</span>
                                                            </label>
                                                            <!-- Radio buttons for water consumption -->
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio"
                                                                    name="rcaQ51" id="rcaQ51" value="poor"
                                                                    [disabled]="mode === 'view'"
                                                                    [(ngModel)]="formData.rcaQ51">
                                                                <label class="form-check-label"
                                                                    for="lessThan1Liter">poor</label>
                                                            </div>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio"
                                                                    name="rcaQ51" id="rcaQ51" value="Fair"
                                                                    [disabled]="mode === 'view'"
                                                                    [(ngModel)]="formData.rcaQ51">
                                                                <label class="form-check-label" for="1to2Liters">
                                                                    Fair</label>
                                                            </div>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio"
                                                                    name="rcaQ51" id="rcaQ51" value="Good"
                                                                    [disabled]="mode === 'view'"
                                                                    [(ngModel)]="formData.rcaQ51">
                                                                <label class="form-check-label" for="2to3Liters">
                                                                    Good</label>
                                                            </div>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio"
                                                                    name="rcaQ51" id="rcaQ51" value="Very Good"
                                                                    [disabled]="mode === 'view'"
                                                                    [(ngModel)]="formData.rcaQ51">
                                                                <label class="form-check-label"
                                                                    for="moreThan3Liters">Very Good</label>
                                                            </div>

                                                            <div class="form-check">
                                                                
                                                                <input class="form-check-input" type="radio"
                                                                    name="rcaQ51" id="rcaQ51" value="Excellent"
                                                                    [disabled]="mode === 'view'"
                                                                    [(ngModel)]="formData.rcaQ51">
                                                                <label class="form-check-label"
                                                                    for="moreThan3Liters">Excellent</label>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div class="row mb-3">
                                                        <div class="col">
                                                            <label for="question" class="form-label">
                                                                c. Did you feel satisfied or hungry after your meals?
                                                                <span class="required">*</span>
                                                            </label>
                                                            <!-- Radio buttons for water consumption -->
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio"
                                                                    name="rcaQ52" id="rcaQ52" value="Very Hungry"
                                                                    [disabled]="mode === 'view'"
                                                                    [(ngModel)]="formData.rcaQ52">
                                                                <label class="form-check-label"
                                                                    for="lessThan1Liter">Very Hungry</label>
                                                            </div>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio"
                                                                    name="rcaQ52" id="rcaQ52" value="Hungry"
                                                                    [disabled]="mode === 'view'"
                                                                    [(ngModel)]="formData.rcaQ52">
                                                                <label class="form-check-label" for="1to2Liters">
                                                                    Hungry</label>
                                                            </div>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio"
                                                                    name="rcaQ52" id="rcaQ52" value="Neutral"
                                                                    [disabled]="mode === 'view'"
                                                                    [(ngModel)]="formData.rcaQ52">
                                                                <label class="form-check-label" for="2to3Liters">
                                                                    Neutral</label>
                                                            </div>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio"
                                                                    name="rcaQ52" id="rcaQ52" value="Satisfied"
                                                                    [disabled]="mode === 'view'"
                                                                    [(ngModel)]="formData.rcaQ52">
                                                                <label class="form-check-label"
                                                                    for="moreThan3Liters">Satisfied</label>
                                                            </div>

                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio"
                                                                    name="rcaQ52" id="rcaQ52" value="Very Satisfied"
                                                                    [disabled]="mode === 'view'"
                                                                    [(ngModel)]="formData.rcaQ52">
                                                                <label class="form-check-label"
                                                                    for="moreThan3Liters">Very Satisfied</label>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <h4 class="mb-4"> Physical Activity and Eating</h4>
                                                    <div class="row mb-3">
                                                        <div class="col">
                                                            <label for="question" class="form-label">
                                                                a. Did you eat before or after any physical activity
                                                                yesterday?
                                                                <span class="required">*</span>
                                                            </label>


                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio"
                                                                    name="rcaQ53" id="rcaQ53" [(ngModel)]="formData.rcaQ53"
                                                                    [disabled]="mode === 'view'" value="Yes">
                                                                <label class="form-check-label"
                                                                    for="rcaQ53">Yes</label>
                                                            </div>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio"
                                                                    name="rcaQ53" id="rcaQ53" [(ngModel)]="formData.rcaQ53"
                                                                    [disabled]="mode === 'view'" value="No">
                                                                <label class="form-check-label"
                                                                    for="rcaQ53">No</label>
                                                            </div>


                                                            <div *ngIf="formData.rcaQ53 === 'Yes'">
                                                                <!-- Second input field for pre-activity consumption -->
                                                                <label for="preActivity" class="form-label mt-3">b. what
                                                                    did you consume before and/or after your physical
                                                                    activity?
                                                                    <span class="required">*</span></label>
                                                                <div class="input-fields">
                                                                    <input type="text" id="rcaQ54" class="form-control"
                                                                        [(ngModel)]="formData.rcaQ54" name="rcaQ54"
                                                                        [disabled]="mode === 'view'" #rcaQ54="ngModel"
                                                                        placeholder="Please explain" required [ngClass]="{
                                                                            'invalid-field': rcaQ54.invalid,
                                                                          }">
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br>
                                                    <div class="row" *ngIf="mode === 'edit'">
                                                        <div class="col d-flex justify-content-end">

                                                            <button type="submit" class="submit btn btn-primary">
                                                                <span class="btnText">Submit</span>
                                                            </button>

                                                        </div>
                                                    </div>
                                                </form>
                                      
                                            </div>
                                            </div>
                                            </div>
                                            </div>
                                            </div>
                                           
                                        
                 
                </div>
            </div>
        </div>
    </div>
</div>


<div class="toaster">
    <ng-toast />
</div>