<div class="body-wrapper">
    <div >
        <button class="btn btn-primary" (click)="onBack()">Back</button>
      </div>
    <div class="row flex-grow">
        <div class="col-12 grid-margin stretch-card">
            <div class="card card-rounded">
                <div class="card-body">
                    <div class="d-sm-flex justify-content-between align-items-start mb-4">
                        <div>
                            <h4 class="card-title card-title-dash">Assign Team</h4>
                        </div>
                    </div>
                    <div class="d-sm-flex flex-row justify-content-between mb-4">
                        <div class="d-flex gap-4 flex-row">
                            <div class="select-wrapper">
                                <img src="/assets/org.png" class="select-icon">
                                <label for="orgSelect" class="form-label">Select Organization</label>
                                <select id="orgSelect" class="form-select" [(ngModel)]="selectedOrg" (change)="onSelectOrg()">
                                    <option value="" disabled>Select Organisation</option>
                                    <option *ngFor="let org of organization" [value]="org.orgId">{{ org.orgName }}</option>
                                </select>
                            </div>
                            <div class="select-wrapper">
                                <img src="/assets/team.png" class="select-icon">
                                <label for="teamSelect" class="form-label">Select Team</label>
                                <select id="teamSelect" class="form-select" [(ngModel)]="selectedTeam" (change)="onSelectTeam()">
                                    <option [ngValue]="null" disabled>Select Team</option>
                                    <option *ngFor="let team of orgTeams" [ngValue]="team.teamId">{{ team.teamName }}</option>
                                </select>
                            </div>                            
                            <div class="select-wrapper">
                                <img src="/assets/coach.png" class="select-icon">
                                <label for="coachSelect" class="form-label">Select Coach</label>
                                <select id="coachSelect" class="form-select" [(ngModel)]="selectedCoach">
                                    <option value="" disabled>Select Coach</option>
                                    <option *ngFor="let trainer of Trainer" [value]="trainer.usrId">{{ trainer.usrFullName }}</option>
                                </select>
                            </div>
                            </div>
                            <div class="mt-4">
                                <button type="button" class="btn btn-primary"
                                        (click)="updateSelection()" 
                                        [disabled]="!isFormValid()">
                                    Update
                                </button>
                            </div>
                        
                    </div>
                    <div class="content" style="width: 100%; height: 100%;">
                        <ag-grid-angular class="ag-theme-quartz" style="height: 500px;" [rowData]="rowData" [columnDefs]="colDefs"
                          [defaultColDef]="defaultColDef" [gridOptions]="gridOptions" [pagination]="true" (gridReady)="onGridReady($event)">
                        </ag-grid-angular>
                       
                      </div>
                    <br>
                </div>
            </div>
        </div>
    </div>
</div>
 
<div class="toaster">
    <ng-toast></ng-toast>
</div>