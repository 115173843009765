<div class="container">
  <header>Physiotherapy</header>
  <form id="userForm" #physioForm="ngForm">
    <div class="form-one form-step active form-container">
      <span class="title">Patient Information</span>
      <div class="fields">
        <div class="input-field">
          <label>Name</label>
          <input type="text" class="form-control" placeholder="Enter Name" required name="plgame">
        </div>
        <div class="input-field">
          <label>Age</label>
          <input type="number" class="form-control" placeholder="Enter Age" required name="Age">
        </div>
        <div class="input-field">
          <label>Gender</label>
          <select class="form-container" name="gender">
            <option value="">Male</option>
            <option value="">Female</option>
            <option value="">Others</option>
          </select>
        </div>
        <div class="input-field">
          <label>Date of Assessment </label>
          <input type="date" class="form-control" placeholder="Enter Date of assessment" required name="date">
        </div>
        <div class="input-field">
          <label>Referral Source</label>
          <input type="text" class="form-control" placeholder="Enter Referral Source " required name="Referral">
        </div>
        <div class="input-field">
          <label for="occupation">Occupation</label>
          <input type="text" id="occupation" class="form-control" placeholder="Enter Occupation" required
            [(ngModel)]="occupation" name="occupation" (blur)="checkOccupation()" />
        </div>
        <div class="input-field">
          <label for="chiefComplaint">Chief Complaint (C/O)</label>
          <input type="text" id="chiefComplaint" class="form-control" placeholder="Enter Chief Complaint" required
            name="chiefComplaint" />
        </div>
        <div class="input-field">
          <label>Past History</label>
          <input type="text" class="form-control" placeholder="Enter Past History" required name="pasthistry">
        </div>
        <div class="input-field">
          <label>Present History </label>
          <input type="date" class="form-control" placeholder="Enter Present History" required name="prehistry">
        </div>
      </div>
      <div *ngIf="showSportsHistory" class="">
        <span class="title">Sports Training History</span>
        <div class="fields">

          <div class="input-field">
            <label>Type of Sport</label>
            <select class="form-container" name="sport">
              <option value=""> Football </option>
              <option value="">Basketball</option>
            </select>
          </div>
          <div class="input-field">
            <label>Level of Participation</label>
            <select class="form-container" name="level">
              <option value=""> Amateur</option>
              <option value="">Professiona</option>
            </select>
          </div>
          <div class="input-field">
            <label>Duration of Involvement</label>
            <input type="number" class="form-control" placeholder="Enter Duration of Involvement" required
              name="Duration">
          </div>
          <div class="input-field">
            <label>Typical Training Regiment </label>
            <input type="text" class="form-control" placeholder="Enter Training Regiment" required name="Regiment">
          </div>
          <div class="input-field">
            <label>Recent Changes in Training</label>
            <input type="text" class="form-control" placeholder="Enter Recent Changes" required name="Changes">
          </div>
          <div class="input-field">
            <label>Previous Injuries</label>
            <input type="text" class="form-control" placeholder="Enter Previous Injuries" required name="Injuries">
          </div>
          <div class="input-field">
            <label>Competition Schedule</label>
            <input type="text" class="form-control" placeholder="Enter Competition Schedule" required name="Schedule">
          </div>
          <div class="input-field">
            <label>Goal </label>
            <input type="text" class="form-control" placeholder="Enter Goal" required name="Goal">
          </div>
        </div>
      </div>
    </div>
    <div class="form-two form-step  form-container">
      <span class="title">Observation</span>
      <div class="fields">
        <!-- Posture Dropdown -->
        <div class="input-field">
          <label for="posture">Posture:</label>
          <select id="posture" [(ngModel)]="postureSelection" class="form-control"
            (change)="onDropdownChange('posture', $event)">
            <option value="">Select Option</option>
            <option value="no">Normal</option>
            <option value="yes">Abnormal</option>
          </select>
        </div>
        <div class="input-field" *ngIf="showPostureTextbox">
          <label>Types of Posture:</label>
          <input class="form-control" type="text" placeholder="Enter Posture value" [(ngModel)]="postureValue" />
        </div>
      </div>
      <div class="fields">
        <!-- Gait Dropdown -->
        <div class="input-field">
          <label for="posture">Gait</label>
          <select id="gait" [(ngModel)]="gaitSelection" class="form-control"
            (change)="onDropdownChange('gait', $event)">
            <option value="">Select Option</option>
            <option value="no">Normal</option>
            <option value="yes">Abnormal</option>
          </select>
        </div>
        <div class="input-field" *ngIf="showGaitTextbox">
          <label>Types of Gait</label>
          <input class="form-control" type="text" placeholder="Enter Gait value" [(ngModel)]="gaitValue" />
        </div>
      </div>
      <div class="fields">
        <!-- Swelling Dropdown -->
        <div class="input-field">
          <label for="posture">Swelling</label>
          <select id="swelling" [(ngModel)]="swellingSelection" class="form-control"
            (change)="onDropdownChange('swelling', $event)">
            <option value="">Select Option</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </div>
        <div class="input-field" *ngIf="showSwellingTextbox">
          <label>Area</label>
          <input class="form-control" type="text" placeholder="Enter Swelling value" [(ngModel)]="swellingValue" />
        </div>
      </div>
      <div class="fields">
        <div class="input-field">
          <label>Skin Condition </label>
          <input type="text" class="form-control" placeholder="Enter SkinCondition" required name="Skincondition">
        </div>
      </div>
      <div class="fields">
        <div class="input-field">
          <label for="posture">Muscle Wasting</label>
          <select id="swelling" [(ngModel)]="MuscleWastingSelection" class="form-control"
            (change)="onDropdownChange('MuscleWasting', $event)">
            <option value="">Select Option</option>
            <option value="yes">Present</option>
            <option value="no">Absent</option>
          </select>
        </div>
        <div class="input-field" *ngIf="showMuscleWastingTextbox">
          <label>Muscles Name </label>
          <input class="form-control" type="text" placeholder="Enter Swelling value" [(ngModel)]="swellingValue" />
        </div>
      </div>
    </div>
    <div class="form-three form-step form-container">
      <span class="title">Palpation</span>
      <div class="fields">
        <!-- Temperature Dropdown -->
        <div class="input-field">
          <label for="posture">Temperature:</label>
          <select id="posture" [(ngModel)]="temperatureSelection" class="form-control">
            <option value="">Select Option</option>
            <option value="no">Incresaed </option>
            <option value="yes">Decreased</option>
          </select>
        </div>
        <div class="input-field">
          <label>Value</label>
          <input class="form-control" type="text" placeholder="Enter value" [(ngModel)]="postureValue" />
        </div>
      </div>
      <div class="fields">
        <!-- Tenderness Dropdown -->
        <div class="input-field">
          <label for="posture">Tenderness</label>
          <select id="gait" [(ngModel)]="tendernessSelection" class="form-control"
            (change)="onDropdownChange('tenderness', $event)">
            <option value="">Select Option</option>
            <option value="yes">Present</option>
            <option value="no">Absent</option>
          </select>
        </div>
        <div class="input-field" *ngIf="showTendernessTextbox">
          <label>Which Grade [1-4]</label>
          <input class="form-control" type="number" placeholder="Enter Grade value" [(ngModel)]="gaitValue" />
        </div>
      </div>
      <div class="fields">
        <!-- Swelling Dropdown -->
        <div class="input-field">
          <label for="posture">Swelling</label>
          <select id="swelling" [(ngModel)]="swellingPLSelection" class="form-control"
            (change)="onDropdownChange('swellingPL', $event)">
            <option value="">Select Option</option>
            <option value="yes">Present</option>
            <option value="no">Absent</option>
          </select>
        </div>
        <div class="input-field" *ngIf="showSwellingPLTextbox">
          <label>Area</label>
          <input class="form-control" type="text" placeholder="Enter Area" [(ngModel)]="swellingValue" />
        </div>
      </div>
      <div class="fields">
        <div class="input-field">
          <label for="posture">Bony Alignment</label>
          <select id="swelling" [(ngModel)]="BonySelection" class="form-control"
            (change)="onDropdownChange('Bony', $event)">
            <option value="">Select Option</option>
            <option value="no">Normal</option>
            <option value="yes">Deformity </option>
          </select>
        </div>
        <div class="input-field" *ngIf="showBonyTextbox">
          <label> Area and Type </label>
          <input class="form-control" type="text" placeholder="Enter Bony Alignment" [(ngModel)]="swellingValue" />
        </div>
      </div>
      <div class="multi-select-dropdown">
        <label class="subtitle">Select Target Joints</label>
        <button class="multi-select-btn btn" (click)="toggleJoint()">
          <span class="btn-text">Select</span>
          <span class="btn-icon">&#9662;</span>
        </button>
        <ul class="multi-select-list" *ngIf="dropdownOpenJoint">
          <li *ngFor="let joint of jointTabs">
            <input type="checkbox" name="joint_{{joint}}" (change)="onJointChange(joint, $event)">
            <label>{{ joint }}</label>
          </li>
        </ul>
      </div>
    </div>
    <!-- Joint -->
    <div class="form-four form-step form-container body-wrapper">
      <div class="emp-profile">
        <div class="row">
          <div class="profile-head" *ngIf="selectedJoints.length > 0">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item" *ngFor="let joint of selectedJoints; let i = index">
                <a class="nav-link" [class.active]="i === 0" [id]="joint+'-tab'" data-bs-toggle="tab" [href]="'#'+joint"
                  role="tab">{{ joint }}</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="tab-content Shoulder-tab" id="myTabContent">
          <div *ngFor="let joint of selectedJoints; let i = index" class="tab-pane fade"
            [ngClass]="{'show active': i === 0}" [id]="joint" role="tabpanel">
            <ng-container [ngSwitch]="joint">
              <div *ngSwitchCase="'Shoulder'">
                <!-- Shoulder Form -->
                <app-multi-select-dropdown [label]="'Range of MotionSH (ROM)'"
                  [options]="ROMShoulderHip"></app-multi-select-dropdown>
                <app-multi-select-dropdown [label]="'Muscle Strength (MMT)'"
                  [options]="MMTShoulder"></app-multi-select-dropdown>
                <app-multi-select-dropdown [label]="'Special Tests'" [options]="STshoulder"></app-multi-select-dropdown>
              </div>
              <div *ngSwitchCase="'Elbow'">
                <!-- Elbow Form -->
                <app-multi-select-dropdown [label]="'Range of MotionEL (ROM)'"
                  [options]="ROMElbow"></app-multi-select-dropdown>
                <app-multi-select-dropdown [label]="'Muscle Strength (MMT)'"
                  [options]="MMTElbowWrist"></app-multi-select-dropdown>
                <app-multi-select-dropdown [label]="'Special Tests'" [options]="STElbow"></app-multi-select-dropdown>
              </div>
              <div *ngSwitchCase="'Wrist'">
                <!-- Wrist Form -->
                <app-multi-select-dropdown [label]="'Range of MotionWR (ROM)'"
                  [options]="ROMWrist"></app-multi-select-dropdown>
                <app-multi-select-dropdown [label]="'Muscle Strength (MMT)'"
                  [options]="MMTElbowWrist"></app-multi-select-dropdown>
                <app-multi-select-dropdown [label]="'Special Tests'" [options]="STWrist"></app-multi-select-dropdown>
              </div>
              <div *ngSwitchCase="'Hip'">
                <!-- Hip Form -->
                <app-multi-select-dropdown [label]="'Range of MotionHP (ROM)'"
                  [options]="ROMShoulderHip"></app-multi-select-dropdown>
                <app-multi-select-dropdown [label]="'Muscle Strength (MMT)'"
                  [options]="MMTHip"></app-multi-select-dropdown>
                <app-multi-select-dropdown [label]="'Special Tests'" [options]="STHip"></app-multi-select-dropdown>
              </div>
              <div *ngSwitchCase="'Knee'">
                <!-- Knee Form -->
                <app-multi-select-dropdown [label]="'Range of MotionKE (ROM)'"
                  [options]="ROMMMTKnee"></app-multi-select-dropdown>
                <app-multi-select-dropdown [label]="'Muscle Strength (MMT)'"
                  [options]="ROMMMTKnee"></app-multi-select-dropdown>
                <app-multi-select-dropdown [label]="'Special Tests'" [options]="STKnee"></app-multi-select-dropdown>
              </div>
              <div *ngSwitchCase="'Ankle'">
                <!-- Ankle Form -->
                <app-multi-select-dropdown [label]="'Range of MotionAK (ROM)'"
                  [options]="ROMAnkle"></app-multi-select-dropdown>
                <app-multi-select-dropdown [label]="'Muscle Strength (MMT)'"
                  [options]="MMTAnkle"></app-multi-select-dropdown>
                <app-multi-select-dropdown [label]="'Special Tests'" [options]="STAnkle"></app-multi-select-dropdown>
              </div>
              <div *ngSwitchCase="'Spine'">
                <!-- Spine Form -->
                <label>Range of MotionSP (ROM)</label>
                <app-multi-select-dropdown [label]="'Cervical'" [options]="ROMSpine"></app-multi-select-dropdown>
                <app-multi-select-dropdown [label]="'Thoracic'" [options]="ROMSpine1"></app-multi-select-dropdown>
                <app-multi-select-dropdown [label]="'Lumbar'" [options]="ROMSpine2"></app-multi-select-dropdown>
                <br>
                <label>Muscle Strength (MMT)</label>
                <app-multi-select-dropdown [options]="MMTSpine"></app-multi-select-dropdown>
                <label>Special Tests</label>
                <br>
                <app-multi-select-dropdown [options]="STSpine"></app-multi-select-dropdown>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <span class="title">Flexibility</span>
      <div class="fields">
        <div class="input-field">
          <label>Hamstrings</label>
          <input class="form-control" type="text" placeholder="Enter Hamstrings" [(ngModel)]="postureValue" />
        </div>
        <div class="input-field">
          <label>Quadriceps</label>
          <input class="form-control" type="text" placeholder="Enter Quadriceps" [(ngModel)]="postureValue" />
        </div>
        <div class="input-field">
          <label>IlioPsoas</label>
          <input class="form-control" type="text" placeholder="Enter IlioPsoas" [(ngModel)]="postureValue" />
        </div>
        <div class="input-field">
          <label>Pectoral</label>
          <input class="form-control" type="text" placeholder="Enter Pectoral" [(ngModel)]="postureValue" />
        </div>

        <div class="input-field">
          <label>Spine Flexibility </label>
          <input class="form-control" type="text" placeholder="Enter Spine Flexibility " [(ngModel)]="postureValue" />
        </div>
        <div class="input-field">
          <label>Piriformis</label>
          <input class="form-control" type="text" placeholder="Enter Piriformis" [(ngModel)]="postureValue" />
        </div>
        <div class="input-field">
          <label>Adductors</label>
          <input class="form-control" type="text" placeholder="Enter Adductors" [(ngModel)]="postureValue" />
        </div>
        <div class="input-field">
          <label>Weight bearing Lunge Tes</label>
          <input class="form-control" type="text" placeholder="Enter Weight bearing Lunge Tes"
            [(ngModel)]="postureValue" />
        </div>
        <div class="input-field">
          <label>Sit and Reach </label>
          <input class="form-control" type="text" placeholder="Enter Sit and Reach " [(ngModel)]="postureValue" />
        </div>
      </div>
    </div>
    <div class="form-five form-step form-container">
      <span class="title">Diagnosis/Clinical Impression</span>
      <span class="title">Treatment Goals</span>
      <div class="fields">
        <div class="input-field">
          <label>Short-Term Goals</label>
          <input class="form-control" type="text" placeholder="Enter Short-Term Goals" [(ngModel)]="postureValue" />
        </div>
        <div class="input-field">
          <label>Long-Term Goal</label>
          <input class="form-control" type="text" placeholder="Enter Long-Term Goal" [(ngModel)]="postureValue" />
        </div>
      </div>
      <span class="title">Follow up</span>
      <div class="fields">
        <div class="input-field">
          <label>Next Assessment Date</label>
          <input class="form-control" type="text" placeholder="Enter Next Assessment" [(ngModel)]="postureValue" />
        </div>
        <div class="input-field">
          <label>Additional Referrals/Investigation</label>
          <input class="form-control" type="text" placeholder="Enter Referrals" [(ngModel)]="postureValue" />
        </div>
      </div>
      <span class="title">Payment Details</span>
      <div class="fields">
        <div class="input-field">
          <label>Total Amount</label>
          <input class="form-control" type="text" placeholder="Enter Total Amount" [(ngModel)]="postureValue" />
        </div>
        <div class="input-field">
          <label>Payment Method</label>
          <input class="form-control" type="text" placeholder="Enter Payment Method" [(ngModel)]="postureValue" />
        </div>
        <div class="input-field">
          <label>Adductors</label>
          <input class="form-control" type="text" placeholder="Enter Payment Date" [(ngModel)]="postureValue" />
        </div>
      </div>
    </div>
    <div class="btn-group">
      <button type="button" class="btn-prev" (click)="prevStep()" [disabled]="activeStep === 1"> Back</button>
      <button type="button" class="btn-next" (click)="nextStep()" [disabled]="activeStep === formSteps.length">Next
      </button>
      <button type="submit" class="btn-submit">Submit</button>
    </div>
  </form>
</div>
<div class="toaster">
  <ng-toast />
</div>