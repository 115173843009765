<div *ngIf="isLoading" class="loading-spinner">
  <div class="spinner"></div>
</div>

<div *ngIf="Isplayer">
  <app-player-dashboard ></app-player-dashboard>
</div>
<div *ngIf="assessor">
  <app-assessor-dashboard></app-assessor-dashboard>
</div>
<div class="body-wrapper" [ngClass]="{ 'blurred': isLoading }">
  <div *ngIf="Admin || OrgAdmin">
    <div class="col-12 d-flex align-items-center">
      <div class="col-5">
        <div class="text-wrapper mb-2">
          <div class="gradient-text-container">
            Explore, <span>What would you like to know?</span>
          </div>
        </div>
      </div>
      <div class="col-7 input-container">
        <img src="/assets/image/welcome.png" alt="Microphone Icon">
        <input type="text" [(ngModel)]="userMessage" placeholder="Type your prompt here...">
        <button class="send-btn" (click)="handleMessageSend()">
          <i class='bx bx-right-arrow-alt'></i>
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="chat" class="prompt-box">
    <div class="row prompts">
      <pre>{{ getFilteredChat() }}</pre>
    </div>
  </div>

  <div>
  
    <div class="container-fluid">
      <div *ngIf="OrgAdmin || Admin || Isplayer || sciStaff">
        <div class="d-flex justify-content-end mb-3">
          <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" >
            <button type="button" class="accept" (click)="downloadPDF()">
              <i class='bx bx-download'></i> Download
            </button>
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div id="pdf-content">
                  <div *ngFor="let athlete of admitcard; let i = index" class="modal-body">
                    <div class="card1">
                      <div class="logo-content">
                        <div>
                          <h2>AMS SPORTS</h2>
                          <p>Centre For Sport Science</p>
                        </div>
                        <div class="logo-circle">
                          <img src="/assets/image/new_id.jpg" alt="Logo" class="logo-img">
                        </div>
                      </div>
                      <div class="img">
                        <img [src]="imageUrls[i]" alt="Athlete Image">
                      </div>
                      

                      <h2 class="title">
                        <span>ID : </span> {{athlete.usrId}}
                      </h2>
                      <h2 class="title">
                        <span>Name: </span> {{athlete.usrFullName}}
                      </h2>
                      <p class="info">
                        <span>DOB: </span>
                        {{athlete.usrDoB | date:'dd-MM-yyyy'}}
                      </p>
                      <p class="info"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="drop-items d-flex justify-content-end mb-3 gap-4 align-items-end">
      <div *ngIf="!Isplayer && !Coach && !OrgAdmin && !assessor">     
        <div *ngIf="Admin || sciStaff " class="select-wrapper">
          <h4>Organization</h4>
          <ng-select
          class="ng-select-custom"
          [items]="dropdownItems"
          bindLabel="orgName"
          bindValue="orgId"
          [(ngModel)]="selectedOrg"
          (change)="selectOrganisation()"
          [searchable]="false"
          [clearable]="false">
          <ng-template ng-label-tmp let-item="item">
            <span>{{ item.orgName }}</span>
            <i class="fa fa-chevron-down"></i>
          </ng-template>
          
          <ng-template ng-option-tmp let-item="item">
            {{ item.orgName }}
          </ng-template>
        </ng-select>
        
        
        </div>      
      </div>
   <div *ngIf="!Isplayer && !Coach && !assessor"
      class="d-flex justify-content-end">
      <div class="select-wrapper">        
          <h4>Team</h4>
          <ng-select
          class="ng-select-custom"
          [items]="Allteam"
          bindLabel="teamName"
          bindValue="teamId"
          [(ngModel)]="selectedTeamId"
          (change)="orgStudents()"
          [disabled]="selectedOrg === '0' && !OrgAdmin && !assessor"
          [ngClass]="{'disabled-dropdown': selectedOrg === '0' && !OrgAdmin && !assessor}"
          [searchable]="false"
          [clearable]="false">
          <ng-template ng-label-tmp let-item="item">
            <span>{{ item.teamName }}</span>
            <i class="fa fa-chevron-down"></i>
          </ng-template>
          <ng-template ng-option-tmp let-item="item">
            {{ item.teamName }}
          </ng-template>
        </ng-select>
        
        
      </div>
  </div>
  
      <!-- <div *ngIf="!Isplayer && !Coach && !Admin || !OrgAdmin">

        <div class="select-wrapper">
          <i class="bx bx-cog select-icon"></i>
          <select class="form-select" [(ngModel)]="selectedTrainer" (change)="selectTrainer()">
            <option value="" disabled>Select Trainer</option>
            <option value="0" selected>All</option>
            <option *ngFor="let trainer of Trainer" [value]="trainer.usrId">{{ trainer.usrFullName }}</option>
          </select>
        </div>

      </div> -->

      <div *ngIf="!Isplayer && !assessor">
        <div class="select-wrapper">
          <h4> Test Type</h4>
          <ng-select
          class="ng-select-custom"
          [items]="dropdownOptions"
          bindLabel="label"
          bindValue="value"
          [(ngModel)]="selectedOptions"
          (change)="onDropdownChange()"
          [searchable]="false"
          [clearable]="false">
          <ng-template ng-label-tmp let-item="item">
            <i class="fa fa-chevron-down"></i>
            {{ item.label }}
          </ng-template>
          <ng-template ng-option-tmp let-item="item">
            {{ item.label }}
          </ng-template>
        </ng-select>
        
        </div>
      </div>  
       <div *ngIf="!Isplayer && !assessor">
        <div class="select-wrapper">
          <h4>Category</h4>
          <ng-select
           class="ng-select-custom"
          [items]="assessmentOptions"
          bindLabel="label"
          bindValue="value"
          [(ngModel)]="selectedOption"
          (change)="selectAssessment()"
          [disabled]="selectedOptions === 'Level One'"
          [ngClass]="{'disabled-dropdown': selectedOptions === 'Level One'}"
           [searchable]="false"
          [clearable]="false">
          <ng-template ng-label-tmp let-item="item">
            <i class="fa fa-chevron-down"></i>
            {{ item.label }}
          </ng-template>
          <ng-template ng-option-tmp let-item="item">
            {{ item.label }}
          </ng-template>
        </ng-select>
        
        </div>
      </div>
      <div *ngIf="Admin || sciStaff || OrgAdmin">
        <button class="btn btn-primary"  [disabled]="isButtonDisabled && !OrgAdmin "  (click)="DownloadClick()" data-bs-toggle="modal" data-bs-target="#exampleModal">Download Players Ids</button>
      </div>
      
    </div>
   <!-- Conditionally Display Components Based on selectedOption -->
    <div *ngIf="!Isplayer && !assessor">
      <div *ngIf="selectedOption =='All' && selectedOptions =='CSS'">
        <app-overall-dashboard [userMessage]="userMessage" [selectedTeamId]="selectedTeamId" [orgId]="selectedOrg" (chatData)="handleChatFromChild($event)"></app-overall-dashboard>
      </div>
      <div *ngIf="selectedOption =='PhysicalFitness' && selectedOptions =='CSS'">
        <app-physical-fitness-dashboard [userMessage]="userMessage" [selectedTeamId]="selectedTeamId" [orgId]="selectedOrg" (chatData)="handleChatFromChild($event)"></app-physical-fitness-dashboard>
      </div>
      <div *ngIf="selectedOption =='Nutrition' && selectedOptions =='CSS'">
        <app-nutritional-dashboard [userMessage]="userMessage" [selectedTeamId]="selectedTeamId" [orgId]="selectedOrg" (chatData)="handleChatFromChild($event)"></app-nutritional-dashboard>
      </div>
      <div *ngIf="selectedOption =='Cognitive' && selectedOptions =='CSS'">
        <app-cognitive-dashboard [userMessage]="userMessage" [selectedTeamId]="selectedTeamId" [orgId]="selectedOrg" (chatData)="handleChatFromChild($event)"></app-cognitive-dashboard>
      </div>
      <div *ngIf="selectedOptions =='Level One'">
        <app-level-one-dashboard [selectedTeamId]="selectedTeamId"></app-level-one-dashboard>
      </div>
      <div *ngIf="selectedOption =='Injury Prevention Analysis' && selectedOptions =='CSS'">
        <app-injury-prevention-dashboard [userMessage]="userMessage" [selectedTeamId]="selectedTeamId" [orgId]="selectedOrg" (chatData)="handleChatFromChild($event)"></app-injury-prevention-dashboard>
      </div>
      <div *ngIf="selectedOption =='Sports Performance Analysis' && selectedOptions =='CSS'">
        <app-sport-performance [userMessage]="userMessage" [selectedTeamId]="selectedTeamId" [orgId]="selectedOrg" (chatData)="handleChatFromChild($event)"></app-sport-performance>
      </div>
    </div>
  </div>
</div>