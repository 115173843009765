<div class="body-wrapper">
    <div class="row flex-grow">
        <div class="col-12 grid-margin stretch-card">
            <div class="card card-rounded">
                <div class="card-body">
                    <div class="d-sm-flex justify-content-between align-items-start mb-4">
                        <div>
                            <h4 class="card-title card-title-dash">Team and Coach</h4>
                        </div>
                        <div class="d-flex m-2">
                            <div>
                                <!-- Manage Organization Button -->
                                <button class="btn btn-primary" type="button" (click)="openManageOrgModal()">
                                    Manage Organization
                                </button>
                            </div>&nbsp;
                            <div>
                                <button class="btn btn-primary" type="button" (click)="openManageTeamModal()">
                                    Manage Team
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="d-sm-flex justify-content-between align-items-start mb-4">
                        <div class="d-flex gap-4">

                            <select class="form-select" [(ngModel)]="selectedOrgTrainer" (change)="onSelectOrg()">
                                <option value="" disabled>Select Organisation</option>
                                <option *ngFor="let org of organization" [value]="org.orgId">{{ org.orgName }}</option>
                            </select> &nbsp;
                            <select class="form-select" [(ngModel)]="selectedCoach">
                                <option value="" disabled>Select Coach</option>
                                <option *ngFor="let trainer of Trainer" [value]="trainer.usrId">{{ trainer.usrFullName
                                    }}</option>
                            </select>
                            &nbsp;
                            <ng-select [items]="statusOptions" bindLabel="teamName" bindValue="teamId"
                                [(ngModel)]="selectedTeam" (change)="onSelectTeam()" placeholder="Search/Select Team"
                                [searchable]="true" class="form-select">
                            </ng-select>
                            &nbsp;
                            <ng-select [items]="statusPlayer" bindLabel="usrFullName" [(ngModel)]="selectedPlayer"
                                placeholder="Select players" [multiple]="true" [closeOnSelect]="false"
                                [searchable]="true" class="form-select">
                                <!-- Custom Option Template -->
                                <ng-template ng-option-tmp let-item="item">
                                    <div class="custom-checkbox">
                                        <input type="checkbox" [checked]="isSelected(item)" />
                                        <span>{{ item.usrFullName }}</span>
                                    </div>
                                </ng-template>
                            </ng-select>



                        </div>
                    </div>
                    <div class="d-flex align-items-center justify-content-end">
                        <button class="btn btn-primary" type="button" (click)="updateSelection()">
                            Update
                        </button>
                    </div>
                    <br>
                    <!-- <div class="content" style="width: 100%; height: 100%;">
              <ag-grid-angular class="ag-theme-quartz" style="height: 500px;" [rowData]="rowData" [columnDefs]="colDefs"
                [defaultColDef]="defaultColDef" [gridOptions]="gridOptions" [pagination]="true" (gridReady)="onGridReady($event)">
              </ag-grid-angular>
            </div> -->
                </div>
            </div>
        </div>
    </div>

    <div class="content" *ngIf="Organisation">
        <ag-grid-angular class="ag-theme-quartz" style="height: 500px;" [rowData]="rowData" [columnDefs]="colDefs"
            [gridOptions]="gridOptions" [pagination]="true">
        </ag-grid-angular>
    </div>
</div>


<!-- Trigger Button -->
<!-- <button type="button" class="btn btn-primary" (click)="openModal()">Show Duplicates</button> -->

<!-- File Upload Modal -->

<!-- Modal for Managing Teams -->
<div class="modal fade" id="teamcreateModal1" tabindex="-1" aria-labelledby="teamcreateModalLabel1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" style="max-width: 400px;">
        <div class="modal-content" style="max-height: 400px;">
            <div class="modal-header">
                <h5 class="modal-title" id="teamcreateModalLabel1">Manage Teams</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body p-3">
                <!-- Button to open Add Team modal -->
                <button type="button" class="btn btn-primary mb-3" (click)="openAddTeamModal()">Add New Team</button>

                <!-- Filter Teams by Organization -->
                <select class="form-select" [(ngModel)]="selectedOrg" (change)="filterTeams()"
                    style="max-height: 400px;">
                    <option value="" disabled>Edit Team Under Organization</option>
                    <option *ngFor="let org of organization" [value]="org.orgId">{{ org.orgName }}</option>
                </select>

                <!-- List of Teams -->
                <ul class="list-group mt-3" style="max-height: 180px; overflow-y: auto;" *ngIf="fiterOrg">
                    <li class="list-group-item" *ngFor="let team of filteredTeams">
                        {{ team.teamName }}
                        <button type="button" class="btn btn-sm btn-warning float-end"
                            (click)="openEditTeamModal(team)">
                            <i class="fa fa-edit"></i> Edit
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>

<!-- Modal for Adding/Editing a Team -->
<div class="modal fade" id="teamcreateModal" tabindex="-1" aria-labelledby="teamcreateModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" style="max-width: 400px;">
        <div class="modal-content" style="max-height: 400px;">
            <div class="modal-header">
                <h5 class="modal-title" id="teamcreateModalLabel">{{ isEditMode ? 'Edit Team' : 'Add New Team' }}</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body p-3">
                <!-- Select for Organization -->
                <select class="form-select" [(ngModel)]="selectedOrgFilter" style="max-height: 400px;">
                    <option value="" disabled>Select Organization</option>
                    <option *ngFor="let org of organization" [value]="org.orgId">{{ org.orgName }}</option>
                </select>
                <div>
                    <!-- Input for Team Name -->
                    <input type="text" class="form-control mt-3" [(ngModel)]="teamName" placeholder="Enter Team Name" />
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="saveTeam()">
                    <span class="btnText">{{ isEditMode ? 'Update Team' : 'Create Team' }}</span>
                    <i class="uil uil-navigator"></i>
                </button>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="manageOrgModal" tabindex="-1" aria-labelledby="manageOrgModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" style="max-width: 400px;">
        <div class="modal-content" style="max-height: 400px;">
            <div class="modal-header">
                <h5 class="modal-title" id="manageOrgModalLabel">Manage Organization</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body p-3">
                <!-- Add New and Edit Buttons -->
                <button type="button" class="btn btn-primary mb-3" (click)="openAddOrgModal()">Add New
                    Organization</button>

                <!-- List of Organizations with Edit Icons -->
                <h5>Organizations</h5>
                <ul class="list-group" style="max-height: 200px; overflow-y: scroll;">
                    <li class="list-group-item" *ngFor="let org of organization">
                        {{ org.orgName }}
                        <button type="button" class="btn btn-sm btn-warning float-end" (click)="openEditOrgModal(org)">
                            <i class="fa fa-edit"></i> Edit
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>

<!-- Add/Edit Organization Modal -->
<div class="modal fade" id="orgModal" tabindex="-1" aria-labelledby="orgModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" style="max-width: 400px;">
        <div class="modal-content" style="max-height: 400px;">
            <div class="modal-header">
                <h5 class="modal-title" id="orgModalLabel">{{ isEditMode ? 'Edit Organization' : 'Add New Organization'
                    }}</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body  p-3">
                <!-- Input for Organization Name -->
                <input type="text" class="form-control mt-3" [(ngModel)]="orgName"
                    placeholder="Enter Organization Name" />
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="submitOrganization()">
                    <span class="btnText">{{ isEditMode ? 'Update' : 'Add' }}</span>
                </button>
            </div>
        </div>
    </div>
</div>



<div class="toaster">
    <ng-toast />
</div>