import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Component, NgZone } from '@angular/core';
import { ActivatedRoute, Router, RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { AssessmentService } from '../../../services/assessment.service';
import { SharedService } from '../../../services/shared.service';
import { PrivilegeEnum } from '../../../model/PrivilageEnum';
import { User } from '../../../model/User';
import { FormsModule } from '@angular/forms';
import { PrivilegeService } from '../../../services/privilage.service';
import { UserRegisterService } from '../../../services/user-register.service';
interface IRow {}

@Component({
  selector: 'app-saihealth-fitness',
  standalone: true,
  imports: [CommonModule, RouterLink, RouterModule, HttpClientModule, AgGridAngular,FormsModule],
  templateUrl: './saihealth-fitness.component.html',
  styleUrl: './saihealth-fitness.component.css'
})
export class SAIHealthFitnessComponent {
  Response: any;
  detail: any;
  Id: any;
  Status: any;
  isTableVisible: boolean = false;
  coachData: any;
  public asmId: any;
  public testNames: any;
  public assesserName: any;
  public CoachName: any;
  public teamName: any;
  public created: any;
  public CoachId: any;
  assessments: any;
  user: User | null = null;
  privilege: any;
  themeClass = 'ag-theme-quartz';
  rowData: IRow[] = [];
  colDefs: ColDef[] = [];
  ramPrivilege: PrivilegeEnum | undefined;
  defaultColDef: ColDef = {
    flex: 1,
    sortable: true,
    filter: true,
  };
  Isplayer: boolean | undefined;
  Admin: boolean | undefined;
  Coach:boolean=false;
  org: any;
  role: any;
  category: number = 2;
  selectedStatus: string = '0';
  selectdata: any;
  userDetail: any;
  constructor(
    private assessmentService: AssessmentService,
    private sharedService: SharedService,
    private ngZone: NgZone,private userRegisterService:UserRegisterService,
    private router: Router ,private priService:PrivilegeService,private route: ActivatedRoute
  ) {
    this.privilege = this.priService.getPrivileges();
    this.Isplayer = this.privilege.isPlayer;
    this.Coach = this.privilege.isCoach;
    this.route.queryParams.subscribe((params:any) => {
      this.selectedStatus = params['statusId'] ?? this.selectedStatus;
    });
  }

  ngOnInit() {
    this.sharedService.detail$.subscribe((data: any) => {
      this.detail = data;
      this.Id = this.detail.user.usrId;
      this.role=this.detail.user.usrRole;
      this.Status = 0;
    });
this. getUser();
    this.getPendingAssessment();
  }
  getUser(){
    this.userRegisterService.fetchUser(this.Id).subscribe(
      (response: any) => {
        this.userDetail = response;
        console.log(this.userDetail,"userdetail")
      })
  }
  getPendingAssessment() {

    this.assessmentService.getAssessmentBycategory(this.category).subscribe(
      (response) => {
        this.selectdata = response.filter((assessment: any) => assessment.asmStatus ==parseInt(this.selectedStatus, 10));
        console.log(this.selectdata,"coach")
        if(this.role == 1 ){

          this.selectdata = this.selectdata.filter((assessment: any) => assessment.asmTeam == this.userDetail[0].asiCurrentClubTeam);
console.log(this.selectdata,"data")
          const uniqueAssessments = new Map<number, any>();
  
          this.selectdata.forEach((item: any) => {
            if (!uniqueAssessments.has(item.asmId)) {
              uniqueAssessments.set(item.asmId, {
                asmId: `${item.asmId}`,
                AssessmentName: `${item.asmName}`,
                TeamName: `${item.teamName}`,
                CoachName: `${item.coachName}`,
                OrganizationName: `${item.orgName}`,
                Description: `${item.asmDescription}`,
                category:`${item.testCategory}`,
                AssessmentDate: new Date(item.asmDate).toLocaleDateString(),
                testNames: [item.testName],
                CoachId: item.asmCoach,
                id: item.asmId,
                asmStatus: item.asmStatus, // Add asmStatus to the object for filtering
              });
            } else {
              const existingAssessment = uniqueAssessments.get(item.asmId);
              if (!existingAssessment.testNames.includes(item.testName)) {
                existingAssessment.testNames.push(item.testName);
              }
            }
          });
    
          this.rowData = Array.from(uniqueAssessments.values());
          this.filterAssessmentsByStatus(); // Call filter function after setting the data
          this.colDefs = this.getColumnDefinitions();

        }else if(this.role ==  6){

          this.selectdata = this.selectdata.filter((assessment: any) => assessment.asmCoach == this.userDetail[0].usrId);
console.log(this.selectdata,"data")
          const uniqueAssessments = new Map<number, any>();
  
          this.selectdata.forEach((item: any) => {
            if (!uniqueAssessments.has(item.asmId)) {
              uniqueAssessments.set(item.asmId, {
                asmId: `${item.asmId}`,
                AssessmentName: `${item.asmName}`,
                TeamName: `${item.teamName}`,
                CoachName: `${item.coachName}`,
                OrganizationName: `${item.orgName}`,
                Description: `${item.asmDescription}`,
                category:`${item.testCategory}`,
                AssessmentDate: new Date(item.asmDate).toLocaleDateString(),
                testNames: [item.testName],
                CoachId: item.asmCoach,
                id: item.asmId,
                asmStatus: item.asmStatus, // Add asmStatus to the object for filtering
              });
            } else {
              const existingAssessment = uniqueAssessments.get(item.asmId);
              if (!existingAssessment.testNames.includes(item.testName)) {
                existingAssessment.testNames.push(item.testName);
              }
            }
          });
    
          this.rowData = Array.from(uniqueAssessments.values());
          this.filterAssessmentsByStatus(); // Call filter function after setting the data
          this.colDefs = this.getColumnDefinitions();
        }
        else if(this.role ==  12){

          this.selectdata = this.selectdata.filter((assessment: any) => assessment.asmOrg == this.userDetail[0].usrOrganization );
          const uniqueAssessments = new Map<number, any>();
  
          this.selectdata.forEach((item: any) => {
            if (!uniqueAssessments.has(item.asmId)) {
              uniqueAssessments.set(item.asmId, {
                asmId: `${item.asmId}`,
                AssessmentName: `${item.asmName}`,
                TeamName: `${item.teamName}`,
                CoachName: `${item.coachName}`,
                OrganizationName: `${item.orgName}`,
                Description: `${item.asmDescription}`,
                category:`${item.testCategory}`,
                AssessmentDate: new Date(item.asmDate).toLocaleDateString(),
                testNames: [item.testName],
                CoachId: item.asmCoach,
                id: item.asmId,
                asmStatus: item.asmStatus, // Add asmStatus to the object for filtering
              });
            } else {
              const existingAssessment = uniqueAssessments.get(item.asmId);
              if (!existingAssessment.testNames.includes(item.testName)) {
                existingAssessment.testNames.push(item.testName);
              }
            }
          });
    
          this.rowData = Array.from(uniqueAssessments.values());
          this.filterAssessmentsByStatus(); // Call filter function after setting the data
          this.colDefs = this.getColumnDefinitions();
        }
        else{
          const uniqueAssessments = new Map<number, any>();
  
          this.selectdata.forEach((item: any) => {
            if (!uniqueAssessments.has(item.asmId)) {
              uniqueAssessments.set(item.asmId, {
                asmId: `${item.asmId}`,
                AssessmentName: `${item.asmName}`,
                TeamName: `${item.teamName}`,
                CoachName: `${item.coachName}`,
                OrganizationName: `${item.orgName}`,
                Description: `${item.asmDescription}`,
                category:`${item.testCategory}`,
                AssessmentDate: new Date(item.asmDate).toLocaleDateString(),
                testNames: [item.testName],
                CoachId: item.asmCoach,
                id: item.asmId,
                asmStatus: item.asmStatus, // Add asmStatus to the object for filtering
              });
            } else {
              const existingAssessment = uniqueAssessments.get(item.asmId);
              if (!existingAssessment.testNames.includes(item.testName)) {
                existingAssessment.testNames.push(item.testName);
              }
            }
          });
    
          this.rowData = Array.from(uniqueAssessments.values());
          this.filterAssessmentsByStatus(); // Call filter function after setting the data
          this.colDefs = this.getColumnDefinitions();
        }
        
      },
      (error) => {
        console.error('Error retrieving pending assessments', error);
      }
    );
  }

  getColumnDefinitions(): ColDef[] {
    const columns: ColDef[] = [
      { field: 'AssessmentName' },
      { field: 'TeamName' },
      { field: 'CoachName' },
      { field: 'OrganizationName' },
      { field: 'Description' },
      { field: 'AssessmentDate' },
      {
        headerName: 'Action',
        cellRenderer: (_params: any) => {
          const container = document.createElement('div');
          container.style.display = 'flex';
          container.style.justifyContent = 'space-around';
 
          const assessmentDate = new Date(_params.data.AssessmentDate);
          assessmentDate.setHours(0, 0, 0, 0);
       
          const currentDateTime = new Date();
       
          const viewButton = document.createElement('button');
          viewButton.classList.add('btn');
          viewButton.innerHTML = '<i class="fa fa-eye"></i>';
       
          // Check if the assessment date is in the future
          if(this.Isplayer){
            if (assessmentDate > currentDateTime || _params.data.asmStatus == 0) {
              viewButton.style.cssText = 'color: grey; cursor: not-allowed; opacity: 0.6;';
            }
          } else{
            if (assessmentDate > currentDateTime) {
              viewButton.style.cssText = 'color: grey; cursor: not-allowed; opacity: 0.6;';
            }
          }
 
          viewButton.addEventListener('click', () => {
            if (assessmentDate <= currentDateTime) {
              this.ngZone.run(() => {
                if (this.Isplayer) {
                  if (_params.data.asmStatus == 1) {
                    this.router.navigate(['/LeveloneReport'], {
                      queryParams: {
                        id: _params.data.asmId,
                        athleteId: this.Id,
                        selectStatus: this.selectedStatus
                      }
                    });
                  }
                } else {
                    this.router.navigate(['/saisportdetail'], {
                      queryParams: {
                        asmId: _params.data.asmId,
                        category: _params.data.category,
                        assessor: _params.data.assessor,
                        selectStatus: this.selectedStatus
                      },
                    });
                  }
                
              });
            }
          });
          container.appendChild(viewButton);
          return container;
        },
        width: 120,
      },
    ];

    if (this.Admin) {
      return columns;
    } else if (this.Isplayer) {
      return columns.filter((col) => col.field !== 'playername');
    } else {
      return columns.filter((col) => col.field !== 'Assessor');
    }
  }  filterAssessmentsByStatus() {
    if (this.selectedStatus !== '') {
      // Filter rowData based on the selectedStatus
      this.rowData = this.rowData.filter((assessment: any) => assessment.asmStatus == this.selectedStatus);
    }
  }
}
