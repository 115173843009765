import { CommonModule } from '@angular/common';
import { Component, Input, NgZone, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink, RouterModule } from '@angular/router';
import { AgGridAngular } from 'ag-grid-angular';
import { FileuploadComponent } from '../../assessment/fileupload/fileupload.component';
import { User } from '../../model/User';
import { ColDef } from 'ag-grid-community';
import { PrivilegeEnum } from '../../model/PrivilageEnum';
import { Privilege } from '../../model/Privillage';
import { AssessmentService } from '../../services/assessment.service';
import { SharedService } from '../../services/shared.service';
import { UserService } from '../../services/user.service';
import { PrivilegeService } from '../../services/privilage.service';
import { SupportStaffService } from '../../services/support-staff.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { Subscription } from 'rxjs';
import { SidebarService } from '../../services/sidebar.service';

interface IRow { }

@Component({
  selector: 'app-sleepandrest-assessment',
  standalone: true,
  imports: [CommonModule, RouterModule, RouterLink, FormsModule, AgGridAngular, FileuploadComponent,NgSelectModule],
  templateUrl: './sleepandrest-assessment.component.html',
  styleUrl: './sleepandrest-assessment.component.css'
})
export class SleepandrestAssessmentComponent {
  @ViewChild('fileUploadModal') fileUploadModal: FileuploadComponent | undefined;
  @Input() name: any;
  isSidebarCollapsed = false;
  private sidebarSubscription: Subscription | undefined;
  user: User | null = null;

  themeClass = 'ag-theme-quartz';

  rowData: IRow[] = [];

  colDefs: ColDef[] = [];

  ramPrivilege: PrivilegeEnum | undefined;

  defaultColDef: ColDef = {
    flex: 1,
    sortable: true,
    filter: true,
  };
  privilege: Privilege;
  Isplayer: boolean;
  Admin: boolean;
  detail: any;
  org: any;
  id: any;
  selectedStatus: string = '0';
  selectdata: any;
  usrrole: any;
  userid: any
  selecteddata: any;
  filterresponse: any;
  statusOptions = [
    { label: 'Completed', value: '1' },
    { label: 'Pending', value: '0' }
  ];
  ScienceStaff: boolean;
  isLoading:boolean= false;

  constructor(private assessmentService: AssessmentService, private router: Router, private sharedService: SharedService,private supportStaffService: SupportStaffService,
    private ngZone: NgZone, private userService: UserService, private priService: PrivilegeService, private route:ActivatedRoute,private sidebarService: SidebarService) {
    this.privilege = this.priService.getPrivileges();
    this.Isplayer = this.privilege.isPlayer;
    this.Admin = this.privilege.isAdmin;
    this.ScienceStaff = this.privilege.isScienceStaff;
    this.route.queryParams.subscribe((params:any) => {
      this.selectedStatus = params['statusId'] ?? this.selectedStatus;
    });
  }

  ngOnInit() {
    this.sharedService.detail$.subscribe((data: any) => {
      this.detail = data;
      this.org = this.detail.user.usrOrganization;
      this.usrrole = this.detail.user.usrRole;
      this.userid = this.detail.user.usrId;
      this.supportStaffService.getSupportStaff().subscribe();
      this.id = this.supportStaffService.getSsIdBySsName("Sleep questionnaire");
    });
    this.isLoading=true;
    this.user = this.userService.getUser();
    this.ramPrivilege = this.userService.getRamPrivilege();

    // this.assessmentService.getPendingAllAssessment(this.id).subscribe(response => {
    //   const uniqueAssessments = new Map<number, any>();

    this.getPendingAssessment();

    //   // Convert the Map back to an array for `rowData`
    //   this.rowData = Array.from(uniqueAssessments.values());
    //   this.colDefs = this.getColumnDefinitions();
    // }, error => {
    //   console.error('Error submitting assessment', error);
    // });
    this.getPendingAssessment()
    this.sidebarSubscription = this.sidebarService.isSidebarCollapsed$.subscribe(
      (isCollapsed) => {
        this.isSidebarCollapsed = isCollapsed;
        this.adjustWidth(isCollapsed);
      }
    );
  }
  ngOnDestroy() {
    // Unsubscribe when the component is destroyed to avoid memory leaks
    if (this.sidebarSubscription) {
      this.sidebarSubscription.unsubscribe();
    }
  }

  adjustWidth(isCollapsed: boolean) {
    const bodyWrapper = document.querySelector('.body-wrapper') as HTMLElement;
    if (bodyWrapper) {
      if (isCollapsed) {
        bodyWrapper.classList.add('full-width');
      } else {
        bodyWrapper.classList.remove('full-width');
      }
    }
  }
  getPendingAssessment() {

    this.assessmentService.getPendingAllAssessment(this.id).subscribe(response => {
      this.filterresponse = response.filter((assessment: any) => assessment.testName == 'Sleep questionnaire');
      this.selectdata = this.filterresponse.filter((assessment: any) => assessment.astStatus == this.selectedStatus);
      this.selecteddata = this.filterresponse.filter((assessment: any) => assessment.astAssesser == Number(this.userid) && assessment.astStatus === Number(this.selectedStatus));
      const uniqueAssessments = new Map<number, any>();
      const dataToUse = this.usrrole == 2 || this.usrrole == 5 ? this.selectdata : this.selecteddata;

      // Loop through each item and ensure only unique asmId entries are kept
      dataToUse.forEach((item: any) => {
        if (!uniqueAssessments.has(item.asmId)) {
          uniqueAssessments.set(item.asmId, {
            'Assessment Name': `${item.asmName}`,
            Id: `${item.asmId}`,
            "Assessment Description": `${item.asmDescription}`,
            "Organization Name": `${item.orgName}`,
            "Coach Name": `${item.coachName}`,
            "Assessor Name": `${item.assesserName}`,
            "Team Name": `${item.teamName}`,
            assessor: `${item.astAssesser}`,
            testCategory: `${item.testCategory}`,
            AssessmentDate: new Date(item.asmDate).toLocaleDateString('en-US'),
          });
        }
      });

      // Convert the Map back to an array for `rowData`
      this.rowData = Array.from(uniqueAssessments.values());
      this.colDefs = this.getColumnDefinitions();
    }, error => {
      console.error('Error submitting assessment', error);
    });
  }


  getColumnDefinitions(): ColDef[] {
    const columns: ColDef[] = [
      { field: 'Assessment Name' },
      { field: 'Assessment Description' },
      { field: 'Organization Name' },
      { field: 'Team Name' },
      { field: 'Coach Name' },
      { field: 'Assessor Name' },
      { field: 'AssessmentDate' },
      {
        headerName: 'Action',
        cellRenderer: (_params: any) => {
          const container = document.createElement('div');
          container.style.display = 'flex';
          container.style.justifyContent = 'space-around';

          const assessmentDate = new Date(_params.data.AssessmentDate);
          assessmentDate.setHours(0, 0, 0, 0); 
        
          const currentDateTime = new Date();

          const viewButton = document.createElement('button');
          viewButton.classList.add('btn');
          viewButton.innerHTML = '<i class="fa fa-eye"></i>';

          // Check if the assessment date is in the future
          if (assessmentDate > currentDateTime) {
            viewButton.style.cssText = 'color: grey; cursor: not-allowed; opacity: 0.6;';
          }

          viewButton.addEventListener('click', () => {
            if (assessmentDate <= currentDateTime) {
              this.ngZone.run(() => {
                this.router.navigate(['/SleepandRestAssessmentList'], {
                  queryParams: {
                    id: _params.data.Id,
                    assessor: _params.data.assessor,
                    testCategory: _params.data.testCategory,
                    selectStatus: this.selectedStatus
                  }
                });
              });
            }
          });
          container.appendChild(viewButton);
          return container;
        },
        width: 120
      }
    ];

    this.isLoading=false;
    if (this.Admin) {
      return columns;
    } else if (this.Isplayer) {
      return columns.filter(col => col.field !== 'playername');
    } else {
      return columns.filter(col => col.field !== 'Assessor');
    }
  }

  openModal(): void {
    // Use Bootstrap's modal function to open the modal
    const modalElement = document.getElementById('fileUploadModal')!;
    const modal = new (window as any).bootstrap.Modal(modalElement);
    modal.show();
  }

}
