import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { AssessmentService } from '../services/assessment.service';
import { NotificationService } from '../services/Notifications.service';
import { ActivatedRoute, Router, RouterLink, RouterOutlet } from '@angular/router';
import { NgToastModule } from 'ng-angular-popup';
import { DomSanitizer } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { PrivilegeService } from '../services/privilage.service';
import { Privilege } from '../model/Privillage';

interface IRow { }

@Component({
  selector: 'app-sleep-question',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterOutlet, RouterLink, HttpClientModule, NgToastModule],
  templateUrl: './sleep-question.component.html',
  styleUrls: ['./sleep-question.component.css']
})
export class SleepQuestionComponent implements OnInit {
  currentSection = 1;
  formValues: any = {
    athleteId: '',
    sleep: {
      hours: '',
      quality: '',
      naps: '',
      napDuration: ''
    }
  };
  mode: any;
  teamId: any;
  athleteId: any;
  id: any;
  assessor: any;
  categoryId: any;
  sarId: any;
  ImageUrl: any;
  showImage: boolean = false;
  selectdata: any;
  rowData: IRow[] = [];
  playerList: any[] = [];
  privilege: Privilege;
  Admin: boolean;
  playerName: any;
  image: any;
  Assessor: boolean;

  constructor(
    private assessmentService: AssessmentService,
    private notify: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private location: Location,
    private priService: PrivilegeService
  ) {
    this.privilege = this.priService.getPrivileges();
    this.Admin = this.privilege.isAdmin;
    this.Assessor = this.privilege.isAssessor;
 
    this.route.queryParams.subscribe(params => {
      this.mode = params['mode'];
      this.teamId = params['teamId'];
      this.athleteId = params['athleteId'];
      this.id = params['id'];
      this.assessor = params['assessor'];
      this.categoryId = params['categoryId'];
    })
  }
  ngOnInit() {
    this.getProfileImage();
    this.getSleepData(this.athleteId, this.id);
    this.GetPlayer();

  }

  GetPlayer() {
    this.assessmentService.getPlayerByAssessmentId(this.id).subscribe(response => {
      this.selectdata = response.filter((assessment: any) => assessment.aplCategory == this.categoryId);
      // Create a map to store unique assessments based on PlayerId (or any unique identifier)
      const uniqueAssessments = new Map<number, any>();
      console.log('Assessment submitted', response);

      // Filter the response to only include unique assessments
      this.selectdata.forEach((item: any) => {
        // Only add the item if it does not exist in the map
        if (!uniqueAssessments.has(item.aplPlayer)) {
          uniqueAssessments.set(item.aplPlayer, {
            "Player Name": `${item.usrFullName}`,
            Name: `${item.usrFullName}`,
            aplPlayer: `${item.aplPlayer}`,
            teamId: `${item.teamId}`,
            aplAssessment: `${item.aplAssessment}`,
            "Email": `${item.usrEmail}`,
            PlayerEmail: `${item.usrEmail}`,
            "Phone No": `${item.usrPhoneNo}`,
            PhoneNo: `${item.usrPhoneNo}`,
            "DOB": new Date(item.usrDoB).toLocaleDateString(),
            "status": `${item.aplStatus}`,
          });
        }
      });

      // Convert the unique assessments map back to an array for your rowData
      this.rowData = Array.from(uniqueAssessments.values());
      this.playerName = this.rowData.filter((assessment: any) => assessment.aplPlayer === this.athleteId);
      this.playerList = this.rowData;

    }, error => {
      console.error('Error submitting assessment', error);
    });
  }

  onAthleteChange(event: any) {
    const selectedValue = event.target.value;
    const selectedPlayer = this.playerList.find(player => player.aplPlayer === selectedValue);
    if (selectedPlayer) {
      const athleteId = selectedPlayer.aplPlayer;
      const assessmentId = selectedPlayer.aplAssessment;
      this.athleteId = selectedPlayer.aplPlayer

      this.getProfileImage();
      this.getSleepData(athleteId, assessmentId);
      this.formValues = {
        athleteId: '',
        sleep: {
          hours: '',
          quality: '',
          naps: '',
          napDuration: ''
        }
      };
      this.GetPlayer();
    }
  }

  getSleepData(athleteId: any, id: any): void {
    const atheleId = Number(athleteId);
    const asmId = Number(id);
    this.assessmentService.getSleepandRestByAsm(athleteId, id).subscribe(response => {
      if (response.length > 0) {
        const sleepData = response[0];
        console.log(response, "response data");
        this.sarId = sleepData.sarId || '';
        this.formValues.sleep.hours = sleepData.answer1 || '';
        this.formValues.sleep.quality = sleepData.answer2 || '';
        this.formValues.sleep.naps = sleepData.answer3 || '';
        this.formValues.sleep.napDuration = sleepData.answer4 || '';

        // Structure the questions and answers into JSON format
        const sleepJson = {
          athleteId: athleteId,
          sarId: this.sarId,
          questions: [
            {
              question: 'How many hours of sleep do you usually get per night?',
              answer: this.formValues.sleep.hours || ''
            },
            {
              question: 'How would you rate the quality of your sleep?',
              answer: this.formValues.sleep.quality || ''
            },
            {
              question: 'Do you take naps during the day to help with recovery?',
              answer: this.formValues.sleep.naps || ''
            },
            {
              question: 'If yes, how long are your naps?',
              answer: this.formValues.sleep.napDuration || ''
            }
          ]
        };

        console.log(sleepJson);
        const jsonStr = JSON.stringify(sleepJson, null, 2);
      }
    }, error => {
      console.error('Error fetching sleep data', error);
    });
  }

  getProfileImage() {
    this.ImageUrl = null;
    this.showImage = false;
 
    this.assessmentService.getEmptyImage(this.athleteId).subscribe(
      (response) => {
        if (response) {
          this.showImage = true;
           this.image = "assets/image/profileimg.jpg"
          console.log(this.image ,"image")
          
        }
      },
      (error) => {
        this.assessmentService.getImage(this.athleteId).subscribe(
          (imageBlob) => {
        const objectURL = URL.createObjectURL(imageBlob);
        this.ImageUrl = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        this.image = this.ImageUrl.changingThisBreaksApplicationSecurity
        console.log(  this.image,"this.image")
        this.showImage = true;
          }
      );
        console.error('Error fetching image', error);
      }
    );
  }
 
 

  // Method to check if all questions in the current section are answered
  isSectionComplete(section: number): boolean {
    switch (section) {
      case 1:
        return (
          this.formValues.sleep.hours !== '' &&
          this.formValues.sleep.quality !== '' &&
          this.formValues.sleep.naps !== '' &&
          (this.formValues.sleep.naps === 'No' || this.formValues.sleep.napDuration !== '')
        );

      default:
        return false;
    }
  }

  back(): void {
    this.location.back();
  }

  submit(): void {
    const submissionData = [{
      sarId: this.sarId ? Number(this.sarId) : 0,
      sarAthleteId: Number(this.athleteId),
      sarAssessmentId: Number(this.id),
      sarAssessor: Number(this.assessor),
      sarQ1: this.formValues.sleep.hours,
      sarQ2: this.formValues.sleep.quality,
      sarQ3: this.formValues.sleep.naps,
      sarQ4: this.formValues.sleep.naps === 'Yes' ? this.formValues.sleep.napDuration : null,
      sarCreatedBy: Number(this.assessor),
    }];

    console.log("Submission Data:", submissionData);

    this.assessmentService.saveSleepandRest(submissionData).subscribe(response => {
      this.updateAssessmentStatus();
      console.log("Response from API:", response);
      this.notify.success("Answers submitted successfully");
      this.back();
    }, error => {
      console.error('Error submitting assessment', error);
    });
   
  }

  updateAssessmentStatus(): void {
    this.assessmentService.updateAssessmentStatus(
      Number(this.athleteId),
      Number(this.id),
      Number(this.categoryId),
      Number(this.assessor)
    ).subscribe(response => {
      console.log("Assessment status updated:", response);
    }, error => {
      console.error('Error updating assessment status', error);
    });
  }


  isReadOnly(): boolean {
    return this.mode === 'view';
  }
}
