import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink, RouterOutlet } from '@angular/router';
import { NotificationService } from '../../../services/Notifications.service';
import { AssessmentService } from '../../../services/assessment.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ChatService } from '../../../services/chat.service';
import { NgToastModule } from 'ng-angular-popup';

@Component({
  selector: 'app-injury-prevention-analysis-form',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterOutlet, RouterLink,NgToastModule, HttpClientModule],
  templateUrl: './injury-prevention-analysis-form.component.html',
  styleUrl: './injury-prevention-analysis-form.component.css'
})
export class InjuryPreventionAnalysisFormComponent {




  userid: any;
  formSubmitted: boolean = false;
  constructor(private notify:NotificationService,private route: ActivatedRoute,private router: Router,private assessmentService:AssessmentService,private sanitizer: DomSanitizer,private chatService: ChatService){
    this.route.queryParams.subscribe(params => {
      this.userid = params['amsId'];
    });
  
  }
  scoreOptions = [
    { value: 1, label: 'Score 1' },
    { value: 2, label: 'Score 2' },
    { value: 3, label: 'Score 3' }
  ];
  
  ImageUrl: any;
  showImage: boolean = false;
  ipa: any = {
    ipaId:0,
      ipaAthlete:'',
      ipaAssessor:1,
      ipaAssessment:1,
      ipaAssessmentDate:'',
    ipaDeepSquat: '',
    ipaInlineLunge: '',
    ipaHurdleStep: '',
    ipaActiveSLR: '',
    ipaShoulderMobility: '',
    ipaPushUp: '',
    ipaRotaryStability: '',
    ipaTotalScore:''
  };
  getProfileImage() {
    this.ImageUrl = null;
    this.showImage = false;
  
    this.assessmentService.getImage(this.ipa.ipaAthlete).subscribe(
      (imageBlob) => {
        if (imageBlob) {
          const objectURL = URL.createObjectURL(imageBlob);
          this.ImageUrl = this.sanitizer.bypassSecurityTrustUrl(objectURL);
          this.showImage = true; 
        } else {
          this.notify.failed("invalid athlete number.");
        }
      },
      (error) => {
        this.ImageUrl = null;
        this.showImage = false;
        this.notify.failed("invalid athlete number");
        console.error('Error fetching image', error);
      }
    );
  }
  calculateTotalScore() {
    const deepSquat = parseInt(this.ipa.ipaDeepSquat) || 0;
    const inlineLunge = parseInt(this.ipa.ipaInlineLunge) || 0;
    const hurdleStep = parseInt(this.ipa.ipaHurdleStep) || 0;
    const activeSLR = parseInt(this.ipa.ipaActiveSLR) || 0;
    const shoulderMobility = parseInt(this.ipa.ipaShoulderMobility) || 0;
    const pushUp = parseInt(this.ipa.ipaPushUp) || 0;
    const rotaryStability = parseInt(this.ipa.ipaRotaryStability) || 0;

    // Calculate the total score
    this.ipa.ipaTotalScore = deepSquat + inlineLunge + hurdleStep + activeSLR + shoulderMobility + pushUp + rotaryStability;
  }
  
  onSubmit(): void {
  
    this.assessmentService.addIPADetails([this.ipa]).subscribe(response =>{
      console.log('Assessment submitted', response);
      this.formSubmitted = true;
      this.notify.success("Form submitted successfully");
    
    }, error => {
      console.error('Error submitting assessment', error);

    });
}
}
