<div class="container">
  <header>Dynamic Form</header><br>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="form first">
      <div class="details personal">
        <div class="fields">
         
          <!-- Dynamic Fields -->
          <div class="input-field" *ngFor="let column of columns">
            <label>{{ column.columnName }}</label>
            <input
              [formControlName]="column.columnName"
              [type]="column.inputType"              
              [ngClass]="{'invalid-field': isFieldInvalid(column.columnName)}"
            />
            <div *ngIf="isFieldInvalid(column.columnName)" class="error-message">
              {{ getErrorMessage(column.columnName) }}
            </div>
          </div>

        </div>

        <!-- Submit Button -->
        <div class="buttons">
          <button type="submit" class="submit">
            <span class="btnText">Submit</span>
            <i class="uil uil-navigator"></i>
          </button>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="toaster">
  <ng-toast></ng-toast>
</div>
