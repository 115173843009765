import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule, NgForm, FormGroup, FormBuilder, ReactiveFormsModule, Validators, FormControl  } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { AssessmentService } from '../../services/assessment.service';
import { NotificationService } from '../../services/Notifications.service';
import { NgToastModule } from 'ng-angular-popup';
import { RoleService } from '../../services/role.service';
import { PrivilegeService } from '../../services/privilage.service';
import { UserService } from '../../services/user.service';
import { User } from '../../model/User';
import { PrivilegeAccess } from '../../model/PrivlageAcess';
import { SharedService } from '../../services/shared.service';
import { SupportStaffService } from '../../services/support-staff.service';

interface LoginFormControls {
  email: FormControl<string | null>;
  password: FormControl<string | null>;
}

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterLink, NgToastModule, ReactiveFormsModule],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'] // Corrected 'styleUrl' to 'styleUrls'
})
export class LoginComponent implements OnInit {
  inputValue: string = '';
  name: string = '';
  email: string = '';
  password: string = '';
  resetpassword: boolean = false;
  changePassword: boolean = false;
  newPassword: string = '';
  confirmPassword: string = '';
  changePwd: string | null = null;
  usrEmail: string | null = null;
  loginForm!: FormGroup<LoginFormControls>;
  submitted = false;
  errorMessage = '';

  constructor(
    private assessmentService: AssessmentService,
    private sharedService: SharedService,
    private router: Router,
    private notify: NotificationService,
    private priService: PrivilegeService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private supportStaffService: SupportStaffService
  ) {}

  ngOnInit(): void {
    if (typeof window !== 'undefined' && window.localStorage) {
      this.usrEmail = localStorage.getItem("userEmail");
    }
    this.loginForm = this.formBuilder.group<LoginFormControls>({
      email: this.formBuilder.control('', [Validators.required, Validators.email]),
      password: this.formBuilder.control('', Validators.required)
    });
  }

  onSubmit(): void {
    this.submitted = true;

    // If the form is invalid, stop the submission
    if (this.loginForm.invalid) {
      return;
    }
    this.assessmentService.userLogin(<string>this.loginForm.getRawValue().email,<string>this.loginForm.getRawValue().password).subscribe(
      (response: any) => {
        this.sharedService.setDetail(response);
        if (response != null) {
          this.sharedService.setToken(response.token);
          const usrRole = response.user.usrRole;
          if (usrRole == 2) {
            localStorage.setItem("Admin", "true");
          }
          if (response.user.usrActive === 0  ) {
            this.notify.failed("Waiting For Admin Approval");
          } else if(response.user.usrActive == 2){
            this.notify.failed("Your access has been declined. Please contact Admin for assistance");
          }
          else {
            this.priService.getPrivilege(response);
            this.assessmentService.getPrivilageById(usrRole).subscribe(
              (data: PrivilegeAccess[]) => {
                if (data && data.length > 0) {
                  this.notify.success("Successfully logged In");
                  const user: User = response.user;
                  this.userService.setUser(user);
                  this.userService.setUserPrivileges(data);
                  this.supportStaffService.getSupportStaff().subscribe(
                    () => {
                      console.log("Support staff data loaded successfully");
                    },
                    (error: any) => {
                      this.handleError(error, "Failed to load support staff data");
                    }
                  );
                  setTimeout(() => {
                    this.router.navigate(['dashboard']);
                  }, 500);
                }
              },
              (error: any) => {
                this.handleError(error, "Login failed");
              }
            );
          }
          
        }
      },
      (error: any) => {
        this.handleError(error, "Login failed");
      }
    );
  }

  resetPassword(): void {
    this.resetpassword = true;
  }

  // Getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  pwdReq(): void {
    this.assessmentService.sendEmail(this.email).subscribe(
      (response: any) => {
        this.resetpassword = false;
        localStorage.setItem("changePassword", "true");
        this.notify.success("Mail Sent successfully");
      },
      (error: any) => {
        this.handleError(error, "Failed to send password reset email");
      }
    );
  }

  private handleError(error: any, message: string): void {
    console.error(`${message}`, error);
    this.notify.failed(`${message}: ${error.error.message || error.message}`);
  }
}
