import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PdfWorkerService {

  private isDownloadingSubject = new BehaviorSubject<boolean>(false);
  private progressSubject = new BehaviorSubject<number>(0);

  // Observable to track downloading status
  isDownloading$ = this.isDownloadingSubject.asObservable();

  // Observable to track progress percentage
  progress$ = this.progressSubject.asObservable();

  setProgress(progress: number): void {
    this.progressSubject.next(progress);
  }

  setIsDownloading(isDownloading: boolean): void {
    this.isDownloadingSubject.next(isDownloading);
  }
}
