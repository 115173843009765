<div *ngIf="isLoading" class="loading-spinner">
  <div class="spinner"></div>
</div>
<!-- <div class="text-center" *ngIf="this.spaPerformersReport.length === 0">
  No Data!
</div> -->
<div>
  <div class="row d-flex align-items-stretch">
    <div class="col-sm-9 flex-column d-flex stretch-card">
      <div class="row">
        <div class="col-sm-12 grid-margin d-flex stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="d-flex align-items-center justify-content-between">
                <h4 class="card-title mb-2">{{ sportTitle }}</h4>
                <div class="dropdown">
                  <!-- <a href="#" class="text-success btn btn-link Overall"><i class="mdi mdi-refresh"></i></a> -->
                  <a href="#" class="text-success btn btn-link Overall dropdown-toggle dropdown-arrow-none"
                    data-bs-toggle="dropdown" id="settingsDropdownsales">
                    <i class="mdi mdi-dots-horizontal"></i></a>
                  <div class="dropdown-menu dropdown-menu-right navbar-dropdown"
                    aria-labelledby="settingsDropdownsales">
                    <a class="dropdown-item" (click)="filterPerformers('All','Sport')">
                      <i class="mdi mdi-grease-pencil text-primary"></i>
                      All
                    </a>
                    <a class="dropdown-item" (click)="filterPerformers('Excellent','Sport')">
                      <i class="mdi mdi-grease-pencil text-primary"></i>
                      Excellent
                    </a>
                    <a class="dropdown-item" (click)="filterPerformers('Good','Sport')">
                      <i class="mdi mdi-delete text-primary"></i>
                      Good
                    </a>
                    <a class="dropdown-item" (click)="filterPerformers('Average','Sport')">
                      <i class="mdi mdi-grease-pencil text-primary"></i>
                      Average
                    </a>
                    <a class="dropdown-item" (click)="filterPerformers('Poor','Sport')">
                      <i class="mdi mdi-delete text-primary"></i>
                      Poor
                    </a>
                  </div>
                </div>
              </div>
              <div id="new-chart"></div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="col-sm-3 flex-column d-flex stretch-card">
      <div class="">
        <div class="col-lg-12 d-flex grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="d-flex align-items-center justify-content-between mb-3">
              <img src="assets/image/icon1.png" class="img-fluid" alt="icon1">
              <p class="pb-0">Players Count</p>
              </div>
              <h2 class="font-weight-bold">{{sportCount }}</h2>
              <button (click)="selectRole('Player', null)" class="btn btn-primary" *ngIf="Admin || OrgAdmin">Add New Player</button>
            </div>
          </div>
        </div>
        <div class="col-lg-12 d-flex grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="d-flex align-items-center justify-content-between mb-3">
              <img src="assets/image/icon2.png" class="img-fluid" alt="icon1">
              <p class="pb-0">Overall Score</p>
              </div>
              <h2 class="font-weight-bold mb-3">{{averageOverallPercentage}}%</h2>
              <div class="progress">
                <!-- Use Angular's property binding to set the width of the progress bar -->
                <div class="progress-bar bg-warning" role="progressbar" [style.width.%]="averageOverallPercentage"
                  [attr.aria-valuenow]="averageOverallPercentage" aria-valuemin="0" aria-valuemax="100">
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>



  <div class="row row-cards row-deck">
    <div class="col-sm-9">
      <div class="col-12">
        <h4 class="card-title fw-semibold mb-2">International Benchmark (IBM)</h4>
        <div class="card py-3">
          <h4 class="m-3" *ngIf="chat">Athlete Data</h4>
          <div class="table-responsive">
            <table class="table table-hover table-outline table-vcenter text-nowrap card-table">
              <thead>
                <tr>
                  <th>Test</th>
                  <th>IBM Male</th>
                  <th>IBM Female</th>
                  <th>Team Average Score</th>
                  <th>Team Average PCTL</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Concentric Right Average Torque</td>
                  <td>150-200</td>
                  <td> 130-180 </td>
                  <td>{{ this.averageAvgTorqueCR | number: '1.2-2' }}</td>
                  <td>
                    <div class="circular-progress mb-2">
                      <svg width="60" height="60" class="progress-circle">
                        <!-- Background Circle -->
                        <circle class="background" cx="30" cy="30" r="27" stroke="green" stroke-width="6" fill="none">
                        </circle>
                        <!-- Foreground Circle (Percentage) -->
                        <circle class="circle" cx="30" cy="30" r="27" [attr.stroke]="getColor(AvgTorqueCR)"
                          stroke-width="6" stroke-dasharray="169.65" stroke-dashoffset="42.41" stroke-linecap="round">
                        </circle>
                        <!-- Hardcoded Percentage Text in Center -->
                        <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="#000"
                          font-size="12">
                          {{ AvgTorqueCR | number: '1.2-2' }}
                        </text>
                      </svg>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Concentric Right Peak Torque</td>
                  <td>180-240</td>
                  <td> 160-220 </td>
                  <td>{{ this.averagePeakTorqueCR | number: '1.2-2' }}</td>
                  <td>
                    <div class="circular-progress mb-2">
                      <svg width="60" height="60" class="progress-circle">
                        <!-- Background Circle -->
                        <circle class="background" cx="30" cy="30" r="27" stroke="green" stroke-width="6" fill="none">
                        </circle>
                        <!-- Foreground Circle (Percentage) -->
                        <circle class="circle" cx="30" cy="30" r="27" [attr.stroke]="getColor(PeakTorqueCR)"
                          stroke-width="6" stroke-dasharray="169.65" stroke-dashoffset="42.41" stroke-linecap="round">
                        </circle>
                        <!-- Hardcoded Percentage Text in Center -->
                        <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="#000"
                          font-size="12">
                          {{ PeakTorqueCR | number: '1.2-2' }}
                        </text>
                      </svg>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Concentric Left Average Torque</td>
                  <td>150-200</td>
                  <td> 130-180 </td>
                  <td>{{ this.averagePeakTorqueCL | number: '1.2-2' }}</td>
                  <td>
                    <div class="circular-progress mb-2">
                      <svg width="60" height="60" class="progress-circle">
                        <!-- Background Circle -->
                        <circle class="background" cx="30" cy="30" r="27" stroke="green" stroke-width="6" fill="none">
                        </circle>
                        <!-- Foreground Circle (Percentage) -->
                        <circle class="circle" cx="30" cy="30" r="27" [attr.stroke]="getColor(PeakTorqueCL)"
                          stroke-width="6" stroke-dasharray="169.65" stroke-dashoffset="42.41" stroke-linecap="round">
                        </circle>
                        <!-- Hardcoded Percentage Text in Center -->
                        <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="#000"
                          font-size="12">
                          {{ PeakTorqueCL | number: '1.2-2' }}
                        </text>
                      </svg>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Concentric Left Peak Torque</td>
                  <td>180-240</td>
                  <td> 160-220 </td>
                  <td>{{ this.averageAvgTorqueCR | number: '1.2-2' }}</td>
                  <td>
                    <div class="circular-progress mb-2">
                      <svg width="60" height="60" class="progress-circle">
                        <!-- Background Circle -->
                        <circle class="background" cx="30" cy="30" r="27" stroke="green" stroke-width="6" fill="none">
                        </circle>
                        <!-- Foreground Circle (Percentage) -->
                        <circle class="circle" cx="30" cy="30" r="27" [attr.stroke]="getColor(AvgTorqueCR)"
                          stroke-width="6" stroke-dasharray="169.65" stroke-dashoffset="42.41" stroke-linecap="round">
                        </circle>
                        <!-- Hardcoded Percentage Text in Center -->
                        <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="#000"
                          font-size="12">
                          {{ AvgTorqueCR | number: '1.2-2' }}
                        </text>
                      </svg>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Isokinetic Left Average Torque</td>
                  <td>180-240</td>
                  <td> 160-220 </td>
                  <td>{{ this.averageAvgTorqueIL | number: '1.2-2' }}</td>
                  <td>
                    <div class="circular-progress mb-2">
                      <svg width="60" height="60" class="progress-circle">
                        <!-- Background Circle -->
                        <circle class="background" cx="30" cy="30" r="27" stroke="green" stroke-width="6" fill="none">
                        </circle>
                        <!-- Foreground Circle (Percentage) -->
                        <circle class="circle" cx="30" cy="30" r="27" [attr.stroke]="getColor(AvgTorqueIL)"
                          stroke-width="6" stroke-dasharray="169.65" stroke-dashoffset="42.41" stroke-linecap="round">
                        </circle>
                        <!-- Hardcoded Percentage Text in Center -->
                        <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="#000"
                          font-size="12">
                          {{ AvgTorqueIL | number: '1.2-2' }}
                        </text>
                      </svg>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Isokinetic Left Peak Torque</td>
                  <td>250-300</td>
                  <td> 240-300 </td>
                  <td>{{ this.averagePeakTorqueIL | number: '1.2-2' }}</td>
                  <td>
                    <div class="circular-progress mb-2">
                      <svg width="60" height="60" class="progress-circle">
                        <!-- Background Circle -->
                        <circle class="background" cx="30" cy="30" r="27" stroke="green" stroke-width="6" fill="none">
                        </circle>
                        <!-- Foreground Circle (Percentage) -->
                        <circle class="circle" cx="30" cy="30" r="27" [attr.stroke]="getColor(PeakTorqueIL)"
                          stroke-width="6" stroke-dasharray="169.65" stroke-dashoffset="42.41" stroke-linecap="round">
                        </circle>
                        <!-- Hardcoded Percentage Text in Center -->
                        <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="#000"
                          font-size="12">
                          {{ PeakTorqueIL | number: '1.2-2' }}
                        </text>
                      </svg>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Isokinetic Right Average Torque</td>
                  <td>180-240</td>
                  <td> 160-220 </td>
                  <td>{{ this.averageAvgTorqueIR | number: '1.2-2' }}</td>
                  <td>
                    <div class="circular-progress mb-2">
                      <svg width="60" height="60" class="progress-circle">
                        <!-- Background Circle -->
                        <circle class="background" cx="30" cy="30" r="27" stroke="green" stroke-width="6" fill="none">
                        </circle>
                        <!-- Foreground Circle (Percentage) -->
                        <circle class="circle" cx="30" cy="30" r="27" [attr.stroke]="getColor(AvgTorqueIR)"
                          stroke-width="6" stroke-dasharray="169.65" stroke-dashoffset="42.41" stroke-linecap="round">
                        </circle>
                        <!-- Hardcoded Percentage Text in Center -->
                        <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="#000"
                          font-size="12">
                          {{ AvgTorqueIR | number: '1.2-2' }}
                        </text>
                      </svg>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Isokinetic Right Peak Torque</td>
                  <td>250-300</td>
                  <td> 240-300 </td>
                  <td>{{ this.averagePeakTorqueIR | number: '1.2-2' }}</td>
                  <td>
                    <div class="circular-progress mb-2">
                      <svg width="60" height="60" class="progress-circle">
                        <!-- Background Circle -->
                        <circle class="background" cx="30" cy="30" r="27" stroke="green" stroke-width="6" fill="none">
                        </circle>
                        <!-- Foreground Circle (Percentage) -->
                        <circle class="circle" cx="30" cy="30" r="27" [attr.stroke]="getColor(PeakTorqueIR)"
                          stroke-width="6" stroke-dasharray="169.65" stroke-dashoffset="42.41" stroke-linecap="round">
                        </circle>
                        <!-- Hardcoded Percentage Text in Center -->
                        <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="#000"
                          font-size="12">
                          {{ PeakTorqueIR | number: '1.2-2' }}
                        </text>
                      </svg>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-3">
      <div class="row flex-grow">
        <div class="col-sm-12 grid-margin stretch-card">
          <div class="card">
            <!-- Display when spaPerformers has data -->
            <div class="card-body" *ngIf="TopPerformer.length > 0">
              <div>
                <h4 class="card-title fw-semibold">Top Performer</h4>
                <h4 class="font-weight-bold text-name">Name : {{ TopPerformer[0]?.usrFullName }}</h4>
                <h4 class="font-weight-bold text-id">ID :
                  {{ TopPerformer[0]?.spaAthlete }}
                </h4>
              </div>
              <div class="donut-container d-flex align-items-center justify-content-center mb-4">
                <div class="donut-circle" [ngClass]="{
                     'dark-green': TopPerformer[0]?.overallZScore >= 85,
                     'light-green': TopPerformer[0]?.overallZScore >= 70 && TopPerformer[0]?.overallZScore < 85,
                     'yellow': TopPerformer[0]?.overallZScore >= 40 && TopPerformer[0]?.overallZScore < 70,
                     'red': TopPerformer[0]?.overallZScore < 40,
                     'transparent-border': TopPerformer[0]?.overallZScore == null
                   }">
                  <div class="inner-circle">
                    <h3 class="font-weight-bold">{{ TopPerformer[0]?.overallZScore| number: '1.2-2' }}%</h3>
                  </div>
                </div>
              </div>

              <table class="score-table">
                <thead>
                  <tr>
                    <th>Test</th>
                    <th>Score</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="score-title">Average Torque(CR)</td>
                    <td class="score-value">{{TopPerformer[0]?.spaAverageTorqueCR}}</td>
                  </tr>
                  <tr>
                    <td class="score-title">Peak Torque(CR)</td>
                    <td class="score-value">{{TopPerformer[0]?.spaPeakTorqueCR}}</td>
                  </tr>
                  <tr>
                    <td class="score-title">Average Torque(CL)</td>
                    <td class="score-value">{{TopPerformer[0]?.spaAverageTorqueCL}}</td>
                  </tr>
                  <tr>
                    <td class="score-title">Peak Torque(CL)</td>
                    <td class="score-value">{{TopPerformer[0]?.spaPeakTorqueCL}}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- Display when spaPerformers has no data -->
            <div class="card-body d-flex align-items-center justify-content-center" *ngIf="TopPerformer.length === 0">
              <h4 class="text-muted">No Data Available</h4>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>



  <div class="d-sm-flex justify-content-between align-items-center mb-2">
    <h4 class="card-title fw-semibold">Sports {{header}} Performers</h4>
    <div>
      <div class="select-wrapper">
        <ng-select [items]="selectOptions" bindLabel="label" bindValue="value" [(ngModel)]="selectedStatus"
          (change)="onPhysiologyStatusChange()" class="ng-select-custom" [searchable]="false" [clearable]="false">
          <ng-template ng-label-tmp let-item="item">
            {{ item.label }}
            <i class="fa fa-chevron-down"></i> 
          </ng-template>
          <ng-template ng-option-tmp let-item="item">
            {{ item.label }}
          </ng-template>
        </ng-select>


      </div>
    </div>
  </div>
  <!-- Top Performers Section -->
  <div class="col-12">
    <div class="card py-3">
      <h4 class="m-3" *ngIf="chat == undefined">Sports {{header}} Performers</h4>
      <h4 class="m-3" *ngIf="chat">Athlete Data</h4>
      <div class="table-responsive">
        <table class="table table-hover table-outline table-vcenter text-nowrap card-table">
          <thead>
            <tr>
             
              <th>Athletes</th>
              <th>Overall %</th>
              <th>Average TorqueCR (nm)</th>
              <th>Average TorqueCL (nm)</th>
              <th>Peak TorqueCR (nm)</th>
              <th>Peak TorqueCL (nm)</th>
              <th>Average TorqueIR (nm)</th>
              <th>Average TorqueIL (nm)</th>
              <th>Peak TorqueIR (nm)</th>
              <th>Peak TorqueIL (nm)</th>

            </tr>
          </thead>
          <tbody *ngIf="spaPerformers && spaPerformers.length > 0; else noData">
            <tr *ngFor="let athlete of spaPerformers">
              
              <td>
                <div class="d-flex align-items-center">
                <img class="avatar" src="{{athlete.image}}">
                <div>{{ athlete.usrFullName }}
                <div class="small text-muted">ID: {{ athlete.spaAthlete }}</div>
              </div>
                </div>
              </td>
              <td>
                <div class="clearfix">
                  <div class="float-left"><strong>{{ athlete.overallZScore| number: '1.2-2' }} %</strong></div>
                </div>
                <div class="progress progress-xs">
                  <div class="progress-bar" [ngClass]="{
                              'bg-lightgreen':  athlete.overallZScore >= 85,
                              'bg-green':  athlete.overallZScore >= 70 &&  athlete.overallZScore < 85,
                              'bg-yellow':  athlete.overallZScore >= 40 &&  athlete.overallZScore  < 70,
                              'bg-red': athlete.overallZScore < 40
                            }" role="progressbar" [style.width.%]=" athlete.overallZScore"></div>
                </div>
              </td>
              <td>{{ athlete.spaAverageTorqueCR }}</td>
              <td>{{ athlete.spaAverageTorqueCL }}</td>
              <td>{{ athlete.spaPeakTorqueCR }}</td>
              <td>{{ athlete.spaPeakTorqueCL }}</td>
              <td>{{ athlete.spaAverageTorqueIR }}</td>
              <td>{{ athlete.spaAverageTorqueIL }}</td>
              <td>{{ athlete.spaPeakTorqueIR }}</td>
              <td>{{ athlete.spaPeakTorqueIL }}</td>


            </tr>
          </tbody>
          <!-- Template for when there is no data -->
          <ng-template #noData>
            <tr>
              <td colspan="8" class="text-center">Data not available</td>
            </tr>
          </ng-template>
        </table>

      </div>
    </div>
  </div>


  <!--IsoKinetics-->

  <!--Concentric-->
  <div class="row">
    <div class="col-sm-12 grid-margin d-flex stretch-card">
      <div class="card">
        <div class="card-body">
          <div class="d-flex align-items-center justify-content-between">
            <h4 class="card-title mb-2">{{ concentricTitle }}</h4>
            <div class="dropdown">
              <!-- <a href="#" class="text-success btn btn-link Overall"><i class="mdi mdi-refresh"></i></a> -->
              <a href="#" class="text-success btn btn-link Overall dropdown-toggle dropdown-arrow-none"
                data-bs-toggle="dropdown" id="settingsDropdownsales">
                <i class="mdi mdi-dots-horizontal"></i></a>
              <div class="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="settingsDropdownsales">
                <a class="dropdown-item" (click)="filterPerformers('All','Concentric')">
                  <i class="mdi mdi-grease-pencil text-primary"></i>
                  All
                </a>
                <a class="dropdown-item" (click)="filterPerformers('Excellent','Concentric')">
                  <i class="mdi mdi-grease-pencil text-primary"></i>
                  Excellent
                </a>
                <a class="dropdown-item" (click)="filterPerformers('Good','Concentric')">
                  <i class="mdi mdi-delete text-primary"></i>
                  Good
                </a>
                <a class="dropdown-item" (click)="filterPerformers('Average','Concentric')">
                  <i class="mdi mdi-grease-pencil text-primary"></i>
                  Average
                </a>
                <a class="dropdown-item" (click)="filterPerformers('Poor','Concentric')">
                  <i class="mdi mdi-delete text-primary"></i>
                  Poor
                </a>
              </div>
            </div>
          </div>
          <div id="concentric-chart"></div>
        </div>
      </div>
    </div>
  </div>
  <!-- Top Performers Section -->

  <div class="row row-cards row-deck">
    <div class="col-12">
      <h4 class="card-title fw-semibold mb-2">Concentric International Benchmark (IBM)</h4>
      <div class="card py-3">
        <h4 class="m-3" *ngIf="chat">Athlete Data</h4>
        <div class="table-responsive">
          <table class="table table-hover table-outline table-vcenter text-nowrap card-table">
            <thead>
              <tr>
                <th>Test</th>
                <th>IBM Male</th>
                <th>IBM Female</th>
                <th>Team Average Score</th>
                <th>Team Average PCTL</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Concentric Right Average Torque</td>
                <td>150-200</td>
                <td> 130-180 </td>
                <td>{{ this.averageAvgTorqueCR | number: '1.2-2' }}</td>
                <td>
                  <div class="circular-progress mb-2">
                    <svg width="60" height="60" class="progress-circle">
                      <!-- Background Circle -->
                      <circle class="background" cx="30" cy="30" r="27" stroke="green" stroke-width="6" fill="none">
                      </circle>
                      <!-- Foreground Circle (Percentage) -->
                      <circle class="circle" cx="30" cy="30" r="27" [attr.stroke]="getColor(AvgTorqueCR)"
                        stroke-width="6" stroke-dasharray="169.65" stroke-dashoffset="42.41" stroke-linecap="round">
                      </circle>
                      <!-- Hardcoded Percentage Text in Center -->
                      <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="#000" font-size="12">
                        {{ AvgTorqueCR | number: '1.2-2' }}
                      </text>
                    </svg>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Concentric Right Peak Torque</td>
                <td>180-240</td>
                <td> 160-220 </td>
                <td>{{ this.averagePeakTorqueCR | number: '1.2-2' }}</td>
                <td>
                  <div class="circular-progress mb-2">
                    <svg width="60" height="60" class="progress-circle">
                      <!-- Background Circle -->
                      <circle class="background" cx="30" cy="30" r="27" stroke="green" stroke-width="6" fill="none">
                      </circle>
                      <!-- Foreground Circle (Percentage) -->
                      <circle class="circle" cx="30" cy="30" r="27" [attr.stroke]="getColor(PeakTorqueCR)"
                        stroke-width="6" stroke-dasharray="169.65" stroke-dashoffset="42.41" stroke-linecap="round">
                      </circle>
                      <!-- Hardcoded Percentage Text in Center -->
                      <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="#000" font-size="12">
                        {{ PeakTorqueCR | number: '1.2-2' }}
                      </text>
                    </svg>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Concentric Left Average Torque</td>
                <td>150-200</td>
                <td> 130-180 </td>
                <td>{{ this.averagePeakTorqueCL | number: '1.2-2' }}</td>
                <td>
                  <div class="circular-progress mb-2">
                    <svg width="60" height="60" class="progress-circle">
                      <!-- Background Circle -->
                      <circle class="background" cx="30" cy="30" r="27" stroke="green" stroke-width="6" fill="none">
                      </circle>
                      <!-- Foreground Circle (Percentage) -->
                      <circle class="circle" cx="30" cy="30" r="27" [attr.stroke]="getColor(PeakTorqueCL)"
                        stroke-width="6" stroke-dasharray="169.65" stroke-dashoffset="42.41" stroke-linecap="round">
                      </circle>
                      <!-- Hardcoded Percentage Text in Center -->
                      <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="#000" font-size="12">
                        {{ PeakTorqueCL | number: '1.2-2' }}
                      </text>
                    </svg>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Concentric Left Peak Torque</td>
                <td>180-240</td>
                <td> 160-220 </td>
                <td>{{ this.averageAvgTorqueCR | number: '1.2-2' }}</td>
                <td>
                  <div class="circular-progress mb-2">
                    <svg width="60" height="60" class="progress-circle">
                      <!-- Background Circle -->
                      <circle class="background" cx="30" cy="30" r="27" stroke="green" stroke-width="6" fill="none">
                      </circle>
                      <!-- Foreground Circle (Percentage) -->
                      <circle class="circle" cx="30" cy="30" r="27" [attr.stroke]="getColor(AvgTorqueCR)"
                        stroke-width="6" stroke-dasharray="169.65" stroke-dashoffset="42.41" stroke-linecap="round">
                      </circle>
                      <!-- Hardcoded Percentage Text in Center -->
                      <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="#000" font-size="12">
                        {{ AvgTorqueCR | number: '1.2-2' }}
                      </text>
                    </svg>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="d-sm-flex justify-content-between align-items-center mb-2">
      <h4 class="card-title fw-semibold mb-2">Concentric {{conheader}} Performers</h4>
      <div>
        <!-- <div class="select-wrapper">

          <select [(ngModel)]="selectedConcentricStatus" (change)="loadConcentricTopPerformersData()"
            class="form-select">
            <option selected disabled>Select Option</option>
            <option value="0">All</option>
            <option value="1">Excellent</option>
            <option value="2">Good</option>
            <option value="3">Average</option>
            <option value="4">Poor</option>
          </select>
          <i class="fa fa-chevron-down"></i>
        </div> -->

        <div class="select-wrapper">
          <ng-select
            class="ng-select-custom"
            [(ngModel)]="selectedConcentricStatus"
            (change)="loadConcentricTopPerformersData()"
            [searchable]="false"
            [clearable]="false">
            <ng-template ng-label-tmp>
              <span>{{ conheader }}</span>
              <i class="fa fa-chevron-down"></i>
            </ng-template>
          
            <ng-option [value]="0">All</ng-option>
            <ng-option [value]="1">Excellent</ng-option>
            <ng-option [value]="2">Good</ng-option>
            <ng-option [value]="3">Average</ng-option>
            <ng-option [value]="4">Poor</ng-option>
          </ng-select>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="card py-3">
        <h4 class="m-3" *ngIf="chat == undefined">{{conheader}} Performers</h4>
        <h4 class="m-3" *ngIf="chat">Athlete Data</h4>
        <div class="table-responsive">
          <table class="table table-hover table-outline table-vcenter text-nowrap card-table">
            <thead>
              <tr>
              
                <th>Athletes</th>
                <th>Overall%</th>
                <th>Average TorqueCR (nm)</th>
                <th>Average TorqueCL (nm)</th>
                <th>Peak TorqueCR (nm)</th>
                <th>Peak TorqueCL (nm)</th>

              </tr>
            </thead>
            <tbody *ngIf="ConcentricPerformers && ConcentricPerformers.length > 0; else noData">
              <tr *ngFor="let athlete of ConcentricPerformers">
                
                <td>
                  <div class="d-flex align-items-center">
                  <img class="avatar" src="{{athlete.image}}">
                  <div>{{ athlete.usrFullName }}
                  <div class="small text-muted">ID: {{ athlete.spaAthlete }}</div>
                </div>
                  </div>
                </td>
                <td>
                  <div class="clearfix">
                    <div class="float-left"><strong>{{ athlete.overallConcentricZScore| number: '1.2-2' }} %</strong>
                    </div>
                  </div>
                  <div class="progress progress-xs">
                    <div class="progress-bar" [ngClass]="{
                        'bg-lightgreen':  athlete.overallConcentricZScore >= 85,
                        'bg-green':  athlete.overallConcentricZScore >= 70 &&  athlete.overallConcentricZScore < 85,
                        'bg-yellow':  athlete.overallConcentricZScore >= 40 &&  athlete.overallConcentricZScore  < 70,
                        'bg-red': athlete.overallConcentricZScore < 40
                      }" role="progressbar" [style.width.%]=" athlete.overallConcentricZScore"></div>
                  </div>
                </td>
                <td>{{ athlete.spaAverageTorqueCR }}</td>
                <td>{{ athlete.spaAverageTorqueCL }}</td>
                <td>{{ athlete.spaPeakTorqueCR }}</td>
                <td>{{ athlete.spaPeakTorqueCL }}</td>

              </tr>
            </tbody>
            <!-- Template for when there is no data -->
            <ng-template #noData>
              <tr>
                <td colspan="8" class="text-center">Data not available</td>
              </tr>
            </ng-template>
          </table>

        </div>
      </div>

      <div class="col-sm-12 grid-margin d-flex stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-center justify-content-between">
              <h4 class="card-title mb-2">{{ isokineticTitle }}</h4>
              <div class="dropdown">
                <!-- <a href="#" class="text-success btn btn-link Overall"><i class="mdi mdi-refresh"></i></a> -->
                <a href="#" class="text-success btn btn-link Overall dropdown-toggle dropdown-arrow-none"
                  data-bs-toggle="dropdown" id="settingsDropdownsales">
                  <i class="mdi mdi-dots-horizontal"></i></a>
                <div class="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="settingsDropdownsales">
                  <a class="dropdown-item" (click)="filterPerformers('All','Isokinetic')">
                    <i class="mdi mdi-grease-pencil text-primary"></i>
                    All
                  </a>
                  <a class="dropdown-item" (click)="filterPerformers('Excellent','Isokinetic')">
                    <i class="mdi mdi-grease-pencil text-primary"></i>
                    Excellent
                  </a>
                  <a class="dropdown-item" (click)="filterPerformers('Good','Isokinetic')">
                    <i class="mdi mdi-delete text-primary"></i>
                    Good
                  </a>
                  <a class="dropdown-item" (click)="filterPerformers('Average','Isokinetic')">
                    <i class="mdi mdi-grease-pencil text-primary"></i>
                    Average
                  </a>
                  <a class="dropdown-item" (click)="filterPerformers('Poor','Isokinetic')">
                    <i class="mdi mdi-delete text-primary"></i>
                    Poor
                  </a>
                </div>
              </div>
            </div>
            <div id="isokinetic-chart"></div>
          </div>
        </div>
      </div>
      <!-- Top Performers Section -->
      <div class="row row-cards row-deck">

        <div class="col-12">
          <h4 class="card-title fw-semibold mb-2">Isokinetic International Benchmark (IBM)</h4>
          <div class="card py-3">
            <h4 class="m-3" *ngIf="chat">Athlete Data</h4>
            <div class="table-responsive">
              <table class="table table-hover table-outline table-vcenter text-nowrap card-table">
                <thead>
                  <tr>
                    <th>Test</th>
                    <th>IBM Male</th>
                    <th>IBM Female</th>
                    <th>Team Average Score</th>
                    <th>Team Average PCTL</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Isokinetic Right Average Torque</td>
                    <td>180-240</td>
                    <td> 160-220 </td>
                    <td>{{ this.averageAvgTorqueIR | number: '1.2-2' }}</td>
                    <td>
                      <div class="circular-progress mb-2">
                        <svg width="60" height="60" class="progress-circle">
                          <!-- Background Circle -->
                          <circle class="background" cx="30" cy="30" r="27" stroke="green" stroke-width="6" fill="none">
                          </circle>
                          <!-- Foreground Circle (Percentage) -->
                          <circle class="circle" cx="30" cy="30" r="27" [attr.stroke]="getColor(AvgTorqueIR)"
                            stroke-width="6" stroke-dasharray="169.65" stroke-dashoffset="42.41" stroke-linecap="round">
                          </circle>
                          <!-- Hardcoded Percentage Text in Center -->
                          <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="#000"
                            font-size="12">
                            {{ AvgTorqueIR | number: '1.2-2' }}
                          </text>
                        </svg>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Isokinetic Right Peak Torque</td>
                    <td>250-300</td>
                    <td> 240-300 </td>
                    <td>{{ this.averagePeakTorqueIR | number: '1.2-2' }}</td>
                    <td>
                      <div class="circular-progress mb-2">
                        <svg width="60" height="60" class="progress-circle">
                          <!-- Background Circle -->
                          <circle class="background" cx="30" cy="30" r="27" stroke="green" stroke-width="6" fill="none">
                          </circle>
                          <!-- Foreground Circle (Percentage) -->
                          <circle class="circle" cx="30" cy="30" r="27" [attr.stroke]="getColor(PeakTorqueIR)"
                            stroke-width="6" stroke-dasharray="169.65" stroke-dashoffset="42.41" stroke-linecap="round">
                          </circle>
                          <!-- Hardcoded Percentage Text in Center -->
                          <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="#000"
                            font-size="12">
                            {{ PeakTorqueIR | number: '1.2-2' }}
                          </text>
                        </svg>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Isokinetic Left Average Torque</td>
                    <td>180-240</td>
                    <td> 160-220 </td>
                    <td>{{ this.averageAvgTorqueIL | number: '1.2-2' }}</td>
                    <td>
                      <div class="circular-progress mb-2">
                        <svg width="60" height="60" class="progress-circle">
                          <!-- Background Circle -->
                          <circle class="background" cx="30" cy="30" r="27" stroke="green" stroke-width="6" fill="none">
                          </circle>
                          <!-- Foreground Circle (Percentage) -->
                          <circle class="circle" cx="30" cy="30" r="27" [attr.stroke]="getColor(AvgTorqueIL)"
                            stroke-width="6" stroke-dasharray="169.65" stroke-dashoffset="42.41" stroke-linecap="round">
                          </circle>
                          <!-- Hardcoded Percentage Text in Center -->
                          <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="#000"
                            font-size="12">
                            {{ AvgTorqueIL | number: '1.2-2' }}
                          </text>
                        </svg>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Isokinetic Left Peak Torque</td>
                    <td>250-300</td>
                    <td> 240-300 </td>
                    <td>{{ this.averagePeakTorqueIL | number: '1.2-2' }}</td>
                    <td>
                      <div class="circular-progress mb-2">
                        <svg width="60" height="60" class="progress-circle">
                          <!-- Background Circle -->
                          <circle class="background" cx="30" cy="30" r="27" stroke="green" stroke-width="6" fill="none">
                          </circle>
                          <!-- Foreground Circle (Percentage) -->
                          <circle class="circle" cx="30" cy="30" r="27" [attr.stroke]="getColor(PeakTorqueIL)"
                            stroke-width="6" stroke-dasharray="169.65" stroke-dashoffset="42.41" stroke-linecap="round">
                          </circle>
                          <!-- Hardcoded Percentage Text in Center -->
                          <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="#000"
                            font-size="12">
                            {{ PeakTorqueIL | number: '1.2-2' }}
                          </text>
                        </svg>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="d-sm-flex justify-content-between align-items-center mb-2">
          <h4 class="card-title fw-semibold"> Isokinetic {{isoheader}} Performers</h4>
          <div>
            <!-- <div class="select-wrapper">

              <select [(ngModel)]="selectedIsoKineticStatus" (change)="loadIsokineticTopPerformersData()"
                class="form-select">
                <option selected disabled>Select Option</option>
                <option value="0">All</option>
                <option value="1">Excellent</option>
                <option value="2">Good</option>
                <option value="3">Average</option>
                <option value="4">Poor</option>
              </select>
              <i class="fa fa-chevron-down"></i>
            </div> -->

            <div class="select-wrapper">
              <ng-select
                class="ng-select-custom"
                [(ngModel)]="selectedIsoKineticStatus"
                (change)="loadIsokineticTopPerformersData()"
                [searchable]="false"
                [clearable]="false">
                <ng-template ng-label-tmp>
                  <span>{{ isoheader }}</span>
                  <i class="fa fa-chevron-down"></i>
                </ng-template>
              
                <ng-option [value]="0">All</ng-option>
                <ng-option [value]="1">Excellent</ng-option>
                <ng-option [value]="2">Good</ng-option>
                <ng-option [value]="3">Average</ng-option>
                <ng-option [value]="4">Poor</ng-option>
              </ng-select>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="card py-3">
            <h4 class="m-3" *ngIf="chat == undefined">{{isoheader}} Performers</h4>
            <h4 class="m-3" *ngIf="chat">Athlete Data</h4>
            <div class="table-responsive">
              <table class="table table-hover table-outline table-vcenter text-nowrap card-table">
                <thead>
                  <tr>
                    
                    <th>Athletes</th>
                    <th>Overall%</th>
                    <th>Average TorqueIR (nm)</th>
                    <th>Average TorqueIL (nm)</th>
                    <th>Peak TorqueIR (nm)</th>
                    <th>Peak TorqueIL (nm)</th>

                  </tr>
                </thead>
                <tbody *ngIf="IsokineticPerformers && IsokineticPerformers.length > 0; else noData">
                  <tr *ngFor="let athlete of IsokineticPerformers">
                  
                    <td>
                      <div class="d-flex align-items-center">
                      <img class="avatar" src="{{athlete.image}}">
                      <div>{{ athlete.usrFullName }}
                      <div class="small text-muted">ID: {{ athlete.spaAthlete }}</div>
                    </div>
                      </div>
                    </td>
                    <td>
                      <div class="clearfix">
                        <div class="float-left"><strong>{{ athlete.overallIsokineticScore| number: '1.2-2' }} %</strong>
                        </div>
                      </div>
                      <div class="progress progress-xs">
                        <div class="progress-bar" [ngClass]="{
                        'bg-lightgreen':  athlete.overallIsokineticScore >= 85,
                        'bg-green':  athlete.overallIsokineticScore >= 70 &&  athlete.overallIsokineticScore < 85,
                        'bg-yellow':  athlete.overallIsokineticScore >= 40 &&  athlete.overallIsokineticScore  < 70,
                        'bg-red': athlete.overallIsokineticScore < 40
                      }" role="progressbar" [style.width.%]=" athlete.overallIsokineticScore"></div>
                      </div>
                    </td>
                    <td>{{ athlete.spaAverageTorqueIR }}</td>
                    <td>{{ athlete.spaAverageTorqueIL }}</td>
                    <td>{{ athlete.spaPeakTorqueIR }}</td>
                    <td>{{ athlete.spaPeakTorqueIL }}</td>
                  </tr>
                </tbody>
                <!-- Template for when there is no data -->
                <ng-template #noData>
                  <tr>
                    <td colspan="8" class="text-center">Data not available</td>
                  </tr>
                </ng-template>
              </table>
            </div>
          </div>
        </div>