<div class="body-wrapper">
    <div class="team-single">
      <div class="d-flex justify-content-between mb-3">
        <div>
          <button class="btn btn-primary" (click)="onBack()">Back</button>
      </div>
      <div>
        <button type="button" class="btn btn-primary"(click)="downloadPDF()">Download</button>  
      </div>
      </div>
      <div class="contact-info-section margin-40px-tb">
        <div id="pdf-content">
          <div class="row">
            <div class="col-lg-4 col-md-5 xs-margin-30px-bottom">
                <div class="col-lg-12">
                    <div class="card mb-4">
                      <div class="content-header">
                        <h2 class="sub-title">Sports Performance Analysis</h2>
                      </div>
                      <div class="content-body">
                        <!-- Sports Activity -->
                        <div class="mb-4" *ngFor="let activity of activities">
                          <div class="clearfix">
                            <div class="d-flex justify-content-between align-items-center mb-3">
                              <p class="small-text">{{ activity.name }}</p>
                              <strong>{{ activity.percentage }}%</strong>
                            </div>
                          </div>
                          <div class="progress progress-xs">
                            <div class="progress-bar"
                                 [ngClass]="getProgressColor(activity.percentage)"
                                 role="progressbar"
                                 [style.width]="activity.percentage + '%'"
                                 [attr.aria-valuenow]="activity.percentage"
                                 aria-valuemin="0"
                                 aria-valuemax="100">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                 
              <div class="col-lg-12">
                <div class="bg-light-blue">
                  <h4 class="side-Title">{{athleteFullName}}</h4>
                  <p class="side-content">Athelete</p>
                </div>
              </div>
            </div>
            <div class="col-lg-8 card col-md-7">
              <div class="card-body">
                <div class="team-single-text padding-50px-left sm-no-padding-left">
                  <div class="mobile-res d-flex justify-content-between mb-4">
                    <div>
                      <h4 class="Title">Athlete Details</h4>
                    </div>
                    <!-- <div>
                      <p>Test Taken Date:</p>
                      <h4 class="sub-title mb-4">{{ date | date: 'dd-MM-yyyy' }}</h4>
                    </div> -->
                    <div>
                      <p>Assessment Date:</p>
                      <h4 class="sub-title mb-4">{{ assessmentDate | date: 'dd-MM-yyyy' }}</h4>
                    
                    </div>
                  </div>
                  <div class="contact-info-section margin-40px-tb">
                    <ul class="list-style9 no-margin">
                      <li *ngIf="athleteId">
                        <div class="row">
                          <div class="col-md-5 col-5">
                            <strong class="margin-10px-left">Athlete ID:</strong>
                          </div>
                          <div class="col-md-7 col-7">
                            <p>{{athleteId}}</p>
                          </div>
                        </div>
                      </li>
                      <li *ngIf="spaAverageTorqueCR">
                        <div class="row">
                          <div class="col-md-5 col-5">
                            <strong class="margin-10px-left"> Average TorqueCR (nm):</strong>
                          </div>
                          <div class="col-md-7 col-7">
                            <p>{{spaAverageTorqueCR}}</p>
                          </div>
                        </div>
                      </li>
                      <li *ngIf="spaAverageTorqueCL">
                        <div class="row">
                          <div class="col-md-5 col-5">
                            <strong class="margin-10px-left"> Average TorqueCL (nm):</strong>
                          </div>
                          <div class="col-md-7 col-7">
                            <p>{{spaAverageTorqueCL}}</p>
                          </div>
                        </div>
                      </li>
                      <li *ngIf="spaPeakTorqueCR">
                        <div class="row">
                          <div class="col-md-5 col-5">
                            <strong class="margin-10px-left">Peak TorqueCR (nm):</strong>
                          </div>
                          <div class="col-md-7 col-7">
                            <p>{{spaPeakTorqueCR}}</p>
                          </div>
                        </div>
                      </li>
                      <li *ngIf="spaPeakTorqueCL">
                        <div class="row">
                          <div class="col-md-5 col-5">
                            <strong class="margin-10px-left">Peak TorqueCL (nm):</strong>
                          </div>
                          <div class="col-md-7 col-7">
                            <p>{{spaPeakTorqueCL}}</p>
                          </div>
                        </div>
                      </li>
                      <li *ngIf="spaAverageTorqueIR">
                        <div class="row">
                          <div class="col-md-5 col-5">
                            <strong class="margin-10px-left">AverageTorqueIR (nm):</strong>
                          </div>
                          <div class="col-md-7 col-7">
                            <p>{{spaAverageTorqueIR}}</p>
                          </div>
                        </div>
                      </li>
                      <li *ngIf="spaAverageTorqueIL">
                        <div class="row">
                          <div class="col-md-5 col-5">
                            <strong class="margin-10px-left">Average TorqueIL (nm):</strong>
                          </div>
                          <div class="col-md-7 col-7">
                            <p>{{spaAverageTorqueIL}}</p>
                          </div>
                        </div>
                      </li>
                      <li *ngIf="spaPeakTorqueIR">
                        <div class="row">
                          <div class="col-md-5 col-5">
                            <strong class="margin-10px-left">Peak TorqueIR (nm):</strong>
                          </div>
                          <div class="col-md-7 col-7">
                            <p>{{spaPeakTorqueIR}}</p>
                          </div>
                        </div>
                      </li>
                      <li *ngIf="spaPeakTorqueIL">
                        <div class="row">
                          <div class="col-md-5 col-5">
                            <strong class="margin-10px-left">Peak TorqueIL (nm):</strong>
                          </div>
                          <div class="col-md-7 col-7">
                            <p>{{spaPeakTorqueIL}}</p>
                          </div>
                        </div>
                      </li>
                      <li *ngIf="spaJumpHeight">
                        <div class="row">
                          <div class="col-md-5 col-5">
                            <strong class="margin-10px-left">Jump Height (cm):</strong>
                          </div>
                          <div class="col-md-7 col-7">
                            <p>{{spaJumpHeight}}</p>
                          </div>
                        </div>
                      </li>
                      <li *ngIf="spaFlightTime">
                        <div class="row">
                          <div class="col-md-5 col-5">
                            <strong class="margin-10px-left">Flight Time  (ms):</strong>
                          </div>
                          <div class="col-md-7 col-7">
                            <p>{{spaFlightTime}}</p>
                          </div>
                        </div>
                      </li>
                      <li *ngIf="spaVelocity">
                        <div class="row">
                          <div class="col-md-5 col-5">
                            <strong class="margin-10px-left">Velocity (m/s):</strong>
                          </div>
                          <div class="col-md-7 col-7">
                            <p>{{spaVelocity}}</p>
                          </div>
                        </div>
                      </li>
                      <li *ngIf="spaForce">
                        <div class="row">
                          <div class="col-md-5 col-5">
                            <strong class="margin-10px-left">Force (N):</strong>
                          </div>
                          <div class="col-md-7 col-7">
                            <p>{{spaForce}}</p>
                          </div>
                        </div>
                      </li>
                      <li *ngIf="spaPower">
                        <div class="row">
                          <div class="col-md-5 col-5">
                            <strong class="margin-10px-left">Power (W):</strong>
                          </div>
                          <div class="col-md-7 col-7">
                            <p>{{spaPower}}</p>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> <!-- end of pdf-content -->
      </div> <!-- end of contact-info-section -->
    </div> <!-- end of team-single -->
  </div>