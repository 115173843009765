<div class="container" [ngClass]="{'secActive': formSubmitted}">
  <div class="d-sm-flex justify-content-between align-items-start levelone">
    <header>Level One</header>
    <button class="btn btn-primary" *ngIf="this.userid == undefined" routerLink="/levelonelist">
      View List
    </button>
  </div><br>
  <form (ngSubmit)="onSubmit()" #leveloneForm="ngForm">
    <img *ngIf="showImage && ImageUrl"  src="{{this.ImageUrl.changingThisBreaksApplicationSecurity}}">

    <div class="fields">
      <div class="input-field">
        <label>Register Id<span style="color: red;">*</span></label>
        <input type="number" placeholder="Enter Id " required [disabled]="this.userid" name="name"
          [(ngModel)]="levelone.loaAthlete" #loaAthlete="ngModel" (blur)="getProfileImage()" >
        <div *ngIf="checkAge" class="error-message">
          <p></p>
        </div>
      </div>
      <div *ngIf="loading" class="loading-spinner">
        <div class="spinner"></div>
      </div>
      <div class="input-field">
        <label>Assessment Date <span style="color: red;">*</span></label>
        <input type="date" placeholder="Enter Assessment Date" required name="loaAsessmentDate"
          [(ngModel)]="levelone.loaAsessmentDate" #loaAsessmentDate="ngModel">
        <div *ngIf="leveloneForm.submitted && loaAsessmentDate.invalid" class="error-message">
        </div>
      </div>
      <!-- <div class="input-field">
        <label>Age<span style="color: red;">*</span></label>
        <input type="number" placeholder="Enter age " required name="age" [(ngModel)]="levelone.aggAge"
          (blur)="validateAge()" #aggAge="ngModel">
        <div *ngIf="checkAge" class="error-message">
          <p> Age must be between 10 and 16</p>
        </div>
      </div> -->
      <div class="input-field">
        <label>Sports<span style="color: red;">*</span></label>
        <select [(ngModel)]="levelone.sportName" name="sportName" required #sportName="ngModel" (change)="validateUsrId($event)">
          <option value="" disabled selected>Select Sport</option>
          <option *ngFor="let sport of Sports" [value]="sport.sportName">{{ sport.sportName }}</option>
        </select>
        <div *ngIf="checkAge" class="error-message">
          <p> </p>
        </div>
      </div>
      <!-- <div class="input-field">
        <label>Gender<span style="color: red;">*</span></label>
        <select required required [(ngModel)]="levelone.genderName" class="form-control" name="genderName"
          #genderName="ngModel">
          <option value="" disabled selected>Select gender</option>
          <option value="Male">Male</option>
          <option value="Female">Female</option>
        </select>
        <div *ngIf="leveloneForm.submitted && genderName.invalid" class="error-message">

        </div>
      </div> -->
      <div class="input-field">
        <label>Height <span>(Cm)</span><span style="color: red;">*</span></label>
        <input type="number" placeholder="Enter Height " required name="loaHeight" [(ngModel)]="levelone.loaHeight"
          #loaHeight="ngModel">
        <div *ngIf="leveloneForm.submitted && loaHeight.invalid" class="error-message">

        </div>
      </div>
      <div class="input-field">
        <label>Weight <span>(Kg)</span><span style="color: red;">*</span></label>
        <input type="number" placeholder="Enter Weight" required name="loaWeight" [(ngModel)]="levelone.loaWeight"
          #loaWeight="ngModel">
        <div *ngIf="leveloneForm.submitted && loaWeight.invalid" class="error-message">

        </div>
      </div>
      <div class="input-field">
        <label>Ruler Drop <span>(Cm)</span><span style="color: red;">*</span></label>
        <input type="number" placeholder="Enter Ruler Drop" required name="loaRulerDrop"
          [(ngModel)]="levelone.loaRulerDrop" #loaRulerDrop="ngModel">
        <div *ngIf="leveloneForm.submitted && loaRulerDrop.invalid" class="error-message">

        </div>
      </div>
      <div class="input-field">
        <label>Hand Grip <span>(Kg)</span><span style="color: red;">*</span></label>
        <input type="number" placeholder="Enter Hand Grip" required name="loaHandGrip"
          [(ngModel)]="levelone.loaHandGrip" #loaHandGrip="ngModel">
        <div *ngIf="leveloneForm.submitted && loaHandGrip.invalid" class="error-message">

        </div>
      </div>
      <div class="input-field">
        <label>Sit & Reach <span>(Cm)</span><span style="color: red;">*</span></label>
        <input type="number" placeholder="Enter Sit & Reach" required name="loaSitnReach"
          [(ngModel)]="levelone.loaSitnReach" #loaSitnReach="ngModel">
        <div *ngIf="leveloneForm.submitted && loaSitnReach.invalid" class="error-message">

        </div>
      </div>
      <div class="input-field">
        <label>Med Ball Throw <span>(meters)</span><span style="color: red;">*</span></label>
        <input type="number" placeholder="Enter Med Ball Throw" required name="loaSeatedMedBall"
          [(ngModel)]="levelone.loaSeatedMedBall" #loaSeatedMedBall="ngModel">
        <div *ngIf="leveloneForm.submitted && loaSeatedMedBall.invalid" class="error-message">

        </div>
      </div>
      <div class="input-field">
        <label>Vertical Jump <span>(cm)</span><span style="color: red;">*</span></label>
        <input type="number" placeholder="Enter Vertical Jump" required name="loaVerticalJump"
          [(ngModel)]="levelone.loaVerticalJump" #loaVerticalJump="ngModel">
        <div *ngIf="leveloneForm.submitted && loaVerticalJump.invalid" class="error-message">

        </div>
      </div>
      <div class="input-field">
        <label>Wall Toss <span></span><span style="color: red;">*</span></label>
        <input type="number" placeholder="Enter Wall Toss" required name="loaWallToss"
          [(ngModel)]="levelone.loaWallToss" #loaWallToss="ngModel">
        <div *ngIf="leveloneForm.submitted && loaWallToss.invalid" class="error-message">

        </div>
      </div>
      <div class="input-field">
        <label>30m Sprint <span>(Sec)</span><span style="color: red;">*</span></label>
        <input type="number" placeholder="Enter 30m Sprint" required name="loa30mSprint"
          [(ngModel)]="levelone.loa30mSprint" #loa30mSprint="ngModel">
        <div *ngIf="leveloneForm.submitted && loa30mSprint.invalid" class="error-message">

        </div>
      </div>
      <div class="input-field">
        <label>6*10m Shuttle Run <span>(Sec)</span><span style="color: red;">*</span></label>
        <input type="number" placeholder="Enter 6*10m Shuttle Run" required name="loa6x10ShuttleRun"
          [(ngModel)]="levelone.loa6x10ShuttleRun" #loa6x10ShuttleRun="ngModel">
        <div *ngIf="leveloneForm.submitted && loa6x10ShuttleRun.invalid" class="error-message">

        </div>
      </div>
      <div class="input-field">
        <label>800m Run <span>(Min)</span><span style="color: red;">*</span></label>
        <input type="number" placeholder="Enter 800m Run" required name="loa800mRun" [(ngModel)]="levelone.loa800mRun"
          #loa800mRun="ngModel">
        <div *ngIf="leveloneForm.submitted && loa800mRun.invalid" class="error-message">

        </div>
      </div>

    </div>
    <!-- <div class="input-field">
      <app-multi-select-dropdown [label]="'Select Test'"
      [options]="options"></app-multi-select-dropdown>
    </div> -->
   
    <div class="buttons">
      <button type="submit" class="submit">
        <span class="btnText">Submit</span>
        <i class="uil uil-navigator"></i>
      </button>
    </div>


  </form>
</div>
<!-- <div class="chat-container">
  <div class="chat-history">
    <div *ngFor="let entry of chatHistory">
      {{ entry }}
    </div>
  </div>
  <input type="file" class="form-control w-25" (change)="onFileSelected($event)" />
  <textarea class="form-control" [(ngModel)]="message" rows="3" placeholder="Type your message..."></textarea>
  <button class="btn btn-primary" (click)="sendMessage()">Send</button>
</div> -->


<div class="toaster">
  <ng-toast />        
        </div>
