import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Input } from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';
import { SharedService } from '../services/shared.service';
import { AssessmentService } from '../services/assessment.service';
import { FormsModule } from '@angular/forms';
import { map, Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { NgSelectModule } from "@ng-select/ng-select";

@Component({
  selector: 'app-player-dashboard',
  standalone: true,
  imports: [CommonModule, RouterOutlet, FormsModule, RouterLink,NgSelectModule],
  templateUrl: './player-dashboard.component.html',
  styleUrls: ['./player-dashboard.component.css']
})
export class PlayerDashboardComponent {
  userMessage: string = "";
  isLoading = false;
  lastAssessment: any;
  detail: any;
  UserId: any;
  LevelOnedata: any[] = [];
  lastLevelOneAssessment: any
  Fitnessdata: any[] = [];
  lastFitnessAssessment: any;
  Nutritionaldata: any[] = [];
  lastNutritionAssessment: any;
  Cognitivedata: any[] = [];
  lastCognitiveAssessment: any;
  Cardiodata: any[] = [];
  lastCardiioAssessment: any;
  Injurydata: any[] = [];
  lastInjuryAssessment: any;
  Sportsdata: any[] = [];
  lastSportsAssessment: any;
  selectedOption: any = 'Level One'; // Default value
  options = [
    { label: 'Level One', value: 'Level One' },
    { label: 'Physical Fitness', value: 'Physical Fitness' },
    { label: 'Nutrition', value: 'Nutrition' },
    { label: 'Cognitive', value: 'Cognitive' },
    { label: 'Injury Prevention', value: 'Injury Prevention' },
    { label: 'Sports Performance', value: 'Sports Performance' },
  ];
  admitcardReport: any;
  public imageUrls: any[] = [];
  profileImageUrl = "assets/image/profileimg.jpg"

  constructor(
    private assessmentService: AssessmentService, private sharedService: SharedService, private sanitizer: DomSanitizer,
  ) {
  }

  ngOnInit(): void {

    this.sharedService.detail$.subscribe((data: any) => {
      this.detail = data;
      this.UserId = this.detail.user.usrId;
      setTimeout(() => {
        this.updateChart(this.selectedOption);
      }, 2000);
    });

  }

  async LeveloneGraph(): Promise<void> {
    const c3 = (await import('c3')).default;

    // Maximum possible values for each assessment category
    const maxWallToss = 100;
    const maxRulerDrop = 100;
    const maxHandGrip = 100;
    const maxSeatedMedBall = 100;
    const max30mSprint = 100;
    const max800mRun = 100;

    // Get the current and previous assessments
    const currentAssessmentIndex = this.LevelOnedata.length - 1;
    const previousAssessmentIndex = this.LevelOnedata.length - 2;

    const currentAssessment = this.LevelOnedata[currentAssessmentIndex];
    const previousAssessment = this.LevelOnedata.length > 1 ? this.LevelOnedata[previousAssessmentIndex] : null;

    const xAxisCategories = ['Wall Toss', 'Ruler Drop', 'Hand Grip', 'Seated Med Ball', '30m Sprint', '800m Run'];

    // Helper function to calculate percentage
    const calculatePercentage = (value: number, max: number): number => (value / max) * 100;

    // Calculate previous assessment data as percentages
    const previousAssessmentData = previousAssessment
      ? [
        calculatePercentage(previousAssessment.loaWallTossRange ?? 0, maxWallToss),
        calculatePercentage(previousAssessment.loaRulerDropRange ?? 0, maxRulerDrop),
        calculatePercentage(previousAssessment.loaHandGripRange ?? 0, maxHandGrip),
        calculatePercentage(previousAssessment.loaSeatedMedBallRange ?? 0, maxSeatedMedBall),
        calculatePercentage(previousAssessment.loa30mSprintRange ?? 0, max30mSprint),
        calculatePercentage(previousAssessment.loa800mRunRange ?? 0, max800mRun)
      ]
      : [0, 0, 0, 0, 0, 0];

    // Calculate current assessment data as percentages
    const currentAssessmentData = currentAssessment
      ? [
        calculatePercentage(currentAssessment.loaWallTossRange ?? 0, maxWallToss),
        calculatePercentage(currentAssessment.loaRulerDropRange ?? 0, maxRulerDrop),
        calculatePercentage(currentAssessment.loaHandGripRange ?? 0, maxHandGrip),
        calculatePercentage(currentAssessment.loaSeatedMedBallRange ?? 0, maxSeatedMedBall),
        calculatePercentage(currentAssessment.loa30mSprintRange ?? 0, max30mSprint),
        calculatePercentage(currentAssessment.loa800mRunRange ?? 0, max800mRun)
      ]
      : [0, 0, 0, 0, 0, 0];

    // Generate the C3 chart with percentage data
    const chart = c3.generate({
      bindto: '#levelone-graph',
      data: {
        columns: [
          ['previousAssessment', ...previousAssessmentData],
          ['currentAssessment', ...currentAssessmentData]
        ],
        types: {
          previousAssessment: 'area-spline',
          currentAssessment: 'area-spline'
        }
      },
      axis: {
        x: {
          type: 'category',
          categories: xAxisCategories,
          tick: {
            rotate: 90,
            multiline: false
          }
        },
        y: {
          label: {
            text: 'Percentage (%)',
            position: 'outer-middle'
          },
          min: 0,
          max: 100,  // Since percentages range from 0 to 100
          tick: {
            format: (d) => `${d}%`  // Add '%' symbol to y-axis ticks
          },
          padding: {
            bottom: 0,
          }
        }
      },
      tooltip: {
        contents: (data) => {
          // Custom tooltip generation
          let html = '<table class="c3-tooltip">';
          html += '<thead><tr><th>Assessment</th><th>Metric</th><th>Actual Value</th><th>Percentage</th></tr></thead>';
          html += '<tbody>';

          // Loop through the data and build the rows
          data.forEach(item => {
            const key = item.id; // 'previousAssessment' or 'currentAssessment'
            const percentage = item.value; // percentage value from the chart

            // Define assessment type
            const assessment = key === 'currentAssessment' ? 'Current' : 'Previous';

            // Define metric based on the index in the data
            const metric = xAxisCategories[item.index]; // 'Wall Toss', 'Ruler Drop', etc.

            // Get actual value based on the assessment and metric
            let actualValue = '';
            if (key === 'currentAssessment') {
              actualValue = [
                currentAssessment.loaWallToss,
                currentAssessment.loaRulerDrop,
                currentAssessment.loaHandGrip,
                currentAssessment.loaSeatedMedBall,
                currentAssessment.loa30mSprint,
                currentAssessment.loa800mRun
              ][item.index] ?? 0; // Ensure a valid index
            } else if (key === 'previousAssessment') {
              actualValue = [
                previousAssessment?.loaWallToss,
                previousAssessment?.loaRulerDrop,
                previousAssessment?.loaHandGrip,
                previousAssessment?.loaSeatedMedBall,
                previousAssessment?.loa30mSprint,
                previousAssessment?.loa800mRun
              ][item.index] ?? 0; // Ensure a valid index
            }

            html += '<tr>';
            html += `<td>${assessment}</td>`; // Assessment ('Current' or 'Previous')
            html += `<td>${metric}</td>`; // Metric (e.g., Wall Toss, Ruler Drop)
            html += `<td>${actualValue}</td>`; // Actual value
            html += `<td>${percentage.toFixed(2)}%</td>`; // Percentage with two decimal points
            html += '</tr>';
          });

          html += '</tbody></table>';
          return html;
        }
      },
      legend: {
        show: true
      }
    });
  }

  async LeveloneTable(): Promise<void> {
    const currentAssessment = this.LevelOnedata[this.LevelOnedata.length - 1];

    // Create an object to map metric ids to values
    const valuesMap = {
      loaHeight: currentAssessment.loaHeight || 0,
      loaHeightRange: currentAssessment.loaHeightRange || 0,
      loaWeight: currentAssessment.loaWeight || 0,
      loaWeightRange: currentAssessment.loaWeightRange || 0,
      loaRulerDrop: currentAssessment.loaRulerDrop || 0,
      loaRulerDropRange: currentAssessment.loaRulerDropRange || 0,
      loaHandGrip: currentAssessment.loaHandGrip || 0,
      loaHandGripRange: currentAssessment.loaHandGripRange || 0,
      loaSeatedMedBall: currentAssessment.loaSeatedMedBall || 0,
      loaSeatedMedBallRange: currentAssessment.loaSeatedMedBallRange || 0,
      loaSitnReach: currentAssessment.loaSitnReach || 0,
      loaSitnReachRange: currentAssessment.loaSitnReachRange || 0,
      loaVerticalJump: currentAssessment.loaVerticalJump || 0,
      loaVerticalJumpRange: currentAssessment.loaVerticalJumpRange || 0,
      loaWallToss: currentAssessment.loaWallToss || 0,
      loaWallTossRange: currentAssessment.loaWallTossRange || 0,
      loa30mSprint: currentAssessment.loa30mSprint || 0,
      loa30mSprintRange: currentAssessment.loa30mSprintRange || 0,
      loa6x10ShuttleRun: currentAssessment.loa6x10ShuttleRun || 0,
      loa6x10ShuttleRunRange: currentAssessment.loa6x10ShuttleRunRange || 0,
      loa800mRun: currentAssessment.loa800mRun || 0,
      loa800mRunRange: currentAssessment.loa800mRunRange || 0,
      loaAverageRange: currentAssessment.loaAverageRange || 0,
    };

    // Populate the values in the respective table cells
    Object.keys(valuesMap).forEach(id => {
      const cell = document.getElementById(id);
      if (cell) {
        cell.textContent = (valuesMap as any)[id].toString();
      } else {
        console.error(`Element with id ${id} not found!`);
      }
    });
  }

  async NutritionGraph(): Promise<void> {
    const c3 = (await import('c3')).default;

    // Reference nutrition values
    const referenceNutritionValues = {
      naBodyWeight: 70,
      naBodyHeight: 170,
      naBMI: 22,
      naBodyFat: 15,
      naFatFreeWeight: 50,
      naSubcutaneousFat: 10,
      naVisceralFat: 10,
      naBodyWater: 60,
      naSkeletalMuscle: 30,
      naLeanMass: 55,
      naBoneMass: 3,
      naProtein: 18,
      naBMR: 1500,
      naMetabolicAge: 25,
      naHealthScore: 100,
    };

    const assessmentKeys = Object.keys(referenceNutritionValues);
    const xAxisCategories = [
      'Body Weight', 'Body Height', 'BMI', 'Body Fat', 'Fat Free Weight',
      'Subcutaneous Fat', 'Visceral Fat', 'Body Water', 'Skeletal Muscle',
      'Lean Mass', 'Bone Mass', 'Protein', 'BMR', 'Metabolic Age', 'Health Score'
    ];

    const currentAssessment = this.Nutritionaldata[this.Nutritionaldata.length - 1];
    const previousAssessment = this.Nutritionaldata.length > 1
      ? this.Nutritionaldata[this.Nutritionaldata.length - 2]
      : null;

    // Function to calculate percentage based on test value and reference value
    const calculatePercentage = (testValue: number | null, referenceValue: number): number | null => {
      if (testValue !== null) {
        let percentage = (testValue / referenceValue) * 100;
        percentage = Math.min(Math.max(percentage, 0), 100); // Clamp between 0 and 100
        return Math.round(percentage);
      }
      return null;
    };

    let previousNutrionalAssessmentData: Array<{ zScore: number, percentage: number }> = [];
    let currentNutrionalAssessmentData: Array<{ zScore: number, percentage: number }> = [];

    if (this.Nutritionaldata.length <= 1) {
      // Only one assessment available, calculate percentages based on reference values
      currentNutrionalAssessmentData = assessmentKeys.map(key => ({
        zScore: 0, // No Z-score for single assessment
        percentage: calculatePercentage(currentAssessment[key], referenceNutritionValues[key as keyof typeof referenceNutritionValues]) ?? 0
      }));
      previousNutrionalAssessmentData = Array(assessmentKeys.length).fill({ zScore: 0, percentage: 0 }); // Default for previous
    } else {
      // More than one assessment, calculate Z-scores and percentages
      const calculateStats = (data: number[]): { mean: number, stdDev: number } => {
        if (data.length === 0) return { mean: 0, stdDev: 0 };
        const mean = data.reduce((sum, value) => sum + value, 0) / data.length;
        const stdDev = Math.sqrt(data.reduce((sum, value) => sum + Math.pow(value - mean, 2), 0) / data.length);
        return { mean, stdDev };
      };

      let stats: { [key: string]: { mean: number, stdDev: number } } = {};
      assessmentKeys.forEach(key => {
        stats[key] = calculateStats(this.Nutritionaldata.map(item => item[key] ?? 0));
      });

      const safeCalculateZScore = (value: number, mean: number, stdDev: number): number => {
        return stdDev === 0 ? 0 : Number(((value - mean) / stdDev).toFixed(2));
      };

      const zScoreToPercent = (zScore: number | null): number | null => {
        if (zScore === null) return null;
        const erf = (x: number): number => {
          const sign = x >= 0 ? 1 : -1;
          x = Math.abs(x);

          const a1 = 0.254829592;
          const a2 = -0.284496736;
          const a3 = 1.421413741;
          const a4 = -1.453152027;
          const a5 = 1.061405429;
          const p = 0.3275911;

          const t = 1.0 / (1.0 + p * x);
          const y = 1.0 - (((((a5 * t + a4) * t) + a3) * t + a2) * t + a1) * t * Math.exp(-x * x);

          return sign * y;
        };

        const percentile = 0.5 * (1 + erf(zScore / Math.sqrt(2)));
        return Math.round(percentile * 100);
      };

      previousNutrionalAssessmentData = assessmentKeys.map((key, index) => {
        const { mean, stdDev } = stats[key];
        const zScore = safeCalculateZScore(previousAssessment?.[key] ?? 0, mean, stdDev);
        const percentage = zScoreToPercent(zScore) ?? 0;
        return { zScore, percentage };
      });

      currentNutrionalAssessmentData = assessmentKeys.map((key, index) => {
        const { mean, stdDev } = stats[key];
        const zScore = safeCalculateZScore(currentAssessment[key] ?? 0, mean, stdDev);
        const percentage = zScoreToPercent(zScore) ?? 0;
        return { zScore, percentage };
      });
    }

    // console.log('Previous Assessment Data:');
    // previousNutrionalAssessmentData.forEach((data, index) => {
    //   console.log(`Metric: ${xAxisCategories[index]}, Z-Score: ${data.zScore}, Percentage: ${data.percentage}`);
    // });

    // console.log('Current Assessment Data:');
    // currentNutrionalAssessmentData.forEach((data, index) => {
    //   console.log(`Metric: ${xAxisCategories[index]}, Z-Score: ${data.zScore}, Percentage: ${data.percentage}`);
    // });

    // Generate the C3 chart with percentage data
    const chart = c3.generate({
      bindto: '#nutritional-graph',
      data: {
        columns: [
          ['previousAssessment', ...previousNutrionalAssessmentData.map(item => item.percentage)],
          ['currentAssessment', ...currentNutrionalAssessmentData.map(item => item.percentage)]
        ],
        types: {
          previousAssessment: 'area-spline',
          currentAssessment: 'area-spline'
        }
      },
      axis: {
        x: {
          type: 'category',
          categories: xAxisCategories,
          tick: {
            rotate: 90,
            multiline: false
          }
        },
        y: {
          label: {
            text: 'Percentage (%)',
            position: 'outer-middle'
          },
          min: 0,
          max: 100,
          tick: {
            format: (d) => `${d}%`
          },
          padding: {
            bottom: 0,
          }
        }
      },
      tooltip: {
        contents: (data) => {
          let html = '<table class="c3-tooltip">';
          html += '<thead><tr><th>Assessment</th><th>Metric</th><th>Actual Value</th><th>Z-Score</th><th>Percentage</th></tr></thead>';
          html += '<tbody>';

          const isSingleAssessment = this.Nutritionaldata.length === 1; // Check if only one assessment is available

          data.forEach(item => {
            const key = item.id; // 'previousAssessment' or 'currentAssessment'
            const percentage = item.value; // percentage value from the chart
            const isCurrent = key === 'currentAssessment';
            const assessment = isCurrent ? 'Current' : 'Previous';

            const metric = xAxisCategories[item.index]; // Metric name

            let actualValue = '';
            let zScoreValue = ''; // Initialize Z-score variable
            if (isSingleAssessment) {
              if (isCurrent) {
                actualValue = currentAssessment[assessmentKeys[item.index]] ?? 0;
                zScoreValue = currentNutrionalAssessmentData[item.index].zScore.toString() ?? 'N/A';
              } else {
                actualValue = '0';
                zScoreValue = '0';
              }
            } else {
              if (isCurrent) {
                actualValue = currentAssessment[assessmentKeys[item.index]] ?? 0;
                zScoreValue = currentNutrionalAssessmentData[item.index].zScore.toString() ?? 'N/A';
              } else {
                actualValue = previousAssessment?.[assessmentKeys[item.index]] ?? 0;
                zScoreValue = previousNutrionalAssessmentData[item.index]?.zScore.toString() ?? 'N/A';
              }
            }

            html += '<tr>';
            html += `<td>${assessment}</td>`; // Assessment type
            html += `<td>${metric}</td>`; // Metric name
            html += `<td>${actualValue}</td>`; // Actual value
            html += `<td>${zScoreValue}</td>`; // Z-score
            html += `<td>${percentage.toFixed(2)}%</td>`; // Percentage
            html += '</tr>';
          });

          html += '</tbody></table>';
          return html;
        }
      },
      legend: {
        show: true
      }
    });
  }

  async NutritionChart(): Promise<void> {
    const c3 = (await import('c3')).default;

    const currentAssessment = this.Nutritionaldata[this.Nutritionaldata.length - 1];
    const previousAssessment = this.Nutritionaldata.length > 1
      ? this.Nutritionaldata[this.Nutritionaldata.length - 2]
      : null;

    // Map the current assessment data to categories for the chart
    const transformedData = {
      'Body Weight': [currentAssessment.naBodyWeight],
      'Body Height': [currentAssessment.naBodyHeight],
      'BMI': [currentAssessment.naBMI],
      'Body Fat (%)': [currentAssessment.naBodyFat],
      'Fat Free Weight': [currentAssessment.naFatFreeWeight],
      'Subcutaneous Fat': [currentAssessment.naSubcutaneousFat],
      'Visceral Fat': [currentAssessment.naVisceralFat],
      'Body Water (%)': [currentAssessment.naBodyWater],
      'Skeletal Muscle (%)': [currentAssessment.naSkeletalMuscle],
      'Lean Mass': [currentAssessment.naLeanMass],
      'Bone Mass': [currentAssessment.naBoneMass],
      'Protein (%)': [currentAssessment.naProtein],
      'BMR': [currentAssessment.naBMR],
      'Metabolic Age': [currentAssessment.naMetabolicAge],
      'Health Score': [currentAssessment.naHealthScore]
    };

    const chartData: [string, ...number[]][] = Object.entries(transformedData).map(([key, values]) => {
      return [key, ...values] as [string, ...number[]];
    });

    const chart = c3.generate({
      bindto: '#nutritional-chart',
      data: {
        columns: chartData,
        type: 'pie'
      },
      axis: {
        x: {
          type: 'category',
          categories: Object.keys(transformedData),
          tick: {
            rotate: 75,
            multiline: false
          }
        }
      },
      bar: {
        width: {
          ratio: 0.5
        }
      },
      color: {
        pattern: ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b', '#e377c2', '#7f7f7f', '#bcbd22', '#17becf']
      },
      legend: {
        show: true
      }
    });
  }

  async NutritionTable(): Promise<void> {
    const currentAssessment = this.Nutritionaldata[this.Nutritionaldata.length - 1];

    // Define the metrics and their corresponding values
    const metrics = [
      'Body Weight', 'Body Height', 'BMI', 'Body Fat', 'Fat Free Weight',
      'Subcutaneous Fat', 'Visceral Fat', 'Body Water', 'Skeletal Muscle',
      'Lean Mass', 'Bone Mass', 'Protein', 'BMR', 'Metabolic Age', 'Health Score'
    ];

    // Create an array of values based on the current assessment
    const valuesMap = {
      naBodyWeight: currentAssessment.naBodyWeight || 0,
      naBodyHeight: currentAssessment.naBodyHeight || 0,
      naBMI: currentAssessment.naBMI || 0,
      naBodyFat: currentAssessment.naBodyFat || 0,
      naFatFreeWeight: currentAssessment.naFatFreeWeight || 0,
      naSubcutaneousFat: currentAssessment.naSubcutaneousFat || 0,
      naVisceralFat: currentAssessment.naVisceralFat || 0,
      naBodyWater: currentAssessment.naBodyWater || 0,
      naSkeletalMuscle: currentAssessment.naSkeletalMuscle || 0,
      naLeanMass: currentAssessment.naLeanMass || 0,
      naBoneMass: currentAssessment.naBoneMass || 0,
      naProtein: currentAssessment.naProtein || 0,
      naBMR: currentAssessment.naBMR || 0,
      naMetabolicAge: currentAssessment.naMetabolicAge || 0,
      naHealthScore: currentAssessment.naHealthScore || 0
    };

    // Populate the values in the respective table cells
    Object.keys(valuesMap).forEach(id => {
      const cell = document.getElementById(id);
      if (cell) {
        cell.textContent = (valuesMap as any)[id].toString();
      } else {
        console.error(`Element with id ${id} not found!`);
      }
    });
  }

  async FitnessGraph(): Promise<void> {
    const c3 = (await import('c3')).default;

    const referenceFitnessValues = {
      pfa50mSprint: 6.0,
      pfa30mSprint: 4.0,
      pfaLevel: 20,
      pfaVO2: 50,
      pfaSitNReach: 18,
      pfaTTest: 15.0,
    };

    // Fitness assessment categories
    const assessmentKeys = Object.keys(referenceFitnessValues);
    const xAxisCategories = ['50m Sprint', '30m Sprint', 'Level', 'VO2', 'Sit & Reach', 'T-Test'];

    const currentAssessment = this.Fitnessdata[this.Fitnessdata.length - 1];
    const previousAssessment = this.Fitnessdata.length > 1
      ? this.Fitnessdata[this.Fitnessdata.length - 2]
      : null;
    // Function to calculate percentage based on test value and reference value
    const calculatePercentage = (testValue: number | null, referenceValue: number): number | null => {
      if (testValue !== null) {
        let percentage = (testValue / referenceValue) * 100;
        percentage = Math.min(Math.max(percentage, 0), 100); // Clamp between 0 and 100
        return Math.round(percentage);
      }
      return null;
    };

    let previousFitnessAssessmentData: Array<{ zScore: number, percentage: number }> = [];
    let currentFitnessAssessmentData: Array<{ zScore: number, percentage: number }> = [];

    if (this.Fitnessdata.length <= 1) {
      // Only one assessment available, calculate percentages based on reference values
      currentFitnessAssessmentData = assessmentKeys.map(key => ({
        zScore: 0, // No Z-score for single assessment
        percentage: calculatePercentage(currentAssessment[key], referenceFitnessValues[key as keyof typeof referenceFitnessValues]) ?? 0
      }));
      previousFitnessAssessmentData = Array(assessmentKeys.length).fill({ zScore: 0, percentage: 0 }); // Default for previous
    } else {
      // More than one assessment, calculate Z-scores and percentages
      const calculateStats = (data: number[]): { mean: number, stdDev: number } => {
        if (data.length === 0) return { mean: 0, stdDev: 0 };
        const mean = data.reduce((sum, value) => sum + value, 0) / data.length;
        const stdDev = Math.sqrt(data.reduce((sum, value) => sum + Math.pow(value - mean, 2), 0) / data.length);
        return { mean, stdDev };
      };

      let stats: { [key: string]: { mean: number, stdDev: number } } = {};
      assessmentKeys.forEach(key => {
        stats[key] = calculateStats(this.Fitnessdata.map(item => item[key] ?? 0));
      });

      const safeCalculateZScore = (value: number, mean: number, stdDev: number): number => {
        return stdDev === 0 ? 0 : Number(((value - mean) / stdDev).toFixed(2));
      };

      const zScoreToPercent = (zScore: number | null): number | null => {
        if (zScore === null) return null;
        const erf = (x: number): number => {
          const sign = x >= 0 ? 1 : -1;
          x = Math.abs(x);

          const a1 = 0.254829592;
          const a2 = -0.284496736;
          const a3 = 1.421413741;
          const a4 = -1.453152027;
          const a5 = 1.061405429;
          const p = 0.3275911;

          const t = 1.0 / (1.0 + p * x);
          const y = 1.0 - (((((a5 * t + a4) * t) + a3) * t + a2) * t + a1) * t * Math.exp(-x * x);

          return sign * y;
        };

        const percentile = 0.5 * (1 + erf(zScore / Math.sqrt(2)));
        return Math.round(percentile * 100);
      };

      previousFitnessAssessmentData = assessmentKeys.map((key, index) => {
        const { mean, stdDev } = stats[key];
        const zScore = safeCalculateZScore(previousAssessment?.[key] ?? 0, mean, stdDev);
        const percentage = zScoreToPercent(zScore) ?? 0;
        return { zScore, percentage };
      });

      currentFitnessAssessmentData = assessmentKeys.map((key, index) => {
        const { mean, stdDev } = stats[key];
        const zScore = safeCalculateZScore(currentAssessment[key] ?? 0, mean, stdDev);
        const percentage = zScoreToPercent(zScore) ?? 0;
        return { zScore, percentage };
      });
    }
    // Generate the C3 chart with percentage data
    const chart = c3.generate({
      bindto: '#fitness-graph',
      data: {
        columns: [
          ['previousAssessment', ...previousFitnessAssessmentData.map(item => item.percentage)],
          ['currentAssessment', ...currentFitnessAssessmentData.map(item => item.percentage)]
        ],
        types: {
          previousAssessment: 'area-spline',
          currentAssessment: 'area-spline'
        }
      },
      axis: {
        x: {
          type: 'category',
          categories: xAxisCategories,
          tick: {
            rotate: 90,
            multiline: false
          }
        },
        y: {
          label: {
            text: 'Percentage (%)',
            position: 'outer-middle'
          },
          min: 0,
          max: 100,
          tick: {
            format: (d) => `${d}%`
          },
          padding: {
            bottom: 0,
          }
        }
      },
      tooltip: {
        contents: (data) => {
          let html = '<table class="c3-tooltip">';
          html += '<thead><tr><th>Assessment</th><th>Metric</th><th>Actual Value</th><th>Z-Score</th><th>Percentage</th></tr></thead>';
          html += '<tbody>';

          const isSingleAssessment = this.Fitnessdata.length === 1; // Check if only one assessment is available

          data.forEach(item => {
            const key = item.id; // 'previousAssessment' or 'currentAssessment'
            const percentage = item.value; // percentage value from the chart
            const isCurrent = key === 'currentAssessment';
            const assessment = isCurrent ? 'Current' : 'Previous';

            const metric = xAxisCategories[item.index]; // Metric name

            let actualValue = '';
            let zScoreValue = ''; // Initialize Z-score variable
            if (isSingleAssessment) {
              if (isCurrent) {
                actualValue = currentAssessment[assessmentKeys[item.index]] ?? 0;
                zScoreValue = currentFitnessAssessmentData[item.index].zScore.toString() ?? 'N/A';
              } else {
                actualValue = '0';
                zScoreValue = '0';
              }
            } else {
              if (isCurrent) {
                actualValue = currentAssessment[assessmentKeys[item.index]] ?? 0;
                zScoreValue = currentFitnessAssessmentData[item.index].zScore.toString() ?? 'N/A';
              } else {
                actualValue = previousAssessment?.[assessmentKeys[item.index]] ?? 0;
                zScoreValue = previousFitnessAssessmentData[item.index]?.zScore.toString() ?? 'N/A';
              }
            }

            html += '<tr>';
            html += `<td>${assessment}</td>`; // Assessment type
            html += `<td>${metric}</td>`; // Metric name
            html += `<td>${actualValue}</td>`; // Actual value
            html += `<td>${zScoreValue}</td>`; // Z-score
            html += `<td>${percentage.toFixed(2)}%</td>`; // Percentage
            html += '</tr>';
          });

          html += '</tbody></table>';
          return html;
        }
      },
      legend: {
        show: true
      }
    });
  }

  async FitnessTable(): Promise<void> {
    const currentAssessment = this.Fitnessdata[this.Fitnessdata.length - 1];

    // Create an array of values based on the current assessment
    const valuesMap = {
      pfaHeight: currentAssessment.pfaHeight || 0,
      pfaWeight: currentAssessment.pfaWeight || 0,
      pfaBMI: currentAssessment.pfaBMI || 0,
      pfaSpeed: currentAssessment.pfaSpeed || 0,
      pfaYoYoTest: currentAssessment.pfaYoYoTest || 0,
      pfaSingleLegBalanceLeftEyesOpen: currentAssessment.pfaSingleLegBalanceLeftEyesOpen || 0,
      pfaSingleLegBalanceLeftEyesClose: currentAssessment.pfaSingleLegBalanceLeftEyesClose || 0,
      pfaSingleLegBalanceRightEyesOpen: currentAssessment.pfaSingleLegBalanceRightEyesOpen || 0,
      pfaSingleLegBalanceRightEyesClose: currentAssessment.pfaSingleLegBalanceRightEyesClose || 0,
      pfaSquat: currentAssessment.pfaSquat || 0,
      pfaPartialCurlUp: currentAssessment.pfaPartialCurlUp || 0,
      pfaGripStrengthRight: currentAssessment.pfaGripStrengthRight || 0,
      pfaGripStrengthLeft: currentAssessment.pfaGripStrengthLeft || 0,
      pfaSitNReach: currentAssessment.pfaSitNReach || 0,
      pfaStandingBroadJump: currentAssessment.pfaStandingBroadJump || 0,
      pfaVerticalJump: currentAssessment.pfaVerticalJump || 0,
      pfaSeatedMedBallThrow: currentAssessment.pfaSeatedMedBallThrow || 0,
      pfaUpperBodyPower: currentAssessment.pfaUpperBodyPower || 0,
      pfaUpperBodyForce: currentAssessment.pfaUpperBodyForce || 0,
      pfaLowerBodyPower: currentAssessment.pfaLowerBodyPower || 0,
      pfaLowerBodyForce: currentAssessment.pfaLowerBodyForce || 0
    };
    // Populate the values in the respective table cells
    Object.keys(valuesMap).forEach(id => {
      const cell = document.getElementById(id);
      if (cell) {
        cell.textContent = (valuesMap as any)[id].toString();
      } else {
        console.error(`Element with id ${id} not found!`);
      }
    });
  }

  async CognitiveGraph(): Promise<void> {
    const c3 = (await import('c3')).default;

    const referenceCognitiveValues = {
      cnaRulerDrop: 100,
      cnaBlazepods: 100,
      cnaBlazepodsTime: 30,
      cnaWallToss: 100,
      cnaPlateTaping: 100
    };

    const assessmentKeys = Object.keys(referenceCognitiveValues);
    const xAxisCategories = ['Ruler Drop', 'Blazepods', 'Blazepods Time', 'Wall Toss', 'Plate Taping'];

    const currentAssessment = this.Cognitivedata[this.Cognitivedata.length - 1];
    const previousAssessment = this.Cognitivedata.length > 1
      ? this.Cognitivedata[this.Cognitivedata.length - 2]
      : null;

    // Function to calculate percentage based on test value and reference value
    const calculatePercentage = (testValue: number | null, referenceValue: number): number | null => {
      if (testValue !== null) {
        let percentage = (testValue / referenceValue) * 100;
        percentage = Math.min(Math.max(percentage, 0), 100); // Clamp between 0 and 100
        return Math.round(percentage);
      }
      return null;
    };

    let previousCognitiveAssessmentData: Array<{ zScore: number, percentage: number }> = [];
    let currentCognitiveAssessmentData: Array<{ zScore: number, percentage: number }> = [];

    if (this.Cognitivedata.length <= 1) {
      // Only one assessment available, calculate percentages based on reference values
      currentCognitiveAssessmentData = assessmentKeys.map(key => ({
        zScore: 0, // No Z-score for single assessment
        percentage: calculatePercentage(currentAssessment[key], referenceCognitiveValues[key as keyof typeof referenceCognitiveValues]) ?? 0
      }));
      previousCognitiveAssessmentData = Array(assessmentKeys.length).fill({ zScore: 0, percentage: 0 }); // Default for previous
    } else {
      // More than one assessment, calculate Z-scores and percentages
      const calculateStats = (data: number[]): { mean: number, stdDev: number } => {
        if (data.length === 0) return { mean: 0, stdDev: 0 };
        const mean = data.reduce((sum, value) => sum + value, 0) / data.length;
        const stdDev = Math.sqrt(data.reduce((sum, value) => sum + Math.pow(value - mean, 2), 0) / data.length);
        return { mean, stdDev };
      };

      let stats: { [key: string]: { mean: number, stdDev: number } } = {};
      assessmentKeys.forEach(key => {
        stats[key] = calculateStats(this.Cognitivedata.map(item => item[key] ?? 0));
      });

      const safeCalculateZScore = (value: number, mean: number, stdDev: number): number => {
        return stdDev === 0 ? 0 : Number(((value - mean) / stdDev).toFixed(2));
      };

      const zScoreToPercent = (zScore: number | null): number | null => {
        if (zScore === null) return null;
        const erf = (x: number): number => {
          const sign = x >= 0 ? 1 : -1;
          x = Math.abs(x);

          const a1 = 0.254829592;
          const a2 = -0.284496736;
          const a3 = 1.421413741;
          const a4 = -1.453152027;
          const a5 = 1.061405429;
          const p = 0.3275911;

          const t = 1.0 / (1.0 + p * x);
          const y = 1.0 - (((((a5 * t + a4) * t) + a3) * t + a2) * t + a1) * t * Math.exp(-x * x);

          return sign * y;
        };

        const percentile = 0.5 * (1 + erf(zScore / Math.sqrt(2)));
        return Math.round(percentile * 100);
      };

      previousCognitiveAssessmentData = assessmentKeys.map((key, index) => {
        const { mean, stdDev } = stats[key];
        const zScore = safeCalculateZScore(previousAssessment?.[key] ?? 0, mean, stdDev);
        const percentage = zScoreToPercent(zScore) ?? 0;
        return { zScore, percentage };
      });

      currentCognitiveAssessmentData = assessmentKeys.map((key, index) => {
        const { mean, stdDev } = stats[key];
        const zScore = safeCalculateZScore(currentAssessment[key] ?? 0, mean, stdDev);
        const percentage = zScoreToPercent(zScore) ?? 0;
        return { zScore, percentage };
      });
    }

    // Generate the C3 chart with percentage data
    const chart = c3.generate({
      bindto: '#cognitive-graph',
      data: {
        columns: [
          ['previousAssessment', ...previousCognitiveAssessmentData.map(item => item.percentage)],
          ['currentAssessment', ...currentCognitiveAssessmentData.map(item => item.percentage)]
        ],
        types: {
          previousAssessment: 'area-spline',
          currentAssessment: 'area-spline'
        }
      },
      axis: {
        x: {
          type: 'category',
          categories: xAxisCategories,
          tick: {
            rotate: 90,
            multiline: false
          }
        },
        y: {
          label: {
            text: 'Percentage (%)',
            position: 'outer-middle'
          },
          min: 0,
          max: 100,
          tick: {
            format: (d) => `${d}%`
          },
          padding: {
            bottom: 0,
          }
        }
      },
      tooltip: {
        contents: (data) => {
          let html = '<table class="c3-tooltip">';
          html += '<thead><tr><th>Assessment</th><th>Metric</th><th>Actual Value</th><th>Z-Score</th><th>Percentage</th></tr></thead>';
          html += '<tbody>';

          const isSingleAssessment = this.Nutritionaldata.length === 1; // Check if only one assessment is available

          data.forEach(item => {
            const key = item.id; // 'previousAssessment' or 'currentAssessment'
            const percentage = item.value; // percentage value from the chart
            const isCurrent = key === 'currentAssessment';
            const assessment = isCurrent ? 'Current' : 'Previous';

            const metric = xAxisCategories[item.index]; // Metric name

            let actualValue = '';
            let zScoreValue = ''; // Initialize Z-score variable
            if (isSingleAssessment) {
              if (isCurrent) {
                actualValue = currentAssessment[assessmentKeys[item.index]] ?? 0;
                zScoreValue = currentCognitiveAssessmentData[item.index].zScore.toString() ?? 'N/A';
              } else {
                actualValue = '0';
                zScoreValue = '0';
              }
            } else {
              if (isCurrent) {
                actualValue = currentAssessment[assessmentKeys[item.index]] ?? 0;
                zScoreValue = currentCognitiveAssessmentData[item.index].zScore.toString() ?? 'N/A';
              } else {
                actualValue = previousAssessment?.[assessmentKeys[item.index]] ?? 0;
                zScoreValue = previousCognitiveAssessmentData[item.index]?.zScore.toString() ?? 'N/A';
              }
            }

            html += '<tr>';
            html += `<td>${assessment}</td>`; // Assessment type
            html += `<td>${metric}</td>`; // Metric name
            html += `<td>${actualValue}</td>`; // Actual value
            html += `<td>${zScoreValue}</td>`; // Z-score
            html += `<td>${percentage.toFixed(2)}%</td>`; // Percentage
            html += '</tr>';
          });

          html += '</tbody></table>';
          return html;
        }
      },
      legend: {
        show: true
      }
    });
  }

  async CognitiveTable(): Promise<void> {
    const currentAssessment = this.Cognitivedata[this.Cognitivedata.length - 1];

    // Define the metrics and their corresponding values
    const metrics = [
      'Ruler Drop', 'Blaze pods', 'Blaze pods Time', 'Wall Toss', 'Plate Taping',
    ];

    // Create an array of values based on the current assessment
    const valuesMap = {
      cnaRulerDrop: currentAssessment.cnaRulerDrop || 0,
      cnaBlazepods: currentAssessment.cnaBlazepods || 0,
      cnaBlazepodsTime: currentAssessment.cnaBlazepodsTime || 0,
      cnaWallToss: currentAssessment.cnaWallToss || 0,
      cnaPlateTaping: currentAssessment.cnaPlateTaping || 0,
    };

    // Populate the values in the respective table cells
    Object.keys(valuesMap).forEach(id => {
      const cell = document.getElementById(id);
      if (cell) {
        cell.textContent = (valuesMap as any)[id].toString();
      } else {
        console.error(`Element with id ${id} not found!`);
      }
    });
  }

  async InjuryGraph(): Promise<void> {
    const c3 = (await import('c3')).default;

    const referenceInjuryValues = {
      ipaDeepSquat: 3,
      ipaInlineLunge: 3,
      ipaHurdleStep: 3,
      ipaActiveSLR: 3,
      ipaShoulderMobility: 3,
      ipaPushUp: 3,
      ipaRotaryStability: 3
    };

    const assessmentKeys = Object.keys(referenceInjuryValues);
    const xAxisCategories = [
      'Deep Squat', 'Inline Lunge', 'Hurdle Step', 'Active SLR',
      'Shoulder Mobility', 'Push Up', 'Rotary Stability'
    ];

    const currentAssessment = this.Injurydata[this.Injurydata.length - 1];
    const previousAssessment = this.Injurydata.length > 1
      ? this.Injurydata[this.Injurydata.length - 2]
      : null;

    // Function to calculate percentage based on test value and reference value
    const calculatePercentage = (testValue: number | null, referenceValue: number): number | null => {
      if (testValue !== null) {
        let percentage = (testValue / referenceValue) * 100;
        percentage = Math.min(Math.max(percentage, 0), 100); // Clamp between 0 and 100
        return Math.round(percentage);
      }
      return null;
    };

    let previousInjuryAssessmentData: Array<{ zScore: number, percentage: number }> = [];
    let currentInjuryAssessmentData: Array<{ zScore: number, percentage: number }> = [];

    if (this.Injurydata.length <= 1) {
      // Only one assessment available, calculate percentages based on reference values
      currentInjuryAssessmentData = assessmentKeys.map(key => ({
        zScore: 0, // No Z-score for single assessment
        percentage: calculatePercentage(currentAssessment[key], referenceInjuryValues[key as keyof typeof referenceInjuryValues]) ?? 0
      }));
      previousInjuryAssessmentData = Array(assessmentKeys.length).fill({ zScore: 0, percentage: 0 }); // Default for previous
    } else {
      // More than one assessment, calculate Z-scores and percentages
      const calculateStats = (data: number[]): { mean: number, stdDev: number } => {
        if (data.length === 0) return { mean: 0, stdDev: 0 };
        const mean = data.reduce((sum, value) => sum + value, 0) / data.length;
        const stdDev = Math.sqrt(data.reduce((sum, value) => sum + Math.pow(value - mean, 2), 0) / data.length);
        return { mean, stdDev };
      };

      let stats: { [key: string]: { mean: number, stdDev: number } } = {};
      assessmentKeys.forEach(key => {
        stats[key] = calculateStats(this.Injurydata.map(item => item[key] ?? 0));
      });

      const safeCalculateZScore = (value: number, mean: number, stdDev: number): number => {
        return stdDev === 0 ? 0 : Number(((value - mean) / stdDev).toFixed(2));
      };

      const zScoreToPercent = (zScore: number | null): number | null => {
        if (zScore === null) return null;
        const erf = (x: number): number => {
          const sign = x >= 0 ? 1 : -1;
          x = Math.abs(x);

          const a1 = 0.254829592;
          const a2 = -0.284496736;
          const a3 = 1.421413741;
          const a4 = -1.453152027;
          const a5 = 1.061405429;
          const p = 0.3275911;

          const t = 1.0 / (1.0 + p * x);
          const y = 1.0 - (((((a5 * t + a4) * t) + a3) * t + a2) * t + a1) * t * Math.exp(-x * x);

          return sign * y;
        };

        const percentile = 0.5 * (1 + erf(zScore / Math.sqrt(2)));
        return Math.round(percentile * 100);
      };

      previousInjuryAssessmentData = assessmentKeys.map((key, index) => {
        const { mean, stdDev } = stats[key];
        const zScore = safeCalculateZScore(previousAssessment?.[key] ?? 0, mean, stdDev);
        const percentage = zScoreToPercent(zScore) ?? 0;
        return { zScore, percentage };
      });

      currentInjuryAssessmentData = assessmentKeys.map((key, index) => {
        const { mean, stdDev } = stats[key];
        const zScore = safeCalculateZScore(currentAssessment[key] ?? 0, mean, stdDev);
        const percentage = zScoreToPercent(zScore) ?? 0;
        return { zScore, percentage };
      });
    }

    // Generate the C3 chart with percentage data
    const chart = c3.generate({
      bindto: '#injury-graph',
      data: {
        columns: [
          ['previousAssessment', ...previousInjuryAssessmentData.map(item => item.percentage)],
          ['currentAssessment', ...currentInjuryAssessmentData.map(item => item.percentage)]
        ],
        types: {
          previousAssessment: 'area-spline',
          currentAssessment: 'area-spline'
        }
      },
      axis: {
        x: {
          type: 'category',
          categories: xAxisCategories,
          tick: {
            rotate: 90,
            multiline: false
          }
        },
        y: {
          label: {
            text: 'Percentage (%)',
            position: 'outer-middle'
          },
          min: 0,
          max: 100,
          tick: {
            format: (d) => `${d}%`
          },
          padding: {
            bottom: 0,
          }
        }
      },
      tooltip: {
        contents: (data) => {
          let html = '<table class="c3-tooltip">';
          html += '<thead><tr><th>Assessment</th><th>Metric</th><th>Actual Value</th><th>Z-Score</th><th>Percentage</th></tr></thead>';
          html += '<tbody>';

          const isSingleAssessment = this.Injurydata.length === 1; // Check if only one assessment is available

          data.forEach(item => {
            const key = item.id; // 'previousAssessment' or 'currentAssessment'
            const percentage = item.value; // percentage value from the chart
            const isCurrent = key === 'currentAssessment';
            const assessment = isCurrent ? 'Current' : 'Previous';

            const metric = xAxisCategories[item.index]; // Metric name

            let actualValue = '';
            let zScoreValue = ''; // Initialize Z-score variable
            if (isSingleAssessment) {
              if (isCurrent) {
                actualValue = currentAssessment[assessmentKeys[item.index]] ?? 0;
                zScoreValue = currentInjuryAssessmentData[item.index].zScore.toString() ?? 'N/A';
              } else {
                actualValue = '0';
                zScoreValue = '0';
              }
            } else {
              if (isCurrent) {
                actualValue = currentAssessment[assessmentKeys[item.index]] ?? 0;
                zScoreValue = currentInjuryAssessmentData[item.index].zScore.toString() ?? 'N/A';
              } else {
                actualValue = previousAssessment?.[assessmentKeys[item.index]] ?? 0;
                zScoreValue = previousInjuryAssessmentData[item.index]?.zScore.toString() ?? 'N/A';
              }
            }

            html += '<tr>';
            html += `<td>${assessment}</td>`; // Assessment type
            html += `<td>${metric}</td>`; // Metric name
            html += `<td>${actualValue}</td>`; // Actual value
            html += `<td>${zScoreValue}</td>`; // Z-score
            html += `<td>${percentage}%</td>`; // Percentage
            html += '</tr>';
          });

          html += '</tbody></table>';
          return html;
        }
      },
      legend: {
        show: true
      }
    });
  }

  async InjuryTable(): Promise<void> {
    const currentAssessment = this.Injurydata[this.Injurydata.length - 1];

    // Define the metrics and their corresponding values
    const metrics = [
      'Deep Squat', 'Inline Lunge', 'Hurdle Step', 'Active SLR', 'Shoulder Mobility',
      'PushUp', 'Rotary Stability', 'TotalScore'
    ];

    const valuesMap = {
      ipaDeepSquat: currentAssessment.ipaDeepSquat || 0,
      ipaInlineLunge: currentAssessment.ipaInlineLunge || 0,
      ipaHurdleStep: currentAssessment.ipaHurdleStep || 0,
      ipaActiveSLR: currentAssessment.ipaActiveSLR || 0,
      ipaShoulderMobility: currentAssessment.ipaShoulderMobility || 0,
      ipaPushUp: currentAssessment.ipaPushUp || 0,
      ipaRotaryStability: currentAssessment.ipaRotaryStability || 0,
      ipaTotalScore: currentAssessment.ipaTotalScore || 0,
    };

    // Populate the values in the respective table cells
    Object.keys(valuesMap).forEach(id => {
      const cell = document.getElementById(id);
      if (cell) {
        cell.textContent = (valuesMap as any)[id].toString();
      } else {
        console.error(`Element with id ${id} not found!`);
      }
    });
  }

  async SportsGraph(): Promise<void> {
    const c3 = (await import('c3')).default;

    const referenceSportsValues = {
      spaAverageTorqueCR: 100,
      spaAverageTorqueCL: 100,
      spaPeakTorqueCR: 200,
      spaPeakTorqueCL: 200,
      spaJumpHeight: 30,
      spaForce: 10.0,
    };

    const assessmentKeys = Object.keys(referenceSportsValues);
    const xAxisCategories = [
      'Average Torque CR', 'Average Torque CL', 'Peak Torque CR',
      'Peak Torque CL', 'Jump Height', 'Force'
    ];

    const currentAssessment = this.Sportsdata[this.Sportsdata.length - 1];
    const previousAssessment = this.Sportsdata.length > 1
      ? this.Sportsdata[this.Sportsdata.length - 2]
      : null;

    // Function to calculate percentage based on test value and reference value
    const calculatePercentage = (testValue: number | null, referenceValue: number): number | null => {
      if (testValue !== null) {
        let percentage = (testValue / referenceValue) * 100;
        percentage = Math.min(Math.max(percentage, 0), 100); // Clamp between 0 and 100
        return Math.round(percentage);
      }
      return null;
    };

    let previousSportsAssessmentData: Array<{ zScore: number, percentage: number }> = [];
    let currentSportsAssessmentData: Array<{ zScore: number, percentage: number }> = [];

    if (this.Sportsdata.length <= 1) {
      currentSportsAssessmentData = assessmentKeys.map(key => {
        let percentage = 0;

        // Check if the key requires special handling for metrics with a slash value
        if (['spaAverageTorqueCR', 'spaAverageTorqueCL', 'spaPeakTorqueCR', 'spaPeakTorqueCL'].includes(key)) {
          const value = currentAssessment[key];
          const [num1, num2] = value.split('/').map(Number); // Split by slash and convert to numbers
          if (isNaN(num1) || isNaN(num2)) {
            percentage = 0; // If parsing fails, set percentage to null
          } else {
            const averageValue = (num1 + num2) / 2; // Calculate the average
            percentage = calculatePercentage(averageValue, referenceSportsValues[key as keyof typeof referenceSportsValues]) ?? 0;
          }
        } else {
          // Standard percentage calculation
          percentage = calculatePercentage(Number(currentAssessment[key]), referenceSportsValues[key as keyof typeof referenceSportsValues]) ?? 0;
        }

        return {
          zScore: 0, // No Z-score for single assessment
          percentage: percentage
        };
      });
      previousSportsAssessmentData = Array(assessmentKeys.length).fill({ zScore: 0, percentage: 0 }); // Default for previous
    } else {
      // More than one assessment, calculate Z-scores and percentages
      const calculateStats = (data: any[]): { mean: number, stdDev: number } => {
        if (data.length === 0) return { mean: NaN, stdDev: NaN };

        let values: number[] = [];

        data.forEach(item => {
          if (typeof item === 'string' && item.includes('/')) {
            // Handle metrics with slash-separated values
            const [num1, num2] = item.split('/').map(Number); // Split by slash and convert to numbers
            if (!isNaN(num1) && !isNaN(num2)) {
              const averageValue = (num1 + num2) / 2; // Calculate the average
              values.push(averageValue);
            }
          } else if (typeof item === 'number' && !isNaN(item)) {
            // Handle standard numeric values
            values.push(item);
          }
        });

        if (values.length === 0) return { mean: NaN, stdDev: NaN };

        const mean = values.reduce((sum, value) => sum + value, 0) / values.length;
        const stdDev = Math.sqrt(values.reduce((sum, value) => sum + Math.pow(value - mean, 2), 0) / values.length);

        return { mean, stdDev };
      };

      let stats: { [key: string]: { mean: number, stdDev: number } } = {};
      assessmentKeys.forEach(key => {
        stats[key] = calculateStats(this.Sportsdata.map(item => item[key] ?? 0));
      });

      const safeCalculateZScore = (value: number, mean: number, stdDev: number): number => {
        return stdDev === 0 ? 0 : Number(((value - mean) / stdDev).toFixed(2));
      };

      const zScoreToPercent = (zScore: number | null): number | null => {
        if (zScore === null) return null;
        const erf = (x: number): number => {
          const sign = x >= 0 ? 1 : -1;
          x = Math.abs(x);

          const a1 = 0.254829592;
          const a2 = -0.284496736;
          const a3 = 1.421413741;
          const a4 = -1.453152027;
          const a5 = 1.061405429;
          const p = 0.3275911;

          const t = 1.0 / (1.0 + p * x);
          const y = 1.0 - (((((a5 * t + a4) * t) + a3) * t + a2) * t + a1) * t * Math.exp(-x * x);

          return sign * y;
        };

        const percentile = 0.5 * (1 + erf(zScore / Math.sqrt(2)));
        return Math.round(percentile * 100);
      };

      previousSportsAssessmentData = assessmentKeys.map((key, index) => {
        const { mean, stdDev } = stats[key];
        let zScore = 0;
        let percentage = 0;

        // Check if the key requires special handling for metrics with a slash value
        if (['spaAverageTorqueCR', 'spaAverageTorqueCL', 'spaPeakTorqueCR', 'spaPeakTorqueCL'].includes(key)) {
          const value = previousAssessment?.[key];
          if (value) {
            const [num1, num2] = value.split('/').map(Number); // Split by slash and convert to numbers
            if (!isNaN(num1) && !isNaN(num2)) {
              const averageValue = (num1 + num2) / 2; // Calculate the average
              zScore = safeCalculateZScore(averageValue, mean, stdDev);
              percentage = zScoreToPercent(zScore) ?? 0;
            }
          }
        } else {
          // Standard calculation
          zScore = safeCalculateZScore(previousAssessment?.[key] ?? 0, mean, stdDev);
          percentage = zScoreToPercent(zScore) ?? 0;
        }

        return { zScore, percentage };
      });

      currentSportsAssessmentData = assessmentKeys.map((key, index) => {
        const { mean, stdDev } = stats[key];
        let zScore = 0;
        let percentage = 0;

        // Check if the key requires special handling for metrics with a slash value
        if (['spaAverageTorqueCR', 'spaAverageTorqueCL', 'spaPeakTorqueCR', 'spaPeakTorqueCL'].includes(key)) {
          const value = currentAssessment[key];
          if (value) {
            const [num1, num2] = value.split('/').map(Number); // Split by slash and convert to numbers
            if (!isNaN(num1) && !isNaN(num2)) {
              const averageValue = (num1 + num2) / 2; // Calculate the average
              zScore = safeCalculateZScore(averageValue, mean, stdDev);
              percentage = zScoreToPercent(zScore) ?? 0;
            }
          }
        } else {
          // Standard calculation
          zScore = safeCalculateZScore(currentAssessment[key] ?? 0, mean, stdDev);
          percentage = zScoreToPercent(zScore) ?? 0;
        }

        return { zScore, percentage };
      });
    }

    // Generate the C3 chart with percentage data
    const chart = c3.generate({
      bindto: '#sports-graph',
      data: {
        columns: [
          ['previousAssessment', ...previousSportsAssessmentData.map(item => item.percentage)],
          ['currentAssessment', ...currentSportsAssessmentData.map(item => item.percentage)]
        ],
        types: {
          previousAssessment: 'area-spline',
          currentAssessment: 'area-spline'
        }
      },
      axis: {
        x: {
          type: 'category',
          categories: xAxisCategories,
          tick: {
            rotate: 90,
            multiline: false
          }
        },
        y: {
          label: {
            text: 'Percentage (%)',
            position: 'outer-middle'
          },
          min: 0,
          max: 100,
          tick: {
            format: (d) => `${d}%`
          },
          padding: {
            bottom: 0,
          }
        }
      },
      tooltip: {
        contents: (data) => {
          let html = '<table class="c3-tooltip">';
          html += '<thead><tr><th>Assessment</th><th>Metric</th><th>Actual Value</th><th>Z-Score</th><th>Percentage</th></tr></thead>';
          html += '<tbody>';

          const isSingleAssessment = this.Sportsdata.length === 1; // Check if only one assessment is available

          data.forEach(item => {
            const key = item.id; // 'previousAssessment' or 'currentAssessment'
            const percentage = item.value; // percentage value from the chart
            const isCurrent = key === 'currentAssessment';
            const assessment = isCurrent ? 'Current' : 'Previous';

            const metric = xAxisCategories[item.index]; // Metric name

            let actualValue = '';
            let zScoreValue = ''; // Initialize Z-score variable
            if (isSingleAssessment) {
              if (isCurrent) {
                actualValue = currentAssessment[assessmentKeys[item.index]] ?? 0;
                zScoreValue = currentSportsAssessmentData[item.index].zScore.toString() ?? 'N/A';
              } else {
                actualValue = '0';
                zScoreValue = '0';
              }
            } else {
              if (isCurrent) {
                actualValue = currentAssessment[assessmentKeys[item.index]] ?? 0;
                zScoreValue = currentSportsAssessmentData[item.index].zScore.toString() ?? 'N/A';
              } else {
                actualValue = previousAssessment?.[assessmentKeys[item.index]] ?? 0;
                zScoreValue = previousSportsAssessmentData[item.index]?.zScore.toString() ?? 'N/A';
              }
            }

            html += '<tr>';
            html += `<td>${assessment}</td>`; // Assessment type
            html += `<td>${metric}</td>`; // Metric name
            html += `<td>${actualValue}</td>`; // Actual value
            html += `<td>${zScoreValue}</td>`; // Z-score
            html += `<td>${percentage}%</td>`; // Percentage
            html += '</tr>';
          });

          html += '</tbody></table>';
          return html;
        }
      },
      legend: {
        show: true
      }
    });
  }

  async SportsTable(): Promise<void> {
    const currentAssessment = this.Sportsdata[this.Sportsdata.length - 1];

    // Define the metrics and their corresponding values
    const metrics = [
      'AverageTorque (Concentric Right)',
      'AverageTorque (Concentric Left)',
      'PeakTorque (Concentric Right)',
      'PeakTorque (Concentric Left)',
      'AverageTorque (Isometrics Right)',
      'AverageTorque (Isometrics Left)',
      'PeakTorque (Isometrics Right)',
      'PeakTorque (Isometrics Left)',
      'Jump Height',
      'Flight Time', 'Velocity', 'Force', 'Power'
    ];

    const valuesMap = {
      spaAverageTorqueCR: currentAssessment.spaAverageTorqueCR || 0,
      spaAverageTorqueCL: currentAssessment.spaAverageTorqueCL || 0,
      spaPeakTorqueCR: currentAssessment.spaPeakTorqueCR || 0,
      spaPeakTorqueCL: currentAssessment.spaPeakTorqueCL || 0,
      spaAverageTorqueIR: currentAssessment.spaAverageTorqueIR || 0,
      spaAverageTorqueIL: currentAssessment.spaAverageTorqueIL || 0,
      spaPeakTorqueIR: currentAssessment.spaPeakTorqueIR || 0,
      spaPeakTorqueIL: currentAssessment.spaPeakTorqueIL || 0,
      spaJumpHeight: currentAssessment.spaJumpHeight || 0,
      spaFlightTime: currentAssessment.spaFlightTime || 0,
      spaVelocity: currentAssessment.spaVelocity || 0,
      spaForce: currentAssessment.spaForce || 0,
      spaPower: currentAssessment.spaPower || 0,
    };

    // Populate the values in the respective table cells
    Object.keys(valuesMap).forEach(id => {
      const cell = document.getElementById(id);
      if (cell) {
        cell.textContent = (valuesMap as any)[id].toString();
      } else {
        console.error(`Element with id ${id} not found!`);
      }
    });
  }

  getRangeColor(range: number): string {
    if (range === 100) {
      return '#00FF00'; // Green for 100%
    } else if (range === 50) {
      return '#FFA500'; // Orange for 50%
    } else if (range === 10) {
      return '#FF0000'; // Red for 10%
    }
    return '#000000'; // Default black color
  }

  getArrowIcon(range: number): string {
    const color = this.getRangeColor(range);
    if (color === '#00FF00') {
      // Up arrow SVG path
      return 'M1.49217 11C0.590508 11 0.149368 9.9006 0.800944 9.27736L9.80878 0.66117C10.1954 0.29136 10.8046 0.291359 11.1912 0.661169L20.1991 9.27736C20.8506 9.9006 20.4095 11 19.5078 11H1.49217Z';
    } else {
      // Down arrow SVG path
      return 'M1.49217 0C0.590508 0 0.149368 1.0994 0.800944 1.72264L9.80878 10.3388C10.1954 10.7086 10.8046 10.7086 11.1912 10.3388L20.1991 1.72264C20.8506 1.0994 20.4095 0 19.5078 0H1.49217Z';
    }
  }

  getRangeColorClass(range: number): string {
    if (range === 100) {
      return 'text-green'; // Green class
    } else if (range === 50) {
      return 'text-orange'; // Orange class
    } else if (range === 10) {
      return 'text-red'; // Red class
    }
    return ''; // Default no class
  }


  updateChart(option: string): void {
    this.isLoading = true;
    if (option === 'Level One') {
      this.updateLevelOneChart();
      this.isLoading = false;
    } else if (option === 'Physical Fitness') {
      this.updateFitnessChart();
      this.isLoading = false;
    } else if (option === 'Nutrition') {
      this.updateNutritionChart();
      this.isLoading = false;
    } else if (option === 'Cognitive') {
      this.updateCognitiveChart();
      this.isLoading = false;
    } else if (option === 'Injury Prevention') {
      this.updateInjuryChart();
      this.isLoading = false;
    } else if (option === 'Sports Performance') {
      this.updateSportsChart();
      this.isLoading = false;
    } else {
      this.isLoading = false;
    }

  }

  updateFitnessChart() {
    this.assessmentService.getFitnessByAthleteId(this.UserId).subscribe(
      (response: any) => {
        this.Fitnessdata = response;
        // Select the last item from the response array
        if (response && response.length > 0) {
          this.lastFitnessAssessment = response[response.length - 1]; // Get the last element
        }
        this.FitnessGraph();
        this.FitnessTable();
      }
    );
  }

  updateCognitiveChart() {
    this.assessmentService.getcognitiveDetaillist(this.UserId).subscribe(
      (response: any) => {
        this.Cognitivedata = response;
        // Select the last item from the response array
        if (response && response.length > 0) {
          this.lastCognitiveAssessment = response[response.length - 1]; // Get the last element
        }
        this.CognitiveGraph();
        this.CognitiveTable();
      }
    );
  }

  updateInjuryChart() {
    this.assessmentService.geteInjuryPrventionByAthleteId(this.UserId).subscribe(
      (response: any) => {
        this.Injurydata = response;
        // Select the last item from the response array
        if (response && response.length > 0) {
          this.lastInjuryAssessment = response[response.length - 1]; // Get the last element
        }
        this.InjuryGraph();
        this.InjuryTable();
      }
    );
  }

  updateSportsChart() {
    this.assessmentService.getSportsPerformaceDataByAthleteId(this.UserId).subscribe(
      (response: any) => {
        this.Sportsdata = response;
        // Select the last item from the response array
        if (response && response.length > 0) {
          this.lastSportsAssessment = response[response.length - 1]; // Get the last element
        }
        this.SportsGraph();
        this.SportsTable();
      }
    );
  }


  getEndurance(plaAthlete: any): Observable<number> {
    return this.assessmentService.getFitnessByAthleteId(plaAthlete).pipe(
      map(endurance => endurance.length > 0 ? endurance[endurance.length - 1].pfaEndurance : 0)
    );
  }

  updateNutritionChart() {
    this.assessmentService.geteNutritionalByAthleteId(this.UserId).subscribe(
      (response: any) => {
        this.Nutritionaldata = response;
        // Select the last item from the response array
        if (response && response.length > 0) {
          this.lastNutritionAssessment = response[response.length - 1]; // Get the last element
        }
        // this.NutritionChart();
        this.NutritionGraph();
        this.NutritionTable();
      }
    );
  }

  updateLevelOneChart() {
    this.assessmentService.getLevelOneByAthleteId(this.UserId).subscribe(
      (response: any) => {
        this.LevelOnedata = response;
        // Select the last item from the response array
        if (response && response.length > 0) {
          this.lastLevelOneAssessment = response[response.length - 1]; // Get the last element
        }
        this.LeveloneGraph();
        this.LeveloneTable();
      }
    );
  }

  onDropdownChange(): void {
    this.updateChart(this.selectedOption);
  }


  getClassification(value: number): string {
    if (value >= 90) return 'Excellent';
    if (value >= 70) return 'Good';
    if (value >= 50) return 'Average';
    return 'Poor';
  }

  getNutritionRangeColor(classification: string): string {
    switch (classification) {
      case 'Excellent':
        return 'text-darkgreen';
      case 'Good':
        return 'text-green';
      case 'Average':
        return 'text-orange';
      default:
        return 'text-red';
    }
  }

  DownloadClick() {

    this.assessmentService.getAdmitCardById(this.UserId).subscribe((response: any) => {
      this.admitcardReport = response;
      this.imageUrls = this.admitcardReport.map((athlete: any) => {
        if (athlete.usrImage) {
          return this.sanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + athlete.usrImage);
        } else {
          return this.profileImageUrl; // Handle cases where there is no image
        }
      });
    });

  }
  downloadPDF(): void {
    const DATA = document.getElementById('pdf-content');
    if (DATA) {
      let pdf: jsPDF | null = null; // Initialize pdf as null
      this.admitcardReport.forEach((athlete: any, index: number) => {
        const athleteElement = DATA.querySelectorAll('.modal-body')[index]; // Target each athlete
        if (athleteElement) {
          html2canvas(athleteElement as HTMLElement, {
            scale: 2, // Higher scale for better quality
          }).then(canvas => {
            const imgData = canvas.toDataURL('image/jpeg', 0.7);

            if (!pdf) {
              pdf = new jsPDF('p', 'mm', 'a4'); // Initialize pdf only once
            }

            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();

            const imgProps = pdf.getImageProperties(imgData);
            const imgWidth = imgProps.width;
            const imgHeight = imgProps.height;

            const widthScale = (pdfWidth * 0.5) / imgWidth;
            const heightScale = pdfHeight / imgHeight;
            const scale = Math.min(widthScale, heightScale); // Scale based on the smaller of the two

            const newImgWidth = imgWidth * scale;
            const newImgHeight = imgHeight * scale;

            const marginX = (pdfWidth - newImgWidth) / 2;
            const marginY = (pdfHeight - newImgHeight) / 2;

            if (index > 0) {
              pdf.addPage();
            }

            pdf.addImage(
              imgData,
              'JPEG',
              marginX,
              marginY,
              newImgWidth,
              newImgHeight,
              undefined,
              'FAST'
            );

            if (index === this.admitcardReport.length - 1) {
              pdf.save('AdmitCard.pdf');
            }
          });
        }
      });
    }
  }
}