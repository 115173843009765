import { CommonModule } from '@angular/common';
import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLink, RouterOutlet } from '@angular/router';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AssessmentService } from '../../../services/assessment.service';
import { PrivilegeService } from '../../../services/privilage.service';
import { Privilege } from '../../../model/Privillage';
import { UserService } from '../../../services/user.service';
import { User } from '../../../model/User';
 
// Row Data Interface
interface IRow {
  vuUploader: string;
  playername: string;
  vuId: string;
  ReactionTime: string;
  Assessor: string;
  date: string;
  vuVideo: string;
}
 
@Component({
  selector: 'app-video-analysis-list',
  standalone: true,
  imports: [CommonModule, RouterOutlet, RouterLink, AgGridAngular],
  templateUrl: './video-analysis-list.component.html',
  styleUrls: ['./video-analysis-list.component.css']
})
export class VideoAnalysisListComponent implements OnInit {
  user: User | null = null;
  videoSrcs: string[] = [];
  isAdmin: boolean = false;
  isPlayer: boolean = false;
  themeClass = 'ag-theme-quartz';
 
  rowData: IRow[] = [];
  colDefs: ColDef[] = [];
  defaultColDef: ColDef = {
    flex: 1,
    sortable: true,
    filter: true
  };
  privilege: Privilege;
  Isplayer: any;
  Admin: any;
 
  constructor(private assessmentService: AssessmentService,private ngZone: NgZone, private router: Router,private priService:PrivilegeService,private userService:UserService) {
    this.privilege = this.priService.getPrivileges();
    this.Isplayer = this.privilege.isPlayer;
    this.Admin = this.privilege.isAdmin;}
 
  ngOnInit() {
    this.loadVideos();
 
    this.assessmentService.getvideoAnalysis().subscribe(
      response => {
        console.log('Assessment data received', response);
        if (response && Array.isArray(response)) {
          this.rowData = response.map((item: any) => {
            console.log('Item vuvideo:', item.vuVideo);
            return {
              vuUploader: `${item.vuUploader}`,
              playername: `${item.usrFullName}`,
              vuId: `${item.vuId}`,
              Assessor: `Assessor ${item.vuPerformanceAnalysis}`,
              ReactionTime: `${item.vuPerformanceAnalysis}`,
              date: new Date(item.vuDate).toLocaleDateString('en-US'),
              vuVideo: `${item.vuVideo}`,
            };
          });
 
          this.colDefs = this.getColumnDefinitions();
        } else {
          console.error('Unexpected response format');
        }
      },
      error => {
        console.error('Error fetching video analysis data', error);
      }
    );
 
    // Logic to set user roles
   
  }
 
  getColumnDefinitions(): ColDef[] {
    const columns: ColDef[] = [
      { field: 'playername' },
      { field: 'Assessor' },
      { field: 'ReactionTime' },
      { field: 'date' },
      {
        headerName: 'Action',
        cellRenderer: (params: any) => {
          const container = document.createElement('div');
          container.style.display = 'flex';
          container.style.justifyContent = 'space-around';

          // View button with eye icon
          const viewButton = document.createElement('button');
          viewButton.classList.add('btn');
          viewButton.innerHTML = '<i class="fa fa-eye"></i>';
          viewButton.addEventListener('click', () => {
            this.ngZone.run(() => {
            if (this.isPlayer) {
              this.router.navigate(['/videoanalysisdetail']);
            } else {
              this.router.navigate(['/VideoAnalysisDetailList'], { queryParams: { id: params.data.vuUploader } });
            }
          })
          });

          // Edit button with edit icon
          const editButton = document.createElement('button');
          editButton.classList.add('btn');
          editButton.innerHTML = '<i class="fa fa-edit"></i>';
          editButton.addEventListener('click', () => {
            this.ngZone.run(() => {
            this.router.navigate(['/VideoAnaalysisedit'], { queryParams: { id: params.data.vuId } });
            })
          });

          container.appendChild(viewButton);
          if (!this.Isplayer) {
          container.appendChild(editButton);
          }
          return container;
        },
        width: 120
      }
    ];

    if (this.isAdmin) {
      return columns;
    } else if (this.isPlayer) {
      return columns.filter(col => col.field !== 'playername');
    } else {
      return columns.filter(col => col.field !== 'Assessor');
    }
  }
 
  loadVideos(): void {
    this.assessmentService.getvideoAnalysis().subscribe(response => {
      if (response && Array.isArray(response) && response.length > 0) {
        this.videoSrcs = response
          .map(video => this.convertBase64ToBlobUrl(video.vuVideo))
          .filter(url => url !== '');
        if (this.videoSrcs.length === 0) {
          console.error('Error: No valid video data found');
        } else {
          console.log('Video sources:', this.videoSrcs);
        }
      } else {
        console.error('Error: Video data not found');
      }
    }, error => {
      console.error('Error fetching video analysis data:', error);
    });
  }
 
  convertBase64ToBlobUrl(base64: string): string {
    try {
      if (!base64) {
        return '';
      }
      const byteCharacters = atob(base64);
      const byteNumbers = new Array(byteCharacters.length);
 
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
 
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'video/mp4' }); // Ensure type matches your video format
      return URL.createObjectURL(blob);
    } catch (e) {
      console.error('Error converting base64 to Blob URL:', e);
      return '';
    }
  }
}
 