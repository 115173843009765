import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, Input, PLATFORM_ID } from '@angular/core';
import { Router, RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { AssessmentService } from '../../services/assessment.service';
import { PrivilegeService } from '../../services/privilage.service';
import { UserService } from '../../services/user.service';
import { SharedService } from '../../services/shared.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ChatService } from '../../services/chat.service';
import { catchError, firstValueFrom, map, Observable, of } from 'rxjs';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-level-one-dashboard',
  standalone: true,
  imports: [CommonModule,RouterOutlet,FormsModule],
  templateUrl: './level-one-dashboard.component.html',
  styleUrl: './level-one-dashboard.component.css'
})
export class LevelOneDashboardComponent {
  @Input() selectedTeamId: any = null;
  user: any;
  apiResponse: any;
  topPerformers: any;
  athletedata: any;
  leastPerformers: any;
  selectedImageUrl: any;
  selectedStatus: number = 0;
  selectedGender: string = '';
  userMessage: string = ""; // Default message
  List: any;
  isLoading = false;
  c3: any;
  Response: any;
  chat: any;
  Performers: any;
  header: any;
  privilege: any;
  Isplayer: boolean = false;
  Admin: boolean = false;
  OrgAdmin: boolean = false;
  selectedOption: string = ''; // Default value
  public imageUrls: any[] = [];
  detail: any;
  UserId: any;
  org: any = '0';
  ID: any;
  role: any;
  athleteReport: any[] = [];
  reportCount: number = 0;  // Variable to store the count
  topPerformer: any = null;  // Add this line
  averageOverallPercentage: any = null;  // Add this line
  admitcardReport: any;
  msg: any;
  plaResponse: any;
  plaPerformers: any;
  averageOverallZScore: number = 0;
  Orgainsation: any;
  selectedOrg: string = '0'; 
  trainerId: any;
  selectedTrainer: string = '';
  Coach: boolean = false;
  Trainer: any;
  image: any;
  ImageUrl: any;

  constructor(
    private router: Router,
    private assessmentService: AssessmentService,
    private priService: PrivilegeService,
    private userService: UserService,
    private sharedService: SharedService, 
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef, 
    private chatService: ChatService, @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.sharedService.detail$.subscribe((data: any) => {
      this.detail = data;
      this.UserId = this.detail.user.usrId;
      this.org = this.detail.user.usrOrganization;
    });
    // this.assessmentService.getLevelOneList(this.org).subscribe(async response => {
    //   this.Response = response;
    //   await this.loadC3Library();
    //   this.loadTopPerformersData();
    //   this.isLoading = false;
    // });
  }
  onStatusChange() {
    this.loadTopPerformersData();
  }

  
  async ngOnInit(): Promise<void> {

    this.privilege = this.priService.getPrivileges();
    this.Isplayer = this.privilege?.isPlayer ?? false;
    this.Admin = this.privilege?.isAdmin ?? false;
    this.OrgAdmin = this.privilege?.isOrgAdmin ?? false;
    this.Coach = this.privilege?.isCoach ?? false;
    this.user = this.userService.getUser();

    this.assessmentService.getOrgainsation().subscribe(
      (data) => {
        this.Orgainsation = data;
      },
      (error) => {
        console.error('Error fetching coaches:', error);
      }
    );

    this.assessmentService.getTrainer().subscribe(
      (data) => {
        this.Trainer = data;
      },
      (error) => {
        console.error('Error fetching coaches:', error);
      }
    );
    this.isLoading = true;
    this.loadLevelonedata();
      this.isLoading = false;

  }

  orgStudents(){
    if (this.Admin || this.OrgAdmin) {
      this.assessmentService.getAdmitCard(this.org).subscribe((response: any) => {
        this.admitcardReport = response;
      });
    }
  }

  loadLevelonedata(){
    if(this.Coach || this.trainerId){
      if(this.trainerId){
        this.UserId = this.trainerId;
      }
      this.assessmentService.getLeveloneByCoachId(this.UserId).subscribe(async response => {
        this.Response = response;
        await this.loadC3Library();
        this.loadTopPerformersData();
        this.isLoading = false;
      });
    }
    else{
      this.assessmentService.getLevelOneList(this.org).subscribe(async response => {
        this.Response = response;
        await this.loadC3Library();
        this.loadTopPerformersData();
        this.isLoading = false;
      });
    }
  }

  sendMessage() {
    if (this.selectedOption == 'Level One') {
      this.apiResponse = this.Response;
      this.msg = " give me result with their loaId,usrFullName and result in both text and json format";
    }

    const assessmentDataJson = JSON.stringify(this.apiResponse);
    const message = "in this data give me " + this.userMessage + this.msg;
    const combinedMessage = `
      {
        "data": ${assessmentDataJson},
        "message": "${message}"
      }
    `;
    this.isLoading = true;
    this.chatService.getChatResponse(combinedMessage).subscribe(async chatResponse => {
      const chatContent = chatResponse?.choices?.[0]?.message?.content;
      if (chatContent) {
        try {
          // Extract JSON array or object from the chat response
          const jsonMatch = chatContent.match(/\[.*?\]/s) || chatContent.match(/\{.*?\}/s);
          if (jsonMatch) {
            const jsonString = jsonMatch[0].trim();
            this.athletedata = JSON.parse(jsonString);
            const textContent = chatContent.replace(jsonString, '').trim();
            this.chat = textContent;
          } else {
            this.chat = chatContent;
            console.error('No JSON block found in the chat content.');
          }
        } catch (error) {
          this.chat = chatContent;
          console.error('Error parsing chat response JSON:', error);
        }
      } else {
        console.error('Unexpected chat response structure:', chatResponse);
      }

      this.isLoading = false;

      // Update each performer with matching assessment data and image URL
      this.athletedata.forEach((performer: any) => {
         if (this.selectedOption == 'Level One') {
          const matchingAssessment = this.apiResponse.find((item: any) => item.loaId === performer.loaId)
          if (matchingAssessment) {
            Object.assign(performer, matchingAssessment);
            this.getProfileImage(performer.loaAthlete)
            this.loadC3Library();
            this.loadTopPerformersData();
          } else {
            console.error(`No matching assessment found`);
          }
        } 
      });
    }, error => {
      console.error('Error from Chat Service:', error);
      this.isLoading = false;
    });

  }

  getFilteredChat(): string {
    // Assuming "JSON Format" starts from a specific keyword you want to hide
    const jsonIndex = this.chat.indexOf("In JSON format:");
    if (jsonIndex !== -1) {
      return this.chat.substring(0, jsonIndex);  // Return text before "JSON Format"
    }
    return this.chat;  // If "JSON Format" is not found, return the full text
  }

  loadTopPerformersData() {
    if (this.athletedata === undefined) {
      this.Performers = this.Response;
      if (this.selectedStatus == 0) {
        this.Performers = this.Performers.sort((a: any, b: any) => b.loaAverageRange - a.loaAverageRange)
          .slice(0, 5);
        this.header = "Top";
        this.Performers.forEach((performer: any) => {
          this.getProfileImage(performer.loaAthlete)
        });
      } else {
        this.Performers = this.Performers
          .sort((a: any, b: any) => a.loaAverageRange - b.loaAverageRange)
          .slice(0, 5);
        this.header = "Under";
        this.Performers.forEach((performer: any) => {
          this.getProfileImage(performer.loaAthlete)
        });
      }
    } else {
      this.Performers = this.athletedata;
      this.Performers.forEach((performer: any) => {
        this.getProfileImage(performer.loaAthlete)
      });
    }


  }


  formnavigation() {
    this.router.navigate(['/nutrition']);
  }

  generateBarChart(c3: any) {
    let ageGroups: number[] = [];
    let goodCounts: any = {};
    let averageCounts: any = {};
    let belowAverageCounts: any = {};
    let totalCounts: any = {};

    // Fetching data from the API response
    if (this.athletedata === undefined) {
      this.apiResponse = this.Response;
    } else {
      this.apiResponse = this.athletedata;
    }

    // Loop through API response to group data by age and range
    this.apiResponse.forEach((item: any) => {
      const age = item.aggAge;
      if (age == null || age === '') {
        return;
      }
      // Initialize count if age not already present
      if (!ageGroups.includes(age)) {
        ageGroups.push(age);
        goodCounts[age] = 0;
        averageCounts[age] = 0;
        belowAverageCounts[age] = 0;
        totalCounts[age] = 0; // Initialize total count for each age group
      }

      // Classify based on loaAverageRange
      if (item.loaAverageRange >= 80) {
        goodCounts[age]++;
      } else if (item.loaAverageRange >= 60) {
        averageCounts[age]++;
      } else if (item.loaAverageRange < 60)  {
        belowAverageCounts[age]++;
      }

      totalCounts[age]++; // Increment total count for the age group
    });

    // Sort the ageGroups array in ascending order
    ageGroups.sort((a, b) => a - b);

    // Prepare chart data based on sorted age groups
    const columns = [
      ['Good', ...ageGroups.map((age: any) => goodCounts[age] || 0)],
      ['Average', ...ageGroups.map((age: any) => averageCounts[age] || 0)],
      ['BelowAverage', ...ageGroups.map((age: any) => belowAverageCounts[age] || 0)]
    ];

    const chart = c3.generate({
      bindto: '#bar-chart', // Bind to your HTML element
      data: {
        columns: columns,
        type: 'bar',
        colors: {
          Good: '#F95F53',
          Average: '#34B1AA',
          // BelowAverage: '#0049FA',
          BelowAverage: 'var(--green-color)'
        },
        onclick: (d: any) => {
          this.showTable(d, ageGroups);
        }
      },
      axis: {
        x: {
          type: 'category',
          categories: ageGroups, 
          label: { 
            text: 'Age',
            position: 'outer-center' 
          }
        },
        y: {
          label: { 
            text: 'Count',
            position: 'outer-middle' 
          }
        }
      },
      bar: {
        width: {
          ratio: 0.5 // Customize bar width
        }
      },
      tooltip: {
        format: {
          title: function (index: any) {
            return `Age: ${ageGroups[index]}`; // Display the age as title
          },
          value: function (value: number, ratio: any, id: string, index: number) {
            const age = ageGroups[index]; // Get the corresponding age
            const total = totalCounts[age]; // Get total for the age group
            const percentage = ((value / total) * 100).toFixed(2);
            if (id === 'Good') {
              return `${value} Good (${percentage}%)`;
            } else if (id === 'Average') {
              return `${value} Average (${percentage}%)`;
            } else if (id === 'BelowAverage') {
              return `${value} BelowAverage (${percentage}%)`;
            }
            return `${value}`;
          },

        },
      }
    });  
  }

  generatepieChart(c3: any) {
    let goodCount = 0, averageCount = 0, belowAverageCount = 0;
    if (this.athletedata === undefined) {
      this.apiResponse = this.Response;
    } else {
      this.apiResponse = this.athletedata;
    }

    // Iterate over the API response to classify based on loaAverageRange
    this.apiResponse.forEach((item: { loaAverageRange: number; }) => {
      if (item.loaAverageRange >= 80) {
        goodCount++;
      } else if (item.loaAverageRange >= 60) {
        averageCount++;
      } else if (item.loaAverageRange < 60) {
        belowAverageCount++;
      }
    });
    const chart = c3.generate({
      bindto: '#pie-donut', // ID of the element to bind the chart to
      data: {
        columns: [
          ['Good', goodCount],
          ['Average', averageCount],
          ['BelowAverage', belowAverageCount]
        ],
        type: 'pie',
        colors: {
          Good: '#F95F53', // Color for Good
          Average: '#34B1AA', // Color for Average
          BelowAverage: '#0049FA' // Color for Below Average
        }
      }
    });

    setTimeout(() => {
      chart.load({
        columns: [
          ["Good", goodCount],
          ["Average", averageCount],
          ["BelowAverage", belowAverageCount],
        ]
      });
    }, 1500);
  }

  async loadC3Library() {
    const c3 = (await import('c3')).default;
    //this.generateChart(c3);
    this.generateBarChart(c3);
    this.generatepieChart(c3);
    this.isLoading= false;
  }

  generateChart(c3: any) {
    const chart = c3.generate({
      bindto: '#chart',
      data: {
        columns: [
          ['data1', 30, 200, 100, 400, 150, 250],
          ['data2', 50, 20, 10, 40, 15, 25]
        ],
        colors: {
          data1: '',
          data2: '#87CEEB'
        }
      }
    });
    
  }

  getProfileImage(userId: any) {
   
 
    this.assessmentService.getEmptyImage(userId).subscribe(
      (response: any) => {
        console.log('Empty image response:', response);
        if (response) {
          this.image = "assets/image/profileimg.jpg";
          const athleteEntry = this.Performers.find((entry: { loaAthlete: any; }) => entry.loaAthlete === userId);
          if (athleteEntry) {
            athleteEntry.image = this.image;
          } else {
            // this.Performers.push({ loaAthlete: userId, image: this.image });
          }
          this.cdr.detectChanges();
        }
      },
      (error: any) => {
        this.assessmentService.getImage(userId).subscribe(
          (imageBlob) => {
            const objectURL = URL.createObjectURL(imageBlob);
            this.ImageUrl = this.sanitizer.bypassSecurityTrustUrl(objectURL);
            this.image = this.ImageUrl.changingThisBreaksApplicationSecurity;
            const athleteEntry = this.Performers.find((entry: { loaAthlete: any; }) => entry.loaAthlete === userId);
            if (athleteEntry) {
              athleteEntry.image = this.image;
            } else {
              // this.Performers.push({ loaAthlete: userId, image: this.image });
            }
            this.cdr.detectChanges();
          },
          (error) => {
            console.error('Error fetching image:', error);
          }
        );
      }
    );
  }

  openImageModal(imageUrl: string) {
    this.selectedImageUrl = imageUrl;
    const modalElement = document.getElementById('imageModal');
    const modal = new (window as any).bootstrap.Modal(modalElement);
    modal.show();
  }

  fetchImageUrls() {
    this.imageUrls = this.apiResponse.map((athlete: any) => {
      if (athlete.usrImage) {
        return this.sanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + athlete.usrImage);
      } else {
        return null;
      }
    });
  }

  showTable(data: any, ageGroups: number[]) {
    const table = document.getElementById('dataTable');
    const age = ageGroups[data.index];
    const category = data.name;
    let filteredAthletes = this.apiResponse.filter((item: any) => {
      if (item.aggAge !== age) return false;
      if (category === 'Good' && item.loaAverageRange >= 80) return true;
      if (category === 'Average' && item.loaAverageRange >= 60 && item.loaAverageRange < 80) return true;
      if (category === 'Below Average' && item.loaAverageRange  < 60) return true;

      return false;
    });

    this.fetchImageUrls();
    let tableRows = filteredAthletes.map((athlete: any) => `
      <tr>
        <td>${athlete.usrFullName}</td>  <!-- Person's name -->
        <td>${athlete.aggAge}</td>   <!-- Age -->
        <td>${athlete.loaHeight}</td>  <!-- Height -->
        <td>${athlete.loaWeight}</td>  <!-- Weight -->
        <td>${athlete.loaAverageRange}</td>  <!-- Performance range -->
      </tr>
    `).join('');

    // Dynamically update table content
    if (table) {
      table.innerHTML = `
        <table class="table">
          <thead>
            <tr>
           
              <th>Name</th>
              <th>Age</th>
              <th>Height</th>
              <th>Weight</th>
              <th>Average Range</th>
            </tr>
          </thead>
          <tbody>
            ${tableRows}
          </tbody>
        </table>
      `;
      table.style.display = 'block'; // Make the table visible
    }
  }

  async selectOrganisation(){
    this.org = this.selectedOrg;
    this.orgStudents();
    this.isLoading = false;
    if (this.selectedOption === 'Level One') {
      this.loadLevelonedata();
    }
  }

  async selectTrainer(){
    this.trainerId = this.selectedTrainer;
    this.isLoading = true;
    if (this.selectedOption === 'Level One') {
      this.loadLevelonedata();
    }
  }

  async onDropdownChange() {
    this.chat = '';
    this.athletedata = undefined;
    this.selectedStatus = 0;
    this.isLoading = false;
    if (this.selectedOption === 'Level One1') {
      this.loadLevelonedata();
      this.isLoading = false;
    } 
  }
  


  getEndurance(plaAthlete: any): Observable<number> {
    return this.assessmentService.getFitnessByAthleteId(plaAthlete).pipe(
      map(endurance => endurance.length > 0 ? endurance[endurance.length - 1].pfaEndurance : 0)
    );
  }

  DownloadClick() {
    if (this.Admin || this.OrgAdmin) {
      this.assessmentService.getAdmitCard(this.org).subscribe((response: any) => {
        this.admitcardReport = response;

        // Map the image URLs for multiple athletes
        this.imageUrls = this.admitcardReport.map((athlete: any) => {
          if (athlete.usrImage) {
            return this.sanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + athlete.usrImage);
          } else {
            return null; // Handle cases where there is no image
          }
        });
      });
    } else if (this.Isplayer) {
      this.assessmentService.getAdmitCardById(this.UserId).subscribe((response: any) => {
        this.admitcardReport = response;
        this.imageUrls = this.admitcardReport.map((athlete: any) => {
          if (athlete.usrImage) {
            return this.sanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + athlete.usrImage);
          } else {
            return null; // Handle cases where there is no image
          }
        });
      });
    }
  }

  downloadPDF(): void {
    const DATA = document.getElementById('pdf-content');
    if (DATA) {
      let pdf: jsPDF | null = null; // Initialize pdf as null
      this.admitcardReport.forEach((athlete: any, index: number) => {
        const athleteElement = DATA.querySelectorAll('.modal-body')[index]; // Target each athlete
        if (athleteElement) {
          html2canvas(athleteElement as HTMLElement, {
            scale: 2, // Higher scale for better quality
          }).then(canvas => {
            const imgData = canvas.toDataURL('image/jpeg', 0.7);

            if (!pdf) {
              pdf = new jsPDF('p', 'mm', 'a4'); // Initialize pdf only once
            }

            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();

            const imgProps = pdf.getImageProperties(imgData);
            const imgWidth = imgProps.width;
            const imgHeight = imgProps.height;

            const widthScale = (pdfWidth * 0.5) / imgWidth;
            const heightScale = pdfHeight / imgHeight;
            const scale = Math.min(widthScale, heightScale); // Scale based on the smaller of the two

            const newImgWidth = imgWidth * scale;
            const newImgHeight = imgHeight * scale;

            const marginX = (pdfWidth - newImgWidth) / 2;
            const marginY = (pdfHeight - newImgHeight) / 2;

            if (index > 0) {
              pdf.addPage();
            }

            pdf.addImage(
              imgData,
              'JPEG',
              marginX,
              marginY,
              newImgWidth,
              newImgHeight,
              undefined,
              'FAST'
            );

            if (index === this.admitcardReport.length - 1) {
              pdf.save('AdmitCard.pdf');
            }
          });
        }
      });
    }
  }
  

}
