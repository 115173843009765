import { CommonModule } from '@angular/common';
import { Component, Input, NgZone } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink, RouterModule } from '@angular/router';
import { AgGridAngular } from 'ag-grid-angular';
import { User } from '../../../model/User';
import { ColDef } from 'ag-grid-community';
import { PrivilegeEnum } from '../../../model/PrivilageEnum';
import { Privilege } from '../../../model/Privillage';
import { AssessmentService } from '../../../services/assessment.service';
import { PrivilegeService } from '../../../services/privilage.service';
import { SharedService } from '../../../services/shared.service';
import { UserService } from '../../../services/user.service';
interface IRow {}
@Component({
  selector: 'app-recall-player-list',
  standalone: true,
  imports: [CommonModule, RouterModule, RouterLink, FormsModule,AgGridAngular],
  templateUrl: './recall-player-list.component.html',
  styleUrl: './recall-player-list.component.css'
})
export class RecallPlayerListComponent {


  @Input() name: any;
 
  user: User | null = null;
 
  themeClass = 'ag-theme-quartz';
 
  rowData: IRow[] = [];
 
  colDefs: ColDef[] = [];
 
  ramPrivilege: PrivilegeEnum | undefined;
 
  defaultColDef: ColDef = {
    flex: 1,
    sortable: true,
    filter: true,
  };
  privilege: Privilege;
  Isplayer: boolean;
  Admin: boolean;
  detail: any;
  org: any;
  id:any;
  assessor: any;
  selectedStatus: string = '0';
  selectdata: any;
  testCategory: any;
  role: any;
  teamId:any;
  athleteId:any;
  assesserId:any;
  categoryId:any;

 
  constructor(private assessmentService: AssessmentService,private route: ActivatedRoute, private router: Router, private sharedService: SharedService,
    private ngZone: NgZone,private userService:UserService,private priService:PrivilegeService) {
    this.privilege = this.priService.getPrivileges();
    this.Isplayer = this.privilege.isPlayer;
    this.Admin = this.privilege.isAdmin;

    this.route.queryParams.subscribe(params => {
      this.teamId = params['teamId'];
      this.athleteId = params['athleteId'];
      this.assesserId = params['assessor'];  
      this.categoryId = params['categoryId'];
      this.id = params['id'];
  
    });
    }
 
  ngOnInit() {
    this.sharedService.detail$.subscribe((data: any) => {
      this.detail = data;
      this.org=this.detail.user.usrOrganization;
      this.role=this.detail.user.usrRole;
      this.id = this.detail.user.usrId;

      console.log(this.org,"org")
    });
    this.user = this.userService.getUser();
    this.ramPrivilege = this.userService.getRamPrivilege();
    console.log(this.ramPrivilege,"Datapri");
    this.getRecallAssessmentByAtheleteId();
    
  }

  getRecallAssessmentByAtheleteId(){

    this.assessmentService.getRecallAssessmentByAtheleteId(this.id).subscribe(response => {
      console.log('Assessment data', response);
      this.rowData = response.map((item: any) => ({
        AthleteName: item.athleteName,
        PlayerId:item.rcaAthletId,
        id:item.rcaId,
       'Assessment Name': `${item.asmName}`,
       'Assessment Date': item.asmDate,   
        coachName:item.coachName,
        TeamName: item.teamName,
        OrgName: item.orgName,
        Date: new Date(item.asmDate).toLocaleDateString('en-US'),
      }));

      this.colDefs = this.getColumnDefinitions();
    }, error => {
      console.error('Error fetching assessment data', error);
    });
  }
 
 
  getColumnDefinitions(): ColDef[] {
    const columns: ColDef[] = [
      {field:'AthleteName'},
      { field: 'Assessment Name' },
      { field: 'Assessment Date' },
      { field: 'TeamName'},
      { field: 'OrgName' },
      { field: 'Date' },
      {
        field: 'Status',
        cellRenderer: (_params: any) => {
          const statusElement = document.createElement('span');
          if (_params.data.status == 0) {
            statusElement.textContent = 'Not Completed';
            statusElement.style.color = 'red'; // Text color red for "Not Completed"
          } else if (_params.data.status == 1) {
            statusElement.textContent = 'Completed';
            statusElement.style.color = 'green'; // Text color green for "Completed"
          }
          return statusElement;
        }
      },
      {
        headerName: 'Action',
        cellRenderer: (_params: any) => {
          const container = document.createElement('div');
          container.style.display = 'flex';
          container.style.justifyContent = 'space-around';  
          const viewButton = document.createElement('button');
          viewButton.classList.add('btn');
          viewButton.innerHTML = '<i class="fa fa-eye"></i>';
          if (_params.data.status == 0) {
            viewButton.disabled = true;
            // viewButton.style.cursor = 'not-allowed'; 
            // viewButton.style.opacity = '0.5';
          }
          viewButton.addEventListener('click', () => {
            this.ngZone.run(() => {
              this.router.navigate(['/RecallNutritionComponent'], {
                queryParams: { 
                  mode: 'view',
                  teamId: this.teamId,
                  athleteId: _params.data.PlayerId, 
                  id: this.id,
                  assessor: this.assesserId,
                  categoryId: this.testCategory             
                }
              });
            });
          });
          container.appendChild(viewButton);     
          return container;   
        },
        width: 120
      }  
    ];
 
    if (this.Admin) {
      return columns.filter(col => col.field !== 'Status');
    } else if (this.Isplayer) {
      return columns.filter(col => col.field !== 'playername');
    } else {
      return columns.filter(col => col.field !== 'Assessor');
    }
  }
 
  openModal(): void {
    // Use Bootstrap's modal function to open the modal
    const modalElement = document.getElementById('fileUploadModal')!;
    const modal = new (window as any).bootstrap.Modal(modalElement);
    modal.show();
  }

}
