import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AssessmentService } from '../../../services/assessment.service';
import { FormsModule, NgForm } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink, RouterOutlet } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { NotificationService } from '../../../services/Notifications.service';
import { NgToastModule } from 'ng-angular-popup';
import { SharedService } from '../../../services/shared.service';

@Component({
  selector: 'app-physiotherapy-form',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterOutlet, RouterLink, HttpClientModule,NgToastModule],
  templateUrl: './physiotherapy-form.component.html',
  styleUrl: './physiotherapy-form.component.css'
})
export class PhysiotherapyFormComponent implements OnInit{
 
  formSubmitted: boolean = false;
  personalDetailsFilled: boolean = false;
  physiotherapy: boolean = false;
  physiology: boolean = false;

  isReadOnly: boolean = false;
  assessment: any = {
    //ptaId: null,
    ptaAthlete: null,
    ptaDate:null,
    ptaAssessor:'',
    // ptaAssessorName:'Moulika',
    ptaInjuryHistory: null,
    ptaCurrentPainLevel: null,
    ptaRangeOfMotion:null,
    ptaFlexibility:null,
    ptaMuscleStrength: null,
    ptaBalance: null,
    ptaTreatmentPlan: null,
    ptaFollowUp:null,
    // ptaCreatedBy: null,
    // ptaCreatedOn: null,
    // ptaModifiedBy: null,
    // ptaModifiedOn: null
  };
  athletes: any[] = [];
  selectedAthleteId: string | null = null;  // To store the selected athlete's ID
  users:any;
  detail: any;
  UserId: any;
  id: any;
  constructor(private router: Router ,private route: ActivatedRoute,private sharedService: SharedService,private assessmentService: AssessmentService,private notify:NotificationService) {
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
    });
 
    this.FetchAthelete();
  }
  ngOnInit() {
    this.sharedService.detail$.subscribe((data: any) => {
      this.detail = data;
      this.UserId=this.detail.user.usrId;
    });
    if (typeof window !== 'undefined' && typeof localStorage !== 'undefined') {
    this.isReadOnly = localStorage.getItem('athelete') !== null;
    }
  }

  checkPersonalDetailsFilled(): void {
    const personalDetailsInputs = document.querySelectorAll(".form.first input");
    this.personalDetailsFilled = Array.from(personalDetailsInputs).every(input => {
      const inputElement = input as HTMLInputElement;
      return inputElement.value.trim() !== '';
    });
  }

formSubmit(): void {
    this.checkPersonalDetailsFilled();
    if (this.personalDetailsFilled) {
      console.log(this.personalDetailsFilled);
      this.formSubmitted = true;
    }
  }
  onSubmit(): void {
    this.assessment.ptaAssessor = this.UserId;
    if (this.selectedAthleteId) {
      this.assessment.ptaAthlete = this.selectedAthleteId;
    }
    if (this.isFormValid()) {
      this.assessmentService.addPhysiotherapy([this.assessment]).subscribe(response => {
        console.log('Assessment submitted', response);
        this.formSubmitted = true;
        this.notify.success("data");
        setTimeout(() => {
          this.router.navigate(['physiotherapylist']);
        }, 2000);
      }, error => {
        console.error('Error submitting assessment', error);
 
      });
    } else {
      console.log('Form is not valid');
    }
  }

  isFormValid(): boolean {
    return Object.keys(this.assessment).every(key => {
      // Exclude 'OtherNutrientLevels' from validation
      if (key === 'OtherNutrientLevels') return true;
      return this.assessment[key] !== '' && this.assessment[key] !== null;
    });
  }
  fetchAthletes(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement) {
      const query = inputElement.value;
      this.assessmentService.getAthlete(query).subscribe(data => {
        this.athletes = data;
        console.log(this.athletes)
      }, error => {
        console.error('Error fetching athletes', error);
      });
    }
  }
 
  onAthleteSelected(event: Event): void {
    const inputElement = event.target as HTMLInputElement | null;
    if (inputElement) {
      const selectedAthlete = this.athletes.find(athlete => athlete.usrFullName === inputElement.value);
      if (selectedAthlete) {
        this.selectedAthleteId = selectedAthlete.usrId;
      }
    }
  }
  FetchAthelete(){
    if(this.id){
    this.assessmentService.fetchphysiotherapybyid(this.id).subscribe((response: any) => {
      if (response && response.length > 0) {
        console.log(response,'data');
        const data = response[0];
        this.assessment.ptaId = data.ptaId;
        this.assessment.ptaDate =  data.ptaDate ? data.ptaDate.split('T')[0] : '';
     
        this.assessment.ptaAssessorName = data.assessorName;
        this.assessment.ptaAthleteName = data.athleteName;
        this.assessment.ptaAssessor = data.ptaAssessor;
        this.assessment.ptaAthlete = data.ptaAthlete;
        this.assessment.ptaInjuryHistory = data.ptaInjuryHistory;
        this.assessment.ptaCurrentPainLevel = data.ptaCurrentPainLevel;
        this.assessment.ptaRangeOfMotion = data.ptaRangeOfMotion;
        this.assessment.ptaFlexibility = data.ptaFlexibility;
        this.assessment.ptaMuscleStrength = data.ptaMuscleStrength;
        this.assessment.ptaBalance = data.ptaBalance;
        this.assessment.ptaTreatmentPlan = data.ptaTreatmentPlan;
        this.assessment.ptaFollowUp =  data.ptaFollowUp ? data.ptaFollowUp.split('T')[0] : '';
        this.assessment.ptaCreatedBy = data.ptaCreatedBy;
        this.assessment.ptaCreatedOn = new Date(data.ptaCreatedOn);
        this.assessment.ptaModifiedBy = data.ptaModifiedBy;
        this.assessment.ptaModifiedOn = new Date(data.ptaModifiedOn);
       
      }
    },
    (error: any) => {
      console.error('Error fetching assessment data', error);
    }
  );
 
  }
}
}