import { Component, Input, NgZone, ViewChild } from '@angular/core';
import { PrivilegeEnum } from '../../../model/PrivilageEnum';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FileuploadComponent } from '../../../assessment/fileupload/fileupload.component';
import { AgGridAngular } from 'ag-grid-angular';
import { ActivatedRoute, Router, RouterLink, RouterModule } from '@angular/router';
import { User } from '../../../model/User';
import { ColDef } from 'ag-grid-community';
import { Privilege } from '../../../model/Privillage';
import { AssessmentService } from '../../../services/assessment.service';
import { UserService } from '../../../services/user.service';
import { SharedService } from '../../../services/shared.service';
import { PrivilegeService } from '../../../services/privilage.service';
import { CategoryScale } from 'chart.js';
import { Location } from '@angular/common';


interface IRow {
  playername: string;
  pfaId:string;
  pfaAthlete:string;
  Assessor: string;
  Speed: string;
  Strength: string;
  ReactionTime: string;
  priority: string;
  date: string;
}
@Component({
  selector: 'app-sports-performace-analysis-list',
  standalone: true,
  imports: [CommonModule, RouterModule, RouterLink, FormsModule,AgGridAngular,FileuploadComponent],
  templateUrl: './sports-performace-analysis-list.component.html',
  styleUrl: './sports-performace-analysis-list.component.css'
})
export class SportsPerformaceAnalysisListComponent {

  @ViewChild('fileUploadModal') fileUploadModal: FileuploadComponent | undefined;
  @Input() name: any;
 
  user: User | null = null;
 
  themeClass = 'ag-theme-quartz';
 
  rowData: IRow[] = [];
 
  colDefs: ColDef[] = [];
 
  ramPrivilege: PrivilegeEnum | undefined;
 
  defaultColDef: ColDef = {
    flex: 1,
    sortable: true,
    filter: true,
  };
  privilege: Privilege;
  Isplayer: boolean;
  Admin: boolean;
  detail: any;
  org: any;
  id:any;
  assessor: any;
  testCategory: any;
  selectedStatus: string = '0';
  selectdata: any;
  role: any;
  Assessor: boolean;
  Coach: boolean;
  userId: any;
  status: any;
  ScienceStaff: boolean;
  isLoading:boolean= false;
 
  constructor(private assessmentService: AssessmentService,private route: ActivatedRoute, private router: Router, private sharedService: SharedService,
    private ngZone: NgZone,private userService:UserService,private priService:PrivilegeService,private location: Location) {
    this.privilege = this.priService.getPrivileges();
    this.Isplayer = this.privilege.isPlayer;
    this.Admin = this.privilege.isAdmin;
    this.Assessor = this.privilege.isAssessor;
    this.Coach = this.privilege.isCoach;
    this.ScienceStaff= this.privilege.isScienceStaff;


    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
      this.assessor = params ['assessor'];
      this.testCategory = params ['testCategory'];
      this.status = params['selectStatus'];
 
    
    })
    }
 
  ngOnInit() {
    this.sharedService.detail$.subscribe((data: any) => {
      this.detail = data;
      this.userId=this.detail.user.usrId;
      this.org=this.detail.user.usrOrganization;
      this.role=this.detail.user.usrRole;
      
    });
    this.isLoading=true;
    this.user = this.userService.getUser();
    this.ramPrivilege = this.userService.getRamPrivilege();
    console.log(this.ramPrivilege,"Datapri");
  this.assessmentService.getPlayerByAssessmentId(this.id).subscribe(response => {
    this.selectdata = response.filter((assessment: any) => 
      assessment.aplCategory == this.testCategory &&
      (this.Admin || assessment.apIAssesser == this.userId || this.ScienceStaff)
  );
  
  // Create a map to store unique assessments based on PlayerId (or any unique identifier)
  const uniqueAssessments = new Map<number, any>();
  

  // Filter the response to only include unique assessments
  this.selectdata.forEach((item: any) => {
    // Only add the item if it does not exist in the map
    if (!uniqueAssessments.has(item.aplPlayer)) {
      // Initialize the player entry with the item values
      uniqueAssessments.set(item.aplPlayer, {
          "Player Name": `${item.usrFullName}`,
          PlayerId: `${item.aplPlayer}`,
          teamId: `${item.teamId}`,
          id: `${item.aplAssessment}`,
          "Email": `${item.usrEmail}`,
          "Phone No": `${item.usrPhoneNo}`,
          "status": `${item.aplStatus}`,  // Initialize status
          "DOB": new Date(item.usrDoB).toLocaleDateString('en-US'),
      });
  } else {
      // If the player already exists, update the status if any item has status 0
      const existingEntry = uniqueAssessments.get(item.aplPlayer);
      existingEntry.status = existingEntry.status === '1' && item.aplStatus === 1 ? '1' : '0';
  }
  });

  // Convert the unique assessments map back to an array for your rowData
  this.rowData = Array.from(uniqueAssessments.values());

  this.colDefs = this.getColumnDefinitions();
}, error => {
  console.error('Error submitting assessment', error);
});
    
  }
 
 
  getColumnDefinitions(): ColDef[] {
    const columns: ColDef[] = [
      {field:'PlayerId'},
      { field: 'Player Name' },
      { field: 'Email' },
      { field: 'DOB'},
      { field: 'Phone No' },
      {
        field: 'Status',
        cellRenderer: (_params: any) => {
          const statusElement = document.createElement('span');
          if (_params.data.status == 0) {
            statusElement.textContent = 'Not Completed';
            statusElement.style.color = 'red'; // Text color red for "Not Completed"
          } else if (_params.data.status == 1) {
            statusElement.textContent = 'Completed';
            statusElement.style.color = 'green'; // Text color green for "Completed"
          }
          return statusElement;
        }
      },
      {
        headerName: 'Action',
        cellRenderer: (_params: any) => {
          const container = document.createElement('div');
          container.style.display = 'flex';
          container.style.justifyContent = 'space-around';

          
          const viewButton = document.createElement('button');
          viewButton.classList.add('btn');
          viewButton.innerHTML = '<i class="fa fa-eye"></i>';
          if (_params.data.status == 0) {
            viewButton.style.cssText = 'color: grey; cursor: not-allowed; opacity: 0.6;';
          }
          viewButton.addEventListener('click', () => {
            this.ngZone.run(() => {
              if(_params.data.status == 1){
              this.router.navigate(['/SportsPerformanceAnalysisDetail'], {
                queryParams: {
                  athleteId: _params.data.PlayerId,
                  id: _params.data.id,
                }
              });
              }
            });
          })
  
          // View button with eye icon
     
          const editButton = document.createElement('button');
          editButton.classList.add('btn');
          editButton.innerHTML = '<i class="fa fa-edit"></i>';
          editButton.addEventListener('click', () => {
            this.ngZone.run(() => {
                this.router.navigate(['/pendingform'], {
                  queryParams: {
                    teamId: _params.data.teamId,
                    athleteId: _params.data.PlayerId,
                    id: _params.data.id,
                    category: this.testCategory,
                    assessor: this.assessor
                  }
                });
            });
          });
           container.appendChild(viewButton);    
           container.appendChild(editButton); // Append the edit button to the container
      
          return container;
        },
        width: 120
      }

    ];
    this.isLoading=false;
 
    if (this.Admin || this.ScienceStaff) {
      return columns;
    } else if (this.Isplayer) {
      return columns.filter(col => col.field !== 'Player Name');
    }else if (this.Coach){
      return columns;
    } else{
      return columns.filter(col =>col.field !== 'Assessor'&& col.field !== 'Player Name' && col.field !== 'Email' && col.field !== 'DOB' && col.field !== 'Phone No');
    }
  }
 
  openModal(): void {
    // Use Bootstrap's modal function to open the modal
    const modalElement = document.getElementById('fileUploadModal')!;
    const modal = new (window as any).bootstrap.Modal(modalElement);
    modal.show();
  }

  onBack():void{
    this.router.navigate(['/SportsPerformanceAnalysisAssessment'], {
      queryParams: {
        statusId: this.status
      }
    });
  }


}
