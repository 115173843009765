<div class="body-wrapper">
    <div class="team-single">
        <div class="d-flex justify-content-between mb-3 gap-4">
            <div>
                <button class="btn btn-primary" (click)="onBack()">Back</button>
            </div>
            <div>
                <button type="button" class="accept" (click)="downloadPDF()">Download</button>
            </div>
        </div>
        <div class="contact-info-section margin-40px-tb">
            <div id="pdf-content">
                <div class="row">
                    <div class="col-lg-4 col-md-5 xs-margin-30px-bottom">
                        <div class="col-lg-12">
                            <div class="card mb-4">
                                <div class="content-header">
                                    <h2 class="sub-title">Nutrition Analysis</h2>
                                </div>
                                <div class="content-body">
                                    <!-- Sports Activity -->
                                    <div class="mb-4" *ngFor="let activity of activities">
                                        <div class="clearfix">
                                            <div class="d-flex justify-content-between align-items-center mb-3">
                                                <p class="small-text">{{ activity.name }}</p>
                                                <strong>{{ activity.percentage }}%</strong>
                                            </div>
                                        </div>
                                        <div class="progress progress-xs">
                                            <div class="progress-bar" [ngClass]="getProgressColor(activity.percentage)"
                                                role="progressbar" [style.width]="activity.percentage + '%'"
                                                [attr.aria-valuenow]="activity.percentage" aria-valuemin="0"
                                                aria-valuemax="100">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="bg-light-blue">
                                <h4 class="side-Title">{{athleteFullName}}</h4>
                                <p class="side-content">Athlete</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-8 card col-md-7">
                        <div class="card-body">
                            <div class="team-single-text padding-50px-left sm-no-padding-left">
                                <div class="mobile-res d-flex justify-content-between mb-4">
                                    <div>
                                        <h4 class="Title">Athlete Details</h4>
                                    </div>
                                    <!-- <div>
                                        <p>Test Taken Date:</p>
                                        <h4 class="sub-title">{{date | date: 'dd-MM-yyyy'}}</h4>
                                    </div> -->
                                    <div>
                                        <p>Assessment Date:</p>
                                        <h4 class="sub-title">{{assessmentDate | date: 'dd-MM-yyyy'}}</h4>
                                    </div>
                                </div>
                                <div class="contact-info-section margin-40px-tb">
                                    <ul class="list-style9 no-margin">
                                        <li *ngIf="athleteId">
                                            <div class="row">
                                                <div class="col-md-5 col-5">
                                                    <strong class="margin-10px-left">Athlete ID:</strong>
                                                </div>
                                                <div class="col-md-7 col-7">
                                                    <p>{{athleteId}}</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li *ngIf="naBodyWeight">
                                            <div class="row">
                                                <div class="col-md-5 col-5">
                                                    <strong class="margin-10px-left ">BodyWeight:</strong>
                                                </div>
                                                <div class="col-md-7 col-7">
                                                    <p>{{naBodyWeight}}</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li *ngIf="naBodyHeight">
                                            <div class="row">
                                                <div class="col-md-5 col-5">
                                                    <strong class="margin-10px-left ">BodyHeight:</strong>
                                                </div>
                                                <div class="col-md-7 col-7">
                                                    <p>{{naBodyHeight}}</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li *ngIf="naBMI">
                                            <div class="row">
                                                <div class="col-md-5 col-5">
                                                    <strong class="margin-10px-left ">BMI:</strong>
                                                </div>
                                                <div class="col-md-7 col-7">
                                                    <p>{{naBMI}}</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li *ngIf="naBodyFat">
                                            <div class="row">
                                                <div class="col-md-5 col-5">
                                                    <strong class="margin-10px-left ">BodyFat:</strong>
                                                </div>
                                                <div class="col-md-7 col-7">
                                                    <p>{{naBodyFat}}</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li *ngIf="naFatFreeWeight">
                                            <div class="row">
                                                <div class="col-md-5 col-5">
                                                    <strong class="margin-10px-left ">Fat Free Weight:</strong>
                                                </div>
                                                <div class="col-md-7 col-7">
                                                    <p>{{naFatFreeWeight}}</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li *ngIf="naSubcutaneousFat">
                                            <div class="row">
                                                <div class="col-md-5 col-5">
                                                    <strong class="margin-10px-left ">Subcutaneous Fat:</strong>
                                                </div>
                                                <div class="col-md-7 col-7">
                                                    <p>{{naSubcutaneousFat}}</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li *ngIf="naVisceralFat">
                                            <div class="row">
                                                <div class="col-md-5 col-5">
                                                    <strong class="margin-10px-left ">Visceral Fat:</strong>
                                                </div>
                                                <div class="col-md-7 col-7">
                                                    <p>{{naVisceralFat}}</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li *ngIf="naBodyWater">
                                            <div class="row">
                                                <div class="col-md-5 col-5">
                                                    <strong class="margin-10px-left ">Body Water:</strong>
                                                </div>
                                                <div class="col-md-7 col-7">
                                                    <p>{{naBodyWater}}</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li *ngIf="naSkeletalMuscle">
                                            <div class="row">
                                                <div class="col-md-5 col-5">
                                                    <strong class="margin-10px-left ">Skeletal Muscle:</strong>
                                                </div>
                                                <div class="col-md-7 col-7">
                                                    <p>{{naSkeletalMuscle}}</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li *ngIf="naLeanMass">
                                            <div class="row">
                                                <div class="col-md-5 col-5">
                                                    <strong class="margin-10px-left ">Lean Mass:</strong>
                                                </div>
                                                <div class="col-md-7 col-7">
                                                    <p>{{naLeanMass}}</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li *ngIf="naBoneMass">
                                            <div class="row">
                                                <div class="col-md-5 col-5">
                                                    <strong class="margin-10px-left ">Bone Mass:</strong>
                                                </div>
                                                <div class="col-md-7 col-7">
                                                    <p>{{naBoneMass}}</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li *ngIf="naProtein">
                                            <div class="row">
                                                <div class="col-md-5 col-5">
                                                    <strong class="margin-10px-left ">Protein:</strong>
                                                </div>
                                                <div class="col-md-7 col-7">
                                                    <p>{{naProtein}}</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li *ngIf="naBMR">
                                            <div class="row">
                                                <div class="col-md-5 col-5">
                                                    <strong class="margin-10px-left ">BMR:</strong>
                                                </div>
                                                <div class="col-md-7 col-7">
                                                    <p>{{naBMR}}</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li *ngIf="naMetabolicAge">
                                            <div class="row">
                                                <div class="col-md-5 col-5">
                                                    <strong class="margin-10px-left ">Metabolic Age:</strong>
                                                </div>
                                                <div class="col-md-7 col-7">
                                                    <p>{{naMetabolicAge}}</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li *ngIf="naHealthScore">
                                            <div class="row">
                                                <div class="col-md-5 col-5">
                                                    <strong class="margin-10px-left">Health Score:</strong>
                                                </div>
                                                <div class="col-md-7 col-7">
                                                    <p>{{naHealthScore}}</p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>