import { Component, Inject, PLATFORM_ID, OnInit } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink, RouterOutlet } from '@angular/router';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AssessmentService } from '../../../services/assessment.service';
import { NotificationService } from '../../../services/Notifications.service';
import { NgToastModule } from 'ng-angular-popup';
import { SharedService } from '../../../services/shared.service';
import { environment } from '../../../../environments/environment.prod';
import { DomSanitizer } from '@angular/platform-browser';
import { UserRegisterService } from '../../../services/user-register.service';
 
@Component({
  selector: 'app-physicalfitness',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterOutlet, RouterLink, HttpClientModule, NgToastModule],
  templateUrl: './physicalfitness.component.html',
  styleUrls: ['./physicalfitness.component.css']
})
export class PhysicalfitnessComponent implements OnInit {
  formSubmitted: boolean = false;
  isReadOnly: boolean = false;
  selectedAthleteId: any;
  athletes: any[] = [];
  assessment: any = {
    pfaDate: null,
    pfaAssessor: '',
    pfaAthlete: null,
    pfaHeight: null,
    pfaWeight: null,
    pfaBMI: null,
    pfaSingleLegBalanceEyesOpen: null,
    pfaSingleLegBalanceEyesClose: null,
    pfaYBalanceTest1: null,
    pfaYBalanceTest2: null,
    pfaYBalanceTest3: null,
    pfaStarExcursionTest1: null,
    pfaStarExcursionTest2: null,
    pfaStarExcursionTest3: null,
    pfaPushups: null,
    pfaPullups: null,
    pfaPullAndHang: null,
    pfaSquat: null,
    pfaSingleLegSquat: null,
    pfaPartialCurlUp: null,
    pfaGripStrengthRight: null,
    pfaGripStrengthLeft: null,
    pfaSitNReach: null,
    pfaAppleysScratchTest:null,
    pfa50mSprint:null,
		pfa30mSprint:null,
		pfa10mShuttleRun:null,
		pfaTTest:null,
		pfaStandingBroadJump:null,
		pfaVerticalJump:null,
		pfaSeatedMedBallThrow:null,
    // pfaInterpretation:'',
    // pfaRecommendations:'',
    pfaFollowUpDate:'',
    pfaCreatedBy:'',
  };
  UserId: any;
  name: any;
  id: any;
  selectedFiles: FileList | null = null;
  ImageUrl: any;
  Status:any;
  showImage: boolean = false;
  athleteId:any;
 
  constructor(
    private assessmentService: AssessmentService,
    private userRegisterService: UserRegisterService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private sharedService: SharedService,
    private router: Router,
    private notify: NotificationService,private sanitizer: DomSanitizer,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
    });
    this.FetchAthelete();
  }
 
  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      // Safe to use window, document, etc.
      this.isReadOnly = window.localStorage.getItem('athelete') !== null;
    }
 
    this.sharedService.detail$.subscribe((data: any) => {
      this.UserId = data.user.usrId;
      this.name = data.user.usrFullName;
     
    });
  }
  getProfileImage() {
    this.ImageUrl = null;
    this.showImage = false;
 
    this.assessmentService.getImage(this.athleteId).subscribe(
      (imageBlob) => {
        if (imageBlob) {
          const objectURL = URL.createObjectURL(imageBlob);
          this.ImageUrl = this.sanitizer.bypassSecurityTrustUrl(objectURL);
          this.showImage = true;
          console.log(this.ImageUrl, "ImageUrl");
        } else {
          this.notify.failed("invalid athlete number.");
        }
      },
      (error) => {
        this.ImageUrl = null;
        this.showImage = false;
        this.notify.failed("invalid athlete number");
        console.error('Error fetching image', error);
      }
    );
  }
  onPlayerChange(event: any) {
    console.log(event.target.value, "athlete");
  }
 
  onSubmit(): void {
    this.assessment.pfaAssessor = this.UserId;
    this.assessment.pfaCreatedBy = this.UserId;
      this.assessmentService.addPhysicalFitnessDetail([this.assessment]).subscribe(
        response => {
          console.log('Assessment submitted', response);
          this.formSubmitted = true;
          this.notify.success("Data updated successfully");
          setTimeout(() => {
            this.router.navigate(['physicalfitnesslist']);
          }, 1000);
        },
        error => {
          console.error('Error submitting assessment', error);
          this.notify.failed("Error submitting assessment");
        }
      );
   
  }
 
  isFormValid(): boolean {
    return Object.keys(this.assessment).every(key => {
      if (['pfaAssessorName', 'pfaAthleteName'].includes(key)) return true;
      return this.assessment[key] !== '' && this.assessment[key] !== null;
    });
  }
 
  fetchAthletes(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement) {
      const query = inputElement.value;
      this.assessmentService.getAthlete(query).subscribe(
        data => {
          this.athletes = data;
        },
        error => {
          console.error('Error fetching athletes', error);
        }
      );
    }
  }
 
  onAthleteSelected(event: Event): void {
    const inputElement = event.target as HTMLInputElement | null;
    if (inputElement) {
      const selectedAthlete = this.athletes.find(athlete => athlete.usrFullName === inputElement.value);
      if (selectedAthlete) {
        this.selectedAthleteId = selectedAthlete.usrId;
      }
    }
  }
 
  FetchAthelete() {
    if (this.id) {
      this.assessmentService.fetchAtletefitness(this.id).subscribe(
        (response: any) => {
          if (response && response.length > 0) {
            const data = response[0];
            this.assessment = {
              ...this.assessment,
              pfaId: data.pfaId,
              pfaDate: data.pfaDate ? data.pfaDate.split('T')[0] : '',
              pfaAssessor: data.pfaAssessor,
              pfaAthlete: data.pfaAthlete,
              pfaHeight: data.pfaHeight,
              pfaWeight: data.pfaWeight,
              pfaBMI: data.pfaBMI,
              pfaSingleLegBalanceEyesOpen: data.pfaSingleLegBalanceEyesOpen,
              pfaSingleLegBalanceEyesClose: data.pfaSingleLegBalanceEyesClose,
              pfaYBalanceTest1: data.pfaYBalanceTest1,
              pfaYBalanceTest2: data.pfaYBalanceTest2,
              pfaYBalanceTest3: data.pfaYBalanceTest3,
              pfaStarExcursionTest1: data.pfaStarExcursionTest1,
              pfaStarExcursionTest2: data.pfaStarExcursionTest2,
              pfaStarExcursionTest3: data.pfaStarExcursionTest3,
              pfaPushups: data.pfaPushups,
              pfaPullups: data.pfaPullups,
              pfaPullAndHang: data.pfaPullAndHang,
              pfaSquat: data.pfaSquat,
              pfaSingleLegSquat: data.pfaSingleLegSquat,
              pfaPartialCurlUp: data.pfaPartialCurlUp,
              pfaGripStrengthRight: data.pfaGripStrengthRight,
              pfaGripStrengthLeft: data.pfaGripStrengthLeft,
              pfaSitNReach: data.pfaSitNReach,
              pfaAppleysScratchTest: data.pfaAppleysScratchTest,
              pfaInterpretation: data.pfaInterpretation,
              pfaRecommendations: data.pfaRecommendations,
              pfaFollowUpDate: data.pfaFollowUpDate ? data.pfaFollowUpDate.split('T')[0] : '',
              pfaAssessorName: data.assessorName,
              pfaAthleteName: data.athleteName,
              pfaCreatedOn: new Date(data.pfaCreatedOn),
              pfaUpdatedOn: new Date(data.pfaUpdatedOn)
            };
          }
        },
        (error: any) => {
          console.error('Error fetching assessment data', error);
        }
      );
    }
  }
 
  onFileSelected(event: any) {
    this.selectedFiles = event.target.files;
  }
 
  onUpload() {
    if (this.selectedFiles) {
      const formData = new FormData();
      for (let i = 0; i < this.selectedFiles.length; i++) {
        formData.append('files', this.selectedFiles[i], this.selectedFiles[i].name);
      }
 
      this.http.post(`${environment.BaseUrl}${environment.UploadFiles}`, formData).subscribe(
        (response: any) => {
          this.notify.success('Files uploaded successfully!');
        },
        (error: any) => {
          console.error('File upload failed!', error);
          this.notify.failed('File upload failed!');
        }
      );
    }
  }
}
 