export class Privilege {
    isPlayer: boolean;
    isAdmin: boolean;
    isAssessor: boolean;
    isManager: boolean;
    isBioMechanical: boolean;
    isPhysiotherapy: boolean;
    isPhysiology: boolean;
    isPhysicalFitness: boolean;
    isNutrition: boolean;
    isVideoAnalysis: boolean;
    isOrgAdmin:boolean;
    isCardio:boolean;
    isCoach:boolean;
    isCognitive:boolean;
    isLevelOne:boolean;
    isScienceStaff:boolean;
  
    constructor() {
      this.isPlayer = false;
      this.isAdmin = false;
      this.isAssessor = false;
      this.isManager = false;
      this.isBioMechanical = false;
      this.isPhysiotherapy = false;
      this.isPhysiology = false;
      this.isPhysicalFitness = false;
      this.isNutrition = false;
      this.isVideoAnalysis = false;
      this.isOrgAdmin=false;
      this.isCardio= false;
      this.isCoach=false;
      this.isCognitive=false;
      this.isLevelOne=false;
      this.isScienceStaff=false;
    }
  }
  