    
    <div class="body-wrapper">
      <div class="container-fluid">
    <div class="row" *ngIf="!Isplayer">

      <div class="col-lg-8 d-flex align-items-strech">
        <div class="card w-100">
          <div class="card-body">
            <div class="d-sm-flex d-block align-items-center justify-content-between mb-9">
              <div class="mb-3 mb-sm-0">
                <h5 class="card-title fw-semibold mb-2">LevelOne</h5>
              </div>
            </div>
            <div id="bar-chart"></div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 d-flex align-items-strech">
        <div class="card w-100">
          <div class="card-body">
            <div class="d-flex align-items-center justify-content-center">
              <div id="pie-donut"></div>
            </div>
          </div>

        </div>


      </div>
    </div>
    <div id="dataTable" style="display: none; margin-top: 40px;"> <!-- Added margin-top for spacing -->
      <h5 class="fw-semibold">Bar Data</h5>
      <!-- Table will be dynamically updated -->
    </div>
    <div class="row row-cards row-deck" *ngIf="!Isplayer">

      <!-- Top Performers Section -->
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="d-sm-flex justify-content-between align-items-center mb-2">
              <h4 class="card-title fw-semibold">{{header}} Performers</h4>
              <div>
                <div class="select-wrapper">
                  <i class="bx bx-cog select-icon"></i>
                  <select [(ngModel)]="selectedStatus"  (change)="onStatusChange()" class="form-select">
                    <option selected disabled>Select Option</option>
                    <option value="0">Top Performers </option>
                    <option value="1">Under Performers</option>
                  </select>
                </div>
              </div>
            </div>

            <h4 class="m-3" *ngIf="chat">Athlete Data</h4>
            <div class="table-responsive">
              <table class="table table-hover table-outline table-vcenter text-nowrap card-table table-striped">
                <thead>
                  <tr>
                    <th>Profile</th>
                    <th>Athletes</th>
                    <th>Age</th>
                    <th>Overall %</th>
                    <th>Sport Name</th>
                    <th>Assessment Date</th>
                  </tr>
                </thead>
                <tbody *ngIf="Performers && Performers.length > 0; else noData">
                  <tr *ngFor="let performer of Performers">
                    <td class="text-center">
                      <img class="avatar"
                  src="{{performer.image}}">
                    </td>
                    <td>
                      <div *ngIf="Admin || OrgAdmin">{{ performer.usrFullName }}</div>
                      <div class="small text-muted">ID: {{ performer.loaAthlete }}</div>
                    </td>
                    <td>{{performer.aggAge}}</td>
                    <td>
                      <div class="clearfix">
                        <div class="float-left"><strong>{{ performer.loaAverageRange }}%</strong></div>
                      </div>
                      <div class="progress progress-xs">
                        <div class="progress-bar" [ngClass]="{
                              'bg-green': performer.loaAverageRange >= 80,
                              'bg-yellow': performer.loaAverageRange >= 50 && performer.loaAverageRange < 80,
                              'bg-red': performer.loaAverageRange < 50
                            }" role="progressbar" [style.width.%]="performer.loaAverageRange"></div>
                      </div>
                    </td>
                    <td>{{ performer.sportName }}</td>
                    <td>{{ performer.loaAsessmentDate | date:'dd/MM/yyyy' }}</td>
                  </tr>
                </tbody>
                <!-- Template for when there is no data -->
                <ng-template #noData>
                  <tr>
                    <td colspan="8" class="text-center">Data not available</td>
                  </tr>
                </ng-template>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Image Modal -->
    <div class="modal fade" id="imageModal" tabindex="-1" role="dialog" aria-labelledby="imageModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="imageModalLabel">Profile Image</h5>
            <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button> -->
          </div>
          <div class="modal-body text-center">
            <img [src]="selectedImageUrl" class="img-fluid" alt="Profile Image">
          </div>
        </div>
      </div>
    </div>
      </div>
    </div>


