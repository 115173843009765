<div *ngIf="isLoading" class="loading-spinner">
  <div class="spinner"></div>
</div>
<div class="body-wrapper">
  <div class="row flex-grow">
    <div class="col-12 grid-margin stretch-card">
      <div class="card card-rounded">
        <div class="card-body">
          <button class="btn btn-primary mb-2" (click)="onBack()">Back</button>
          <div class="d-sm-flex justify-content-between align-items-start">
            
            <div class="asm-title">
             
              <h4 class="card-title card-title-dash">{{this.test[0].asmName}}</h4>
              <!-- <p class="card-subtitle card-subtitle-dash">{{this.test[0].asmDescription}}</p> -->
            </div>
            <div class="container mt-2">
              <div class="container-custom">
                  <div class="card-custom">
                      <div class="icon-wrapper">
                          <i class="fa fa-building"></i>
                      </div>
                      <span class="title">Organization : {{this.test[0].orgName}}</span>
                  </div>
                  <div class="card-custom">
                      <div class="icon-wrapper">
                          <i class="fa fa-users"></i>
                      </div>
                      <span class="title">Team : {{this.test[0].teamName}}</span>
                  </div>
              </div>
          </div>
          </div>
          <div class="scrollable-div">
            <p class="card-subtitle card-subtitle-dash">{{this.test[0].asmDescription}}</p>
            </div>
          <div class="container-fluid">
            <!-- <div *ngFor="let group of groupedData | keyvalue">
                <h5>{{ group.key }}</h5> 
                <div *ngFor="let assessment of group.value">
               
                  <p>{{ assessment.testName }} <span style="font-size: 10px;"> {{ assessment.testUnit }}</span></p>
                </div>
              </div> -->
            <div class="content" style="width: 100%; height: 100%;">
              <!-- The AG Grid component, with Dimensions, CSS Theme, Row Data, and Column Definition -->
              <ag-grid-angular class="ag-theme-quartz" style="height: 500px;" [rowData]="rowData" [columnDefs]="colDefs"
                [defaultColDef]="defaultColDef" [pagination]="true" />
            </div>
            <div class="table-responsivee">
              <table class="table table-hover table-outline table-vcenter text-nowrap card-table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Sub Category</th>
                    <th scope="col">Tests</th>
                    <th scope="col">Assessor</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let group of groupedData | keyvalue">
                    <!-- Group key as Category -->
                    <th scope="row" style="color:black">{{ group.key }}</th>
                    <td>
                      <div *ngFor="let assessment of group.value">
                        {{ assessment.testName }} <span style="font-size: 10px;">{{ assessment.testUnit }}</span>
                      </div>
                    </td>
                    <td>
                      {{ group.value[0]?.assesserName}}
                    </td>
                  </tr>
                </tbody>
                <!-- Template for when there is no data -->
                <ng-template #noData>
                  <tr>
                    <td colspan="8" class="text-center">Data not available</td>
                  </tr>
                </ng-template>
              </table>
            </div>


          </div>
        </div>
      </div>
    </div>
  </div>