<div class="body-wrapper">
  <div class="d-flex justify-content-between mb-3">
    <!-- <div>
        <button class="btn btn-primary" (click)="navigateToNextPage()">Back</button>
    </div> -->
    <div>
      <button class="btn btn-primary">Download</button>
    </div>
  </div>
  <header>
    <div class="headerSection">
 
      <div class="logoAndName">
        <svg>
          <circle cx="50%" cy="50%" r="40%" stroke="black" stroke-width="3" fill="black" />
        </svg>
        <h1>AMS &amp; Sports</h1>
      </div>
      <!-- <div>
            <img *ngIf="showImage && ImageUrl"  src="{{this.ImageUrl.changingThisBreaksApplicationSecurity}}">
        </div> -->
      <div class="invoiceDetails">
        <h2>PLAYER</h2>
      </div>
      <!-- <div class="image">
          <img src="./profileid.jpg" alt="image">
        </div> -->
    </div>
    <hr />
    <div class="container">
      <h4 class="my-4">Test Results. <small class="text-muted">lorem ipsum dolar sit</small></h4>
      <div class="row">
        <div class="col-lg-3 col-md-6 col-sm-6 mb-4">
          <div class="category-trend-card">
            <h5>Physical Fitness</h5>
            <p>{{fitnesspercentage}}%</p>
            <div class="card-chart">
              <canvas id="pie-chart" height="80"></canvas>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6 mb-4">
          <div class="category-trend-card">
            <h5>Nutrition</h5>
            <p>43%</p>
            <div class="card-chart">
              <canvas id="balance-chart-1" height="80"></canvas>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6 mb-4">
          <div class="category-trend-card">
            <h5>Cardio</h5>
            <p>35.7%</p>
            <div class="card-chart">
              <canvas id="balance-chart-2" height="80"></canvas>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6 mb-4">
          <div class="category-trend-card">
            <h5>Cognitive</h5>
            <p>58.7%</p>
            <div class="card-chart">
              <canvas id="balance-chart-2" height="80"></canvas>
            </div>
          </div>
        </div>
      </div>
    </div>
 
  </header>
 
  <main>
    <div class="container-fluid">
      <div class="row">
        <div class="col-xl-12">
          <div class="row">
            <div class="col-xl-6">
              <div class="row">
                <div class="col-xl-12">
                  <div class="card">
                    <div class="card-body">
                      <div class="row d-flex align-items-center">
                        <div class="col-xl-6 col-sm-6">
 
                          <div class="items">
                            <h4 class="mb-4">Overall data heading here</h4>
                            <span class="fs-14 font-w400">Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                              accusantium doloremque </span>
                          </div>
 
 
                        </div>
                        <div class="col-xl-6 redial col-sm-6">
                          <div class="progress-circle" style="--percentage: 75;">
                            75%
                          </div>
 
                          <span class="text-center d-block fs-18 font-w600">Overall <small
                              class="text-success">70%</small></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
 
              </div>
            </div>
            <div class="col-xl-6">
              <div class="row">
                <div class="col-xl-12">
                  <div class="card">
                    <div class="card-body">
                      <div class="row d-flex align-items-center">
                        <div class="col-xl-6 col-sm-6">
 
                          <div class="items">
                            <h4 class="fs-20 font-w700 mb-4">Overall data heading here</h4>
                            <span class="fs-14 font-w400">Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                              accusantium doloremque </span>
                          </div>
 
 
                        </div>
                        <div class="col-xl-6 redial col-sm-6">
                          <div class="progress-circle" style="--percentage: 75;">
                            75%
                          </div>
                          <span class="text-center d-block fs-18 font-w600">Overall <small
                              class="text-success">70%</small></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
 
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12">
          <div class="row">
            <div class="col-xl-12">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex flex-column align-items-center justify-content-center">
                    <h4>PhysicalFitness Results <small class="text-muted"></small></h4>
 
                    <div class="row w-100 justify-content-between">
                      <div class="col-lg-3 mb-4">
                        <div class="inner-trend-card">
                          <h5>Agility test</h5>
                          <p>{{agilityPercentage}}%</p>
                          <div id="chart1" class="chart"></div>
                        </div>
                      </div>
 
                      <div class="col-lg-3 mb-4">
                        <div class="inner-trend-card">
                          <h5>Endurance</h5>
                          <p>{{endurancePercentage}}%</p>
                          <div id="chart2" class="chart"></div>
                        </div>
                      </div>
 
                      <div class="col-lg-3 mb-4">
                        <div class="inner-trend-card">
                          <h5>Speed test</h5>
                          <p>{{speedPercentage}}%</p>
                          <div id="chart3" class="chart"></div>
                        </div>
                      </div>
 
                      <div class="col-lg-3 mb-4">
                        <div class="inner-trend-card">
                          <h5>Reaction Time</h5>
                          <p>{{reactionTimePercentage}}%</p>
                          <div id="chart4" class="chart"></div>
                        </div>
                      </div>
 
                    </div> <!-- End of row -->
                  </div> <!-- End of flex column -->
                </div> <!-- End of card-body -->
              </div> <!-- End of card -->
            </div> <!-- End of col-xl-12 -->
          </div> <!-- End of row -->
 
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12">
          <div class="row">
            <div class="col-xl-12">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex flex-column align-items-center justify-content-center">
                    <h4>Nutrition Results <small class="text-muted"></small></h4>
 
                    <div class="row w-100 justify-content-between">
                      <div class="col-lg-3 mb-4">
                        <div class="inner-trend-card">
                          <h5>IronLevels</h5>
                          <p>{{ironLevelsPercentage}}%</p>
                          <div id="chart1" class="chart"></div>
                        </div>
                      </div>
 
                      <div class="col-lg-3 mb-4">
                        <div class="inner-trend-card">
                          <h5>Vitamin B12 Level</h5>
                          <p>{{vitaminB12LevelPercentage}}%</p>
                          <div id="chart2" class="chart"></div>
                        </div>
                      </div>
 
                      <div class="col-lg-3 mb-4">
                        <div class="inner-trend-card">
                          <h5>VitaminDLevel</h5>
                          <p>{{vitaminDLevelPercentage}}%</p>
                          <div id="chart3" class="chart"></div>
                        </div>
                      </div>
 
                      <div class="col-lg-3 mb-4">
                        <div class="inner-trend-card">
                          <h5>BMI</h5>
                          <p>{{bmiPercentage}}%</p>
                          <div id="chart4" class="chart"></div>
                        </div>
                      </div>
                    </div> <!-- End of row -->
                  </div> <!-- End of flex column -->
                </div> <!-- End of card-body -->
              </div> <!-- End of card -->
            </div> <!-- End of col-xl-12 -->
          </div> <!-- End of row -->
 
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12">
          <div class="row">
            <div class="col-xl-12">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex flex-column align-items-center justify-content-center">
                    <h4>Cardio<small class="text-muted"></small></h4>
                    <div class="row w-100 justify-content-between">
                      <div class="col-lg-3 mb-4">
                        <div class="inner-trend-card">
                          <h5>IronLevels</h5>
                          <p>+{{this.avgZPercent}}%</p>
                          <div id="chart1" class="chart"></div>
                        </div>
                      </div>
                      <div class="col-lg-3 mb-4">
                        <div class="inner-trend-card">
                          <h5>Vitamin B12 Level</h5>
                          <p>+88.7%</p>
                          <div id="chart2" class="chart"></div>
                        </div>
                      </div>
                      <div class="col-lg-3 mb-4">
                        <div class="inner-trend-card">
                          <h5>VitaminDLevel</h5>
                          <p>+56.7%</p>
                          <div id="chart3" class="chart"></div>
                        </div>
                      </div>
                      <div class="col-lg-3 mb-4">
                        <div class="inner-trend-card">
                          <h5>BMI</h5>
                          <p>+68.7%</p>
                          <div id="chart4" class="chart"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12">
          <div class="row">
            <div class="col-xl-12">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex flex-column align-items-center justify-content-center">
                    <h4>Cognitive<small class="text-muted"></small></h4>
 
                    <div class="row w-100 justify-content-between">
                      <div class="col-lg-3 mb-4">
                        <div class="inner-trend-card">
                          <h5>ProcessingSpeed</h5>
                          <p>+{{this.avgZPercent}}%</p>
                          <div id="chart1" class="chart"></div>
                        </div>
                      </div>
 
                      <div class="col-lg-3 mb-4">
                        <div class="inner-trend-card">
                          <h5>LanguageSkills</h5>
                          <p>+88.7%</p>
                          <div id="chart2" class="chart"></div>
                        </div>
                      </div>
 
                      <div class="col-lg-3 mb-4">
                        <div class="inner-trend-card">
                          <h5>DecisionMaking</h5>
                          <p>+56.7%</p>
                          <div id="chart3" class="chart"></div>
                        </div>
                      </div>
 
                      <div class="col-lg-3 mb-4">
                        <div class="inner-trend-card">
                          <h5>ProblemSolving</h5>
                          <p>+68.7%</p>
                          <div id="chart4" class="chart"></div>
                        </div>
                      </div>
                    </div> <!-- End of row -->
                  </div> <!-- End of flex column -->
                </div> <!-- End of card-body -->
              </div> <!-- End of card -->
            </div> <!-- End of col-xl-12 -->
          </div> <!-- End of row -->
 
        </div>
      </div>
    </div>
  </main>
  <aside>
  </aside>
 
</div>