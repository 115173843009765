<div class="container">
    <div class="container1">
        <header>Sports Performance Analysis</header><br>
       
        <form (ngSubmit)="onSubmit()" #spaForm="ngForm">

            <span class="title">Isokinetic Dynamometer</span>
            <span class="subtitle">Concentric (Right)</span>
            <div class="details personal">
             
                <img *ngIf="showImage && ImageUrl" src="{{ImageUrl}}">
               
                <div class="fields">
                    <div class="input-field">
                        <label>Player Id<span style="color: red;">*</span></label>
                        <input type="number" placeholder="Enter Id" required [disabled]="this.UserId" name="name"
                        [(ngModel)]="assessment.spaAthlete" #spaAthlete="ngModel" (blur)="getProfileImage()" >
                    </div>
                </div>
            
                <div class="fields">
                    <div class="input-field">
                        <label>Average Torque</label>
                        <input type="text" name="spaAverageTorqueCR" [(ngModel)]="assessment.spaAverageTorqueCR" placeholder="Enter Average Torque" required>
                    </div>
                    <div class="input-field">
                        <label>Peak Torque</label>
                        <input type="text" name="spaPeakTorqueCR" [(ngModel)]="assessment.spaPeakTorqueCR" placeholder="Enter Peak Torque" required>
                    </div>
                    <div class="input-field">
                        <label>Assessment Date <span style="color: red;">*</span></label>
                        <input type="date" placeholder="Enter Assessment Date" required name="spaDate" [(ngModel)]="assessment.spaDate" #spaDate="ngModel">
                    </div>
                </div>
            </div>
            <span class="subtitle">Concentric (Left)</span>
            <div class="details personal">
                <div class="fields">
                    <div class="input-field">
                        <label>Average Torque</label>
                        <input type="text" name="spaAverageTorqueCL" [(ngModel)]="assessment.spaAverageTorqueCL" placeholder="Enter Average Torque" required>
                    </div>
                    <div class="input-field">
                        <label>Peak Torque</label>
                        <input type="text" name="spaPeakTorqueCL" [(ngModel)]="assessment.spaPeakTorqueCL" placeholder="Enter Peak Torque" required>
                    </div>
                </div>
            </div>
            
            <span class="subtitle">Isometrics (Right)</span>
            <div class="details personal">
                <div class="fields">
                    <div class="input-field">
                        <label>Average Torque</label>
                        <input type="text" name="spaAverageTorqueIR" [(ngModel)]="assessment.spaAverageTorqueIR" placeholder="Enter Average Torque" required>
                    </div>
                    <div class="input-field">
                        <label>Peak Torque</label>
                        <input type="text" name="spaPeakTorqueIR" [(ngModel)]="assessment.spaPeakTorqueIR" placeholder="Enter Peak Torque" required>
                    </div>
                </div>
            </div>

            <span class="subtitle">Isometrics (Left)</span>
            <div class="details personal">
                <div class="fields">
                    <div class="input-field">
                        <label>Average Torque</label>
                        <input type="text" name="spaAverageTorqueIL" [(ngModel)]="assessment.spaAverageTorqueIL" placeholder="Enter Average Torque" required>
                    </div>
                    <div class="input-field">
                        <label>Peak Torque</label>
                        <input type="text" name="spaPeakTorqueIL" [(ngModel)]="assessment.spaPeakTorqueIL" placeholder="Enter Peak Torque" required>
                    </div>
                </div>
            </div>

            <div class="details ID">
                <span class="title">Force Plate (CMJ)</span>
                <div class="fields">
                    <div class="input-field">
                        <label>Jump Height (cm)</label>
                        <input type="number" name="spaJumpHeight" [(ngModel)]="assessment.spaJumpHeight" placeholder="Enter Jump Height" required>
                    </div>
                    <div class="input-field">
                        <label>Flight Time (ms)</label>
                        <input type="number" name="spaFlightTime" [(ngModel)]="assessment.spaFlightTime" placeholder="Enter Flight Time" required>
                    </div>
                    <div class="input-field">
                        <label>Velocity (m/s)</label>
                        <input type="number" name="spaVelocity" [(ngModel)]="assessment.spaVelocity" placeholder="Enter Velocity" required>
                    </div>
                    <div class="input-field">
                        <label>Force (N)</label>
                        <input type="number" name="spaForce" [(ngModel)]="assessment.spaForce" placeholder="Enter Force" required>
                    </div>
                    <div class="input-field">
                        <label>Power (W)</label>
                        <input type="number" name="spaPower" [(ngModel)]="assessment.spaPower" placeholder="Enter Power" required>
                    </div>
                </div>
            </div>

            <div class="main">
                <button type="submit" [disabled]="spaForm.invalid">Submit</button>
            </div>
        </form>
    </div>
</div>

<div class="toaster">
    <ng-toast></ng-toast>        
</div>
