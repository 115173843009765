import { AgGridAngular } from "ag-grid-angular";
import { ColDef } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { Component } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ActivatedRoute, Router, RouterLink, RouterModule, RouterOutlet } from "@angular/router";
import { AssessmentService } from "../../../services/assessment.service";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

@Component({
  selector: 'app-physiology-detail',
  standalone: true,
  imports: [CommonModule,RouterModule,RouterLink,AgGridAngular],
  templateUrl: './physiology-detail.component.html',
  styleUrl: './physiology-detail.component.css'
})
export class PhysiologyDetailComponent {
Id: any;
  player: any;

constructor(private router: Router, private route: ActivatedRoute,private assessmentService: AssessmentService) {
    this.route.queryParams.subscribe(params => {
      this.Id = params['id'];
      console.log(this.Id,"ID");
    });
  }
  ngOnInit() {
    if(this.Id){
    this.assessmentService.fetchAtletePhysiology(this.Id).subscribe((res: any) => {
      this.player=res;
      console.log(res,"userdata")

  }, error => {
    console.error('Error getting details ', error);
  });
}
  }
  downloadPDF(): void {
    const DATA = document.getElementById('pdf-content');
    if (DATA) {
      html2canvas(DATA).then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgProps = pdf.getImageProperties(imgData);
        const imgWidth = pdfWidth * 0.8; // 80% of the page width
        const imgHeight = (imgProps.height * imgWidth) / imgProps.width;
        const margin = (pdfWidth - imgWidth) / 2;
        const topMargin = 20; // Add top margin
 
        pdf.addImage(imgData, 'PNG', margin, topMargin, imgWidth, imgHeight);
        pdf.save('Physiology-details.pdf');
      });
    }
  }
}
