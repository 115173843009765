import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { AssessmentService } from '../../../services/assessment.service';
import { Location } from '@angular/common';
import { Privilege } from '../../../model/Privillage';
import { SharedService } from '../../../services/shared.service';
import { PrivilegeService } from '../../../services/privilage.service';

@Component({
  selector: 'app-injury-prevention-analysis-details',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './injury-prevention-analysis-details.component.html',
  styleUrl: './injury-prevention-analysis-details.component.css'
})
export class InjuryPreventionAnalysisDetailsComponent {

  id: any;
  athleteReport: any;
  athleteId: any;
  privilege: Privilege;
  IsAdmin: boolean = false;
  isScienceStaff: boolean = false;
  role: any;
  detail: any;
  userId: any;
  selectdata: any;
  ipaActiveSLR: any;
  ipaDeepSquat: any;
  ipaHurdleStep: any;
  ipaInlineLunge: any;
  athleteFullName: any;
  date: any;
  assessmentDate: any;
  ipaPushUp: any;
  ipaRotaryStability: any;
  ipaShoulderMobility: any;
  
  constructor(private router: Router,
     private route: ActivatedRoute,
     private assessmentService: AssessmentService,private location: Location,
     private sharedService: SharedService,private priService:PrivilegeService) {
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
      this.athleteId = params['athleteId'];
    });
    this.privilege = this.priService.getPrivileges();
    this.IsAdmin = this.privilege.isAdmin;
    this.isScienceStaff=this.privilege.isScienceStaff
  }
 
  activities = [
    { name: 'Sports Activity', percentage: 82 },
    { name: 'Technique Analysis', percentage: 42 },
    { name: 'Performance Analysis', percentage: 100 }
  ];
 
  // Dynamically return the color class based on percentage
  getProgressColor(percentage: number): string {
    if (percentage >= 75) {
      return 'bg-green';
    } else if (percentage >= 40) {
      return 'bg-yellow';
    } else {
      return 'bg-red';
    }
  }
 
  ngOnInit(): void {
    this.sharedService.detail$.subscribe((data: any) => {
      this.detail = data;
      this.role = this.detail.user.usrRole;
      this.userId = this.detail.user.usrId;
    });
  
    const getAthleteReport = (this.IsAdmin || this.isScienceStaff ) 
      ? this.assessmentService.getInjuryByTest(this.id)
      : this.assessmentService.getInjuryByTestAssessor(this.id);
  
    getAthleteReport.subscribe((response: any) => {
      this.athleteReport = response;
      this.selectdata = response.filter((assessment: any) =>
        (this.IsAdmin || this.isScienceStaff )  ? assessment.athlete == this.athleteId : assessment.athlete == this.athleteId && assessment.assessorId == this.userId
      );
  
      this.selectdata.forEach((assessment: any) => {
        switch (assessment.testColumnName) {
          case "ipaActiveSLR":
            this.ipaActiveSLR = assessment.matchedColumnData;
            break;
          case "ipaDeepSquat":
            this.ipaDeepSquat = assessment.matchedColumnData;
            break;
          case "ipaHurdleStep":
            this.ipaHurdleStep = assessment.matchedColumnData;
            break;
          case "ipaInlineLunge":
            this.ipaInlineLunge = assessment.matchedColumnData;
            break;
          case "ipaPushUp":
            this.ipaPushUp = assessment.matchedColumnData;
            break;
          case "ipaRotaryStability":
            this.ipaRotaryStability = assessment.matchedColumnData;
            break;
          case "ipaShoulderMobility":
            this.ipaShoulderMobility = assessment.matchedColumnData;
            break;
          }
      });
  
      // Assign common athlete information
      if (this.selectdata.length > 0) {
        const firstData = this.selectdata[0];
        this.athleteFullName = firstData.athleteFullName;
        this.date = firstData.date;
        this.assessmentDate = firstData.assessmentDate;
        this.athleteId = firstData.athlete;
      }
    });
  }
  
 
 
  downloadPDF(): void {
    const DATA = document.getElementById('pdf-content');
    if (DATA) {
      html2canvas(DATA).then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgProps = pdf.getImageProperties(imgData);
        const imgWidth = pdfWidth * 0.8; // 80% of the page width
        const imgHeight = (imgProps.height * imgWidth) / imgProps.width;
        const margin = (pdfWidth - imgWidth) / 2;
        const topMargin = 20; // Add top margin
 
        pdf.addImage(imgData, 'PNG', margin, topMargin, imgWidth, imgHeight);
        pdf.save('Cognitive-details.pdf');
      });
    }
  }

  onBack():void{
    this.location.back();
  }

}
