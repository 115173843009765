import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AssessmentService } from '../../../services/assessment.service';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-video-analysis-detail',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './video-analysis-detail.component.html',
  styleUrl: './video-analysis-detail.component.css'
})
export class VideoAnalysisDetailComponent implements OnInit{

  selectedDate: string = ''; // Holds the selected date from the date picker

  id: any;
  athleteReport: any;
  videoSrcs: any;
  videoSrc: string = '';
 
  constructor(private router: Router, private route: ActivatedRoute,private assessmentService: AssessmentService) {
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
      console.log(this.id,"ID");
    });
 
  }
 
  ngOnInit(): void {
    if(this.id){
    this.assessmentService.fetchAtleteVideoAnalysis(this.id).subscribe((response: any) => {
 
      this.athleteReport = response;

  
      if (response.length > 0 && response[0].vuVideo) {
        this.videoSrc = this.convertBase64ToBlobUrl(response[0].vuVideo);
      } else {
        this.videoSrc = ''; // Handle the case where there is no video
      }
    });
  }
  }

  downloadPDF(): void {
    const DATA = document.getElementById('pdf-content');
    if (DATA) {
      html2canvas(DATA).then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgProps = pdf.getImageProperties(imgData);
        const imgWidth = pdfWidth * 0.8; // 80% of the page width
        const imgHeight = (imgProps.height * imgWidth) / imgProps.width;
        const margin = (pdfWidth - imgWidth) / 2;
        const topMargin = 20; // Add top margin

        pdf.addImage(imgData, 'PNG', margin, topMargin, imgWidth, imgHeight);
        pdf.save('VideoAnalysis-details.pdf');
      });
    }
  }

  convertBase64ToBlobUrl(base64: string): string {
    try {
      if (!base64) {
        return '';
      }
      const byteCharacters = atob(base64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'video/mp4' }); // Ensure type matches your video format
      return URL.createObjectURL(blob);
    } catch (e) {
      console.error('Error converting base64 to Blob URL:', e);
      return '';
    }
  }
}
