<div class="container" [ngClass]="{'secActive': formSubmitted}">
  <header>Physiotherapy</header><br>
  <form (ngSubmit)="onSubmit()" #physiotherapyForm="ngForm">
    <div class="form first">
      <div class="details personal">
        <div class="fields">
          <div class="input-field">
            <label>Player Name</label>
            <input type="text" placeholder="Enter Player Name" required [(ngModel)]="assessment.ptaAthleteName"
              name="playerName" list="athleteList" (input)="fetchAthletes($event)"
              (change)="onAthleteSelected($event)" #playerName="ngModel" [ngClass]="{
                'invalid-field': physiotherapyForm.submitted && playerName.invalid,
                'has-value': assessment.ptaAthlete
              }">
            <datalist id="athleteList">
              <option *ngFor="let athlete of athletes" [value]="athlete.usrFullName"></option>
            </datalist>
            <div *ngIf="physiotherapyForm.submitted && playerName.invalid" class="error-message">
              <!-- Player Name error message -->
            </div>
          </div>

        </div><br>
        <div class="fields">
          <!-- <div class="input-field">
            <label>Assessor's Name</label>
            <input type="text" placeholder="Enter assessor's name" required [(ngModel)]="assessment.ptaAssessorName"
              name="assessorName" #assessorName="ngModel" [ngClass]="{
                  'invalid-field': physiotherapyForm.submitted && assessorName.invalid,
                  'has-value': assessment.ptaAssessor
                }">
            <div *ngIf="physiotherapyForm.submitted && assessorName.invalid" class="error-message">
              
            </div>
          </div> -->
          <div class="input-field">
            <label>Date of Assessment</label>
            <input type="date" placeholder="Enter date of assessment" required [(ngModel)]="assessment.ptaDate"
              name="dateOfAssessment" #dateOfAssessment="ngModel" [ngClass]="{
                  'invalid-field': physiotherapyForm.submitted && dateOfAssessment.invalid,
                  'has-value': assessment.ptaDate
                }">
            <div *ngIf="physiotherapyForm.submitted && dateOfAssessment.invalid" class="error-message">
              <!-- Date of Assessment error message -->
            </div>
          </div>
          
          <div class="input-field">
            <label>Injury History</label>
            <input type="text" placeholder="Enter injury history" required [(ngModel)]="assessment.ptaInjuryHistory"
              name="injuryHistory" #injuryHistory="ngModel" [ngClass]="{
                  'invalid-field': physiotherapyForm.submitted && injuryHistory.invalid,
                  'has-value': assessment.ptaInjuryHistory
                }">
            <div *ngIf="physiotherapyForm.submitted && injuryHistory.invalid" class="error-message">
              <!-- Injury History error message -->
            </div>
          </div>
        </div>
        <div class="fields">
         
          <div class="input-field">
            <label>Current Pain Level</label>
            <input type="number" placeholder="Enter current pain level" required
              [(ngModel)]="assessment.ptaCurrentPainLevel" name="currentPainLevel" #currentPainLevel="ngModel"
              [ngClass]="{
                  'invalid-field': physiotherapyForm.submitted && currentPainLevel.invalid,
                  'has-value': assessment.ptaCurrentPainLevel
                }">
            <div *ngIf="physiotherapyForm.submitted && currentPainLevel.invalid" class="error-message">
              <!-- Current Pain Level error message -->
            </div>
          </div>
      
          <div class="input-field">
            <label>Treatment Plan</label>
            <textarea placeholder="Enter treatment plan" required [(ngModel)]="assessment.ptaTreatmentPlan"
              name="treatmentPlan" #treatmentPlan="ngModel" [ngClass]="{
                  'invalid-field': physiotherapyForm.submitted && treatmentPlan.invalid,
                  'has-value': assessment.ptaTreatmentPlan
                }"></textarea>
            <div *ngIf="physiotherapyForm.submitted && treatmentPlan.invalid" class="error-message">
              <!-- Treatment Plan error message -->
            </div>
          </div>
          <div class="input-field">
            <label>Follow-up Date</label>
            <input type="date" placeholder="Enter Follow-up Date" required [(ngModel)]="assessment.ptaFollowUp"
              name="followUpDate" #followUpDate="ngModel" [ngClass]="{
                  'invalid-field': physiotherapyForm.submitted && followUpDate.invalid,
                  'has-value': assessment.ptaFollowUp
                }">
            <div *ngIf="physiotherapyForm.submitted && followUpDate.invalid" class="error-message">
              <!-- Follow-up Date error message -->
            </div>
          </div>
        </div>

        <!-- Mobility Tests -->
        <div class="details">
          <span class="title">Mobility Tests</span>
          <div class="fields">
            <div class="input-field">
              <label>Range of Motion</label>
              <input type="number" placeholder="Enter range of motion" required [(ngModel)]="assessment.ptaRangeOfMotion"
                name="rangeOfMotion" #rangeOfMotion="ngModel" [ngClass]="{
                                  'invalid-field': physiotherapyForm.submitted && rangeOfMotion.invalid,
                                  'has-value': assessment.ptaRangeOfMotion
                                }">
              <div *ngIf="physiotherapyForm.submitted && rangeOfMotion.invalid" class="error-message">
                <!-- Range of Motion error message -->
              </div>
            </div>
            <div class="input-field">
              <label>Flexibility</label>
              <input type="number" placeholder="Enter flexibility" required [(ngModel)]="assessment.ptaFlexibility"
                name="flexibility" #flexibility="ngModel" [ngClass]="{
                    'invalid-field': physiotherapyForm.submitted && flexibility.invalid,
                    'has-value': assessment.ptaFlexibility
                  }">
              <div *ngIf="physiotherapyForm.submitted && flexibility.invalid" class="error-message">
                <!-- Flexibility error message -->
              </div>
            </div>
          </div>
        </div>

        <!-- Strength Tests -->
        <div class="details">
          <span class="title">Strength Tests</span>
          <div class="fields">
            <div class="input-field">
              <label>Muscle Strength</label>
              <input type="number" placeholder="Enter muscle strength" required
                [(ngModel)]="assessment.ptaMuscleStrength" name="muscleStrength" #muscleStrength="ngModel" [ngClass]="{
                    'invalid-field': physiotherapyForm.submitted && muscleStrength.invalid,
                    'has-value': assessment.ptaMuscleStrength
                  }">
              <div *ngIf="physiotherapyForm.submitted && muscleStrength.invalid" class="error-message">
                <!-- Muscle Strength error message -->
              </div>
            </div>
            <div class="input-field">
              <label>Balance</label>
              <input type="number" placeholder="Enter balance" required [(ngModel)]="assessment.ptaBalance"
                name="balance" #balance="ngModel" [ngClass]="{
                    'invalid-field': physiotherapyForm.submitted && balance.invalid,
                    'has-value': assessment.ptaBalance
                  }">
              <div *ngIf="physiotherapyForm.submitted && balance.invalid" class="error-message">
                <!-- Balance error message -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="buttons">
        <button type="submit" class="submit">
          <span class="btnText">Submit</span>
          <i class="uil uil-navigator"></i>
        </button>
      </div>
      
    </div>
  </form>
</div>

<div class="toaster">
  <ng-toast />
  </div>