import { Component } from '@angular/core';
import { AssessmentService } from '../../../services/assessment.service';
import { ActivatedRoute, Router, RouterLink, RouterOutlet } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgToastModule } from 'ng-angular-popup';
import { NotificationService } from '../../../services/Notifications.service';

@Component({
  selector: 'app-biomechanical-editform',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterOutlet, RouterLink, HttpClientModule,NgToastModule],
  templateUrl: './biomechanical-editform.component.html',
  styleUrl: './biomechanical-editform.component.css'
})
export class BiomechanicalEditformComponent {
  formSubmitted: boolean = false;
  personalDetailsFilled: boolean = false;
  physiotherapy: boolean = false;
  physiology: boolean = false;

  assessment: any = {
// bmaId: null,

bmaAssessor : '',   
bmaAthlete : '',
bmaDate : '',
bmaForceOutput : '',
bmaPowerOutput : '',
bmaSymmetry : '',
bmaImbalance : '',
bmaInterpreationResults : '',
bmaRecommendations : '',
bmaFollowupDate : '',
// bmaCreatedOn : '',
// bmaUpdatedBy : '',
// bmaUpdatedOn: ''
 }

  athletes: any[] = [];
  selectedAthleteId: string | null = null;
  id: any;


  constructor(private router: Router, private route: ActivatedRoute,private assessmentService: AssessmentService, private notify:NotificationService) {
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
      console.log(this.id,"ID");
    });
 
    this.FetchAthelete();
 
  }
 
  checkPersonalDetailsFilled(): void {
    const personalDetailsInputs = document.querySelectorAll(".form.first input");
    this.personalDetailsFilled = Array.from(personalDetailsInputs).every(input => {
      const inputElement = input as HTMLInputElement;
      return inputElement.value.trim() !== '';
    });
  }
 
Physiotherapy(){
  this.physiotherapy = true;
  this.physiology = false;
}
Physiology(){
  this.physiotherapy = false;
  this.physiology = true;
}
 
  next(): void {
    this.checkPersonalDetailsFilled();
    if (this.personalDetailsFilled) {
      console.log(this.personalDetailsFilled);
      this.formSubmitted = true;
    }
  }
 
  back(): void {
    this.formSubmitted = false;
  }

  onSubmit(): void {
    if (this.isFormValid()) {
      this.assessmentService.addBiomechanical([this.assessment]).subscribe(response => {
        console.log('Assessment submitted', response);
        this.formSubmitted = true;
        this.notify.success("data");
        setTimeout(() => {
          this.router.navigate(['biomachanicslist']);
        }, 1000);
      }, error => {
        console.error('Error submitting assessment', error);
 
      });
    } else {
      console.log('Form is not valid');
    }
  }
  isFormValid(): boolean {
    return Object.keys(this.assessment).every(key => {
      // Exclude 'OtherNutrientLevels' from validation
    
      return this.assessment[key] !== '' && this.assessment[key] !== null;
    });
  }
  fetchAthletes(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement) {
      const query = inputElement.value;
      this.assessmentService.getAthletesBM(query).subscribe((data: any[]) => {
        this.athletes = data;
      }, (error: any) => {
        console.error('Error fetching athletes', error);
      });
    }
  }
 
  onAthleteSelected(event: Event): void {
    const inputElement = event.target as HTMLInputElement | null;
    if (inputElement) {
      const selectedAthlete = this.athletes.find(athlete => athlete.usrFullName === inputElement.value);
      if (selectedAthlete) {
        this.selectedAthleteId = selectedAthlete.usrId;
      }
    }
  }
  FetchAthelete(){
    if(this.id){
    this.assessmentService.fetchAtheletesBMfitness(this.id).subscribe((response: any) => {
      if (response && response.length > 0) {
        console.log(response,'data');
        const data = response[0];
        this.assessment.bmaId = data.bmaId;
        this.assessment.bmaAssessorName = data.assessorName;
        this.assessment.bmaAthleteName = data.athleteName;
        this.assessment.bmaAssessor = data.bmaAssessor;
        this.assessment.bmaAthlete = data.bmaAthlete;
        this.assessment.bmaDate = data.bmaDate ? data.bmaDate.split('T')[0] : '';
        this.assessment.bmaForceOutput = data.bmaForceOutput;
        this.assessment.bmaPowerOutput = data.bmaPowerOutput;
        this.assessment.bmaSymmetry = data.bmaSymmetry;
        this.assessment.bmaImbalance = data.bmaImbalance;
        this.assessment.bmaInterpreationResults = data.bmaInterpreationResults;
        this.assessment.bmaRecommendations = data.bmaRecommendations;
        this.assessment.bmaFollowupDate = data.bmaFollowupDate ? data.bmaFollowupDate.split('T')[0] : '';
       
      }
    },
    (error: any) => {
      console.error('Error fetching assessment data', error);
    }
  );
}
  }
}
