import { Injectable } from '@angular/core';
import { NgToastService } from 'ng-angular-popup';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(private toast: NgToastService) { }
  success(message: string) {
    this.toast.success(message, "SUCCESS", 3000) // message with title and 5000ms duration
  }
  failed(message: string) {
    this.toast.danger(message, "Failed", 3000)
  }
  
}
