import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterLink, RouterOutlet } from '@angular/router';
import { NgToastModule } from 'ng-angular-popup';
import { AssessmentService } from '../../services/assessment.service';

@Component({
  selector: 'app-change-password',
  standalone: true,
  imports: [CommonModule,FormsModule,RouterOutlet,RouterLink,NgToastModule],
  templateUrl: './change-password.component.html',
  styleUrl: './change-password.component.css'
})
export class ChangePasswordComponent implements OnInit{
  email: any;
  password: any;
  newPassword: any;
  confirmPassword: any;
  changePwd: any | null;
  usrEmail: string | null;
  constructor(private assessmentService: AssessmentService) {
    this.usrEmail = localStorage.getItem("userEmail");
  }
  ngOnInit() {
    if (typeof window !== 'undefined' && typeof localStorage !== 'undefined') {
    this.changePwd = localStorage.getItem("changePassword");
    console.log(this.changePwd);
    }
  }

  changePasswords() {
    if (this.newPassword === this.confirmPassword) {
      // Logic to handle password change, such as calling a service to update the password
      this.assessmentService.changePassword(this.email,this.confirmPassword).subscribe(
        (response: any) => {
          console.log('Password changed successfully');
          localStorage.setItem("changePassword","false")
        }
      );
      
    } else {
      console.error('Passwords do not match');
      // Show error message to the user
    }
  }
}
