import { Component, NgZone } from '@angular/core';
import { AssessmentService } from '../../../services/assessment.service';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { ColDef } from 'ag-grid-community';
import { Router, RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AgGridAngular } from 'ag-grid-angular';

interface IRow {
  OrgId: string;
  OrgName:string;
}

@Component({
  selector: 'app-organization',
  standalone: true,
  imports: [CommonModule,RouterOutlet, RouterLink, RouterModule, AgGridAngular],
  templateUrl: './organization.component.html',
  styleUrl: './organization.component.css'
})

export class OrganizationComponent {
  defaultColDef: ColDef = {
    flex: 1,
    sortable: true,
    filter: true,
  };
  rowData: IRow[] = [];
  colDefs: ColDef[] = [];
  constructor(private assessmentService: AssessmentService,private ngZone: NgZone, private router: Router){
    }
  ngOnInit() {
    this.assessmentService.getOrgainsation().subscribe(
      (data) => {

    this.rowData = data.map((item:any) => ({
      orgId: `${item.orgId}`, 
      orgName:`${item.orgName}`
    }));

    this.colDefs = this.getColumnDefinitions();
  }, error => {
    console.error('Error submitting assessment', error);
  })
}

getColumnDefinitions(): ColDef[] {
  const columns: ColDef[] = [
    { field: 'orgId' },
    { field: 'orgName' },
    {
      headerName: 'Action',
      cellRenderer: (_params: any) => {
        const container = document.createElement('div');
        container.style.display = 'flex';
        container.style.justifyContent = 'space-around';

        // Edit button with edit icon
        
        const editButton = document.createElement('button');
        editButton.classList.add('btn');
        editButton.innerHTML = '<i class="fa fa-edit"></i>';
        editButton.addEventListener('click', () => {
          this.ngZone.run(() => {
          this.router.navigate(['/add-organization'], {queryParams: { id: _params.data.orgId ,orgName:_params.data.orgName} })
          })
        });

        // Delete button with delete icon
        // const deleteButton = document.createElement('button');
        // deleteButton.classList.add('btn');
        // deleteButton.innerHTML = '<i class="fa fa-trash"></i>';
        // deleteButton.addEventListener('click', () => {
        //   // Handle delete action
        // });
        container.appendChild(editButton);
        //container.appendChild(deleteButton);

        return container;
      },
      width: 120
    }
  ];


    return columns;
}

}
