<div class="body-wrapper">
    <div class="row flex-grow">
        <div class="col-12 grid-margin stretch-card">
            <div class="card card-rounded">
                <div class="card-body">
                    <div class="d-sm-flex justify-content-between align-items-start mb-4">
                        <div>
                            <h4 class="card-title card-title-dash">Create Team</h4>
                        </div>
                    </div>

                    <div class="d-flex justify-content-between align-items-center mb-4">
                        <div class="d-flex gap-4">
                            <select class="form-select" [(ngModel)]="selectedOrgFilter">
                                <option value="" disabled>Select Organization</option>
                                <option *ngFor="let org of organization" [value]="org.orgId">{{ org.orgName }}</option>
                            </select>

                            <input type="text" class="custom-input-height" [(ngModel)]="this.teamName"
                                placeholder="Enter Team Name" />

                            <select class="form-select" [(ngModel)]="selectedSportFilter">
                                <option value="" disabled>Select Sports</option>
                                <option *ngFor="let sport of sport" [value]="sport.sportId">{{ sport.sportName }}
                                </option>
                            </select>

                        </div>
                    </div>
                    <div class="buttons">
                        <button class="btn btn-primary" [routerLink]="['/team']">
                            <span class="btnText">Back</span>
                        </button> &nbsp;&nbsp;
                        <button type="submit" class="submit btn btn-primary" (click)="onSubmit()">
                            <span class="btnText">Submit</span>
                            <!-- <i class="uil uil-navigator"></i> -->
                        </button>
                         
                    </div>
                      
                    <br>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="toaster">
    <ng-toast />
</div>