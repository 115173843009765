import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink, RouterOutlet } from '@angular/router';
import { HttpClientModule } from '@angular/common/http'; 
import { AssessmentService } from '../../../services/assessment.service';
import { NotificationService } from '../../../services/Notifications.service';
import { NgToastModule } from 'ng-angular-popup';
import { DynamicColumnService } from '../../../services/dynamic-column.service';
import { ChangeDetectorRef } from '@angular/core';
import { SharedService } from '../../../services/shared.service';

@Component({
  selector: 'app-physiology-editform',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterOutlet, RouterLink, HttpClientModule, NgToastModule],
  templateUrl: './physiology-editform.component.html',
  styleUrls: ['./physiology-editform.component.css']
})
export class PhysiologyEditformComponent implements OnInit {
  columns: any[] = [];
  tableName: string = 'tblTest'; 
  assessment: any;
  form!: FormGroup; 

  constructor(
    private assessmentService: AssessmentService,
    private route: ActivatedRoute, 
    private sharedService: SharedService,
    private router: Router, 
    private notify: NotificationService,
    private dynamicColumnService: DynamicColumnService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.getColumnNames();
  }

  getColumnNames(): void {
    this.dynamicColumnService.getProcessedColumns(this.tableName).subscribe(
      (data: any) => {
        this.columns = data;
        this.initializeForm();
        console.log(this.columns, 'columns');
        this.cdr.detectChanges();
      },
      (error) => {
        console.error('Error fetching columns', error);
      }
    );
  }

  initializeForm(): void {
    const group: { [key: string]: FormControl } = {};

    this.columns.forEach(column => {
      // Adding required validation to each form control
      group[column.columnName] = new FormControl('', Validators.required);
    });

    this.form = new FormGroup(group);
  }

  isFieldInvalid(fieldName: string): boolean {
    const control = this.form.get(fieldName);
    return control !== null && control !== undefined && control.invalid && (control.dirty || control.touched);
  }
  getErrorMessage(fieldName: string): string {
    const control = this.form.get(fieldName);
    if (control?.hasError('required')) {
      return `${fieldName} is required`;
    }
    return '';
  }

  onSubmit(): void {
    if (this.form.valid) {
      const formData = this.form.value; // Get the form data
      this.dynamicColumnService.postFormData(this.tableName, formData).subscribe(
        response => {
          console.log('Data submitted successfully', response);
          this.notify.success("Form submitted successfully");
        },
        error => {
          console.error('Error submitting data', error);
        }
      );
    } else {
      console.error('Form is invalid');
      this.form.markAllAsTouched(); // Mark all fields as touched to show validation errors
    }
  }
}
