<!-- <div *ngIf="isLoading" class="loading-spinner">
    
    <div class="spinner"></div>
</div> -->
<div class="body-wrapper" [ngClass]="{ 'blurred': isLoading }">
    <div class="drop-items d-flex justify-content-end mb-3 gap-4 align-items-end">
        <div class="select-wrapper">
            <h4>Organization</h4>
            <select class="form-select" [(ngModel)]="selectedOrg" (change)="selectOrganisation()">
                <option value="0" selected>All</option>
                <option *ngFor="let organization of Orgainsation" [value]="organization.teamOrganization" 
                        [ngClass]="{'selected-option': selectedOrg === organization.teamOrganization}">
                  {{ organization.orgName }}
                </option>
              </select>
        </div>
        <div class="d-flex justify-content-end">
            <div class="select-wrapper">
                <h4>Team</h4>
                <select id="teamSelect" class="form-select" (change)="selectTeams()" [(ngModel)]="selectedTeamId" [disabled]="selectedOrg === '0'" [ngClass]="{'disabled-dropdown': selectedOrg === '0'}">
                    <option value="0">All Teams</option>
                    <option *ngFor="let team of teams" [value]="team.asmTeam">{{ team.teamName }}</option>
                  </select>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="row">
            <div class="col-sm-8 flex-column d-flex stretch-card">
                <div class="row">
                    <div class="col-lg-4 d-flex grid-margin stretch-card">
                        <div class="card player-diffrence-border">
                            <div class="card-body">
                                <h2 class="text-dark mb-2 font-weight-bold"> {{ overallCount }}</h2>
                                <h4 class="card-title mb-2">Overall Assessment</h4>

                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 d-flex grid-margin stretch-card">
                        <div class="card player-diffrence-border">
                            <div class="card-body">
                                <h2 class="text-dark mb-2 font-weight-bold"> {{ completedCount }}</h2>
                                <h4 class="card-title mb-2">Completed Assessment</h4>

                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 d-flex grid-margin stretch-card">
                        <div class="card player-diffrence-border">
                            <div class="card-body">
                                <h2 class="text-dark mb-2 font-weight-bold"> {{ pendingCount }}</h2>
                                <h4 class="card-title mb-2">Pending Assessment</h4>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="content" style="width: 100%; height: 100%;">
        <!-- The AG Grid component, with Dimensions, CSS Theme, Row Data, and Column Definition -->
        <ag-grid-angular class="ag-theme-quartz" style="height: 500px;" (gridReady)="onGridReady($event)"
            [rowData]="rowData" [columnDefs]="colDefs" [defaultColDef]="defaultColDef" [pagination]="true" />
    </div>
</div>