import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators'; // Import 'tap' for side effects
import { environment } from '../../environments/environment.prod';
import { AssessmentService } from './assessment.service';


@Injectable({
  providedIn: 'root'
})
export class SupportStaffService {
  private ssMap: Map<string, number> = new Map();

  constructor(private http: HttpClient,private assessmentService: AssessmentService) {}

  // Fetch support staff and store the data in a map and sessionStorage
  getSupportStaff(): Observable<any> {
    return this.assessmentService.getSupportStaff().pipe(
      tap(data => {
        // Convert array to a map with ssName as key and ssId as value
        this.ssMap = new Map(data.map((item: any) => [item.ssName, item.ssId]));
       
        // Store the map in sessionStorage for persistence
        sessionStorage.setItem('ssMap', JSON.stringify(Array.from(this.ssMap.entries())));
      })
    );
  }
  // Retrieve ssId by ssName from sessionStorage
  getSsIdBySsName(ssName: string): number | undefined {
    // Retrieve and parse the map from sessionStorage if needed
    if (!this.ssMap.size) {
      const storedMap = sessionStorage.getItem('ssMap');
      if (storedMap) {
        this.ssMap = new Map(JSON.parse(storedMap));
      }
    }

    // Return the ssId for the given ssName
    return this.ssMap.get(ssName);
  }
}
