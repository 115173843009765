import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Role } from '../model/Role';
import { environment } from '../../environments/environment.prod';
import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  
  constructor(private http: HttpClient, private sharedService: SharedService) { }

  private getAuthHeaders(): HttpHeaders {
    const token = this.sharedService.getToken();
    return new HttpHeaders({
      'Authorization': token ? `Bearer ${token}` : ''
    });
  }

  getRoles(): Observable<Role[]> {
    return this.http.get<Role[]>(`${environment.BaseUrl}${environment.getRole}`, { headers: this.getAuthHeaders() });
  }

  getRoleById(roleId: number): Observable<Role> {
    const url = `${environment.BaseUrl}${environment.getRole}${roleId}`;
    return this.http.get<Role>(url, { headers: this.getAuthHeaders() });
  }

  getCoachName(searchTerm: string = ''): Observable<any[]> {
    const params = new HttpParams().set('search', searchTerm);
    return this.http.get<any[]>(`${environment.BaseUrl}${environment.getcoachName}`, { headers: this.getAuthHeaders(), params });
  }
}
