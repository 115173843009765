import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root' // This means the service is available app-wide
})
export class SidebarService {
  // A BehaviorSubject holds the current state and allows subscribers to get the current value
  private isSidebarCollapsedSubject = new BehaviorSubject<boolean>(false);

  // Observable to subscribe to state changes
  isSidebarCollapsed$ = this.isSidebarCollapsedSubject.asObservable();

  constructor() { }

  // Method to toggle the sidebar's collapsed state
  toggleSidebar() {
    this.isSidebarCollapsedSubject.next(!this.isSidebarCollapsedSubject.value);
  }

  // Method to explicitly set the sidebar's state
  setSidebarState(isCollapsed: boolean) {
    this.isSidebarCollapsedSubject.next(isCollapsed);
  }
}
