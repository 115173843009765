<!-- <div class="footer-container" style="position: fixed; bottom: 0; width: 100%; background-color: #f8f9fa; padding: 10px; z-index: 999;">
    <div class="container-fluid">
        <div class="row text-muted">

            
            <div class="col-12 d-flex justify-content-between align-items-center" style="flex-wrap: nowrap;">
              
                <p class="mb-0 text-center w-100" style="flex: 1;">
                    &copy; 2024 Qwalton Pvt Ltd. All rights reserved.
                </p>

              
            </div>
        </div>
    </div>
</div> -->
<section id="copy-right" style="position: fixed; z-index: 999; bottom: 0; width: 100%;">
    <div class="copy-right-sec">  
      &copy; 2024  <a href="#">  Qwalton Pvt Ltd.</a>  All rights reserved.
  
  
    </div>
  
  </section>