<div class="body-wrapper">  
  <div class="row flex-grow mb-5">
    <div class="col-12 grid-margin stretch-card">
      <div class="card card-rounded">
        <div class="card-body">
          <div class="d-sm-flex justify-content-between align-items-start">
            <div>
              <h4 class="card-title card-title-dash">Overall Report</h4>
              <p class="card-subtitle card-subtitle-dash">Lorem Ipsum dolar sit</p>
            </div>
 
          </div>
 
          <div class="content" style="width: 100%; height: 100%;">
            <ag-grid-angular class="ag-theme-quartz" style="height: 350px;" [rowData]="rowData" [columnDefs]="colDefs"
                [defaultColDef]="defaultColDef" [pagination]="true" />
          </div>
 
 
 
        </div>
      </div>
    </div>
  </div>
 
   
     
  <!-- <div class="video-grid">
    <div *ngFor="let videoSrc of videoSrcs" class="video-item">
      <video width="250" height="240" controls>
        <source [src]="videoSrc" type="video/mp4">
        Your browser does not support the video tag.
      </video>
    </div>
 
 
 
  </div> -->
 
</div>