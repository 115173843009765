import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router, RouterLink, RouterModule} from '@angular/router';




@Component({
  selector: 'app-assessment-form',
  standalone: true,
  imports: [CommonModule,RouterModule,RouterLink],
  templateUrl: './assessment-form.component.html',
  styleUrl: './assessment-form.component.css'
})
export class AssessmentFormComponent implements OnInit, AfterViewInit {
 
  activeItem: string = '';
 
 
  runTimeOut: number = 0;
  runNextAuto: number = 0;
  roles: any;
  roleId: any;
 
 
  constructor(
   
    private router: Router,
 
  ) {
 
  }
 
  ngOnInit(): void {
    // Initialize your component here
 
  }
 
  ngAfterViewInit(): void {
    // Access the DOM elements here
 
    const carouselDom = document.querySelector('.carousel') as HTMLElement;
    const sliderDom = carouselDom.querySelector('.list') as HTMLElement;
    const thumbnailBorderDom = document.querySelector('.thumbnail') as HTMLElement;
    const thumbnailItemsDom = thumbnailBorderDom.querySelectorAll('.item') as NodeListOf<HTMLElement>;
 
 
    // Initialize the first thumbnail
    if (thumbnailItemsDom.length > 0) {
      thumbnailBorderDom.appendChild(thumbnailItemsDom[0]);
    }
 
 
 
    // Add click events for thumbnail items
    thumbnailItemsDom.forEach((thumbnailItem) => {
      thumbnailItem.onclick = () => {
        const targetId = thumbnailItem.getAttribute('data-target');
        if (targetId) {  // Ensure targetId is not null
          const targetItem = document.getElementById(targetId) as HTMLElement;
          this.moveToSlide(targetItem, sliderDom, carouselDom);
        }
      };
    });
  }
 
  showSlider(
    type: 'next' | 'prev',
    sliderDom: HTMLElement,
    thumbnailBorderDom: HTMLElement,
    carouselDom: HTMLElement,
  ): void {
    const sliderItemsDom = sliderDom.querySelectorAll('.item') as NodeListOf<HTMLElement>;
    const thumbnailItemsDom = document.querySelectorAll('.thumbnail .item') as NodeListOf<HTMLElement>;
 
 
  }
 
  moveToSlide(targetItem: HTMLElement, sliderDom: HTMLElement, carouselDom: HTMLElement): void {
    const allItems = Array.from(sliderDom.children) as HTMLElement[];
    const targetIndex = allItems.indexOf(targetItem);
 
    if (targetIndex > -1) {
      for (let i = 0; i < targetIndex; i++) {
        sliderDom.appendChild(allItems[i]);
      }
    }
 
 
 
    carouselDom.classList.add('next');
 
 
  }
  setSupport() {
    localStorage.setItem('isSupport', 'true');
    localStorage.setItem('isCoach', 'false');
 
  }
  setgeneral() {
    localStorage.setItem('isSupport', 'false');
    localStorage.setItem('isCoach', 'false');
  }
 
  setTrainer() {
    localStorage.setItem('isCoach', 'true');
    localStorage.setItem('isSupport', 'false');
 
  }
 
  setActive(item: string): void {
    this.activeItem = item;
  }
 
 
 
  navigateWithId(path: string) {
    this.router.navigate([path], { queryParams: { id: this.roleId } });
  }
 
}
 