import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ChangeDetectorRef, Component, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink, RouterOutlet } from '@angular/router';
import { NgToastModule } from 'ng-angular-popup';
import { MultiSelectDropdownComponent } from '../../assessment/multi-select-dropdown/multi-select-dropdown.component';
import { DomSanitizer } from '@angular/platform-browser';
import { AssessmentService } from '../../services/assessment.service';
import { NotificationService } from '../../services/Notifications.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { ColDef, GridOptions } from 'ag-grid-community';
import { AgGridAngular } from 'ag-grid-angular';
import { Observable, forkJoin } from 'rxjs';  // Correct import for forkJoin
import { concatMap } from 'rxjs/operators'; 
import { Location } from '@angular/common';

interface IRow {
  UserId: string;
  UserName: string;
  Role: string
}
interface AssessmentData {
  asmName: string;
  asmDescription: string;
  asmDate: Date;
  asmOrg: number;
  asmTeam: number;
  asmCoach: number;
  astId: number;
  astTest: number;
  astAssesser: number;
}
interface Test {
  testId: number;
  testName: string;
}

@Component({
  selector: 'app-assign-assessment',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    RouterOutlet,
    RouterLink,
    HttpClientModule,
    NgToastModule, AgGridAngular,
    MultiSelectDropdownComponent, NgSelectModule
  ],
  templateUrl: './assign-assessment.component.html',
  styleUrls: ['./assign-assessment.component.css']
})
export class AssignAssessmentComponent {
  Teams: any[] = [];  // Initialize as empty array
  filteredTeams: any[] = [];  // This will hold filtered teams
  Orgainsation: any[] = [];
  selectedOrgId: number = 0;
  assessmentId: number = 4;
  Assessment: any[] = [];
  Assessments: any[] = [];
  colDefs: ColDef[] = [];
  rowData: IRow[] = [];
  showCheckboxes: boolean = false;
  selectedTeamId: number = 0;
  selectedAssessmentId: number = 0;
  Test: any;
  selectedTests: any[] = [];  // Store selected test objects
  roleId: number = 8;
  Username: any;
  selectedAssessorId: number = 0;
  teamCoach: any;
  testdropdownOpen: boolean[] = [];
  selectedAssessments: any[] = [];
  assessmentName: any;
  assessmentDescription: any;
  assessmentDate: any;
  Categories: any;
  selectedCatId: any;
  filteredOrgainsation: any[] = [];
  Organisation: boolean = false;
  defaultColDef: ColDef = {
    flex: 1,
    sortable: true,
    filter: true,
  };
  gridOptions: GridOptions = {
    rowSelection: 'multiple', // Enable multi-row selection
    suppressRowClickSelection: true,
  };
  availableAssessors: any;
  selectedAssessor: any;
  Assessor: any;
  gridApi: any;
  selectedTest: any;
  selectedAssessment: any;
  filteredAssessment: any[] = [];
  filteredAssessor: any[] = [];
  filteredAssessores: any[] = [];
  assessment: any;
  category: any;
  loading: boolean = false;
  userId: number =1;
  asmId: any;
  catId: any;
  AssessmentData: any;
  filteredAssessmentName: any;

  constructor(
    private notify: NotificationService,
    private route: ActivatedRoute,
    private router: Router,
    private location:Location,
    private assessmentService: AssessmentService,private cdRef: ChangeDetectorRef,private ngZone: NgZone,
    private sanitizer: DomSanitizer, private fb: FormBuilder
  ) {
    this.route.queryParams.subscribe(params => {
      this.assessment = params['assessment'];
      this.category = params['sscategory'];
      this.selectedCatId = Number(this.category)
    });
  }

  ngOnInit() {
    this.loading = true; // Start loading
  
    // Fetch independent data in parallel (user and categories) using forkJoin
    forkJoin({
      user: this.getuser(),  // Now returning an Observable
      categories: this.getCategories()  // Now returning an Observable
    }).subscribe(
      (result) => {
        this.Assessor = result.user;  // Assign user data       
        this.Categories = result.categories;  // Assign categories data
        console.log("User and categories loaded:", result);
      },
      (error) => {
        console.error('Error fetching user/categories:', error);
      }
    );
  
    // Ensure sequential loading for dependent API calls
    this.assessmentService.getOrgainsation().pipe(
      concatMap((data: any) => {
        this.Orgainsation = data;
        return this.assessmentService.getSupportStaff();
      }),
      concatMap((supportStaff: any) => {
        this.Assessments = supportStaff;
        return this.assessmentService.getpendingAssessment();
      }),
      concatMap((pendingAssessments: any[]) => {
        this.AssessmentData=pendingAssessments;
        this.filteredAssessment = pendingAssessments.filter(response => response.asmName === this.assessment);
        this.selectedAssessor = this.filteredAssessment[0].astAssesser;
        this.selectedAssessment=this.filteredAssessment[0].testCategory;
        this.onCategoryChange();
        this.assignFilteredDataToForm(this.filteredAssessment);
        return this.assessmentService.getTeams();
      })
    ).subscribe(
      (teams: any[]) => {
        this.Teams = teams;  
        if (this.selectedOrgId) {
          this.filteredTeams = this.Teams.filter(team => team.teamOrganization == this.selectedOrgId);
        }
        
        this.loading = false;  // Stop loading after the sequence completes
      },
      (error) => {
        console.error('Error fetching data:', error);
        this.loading = false;  // Stop loading in case of error
      }
    );
  
    // Handle the case where `assessment` is undefined, and fetch teams if necessary
    if (this.assessment === undefined) {
      this.assessmentService.getTeams().subscribe(
        (teams: any[]) => {
          this.Teams = teams;
          this.loading = false;  // Stop loading after teams are fetched
        },
        (error) => {
          console.error('Error fetching teams:', error);
          this.loading = false;  // Stop loading in case of error
        }
      );
    }
  }
  
  onBack():void{
    this.location.back();
  }

  getCategories(): Observable<any> {
    // Return the observable instead of subscribing here
    return this.assessmentService.getcategories();
  }
  removeTest(assessmentIndex: any, testIndex: number): void {
    // Remove the selected test from the list
    this.selectedAssessments[assessmentIndex].selectedTest.splice(testIndex, 1);
  }


  onCategoryChange(): void {
    if (this.selectedCatId !== null) {
      // Filter the organisation data based on the selected category ID (sscategory)
      this.Assessment = this.Assessments.filter(
        cat => cat.sscategory == this.selectedCatId
      );
      this.selectedAssessment = this.filteredAssessment[0].testCategory   
      const newIndex = this.selectedAssessment.length - 1;

      this.getTests(this.selectedAssessment, newIndex);
      this.getTestdata();
    } else {
      // If no category is selected, clear the filtered list
      this.Assessment = [];
    }
  }
  AssessmentChange(event: any) {
    const test = event.target.value
    const selectedAsm = this.Assessment.filter((item: any) => item.ssId === Number(test))
    this.selectedAssessments.push({
      ssId: test,
      ssName: selectedAsm[0].ssName,
      roleId: selectedAsm[0].roleId,
      selectedTest: [],  // Initialize an empty array for tests
      selectedAssessor: '',  // Initialize empty assessor
      availableTests: [],   // Initialize empty available tests
      availableAssessors: [] // Initialize empty available assessors
    });
    const newIndex = this.selectedAssessment.length - 1;

    this.getTests(this.selectedAssessment, newIndex);
    this.getTestdata();
  }
  toggleTestOpen(index: number): void {
    this.testdropdownOpen[index] = !this.testdropdownOpen[index];
  }

  // Fetch tests by assessmentId
  getTests(ssId: any, index: number) {
    this.assessmentService.getTests(ssId).subscribe(
      (data) => {
        // Process the data for ssId = 5
        this.rowData = data.map((item: any) => ({
          testId: `${item.testId}`,
          "Test Name": `${item.testName}`,
          Assessor: item.assessor ? `${item.assessor}` : 'Not Assigned'
        }));
    
        this.showCheckboxes = true;
        this.Organisation = true;
        this.colDefs = this.getColumnDefinitions();
    
        // Check if ssId is 5, then proceed to fetch data for 12, 13, and 14
        if (ssId == 5) {
          const additionalSsIds = [12, 13, 14];
          const requests = additionalSsIds.map((id) => this.assessmentService.getTests(id));
    
          forkJoin(requests).subscribe(
            (responses) => {
              // Combine all the data from each response
              const combinedData = responses.reduce((acc: any[], data: any[]) => {
                const processedData = data.map((item: any) => ({
                  testId: `${item.testId}`,
                  "Test Name": `${item.testName}`,
                  Assessor: item.assessor ? `${item.assessor}` : 'Not Assigned'
                }));
                return acc.concat(processedData);
              }, []);
    
              // Append the additional data to the existing rowData
              this.rowData = this.rowData.concat(combinedData);
            },
            (error) => {
              console.error('Error fetching additional tests:', error);
            }
          );
        }
      },
      (error: any) => {
        console.error('Error fetching tests:', error);
      }
    );
  }

  onOptionChange(option: any, assessmentIndex: number): void {
    const selectedAssessment = this.selectedAssessments[assessmentIndex];

    // Check if the test is already selected
    const isSelected = selectedAssessment.selectedTest.some((selected: { testId: any }) => selected.testId === option.testId);

    if (isSelected) {
      // If selected, remove it from selectedTest
      selectedAssessment.selectedTest = selectedAssessment.selectedTest.filter(
        (selected: { testId: any }) => selected.testId !== option.testId
      );
    } else {
      // If not selected, add it to selectedTest
      selectedAssessment.selectedTest.push(option);
    }

    console.log('Selected tests for assessment', assessmentIndex, ':', selectedAssessment.selectedTest);
  }

  isSelected(option: any, assessmentIndex: number): boolean {
    return this.selectedAssessments[assessmentIndex].selectedTest.some(
      (selected: { testId: any }) => selected.testId === option.testId
    );
  }

  // Updated getuser method
  getuser(): Observable<any> {
    // Return the observable instead of subscribing here
    return this.assessmentService.getOrgTrainer(3);
  }

  isSelectedAssessment(test: any): boolean {
    return this.selectedAssessments.some(assessment => assessment.ssId === test.ssId);
  }
  filterTeamsByOrganization() {
    if (this.selectedOrgId) {
      this.filteredTeams = this.Teams.filter(team => team.teamOrganization == this.selectedOrgId);
    } else {
      this.filteredTeams = this.Teams;
    }
  }
  onTeamChange() {
    const selectedTeam = this.filteredTeams.find(team => team.teamId == this.selectedTeamId);
    if (selectedTeam) {
      this.teamCoach = selectedTeam.teamCoach;  // Store the teamCoach value
    } else {
      this.teamCoach = null;  // Reset if no team is selected
    }
  }

  onGridReady(params: any) {
    this.gridApi = params.api;

    // Listen to row selection events and update selectedPlayer array
    this.gridApi.addEventListener('rowSelected', (event: any) => {
      this.updateSelectedTest();
    });
  }
  updateSelectedTest() {
    // Get all selected nodes (rows) from the grid
    const selectedNodes = this.gridApi.getSelectedNodes();

    // Map selected rows to player data
    this.selectedTest = selectedNodes.map((node: any) => node.data);
  }
  onSubmit(form: any) {
    this.filteredAssessmentName = this.AssessmentData.filter((response: { asmName: any; }) => response.asmName === this.assessmentName);
    // if(this.filteredAssessmentName){
    //   this.ngZone.run(() => {
    //     this.notify.failed('Assessment Name Already Exist');
    //     this.cdRef.detectChanges();
    //   });
    // }else{
    if (form.valid) {
      const formData: AssessmentData[] = [];
      if (this.selectedTest && Array.isArray(this.selectedTest)) {
        this.selectedTest.forEach((test: Test) => {
          const data: AssessmentData = {
            asmName: this.assessmentName,
            asmDescription: this.assessmentDescription,
            asmDate: this.assessmentDate,
            asmOrg: this.selectedOrgId,
            asmTeam: this.selectedTeamId,
            asmCoach: this.teamCoach,
            astId: 0,  // Modify if needed
            astTest: test.testId,  // Ensure test.testId exists
            astAssesser: this.selectedAssessor // Ensure it's a number if required
          };
          formData.push(data);
        });
      } else {
        console.error('No selected tests for assessment:');
      }
      if (formData.length > 0) {
        this.assessmentService.assignAssignment(formData).subscribe(response => {
          this.notify.success("Form submitted successfully");
          this.assessment = this.assessmentName;
          const newIndex = this.selectedAssessment.length - 1;

          this.getTests(this.selectedAssessment, newIndex);
          this.getTestdata()
        }, error => {
          console.error('Error submitting assessment', error);
        });
      } else {
        console.log(this.filteredAssessment[0].asmId,"AsmId")
        this.asmId=this.filteredAssessment[0].asmId;
        this.assessmentService.updateAssessment(this.asmId,this.assessmentName,this.assessmentDescription,this.assessmentDate,this.userId).subscribe(response => {
          this.notify.success("Form submitted successfully");
          this.assessment = this.assessmentName;
          const newIndex = this.selectedAssessment.length - 1;

          this.getTests(this.selectedAssessment, newIndex);
          this.getTestdata()
        }, error => {
          this.notify.failed(error.error.message);
          console.error('Error submitting assessment', error);
        });

        // console.error('No valid formData to submit');
      }
    } else {
      this.ngZone.run(() => {
        this.notify.failed('Please provide all data');
        this.cdRef.detectChanges();
      });
    }
  //}
  }
  assignFilteredDataToForm(filteredAssessment: any[]) {
    // Assume you want to map the first result for example:
    console.log("filteredAssessment[0]:",filteredAssessment[0])
    if (filteredAssessment && filteredAssessment.length > 0) {
      const assessmentData = filteredAssessment[0]; // Get the first item or loop over all
      this.assessmentName = assessmentData.asmName,
        this.assessmentDescription = assessmentData.asmDescription,
        this.assessmentDate = assessmentData.asmDate ? assessmentData.asmDate.split('T')[0] : '',
        this.selectedOrgId = assessmentData.teamOrganization,
        this.selectedTeamId = assessmentData.asmTeam,
        this.selectedCatId = Number(this.category),
        //   asmCoach: assessmentData.coachName,
        this.selectedAssessor = assessmentData.astAssesser

    }
    // this.selectedAssessor = this.Assessor[0].usrId
  }
  getSelectedTestNames(assessmentIndex: number): string {
    const selectedAssessment = this.selectedAssessments[assessmentIndex];

    // Check if there are any selected tests
    if (selectedAssessment && selectedAssessment.selectedTest.length > 0) {
      // Map the selected tests to their names and join with commas
      return selectedAssessment.selectedTest.map((test: { testName: string }) => test.testName).join(', ');
    }

    return '';
  }
  getColumnDefinitions(): ColDef[] {
    const columns: ColDef[] = [];

    if (this.showCheckboxes) {
      columns.push({
        headerCheckboxSelection: true,
        checkboxSelection: true,
        width: 80,
        minWidth: 80,
        maxWidth: 80,
      });
    }

    columns.push(

      { field: "Test Name", headerName: "Test Name" },
      { field: "Assessor", headerName: "Assessor" },
    );

    return columns;
  }

  clearSelection() {
    if (this.gridApi) {
      this.gridApi.deselectAll();  // Deselect all rows in the grid
    }
  }

  getTestdata() {
    this.clearSelection();
    this.assessmentService.getpendingAssessment().subscribe(
      (response) => {
        // Filter assessments by asmName
        this.filteredAssessment = response.filter(response => response.asmName === this.assessment);
        this.filteredAssessor = this.filteredAssessment.filter(response => response.astAssesser == this.selectedAssessor);

        // Map testId to the corresponding assessor name for each assessment
        const assessorMapping: { [key: string]: string } = {};
        this.filteredAssessores = this.filteredAssessment.filter((response) => {
          return this.Assessor.some((assessor: any) => {
            if (assessor.usrId === response.astAssesser) {
              assessorMapping[response.testId] = assessor.usrFullName; // Store assessor name based on testId
              return true;
            }
            return false;
          });
        });

        const testData = this.filteredAssessor.map((test: any) => test.testId.toString());

        // Update Assessor name and selection in the grid
        this.gridApi.forEachNode((node: any) => {
          const rowUserId = node.data.testId.toString();
          if (assessorMapping[rowUserId]) {
            node.data.Assessor = assessorMapping[rowUserId];  // Set the correct assessor for each testId
          }
        });

        // Select rows based on filtered testData
        this.gridApi.forEachNode((node: any) => {
          const rowUserId = node.data.testId.toString();
          if (testData.includes(rowUserId)) {
            node.setSelected(true);
          }
        });

        this.colDefs = this.getColumnDefinitions();
      },
      (error) => {
        console.error('Error fetching players:', error);
      }
    );
  }

}