<!--  Main wrapper -->
<div *ngIf="isLoading" class="loading-spinner">
  <div class="spinner"></div>
</div>
<div>  
      <div class="row d-flex align-items-stretch">  
        <div class="col-sm-9 flex-column d-flex stretch-card">
       
          <div class="row">
            <div class="col-sm-12 grid-margin d-flex stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex align-items-center justify-content-between">
                    <h4 class="card-title mb-2">{{GraphHeader}} Performers</h4>
                    <!-- <div class="select-wrapper">
                      <select (change)="filterPerformers($event, 'overall')" class="form-select">
                        <option selected disabled>Select Option</option>
                        <option value="All">All</option>
                        <option value="excellent">Excellent</option>
                        <option value="good">Good</option>
                        <option value="average">Average</option>
                        <option value="poor">Poor</option>
                      </select>
                    </div> -->
                    <div class="dropdown">
                      <a href="#" class="text-success btn btn-link dropdown-toggle dropdown-arrow-none"
                        data-bs-toggle="dropdown" id="settingsDropdownsales">
                        <i class="mdi mdi-dots-horizontal"></i></a>
                        <div class="dropdown-menu dropdown-menu-right navbar-dropdown"
                        aria-labelledby="settingsDropdownsales">
                        <a class="dropdown-item"  (click)="filterPerformers('All', 'overall')">
                          <i class="mdi mdi-grease-pencil text-primary"></i>
                          All
                        </a>
                        <a class="dropdown-item" (click)="filterPerformers('excellent', 'overall')">
                          <i class="mdi mdi-grease-pencil text-primary"></i>
                          Excellent
                        </a>
                        <a class="dropdown-item" (click)="filterPerformers('good', 'overall')">
                          <i class="mdi mdi-grease-pencil text-primary"></i>
                          Good
                        </a>
                        <a class="dropdown-item" (click)="filterPerformers('average', 'overall')">
                          <i class="mdi mdi-grease-pencil text-primary"></i>
                          Average
                        </a>
                        <a class="dropdown-item" (click)="filterPerformers('poor', 'overall')">
                          <i class="mdi mdi-grease-pencil text-primary"></i>
                          Poor
                        </a>
                      </div>
                    </div>
                  </div>
                  <div id="overall-chart"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-3 flex-column d-flex stretch-card">
          <div class="">
            <div class="col-lg-12 d-flex grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex align-items-center justify-content-between mb-3">
                  <img src="../../../assets/image/icon1.png" class="img-fluid " alt="icon1">
               
                  <p class="pb-0">Players Count</p>
                </div>
                  <h2 class="font-weight-bold"> {{ overallCount }}</h2>
          
                  <button (click)="selectRole('Player', null)" class="btn btn-primary" *ngIf="Admin || OrgAdmin">Add New Player</button>
                </div>
              </div>
            </div>
            <div class="col-lg-12 d-flex grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex align-items-center justify-content-between mb-3">
                  <img src="../../../assets/image/icon2.png" class="img-fluid" alt="icon1">
                  <p class="pb-0 ">Overall Score</p>
                  </div>
                  <h2 class="font-weight-bold mb-3">{{ averageOverallPercentage }}%</h2>
                  <div class="progress">
                    <!-- Use Angular's property binding to set the width of the progress bar -->
                    <div class="progress-bar bg-secondary" role="progressbar" [style.width.%]="averageOverallPercentage"
                      [attr.aria-valuenow]="averageOverallPercentage" aria-valuemin="0" aria-valuemax="100">
                    </div>
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row row-cards row-deck">
         

        <!-- Top Performers Section -->
        <div class="col-sm-9">
          <div class="d-sm-flex justify-content-between align-items-center mb-2">
            <h4 class="card-title fw-semibold" >{{header}}</h4>
            <div>
              <!-- <div class="select-wrapper">
                <select [(ngModel)]="selectedStatus" (change)="onStatusChange()" class="form-select">
                  <option selected disabled>Select Option</option>
                  <option value="0">All</option>
                  <option value="1">Excellent</option>
                  <option value="2">Good</option>
                  <option value="3">Average</option>
                  <option value="4">Poor</option>
                  
                </select>
                <i class="fa fa-chevron-down"></i>
               
              </div> -->
              <div class="select-wrapper">
                <ng-select
                  class="ng-select-custom"
                  [(ngModel)]="selectedStatus"
                  (change)="onStatusChange()"
                  [searchable]="false"
                  [clearable]="false">
                  <ng-template ng-label-tmp>
                    <span>{{ performers }}</span>
                    <i class="fa fa-chevron-down"></i>
                  </ng-template>
                
                  <ng-option [value]="0">All</ng-option>
                  <ng-option [value]="1">Excellent</ng-option>
                  <ng-option [value]="2">Good</ng-option>
                  <ng-option [value]="3">Average</ng-option>
                  <ng-option [value]="4">Poor</ng-option>
                </ng-select>
              </div>
              
              
              
              
            </div>
          </div>
          <div class="card py-3">
            <h4 class="m-3" *ngIf="chat">Athlete Data</h4>
            <div class="table-responsive">
              <table class="table table-hover table-outline table-vcenter text-nowrap card-table">
                <thead>
                  <tr>
                   
                    <th>Athletes</th>
                    <th>Overall Score%</th>
                    <th>BMI (kg/m²)</th>
                    <th>GripLeft (Kgs)</th>
                    <th>GripRight (Kgs)</th>
                    <th>Lower Body Force (N)</th>
                    <th>Lower Body Power (W)</th>
                    <th>Upper Body Force (N)</th>
                    <th>Upper Body Power (W)</th>
                    <th>VO2 (ml/kg/min)</th>
                   
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let performer of topPerformers">
                    
                    <td>
                      <div class="d-flex align-items-center">
                      <img class="avatar"
                      src="{{performer.image}}">
                      <div>{{ performer.usrFullName}}
                      <div class="small text-muted">ID: {{ performer.pfaAthlete }}</div>
                    </div>
                    </div>
                    </td>
                    <td>
                    <div class="clearfix">
                        <div class="float-left"><strong>{{ performer.totalScore % 1 === 0 
                          ? performer.totalScore 
                          : (performer.totalScore | number: '1.2-2') }}%</strong></div>
                      </div>
                      <div class="progress progress-xs">
                        <div class="progress-bar" [ngClass]="{
                          'bg-lightgreen': performer.totalScore >= 85,
                          'bg-green':  performer.totalScore >= 70 &&  performer.totalScore < 85,
                          'bg-yellow':  performer.totalScore >= 40 &&  performer.totalScore  < 70,
                          'bg-red': performer.totalScore < 40
                            }" role="progressbar" [style.width.%]="performer.totalScore"></div>
                      </div>
                    </td>
                    <td>{{ performer.pfaBMI }}</td>
                    <td>{{ performer.pfaGripStrengthLeft }}</td>
                    <td class="text-center">{{ performer.pfaGripStrengthRight }}</td>
                    <td class="text-center">{{ performer.pfaLowerBodyForce }}</td>
                    <td class="text-center">{{ performer.pfaLowerBodyPower }}</td>
                    <td class="text-center">{{ performer.pfaUpperBodyForce }}</td>
                    <td class="text-center">{{ performer.pfaUpperBodyPower }}</td>
                    <td class="text-center">{{ performer.pfaVO2 }}</td>
                   </tr>
                </tbody>
                <!-- Template for when there is no data -->
                <ng-template #noData>
                  <tr>
                    <td colspan="8" class="text-center">Data not available</td>
                  </tr>
                </ng-template>
              </table>
            </div>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="col-sm-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body" *ngIf="TopPerformers.length > 0">
                <div class="row">
                  <div>
                    <h4 class="card-title fw-semibold">Top Performer</h4>
                    <h4  class="font-weight-bold text-name">Name : {{ TopPerformers[0]?.usrFullName }}</h4>
                    <h4 class="font-weight-bold text-id">ID :
                      {{ TopPerformers[0]?.pfaAthlete }}
                    </h4>
                    <p class="text-dark">{{ TopPerformers[0]?.naCreatedOn | date:'dd-MM-yyyy' }}</p>
                  </div>
                </div>
                <div class="donut-container d-flex align-items-center justify-content-center mb-4">
                  <div class="donut-circle"
                       [ngClass]="{
                         'dark-green': TopPerformers[0]?.totalScore >= 85,
                         'light-green': TopPerformers[0]?.totalScore >= 70,
                         'yellow': TopPerformers[0]?.totalScore >= 40,
                         'red': TopPerformers[0]?.totalScore < 40
                       }">
                    <div class="inner-circle">
                      <h3 class="font-weight-bold">{{ TopPerformers[0]?.totalScore| number: '1.2-2' }}%</h3>
                    </div>
                  </div>
                  <!-- <p class="score-tag">Overall Score</p> -->
                </div>
               
                <table class="score-table">
                  <thead>
                    <tr>
                      <th>Test</th>
                      <th class="text-center">Score</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr>
                        <td class="score-title">BMI (kg/m²)</td>
                        <td class="score-value">{{ TopPerformers[0]?.pfaBMI || 0 }}</td>
                      </tr>
                      <tr>
                        <td class="score-title">MedBall (M)</td>
                        <td class="score-value">{{ TopPerformers[0]?.pfaSeatedMedBallThrow || 0 }}</td>
                      </tr>
                      <tr>
                        <td class="score-title">Speed (m/s)</td>
                        <td class="score-value">{{ TopPerformers[0]?.pfaSpeed || 0 }}</td>
                      </tr>
                      <tr>
                        <td class="score-title">Yo-Yo Test (M)</td>
                        <td class="score-value">{{ TopPerformers[0]?.pfaYoYoTest || 0 }}</td>
                      </tr>
                      <tr>
                        <td class="score-title">PushUps (min)</td>
                        <td class="score-value">{{ TopPerformers[0]?.pfaPushups || 0 }}</td>
                      </tr>
                  </tbody>
                </table>
       
              </div>
              <div class="card-body d-flex align-items-center justify-content-center" *ngIf="TopPerformers.length === 0">
                <h4 class="text-muted">No Data Available</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
   
</div>