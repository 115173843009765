import { Component, OnInit } from '@angular/core';
import { Chart } from 'chart.js/auto';
import { SharedService } from '../../services/shared.service';
import { DomSanitizer } from '@angular/platform-browser';
import { AssessmentService } from '../../services/assessment.service';
import { NotificationService } from '../../services/Notifications.service';
import { Router, RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
 
@Component({
  selector: 'app-report-card',
  standalone:true,
  imports:[CommonModule,RouterOutlet],
  templateUrl: './report-card.component.html',
  styleUrls: ['./report-card.component.css']
})
export class ReportCardComponent implements OnInit {
  detail: any;
  usrRoleid: any;
  showImage: boolean = false;
  ImageUrl: any;
  userid: number = 2;
  fitnessReport: any[] = [];
  lastFitnessAssessment: any;
  avgZPercent: number | null = null;
  fitnesspercentage: number | null = null;
  nutritionReport: any[] = [];
  cognitiveReport: any[] = [];
  Nutritionalpercentage: number | null = null;
 
   agilityPercentage: number=0;
  endurancePercentage: number=0;
  flexibilityPercentage: number=0;
  reactionTimePercentage: number=0;
  speedPercentage: number=0;
  strengthPercentage: number=0;
 
  bodyWeightPercentage: number=0;
  bodyHeightPercentage: number=0;
  bmiPercentage: number=0;
  bodyFatPctPercentage: number=0;
  muscleMassPercentage: number=0;
  hemoglobinPercentage: number=0;
  ironLevelsPercentage: number=0;
  vitaminDLevelPercentage: number=0;
  vitaminB12LevelPercentage: number=0;
 
  constructor(
    private sharedService: SharedService,
    private assessmentService: AssessmentService,
    private sanitizer: DomSanitizer,
    private notify: NotificationService,
    private route: Router
  ) {}
 
  ngOnInit(): void {
    this.getProfileImage();
    this.updateFitnessData().then(() => {
      this.processFitnessData();
      this.processNutritionData();
    });
   
  }
 
  private async updateFitnessData(): Promise<void> {
    try {
      const fitnessResponse = await this.assessmentService.getFitnessByAthleteId(this.userid).toPromise();
      console.log(fitnessResponse, "fitness");
      this.fitnessReport = fitnessResponse;
      if (fitnessResponse && fitnessResponse.length > 0) {
        this.lastFitnessAssessment = fitnessResponse[fitnessResponse.length - 1];
      }
    } catch (error) {
      console.error('Error fetching fitness data', error);
    }
 
    try {
      const nutritionResponse = await this.assessmentService.geteNutritionalByAthleteId(this.userid).toPromise();
      console.log(nutritionResponse, "Nutrition");
      this.nutritionReport = nutritionResponse;
    } catch (error) {
      console.error('Error fetching nutrition data', error);
    }
 
    try {
      const cognitiveResponse = await this.assessmentService.getcognitiveDetail(this.userid).toPromise();
      console.log(cognitiveResponse, "cognative");
      this.cognitiveReport = cognitiveResponse;
    } catch (error) {
      console.error('Error fetching cognitive data', error);
    }
  }
 
  private processFitnessData(): void {
    const zScoresForFitness = this.calculateZScoresForAll(this.fitnessReport);
 
    zScoresForFitness.forEach(zScoreData => {
      const attribute = zScoreData.attr;
      const avgZScore = this.calculateAverageZScore(zScoreData.zScores);
      const avgPercentage = this.convertZScoreToPercentage(avgZScore);
 
      // Store the percentage in the corresponding property
      switch (attribute) {
        case 'pfaAgility':
          this.agilityPercentage = avgPercentage;
          break;
        case 'pfaEndurance':
          this.endurancePercentage = avgPercentage;
          break;
        case 'pfaFlexibility':
          this.flexibilityPercentage = avgPercentage;
          break;
        case 'pfaReactionTime':
          this.reactionTimePercentage = avgPercentage;
          break;
        case 'pfaSpeed':
          this.speedPercentage = avgPercentage;
          break;
        case 'pfaStrength':
          this.strengthPercentage = avgPercentage;
          break;
        // Add additional cases if needed
      }
 
      console.log(`Average Z-Score for ${attribute}: ${avgZScore.toFixed(2)}, Percentage = ${avgPercentage.toFixed(2)}%`);
    });
 
    this.calculateOverallAverage(zScoresForFitness);
  }
 
 
  private processNutritionData(): void {
    const zScoresForNutrition = this.calculateZScoresForNutrition(this.nutritionReport);
    console.log(zScoresForNutrition, "zScoresForNutrition");
 
    zScoresForNutrition.forEach(zScoreData => {
      const attribute = zScoreData.attr;
      const avgZScore = this.calculateAverageZScore(zScoreData.zScores);
      const avgPercentage = this.convertZScoreToPercentage(avgZScore);
 
      // Store the percentage in the corresponding property
      switch (attribute) {
        case 'naBodyWeight':
          this.bodyWeightPercentage = avgPercentage;
          break;
        case 'naBodyHeight':
          this.bodyHeightPercentage = avgPercentage;
          break;
        case 'naBMI':
          this.bmiPercentage = avgPercentage;
          break;
        case 'naBodyFatPct':
          this.bodyFatPctPercentage = avgPercentage;
          break;
        case 'naMuscleMass':
          this.muscleMassPercentage = avgPercentage;
          break;
        case 'naHemoglobin':
          this.hemoglobinPercentage = avgPercentage;
          break;
        case 'naIronLevels':
          this.ironLevelsPercentage = avgPercentage;
          break;
        case 'naVitaminDLevel':
          this.vitaminDLevelPercentage = avgPercentage;
          break;
        case 'naVitaminB12Level':
          this.vitaminB12LevelPercentage = avgPercentage;
          break;
        // Add additional cases if needed
      }
 
      console.log(`Average Z-Score for ${attribute}: ${avgZScore.toFixed(2)}, Percentage = ${avgPercentage.toFixed(2)}%`);
    });
 
    this.calculateOverallAverage(zScoresForNutrition);
  }
 
 
 
  private processCognitiveData(): void {
    const zScoresForCognitive = this.calculateZScoresForAll(this.fitnessReport);
    zScoresForCognitive.forEach(zScoreData => {
      console.log(`Z Scores for ${zScoreData.attr}:`);
      zScoreData.zScores.forEach((z: number, index: number) => {
        const zPercent = this.convertZScoreToPercentage(z);
        console.log(`Value ${index + 1}: Z-Score = ${z.toFixed(6)}, Percentage = ${zPercent.toFixed(2)}%`);
      });
 
      const avgZScore = this.calculateAverageZScore(zScoreData.zScores);
      this.avgZPercent = this.convertZScoreToPercentage(avgZScore);
      console.log(`Average Z-Score for ${zScoreData.attr}: ${avgZScore.toFixed(2)}, Percentage = ${this.avgZPercent.toFixed(2)}%\n`);
    });
 
    this.calculateOverallAverage(zScoresForCognitive);
  }
 
 
  private calculateMean(values: number[]): number {
    const sum = values.reduce((a, b) => a + b, 0);
    return sum / values.length;
  }
 
  private calculateStandardDeviation(values: number[], mean: number): number {
    const squaredDiffs = values.map(value => Math.pow(value - mean, 2));
    const avgSquareDiff = squaredDiffs.reduce((a, b) => a + b, 0) / values.length;
    return Math.sqrt(avgSquareDiff);
  }
 
  private calculateZScores(values: number[], mean: number, stdDev: number): number[] {
    if (stdDev === 0) {
      return values.map(() => 0);
    }
    return values.map(value => (value - mean) / stdDev);
  }
 
  private calculateZScoresForAll(data: any[]): any[] {
    const attributes = ['pfaAgility', 'pfaEndurance', 'pfaFlexibility', 'pfaReactionTime', 'pfaSpeed', 'pfaStrength'];
 
    return attributes.map(attr => {
      const values = data.map(item => item[attr]);
      const mean = this.calculateMean(values);
      const stdDev = this.calculateStandardDeviation(values, mean);
      const zScores = this.calculateZScores(values, mean, stdDev);
 
      return { attr, zScores };
    });
  }
 
  private calculateZScoresForNutrition(data: any[]): any[] {
    const nutritionAttributes = [
      'naBodyWeight', 'naBodyHeight', 'naBMI', 'naBodyFatPct',
      'naMuscleMass', 'naHemoglobin', 'naIronLevels',
      'naVitaminDLevel', 'naVitaminB12Level'
    ];
 
    return nutritionAttributes.map(attr => {
      const values = data.map(item => item[attr]);
      console.log(values,"nutritionAttributes")
      const mean = this.calculateMean(values);
      const stdDev = this.calculateStandardDeviation(values, mean);
      const zScores = this.calculateZScores(values, mean, stdDev);
      console.log(zScores,"nutritionValues1")
      console.log(mean,"nutritionValues2")
      console.log(stdDev,"nutritionValues3")
      return { attr, zScores };
    });
  }
 
  private calculateAverageZScore(zScores: number[]): number {
    return this.calculateMean(zScores);
  }
 
  private convertZScoreToPercentage(zScore: number): number {
    const percentage = ((zScore + 3) / 6) * 100;
    return Math.min(Math.max(percentage, 0), 100); // Ensure percentage is between 0 and 100
  }
 
  private calculateOverallAverage(zScoresForAll: any[]): void {
    let totalZScores: number[] = [];
 
    zScoresForAll.forEach(zScoreData => {
      totalZScores = totalZScores.concat(zScoreData.zScores);
    });
 
    const overallAvgZScore = this.calculateAverageZScore(totalZScores);
    const overallAvgZPercent = this.convertZScoreToPercentage(overallAvgZScore);
    this.fitnesspercentage = overallAvgZPercent;
    this.Nutritionalpercentage = overallAvgZPercent;
 
    console.log(`Overall Average Z-Score: ${overallAvgZScore.toFixed(2)}, Percentage = ${overallAvgZPercent.toFixed(2)}%\n`);
  }
 
  getProfileImage(): void {
    this.ImageUrl = null;
    this.showImage = false;
 
    this.assessmentService.getImage(this.userid).subscribe(
      (imageBlob) => {
        if (imageBlob) {
          const objectURL = URL.createObjectURL(imageBlob);
          this.ImageUrl = this.sanitizer.bypassSecurityTrustUrl(objectURL);
          this.showImage = true;
          console.log(this.ImageUrl, "ImageUrl");
        } else {
          this.notify.failed("Invalid athlete number.");
        }
      },
      (error) => {
        this.ImageUrl = null;
        this.showImage = false;
        this.notify.failed("Invalid athlete number");
        console.error('Error fetching image', error);
      }
    );
  }
 
  initializeBalanceCharts(): void {
    const balanceChart = document.getElementById('balance-chart') as HTMLCanvasElement;
    const ctx = balanceChart.getContext('2d');
 
    if (ctx) {
      new Chart(ctx, {
        type: 'line',
        data: {
          labels: ['16-07-2018', '17-07-2018', '18-07-2018', '19-07-2018', '20-07-2018'],
          datasets: [{
            label: 'pfaEndurance',
            data: [12, 15, 10, 7, 14],
            borderColor: 'rgba(0, 123, 255, 0.5)',
            backgroundColor: 'rgba(0, 123, 255, 0.2)',
          }]
        },
        options: {
          scales: {
            x: { display: true },
            y: { display: true }
          }
        }
      });
    }
  }
 
  initializeBalanceCharts1(): void {
    const balanceChart1 = document.getElementById('balance-chart1') as HTMLCanvasElement;
    const ctx = balanceChart1.getContext('2d');
 
    if (ctx) {
      new Chart(ctx, {
        type: 'line',
        data: {
          labels: ['16-07-2018', '17-07-2018', '18-07-2018', '19-07-2018', '20-07-2018'],
          datasets: [{
            label: 'pfaAgility',
            data: [10, 15, 12, 9, 14],
            borderColor: 'rgba(0, 123, 255, 0.5)',
            backgroundColor: 'rgba(0, 123, 255, 0.2)',
          }]
        },
        options: {
          scales: {
            x: { display: true },
            y: { display: true }
          }
        }
      });
    }
  }
 
  initializeBalanceCharts2(): void {
    const balanceChart2 = document.getElementById('balance-chart2') as HTMLCanvasElement;
    const ctx = balanceChart2.getContext('2d');
 
    if (ctx) {
      new Chart(ctx, {
        type: 'line',
        data: {
          labels: ['16-07-2018', '17-07-2018', '18-07-2018', '19-07-2018', '20-07-2018'],
          datasets: [{
            label: 'pfaSpeed',
            data: [14, 10, 12, 15, 11],
            borderColor: 'rgba(0, 123, 255, 0.5)',
            backgroundColor: 'rgba(0, 123, 255, 0.2)',
          }]
        },
        options: {
          scales: {
            x: { display: true },
            y: { display: true }
          }
        }
      });
    }
  }
}