import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { AssessmentService } from '../../services/assessment.service';
import { environment } from '../../../environments/environment.prod';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-imageupload',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './imageupload.component.html',
  styleUrl: './imageupload.component.css'
})
export class ImageuploadComponent {
  selectedFiles: File[] = [];
  constructor(private http:HttpClient) {
    
    }
 

  onFileSelected(event: any) {
    this.selectedFiles = Array.from(event.target.files) as File[];
  }
  
  onSubmit() {
    const formData = new FormData();
  
    this.selectedFiles.forEach((file) => {
      formData.append('files', file, file.name);
    });
  
    this.http.post(environment.BaseUrl+environment.uploadImageUrl, formData).subscribe((response) => {
      console.log('Upload successful', response);
    });
  }
}
