import { AgGridAngular } from "ag-grid-angular";
import { ColDef } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { Component, NgZone } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ActivatedRoute, Router, RouterLink, RouterModule, RouterOutlet } from "@angular/router";
import { AssessmentService } from "../../../services/assessment.service";
import { PrivilegeService } from "../../../services/privilage.service";
import { Privilege } from "../../../model/Privillage";
import { User } from "../../../model/User";
import { UserService } from "../../../services/user.service";

// Row Data Interface
interface IRow {
  playername: string;
  Assessor: string;
  priority: string;
  date:string;

}

@Component({
  selector: 'app-physiotherapy-detail-list',
  standalone: true,
  imports: [CommonModule, RouterOutlet,AgGridAngular,RouterLink,RouterModule],
  templateUrl: './physiotherapy-detail-list.component.html',
  styleUrl: './physiotherapy-detail-list.component.css'
})
export class PhysiotherapyDetailListComponent  {
  user: User | null = null;
  id:any;
  isAdmin: boolean = false;
  isPlayer: boolean = false;
  privilege: Privilege;
  Isplayer: boolean;
  Admin: boolean;
 
  constructor(private router: Router,private ngZone: NgZone,private route: ActivatedRoute,private assessmentService: AssessmentService,private priService:PrivilegeService,private userService:UserService) {
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
      console.log(this.id,"ID");
    });
    this.privilege = this.priService.getPrivileges();
    this.Isplayer = this.privilege.isPlayer;
    this.Admin = this.privilege.isAdmin;
  }
 

  themeClass = "ag-theme-quartz";
 
  rowData: IRow[] = [ ];
 
  colDefs: ColDef[] = [];
 
  defaultColDef: ColDef = {
    flex: 1,
    sortable: true,
    filter: true
  };
 
 
 
  ngOnInit() {
    this.user = this.userService.getUser();
    if(this.id){
    this.assessmentService.getPTAtheleteById(this.id).subscribe(response => {
      console.log('dataid', response);
 
      this.rowData = response.map((item:any) => ({
        Id: `${item.ptaId}`,
        playerId: `${item.ptaAthlete}`,
        playername: `${item.athleteName}`,
        Assessor: ` ${item.assessorName}`,
        InjuryHistory: `${item.ptaInjuryHistory}`,
        TreatmentPlan: `${item.ptaTreatmentPlan}`,
        date: new Date(item.ptaDate).toLocaleDateString(),
        //priority: this.getPriority(item)
      }));
 
      this.colDefs = this.getColumnDefinitions();
    }, error => {
      console.error('Error submitting assessment', error);
    });
  }
   
  }
 
  // getPriority(item: any): string {
  //   if (item.pfaSpeed > 0 || item.pfaAgility > 0) {
  //     return 'High';
  //   } else {
  //     return 'Low';
  //   }
  // }
 
  getColumnDefinitions(): ColDef[] {
    const columns: ColDef[] = [
      { field: "playername" },
      { field: "Assessor" },
      { field: "InjuryHistory" },
      { field: "TreatmentPlan" },
      { field: "date" },
      // { field: "priority",
      //   cellStyle: params => {
      //     // Change color based on the priority value
      //     if (params.value === 'Low') {
      //       return { color: 'red' };
      //     } else {
      //       return { color: 'green' };
      //     }
      //   }
      // },
      {
        headerName: 'Action',
        cellRenderer: (_params: any) => {
          const container = document.createElement('div');
          container.style.display = 'flex';
          container.style.justifyContent = 'space-around';
 
          // View button with eye icon
          const viewButton = document.createElement('button');
          viewButton.classList.add('btn');
          viewButton.innerHTML = '<i class="fa fa-eye"></i>';
          viewButton.addEventListener('click', () => {
            this.ngZone.run(() => {
          this.router.navigate(['/physiotherapydetail'], { queryParams: { id: _params.data.Id } })
            })
          });
          // Edit button with edit icon
          const editButton = document.createElement('button');
          editButton.classList.add('btn');
          editButton.innerHTML = '<i class="fa fa-edit"></i>';
          editButton.addEventListener('click', () => {
            this.ngZone.run(() => {
            this.router.navigate(['/physiotherapy'], {queryParams: { id: _params.data.Id } })
            })
          });
         
          container.appendChild(viewButton);
          if (!this.Isplayer) {
          container.appendChild(editButton);
          }
    
 
          return container;
        },
        width: 120
      }
         ];
 
    if (this.isAdmin) {
      return columns;
    } else if (this.isPlayer) {
      return columns.filter(col => col.field !== 'playername');
    } else {
      return columns.filter(col => col.field !== 'Assessor');
    }
  }
}