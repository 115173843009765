import { Component } from '@angular/core';

@Component({
  selector: 'app-personal-information-detail',
  standalone: true,
  imports: [],
  templateUrl: './personal-information-detail.component.html',
  styleUrl: './personal-information-detail.component.css'
})
export class PersonalInformationDetailComponent {

}
