import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ChangeDetectorRef, Component, NgZone, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { NgToastModule } from 'ng-angular-popup';
import { AssessmentService } from '../../../services/assessment.service';
import { NotificationService } from '../../../services/Notifications.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { Privilege } from '../../../model/Privillage';
import { PrivilegeService } from '../../../services/privilage.service';
import { NgSelectModule } from '@ng-select/ng-select';


interface IRow { }

@Component({
  selector: 'app-recall-nutrition-form',
  standalone: true,
  imports: [CommonModule, RouterOutlet, RouterLink, RouterModule, HttpClientModule, FormsModule, NgToastModule,NgSelectModule],
  templateUrl: './recall-nutrition-form.component.html',
  styleUrl: './recall-nutrition-form.component.css'
})
export class RecallNutritionFormComponent {
  isInputVisible: boolean = false; // Variable to track the visibility of the input field
  ImageUrl: any;
  showImage: boolean = false;
  Response: any;
  athleteId: any;
  assesserId: any;
  selectedStatus: string = '0';
  selectdata: any;
  id: any;
  playerList: any[] = [];
  sport: IRow[] = [];
  privilege: Privilege;
  Admin: boolean;
  playerName: any;
  categoryId: any;
  mode: any;
  teamId: any;
  filteredResponse: any;
  assessmentDate: any;
  minDate: any;
  maxDate: any;
  invalid:boolean=false;
  formData: any = {
    rcaId: '0',
    rcaAthletId: '',
    rcaAssessmentId: '',
    rcaAssessor: '',
    rcaQ1: '',
    rcaQ2: '',
    rcaQ3: '',
    rcaQ4: '',
    rcaQ5: '',
    rcaQ6: '',
    rcaQ7: '',
    rcaQ8: '',
    rcaQ9: '',
    rcaQ10: '',
    rcaQ11: '',
    rcaQ12: '',
    rcaQ13: '',
    rcaQ14: '',
    rcaQ15: '',
    rcaQ16: '',
    rcaQ17: '',
    rcaQ18: '',
    rcaQ19: '',
    rcaQ20: '',
    rcaQ21: '',
    rcaQ22: '',
    rcaQ23: '',
    rcaQ24: '',
    rcaQ25: '',
    rcaQ26: '',
    rcaQ27: '',
    rcaQ28: '',
    rcaQ29: '',
    rcaQ30: '',
    rcaQ31: '',
    rcaQ32: '',
    rcaQ33: '',
    rcaQ34: '',
    rcaQ35: '',
    rcaQ36: '',
    rcaQ37: '',
    rcaQ38: '',
    rcaQ39: '',
    rcaQ40: '',
    rcaQ41: '',
    rcaQ42: '',
    rcaQ43: '',
    rcaQ44: '',
    rcaQ45: '',
    rcaQ46: '',
    rcaQ47: '',
    rcaQ48: '',
    rcaQ49: '',
    rcaQ50: '',
    rcaQ51: '',
    rcaQ52: '',
    rcaQ53: '',
    rcaQ54: '',
    rcaCreatedOn: ''
  }
  image: any;
  Assessor: boolean;
  sciStaff: boolean = false;
  constructor(
    private assessmentService: AssessmentService,
    private notify: NotificationService,
    private route: ActivatedRoute,
    private location: Location,
    private sanitizer: DomSanitizer, private router: Router,
    private priService: PrivilegeService,
    private cdRef: ChangeDetectorRef, private ngZone: NgZone
  ) {
    this.privilege = this.priService.getPrivileges();
    this.Admin = this.privilege.isAdmin;
    this.sciStaff = this.privilege.isScienceStaff ?? false;
    this.Assessor = this.privilege.isAssessor;
    this.route.queryParams.subscribe(params => {
      this.mode = params['mode'];
      this.teamId = params['teamId'];
      this.athleteId = params['athleteId'];
      this.assesserId = params['assessor'];
      this.categoryId = params['categoryId'];
      this.id = params['id'];
      this.formData['rcaAthletId'] = this.athleteId;
      this.formData['rcaAssessmentId'] = this.id;
      this.formData['rcaAssessor'] = this.assesserId;
    });
  }


  ngOnInit() {
    this.getRecallAssessments();
    this.getProfileImage();
    this.getPlayerByAssessmentId();
    this.assessmentService.getpendingAssessment().subscribe(response => {
      this.filteredResponse = response.filter((item: any) => item.asmId == this.id);
      this.assessmentDate=this.filteredResponse[0].asmDate ? this.filteredResponse[0].asmDate.split('T')[0] : 'mm/dd/yyyy';
    });
}
updatePlayerLabels(): void {
  console.log(this.athleteId,"Athlete")
  this.playerList = this.playerList.map(player => ({
    ...player,
    displayLabel: !this.Assessor ? `${player.Name} - ${player.PlayerId}` : player.PlayerId
  }));
}
today: string = new Date().toISOString().split('T')[0];
setDateConstraints(): void {
  this.ngZone.run(() => {
  const assessmentDate = this.assessmentDate;
  const today = new Date().toISOString().split('T')[0];
  this.minDate = assessmentDate;
  this.maxDate = today;
  this.formData.rcaCreatedOn  =this.formData.rcaCreatedOn ?this.formData.rcaCreatedOn : today;
  this.invalid=false;
  this.cdRef.detectChanges();
});
  const dateInput = document.querySelector('input[name="rcaCreatedOn"]') as HTMLInputElement;
  if (dateInput) {
    dateInput.showPicker?.();
  }
}

  onAthleteChange(selectedPlayer: any) {
    if (selectedPlayer) {
      const athleteId = selectedPlayer.PlayerId;
      const assessmentId = selectedPlayer.aplAssessment;
      this.athleteId = selectedPlayer.PlayerId;
      this.minDate='';
      this.getProfileImage();
      this.getRecallAssessments();
      this.getPlayerByAssessmentId();
    }
  }

  getPlayerByAssessmentId() {
    this.assessmentService.getPlayerByAssessmentId(this.id).subscribe(response => {
      this.selectdata = response.filter((assessment: any) => assessment.aplCategory == this.categoryId);
      // Create a map to store unique assessments based on PlayerId (or any unique identifier)
      const uniqueAssessments = new Map<number, any>();
      // Filter the response to only include unique assessments
      this.selectdata.forEach((item: any) => {
        // Only add the item if it does not exist in the map
        if (!uniqueAssessments.has(item.aplPlayer)) {
          uniqueAssessments.set(item.aplPlayer, {
            "Player Name": `${item.usrFullName}`,
            Name: `${item.usrFullName}`,
            PlayerId: `${item.aplPlayer}`,
            teamId: `${item.teamId}`,
            id: `${item.aplAssessment}`,
            "Email": `${item.usrEmail}`,
            PlayerEmail: `${item.usrEmail}`,
            "Phone No": `${item.usrPhoneNo}`,
            PhoneNo: `${item.usrPhoneNo}`,
            "DOB": new Date(item.usrDoB).toLocaleDateString('en-US'),
            "status": `${item.aplStatus}`,
          });
        }
      });

      this.sport = Array.from(uniqueAssessments.values());
      this.playerName = this.sport.filter((assessment: any) => assessment.PlayerId === this.athleteId);
      this.playerList = this.sport;
      this.updatePlayerLabels();
    }, error => {
    });
  }

  getProfileImage() {
    this.ImageUrl = null;
    this.showImage = false;

    this.assessmentService.getEmptyImage(this.athleteId).subscribe(
      (response) => {
        if (response) {
          this.showImage = true;
          this.image = "assets/image/profileimg.jpg"
        }
      },
      (error) => {
        this.assessmentService.getImage(this.athleteId).subscribe(
          (imageBlob) => {
            const objectURL = URL.createObjectURL(imageBlob);
            this.ImageUrl = this.sanitizer.bypassSecurityTrustUrl(objectURL);
            this.image = this.ImageUrl.changingThisBreaksApplicationSecurity
            this.showImage = true;
          }
        );
      }
    );
  }

  getRecallAssessments() {
    this.assessmentService.getRecallAssessments(this.athleteId, this.id).subscribe(response => {
      this.Response = response;  
      const data = response[0]; // Assuming response[0] contains the required data
      this.formData.rcaId = data.rcaId ?? null;
      this.formData.rcaAthletId = data.rcaAthletId ?? null;
      this.formData.rcaAssessmentId = data.rcaAssessmentId ?? null;
      this.formData.rcaCreatedOn = data.rcaCreatedOn ? data.rcaCreatedOn.split('T')[0] : 'mm/dd/yyyy'
      // Process rcaQ1 to rcaQ54
      for (let i = 1; i <= 54; i++) {
        const questionKey = `rcaQ${i}`;
        const value = data[questionKey];
  
        if (value != null) { // Check for non-null/non-undefined values
          // Special check for rcaQ29
          if (questionKey === "rcaQ29") {
            this.formData[questionKey] = this.checkLitersOnly(value);
          } else {
            this.formData[questionKey] = this.formatResponseValue(value);
          }
        } else {
          this.formData[questionKey] = null;
        }
      }
    });
  }
  
  // Check if value represents valid liters and format accordingly
  checkLitersOnly(value: string | number): string | null {
    if (typeof value === "string" && this.isWaterIntakeRange(value)) {
      return this.formatWaterIntake(value);
    }
    return null; // Return null if value is not valid for liters
  }
  
  // Format response value based on type or content
  formatResponseValue(value: string | number): string | number {
    if (typeof value === "string") {
      const lowerValue = value.trim().toLowerCase();
  
      // Check for Yes/No
      if (lowerValue === "yes") return "Yes";
      if (lowerValue === "no") return "No";
  
      // Check for hunger satisfaction levels
      if (this.isHungerLevel(lowerValue)) return this.capitalizeWords(value);
  
      // Check for performance levels
      if (this.isPerformanceLevel(lowerValue)) return this.capitalizeWords(value);
  
      // Check for valid time strings
      if (this.isValidTime(value)) return this.extractTime(value);
  
      // Default: return trimmed string
      return value.trim();
    }
  
    // Preserve non-string values
    return value;
  }
  
  // Check if value is a valid water intake range or numeric liters
  isWaterIntakeRange(value: string): boolean {
    const ranges = [
      "less than 1 liter",
      "1-2 liters",
      "2-3 liters",
      "more than 3 liters"
    ];
    return ranges.includes(value.toLowerCase()) || this.isNumericLiters(value);
  }
  
  // Format water intake to a readable format
  formatWaterIntake(value: string): string {
    const lowerValue = value.toLowerCase();
 
    if (lowerValue === "less than 1 liter") return "Less than 1 liter";
    if (lowerValue === "1-2 liters") return "1-2 liters";
    if (lowerValue === "2-3 liters") return "2-3 liters";
    if (lowerValue === "more than 3 liters") return "More than 3 liters";
 
    // Handle numeric liters
    if (this.isNumericLiters(value)) {
      const numericValue = parseFloat(value.replace(/ltr|ltrs|liters|liter|ml/gi, "").trim());
 
      // Map numeric values to ranges
      if (numericValue < 1) return "Less than 1 liter";
      if (numericValue >= 1 && numericValue < 2) return "1-2 liters";
      if (numericValue >= 2 && numericValue <= 3) return "2-3 liters";
      if (numericValue > 3) return "More than 3 liters";
    }
 
    return value; // Default: return original value
  }
 
  // Check if value is numeric and ends with 'ltr' or 'ltrs'
  isNumericLiters(value: string): boolean {
    const numericPattern = /^(\d+(\.\d+)?)\s?(ltr|ltrs|liters|liter|ml)?$/i;
    return numericPattern.test(value);
  }
  
  // Check if the value represents hunger satisfaction levels
  isHungerLevel(value: string): boolean {
    const levels = ["very hungry", "hungry", "neutral", "satisfied", "very satisfied"];
    return levels.includes(value.toLowerCase());
  }
  
  // Check if the value represents performance levels
  isPerformanceLevel(value: string): boolean {
    const levels = ["poor", "fair", "good", "very good", "excellent"];
    return levels.includes(value.toLowerCase());
  }
  
  // Validate if the string is a valid time format
  isValidTime(time: string): boolean {
    const timePattern = /(\d{1,2}):(\d{2})(am|pm)?/i;
    return timePattern.test(time);
  }
  
  // Extract and format valid time strings
  extractTime(time: string): string {
    const timePattern = /(\d{1,2}):(\d{2})(am|pm)?/i;
    const match = time.match(timePattern);
  
    if (match) {
      let hour = parseInt(match[1], 10);
      const minutes = match[2];
      const meridian = match[3]?.toLowerCase();
  
      if (meridian === "pm" && hour < 12) {
        hour += 12;
      } else if (meridian === "am" && hour === 12) {
        hour = 0;
      }
  
      return `${hour.toString().padStart(2, "0")}:${minutes}`;
    }
  
    return time; // Return original value if not valid time
  }
  
  // Capitalize words in a string
  capitalizeWords(value: string): string {
    return value
      .split(" ")
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }
  

  onBeverageChange(beverage: string, isChecked: boolean) {
    switch (beverage) {
      case 'Coffee':
        this.formData.rcaQ30 = isChecked ? beverage : null; // Set to 'Coffee' if checked, otherwise null
        break;
      case 'withmilk':
        this.formData.rcaQ31 = isChecked ? beverage : null; // Set to 'Milk' if checked, otherwise null
        break;
      case 'Black':
        this.formData.rcaQ32 = isChecked ? beverage : null; // Set to 'Milk' if checked, otherwise null
        break;
      case 'withSugar':
        this.formData.rcaQ33 = isChecked ? beverage : null; // Set to 'Milk' if checked, otherwise null
        break;

      case 'Tea':
        this.formData.rcaQ34 = isChecked ? beverage : null; // Set to 'Tea' if checked, otherwise null
        break;
      case 'withmilks':
        this.formData.rcaQ35 = isChecked ? beverage : null; // Set to 'Milk' if checked, otherwise null
        break;
      case 'Blacks':
        this.formData.rcaQ36 = isChecked ? beverage : null; // Set to 'Milk' if checked, otherwise null
        break;
      case 'withSugars':
        this.formData.rcaQ37 = isChecked ? beverage : null; // Set to 'Milk' if checked, otherwise null
        break;

      case 'Sports Drinks':
        this.formData.rcaQ38 = isChecked ? beverage : null; // Set to 'Sports Drinks' if checked, otherwise null
        break;

      case 'Soft Drinks':
        this.formData.rcaQ39 = isChecked ? beverage : null; // Set to 'Sports Drinks' if checked, otherwise null
        break;
      case 'Regular':
        this.formData.rcaQ40 = isChecked ? beverage : null; // Set to 'Milk' if checked, otherwise null
        break;
      case 'Diet':
        this.formData.rcaQ41 = isChecked ? beverage : null; // Set to 'Milk' if checked, otherwise null
        break;

      case 'Juice':
        this.formData.rcaQ42 = isChecked ? beverage : null; // Set to 'Juice' if checked, otherwise null
        break;

      case 'Alcohol':
        this.formData.rcaQ43 = isChecked ? beverage : null; // Set to 'Alcohol' if checked, otherwise null
        break;

      case 'Milk':
        this.formData.rcaQ44 = isChecked ? beverage : null; // Set to 'Milk' if checked, otherwise null
        break;
      case 'Whole':
        this.formData.rcaQ45 = isChecked ? beverage : null; // Set to 'Milk' if checked, otherwise null
        break;
      case 'Skimmed':
        this.formData.rcaQ46 = isChecked ? beverage : null; // Set to 'Milk' if checked, otherwise null
        break;
      case 'Almond':
        this.formData.rcaQ47 = isChecked ? beverage : null; // Set to 'Milk' if checked, otherwise null
        break;

      default:
        break;
    }
  }

  validateNumber(event: KeyboardEvent) {
    const charCode = event.charCode;
    // Allow only numbers (0-9)
    if (charCode < 48 || charCode > 57) {
        event.preventDefault();
    }
  }

validateAlphabet(event: KeyboardEvent) {
  const charCode = event.charCode;
  // Allow only letters (A-Z, a-z) and spaces (charCode 32)
  if (
      (charCode < 65 || charCode > 90) && // A-Z
      (charCode < 97 || charCode > 122) && // a-z
      charCode !== 32 // space
  ) {
      event.preventDefault();
  }
}



  isSectionComplete(section: number): boolean {
    switch (section) {
      case 1:
        // Check if all rcaQ1 to rcaQ54 are answered (non-empty)
        for (let i = 1; i <= 1; i++) {
          if (!this.formData[`rcaQ${i}`]) {
            return false; // Return false if any question is unanswered
          }
        }
        return true; // Return true if all questions are answered
      default:
        return false;
    }
  }


  onSubmit() {
    const optionalFields = ['rcaCreatedOn','rcaQ8','rcaQ9','rcaQ10','rcaQ11','rcaQ12','rcaQ16','rcaQ17','rcaQ18','rcaQ19','rcaQ20','rcaQ24','rcaQ25','rcaQ26',
                            'rcaQ27','rcaQ28','rcaQ30','rcaQ31','rcaQ32','rcaQ33','rcaQ34','rcaQ35','rcaQ36','rcaQ37',
                            'rcaQ38','rcaQ39','rcaQ40','rcaQ41','rcaQ42','rcaQ43','rcaQ44','rcaQ45','rcaQ46',
                            'rcaQ47','rcaQ48'];
    let missingRequiredFields = false;

    // Additional checks for dependent fields
    if (this.formData.rcaQ3 == 'No'){
      this.formData.rcaQ4 = '';
    }
    
    if (!this.formData.rcaQ30) { // Coffee is unchecked
      this.formData.rcaQ31 = ''; // with milk
      this.formData.rcaQ32 = ''; // Black
      this.formData.rcaQ33 = ''; // with Sugar
    }

    if (!this.formData.rcaQ34) { // Tea is unchecked
      this.formData.rcaQ35 = ''; // with milk
      this.formData.rcaQ36 = ''; // Black
      this.formData.rcaQ37 = ''; // with Sugar
    }

    if (!this.formData.rcaQ39) { // Soft Drink is unchecked
      this.formData.rcaQ40 = ''; // Regular
      this.formData.rcaQ41 = ''; // Diet
    }

    if (!this.formData.rcaQ44) { // Milk is unchecked
      this.formData.rcaQ45 = ''; // Whole
      this.formData.rcaQ46 = ''; // Skimmed
      this.formData.rcaQ47 = ''; // Almond
    }

    if (this.formData.rcaQ49 == 'No'){
      this.formData.rcaQ50 = '';
    }

    if (this.formData.rcaQ53 == 'No'){
      this.formData.rcaQ54 = '';
    }

    for (let key in this.formData) {
        if (this.formData.hasOwnProperty(key) && !optionalFields.includes(key)) {
            // Check if rcaQ4 should be required based on rcaQ3's value
            if (key === 'rcaQ4' && this.formData.rcaQ3 != 'Yes') {
              continue; // Skip rcaQ4 if rcaQ3 is not "yes"
            }

            if (key === 'rcaQ50' && this.formData.rcaQ49 != 'Yes') {
              continue; // Skip rcaQ4 if rcaQ3 is not "yes"
            }

            if (key === 'rcaQ54' && this.formData.rcaQ53 != 'Yes') {
              continue; // Skip rcaQ4 if rcaQ3 is not "yes"
            }

            // Check if the key is a required field and not one of the IDs
            if (key !== 'rcaId' && key !== 'rcaAthletId' && key !== 'rcaAssessmentId') {
                // Check if the value is empty or not
                if (this.formData[key] === undefined || this.formData[key] === null || this.formData[key].trim() === '') {
                    missingRequiredFields = true; // Mark as missing
                }
            }
        }
    }

    // If any required fields are missing, show the notification
    if (missingRequiredFields) {
        this.ngZone.run(() => {
            this.notify.failed('Please fill in all required fields marked with *');
            this.cdRef.detectChanges();
            
        });
        return; // Stop submission
    }
    if(!this.formData.rcaCreatedOn)
      {
        this.ngZone.run(() => {
          this.notify.failed("Please select Test Taken Date ");
          this.invalid=true;
          this.cdRef.detectChanges();
        });
        return;
      }
    // Store form data, including any unanswered questions with empty values
    this.assessmentService.saveRecallAssessments([this.formData]).subscribe((response: any) => {
        this.Response = response;
        this.assessmentService.updateAssessmentStatus(this.athleteId, this.id, this.categoryId, this.assesserId).subscribe(
            response => {}
        );
        this.notify.success('Data Saved successfully');
    });
  }

  isReadOnly(): boolean {
    return this.mode === 'view';
  }

  onBack(): void {
    this.location.back();
  }
}
