<div class="body-wrapper">  
    <div class="row flex-grow">
      <div class="col-12 grid-margin stretch-card">
        <div class="card card-rounded">
          <div class="card-body">
            <div class="d-sm-flex justify-content-between align-items-start">
              <div>
                <h4 class="card-title card-title-dash">Teams</h4>
              </div>
                
                <div class="d-flex gap-4">
                  <div class="select-wrapper">
                    <ng-select 
                    name="usrOrganization"
                    [items]="Orgainsation" 
                    bindLabel="orgName" 
                    bindValue="orgId" 
                    [(ngModel)]="selectedOrgId" 
                    [searchable]="false"
                    (change)="onOrganizationChange()"
                    [clearable]="false" 
                    class="ng-select-custom"
                    
                  >
                    <ng-template ng-label-tmp let-item="item">
                      <i class="fa fa-chevron-down"></i> {{ item.orgName  }}
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item">
                      {{ item.orgName }}
                    </ng-template>
                  </ng-select>
                              
                  </div>
                  <div>
                    <button class="btn btn-primary" [routerLink]="['/add-team']">Add Team</button>
                  </div>
                  <div>
                    <button class="btn btn-primary" [routerLink]="['/assign-team']">Assign Team</button>
                  </div>
                </div>              
          
            </div><br>
       <br>
            <div class="ag-theme-quartz" style="width: 100%; height: 500px;">
              <ag-grid-angular
                style="width: 100%; height: 100%;"
                class="ag-theme-quartz"
                [rowData]="rowData"
                [columnDefs]="colDefs"
                [defaultColDef]="defaultColDef"
                (gridReady)="onGridReady($event)"
                rowSelection="single">
              </ag-grid-angular>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>