<!-- <app-header></app-header> -->
<div class="headercontent">
  <h1>Assessments</h1>
</div>

<div class="container">
  <button class="btn btn-primary p-2" routerLink="/Genericform">Genericform</button>
  <form (ngSubmit)="onSubmit()" #columnDataForm="ngForm">
    <div class="form first">
      <div class="details personal">
        <div class="fields">
          <div class="input-field">
            <label>Form Name</label>
            <input type="text" name="tableName" [(ngModel)]="columnData.tableName" placeholder="tableName" required>
          </div>
          <div class="input-field">
            <label>Column Name</label>
            <input type="text" name="columnName" [(ngModel)]="columnData.columnName" placeholder="columnName" required>
          </div>
          <div class="input-field">
            <label>DataType</label>
            <select class="form-control" name="dataType" [(ngModel)]="columnData.dataType">
              <option value="nvarchar(MAX)">Text</option>
              <option value="int">Number</option>
              <option value="smalldatetime">Date</option>
            </select>
          </div>
        </div>
      </div>
      <div class="buttons">
        <button type="submit" class="submit" [disabled]="!columnDataForm.form.valid">
          <span class="btnText">Create</span>
          <i class="uil uil-navigator"></i>
        </button>
      </div>
    </div>
  </form>
</div>
