<div class="body-wrapper">
  <div >
    <button class="btn btn-primary" (click)="onBack()">Back</button>
  </div>
<div class="container">
  <div class="d-sm-flex justify-content-between align-items-start levelone">
    <header>Assessment</header>
  </div><br>
  <div *ngIf="loading" class="loading-spinner">
    <div class="spinner"></div>
  </div>
  <div *ngIf="!loading">
  <form #assessmentForm="ngForm" (ngSubmit)="onSubmit(assessmentForm)">
    <div class="fields">
      <div class="input-field">
        <label>Assessment Name</label>
        <input type="text" placeholder="Enter Assessment Name" required name="assessmentName"
        [(ngModel)]="assessmentName">
      </div>
      <div class="input-field">
        <label>Description</label>
        <textarea [(ngModel)]="assessmentDescription" name="assessmentDescription"></textarea>
      </div>
      <div class="input-field">
        <label>Assessment Date</label>
        <input type="date" placeholder="Enter Assessment Date" required name="assessmentDate"
          [(ngModel)]="assessmentDate">
      </div>
    </div>
    <div class="fields">
      <!-- Organization Field -->
      <div class="input-field">
        <label>Organization</label>
        <select name="usrOrganization" [(ngModel)]="selectedOrgId" (change)="filterTeamsByOrganization()" [disabled]="assessment" required>
          <option value="" disabled>Select Organization</option>
          <option *ngFor="let organization of Orgainsation" [value]="organization.orgId">
            {{ organization.orgName }}
          </option>
        </select>
      </div>
    
      <!-- Team Field -->
      <div class="input-field">
        <label>Team</label>
        <select name="Team" [(ngModel)]="selectedTeamId" (change)="onTeamChange()" [disabled]="assessment" required>
          <option value="" disabled>Select Team</option>
          <option *ngFor="let team of filteredTeams" [value]="team.teamId">
            {{ team.teamName }}
          </option>
        </select>
      </div>
    
      <!-- Categories Field -->
      <div class="input-field">
        <label>Categories</label>
        <select name="Cat" [(ngModel)]="selectedCatId" (change)="onCategoryChange()" [disabled]="assessment" required>
          <option value="" disabled>Select Category</option>
          <option *ngFor="let cat of Categories" [value]="cat.catId">
            {{ cat.catName }}
          </option>
        </select>
      </div>
    </div>  
    <div class="fields">
      <div class="input-field">
        <label>Assessment Types</label>
        <select [(ngModel)]="selectedAssessment" (change)="AssessmentChange($event)" name="selectedAssessment" required>
          <option value="" disabled>Select Assessment</option>
          <option *ngFor="let assessment of Assessment"  [value]="assessment.ssId">
            {{ assessment.ssName }}
          </option>
        </select>
      </div>
    <div class="input-field">
      <label>Assessor</label>
      <select [(ngModel)]="selectedAssessor" name="selectedAssessor" (change)="getTestdata()" required>
        <option value="" disabled>Select Assessor</option>
        <option *ngFor="let assessor of Assessor" [value]="assessor.usrId">
          {{ assessor.usrFullName }}
        </option>
      </select>
    </div>
    <div class="buttons mt-4">
      <button type="submit" class="btn p-3 submit">
        <span class="btnText">Submit</span>
        <!-- <i class="uil uil-navigator"></i> -->
      </button>
    </div>
    </div>
    <div class="content" style="width: 100%; height: 100%;">
      <ag-grid-angular class="ag-theme-quartz" style="height: 500px;" [rowData]="rowData" [columnDefs]="colDefs"
        [defaultColDef]="defaultColDef" [gridOptions]="gridOptions" [pagination]="true" (gridReady)="onGridReady($event)">
      </ag-grid-angular>
     
    </div>
    <br><br><br><br><br>

  </form>
</div>
</div>
</div>
 
<div class="toaster">
  <ng-toast></ng-toast>
</div>