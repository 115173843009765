<div *ngIf="isLoading" class="loading-spinner">
  <div class="spinner"></div>
</div>   
<!-- <div class="text-center" *ngIf="this.athleteReport.length === 0">
  No Data!
</div> -->
<div class="row">
<div>
<div class="row-cards row-deck" *ngIf="!Isplayer">    
    <div class="row">
      <div class="col-sm-8 flex-column d-flex stretch-card">
        <div class="row">   
          <div class="col-lg-4 d-flex grid-margin stretch-card">
            <div class="card bg-blue">
              <div class="card-body text-white">
                <h3 class="font-weight-bold mb-3">{{ averageOverallPercentage  }}%</h3>

                <div class="progress mb-3">
                  <div class="progress-bar bg-warning" role="progressbar"   [style.width.%]="averageOverallPercentage > 0 ? averageOverallPercentage : 0"
                    [attr.aria-valuenow]="averageOverallPercentage" aria-valuemin="0" aria-valuemax="100">
                  </div>
                </div>
                <p class="pb-0 mb-0">Overall Score</p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 d-flex grid-margin stretch-card">
            <div class="card player-diffrence-border">
              <div class="card-body">
                <h2 class="text-dark mb-2 font-weight-bold"> {{ injuryCount }}</h2>
                <h4 class="card-title mb-2">Players Count</h4>
              </div>
            </div>
          </div>
          <div class="col-lg-4 d-flex grid-margin stretch-card">
            <!-- <div class="card justify-content-center player-diffrence-border">
              <div class="card-body ms-4">
                <img class="pro-img"
                src="{{InjuryPerformers[0]?.image}}">
              </div>
            </div> -->
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 grid-margin d-flex stretch-card">
            <div class="card">
              <div class="card-body">
                <div class="d-flex align-items-center justify-content-between">
                  <h4 class="card-title mb-2">{{ reportTitle }}</h4> 
                  <div class="dropdown">
                    <a href="#" class="text-success btn btn-link px-1 dropdown-toggle dropdown-arrow-none"
                       data-bs-toggle="dropdown" id="settingsDropdownsales">
                      <i class="mdi mdi-dots-horizontal"></i>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right navbar-dropdown"
                         aria-labelledby="settingsDropdownsales">
                         <a class="dropdown-item" (click)="filterPerformers('All','overall')">
                          <i class="mdi mdi-grease-pencil text-primary"></i>
                          All
                        </a>
                      <a class="dropdown-item" (click)="filterPerformers('Excellent','overall')">
                        <i class="mdi mdi-grease-pencil text-primary"></i>
                        Excellent
                      </a>
                      <a class="dropdown-item" (click)="filterPerformers('Good','overall')">
                        <i class="mdi mdi-delete text-primary"></i>
                        Good
                      </a>
                       <a class="dropdown-item" (click)="filterPerformers('Average','overall')">
                        <i class="mdi mdi-grease-pencil text-primary"></i>
                        Average
                      </a>
                      <a class="dropdown-item" (click)="filterPerformers('Poor','overall')">
                        <i class="mdi mdi-delete text-primary"></i>
                        Poor
                      </a>
                    </div>
                  </div>
                </div>
                <div id="new-chart"></div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-4 flex-column d-flex stretch-card">
        <div class="row flex-grow">
          <div class="col-sm-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body" *ngIf="topPerformer.length > 0">
                <div class="row">
                  <div>
                    <h4 class="card-title fw-semibold">Top Performer</h4>
                    <h4  *ngIf="!sciStaff"  class="font-weight-bold text-name">Name : {{ topPerformer[0]?.usrFullName }}</h4>
                    <h4 class="font-weight-bold text-id">ID :
                      {{ topPerformer[0]?.ipaAthlete }}
                    </h4>
                  </div>
                </div>
                <div class="donut-container d-flex align-items-center justify-content-center mb-4">
                  <div class="donut-circle"
                       [ngClass]="{
                         'dark-green': topPerformer[0]?.overallZScore >= 85,
                         'light-green':  topPerformer[0]?.overallZScore >= 70 &&  topPerformer[0]?.overallZScore < 85,
                         'yellow':  topPerformer[0]?.overallZScore >= 40 &&  topPerformer[0]?.overallZScore < 70,
                         'red':  topPerformer[0]?.overallZScore < 40,
                         'transparent-border':  topPerformer[0]?.overallZScore == null
                       }">
                    <div class="inner-circle">
                      <h3 class="font-weight-bold">{{   topPerformer[0]?.overallZScore| number: '1.2-2' }}%</h3>
                    </div>
                  </div>
                  <!-- <p class="score-tag">Overall Score</p> -->
                </div>
                <table class="score-table">
                  <tr>
                    <th>Test</th>
                    <th >Score</th>
                  </tr>
                  <tr>
                    <td class="score-title">Deep Squat</td>
                    <td class="score-value">{{ ( topPerformer[0]?.ipaDeepSquat % 1 === 0) 
                      ?  topPerformer[0]?.ipaDeepSquat 
                      : ( topPerformer[0]?.ipaDeepSquat | number: '1.2-2') }}</td>
                  </tr>
                  <tr>
                    <td class="score-title">Inline Lunge</td>
                    <td class="score-value">{{ ( topPerformer[0]?.ipaInlineLunge % 1 === 0) 
                      ?  topPerformer[0]?.ipaInlineLunge 
                      : ( topPerformer[0]?.ipaInlineLunge | number: '1.2-2') }}</td>
                  </tr>
                  <tr>
                    <td class="score-title">Hurdle Step</td>
                    <td class="score-value">{{ ( topPerformer[0]?.ipaHurdleStep % 1 === 0) 
                      ?  topPerformer[0]?.ipaHurdleStep 
                      : ( topPerformer[0]?.ipaHurdleStep | number: '1.2-2') }}</td>
                  </tr>
                  <tr>
                    <td class="score-title">Push Up</td>
                    <td class="score-value">{{ ( topPerformer[0]?.ipaPushUp % 1 === 0) 
                      ?  topPerformer[0]?.ipaPushUp 
                      : ( topPerformer[0]?.ipaPushUp | number: '1.2-2') }}</td>
                  </tr>
                  
                </table>
                
                
              </div>
              <div  class="card-body d-flex align-items-center justify-content-center" *ngIf="topPerformer.length== 0">
                <h4 class="text-muted">No Data Available</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row row-cards row-deck">
        <div class="d-sm-flex justify-content-between align-items-center mb-2">
          <h4 class="card-title fw-semibold" >{{header}} Performers</h4>
          <div>
            <div class="select-wrapper">
          
              <select [(ngModel)]="selectedStatus" (change)="onFitnessStatusChange()" class="form-select">
                <option selected disabled>Select Option</option>
                <option value="0">All</option>
                <option value="1">Excellent </option>
                <option value="2">Good</option>
                <option value="3">Average </option>
                <option value="4">Poor</option>
              </select>
            </div>
          </div>
        </div>
        <!-- Top Performers Section -->
        <div class="col-12">
          <div class="card">
            <h4 class="m-3" *ngIf="chat">Athlete Data</h4>
            <div class="table-responsive custom-scroll">
              <table class="table table-hover table-outline table-vcenter text-nowrap card-table">
                <thead>
                    <tr>
                        <th>Profile</th>
                        <th>Athletes</th>
                        <th>overall%</th>
                        <th>Deep Squat (cm)</th>
                        <th>Inline Lunge (cm)</th>
                        <th>Hurdle Step (cm)</th>
                        <th>Active SLR (cm)</th>
                        <th>Push Up (count)</th>
                        <th>Rotary Stability (count)</th>
                        
                      </tr>
                </thead>
                <tbody *ngIf="InjuryPerformers && InjuryPerformers.length > 0; else noData">
                  <tr *ngFor="let performer of InjuryPerformers">
                    <td class="text-center">
                      <img class="avatar"
                      src="{{performer.image}}">
                    </td>
                    <td>
                      <div>{{ performer.usrFullName }}</div>
                      <div class="small text-muted">ID: {{ performer.ipaAthlete }}</div>
                    </td>
                    <td>
                      <div class="clearfix">
                        <div class="float-left"><strong>{{ performer.overallZScore| number: '1.2-2' }} %</strong></div>
                      </div>
                      <div class="progress progress-xs">
                        <div class="progress-bar" [ngClass]="{
                              'bg-lightgreen':  performer.overallZScore >= 85,
                              'bg-green':  performer.overallZScore >= 70 &&  performer.overallZScore < 85,
                              'bg-yellow':  performer.overallZScore >= 40 &&  performer.overallZScore  < 70,
                              'bg-red': performer.overallZScore < 40
                            }" role="progressbar" [style.width.%]=" performer.overallZScore"></div>
                      </div>
                    </td>
                    <td>{{ performer.ipaDeepSquat }}</td>
                    <td>{{ performer.ipaInlineLunge }}</td>
                    <td>{{ performer.ipaHurdleStep }}</td>
                    <td>{{ performer.ipaActiveSLR }}</td>
                    <td>{{ performer.ipaPushUp }}</td>
                    <td>{{ performer.ipaRotaryStability }}</td>   
                  </tr>
                </tbody>

                <!-- Template for when there is no data -->
                <ng-template #noData>
                  <tr>
                    <td colspan="8" class="text-center">Data not available</td>
                  </tr>
                </ng-template>

              </table>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
        </div>
    </div>