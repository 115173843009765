import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router, RouterLink, RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgToastModule } from 'ng-angular-popup';
import { AssessmentService } from '../../../services/assessment.service';
import { NotificationService } from '../../../services/Notifications.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-videoanalysiseditform',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterOutlet, RouterLink, HttpClientModule, NgToastModule],
  templateUrl: './videoanalysiseditform.component.html',
  styleUrls: ['./videoanalysiseditform.component.css']
})
export class VideoanalysiseditformComponent implements OnInit {
  id: any;
  formSubmitted: boolean = false;
  isReadOnly: boolean = false;
  selectedAthlete: any;
  athletes: any[] = [];
  selectedAthleteId: string | null = null;

  assessment: any = {
    vuUploader: null,
    vuTitle: null,
    vuDate: null,
    vuSportActivity: null,
    vuTechniqueAnalysis: null,
    vuPerformanceAnalysis: null,
  };

  product: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private assessmentService: AssessmentService,
    private notify: NotificationService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
      console.log(this.id, "ID");
    });
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      // Safe to use window, document, etc.
      this.isReadOnly = localStorage.getItem('athelete') !== '';
    } else {
      this.isReadOnly = false;
    }
    this.FetchAthelete();
  }

  onPlayerChange(event: any) {
    console.log(event.target.value, "athlete");
  }

  onSubmit(): void {
    if (this.isFormValid()) {
      this.assessmentService.addvideoAnalysis([this.assessment]).subscribe(
        response => {
          console.log('Assessment submitted', response);
          this.notify.success("Data saved successfully");
          this.formSubmitted = true;
          this.router.navigate(['videoanalysislist']);
        },
        error => {
          console.error('Error submitting assessment', error);
        }
      );
    } else {
      console.log('Form is not valid');
    }
  }

  isFormValid(): boolean {
    // Check if all fields are filled
    return Object.values(this.assessment).every(value => value !== '' && value !== null);
  }

  onAthleteSelected(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement) {
      const selectedAthlete = this.athletes.find((athlete: any) => athlete.usrFullName === inputElement.value);
      if (selectedAthlete) {
        this.selectedAthleteId = selectedAthlete.usrId;
      }
    }
  }

  fetchAthletes(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const query = inputElement.value.trim();
    if (query) {
      this.assessmentService.fetchAtleteVideoAnalysis(query).subscribe(
        data => {
          this.athletes = data;
        },
        error => {
          console.error('Error fetching athletes', error);
        }
      );
    }
  }

  FetchAthelete() {
    if (this.id) {
      this.assessmentService.fetchAtleteVideoAnalysis(this.id).subscribe(
        (response: any) => {
          console.log(response, "video");
          if (response && response.length > 0) {
            console.log(response, 'data');
            const data = response[0];
            this.product = data;
            this.assessment.vuId = data.vuId;
            this.assessment.vuUploader = data.vuUploader;
            this.assessment.vuTitle = data.vuTitle;
            this.assessment.vuDescription = data.vuDescription;
            this.assessment.vuDate = data.vuDate ? data.vuDate.split('T')[0] : '';
            this.assessment.vuSportActivity = data.vuSportActivity;
            this.assessment.vuTechniqueAnalysis = data.vuTechniqueAnalysis;
            this.assessment.vuPerformanceAnalysis = data.vuPerformanceAnalysis;
            this.assessment.vuCreatedBy = data.vuCreatedBy;
            this.assessment.vuCreatedOn = data.vuCreatedOn ? data.vuCreatedOn.split('T')[0] : '';
          }
        },
        (error: any) => {
          console.error('Error fetching assessment data', error);
        }
      );
    }
  }
}
