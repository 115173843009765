export const environment = {
    production: true,
    BaseUrl:'https://amsapi-dev.qwalton.com',
    LoginUrl: '/api/User/userLogin',
    //PhysicalFitnessApi
    apiUrl: '/api/PhysicalFitness/postfitnessreport',
    getfitnessreport: '/api/PhysicalFitness/getfitnessreport?orgId=',
    getAtheletes: '/api/PhysiologyAssessment/allAthletes',
    fetchAtheletesfitness: '/api/PhysicalFitness/getfitnessreportbyId?Id=',
    getPhysicalFitnessByAthleteId: '/api/PhysicalFitness/getPhysicalFitnessByAthleteId?Id=',
    getAllPhysicalFitnessByAssessment: '/api/PhysicalFitness/getAllPhysicalFitnessByAssessment',
    //NutritionApi
    saveNurtitional: '/api/Nutrition/SaveNutritional',
    getAllAthletesUrl: '/api/PhysicalFitness/allAtheletes',
    getAllNutritional: '/api/Nutrition/getNutritional?Org=',
    fetchAtheletesNutritional: '/api/Nutrition/getNutritionalbyId?Id=',
    getNutritionalByAthleteId: '/api/Nutrition/getNutritionalByAthleteId?Id=',
    getAllNutritionalByAssessment : '/api/Nutrition/getAllNutritionalByAssessment',
    getNutritionalByAsm:'/api/Nutrition/getNutritionalByAthleteIdAndAssessmentId',
   
    //VideoAnalysisApi
    fetchAtheletesVideoAnalysis: '/api/VideoAnalysis/getVideoAnalysisbyId?Id=',
    VideoAnalysisUrl: '/api/VideoAnalysis/SaveVideoAnalysis',
    GetvideoAnalysis: '/api/VideoAnalysis/getVideoAnalysis',
    //BioMechanicalApi
    BioMechanicalUrl: '/api/BiomechanicalAssessment/SaveBioMechanical',
    getBioMechanicalUrl: '/api/BiomechanicalAssessment/getBioMechanicalAssessments?org=',
    getAllAthletesBMUrl: '/api/BiomechanicalAssessment/allAtheletes',
    fetchAtheletesBMFitness: '/api/BiomechanicalAssessment/getbiomechanicalbyId?Id=',
    //PhysiologyApi

    PhysiologyDetail: '/api/PhysiologyAssessment/savePhysiologyAssessment',
    getPhysiology: '/api/PhysiologyAssessment/getPhysiologyAssessment?Org=',
    getAllAthletesPhy: '/api/PhysicalFitness/allAtheletes',
    fetchAtheletesPhysiology: '/api/PhysiologyAssessment/getPhysiologybyid?Id=',
    getphysiotherapyUrl: '/api/Physiotherapy/getPhysiotherapy?orgId=',
    postphysiotherapyUrl: '/api/Physiotherapy/savePhysiotherapy',
    getPTAthletesUrl: '/api/PhysicalFitness/allAtheletes',
    fetchphysiotherapy: '/api/Physiotherapy/getPhysiotherapyById?id=',
    //PrivilageApi
    getPrivilagebyId: '/api/Privilege/getPrivilegebyId?Id=',

    getRole: '/api/User/getRole',
    Plyerregister: '/api/Userregister/SaveAthlete',
    getcoachName: '/api/PhysiologyAssessment/allTrainerNames',
    getTeamName: '/api/PhysiologyAssessment/allTeamNames',
    getOrgTrainer: '/api/User/orgUserDetails?roleId=',

    getcolumnName: '/api/DynamicColumn',
    addcolumn: '/api/DynamicColumn/add-column',
    saveDynamicdata: '/api/DynamicColumn/add-data?tableName=',
    updateUser: '/api/Userregister/UserActive',
    userByid: '/api/user/getUser/Id?Id=',
    orgTeams: '/api/Teams/getOrgTeam?orgId=',
    getVideoAnalysisAtheletebyId: '/api/VideoAnalysis/getVideoAnalysisByAthleteId/Id?Id=',
    getPhysiologyByAthleteId: '/api/PhysiologyAssessment/getPhysiologyByAthleteId?Id=',
    fetchptaByIdUrl: '/api/Physiotherapy/getPhysiotherapyByAthleteId?athleteId=',
    fetchBMById: '/api/BiomechanicalAssessment/getBiomechanicalByAthleteId?Id=',
    resetPassword: '/api/User/resetPassword',
    sendmail: '/api/User/forgotPassword?email=',
    changePassword:'/api/User/Changepassword',
    //changePassword:'/api/Registerrequest/resetPassword?email=',

    SAVELEVELONE: "/api/LevelOne/SaveLevelOneForm",
    GETLEVELONEDATA: "/api/LevelOne/getLevelOneForm?OrgId=",
    LEVELONEBYID: "/api/LevelOne/getLevelOneFormById?id=",
    LEVELONEBYSPORT: "/api/LevelOne/getLevelOneFormBySport",
    LEVELONEBYATHLETEID: "/api/LevelOne/getLevelOneFormByAthleteId",
    getallsport: "/api/User/allSports",
    SaveTrainer: "/api/Userregister/SaveTrainer",
    GetSupportStaff: "/api/User/getSupportStaff",
    GetOrganisation: "/api/User/allOrganisations",
    userRegistrationURL: "/api/Userregister/getRegisteredUsersbyId?userId=",
    getUserUrl: "/api/Userregister/getUsers",
    RegisterUploaded: "/api/Upload/RegisterUploaded",
    RegistrationUploaded: "/api/Upload/UserRegisterUpload",
    UploadFiles: "/api/Upload/UploadFiles",
    uploadAssessment: "/api/Upload/uploadAssessment?assessmentId=",
    getAllAssessment: "/api/Assessment/getAllAssessment",
    LevelOnefileUpload: "/api/Upload/LevelOnefileUpload",
    uploadImageUrl: "/api/Upload/upload-images",
    getProfileimagebyId: "/api/Userregister/getProfileImageById?userId=",
    getOrgimagebyId: "/api/Userregister/getOrgImageById?orgId=",
    getAdmitCardById: '/api/Privilege/getAdmitCardById?Id=',
    getAdmitCard: '/api/Privilege/getAdmitCard?OrgId=',
    SAVECOGNITIVE: '/api/CognitiveAssessment/SaveCognitive',
    GETCOGNITIVE: '/api/CognitiveAssessment/getCognitive?OrgId=',
    fetchAtheletescatdio: '/api/CognitiveAssessment/getCognitiveById?Id=',
    getcognitiveByAtheleteId: '/api/CognitiveAssessment/getCognitiveByAthleteId?id=',

    //Pending Assessment
    getPendingAssessmentById: '/api/Assessment/getPendingAssessmentById',
    getPendingAssessment: '/api/Assessment/getPendingAssessment',
    getPlayersByCoachId: '/api/Userregister/getPlayersByCoachId?CoachId=',
    getAllTestsName: '/api/Userregister/getTests?teamid=',
    getTestsName: '/api/Userregister/getTestByAssessor',
    getPendingAllAssessment: '/api/Assessment/getPendingAllAssessment?AssessmentId=',
    getTeamByAssessmentId: '/api/Assessment/getTeamByAssessmentId?AssessmentId=',
    deleteAssessment: '/api/Assessment/RemoveAssessment',

    GETLEVELONEBYCOACH: '/api/LevelOne/getLevelOneFormByCoach?id=',
    GETNUTRITIONALBYCOACH: '/api/Nutrition/getNutritionalByCoach?id=',
    GETFITNESSBYCOACH: '/api/PhysicalFitness/getfitnessreportbyCoach?id=',
    GETPHYSIOLOGYBYCOACH: '/api/PhysiologyAssessment/getPhysiologyByCoachId?id=',
    GETPHYSIOTHERAPYBYCOACH: '/api/Physiotherapy/getPhysiotherapyByCoachId?id=',
    UpdatSportInfo: '/api/Userregister/update-sports-info',
    AddTeam: '/api/Userregister/add-team',
    AddOrganisation: '/api/Userregister/add-organisation',
    SAVEASSESSMENT: '/api/Assessment/SaveAssessment',
    GETTEAMS: '/api/Assessment/getTeams',
    GETPLAYERSBYTEAM: '/api/Teams/getTeamPlayers?teamId=',
    GETATHLETEBYORG: '/api/Teams/getAthleteByOrg?orgId=',
    GETTESTS: '/api/Assessment/getTests?assessmentId=',


    //Cardiology
    getcardioByOrgId: '/api/Cardio/getCardio?OrgId=',
    getcardioByAthleteId: '/api/Cardio/getCardioByAthleteId?id=',
    getcardioById: '/api/Cardio/getCardioById?id=',
    SAVECORDIO: '/api/Cardio/SaveCardio',
    GETCATEGORY: '/api/Assessment/getCategories',
    getAssessmentBycat: '/api/Assessment/getAssessment?category=',
    getAssessmentTest: '/api/Assessment/getAssessmentTest?asmId=',
    leveloneReference: '/api/LevelOne/getLevelOneReference',
    getPlayerByAssessment: '/api/Assessment/getAssessmentPlayerlist?AssessmentId=',
    getfitnessByAssessment: '/api/PhysicalFitness/getPhysicalFitnessByAsmId',
    getCardioByAssessment: '/api/Cardio/getCardioAssessmentByAsmId',
    getCognitiveByAssessment: '/api/CognitiveAssessment/getCognitiveAssessmentsByAsmId',
    updateAssessmentStatus: '/api/Assessment/AssessmentStatus',
    checkUsrSummary: '/api/Assessment/getUserSummery',
    saveUserSummary: '/api/Assessment/PostUserSummery',
    updateProfileDetails: '/api/Userregister/SaveProfile',
    getLevelOneByAsmId: '/api/LevelOne/getLevelOneAssessmentByAsmId',
    GetInjuryPreventionAnalysisByOrg:'/api/InjuryPreventionAnalysis/getIPA?OrgId=',
    getInjuryPreventionAnalysisAthleteId: '/api/InjuryPreventionAnalysis/getIPAByAthleteId?id=',
    getSportsPerformaceAnalysisAthleteId: '/api/SportsPerformaceAnalysis/getSportsPerformaceAnalysisByAthleteId?id=',
    SaveSleepandRest: '/api/Nutrition/SaveSleepAndRest',
    SaveHydrationandNutrition: '/api/Nutrition/SaveHydrationAndNutrition',
    GetSleepandRest: '/api/Nutrition/getSleepAndRest',
    GetSleepandRestByAsmId: '/api/Nutrition/getSleepAndRestByAsmId',
    GetHydrationandNutrition: '/api/Nutrition/getHydrationAndNutrition',
    GetHydrationandNutritionByAsmId: '/api/Nutrition/getHydrationAndNutritionByAsmId',
    uploadorganizationlogo: '/api/Userregister/add-organisation',
    SaveRecallAssessments: '/api/Nutrition/SaveRecallAssessments',
    GetRecallAssessments: '/api/Nutrition/getRecallAssessment',
    InjuryPrevention: '/api/InjuryPreventionAnalysis/SaveIPA',
    getInjuryByAssessment: '/api/InjuryPreventionAnalysis/getIPAByAsmId',
    getInjuryByAthleteId:'/api/InjuryPreventionAnalysis/getIPAByAthleteId?id=',
    getallinjurypreventionByAsm:'/api/InjuryPreventionAnalysis/getAllInjuryPreventionByAssessment?assessmentId',
    //Sport Performance Analysis
    SaveSportsperformanceanalysis: '/api/SportsPerformaceAnalysis/SaveSportsPerformaceAnalysis',
    getSportsperformanceByAsmId: '/api/SportsPerformaceAnalysis/getSportsPerformaceAnalysisByAsmId',
    GetSportsperformanceByOrg: '/api/SportsPerformaceAnalysis/getSportsPerformaceAnalysis?OrgId=',
    GetSportsperformanceByAthleteId: '/api/SportsPerformaceAnalysis/getSportsPerformaceAnalysisByAthleteId?id=',
    getRecallAssessmentByAtheleteId:'/api/Nutrition/getRecallAssessmentByAtheleteId?Id=',
    getPhysicalFitnessByAsm:'/api/PhysicalFitness/getPhysicalFitnessByAssessment',
    getNutritionaByAsm:'/api/Nutrition/getNutritionalByAssessment',
    getcognitiveByAsm:'/api/CognitiveAssessment/getCognitivetest',
    getcardioByAsm:'/api/Cardio/getCardioByAssessment',
    getinjurypreventionByAsm:'/api/InjuryPreventionAnalysis/GetInjuryPreventionByassessment',
    getSportsPerformaceByAsm:'/api/SportsPerformaceAnalysis/getSportAnalysisByAssessment',
    getAllCognitiveByAssessment:'/api/CognitiveAssessment/getAllCognitiveByAssessment',
    getAllSportAnalysisByAssessment:'/api/SportsPerformaceAnalysis/getAllSportAnalysisByAssessment',
    getInterNationalBenchMark: '/api/LevelOne/getInterNationalBenchMark',
    getIBMReference: '/api/LevelOne/getIBMReference',
    getAssessor:'/api/Assessment/getAssessor',
    updateAssessment:'/api/Assessment/UpdateAssessment',
    getTestByAssessment: '/api/Userregister/getTestByAssessment?assessmentId=',

    //Player Assessment 

    getPlayerAssessmentByAtheleteId:'/api/PlayerAssesment/getPlayerAssessmentByAthleteId?id=',
    getNutritionalByTest:'/api/Nutrition/getNutritionalByTest?asmId=',
    getFitnessByTest:'/api/PhysicalFitness/getfitnessDatabyTest?asmId=',
    getCardioByTest:'/api/Cardio/getCardioDataByTest?asmId=',
    getSportsPerformanceByTest:'/api/SportsPerformaceAnalysis/getSportsPerformanceByTest?asmId=',
    getInjuryByTest:'/api/InjuryPreventionAnalysis/getInjuryPerformanceByTest?asmId=',
    getCognitiveByTest:'/api/CognitiveAssessment/getCognitiveByTest?asmId=',
    getNutritionalByTestAssessor:'/api/Nutrition/getNutritionalByTestAndAssessor?asmId=',
    getFitnessByTestAssessor:'/api/PhysicalFitness/getfitnessDatabyTestAndAssessor?asmId=',
    getCardioByTestAssessor:'/api/Cardio/getCardioDataByTestAndAssessor?asmId=',
    getSportsPerformanceByTestAssessor:'/api/SportsPerformaceAnalysis/getSportsPerformanceByTestAndAssessor?asmId=',
    getInjuryByTestAssessor:'/api/InjuryPreventionAnalysis/getInjuryPerformanceByTestAndAssessor?asmId=',
    getCognitiveByTestAssessor:'/api/CognitiveAssessment/getCognitiveByTestAndAssessor?asmId=',
    getLevelOneByTest:'/api/LevelOne/getLevelOneDatabyTest?asmId=',
    getLevelOneByTestAssessor:'/api/LevelOne/getLevelOneDatabyTestAndAssessor?asmId=',

    //Overall Player Data
    getOverallPlayerDetails:'/api/PlayerAssesment/getOverallPlayerData?athleteId='

}
