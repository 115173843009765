import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { AssessmentService } from '../../../services/assessment.service';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { NgToastModule } from 'ng-angular-popup';
import { NotificationService } from '../../../services/Notifications.service';

@Component({
  selector: 'app-nutrition-form',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterOutlet, RouterLink, HttpClientModule,NgToastModule],
  templateUrl: './nutrition-form.component.html',
  styleUrls: ['./nutrition-form.component.css']
})
export class NutritionFormComponent implements OnInit {  
  athletes: any[] = [];
  selectedAthleteId: string | null = null;  // To store the selected athlete's ID
  id: any;
  product: any;

  constructor(private assessmentService: AssessmentService,private route: ActivatedRoute,private Router:Router, private notify:NotificationService) {
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
      console.log(this.id,"ID");
    });
 
    this.FetchAthelete();
  }

  formSubmitted: boolean = false;
  personalDetailsFilled: boolean = false;
  physiotherapy: boolean = false;
  physiology: boolean = false;
  isReadOnly: boolean = false;

  nutrition: any = {
    // naId: null,                 
    naDate: null,
    naAssessment: 1,         
    naAssessor: '1',
    naAthlete: null,
    naBodyWeight: null,
    naBodyHeight: null,
    naBMI: null,
    naBodyFat: null,             
    naFatFreeWeight: null,      
    naSubcutaneousFat: null,    
    naVisceralFat: null,        
    naBodyWater: null,          
    naSkeletalMuscle: null,     
    naLeanMass: null,           
    naBoneMass: null,           
    naProtein: null,            
    naBMR: null,                
    naMetabolicAge: null,       
    naHealthScore: null,         
  };
  

  ngOnInit() {
    if (typeof window !== 'undefined' && typeof localStorage !== 'undefined') {
    this.isReadOnly = localStorage.getItem('athlete') !== null;
    }
  }

  checkPersonalDetailsFilled(): void {
    const personalDetailsInputs = document.querySelectorAll(".form.first input");
    this.personalDetailsFilled = Array.from(personalDetailsInputs).every(input => {
      const inputElement = input as HTMLInputElement;
      return inputElement.value.trim() !== '';
    });
  }

  Physiotherapy() {
    this.physiotherapy = true;
    this.physiology = false;
  }

  Physiology() {
    this.physiotherapy = false;
    this.physiology = true;
  }

  next(): void {
    this.checkPersonalDetailsFilled();
    if (this.personalDetailsFilled) {
      this.formSubmitted = true;
    }
  }

  back(): void {
    this.formSubmitted = false;
  }

  onSubmit(): void {
    // Set the selected athlete ID to the nutrition object
    if (this.selectedAthleteId) {
      this.nutrition.naAthlete = this.selectedAthleteId;
    }
    if(this.isFormValid()){
    this.assessmentService.addnutritional([this.nutrition]).subscribe(response => {
      console.log('Assessment submitted', response);
      this.notify.success("data");
      setTimeout(() => {
        this.Router.navigate(['nutritionlist']);
      }, 1000); 
    }, 
    error => {
      console.error('Error submitting assessment', error);
    });
  }
  }

  isFormValid(): boolean {
    return Object.keys(this.nutrition).every(key => {
      // Exclude 'OtherNutrientLevels' from validation
      return this.nutrition[key] !== '' && this.nutrition[key] !== null;
    });
  }

  fetchAthletes(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement) {
      const query = inputElement.value;
      this.assessmentService.getAthlete(query).subscribe(data => {
        this.athletes = data;
      }, error => {
        console.error('Error fetching athletes', error);
      });
    }
  }

  onAthleteSelected(event: Event): void {
    const inputElement = event.target as HTMLInputElement | null;
    if (inputElement) {
      const selectedAthlete = this.athletes.find(athlete => athlete.usrFullName === inputElement.value);
      if (selectedAthlete) {
        this.selectedAthleteId = selectedAthlete.usrId;
      }
    }
  }

  FetchAthelete(){
    console.log(this.id,"init",this.formSubmitted)
    if(this.id){
    this.assessmentService.fetchAtleteNutritional(this.id).subscribe((response: any) => {
      if (response && response.length > 0) {
        console.log(response,'data');
        const data = response[0];
        this.product = response[0];
        this.nutrition.naId = data.naId;
        this.nutrition.naDate = data.naDate ? data.naDate.split('T')[0] : '';
        this.nutrition.naAssessor = data.naAssessor;
        this.nutrition.naAthlete = data.naAthlete;
        this.nutrition.naAssessorName = data.assessorFullName;
        this.nutrition.naAthleteName = data.athleteFullName;
        this.nutrition.naBodyWeight = data.naBodyWeight;
        this.nutrition.naBodyHeight = data.naBodyHeight;
        this.nutrition.naBMI = data.naBMI;
        this.nutrition.naBodyFat = data.naBodyFat;
        this.nutrition.naFatFreeWeight = data.naFatFreeWeight;
        this.nutrition.naSubcutaneousFat = data.naSubcutaneousFat;
        this.nutrition.naVisceralFat = data.naVisceralFat;
        this.nutrition.naBodyWater = data.naBodyWater;
        this.nutrition.naSkeletalMuscle = data.naSkeletalMuscle;
        this.nutrition.naLeanMass = data.naLeanMass;
        this.nutrition.naBoneMass = data.naBoneMass;
        this.nutrition.naProtein = data.naProtein;
        this.nutrition.naBMR = data.naBMR;
        this.nutrition.naMetabolicAge = data.naMetabolicAge;
        this.nutrition.naHealthScore = data.naHealthScore;
        this.nutrition.naFollowupDate = data.naFollowupDate ? data.naFollowupDate.split('T')[0] : '';
      }
    },
    (error: any) => {
      console.error('Error fetching Assessment data', error);
    }
  );
}
  }
}
