<div *ngIf="isLoading" class="loading-spinner">
  <div class="spinner"></div>
</div>
<!-- <div class="text-center" *ngIf="this.athleteReport.length === 0">
  No Data!
</div> -->
<div class="row" >
    <div class="col-sm-8 flex-column d-flex stretch-card">
      <div class="row">
      
        <div class="col-lg-4 d-flex grid-margin stretch-card">
          <div class="card bg-blue">
            <div class="card-body text-white">
              <h3 class="font-weight-bold mb-3">{{ averageOverallPercentage }}%</h3>
              <div class="progress mb-3">
                <div class="progress-bar bg-warning" role="progressbar" [style.width.%]="averageOverallPercentage"
                  [attr.aria-valuenow]="averageOverallPercentage" aria-valuemin="0" aria-valuemax="100">
                </div>
              </div>
              <p class="pb-0 mb-0">Overall Score</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 d-flex grid-margin stretch-card">
          <div class="card player-diffrence-border">
            <div class="card-body">
              <h2 class="text-dark mb-2 font-weight-bold"> {{ fitnessCount }}</h2>
              <h4 class="card-title mb-2">Players Count</h4>
            </div>
          </div>
        </div>
        <div class="col-lg-4 d-flex grid-margin stretch-card">
          <!-- <div class="card justify-content-center player-diffrence-border">
            <div class="card-body ms-4">
              <img class="pro-img"
              src="{{TopPerformer[0]?.image}}">
            </div>
          </div> -->
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 grid-margin d-flex stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="d-flex align-items-center justify-content-between">
                <h4 class="card-title mb-2">{{fitnessHeader}} </h4>
                <div class="dropdown">
                  <a href="#" class="text-success btn btn-link dropdown-toggle dropdown-arrow-none"
                     data-bs-toggle="dropdown" id="settingsDropdownsales">
                    <i class="mdi mdi-dots-horizontal"></i></a>
                  <div class="dropdown-menu dropdown-menu-right navbar-dropdown"
                       aria-labelledby="settingsDropdownsales">
                       <a class="dropdown-item" (click)="filterPerformers('all','overall')">
                        <i class="mdi mdi-grease-pencil text-primary"></i>
                        All
                      </a>
                    <a class="dropdown-item" (click)="filterPerformers('Excellent','overall')">
                      <i class="mdi mdi-grease-pencil text-primary"></i>
                      Excellent
                    </a>
                    <a class="dropdown-item" (click)="filterPerformers('Good','overall')">
                      <i class="mdi mdi-delete text-primary"></i>
                      Good
                    </a>
                    <a class="dropdown-item" (click)="filterPerformers('Average','overall')">
                      <i class="mdi mdi-grease-pencil text-primary"></i>
                      Average
                    </a>
                    <a class="dropdown-item" (click)="filterPerformers('Poor','overall')">
                      <i class="mdi mdi-delete text-primary"></i>
                      Poor
                    </a>
                  </div>
                </div>
              </div>
              <div id="new-chart"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-4 flex-column d-flex stretch-card">
      <div class="row flex-grow">
        <div class="col-sm-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body" *ngIf="TopPerformer.length > 0">
              <div class="row">
                <div>
                  <h4 class="card-title fw-semibold">Top Performer</h4>
                  <h4   *ngIf="!sciStaff"  class="font-weight-bold text-name">Name : {{ TopPerformer[0]?.usrFullName }}</h4>
                  <h4 class="font-weight-bold text-id">ID :
                    {{ TopPerformer[0]?.pfaAthlete }}
                  </h4>
                  <p class="text-dark">{{ TopPerformer[0]?.pfaDate | date:'dd-MM-yyyy' }}</p>
                </div>
              </div>
              <div class="donut-container d-flex align-items-center justify-content-center mb-4">
                <div class="donut-circle" [ngClass]="{
                           'dark-green': TopPerformer[0]?.overallZScore >= 85,
                           'light-green': TopPerformer[0]?.overallZScore >= 70 && TopPerformer[0]?.overallZScore < 85,
                           'yellow': TopPerformer[0]?.overallZScore >= 40 && TopPerformer[0]?.overallZScore < 70,
                           'red': TopPerformer[0]?.overallZScore < 40,
                           'transparent-border': TopPerformer[0]?.overallZScore == null
                         }">
                  <div class="inner-circle">
                    <h3 class="font-weight-bold">{{ TopPerformer[0]?.overallZScore| number: '1.2-2' }}%</h3>
                  </div>
                </div>
                <!-- <p class="score-tag">Overall Score</p> -->
              </div>
              <table class="score-table">
                <tr>
                  <th>Test</th>
                  <th>Score</th>
                </tr>
                <tr>
                  <td class="score-title">BMI (kg/m²)</td>
                  <td class="score-value">{{ ( TopPerformer[0]?.pfaBMI % 1 === 0) 
                    ?  TopPerformer[0]?.pfaBMI 
                    : ( TopPerformer[0]?.pfaBMI | number: '1.2-2') }}</td>
                </tr>
                <tr>
                  <td class="score-title">MedBall (M)</td>
                  <td class="score-value">{{ ( TopPerformer[0]?.pfaSeatedMedBallThrow % 1 === 0) 
                    ?  TopPerformer[0]?.pfaSeatedMedBallThrow 
                    : ( TopPerformer[0]?.pfaSeatedMedBallThrow | number: '1.2-2') }}</td>
                </tr>
                <tr>
                  <td class="score-title">Speed (m/s)</td>
                  <td class="score-value">{{ ( TopPerformer[0]?.pfaSpeed % 1 === 0) 
                    ?  TopPerformer[0]?.pfaSpeed 
                    : ( TopPerformer[0]?.pfaSpeed | number: '1.2-2') }}</td>
                </tr>
                <tr>
                  <td class="score-title">Yo-Yo Test (M)</td>
                  <td class="score-value">{{ ( TopPerformer[0]?.pfaYoYoTest % 1 === 0) 
                    ?  TopPerformer[0]?.pfaYoYoTest 
                    : ( TopPerformer[0]?.pfaYoYoTest | number: '1.2-2') }}</td>
                </tr>
                <tr>
                  <td class="score-title">PushUps (min)</td>
                  <td class="score-value">{{ ( TopPerformer[0]?.pfaPushups % 1 === 0) 
                    ?  TopPerformer[0]?.pfaPushups 
                    : ( TopPerformer[0]?.pfaPushups | number: '1.2-2') }}</td>
                </tr>
              </table>
            </div>
            <div  class="card-body d-flex align-items-center justify-content-center" *ngIf="TopPerformer.length== 0">
              <h4 class="text-muted">No Data Available</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row row-cards row-deck">
      <div class="d-sm-flex justify-content-between align-items-center mb-2">
        <h4 class="card-title fw-semibold" >Overall {{fitnessTableHeader}} Performers</h4>
        <div>
          <div class="select-wrapper">
            
            <select [(ngModel)]="selectedStatus" (change)="onFitnessStatusChange()" class="form-select">
              <option selected disabled>Select Option</option>
              <option value="0">All</option>
              <option value="1">Excellent</option>
              <option value="2">Good</option>
              <option value="3">Average</option>
              <option value="4">Poor</option>
            </select>
          </div>
        </div>
      </div>
      <!-- Top Performers Section -->
      <div class="col-12">
        <div class="card">
          <h4 class="m-3" *ngIf="chat == undefined">{{fitnessTableHeader}} Performers</h4>
          <h4 class="m-3" *ngIf="chat">Athlete Data</h4>
          <div class="table-responsive">
            <table class="table table-hover table-outline table-vcenter text-nowrap card-table">
              <thead>
                <tr>
                  <th>Profile</th>
                  <th>Athletes</th>
                  <th>Overall%</th>
                  <th>BMI (kg/m²)</th>
                  <th>Pull And Hang (min)</th>
                  <th>Single Leg Squat (min)</th>
                 
                  <!-- <th>Assessment Date</th> -->
                </tr>
              </thead>
              <tbody *ngIf="FitnessPerformers && FitnessPerformers.length > 0; else noData">
                <tr *ngFor="let performer of FitnessPerformers">
                  <td class="text-center">
                   
                    <img class="avatar"
                    src="{{performer.image}}">
                  </td>
                  <td>
                    <div>{{ performer.usrFullName }}</div>
                    <div class="small text-muted">ID: {{ performer.pfaAthlete }}</div>
                  </td>
                  <td>
                    <div class="clearfix">
                      <div class="float-left"><strong>{{ performer.overallZScore| number: '1.2-2' }} %</strong></div>
                    </div>
                    <div class="progress progress-xs">
                      <div class="progress-bar" [ngClass]="{
                            'bg-lightgreen':  performer.overallZScore >= 85,
                            'bg-green':  performer.overallZScore >= 70 &&  performer.overallZScore < 85,
                            'bg-yellow':  performer.overallZScore >= 40 &&  performer.overallZScore  < 70,
                            'bg-red': performer.overallZScore < 40
                          }" role="progressbar" [style.width.%]=" performer.overallZScore"></div>
                    </div>
                  </td>
                  <td>{{ performer.pfaBMI | number:'1.2-2'}}</td>
                  <td>{{ performer.pfaPullAndHang }}</td>
                  <td>{{ performer.pfaSingleLegSquat }}</td>
                  <!-- <td>{{ performer.pfaDate | date:'dd/MM/yyyy' }}</td> -->
                </tr>
              </tbody>
              <!-- Template for when there is no data -->
              <ng-template #noData>
                <tr>
                  <td colspan="8" class="text-center">Data not available</td>
                </tr>
              </ng-template>
            </table>
          </div>
        </div>
      </div>

    </div>
<!--Strength-->    
    <div class="row">
      <div class="col-sm-12 grid-margin d-flex stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-center justify-content-between">
              <h4 class="card-title mb-2">{{strengthHeader}} </h4>
              <div class="dropdown">
                <a href="#" class="text-success btn btn-link dropdown-toggle dropdown-arrow-none"
                   data-bs-toggle="dropdown" id="settingsDropdownsales">
                  <i class="mdi mdi-dots-horizontal"></i></a>
                <div class="dropdown-menu dropdown-menu-right navbar-dropdown"
                     aria-labelledby="settingsDropdownsales">
                     <a class="dropdown-item" (click)="filterPerformers('all','strength')">
                      <i class="mdi mdi-grease-pencil text-primary"></i>
                      All
                    </a>
                  <a class="dropdown-item" (click)="filterPerformers('Excellent','strength')">
                    <i class="mdi mdi-grease-pencil text-primary"></i>
                    Excellent
                  </a>
                  <a class="dropdown-item" (click)="filterPerformers('Good','strength')">
                    <i class="mdi mdi-delete text-primary"></i>
                    Good
                  </a>
                  <a class="dropdown-item" (click)="filterPerformers('Average','strength')">
                    <i class="mdi mdi-grease-pencil text-primary"></i>
                    Average
                  </a>
                  <a class="dropdown-item" (click)="filterPerformers('Poor','strength')">
                    <i class="mdi mdi-delete text-primary"></i>
                    Poor
                  </a>
                </div>
              </div>
        
            </div>
            <div id="strength-chart"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="row row-cards row-deck">
      <div class="row row-cards row-deck">
        <!-- Top Strength IBM Section -->
        <div class="col-12">
          <div class="card">
            <h4 class="card-title fw-semibold">Strength International Benchmark (IBM)</h4>
            <div class="table-responsive">
              <table class="table table-hover table-outline table-vcenter text-nowrap card-table">
                <thead>
                  <tr>
                    <th>Test Name</th>
                    <th>Average Value</th>
                    <th *ngIf="shouldShowStrengthMaleColumn">IBM Male</th>
                    <th *ngIf="shouldShowStrengthFemaleColumn">IBM Female</th>
                    <th>Team Average PCTL</th>
                    <!-- <th>Assessment Date</th> -->
                  </tr>
                </thead>
                <tbody *ngIf="strengthTable && strengthTable.length > 0; else noData">
                  <tr *ngFor="let row of strengthTable">
                    <td>{{ row.testName }}</td>
                    <td>{{ row.averageValue }}</td>
                    <td *ngIf="shouldShowStrengthMaleColumn">{{ row.internationalBenchmarkMale }}</td>
                    <td *ngIf="shouldShowStrengthFemaleColumn">{{ row.internationalBenchmarkFemale }}</td>
                    <td>
                      <div class="circular-progress mb-2">
                        <svg width="60" height="60" class="progress-circle">
                          <!-- Background Circle -->
                          <circle class="background" cx="30" cy="30" r="27" stroke="green" stroke-width="6" fill="none"></circle>
                          <!-- Foreground Circle (Percentage) -->
                          <circle class="circle"
                                  cx="30" cy="30" r="27"
                                  [attr.stroke]="getColor(row.averageZScore)"
                                  stroke-width="6"
                                  stroke-dasharray="169.65"
                                  stroke-dashoffset="42.41"
                                  stroke-linecap="round">
                          </circle>
                          <!-- Hardcoded Percentage Text in Center -->
                          <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="#000" font-size="12">
                            {{ row.averageZScore | number: '1.2-2' }}
                          </text>
                        </svg>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <!-- Template for when there is no data -->
                <ng-template #noData>
                  <tr>
                    <td colspan="8" class="text-center">Data not available</td>
                  </tr>
                </ng-template>
              </table>
            </div>
          </div>
        </div>
  
      </div> 
      <div class="d-sm-flex justify-content-between align-items-center mb-2">
        <h4 class="card-title fw-semibold" >Strength {{strengthTableHeader}} Performers</h4>
        <div>
          <div class="select-wrapper">
            
            <select [(ngModel)]="selectedStrenghtStatus" (change)="loadStrengthTopPerformersData()" class="form-select">
              <option selected disabled>Select Option</option>
              <option value="0">All</option>
              <option value="1">Excellent</option>
              <option value="2">Good</option>
              <option value="3">Average</option>
              <option value="4">Poor</option>
            </select>
          </div>
        </div>
      </div>
 
      <!-- Top Performers Section -->
      <div class="col-12">
        <div class="card">
          <h4 class="m-3" *ngIf="chat == undefined">{{strengthTableHeader}} Performers</h4>
          <h4 class="m-3" *ngIf="chat">Athlete Data</h4>
          <div class="table-responsive">
            <table class="table table-hover table-outline table-vcenter text-nowrap card-table">
              <thead>
                <tr>
                  <th>Profile</th>
                  <th>Athletes</th>
                  <th>Overall %</th> <!-- New Column for Overall Z-Score -->
                  <th>PushUps (min)</th>
                  <th>PullUps (min)</th>
                  <th>Squat (min)</th>
                  <th>CurlUp (min)</th>
                  <th>Grip Strength Right (kgs)</th>
                  <th>Grip Strength Left (kgs)</th>
                  <th>Single Leg Squat (min)</th>
                  
                  <!-- <th>Assessment Date</th>  -->
                </tr>
              </thead>
              <tbody *ngIf="StrengthPerformers && StrengthPerformers.length > 0; else noData">
                <tr *ngFor="let performer of StrengthPerformers">
                  <td class="text-center">
                    
                    <img class="avatar"
                    src="{{performer.image}}">
                   
                  </td>
                  <td>
                    <div>{{ performer.usrFullName }}</div>
                    <div class="small text-muted">ID: {{ performer.pfaAthlete }}</div>
                  </td>
                  <td>
                    <div class="clearfix">
                      <div class="float-left"><strong>{{ performer.overallStrengthZScore| number: '1.2-2' }} %</strong></div>
                    </div>
                    <div class="progress progress-xs">
                      <div class="progress-bar" [ngClass]="{
                            'bg-lightgreen':  performer.overallStrengthZScore >= 85,
                            'bg-green':  performer.overallStrengthZScore >= 70 &&  performer.overallStrengthZScore < 85,
                            'bg-yellow':  performer.overallStrengthZScore >= 40 &&  performer.overallStrengthZScore  < 70,
                            'bg-red': performer.overallStrengthZScore < 40
                          }" role="progressbar" [style.width.%]=" performer.overallStrengthZScore"></div>
                    </div>
                  </td>
                  <td>{{ performer.pfaPushups }}</td>
                  <td>{{ performer.pfaPullups }}</td>
                  <td>{{ performer.pfaSquat }}</td>
                  <td>{{ performer.pfaPartialCurlUp }}</td>
                  <td>{{ performer.pfaGripStrengthRight | number:'1.2-2' }}</td>
                  <td>{{ performer.pfaGripStrengthLeft | number:'1.2-2' }}</td>
                  <td>{{ performer.pfaSingleLegSquat }}</td>
                  <!-- <td>{{ performer.pfaDate | date:'dd/MM/yyyy' }}</td>  -->
                </tr>
              </tbody>

              <!-- Template for when there is no data -->
              <ng-template #noData>
                <tr>
                  <td colspan="8" class="text-center">Data not available</td>
                </tr>
              </ng-template>

            </table>

          </div>
        </div>
      </div>

    </div>    

        <!-- Endurance-->
        <div class="row">
          <div class="col-sm-12 grid-margin d-flex stretch-card">
            <div class="card">
              <div class="card-body">
                <div class="d-flex align-items-center justify-content-between">
                  <h4 class="card-title mb-2">{{enduranceHeader}} </h4>
                  <div class="dropdown">
                    <a href="#" class="text-success btn btn-link dropdown-toggle dropdown-arrow-none"
                       data-bs-toggle="dropdown" id="settingsDropdownsales">
                      <i class="mdi mdi-dots-horizontal"></i></a>
                    <div class="dropdown-menu dropdown-menu-right navbar-dropdown"
                         aria-labelledby="settingsDropdownsales">
                         <a class="dropdown-item" (click)="filterPerformers('all','endurance')">
                          <i class="mdi mdi-grease-pencil text-primary"></i>
                          All
                        </a>
                         <a class="dropdown-item" (click)="filterPerformers('Excellent','endurance')">
                          <i class="mdi mdi-grease-pencil text-primary"></i>
                          Excellent
                        </a>
                        <a class="dropdown-item" (click)="filterPerformers('Good','endurance')">
                          <i class="mdi mdi-delete text-primary"></i>
                          Good
                        </a>
                        <a class="dropdown-item" (click)="filterPerformers('Average','endurance')">
                          <i class="mdi mdi-grease-pencil text-primary"></i>
                          Average
                        </a>
                        <a class="dropdown-item" (click)="filterPerformers('Poor','endurance')">
                          <i class="mdi mdi-delete text-primary"></i>
                          Poor
                        </a>
                    </div>
                  </div>
                </div>
                <div id="endurance-chart"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="row row-cards row-deck">
                      <!-- Top enduranceTable Section -->
                      <div class="col-12">
                        <div class="card">
                          <h4 class="card-title fw-semibold">Endurance International Benchmark (IBM)</h4>
                          <div class="table-responsive">
                            <table class="table table-hover table-outline table-vcenter text-nowrap card-table">
                              <thead>
                                <tr>
                                  <th>Test Name</th>
                                  <th>Average Value</th>
                                  <th *ngIf="shouldShowEnduranceMaleColumn">IBM Male</th>
                                  <th *ngIf="shouldShowEnduranceFemaleColumn">IBM Female</th>
                                  <th>Team Average PCTL</th>
                                  <!-- <th>Assessment Date</th> -->
                                </tr>
                              </thead>
                              <tbody *ngIf="enduranceTable && enduranceTable.length > 0; else noData">
                                <tr *ngFor="let row of enduranceTable">
                                  <td>{{ row.testName }}</td>
                                  <td>{{ row.averageValue}}</td>
                                  <td *ngIf="shouldShowEnduranceMaleColumn">{{ row.internationalBenchmarkMale }}</td>
                                  <td *ngIf="shouldShowEnduranceFemaleColumn">{{ row.internationalBenchmarkFemale }}</td>
                                  <td>
                                    <div class="circular-progress mb-2">
                                      <svg width="60" height="60" class="progress-circle">
                                        <!-- Background Circle -->
                                        <circle class="background" cx="30" cy="30" r="27" stroke="green" stroke-width="6" fill="none"></circle>
                                        <!-- Foreground Circle (Percentage) -->
                                        <circle class="circle"
                                                cx="30" cy="30" r="27"
                                                [attr.stroke]="getColor(row.averageZScore)"
                                                stroke-width="6"
                                                stroke-dasharray="169.65"
                                                stroke-dashoffset="42.41"
                                                stroke-linecap="round">
                                        </circle>
                                        <!-- Hardcoded Percentage Text in Center -->
                                        <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="#000" font-size="12">
                                          {{ row.averageZScore | number: '1.2-2' }}
                                        </text>
                                      </svg>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                              <!-- Template for when there is no data -->
                              <ng-template #noData>
                                <tr>
                                  <td colspan="8" class="text-center">Data not available</td>
                                </tr>
                              </ng-template>
                            </table>
                          </div>
                        </div>
                      </div>
          <div class="d-sm-flex justify-content-between align-items-center mb-2">
            <h4 class="card-title fw-semibold" >Endurance {{enduranceTableHeader}} Performers</h4>
            <div>
              <div class="select-wrapper">
                
                <select [(ngModel)]="selectedEndurancetStatus" (change)="loadEnduranceTopPerformersData()" class="form-select">
                  <option selected disabled>Select Option</option>
                  <option value="0">All</option>
                  <option value="1">Excellent</option>
                  <option value="2">Good</option>
                  <option value="3">Average</option>
                  <option value="4">Poor</option>
                </select>
              </div>
            </div>
          </div>
          <!-- Top Performers Section -->
          <div class="col-12">
            <div class="card">
              <h4 class="m-3" *ngIf="chat == undefined">{{enduranceTableHeader}} Performers</h4>
              <h4 class="m-3" *ngIf="chat">Athlete Data</h4>
              <div class="table-responsive">
                <table class="table table-hover table-outline table-vcenter text-nowrap card-table">
                  <thead>
                    <tr>
                      <th>Profile</th>
                      <th>Athletes</th>
                      <th>Overall %</th> <!-- New Column for Overall Z-Score -->
                      <th>Speed (sec)</th>
                      <th>Yo Yo Test (m)</th>
                      <th>Level</th>
                      <th>VO2 (ml/kg/min )</th>
                     
                      <!-- <th>Assessment Date</th>  -->
                    </tr>
                  </thead>
                  <tbody *ngIf="EndurancePerformers && EndurancePerformers.length > 0; else noData">
                    <tr *ngFor="let performer of EndurancePerformers">
                      <td class="text-center">
                      
                    <img class="avatar"
                    src="{{performer.image}}">
                      </td>
                      <td>
                        <div>{{ performer.usrFullName }}</div>
                        <div class="small text-muted">ID: {{ performer.pfaAthlete }}</div>
                      </td>
                      <td>
                        <div class="clearfix">
                          <div class="float-left"><strong>{{ performer.overallEnduranceZScore| number: '1.2-2' }} %</strong></div>
                        </div>
                        <div class="progress progress-xs">
                          <div class="progress-bar" [ngClass]="{
                                'bg-lightgreen':  performer.overallEnduranceZScore >= 85,
                                'bg-green':  performer.overallEnduranceZScore >= 70 &&  performer.overallEnduranceZScore < 85,
                                'bg-yellow':  performer.overallEnduranceZScore >= 40 &&  performer.overallEnduranceZScore  < 70,
                                'bg-red': performer.overallEnduranceZScore < 40
                              }" role="progressbar" [style.width.%]=" performer.overallEnduranceZScore"></div>
                        </div>
                      </td>
                      <td>{{ performer.pfaSpeed | number:'1.2-2' }}</td>
                      <td>{{ performer.pfaYoYoTest| number:'1.2-2' }}</td>
                      <td>{{ performer.pfaLevel | number:'1.2-2' }}</td>
                      <td>{{ performer.pfaVO2| number:'1.2-2' }}</td>
                      <!-- <td>{{ performer.pfaDate | date:'dd/MM/yyyy' }}</td>  -->
                    </tr>
                  </tbody>
    
                  <!-- Template for when there is no data -->
                  <ng-template #noData>
                    <tr>
                      <td colspan="8" class="text-center">Data not available</td>
                    </tr>
                  </ng-template>
    
                </table>
    
              </div>
            </div>
          </div>
    
        </div>  
<!--Flexibility-->
    <div class="row">
      <div class="col-sm-12 grid-margin d-flex stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-center justify-content-between">
              <h4 class="card-title mb-2">{{flexibilityHeader}} </h4>
              <div class="dropdown">
                <a href="#" class="text-success btn btn-link dropdown-toggle dropdown-arrow-none"
                   data-bs-toggle="dropdown" id="settingsDropdownsales">
                  <i class="mdi mdi-dots-horizontal"></i></a>
                <div class="dropdown-menu dropdown-menu-right navbar-dropdown"
                     aria-labelledby="settingsDropdownsales">
                     <a class="dropdown-item" (click)="filterPerformers('all','flexibility')">
                      <i class="mdi mdi-grease-pencil text-primary"></i>
                      All
                    </a>
                  <a class="dropdown-item" (click)="filterPerformers('Excellent','flexibility')">
                    <i class="mdi mdi-grease-pencil text-primary"></i>
                    Excellent
                  </a>
                  <a class="dropdown-item" (click)="filterPerformers('Good','flexibility')">
                    <i class="mdi mdi-delete text-primary"></i>
                    Good
                  </a>
                  <a class="dropdown-item" (click)="filterPerformers('Average','flexibility')">
                    <i class="mdi mdi-grease-pencil text-primary"></i>
                    Average
                  </a>
                  <a class="dropdown-item" (click)="filterPerformers('Poor','flexibility')">
                    <i class="mdi mdi-delete text-primary"></i>
                    Poor
                  </a>
                </div>
              </div>
            </div>
            <div id="flexibility-chart"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="row row-cards row-deck">
      <!--flexibilityTable-->
      <div class="col-12">
        <div class="card">
          <h4 class="card-title fw-semibold">Flexibility International Benchmark (IBM)</h4>
          <div class="table-responsive">
            <table class="table table-hover table-outline table-vcenter text-nowrap card-table">
              <thead>
                <tr>
                  <th>Test Name</th>
                  <th>Average Value</th>
                  <th *ngIf="shouldShowFlexibilityMaleColumn">IBM Male</th>
                  <th *ngIf="shouldShowFlexibilityFemaleColumn">IBM Female</th>
                  <th>Team Average PCTL</th>
                  <!-- <th>Assessment Date</th> -->
                </tr>
              </thead>
              <tbody *ngIf="flexibilityTable && flexibilityTable.length > 0; else noData">
                <tr *ngFor="let row of flexibilityTable">
                  <td>{{ row.testName }}</td>
                  <td>{{ row.averageValue}}</td>
                  <td *ngIf="shouldShowFlexibilityMaleColumn">{{ row.internationalBenchmarkMale }}</td>
                  <td *ngIf="shouldShowFlexibilityFemaleColumn">{{ row.internationalBenchmarkFemale }}</td>
                  <td>
                    <div class="circular-progress mb-2">
                      <svg width="60" height="60" class="progress-circle">
                        <!-- Background Circle -->
                        <circle class="background" cx="30" cy="30" r="27" stroke="green" stroke-width="6" fill="none"></circle>
                        <!-- Foreground Circle (Percentage) -->
                        <circle class="circle"
                                cx="30" cy="30" r="27"
                                [attr.stroke]="getColor(row.averageZScore)"
                                stroke-width="6"
                                stroke-dasharray="169.65"
                                stroke-dashoffset="42.41"
                                stroke-linecap="round">
                        </circle>
                        <!-- Hardcoded Percentage Text in Center -->
                        <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="#000" font-size="12">
                          {{ row.averageZScore | number: '1.2-2' }}
                        </text>
                      </svg>
                    </div>
                  </td>
                </tr>
              </tbody>
              <!-- Template for when there is no data -->
              <ng-template #noData>
                <tr>
                  <td colspan="8" class="text-center">Data not available</td>
                </tr>
              </ng-template>
            </table>
          </div>
        </div>
      </div>
      <div class="d-sm-flex justify-content-between align-items-center mb-2">
        <h4 class="card-title fw-semibold" >Flexibility {{flexibilityTableHeader}} Performers</h4>
        <div>
          <div class="select-wrapper">
            
            <select [(ngModel)]="selectedFlexibilitytStatus" (change)="loadFlexibilityTopPerformersData()" class="form-select">
              <option selected disabled>Select Option</option>
              <option value="0">All</option>
              <option value="1">Excellent</option>
              <option value="2">Good</option>
              <option value="3">Average</option>
              <option value="4">Poor</option>
            </select>
          </div>
        </div>
      </div>
      <!-- Top Performers Section -->
      <div class="col-12">
        <div class="card">
          <h4 class="m-3" *ngIf="chat == undefined">{{flexibilityTableHeader}} Performers</h4>
          <h4 class="m-3" *ngIf="chat">Athlete Data</h4>
          <div class="table-responsive">
            <table class="table table-hover table-outline table-vcenter text-nowrap card-table">
              <thead>
                <tr>
                  <th>Profile</th>
                  <th>Athletes</th>
                  <th>Overall %</th> <!-- New Column for Overall Z-Score -->
                  <th>Sit and Reach (cm)</th>
                 
                  <!-- <th>Assessment Date</th>  -->
                </tr>
              </thead>
              <tbody *ngIf="FlexibilityPerformers && FlexibilityPerformers.length > 0; else noData">
                <tr *ngFor="let performer of FlexibilityPerformers">
                  <td class="text-center">
                   
                    <img class="avatar"
                    src="{{performer.image}}">
                  </td>
                  <td>
                    <div>{{ performer.usrFullName }}</div>
                    <div class="small text-muted">ID: {{ performer.pfaAthlete }}</div>
                  </td>
                  <td>
                    <div class="clearfix">
                      <div class="float-left"><strong>{{ performer.overallFlexibilityScore| number: '1.2-2' }} %</strong></div>
                    </div>
                    <div class="progress progress-xs">
                      <div class="progress-bar" [ngClass]="{
                            'bg-lightgreen':  performer.overallFlexibilityScore >= 85,
                            'bg-green':  performer.overallFlexibilityScore >= 70 &&  performer.overallFlexibilityScore < 85,
                            'bg-yellow':  performer.overallFlexibilityScore >= 40 &&  performer.overallFlexibilityScore  < 70,
                            'bg-red': performer.overallFlexibilityScore < 40
                          }" role="progressbar" [style.width.%]=" performer.overallFlexibilityScore"></div>
                    </div>
                  </td>
                  <td>{{ performer.pfaSitNReach | number:'1.2-2' }}</td>
                   </tr>
              </tbody>

              <!-- Template for when there is no data -->
              <ng-template #noData>
                <tr>
                  <td colspan="8" class="text-center">Data not available</td>
                </tr>
              </ng-template>

            </table>

          </div>
        </div>
      </div>

    </div>    

<!--Balance-->
<div class="row">
  <div class="col-sm-12 grid-margin d-flex stretch-card">
    <div class="card">
      <div class="card-body">
        <div class="d-flex align-items-center justify-content-between">
          <h4 class="card-title mb-2">{{balanceHeader}} </h4>
          <div class="dropdown">
            <a href="#" class="text-success btn btn-link dropdown-toggle dropdown-arrow-none"
               data-bs-toggle="dropdown" id="settingsDropdownsales">
              <i class="mdi mdi-dots-horizontal"></i></a>
            <div class="dropdown-menu dropdown-menu-right navbar-dropdown"
                 aria-labelledby="settingsDropdownsales">
                 <a class="dropdown-item" (click)="filterPerformers('all','balance')">
                  <i class="mdi mdi-grease-pencil text-primary"></i>
                  All
                </a>
                 <a class="dropdown-item" (click)="filterPerformers('Excellent','balance')">
                  <i class="mdi mdi-grease-pencil text-primary"></i>
                  Excellent
                </a>
                <a class="dropdown-item" (click)="filterPerformers('Good','balance')">
                  <i class="mdi mdi-delete text-primary"></i>
                  Good
                </a>
                <a class="dropdown-item" (click)="filterPerformers('Average','balance')">
                  <i class="mdi mdi-grease-pencil text-primary"></i>
                  Average
                </a>
                <a class="dropdown-item" (click)="filterPerformers('Poor','balance')">
                  <i class="mdi mdi-delete text-primary"></i>
                  Poor
                </a>
            </div>
          </div>
        </div>
        <div id="balance-chart"></div>
      </div>
    </div>
  </div>
</div>
<div class="row row-cards row-deck">
    <!--balanceTable-->
    <div class="col-12">
      <div class="card">
        <h4 class="card-title fw-semibold">Balance International Benchmark (IBM)</h4>
        <div class="table-responsive">
          <table class="table table-hover table-outline table-vcenter text-nowrap card-table">
            <thead>
              <tr>
                <th>Test Name</th>
                <th>Average Value</th>
                <th *ngIf="shouldShowBalanceMaleColumn">IBM Male</th>
                <th *ngIf="shouldShowBalanceFemaleColumn">IBM Female</th>
                <th>Team Average PCTL</th>
                <!-- <th>Assessment Date</th> -->
              </tr>
            </thead>
            <tbody *ngIf="balanceTable && balanceTable.length > 0; else noData">
              <tr *ngFor="let row of balanceTable">
                <td>{{ row.testName }}</td>
                <td>{{ row.averageValue}}</td>
                <td *ngIf="shouldShowBalanceMaleColumn">{{ row.internationalBenchmarkMale }}</td>
                <td *ngIf="shouldShowBalanceFemaleColumn">{{ row.internationalBenchmarkFemale }}</td>
                <td>
                  <div class="circular-progress mb-2">
                    <svg width="60" height="60" class="progress-circle">
                      <!-- Background Circle -->
                      <circle class="background" cx="30" cy="30" r="27" stroke="green" stroke-width="6" fill="none"></circle>
                      <!-- Foreground Circle (Percentage) -->
                      <circle class="circle"
                              cx="30" cy="30" r="27"
                              [attr.stroke]="getColor(row.averageZScore)"
                              stroke-width="6"
                              stroke-dasharray="169.65"
                              stroke-dashoffset="42.41"
                              stroke-linecap="round">
                      </circle>
                      <!-- Hardcoded Percentage Text in Center -->
                      <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="#000" font-size="12">
                        {{ row.averageZScore | number: '1.2-2' }}
                      </text>
                    </svg>
                  </div>
                </td>
              </tr>
            </tbody>
            <!-- Template for when there is no data -->
            <ng-template #noData>
              <tr>
                <td colspan="8" class="text-center">Data not available</td>
              </tr>
            </ng-template>
          </table>
        </div>
      </div>
    </div>
  <div class="d-sm-flex justify-content-between align-items-center mb-2">
    <h4 class="card-title fw-semibold" >Balance {{balanceTableHeader}} Performers</h4>
    <div>
      <div class="select-wrapper">
        
        <select [(ngModel)]="selectedBalancesStatus" (change)="loadBalancesTopPerformersData()" class="form-select">
          <option value="0">All</option>
          <option value="1">Excellent</option>
          <option value="2">Good</option>
          <option value="3">Average</option>
          <option value="4">Poor</option>
        </select>
      </div>
    </div>
  </div>


  <!-- Top Performers Section -->
  <div class="col-12">
    <div class="card">
      <h4 class="m-3" *ngIf="chat == undefined">{{balanceTableHeader}} Performers</h4>
      <h4 class="m-3" *ngIf="chat">Athlete Data</h4>
      <div class="table-responsive">
        <table class="table table-hover table-outline table-vcenter text-nowrap card-table">
          <thead>
            <tr>
              <th>Profile</th>
              <th>Athletes</th>
              <th>Overall %</th>
              <th>Left Eyes Open (min/sec)</th>
              <th>Left Eyes Close (min/sec)</th>
              <th>Right Eyes Open (min/sec)</th>
              <th>Right Eyes Close (min/sec)</th>
           
              <!-- <th>Assessment Date</th>  -->
            </tr>
          </thead>
          <tbody *ngIf="BalancePerformers && BalancePerformers.length > 0; else noData">
            <tr *ngFor="let performer of BalancePerformers">
              <td class="text-center">
               
                <img class="avatar"
                src="{{performer.image}}">
              </td>
              <td>
                <div>{{ performer.usrFullName }}</div>
                <div class="small text-muted">ID: {{ performer.pfaAthlete }}</div>
              </td>
              <td>
                <div class="clearfix">
                  <div class="float-left"><strong>{{performer.overallBalanceZScore| number: '1.2-2' }} %</strong></div>
                </div>
                <div class="progress progress-xs">
                  <div class="progress-bar" [ngClass]="{
                        'bg-lightgreen': performer.overallBalanceZScore >= 85,
                        'bg-green': performer.overallBalanceZScore >= 70 && performer.overallBalanceZScore < 85,
                        'bg-yellow': performer.overallBalanceZScore >= 40 && performer.overallBalanceZScore  < 70,
                        'bg-red':performer.overallBalanceZScore < 40
                      }" role="progressbar" [style.width.%]="performer.overallBalanceZScore"></div>
                </div>
              </td>
              <td>{{ performer.pfaSingleLegBalanceLeftEyesOpen | number:'1.2-2' }}</td>
              <td>{{ performer.pfaSingleLegBalanceLeftEyesClose | number:'1.2-2' }}</td>
              <td>{{ performer.pfaSingleLegBalanceRightEyesOpen | number:'1.2-2' }}</td>
              <td>{{ performer.pfaSingleLegBalanceRightEyesClose | number:'1.2-2' }}</td>
            
            </tr>
          </tbody>
          <ng-template #noData>
            <tr>
              <td colspan="8" class="text-center">Data not available</td>
            </tr>
          </ng-template>

        </table>

      </div>
    </div>
  </div>

</div>     
  </div>