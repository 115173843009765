<div class="container-fluid">
    <div class="row">
      <div class="col-sm-6 col-md-7 intro-section">
        <div class="brand-wrapper">
          <h1><a>Logo</a></h1>
        </div>
        <div class="intro-content-wrapper">
          <h1 class="intro-title">Welcome to website !</h1>
          <p class="intro-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna</p>
          <!-- <a href="#!" class="btn btn-read-more">Read more</a> -->
        </div>
   
      </div>

      <div class="col-sm-6 col-md-5 form-section" *ngIf="changePwd">
        <div class="login-wrapper">
          <div class="text-container mb-4">
            <h3 class="title mb-2 mt-2">Reset Password</h3>
          </div>
          <div class="form-group">
            <label for="email">Email</label>
            <input type="email" id="email" class="form-control" [(ngModel)]="this.usrEmail" name="email" readonly>
          </div>
          <div class="form-group">
            <label for="new-password">New Password</label>
            <input type="password" id="new-password" class="form-control" [(ngModel)]="newPassword" name="newPassword" required>
          </div>
          <div class="form-group">
            <label for="confirm-password">Confirm Password</label>
            <input type="password" id="confirm-password" class="form-control" [(ngModel)]="confirmPassword" name="confirmPassword" required>
          </div>
          <div class="form-group mt-5">
            <button type="submit" (click)="changePasswords()" class="btn btn-primary">
              <small><i class="far fa-user pr-2"></i>Change Password</small>
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="!changePwd">
         <p>Link is expired Reset Password again.</p>
      </div>
    </div>
  </div>
  
<div class="toaster">

  <ng-toast />
    
  </div>