import { AgGridAngular } from "ag-grid-angular";
import { ColDef } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { Component, Input, NgZone, ViewChild } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Router, RouterLink } from "@angular/router";
import { AssessmentService } from "../../../services/assessment.service";
import { PrivilegeService } from "../../../services/privilage.service";
import { Privilege } from "../../../model/Privillage";
import { UserService } from "../../../services/user.service";
import { User } from "../../../model/User";
import { SharedService } from "../../../services/shared.service";
import { FileuploadComponent } from "../../fileupload/fileupload.component";
 
// Row Data Interface
interface IRow {
  playername: string;
  Assessor: string;
  priority: string;
  date: string;
}
 
@Component({
  selector: 'app-physiology-list',
  standalone: true,
  imports: [CommonModule, RouterLink, AgGridAngular,FileuploadComponent],
  templateUrl: './physiology-list.component.html',
  styleUrls: ['./physiology-list.component.css'] // Corrected 'styleUrl' to 'styleUrls'
})
export class PhysiologyListComponent {
  @ViewChild('fileUploadModal') fileUploadModal: FileuploadComponent | undefined;
  @Input() name: any;
  players: any;
  user: User | null = null;
  isPlayer: boolean = false;
  isAdmin: boolean = false;
  privilege:Privilege;
  Isplayer: boolean =false;
  OrgAdmin: boolean = false;
  Admin: boolean;
  detail: any;
  org: any;
  Coach: boolean =false;
  usrId: any;
  constructor(
    private router: Router,
    private assessmentService: AssessmentService,private userService:UserService,
    private ngZone: NgZone, private sharedService: SharedService
    ,private priService:PrivilegeService) {
      this.privilege = this.priService.getPrivileges();
      this.Isplayer = this.privilege.isPlayer;
      this.Admin = this.privilege.isAdmin;
      this.Coach = this.privilege?.isCoach ?? false; 
      this.OrgAdmin = this.privilege.isOrgAdmin;}
  themeClass = "ag-theme-quartz";
 
  // Column Definitions: Defines & controls grid columns.
  rowData: IRow[] = [];
 
  colDefs: ColDef[] = [];
 
  defaultColDef: ColDef = {
    flex: 1,
    sortable: true,
    filter: true,
  };
 
  ngOnInit() {
    this.sharedService.detail$.subscribe((data: any) => {
      this.detail = data;
      this.org=this.detail.user.usrOrganization;
      this.usrId=this.detail.user.usrId;
    });
    this.user = this.userService.getUser();
    this.colDefs = this.getColumnDefinitions();
if(this.Coach){
  this.assessmentService.getplaByCoachId(this.usrId).subscribe((res: any) => {
    console.log(res, 'player');
    this.players = res;
    this.rowData = res.map((item: any) => ({
      id: `${item.plaId}`,
      playerId: `${item.plaAthlete}`,
      playername: `${item.athleteName}`,
      Assessor: `${item.assessorName}`,
      result: `${item.plaTestResults}`,
      Recommendation: `${item.plaRecommendations}`,
      HeartRateVariablity:`${item.plaHeartRateVariablity}`,
      date: new Date(item.plaDate).toLocaleDateString('en-US'),
      priority: this.getPriority(item)
    }));

    this.colDefs = this.getColumnDefinitions();
  }, error => {
    console.error('Error getting assessment', error);
  });
}else{
  this.assessmentService.getPhysiologyDetail(this.org).subscribe((res: any) => {
    console.log(res, 'player');
    this.players = res;
    this.rowData = res.map((item: any) => ({
      id: `${item.plaId}`,
      playerId: `${item.plaAthlete}`,
      playername: `${item.athleteName}`,
      Assessor: `${item.assessorName}`,
      result: `${item.plaTestResults}`,
      Recommendation: `${item.plaRecommendations}`,
      HeartRateVariablity:`${item.plaHeartRateVariablity}`,
      date: new Date(item.plaDate).toLocaleDateString('en-US'),
      priority: this.getPriority(item)
    }));

    this.colDefs = this.getColumnDefinitions();
  }, error => {
    console.error('Error getting assessment', error);
  });
}
  }
 
  getPriority(item: any): string {
    if (item.pfaSpeed > 0 || item.pfaAgility > 0) {
      return 'High';
    } else {
      return 'Low';
    }
  }
 
  getColumnDefinitions(): ColDef[] {
    const columns: ColDef[] = [
      { field: "playername" },
      { field: "Assessor" },
      { field: "result" },
      {field:"HeartRateVariablity"},
      { field: "Recommendation" },
      { field: "date" },
      // { field: "priority",
      //   cellStyle: params => {
      //     if (params.value === 'Low') {
      //       return { color: 'red' };
      //     } else {
      //       return { color: 'green' };
      //     }
      //   }
      // },
      {
        headerName: 'Action',
        cellRenderer: (_params: any) => {
          const container = document.createElement('div');
          container.style.display = 'flex';
          container.style.justifyContent = 'space-around';
 
          const viewButton = document.createElement('button');
          viewButton.classList.add('btn');
          viewButton.innerHTML = '<i class="fa fa-eye"></i>';
          viewButton.addEventListener('click', () => {
            this.ngZone.run(() => {
              if (this.isPlayer) {
                this.ngZone.run(() => {
                this.router.navigate(['/physiologydetail']);
                });
              } else {
                this.ngZone.run(() => {
                this.router.navigate(['/physiologydetaillist'], {
                  queryParams: { id: _params.data.playerId },
                  state: { playerId: _params.data.playerId }
                });
              })
              }
            });
          });
 
          const editButton = document.createElement('button');
          editButton.classList.add('btn');
          editButton.innerHTML = '<i class="fa fa-edit"></i>';
          editButton.addEventListener('click', () => {
            this.ngZone.run(() => {
              // this.router.navigate(['/physiologyeditform'], {
              //   queryParams: { id: _params.data.id }
              // });
              this.router.navigate(['/physiology'], {
                queryParams: { id: _params.data.id }
              });
            });
          });
 
          container.appendChild(viewButton);
          if (!this.Isplayer && !this.OrgAdmin && !this.Coach) {
            container.appendChild(editButton);
            }
 
          return container;
        },
        width: 120
      }
    ];
 
    if (this.isAdmin || this.OrgAdmin) {
      return columns;
    } else if (this.isPlayer) {
      return columns.filter(col => col.field !== 'playername');
    } else {
      return columns.filter(col => col.field !== 'Assessor');
    }
  }
  openModal(): void {
    // Use Bootstrap's modal function to open the modal
    const modalElement = document.getElementById('fileUploadModal')!;
    const modal = new (window as any).bootstrap.Modal(modalElement);
    modal.show();
  }
}