import { Component } from '@angular/core';
import { AssessmentService } from '../../../services/assessment.service';
import { ActivatedRoute, Router, RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { NotificationService } from '../../../services/Notifications.service';
import { CommonModule } from '@angular/common';
import { AgGridAngular } from 'ag-grid-angular';
import { FormsModule } from '@angular/forms';
import { NgToastModule } from 'ng-angular-popup';
import { NgSelectModule } from '@ng-select/ng-select';

@Component({
  selector: 'app-editorganization',
  standalone: true,
  imports: [CommonModule, RouterOutlet, AgGridAngular, RouterLink, RouterModule, FormsModule,NgToastModule,NgSelectModule],
  templateUrl: './editorganization.component.html',
  styleUrl: './editorganization.component.css'
})
export class EditorganizationComponent {
  orgName: any;
  orgId: any;


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private assessmentService: AssessmentService,
    private notify: NotificationService,
  ) {
    this.route.queryParams.subscribe((params:any) => {
      this.orgName = params['orgName'];
      this.orgId = params['id'];
    });
  }


  onSubmit(){
    if (this.orgName) {
      this.assessmentService.AddOrganisation(this.orgId, this.orgName).subscribe(
        (response) => {
          console.log('Organization updated successfully:', response);
          this.notify.success('Organization updated successfully');
          setTimeout(() => {
            this.router.navigate(['/organization']);
          }, 1000);
        
        },
        (error) => {
          this.notify.failed('Organization failed');
          console.error('Error updating organization:', error);
        }
      );
    } else {
      alert('Please enter an organization name.');
    }
  }
}
