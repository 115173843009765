<div *ngIf="isLoading" class="loading-spinner">
  <div class="spinner"></div>
</div>

<div class="body-wrapper" [ngClass]="{ 'blurred': isLoading }">

  <div>
    <div class="container-fluid">
      <div class="d-flex justify-content-end mb-3">
        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <button type="button" class="btn btn-primary" (click)="downloadPDF()">
            <i class='bx bx-download'></i> Download
          </button>
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div id="pdf-content">
                <div *ngFor="let athlete of admitcardReport; let i = index" class="modal-body">
                  <div class="card1">
                    <div class="logo-content">
                      <div>
                        <h2>AMS SPORTS</h2>
                        <p>Centre For Sports Science</p>
                      </div>
                      <div class="logo-circle">
                        <img src="/assets/image/new_id.jpg" alt="Logo" class="logo-img">
                      </div>
                    </div>

                    <!-- Display each athlete's image -->
                    <div class="img">
                      <img *ngIf="imageUrls[i]" [src]="imageUrls[i]" alt="Athlete Image">
                    </div>

                    <h2 class="title">
                      <span>ID : </span>
                      {{athlete.usrId}}
                    </h2>
                    <h2 class="title">
                      <span>Name: </span>
                      {{athlete.usrFullName}}
                    </h2>
                    <p class="info" style="font-size: 15px;">DOB : {{athlete.usrDoB | date:'dd-MM-yyyy'}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="drop-items d-flex justify-content-end align-items-center mb-3 gap-4">
    <div class="select-wrapper">
      <button class="btn btn-primary" (click)="DownloadClick()" data-bs-toggle="modal"
        data-bs-target="#exampleModal">Download My Id</button>
    </div>
    <div class="select-wrapper">
      <button class="btn btn-primary" [routerLink]="['/report']"> My Report</button>
    </div>
    <div class="select-wrapper">
      <ng-select [(ngModel)]="selectedOption" [items]="options" bindLabel="label" bindValue="value"
        placeholder="Select an Option" [searchable]="false" [clearable]="false" class="ng-select-custom"
        (change)="onDropdownChange()">
        <!-- Custom label template -->
        <ng-template ng-label-tmp let-item="item">
          <span>{{ item.label }}</span>
          <i class="fa fa-chevron-down ml-2"></i>
      </ng-template>
      
      <!-- Optional custom dropdown item template -->
      <ng-template ng-option-tmp let-item="item">
          <span>{{ item.label }}</span>
      </ng-template>
      </ng-select>

    </div>
  </div>

  <div *ngIf="selectedOption == 'Level One'">
    <div class="container-fluid p-0">
      <div *ngIf="lastLevelOneAssessment" class="row">
        <div class="col-6 col-sm-4 col-lg-2"
          *ngFor="let metric of ['Height', 'Weight', 'RulerDrop', 'HandGrip', 'SeatedMedBall', 'SitnReach']">
          <div>
            <div class="card">
              <div class="card-body p-3">
                <div class="text-muted mb-2">{{ metric.replace('Height', 'Height')
                  .replace('Weight', 'Weight')
                  .replace('RulerDrop', 'Ruler Drop')
                  .replace('HandGrip', 'Hand Grip')
                  .replace('SeatedMedBall', 'Seated Med Ball')
                  .replace('SitnReach', 'Sit and Reach') }}</div>
                <div class="h3 m-0">{{ lastLevelOneAssessment['loa' + metric] != null && lastLevelOneAssessment['loa' +
                  metric] !== '' ? lastLevelOneAssessment['loa' + metric] : 0 }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 d-flex align-items-strech">
          <div class="card w-100">
            <div class="card-body">
              <div class="d-sm-flex d-block align-items-center justify-content-between mb-9">
                <div class="mb-3 mb-sm-0">
                  <h5 class="card-title fw-semibold">{{ selectedOption }} Performance Graph</h5>
                  <p class="cart-desc">range of last 2 {{ selectedOption }}</p>
                </div>
              </div>
              <div id="levelone-graph"></div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="row">
              <div class="col-lg-12">
                <div class="card">
                  <div class="card-body">
                    <h5 class="card-title fw-semibold">Current Assessment</h5>
                    <div class="table-responsive">
                      <table class="table table-hover table-outline table-vcenter text-nowrap card-table">
                        <thead>
                          <tr>
                            <th>Metric</th>
                            <th>Value</th>
                          </tr>
                        </thead>
                        <tbody id="levelone-table-body">
                          <tr>
                            <td>Height</td>
                            <td id="loaHeight"></td>
                          </tr>
                          <tr>
                            <td>Height Range</td>
                            <td id="loaHeightRange"></td>
                          </tr>
                          <tr>
                            <td>Weight</td>
                            <td id="loaWeight"></td>
                          </tr>
                          <tr>
                            <td>Weight Range</td>
                            <td id="loaWeightRange"></td>
                          </tr>
                          <tr>
                            <td>Ruler Drop</td>
                            <td id="loaRulerDrop"></td>
                          </tr>
                          <tr>
                            <td>Ruler Drop Range</td>
                            <td id="loaRulerDropRange"></td>
                          </tr>
                          <tr>
                            <td>Hand Grip</td>
                            <td id="loaHandGrip"></td>
                          </tr>
                          <tr>
                            <td>Hand Grip Range</td>
                            <td id="loaHandGripRange"></td>
                          </tr>
                          <tr>
                            <td>Seated Med Ball</td>
                            <td id="loaSeatedMedBall"></td>
                          </tr>
                          <tr>
                            <td>Seated Med Ball Range</td>
                            <td id="loaSeatedMedBallRange"></td>
                          </tr>
                          <tr>
                            <td>Sit and Reach</td>
                            <td id="loaSitnReach"></td>
                          </tr>
                          <tr>
                            <td>Sit and Reach Range</td>
                            <td id="loaSitnReachRange"></td>
                          </tr>
                          <tr>
                            <td>Vertical Jump</td>
                            <td id="loaVerticalJump"></td>
                          </tr>
                          <tr>
                            <td>Vertical Jump Range</td>
                            <td id="loaVerticalJumpRange"></td>
                          </tr>
                          <tr>
                            <td>Wall Toss</td>
                            <td id="loaWallToss"></td>
                          </tr>
                          <tr>
                            <td>Wall Toss Range</td>
                            <td id="loaWallTossRange"></td>
                          </tr>
                          <tr>
                            <td>30m Sprint</td>
                            <td id="loa30mSprint"></td>
                          </tr>
                          <tr>
                            <td>30m Sprint Range</td>
                            <td id="loa30mSprintRange"></td>
                          </tr>
                          <tr>
                            <td>6x10 ShuttleRun</td>
                            <td id="loa6x10ShuttleRun"></td>
                          </tr>
                          <tr>
                            <td>6x10 ShuttleRun Range</td>
                            <td id="loa6x10ShuttleRunRange"></td>
                          </tr>
                          <tr>
                            <td>800m Run</td>
                            <td id="loa800mRun"></td>
                          </tr>
                          <tr>
                            <td>800m Run Range</td>
                            <td id="loa800mRunRange"></td>
                          </tr>
                          <tr>
                            <td>Average Range</td>
                            <td id="loaAverageRange"></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="selectedOption == 'Physical Fitness'">
    <div class="container-fluid p-0">
      <div *ngIf="lastFitnessAssessment" class="row">
        <!-- Dynamic Fitness Cards -->
        <div class="col-6 col-sm-4 col-lg-2"
          *ngFor="let metric of ['50mSprint', '30mSprint', 'Level', 'VO2', 'SitNReach', 'TTest']">
          <div>
            <div class="card">
              <div class="card-body p-3">
                <div class="text-muted mb-2">{{ metric.replace('Drop', ' Drop').replace('ReactionTime', ' Reaction
                  Time')
                  }}
                </div>
                <div class="h3 m-0">{{ lastFitnessAssessment['pfa' + metric] != null && lastFitnessAssessment['pfa' +
                  metric] !== '' ? lastFitnessAssessment['pfa' + metric] : 0 }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Charts Section -->
      <div class="row">
        <div class="col-lg-12 d-flex align-items-strech">
          <div class="card w-100">
            <div class="card-body">
              <div class="d-sm-flex d-block align-items-center justify-content-between mb-9">
                <div class="mb-3 mb-sm-0">
                  <h5 class="card-title fw-semibold">{{ selectedOption }} Performance Graph</h5>
                  <p class="cart-desc">range of last 2 {{ selectedOption }}</p>
                </div>
              </div>
              <div id="fitness-graph"></div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="row">
              <div class="col-lg-12">
                <div class="card">
                  <div class="card-body">
                    <h5 class="card-title fw-semibold">Current Assessment</h5>
                    <div class="table-responsive">
                      <table class="table table-hover table-outline table-vcenter text-nowrap card-table">
                        <thead>
                          <tr>
                            <th>Metric</th>
                            <th>Value</th>
                          </tr>
                        </thead>
                        <tbody id="fitness-table-body">
                          <tr>
                            <td>Height</td>
                            <td id="pfaHeight"></td>
                          </tr>
                          <tr>
                            <td>Weight</td>
                            <td id="pfaWeight"></td>
                          </tr>
                          <tr>
                            <td>BMI</td>
                            <td id="pfaBMI"></td>
                          </tr>
                          <tr>
                            <td>Speed</td>
                            <td id="pfaSpeed"></td>
                          </tr>
                          <tr>
                            <td>Yo-Yo Test</td>
                            <td id="pfaYoYoTest"></td>
                          </tr>
                          <tr>
                            <td>Single Leg Balance (Left Eyes Open)</td>
                            <td id="pfaSingleLegBalanceLeftEyesOpen"></td>
                          </tr>
                          <tr>
                            <td>Single Leg Balance (Left Eyes Closed)</td>
                            <td id="pfaSingleLegBalanceLeftEyesClose"></td>
                          </tr>
                          <tr>
                            <td>Single Leg Balance (Right Eyes Open)</td>
                            <td id="pfaSingleLegBalanceRightEyesOpen"></td>
                          </tr>
                          <tr>
                            <td>Single Leg Balance (Right Eyes Closed)</td>
                            <td id="pfaSingleLegBalanceRightEyesClose"></td>
                          </tr>
                          <tr>
                            <td>Squat</td>
                            <td id="pfaSquat"></td>
                          </tr>
                          <tr>
                            <td>Partial Curl Up</td>
                            <td id="pfaPartialCurlUp"></td>
                          </tr>
                          <tr>
                            <td>Grip Strength (Right)</td>
                            <td id="pfaGripStrengthRight"></td>
                          </tr>
                          <tr>
                            <td>Grip Strength (Left)</td>
                            <td id="pfaGripStrengthLeft"></td>
                          </tr>
                          <tr>
                            <td>Sit and Reach</td>
                            <td id="pfaSitNReach"></td>
                          </tr>
                          <tr>
                            <td>Standing Broad Jump</td>
                            <td id="pfaStandingBroadJump"></td>
                          </tr>
                          <tr>
                            <td>Vertical Jump</td>
                            <td id="pfaVerticalJump"></td>
                          </tr>
                          <tr>
                            <td>Seated Med Ball Throw</td>
                            <td id="pfaSeatedMedBallThrow"></td>
                          </tr>
                          <tr>
                            <td>Upper Body Power</td>
                            <td id="pfaUpperBodyPower"></td>
                          </tr>
                          <tr>
                            <td>Upper Body Force</td>
                            <td id="pfaUpperBodyForce"></td>
                          </tr>
                          <tr>
                            <td>Lower Body Power</td>
                            <td id="pfaLowerBodyPower"></td>
                          </tr>
                          <tr>
                            <td>Lower Body Force</td>
                            <td id="pfaLowerBodyForce"></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="selectedOption == 'Nutrition'">
    <div class="container-fluid p-0">
      <div *ngIf="lastNutritionAssessment" class="row">
        <!-- Dynamic Nutrition Cards -->
        <div class="col-6 col-sm-4 col-lg-2"
          *ngFor="let metric of ['BodyWeight', 'BMI', 'BodyFat', 'SkeletalMuscle', 'BodyWater', 'HealthScore']">
          <div>
            <div class="card">
              <div class="card-body p-3">
                <div class="text-muted mb-2">{{ metric.replace('BodyWeight', 'Body Weight')
                  .replace('BMI', 'BMI')
                  .replace('BodyFat', 'Body Fat')
                  .replace('SkeletalMuscle', 'Skeletal Muscle')
                  .replace('BodyWater', 'Body Water')
                  .replace('HealthScore', 'Health Score') }}</div>
                <div class="h3 m-0">{{ lastNutritionAssessment['na' + metric] != null && lastNutritionAssessment['na' +
                  metric] !== '' ? lastNutritionAssessment['na' + metric] : 0 }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Charts Section -->
      <div class="row">
        <div class="col-lg-12 d-flex align-items-stretch">
          <div class="card w-100">
            <div class="card-body">
              <div class="d-sm-flex d-block align-items-center justify-content-between mb-9">
                <div class="mb-3 mb-sm-0">
                  <h5 class="card-title fw-semibold">{{ selectedOption }} Performance Graph</h5>
                  <p class="card-desc">Range of last 2 {{ selectedOption }}</p>
                </div>
              </div>
              <div id="nutritional-graph"></div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="row">
              <div class="col-lg-12">
                <div class="card">
                  <div class="card-body">
                    <h5 class="card-title fw-semibold">Current Assessment</h5>
                    <div class="table-responsive">
                      <table class="table table-hover table-outline table-vcenter text-nowrap card-table">
                        <thead>
                          <tr>
                            <th>Metric</th>
                            <th>Value</th>
                          </tr>
                        </thead>
                        <tbody id="nutritional-table-body">
                          <tr>
                            <td>Body Weight</td>
                            <td id="naBodyWeight"></td>
                          </tr>
                          <tr>
                            <td>Body Height</td>
                            <td id="naBodyHeight"></td>
                          </tr>
                          <tr>
                            <td>BMI</td>
                            <td id="naBMI"></td>
                          </tr>
                          <tr>
                            <td>Body Fat</td>
                            <td id="naBodyFat"></td>
                          </tr>
                          <tr>
                            <td>Fat Free Weight</td>
                            <td id="naFatFreeWeight"></td>
                          </tr>
                          <tr>
                            <td>Subcutaneous Fat</td>
                            <td id="naSubcutaneousFat"></td>
                          </tr>
                          <tr>
                            <td>Visceral Fat</td>
                            <td id="naVisceralFat"></td>
                          </tr>
                          <tr>
                            <td>Body Water</td>
                            <td id="naBodyWater"></td>
                          </tr>
                          <tr>
                            <td>Skeletal Muscle</td>
                            <td id="naSkeletalMuscle"></td>
                          </tr>
                          <tr>
                            <td>Lean Mass</td>
                            <td id="naLeanMass"></td>
                          </tr>
                          <tr>
                            <td>Bone Mass</td>
                            <td id="naBoneMass"></td>
                          </tr>
                          <tr>
                            <td>Protein</td>
                            <td id="naProtein"></td>
                          </tr>
                          <tr>
                            <td>BMR</td>
                            <td id="naBMR"></td>
                          </tr>
                          <tr>
                            <td>Metabolic Age</td>
                            <td id="naMetabolicAge"></td>
                          </tr>
                          <tr>
                            <td>Health Score</td>
                            <td id="naHealthScore"></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="selectedOption == 'Cognitive'">
    <div class="container-fluid p-0">
      <div *ngIf="lastCognitiveAssessment" class="row">
        <!-- Dynamic Fitness Cards -->
        <div class="col-6 col-sm-4 col-lg-2"
          *ngFor="let metric of ['RulerDrop', 'Blazepods', 'BlazepodsTime', 'WallToss', 'PlateTaping']">
          <div>
            <div class="card">
              <div class="card-body p-3">
                <div class="text-muted mb-2">{{ metric.replace('Drop', ' Drop').replace('ReactionTime', ' Reaction
                  Time').replace('Endurance', ' Problem Solving').replace('Flexibility', ' Decision-Making') }}</div>
                <div class="h3 m-0">{{ lastCognitiveAssessment['cna' + metric] != null && lastCognitiveAssessment['cna'
                  + metric] !== '' ? lastCognitiveAssessment['cna' + metric] : 0 }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Charts Section -->
    <div class="row">
      <div class="col-lg-12 d-flex align-items-strech">
        <div class="card w-100">
          <div class="card-body">
            <div class="d-sm-flex d-block align-items-center justify-content-between mb-9">
              <div class="mb-3 mb-sm-0">
                <h5 class="card-title fw-semibold">{{ selectedOption }} Performance Graph</h5>
                <p class="cart-desc">range of last 2 {{ selectedOption }}</p>
              </div>
            </div>
            <div id="cognitive-graph"></div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="row">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title fw-semibold">Current Assessment</h5>
                  <div class="table-responsive">
                    <table class="table table-hover table-outline table-vcenter text-nowrap card-table">
                      <thead>
                        <tr>
                          <th>Metric</th>
                          <th>Value</th>
                        </tr>
                      </thead>
                      <tbody id="cognitive-table-body">
                      </tbody>
                      <tr>
                        <td>Ruler Drop</td>
                        <td id="cnaRulerDrop"></td>
                      </tr>
                      <tr>
                        <td>Blaze pods</td>
                        <td id="cnaBlazepods"></td>
                      </tr>
                      <tr>
                        <td>Blaze pods Time</td>
                        <td id="cnaBlazepodsTime"></td>
                      </tr>
                      <tr>
                        <td>Wall Toss</td>
                        <td id="cnaWallToss"></td>
                      </tr>
                      <tr>
                        <td>Plate Taping</td>
                        <td id="cnaPlateTaping"></td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="selectedOption == 'Injury Prevention'">
    <div class="container-fluid p-0">
      <div *ngIf="lastInjuryAssessment" class="row">
        <!-- Dynamic Fitness Cards -->
        <div class="col-6 col-sm-4 col-lg-2"
          *ngFor="let metric of ['TotalScore', 'DeepSquat', 'InlineLunge', 'HurdleStep', 'PushUp', 'ActiveSLR']">
          <div>
            <div class="card">
              <div class="card-body p-3 ">
                <div class="text-muted mb-2">{{ metric.replace('TotalScore', 'Total Score')
                  .replace('DeepSquat', 'Deep Squat')
                  .replace('InlineLunge', 'Inline Lunge')
                  .replace('HurdleStep', 'Hurdle Step')
                  .replace('PushUp', 'Push Up')
                  .replace('ActiveSLR', 'ActiveSLR') }}</div>
                <div class="h3 m-0">{{ lastInjuryAssessment['ipa' + metric] != null && lastInjuryAssessment['ipa' +
                  metric] !== '' ? lastInjuryAssessment['ipa' + metric] : 0 }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Charts Section -->
      <div class="row">
        <div class="col-lg-12 d-flex align-items-stretch">
          <div class="card w-100">
            <div class="card-body">
              <div class="d-sm-flex d-block align-items-center justify-content-between mb-9">
                <div class="mb-3 mb-sm-0">
                  <h5 class="card-title fw-semibold">{{ selectedOption }} Performance Graph</h5>
                  <p class="cart-desc">range of last 2 {{ selectedOption }}</p>
                </div>
              </div>
              <div id="injury-graph"></div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="row">
              <div class="col-lg-12">
                <div class="card">
                  <div class="card-body">
                    <h5 class="card-title fw-semibold">Current Assessment</h5>
                    <div class="table-responsive">
                      <table class="table table-hover table-outline table-vcenter text-nowrap card-table">
                        <thead>
                          <tr>
                            <th>Metric</th>
                            <th>Value</th>
                          </tr>
                        </thead>
                        <tbody id="injury-table-body">
                          <tr>
                            <td>Deep Squat</td>
                            <td id="ipaDeepSquat"></td>
                          </tr>
                          <tr>
                            <td>Inline Lunge</td>
                            <td id="ipaInlineLunge"></td>
                          </tr>
                          <tr>
                            <td>Hurdle Step</td>
                            <td id="ipaHurdleStep"></td>
                          </tr>
                          <tr>
                            <td>Active SLR</td>
                            <td id="ipaActiveSLR"></td>
                          </tr>
                          <tr>
                            <td>Shoulder Mobility</td>
                            <td id="ipaShoulderMobility"></td>
                          </tr>
                          <tr>
                            <td>PushUp</td>
                            <td id="ipaPushUp"></td>
                          </tr>
                          <tr>
                            <td>Rotary Stability</td>
                            <td id="ipaRotaryStability"></td>
                          </tr>
                          <tr>
                            <td>Total Score</td>
                            <td id="ipaTotalScore"></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="selectedOption == 'Sports Performance'">
    <div class="container-fluid p-0">
      <div *ngIf="lastSportsAssessment" class="row">
        <!-- Dynamic Fitness Cards -->
        <div class="col-6 col-sm-4 col-lg-2"
          *ngFor="let metric of ['AverageTorqueCR', 'AverageTorqueCL', 'PeakTorqueCR', 'PeakTorqueCL', 'JumpHeight', 'Force']">
          <div>
            <div class="card">
              <div class="card-body p-3">
                <div class="text-muted mb-2">{{ metric.replace('AverageTorqueCR', 'Average Torque CR')
                  .replace('AverageTorqueCL', 'Average Torque CL')
                  .replace('PeakTorqueCR', 'Peak Torque CR')
                  .replace('PeakTorqueCL', 'Peak Torque CL')
                  .replace('JumpHeight', 'Jump Height')
                  .replace('Force', 'Force') }}</div>
                <div class="h5 m-0">{{ lastSportsAssessment['spa' + metric] != null && lastSportsAssessment['spa' +
                  metric] !== '' ? lastSportsAssessment['spa' + metric] : 0 }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Charts Section -->
      <div class="row">
        <div class="col-lg-12 d-flex align-items-stretch">
          <div class="card w-100">
            <div class="card-body">
              <div class="d-sm-flex d-block align-items-center justify-content-between mb-9">
                <div class="mb-3 mb-sm-0">
                  <h5 class="card-title fw-semibold">{{ selectedOption }} Graph</h5>
                  <p class="cart-desc">range of last 2 {{ selectedOption }}</p>
                </div>
              </div>
              <div id="sports-graph"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="row">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title fw-semibold">Current Assessment</h5>
                  <div class="table-responsive">
                    <table class="table table-hover table-outline table-vcenter text-nowrap card-table">
                      <thead>
                        <tr>
                          <th>Metric</th>
                          <th>Value</th>
                        </tr>
                      </thead>
                      <tbody id="sports-table-body">
                        <tr>
                          <td>AverageTorque (Concentric Right)</td>
                          <td id="spaAverageTorqueCR"></td>
                        </tr>
                        <tr>
                          <td>AverageTorque (Concentric Left)</td>
                          <td id="spaAverageTorqueCL"></td>
                        </tr>
                        <tr>
                          <td>PeakTorque (Concentric Right)</td>
                          <td id="spaPeakTorqueCR"></td>
                        </tr>
                        <tr>
                          <td>PeakTorque (Concentric Left)</td>
                          <td id="spaPeakTorqueCL"></td>
                        </tr>
                        <tr>
                          <td>AverageTorque (Isometrics Right)</td>
                          <td id="spaAverageTorqueIR"></td>
                        </tr>
                        <tr>
                          <td>AverageTorque (Isometrics Left)</td>
                          <td id="spaAverageTorqueIL"></td>
                        </tr>
                        <tr>
                          <td>PeakTorque (Isometrics Right)</td>
                          <td id="spaPeakTorqueIR"></td>
                        </tr>
                        <tr>
                          <td>PeakTorque (Isometrics Left)</td>
                          <td id="spaPeakTorqueIL"></td>
                        </tr>
                        <tr>
                          <td>Jump Height</td>
                          <td id="spaJumpHeight"></td>
                        </tr>
                        <tr>
                          <td>Flight Time</td>
                          <td id="spaFlightTime"></td>
                        </tr>
                        <tr>
                          <td>Velocity</td>
                          <td id="spaVelocity"></td>
                        </tr>
                        <tr>
                          <td>Force</td>
                          <td id="spaForce"></td>
                        </tr>
                        <tr>
                          <td>Power</td>
                          <td id="spaPower"></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>