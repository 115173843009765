import { AgGridAngular } from "ag-grid-angular";
import { ColDef, GridOptions } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { ChangeDetectorRef, Component, ElementRef, NgZone, OnInit, ViewChild } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ActivatedRoute, Router, RouterLink, RouterModule, RouterOutlet } from "@angular/router";
import { UserRegisterService } from "../../../services/user-register.service";
import { NgToastModule } from "ng-angular-popup";
import { FormsModule } from "@angular/forms";
import { NotificationService } from "../../../services/Notifications.service";
import { AssessmentService } from "../../../services/assessment.service";
import { SharedService } from "../../../services/shared.service";
import { HttpClient } from "@angular/common/http";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import { environment } from '../../../../environments/environment.prod';
import { DomSanitizer } from "@angular/platform-browser";
import { NgSelectModule } from "@ng-select/ng-select";
import saveAs from "file-saver";
import * as XLSX from 'xlsx';
interface IRow {}
interface Issue {
  serialNo: number;
  fullName: string;
  email: string;
  type: string;
}

interface GroupedIssue {
  serialNos: number[];
  fullName: string;
  email: string;
  type: string;
}
@Component({
  selector: 'app-register',
  standalone: true,
  imports: [CommonModule, RouterOutlet, AgGridAngular, RouterLink, RouterModule, FormsModule,NgToastModule,NgSelectModule],
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
 
})
export class RegisterComponent implements OnInit {
  errorMessage: string = '';
  users: string | null = null;
  isAdmin: boolean = false;
  isPlayer: boolean = false;
  isAthlete: boolean = false;
  isEditMode: boolean = false;
  isSupport: boolean = false;
  isCoach: boolean = false;
  selectedRows: any[] = [];
  organization: any[] = [];
  sport: any[] = [];
  hasFitnessData = false;
  hasNutritionData = false;
  hasInjuryData = false;
  hasSportsData = false;
  userDetail: any = {
    UsrId:null,
    usrFullName: null,
    usrDoB: null,
    roleName: null,
    usrAddress: null,
    usrPhoneNo: null,
    usrOrganization:null,
    usrSupport:null,
    orgName:null,
    usrGender:null,
    usrEmail: null,
    usrClass:null,
    usrECName: null,
    usrECRelationship: null,
    usrECPhoneNo: null,
    umhAllergies: null,
    umhCurrentMedications: null,
    umhPreviousInjuries: null,
    umhChronicConditions: null,
    umhSurgeries: null,
    umhFamilyMedicalHistory: null,
    uliSleepPatterns: null,
    uliDiet: null,
    uliHydration: null,
    uliStressLevels: null,
   
    asiPrimarySport: null,
    asiSecondarySport: null,
    asiCurrentClubTeam: null,
    asiCoach: null,
    asiTrainingFrequency: null,
    asiCompetitionLevel: null,
    asiCareerHighlights: null,
    asiShortTermGoals: null,
    asiLongTermGoals: null
  };
  detail: any;
  selectedstatus: any;
  org: any;
  filesSelected: boolean = false;
  nutrition: any;
  selectedFiles: any;
  duplicates: string[] = [];
  ImageUrl: any;
  showImage: boolean = false;
  showCheckboxes: boolean = false;
  Trainer: any[]=[];
  gridApi: any;
  statusOptions: any[]=[];
  role: any;
  active: any;
  trainer: any[]=[];
  roleId: any;
  teams: any[]=[];
  selectedRole: any = '';
 
  sportName: any;
  coach: any;
  fitness: any;
  assessmentId: any;
  injury: any;
  sports: any;
  nutritions: any;
  organisation: any;
  team: any;
  missingMandatoryEmails: any;
  coachEmail: any;
  gridOptions: GridOptions = {
    rowSelection: 'multiple',
    suppressRowClickSelection: true,
    onSelectionChanged: this.onSelectionChanged.bind(this),
    getRowHeight: (params) => {
      const typeText = params.data.type || ""; // Get the error text
      const numberOfLines = (typeText.match(/\n/g) || []).length + 1; // Count lines
      return Math.max(50, numberOfLines * 40); // Minimum height of 50px or calculated height
    }
  };

  issueColumns = [
    { headerName: 'Row No', field: 'serialNo', sortable: true, filter: true,minWidth: 100, maxWidth: 120 },
    { headerName: 'Name', field: 'fullName', sortable: true, filter: true,minWidth: 100, maxWidth: 150 },
    { headerName: 'Email', field: 'email', sortable: true, filter: true,minWidth: 100, maxWidth: 180 },
    {
      headerName: 'Error',
      field: 'type',
      sortable: true,
      filter: true,
      cellStyle: { 'white-space': 'pre-wrap', 'word-wrap': 'break-word'}, // Allow for wrapping
      minWidth: 200, maxWidth: 300
    },
  ];

  issueData: any[] = []; // Your grid data
  staff: boolean =  false;
  scienceStaff: any;
  supportStaff: any[] = [];
  expectedRowCount: number=0;
 
 
  constructor(private assessmentService: AssessmentService,private http:HttpClient,private router: Router, private notify: NotificationService,
              private route: ActivatedRoute, private sharedService: SharedService,private sanitizer: DomSanitizer,
              private userRegisterService: UserRegisterService, private cdRef: ChangeDetectorRef,private ngZone: NgZone) {
 
                this.route.queryParams.subscribe(params => {
                  if(params['usrActive']!=undefined){
                  this.selectedStatus = parseInt( params['usrActive']);
                this.onStatusChange();
                  }
                });
              }
 
  activeStep: number = 1;
  formSteps!: NodeListOf<Element>;
  themeClass = "ag-theme-quartz";
  rowData: IRow[] = [];
  colDefs: ColDef[] = [];
  usrActive: number | null = null;
  usrRole: number | null = null;
  formSubmitted: boolean = false;
  selectedStatus: number = 0;
  selectedTeam: any;
  selectedCoach: string = '';
  defaultColDef: ColDef = {
    flex: 1,
    sortable: true,
    filter: true
  };
 
  // statusOptions = [
  //   { value: 0, name: 'FC GOA' },
  //   { value: 1, name: 'Fire City' },
  //   { value: 2, name: 'FC' },
  //   { value: 3, name: 'Titans' }
  // ];
  getOrganizationName(orgId: number): string {
    const org = this.organization.find(o => o.orgId === orgId);
    return org ? org.orgName : ''; // Returns the organization name or an empty string if not found
}
getSupportName(ssId: number): string{
  const ss = this.supportStaff.find(s => s.ssId === ssId);
  return ss? ss.ssName : '';
}
getSportName() {
  // Fetch sports data first
  this.assessmentService.getSport().subscribe(
    (data) => {
      this.sport = data;
     console.log(this.sport,"sport")
     
      const matchedSport = this.sport.find((s: any) => s.sportId === this.userDetail.usrSport);
      this.sportName = matchedSport ? matchedSport.sportName : null; // Set the sport name
    },
    (error) => {
      console.error('Error fetching sports:', error);
    }
  );
}
getsportName(sportId: number): string {
  const selectedSport = this.sport.find(sport => sport.sportId === sportId);
  return selectedSport ? selectedSport.sportName : '';
}
 
  ngOnInit() {
    this.sharedService.detail$.subscribe((data: any) => {
      this.detail = data;
      this.org=this.detail.user.usrOrganization;
    });
    this.loadOrgainsation();
    this.route.queryParams.subscribe(params => {
      this.usrActive = +params['usrActive'] || 0;
      this.loadUsers();
    });
    this.formSteps = document.querySelectorAll('.form-step');
    this.updateProgress();
    this.getSportName();
  }
   onSupportStaffChange(event: any) {
    const selectedValue = event.target.value;
    this.userDetail.usrSupport = selectedValue;
  }
 
  selectRole(role: string, roleId: any) {
    // Set all role flags to false
    localStorage.setItem('isCoach', 'false');
    localStorage.setItem('isSupport', 'false');
 
    // Set the selected role flag to true
    switch (role) {
      case 'Trainer':
        localStorage.setItem('isCoach', 'true');
        break;
      case 'Science Staff':
        localStorage.setItem('isSupport', 'true');
        break;
        case 'orgAdmin':
      case 'Manager':
      case 'Assessor':
      case 'Player':
      
        break;
    }
 
    this.ngZone.run(() => {
      if (role === 'Player') {
        this.router.navigate(['/player-registration'], { queryParams: { Org: this.org } });
      } else {
        // Pass the roleId in the query params for other roles
        this.router.navigate(['/registration'], { queryParams: { Org: this.org, id: roleId } });
      }
    });
  }
 
  loadOrgainsation() {
    this.assessmentService.getOrgainsation().subscribe(
      (data) => {
        this.organization = data;
      },
      (error) => {
        console.error('Error fetching coaches:', error);
      }
    );
 
    this.assessmentService.getTrainer().subscribe(
      (data) => {
        this.trainer = data;
      },
      (error) => {
        console.error('Error fetching coaches:', error);
      }
    );
    this.assessmentService.getTeams().subscribe(
      (data) => {
        this.teams = data;
      },
      (error) => {
        console.error('Error fetching coaches:', error);
      }
    );
    this.assessmentService.getSupportStaff().subscribe(
      (data: any[]) => {
        this.supportStaff = data;
      },
      (error: any) => {
        console.error('Error fetching science staffs:', error);
      }
    );
  }
  nextStep(): void {
    this.activeStep++;
    if (this.activeStep > this.formSteps.length) {
      this.activeStep = this.formSteps.length;
    }
    this.updateProgress();
  }
  prevStep(): void {
    this.activeStep--;
    if (this.activeStep < 1) {
      this.activeStep = 1;
    }
    this.updateProgress();
  }
 
  updateProgress(): void {
    this.formSteps.forEach((step, i) => {
      if (i === (this.activeStep - 1)) {
        step.classList.add('active');
        this.formSteps[i].classList.add('active');
      } else {
        step.classList.remove('active');
        this.formSteps[i].classList.remove('active');
      }
    });
 
    const prevButton = document.querySelector('.btn-prev') as HTMLButtonElement;
    const nextButton = document.querySelector('.btn-next') as HTMLButtonElement;
    const submitButton = document.querySelector('.btn-submit') as HTMLButtonElement;
 
    if (prevButton) {
      if (this.activeStep === 1) {
        prevButton.disabled = true;
        submitButton.hidden=true;
      } else if (this.activeStep === this.formSteps.length) {
        nextButton.disabled = true;
        submitButton.hidden=false;
      } else {
        prevButton.disabled = false;
        nextButton.disabled = false;
        submitButton.hidden=true;
      }
    }
 
 
  }
  onStatusChange() {
    this.usrActive = this.selectedStatus;
    this.loadUsers();
  }
 
  onSelectTeam() {
   
  }
 
  loadUsers() {
    if (this.usrActive !== null) {
      this.userRegisterService.getAllUsers(this.usrActive, this.org).subscribe((response: any) => {
        this.users = response;
        const filteredUsers = response.filter((item: any) => item.usrRole != 2);
        
        // Map and sort the rowData by UserId
        this.rowData = filteredUsers
          .map((item: any) => ({
            UserId: `${item.usrId}`,
            UserName: `${item.usrFullName}`,
            Role: `${item.roleName}`,
            Organization: item.orgName ? `${item.orgName}` : '',
            CoachName: item.coachName ? `${item.coachName}` : '',
            TeamName: item.teamName ? `${item.teamName}` : '',
            Date: new Date(item.usrDoB).toLocaleDateString(),
            PlayerId: `${item.usrId}`,
            usrRole: `${item.usrRole}`,
            usrActive: `${item.usrActive}`,
            usrOrgId: `${item.usrOrganization}`
          }))
          .sort((a: any, b: any) => a.UserId - b.UserId); // Sort by UserId
  
        this.colDefs = this.getColumnDefinitions();
      }, error => {
        console.error('data not found', error);
      });
    }
  }
  
 
  
  onAssignTeamCoachClick() {
    this.showCheckboxes = true; // Show checkboxes when button is clicked
 
    this.assessmentService.getTeams().subscribe(
      (data) => {
        this.statusOptions = data;
      },
      (error) => {
        console.error('Error fetching coaches:', error);
      }
    );
 
    this.assessmentService.getTrainer().subscribe(
      (data) => {
        this.Trainer = data;
      },
      (error) => {
        console.error('Error fetching coaches:', error);
      }
    );
    this.colDefs = this.getColumnDefinitions(); // Refresh column definitions
  }
 
  async fetchAllAssessmentData(userId: any) {
    try {
 
        await this.getFitnessData(userId);
        await this.getNutritionData(userId);
        await this.getInjuryPrventionData(userId);
        await this.getSportsPerformaceData(userId);
    } catch (error) {
        console.error("Error fetching data:", error);
    }
}
 
  getFitnessData(usrId: any) {
    return new Promise<void>((resolve, reject) => {
      this.assessmentService.getFitnessByAthleteId(usrId).subscribe(
        (response: any) => {
          this.fitness = response && response.length > 0 ? response.slice(-1)[0] : null;
          this.assessmentId = this.fitness?.pfaAssessment;
          this.hasFitnessData = this.fitness;
          resolve();
        },
        (error) => {
          console.error('Error fetching fitness data', error);
          reject(error); // Reject if there’s an error
        }
      );
    });
  }
 
  getNutritionData(usrId: any) {
    return new Promise<void>((resolve, reject) => {
      this.assessmentService.geteNutritionalByAthleteId(usrId).subscribe(
        (response: any) => {
          this.nutritions = response && response.length > 0 ? response.slice(-1)[0] : null;
          this.assessmentId = this.nutritions?.naAssessment;
          this.hasNutritionData = this.nutritions;
          resolve();
         
        },
        (error) => {
          console.error('Error fetching nutrition data', error);
          reject(error);
        }
      );
    });
  }
 
  getInjuryPrventionData(usrId: any) {
    return new Promise<void>((resolve, reject) => {
      this.assessmentService.geteInjuryPrventionByAthleteId(usrId).subscribe(
        (response: any) => {
          this.injury = response && response.length > 0 ? response.slice(-1)[0] : null;
          this.hasInjuryData = this.injury;
          resolve();
        },
        (error) => {
          console.error('Error fetching injury data', error);
          reject(error);
        }
      );
    });
  }
 
  getSportsPerformaceData(usrId: any) {
    return new Promise<void>((resolve, reject) => {
      this.assessmentService.getSportsPerformaceDataByAthleteId(usrId).subscribe(
        (response: any) => {
          this.sports = response && response.length > 0 ? response.slice(-1)[0] : null;
          this.hasSportsData = this.sports;
          resolve();
        },
        (error) => {
          console.error('Error fetching sports performance data', error);
          this.hasSportsData = false;
          reject(error);
        }
      );
    });
  }
 
  getColumnDefinitions(): ColDef[] {
    const columns: ColDef[] = [];
  
    if (this.showCheckboxes) {
      columns.push({
        headerCheckboxSelection: true,  // Enable header checkbox selection
        checkboxSelection: true,        // Enable row selection with checkbox
        width: 80,                      // Set the desired width for the checkbox column
        minWidth: 80,                   // Set the minimum width to prevent expansion
        maxWidth: 80                    // Set the maximum width to prevent resizing
      });
    }
  
    columns.push(
      {
        headerName: 'Action',
        cellRenderer: (_params: any) => {
          const container = document.createElement('div');
          container.style.display = 'flex';
          container.style.justifyContent = 'space-around';
  
          // View button with eye icon
          const viewButton = document.createElement('button');
          viewButton.classList.add('btn');
          viewButton.innerHTML = '<i class="fa fa-eye"></i>';
          viewButton.title = "View";
          viewButton.addEventListener('click', () => {
            const userId = _params.data.UserId;
            this.isEditMode = false; // Set view mode
            this.showUserDetails(userId);
          });
          container.appendChild(viewButton);
  
          // Edit button with edit icon
          const editButton = document.createElement('button');
          editButton.classList.add('btn');
          editButton.innerHTML = '<i class="fa fa-edit"></i>';
          editButton.title = "Edit";
          editButton.addEventListener('click', () => {
            const userId = _params.data.UserId;
            
            this.isEditMode = true; // Set edit mode
            this.showUserDetails(userId);
            this.updateProgress();
          });
        
          
          container.appendChild(editButton);
  
          if (this.usrActive != 1) {
            // Check button with check icon
            const checkButton = document.createElement('button');
            checkButton.classList.add('btn');
            checkButton.innerHTML = '<i class="fa fa-check"></i>';
            checkButton.title = "Accept";
            checkButton.addEventListener('click', () => {
            
              const userId = _params.data.UserId;
              const isActive = 1;
  
              this.userRegisterService.updateUserStatus(userId, isActive)
                .subscribe(response => {
                  this.ngZone.run(() => {

                    this.notify.success("User status updated successfully");
                    this.loadUsers();
                    this.cdRef.detectChanges();
                   
                  });
                  
                 
                },
                error => {
                  this.ngZone.run(() => {
                    this.notify.failed('Error updating user status');
                    this.cdRef.detectChanges();
                  });
                  console.error('Error updating user status', error);
                });
            });
            container.appendChild(checkButton);
          }
  
          if (this.usrActive != 2) {
            // Cross button with cross icon
            const crossButton = document.createElement('button');
            crossButton.classList.add('btn');
            crossButton.innerHTML = '<i class="fa fa-times"></i>';
            crossButton.title = "Reject";
            crossButton.addEventListener('click', () => {
              const userId = _params.data.UserId;
              const isActive = 2;
  
              this.userRegisterService.updateUserStatus(userId, isActive)
                .subscribe(response => {
                  this.ngZone.run(() => {
                    this.notify.success("User Declined");
                    this.loadUsers(); 
                    this.cdRef.detectChanges();
                 
                  });
                 
                },
                error => {
                  this.ngZone.run(() => {
                    this.notify.failed('Error declining user');
                    this.cdRef.detectChanges();
                  });
                  console.error('Error declining user', error);
                });
            });
            container.appendChild(crossButton);
          }
  
          return container;
        },
        minWidth: 200
      },
      {
        headerName: 'Report',
        cellRenderer: (_params: any) => {
          const container = document.createElement('div');
          container.style.display = 'flex';
          container.style.justifyContent = 'space-around';
          this.role = _params.data.usrRole;
          this.active = _params.data.usrActive;
  
          // Ensure the view button is only shown when usrRole === 1 and usrActive === 1
          if (this.role == 1 && this.active == 1) {
            const viewButton = document.createElement('button');
            viewButton.classList.add('btn');
            viewButton.innerHTML = '<i class="fa fa-eye"></i>';
            viewButton.title = "View Report";
 
            // Add click event to fetch data and navigate to report page
            viewButton.addEventListener('click', async () => {
              const userId = _params.data.PlayerId; // Get the userId from the grid data
              await this.fetchAllAssessmentData(userId); // Fetch the assessment data
              this.ngZone.run(() => {
                if (this.hasFitnessData || this.hasNutritionData || this.hasInjuryData || this.hasSportsData) {
                  this.router.navigate(['/report'], {
                    queryParams: { id: _params.data.PlayerId, orgId: _params.data.usrOrgId, user: 5 }
                  });
                } else {
                  this.ngZone.run(() => {
                    this.notify.failed("Assessments are not complete");
                    this.cdRef.detectChanges();
                  });
                }
              });
            });
            container.appendChild(viewButton);
          }
  
          return container;
        },
        width: 100
      },
      { field: "UserName", headerName: 'Name' },
      { field: "Role", headerName: 'Role' },
      { field: "Organization", headerName: 'Organization' },
      { field: "CoachName", headerName: 'Coach Name' },
      { field: "TeamName", headerName: 'Team Name' },
      { field: "Date", headerName: 'DOB' },
    );
  
    if (this.isAdmin) {
      return columns;
    } else if (this.isPlayer) {
      return columns.filter(col => col.field !== 'playername');
    } else {
      return columns.filter(col => col.field !== 'Assessor');
    }
  }
 
  onSelectionChanged(event: any) {
    this.selectedRows = event.api.getSelectedRows();
  }
 
  onGridReady(params: any) {
    this.gridApi = params.api;
  }
 
  updateSelection() {
    // Collect the selected team and coach values
    const selectedTeam = this.selectedTeam;
    const selectedCoach = this.selectedCoach;
 
    // Collect the selected rows data (checkbox data)
    const selectedRowsData = this.gridApi.getSelectedRows(); // Assuming `gridApi` is a reference to your grid instance
 
    const athleteIds = selectedRowsData.map((user: any) => user.UserId);
    // Prepare the data object to be passed
    const updateData = {
      TeamId: selectedTeam,
      CoachId: selectedCoach,
      AthleteIds: athleteIds // This contains the data of the selected rows
    };
 
 // Debugging: Check if the data is being gathered correctly
 
    // Now, pass this data to a service or handle it as needed
    this.assessmentService.updateUserSelection(updateData).subscribe(
      response => {
        this.ngZone.run(() => {
          this.notify.success('Selection updated successfully');
          this.cdRef.detectChanges();
          });
      
      },
      error => {
        this.ngZone.run(() => {
          this.notify.failed('Error updating selection');
          this.cdRef.detectChanges();
          });
     
        console.error('Error updating selection:', error);
      }
    );
  }
 
 
  ChangePasswordModal(): void {
    // Reset the form fields to empty
    this.nutrition.naAthleteName = '';
    this.nutrition.email = '';
    this.nutrition.newPassword = '';
 
  }
 
  showChangePasswordModal(userId: number): void {
    this.userRegisterService.fetchUser(userId).subscribe(userData => {
      this.userDetail = userData[0];
      this.activeStep = 1; // Reset step to the first one whenever user details are shown
 
      // Populate the form fields with user details
      this.nutrition.naAthleteName = this.userDetail.usrFullName;
      this.nutrition.email = this.userDetail.usrEmail;
 
      this.cdRef.detectChanges();
 
      // Show the modal
      const modalElement = document.getElementById('changePasswordModal')!;
      const modal = new (window as any).bootstrap.Modal(modalElement);
      modal.show();
    });
  }
 
  showUserDetails(userId: number): void {
    this.activeStep = 1;
    this.userRegisterService.fetchUser(userId).subscribe(userData => {
      this.userDetail = userData[0];
 console.log(this.userDetail,"userDetail")
      this.isAthlete = this.userDetail.roleName === "Athlete";
      this.isCoach=this.userDetail.roleName==="Coach";
      this.isSupport = this.userDetail.roleName === 'Science Staff';
      this.cdRef.detectChanges();
 
      // Show the modal
      const modalElement = document.getElementById('customModal')!;
      const modal = new (window as any).bootstrap.Modal(modalElement);
      modal.show();
    });
  }
 
  onModalClose() {
  // Loop through all form steps
  this.formSteps.forEach((step, i) => {
    if (i === 0) {
      // Add 'active' class to the first step (stepOne)
      step.classList.add('active');
    } else {
      // Remove 'active' class from all other steps
      step.classList.remove('active');
    }
  });
 
  // Optionally reset the activeStep to 1 (first step)
  this.activeStep = 1;
  this.checkbutton();
 
  }
 
  checkbutton() {
    const prevButton = document.querySelector('.btn-prev') as HTMLButtonElement;
    const nextButton = document.querySelector('.btn-next') as HTMLButtonElement;
    const submitButton = document.querySelector('.btn-submit') as HTMLButtonElement;
 
    if (this.activeStep === 1) {
      prevButton.disabled = true;
      nextButton.disabled = false;
      submitButton.hidden=true;
    } else if (this.activeStep === this.formSteps.length) {
      nextButton.disabled = true;
      submitButton.hidden=false;
    } else {
      prevButton.disabled = false;
      nextButton.disabled = false;
      submitButton.hidden=true;
    }
  }
 
 
  saveUserDetails(): void {
   // this.trainerDetail.usrActive = this.orgId
   this.onModalClose();
    const formElement = document.getElementById('userForm') as HTMLFormElement;
 
    if (!formElement) {
      console.error('Form element not found');
      return;
    }
 
    const formData = new FormData(formElement);
    const data: any = {};
   
    formData.forEach((value, key) => {
      data[key] = value;
    });
 
   
    // Check if the user is a player
    if (this.isAthlete) {
      this.userDetail=data;
      this.formSubmitted = true;
      this.userRegisterService.userRegister([this.userDetail]).subscribe(response =>{
        if (response[0].usrId) {
          this.ngZone.run(() => {
            this.notify.success("Form updated successfully");
            this.loadUsers();
            this.cdRef.detectChanges();
         });
         
        }
       
      }, error => {
        this.ngZone.run(() => {
        this.notify.failed('Error submitting form');
        this.cdRef.detectChanges();
      });
        console.error('Error submitting form', error);
      });
 
    } else {
      // Send only specific fields for non-players
      this.userDetail = {
        usrId:data.usrId,
        usrFullName: data.usrFullName,
        usrDoB: data.usrDoB,    
        usrGender:data.usrGender,
        usrRole: data.usrRole,
        usrAddress: data.usrAddress,
        usrPhoneNo: data.usrPhoneNo,
        usrOrganization: this.org,
        usrReligion:data.usrReligion,
        usrCaste:data.usrCaste,
        usrEmail: data.usrEmail,
        usrECName: data.usrECName,
        usrECRelationship: data.usrECRelationship,
        usrECPhoneNo: data.usrECPhoneNo,
        usrSport:data.usrSport,
        usrSupport: data.usrSupport
      };
    this.assessmentService.addTrainer([this.userDetail]).subscribe(
      (response: any) => {
        const modalElement = document.getElementById('customModal')!;
          const modal = new (window as any).bootstrap.Modal(modalElement);
          modal.hide();
          this.ngZone.run(() => {
        this.notify.success("Data submitted successfully!");
        this.loadUsers();
        this.cdRef.detectChanges();
      });
      },
      (error: any) => {
        this.ngZone.run(() => {
        this.notify.failed('Error submitting form');
        this.cdRef.detectChanges();
        });
        setTimeout(() => {
 
        }, 1000);
        console.error('Error submitting form', error);
      });
 
  }
  }
 
 
 
  saveUserDetail(event: Event): void {
    event.preventDefault(); // Prevent default form submission
   
   
 
    const formElement = document.getElementById('userForm') as HTMLFormElement;
 
    if (!formElement) {
      console.error('Form element not found');
      return;
    }
 
    const formData = new FormData(formElement);
    const data: any = {};
 
    formData.forEach((value, key) => {
      data[key] = value;
    });
 
    let UserData: any = {};
    if (this.isAthlete) {
      UserData = data;
      this.formSubmitted = true;
 
      this.userRegisterService.userRegister([UserData]).subscribe(
        (response) => {
          if (response[0].usrId) {
            this.ngZone.run(() => {
            this.notify.success("Form submitted successfully");
            this.cdRef.detectChanges();
            });
            const modalElement = document.getElementById('customModal')!;
            const modal = new (window as any).bootstrap.Modal(modalElement);
            modal.hide();
          }
        },
        (error) => {
          this.ngZone.run(() => {
          this.notify.failed('Error submitting form');
          this.cdRef.detectChanges();
        });
          console.error('Error submitting form', error);
        }
      );
    } else {
      UserData = {
        usrId: data.usrId,
        usrFullName: data.usrFullName,
        usrDoB: data.usrDoB,
        usrGender: data.usrGender,
        usrRole: data.usrRole,
        usrAddress: data.usrAddress,
        usrPhoneNo: data.usrPhoneNo,
        usrOrganization: data.usrOrganization,
        usrReligion:data.usrReligion,
        usrCaste:data.usrCaste,
        usrEmail: data.usrEmail,
        usrECName: data.usrECName,
        usrECRelationship: data.usrECRelationship,
        usrECPhoneNo: data.usrECPhoneNo,
        usrSport:data.usrSport,
        usrSupport: data.usrSupport
      };
 
      this.assessmentService.addTrainer([UserData]).subscribe(
        (response: any) => {
          this.ngZone.run(() => {
            this.notify.success("Data updated successfully!");
            this.loadUsers();
            this.cdRef.detectChanges();
            });
       
          const modalElement = document.getElementById('customModal')!;
          const modal = new (window as any).bootstrap.Modal(modalElement);  
          modal.hide();
        },
        (error: any) => {
          this.ngZone.run(() => {
            this.notify.failed('Error submitting form');
            this.cdRef.detectChanges();
            });
          
          console.error('Error submitting form', error);
        }
      );
    }
  }
 
  onFileSelected(event: any) {
    this.selectedFiles = event.target.files;
    if (this.selectedFiles != null) {
      this.filesSelected = true;
    }
  }
 
  clearSelectedFiles(fileInput: any) {
    this.selectedFiles = '';
    if(this.selectedRole == 'Science Staff'){
      this.staff = true;
    }else{
      this.staff = false;
    }
    
    this.filesSelected = false;
    if (fileInput) {
      fileInput.value = '';
    }
  }
 

registerUpload() {
  const selectedFile = this.selectedFiles[0];

  if (selectedFile) {
    this.getTemplateColumns(this.selectedRole).then((templateColumns) => {
      this.getSelectedFileColumns(selectedFile).then((fileColumns) => {
        // Check if the columns of the selected file match the template columns
        if (!this.areColumnsValid(fileColumns, templateColumns)) {
          this.errorMessage = `File mismatch. Please select the correct file.`;
          const errorModal = document.getElementById('errorModal')!;
          const modal = new (window as any).bootstrap.Modal(errorModal);
          const fileUploadModal1 = document.getElementById('fileUploadModal')!;
          const modal1 = new (window as any).bootstrap.Modal(fileUploadModal1);
          modal1.show();
          modal.show();
         
          return;
          
        }
        
        // Proceed with file upload if columns are valid
        const formData = new FormData();
        for (let i = 0; i < this.selectedFiles.length; i++) {
          formData.append('files', this.selectedFiles[i], this.selectedFiles[i].name);
        }
        formData.append('selectedRole', this.selectedRole);
        if(this.selectedRole==1){
        this.assessmentService.playerUpload(formData).subscribe(
          (response: any) => {
              this.processApiResponse(response);
              if(this.duplicates.length > 0 || this.missingMandatoryEmails.length > 0 || this.organisation.length > 0 || this.team.length > 0 || this.coachEmail.length > 0 ){
                this.openModal();
              }else{
                if(response.rowsInserted > 0){
                  this.notify.success("File Uploaded Successfully");
                  this.selectedRole = '';
                  this.router.navigate(['/registerrequest'], { queryParams: { usrActive: 1 } });
                }else{
                  this.notify.failed("File Is Empty");
                  this.selectedRole = '';
                }
              }
   
          },
          (error: any) => {
              this.processApiResponse(error.error);
              this.openModal();
          }
      );
    }else{
        this.assessmentService.registerUpload(formData).subscribe(
          (response: any) => {
              this.processApiResponse(response);
              if(this.duplicates.length > 0 || this.missingMandatoryEmails.length > 0 || this.organisation.length > 0 || this.team.length > 0 || this.coachEmail.length > 0 || this.scienceStaff.length > 0 ){
                this.openModal();
              }else{
                if(response.rowsInserted > 0){
                  this.notify.success("File Uploaded Successfully");
                  this.selectedRole = '';
                  this.router.navigate(['/registerrequest'], { queryParams: { usrActive: 1 } });
                }else{
                  this.notify.failed("File is Empty");
                  this.selectedRole = '';
                }
               
              }
   
          },
          (error: any) => {
              this.processApiResponse(error.error);
              this.openModal();
          }
      );
    }
      }).catch(error => {
        console.error('Error reading selected file columns:', error);
      });
    }).catch(error => {
      console.error('Error downloading template file:', error);
    });
  }
}


// Get the columns from the template based on the role
getTemplateColumns(role: string): Promise<string[]> {
  const templateUrlMap: { [key: string]: string } = {
    '1': 'assets/Templates/PlayerRegister_Template.xlsx',
    'Coach': 'assets/Templates/Coach_Register_Template.xlsx',
    'Science Staff': 'assets/Templates/Science_Staff_Register_Template.xlsx',
    'OrgAdmin': 'assets/Templates/Register_Template.xlsx',
    'Assessor': 'assets/Templates/Register_Template.xlsx'
  };

  const templateUrl = templateUrlMap[role];

  if (templateUrl) {
    return this.http.get(templateUrl, { responseType: 'blob' }).toPromise().then((blob) => {
      if (blob) {
        return this.getColumnsFromBlob(blob); // Get columns instead of row count
      } else {
        throw new Error("Failed to fetch the template file.");
      }
    }).catch(error => {
      console.error("Error fetching template file:", error);
      return [];
    });
  } else {
    return Promise.reject('No template URL found for the selected role.');
  }
}

// Extract column names from the uploaded file
getSelectedFileColumns(selectedFile: File): Promise<string[]> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 }); // Header row as the first row
  
      const columns = jsonData[0] ? Object.keys(jsonData[0]) : []; // Get the keys from the first row
      resolve(columns);
    };
    reader.onerror = (error) => reject(error);
    reader.readAsArrayBuffer(selectedFile);
  });
}

// Extract column names from Blob (for template file)
getColumnsFromBlob(blob: Blob): Promise<string[]> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 }); // Header row as the first row
      
      const columns = jsonData[0] ? Object.keys(jsonData[0]) : []; // Get the keys from the first row
      resolve(columns);
    };
    reader.onerror = (error) => reject(error);
    reader.readAsArrayBuffer(blob);
  });
}

// Compare columns between the template and the uploaded file
areColumnsValid(fileColumns: string[], templateColumns: string[]): boolean {
  // Compare the length and names of columns (case sensitive comparison)
  if (fileColumns.length !== templateColumns.length) {
    return false; // Column count mismatch
  }
  for (let i = 0; i < fileColumns.length; i++) {
    if (fileColumns[i] !== templateColumns[i]) {
      return false; // Column name mismatch
    }
  }
  return true; // Columns match
}


  // Helper functions to show error modal and process file upload
  showErrorModal() {
      const errorModal = document.getElementById('errorModal')!;
      const modal = new (window as any).bootstrap.Modal(errorModal);
      modal.show();
  }
  
 
clearRole(){
  this.selectedRole = '';
}

private processApiResponse(response: any) {
    this.duplicates = response.duplicates || [];
    this.missingMandatoryEmails = response.mandatory || [];
    this.organisation = response.organisation || [];
    this.team = response.team || [];
    this.scienceStaff = response.staff || [];
    this.coachEmail = response.coach || [];

    const issueData: Issue[] = [
        ...this.formatDuplicateEmails(this.duplicates),
        ...this.formatMandatoryEmails(this.missingMandatoryEmails),
        ...this.organisation.map((item: any) => this.formatIssue(item, 'Organization Not Found')),
        ...this.scienceStaff.map((item: any) => this.formatIssue(item, this.selectedRole == "Science Staff"?'Staff Role Not Found':'Sport Name not found')),
        ...this.team.map((item: any) => this.formatIssue(item, 'Team Not Found')),
        ...this.formatCoachEmails(this.coachEmail),
    ];

    this.issueData = this.combineIssuesByType(issueData);
}

private formatIssue(item: any, type: string): Issue {
    const [fullName, email] = item.email.split(' - ');
    return {
        serialNo: item.serialNo || 0,
        fullName,
        email,
        type,
    };
}

private formatDuplicateEmails(duplicates: any[]): Issue[] {
    return duplicates.map(duplicate => ({
        serialNo: duplicate.serialNo || 0,
        fullName: duplicate.email.split(' - ')[0],
        email: duplicate.email.split(' - ')[1],
        type: 'Email Already Registered',
    }));
}

private formatMandatoryEmails(mandatoryEmails: any[]): Issue[] {
    return mandatoryEmails.map(mandatoryEmail => {
        const [fullName, email, fieldType] = mandatoryEmail.email.split(' - ');
        let type = '';

        switch (fieldType?.trim().toLowerCase()) {
            case 'name':
                type = 'Full Name is missing';
                break;
            case 'email':
                type = 'Email is missing';
                break;
            case 'password':
                type = 'Password is missing';
                break;
            case 'phone':
                type = 'Phone No is missing';
                break;
            case 'organization':
                type = 'Organization is missing';
                break;
            case 'team':
                type = 'Team Name is missing';
                break;
            case 'coach name':
                type = 'Coach Name is missing';
                break;
            case 'coach email':
                type = 'Coach Email is missing';
                break;
            case 'sport':
              if(this.staff){
                type = 'Staff Role is missing';
              }else{
                type = 'Sport Name is missing';
              } 
                break;
            case 'supportStaff':
              type = 'supportStaff is missing';
              break;
            default:
                type = fieldType?.trim().toLowerCase();
                break;
        }

        return { serialNo: mandatoryEmail.serialNo || 0, fullName, email, type };
    });
}

private formatCoachEmails(coachEmails: any[]): Issue[] {
    return coachEmails.map(coachEmail => {
        const [fullName, email] = coachEmail.email.split(' - ');
        return { serialNo: coachEmail.serialNo || 0, fullName, email, type: 'Coach Not Found' };
    });
}

private combineIssuesByType(issueData: Issue[]): { serialNo: string; fullName: string; email: string; type: string }[] {
    const grouped = issueData.reduce((acc: Record<string, { serialNo: number; fullName: string; email: string; types: string[] }>, issue: Issue) => {
        const key = issue.serialNo; // Group by serial number

        if (!acc[key]) {
            // If the serial number does not exist in the accumulator, add it
            acc[key] = {
                serialNo: issue.serialNo,
                fullName: issue.fullName,
                email: issue.email,
                types: [issue.type], // Start a new array for types
            };
        } else {
            // If the serial number already exists, push the type into the array
            acc[key].types.push(issue.type);
        }
        return acc;
    }, {});

    // Transform the grouped result into an array with formatted type strings
    return Object.values(grouped).map(item => ({
        serialNo: item.serialNo.toString(), // Convert serial number to string
        fullName: item.fullName,
        email: item.email,
        type: item.types.map((type, index) => `${index + 1}. ${type}`).join('\n') // Join types with numbering
    }));
}
 
  openModal() {
    const modalElement = document.getElementById('duplicateModal')!;
      const modal = new (window as any).bootstrap.Modal(modalElement);
      modal.show();
  }
 
  openFileUploadModal() {
    // Use Bootstrap's modal method to show the modal
    const fileUploadModal = document.getElementById('fileUploadModal')!;
      const modal = new (window as any).bootstrap.Modal(fileUploadModal);
      modal.show();
  }
 
  downloadPDF(): void {
    const pdf = new jsPDF('p', 'mm', 'a4');

    // Set the table data
    const tableData = this.issueData.map(item => [item.serialNo,item.fullName, item.email,item.type]);

    // Define column headers
    const headers = [['Row No','Name', 'Email','Error']];

    // Add the auto table to the PDF
    autoTable(pdf, {
      head: headers,
      body: tableData,
      startY: 20, // Start position for the table
      theme: 'grid', // You can customize the theme
      styles: { cellPadding: 5, fontSize: 10 },
    });

    // Save the PDF
    pdf.save('ExistingEmail.pdf');
  }


  downloadTemplate() {
    if(this.selectedRole == 1){
      const fileUrl = 'assets/Templates/PlayerRegister_Template.xlsx';
      this.http.get(fileUrl, { responseType: 'blob' }).subscribe((blob: Blob) => {
        saveAs(blob, 'PlayerRegister_Template.xlsx');
      }, error => {
        console.error('Error downloading file', error);
      });
    }else if (this.selectedRole == "Coach" ){
      const fileUrl = 'assets/Templates/Coach_Register_Template.xlsx';
      this.http.get(fileUrl, { responseType: 'blob' }).subscribe((blob: Blob) => {
        saveAs(blob, 'Coach_Register_Template.xlsx');
      }, error => {
        console.error('Error downloading file', error);
      });
    }
    else if (this.selectedRole == "Science Staff" ){
      const fileUrl = 'assets/Templates/Science_Staff_Register_Template.xlsx';
      this.http.get(fileUrl, { responseType: 'blob' }).subscribe((blob: Blob) => {
        saveAs(blob, 'Science_Staff_Register_Template.xlsx');
      }, error => {
        console.error('Error downloading file', error);
      });
    }else{
      const fileUrl = 'assets/Templates/Register_Template.xlsx';
      this.http.get(fileUrl, { responseType: 'blob' }).subscribe((blob: Blob) => {
        if (this.selectedRole == "OrgAdmin" ){
          saveAs(blob, 'OrgAdmin_Template.xlsx');
        }
        else{
          saveAs(blob, 'Assessor_Template.xlsx');
        }
      }, error => {
        console.error('Error downloading file', error);
      });
    }
   
  }
  
 
  getClassName(classId: number): string {
    switch (classId) {
      case 1:
        return 'Middle School';
      case 2:
        return 'High School';
      case 3:
        return 'College';
      default:
        return ''; // Return an empty string if no match
    }
  }
  getTeamNameById(teamId: string): string {
    const convertid =  Number(teamId);
    const team = this.teams.find(t => t.teamId === convertid );
    return team ? team.teamName : 'N/A'; // Return 'N/A' if no matching team is found
  }
  getCoachNameById(coachId: number): string {
    const coach = this.trainer.find(t => t.usrId === coachId);
    return coach ? coach.usrFullName : 'N/A';
  }
  validatePhoneNumber(event: KeyboardEvent) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();  // Prevent input if it's not a number (0-9)
    }
  }
  validateECPhoneNumber(event: KeyboardEvent) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();  // Prevent input if it's not a number (0-9)
    }
  }
 
 
}
