<div class="body-wrapper">
  <div class="row flex-grow">
    <div class="col-12 grid-margin stretch-card">
      <div class="card card-rounded">
        <div class="card-body">
          <button class="btn btn-primary mb-2" [routerLink]="['/organization']">
            <span class="btnText">Back</span>
          </button>
          <div class="d-sm-flex justify-content-between align-items-start mb-4">
            <div>
              <h4 class="card-title card-title-dash">
                {{ isEditMode ? 'Edit Organization' : 'Create Organization' }}
              </h4>
            </div>
          </div>

          <form (ngSubmit)="onSubmit()" #assessmentForm="ngForm">
            <label>Organization Name</label>
            <input type="text" class="form-control mt-3" [(ngModel)]="orgName" name="orgName"
              placeholder="Enter Organization Name" required />
          </form>
        </div>

        <div class="card-body">
          <form (ngSubmit)="onSubmit()" #assessmentForm="ngForm">
            <label>Organization Logo</label>
            <input type="file" class="form-control mt-3" (change)="onFileSelected($event)" /> <br>

           

            <button type="submit"  class="submit btn btn-primary"> 
              <span class="btnText">{{ isEditMode ? 'Save Changes' : 'Submit' }}</span>
          </button>
          
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="toaster">
  <ng-toast />
</div>
