import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';

interface Option {
  id: string;
  label: string;
  selected: boolean;
  value?: string;
}

@Component({
  selector: 'app-multi-select-dropdown',
  standalone: true,
  imports: [ HttpClientModule, FormsModule, CommonModule ],
  templateUrl: './multi-select-dropdown.component.html',
  styleUrls: ['./multi-select-dropdown.component.css']
})
export class MultiSelectDropdownComponent {
  @Input() label: string = '';
  @Input() options: Option[] = [];

  dropdownOpen = false;
  selectedOptions: Option[] = [];

  toggleDropdown(): void {
    this.dropdownOpen = !this.dropdownOpen;
  }

  onOptionChange(): void {
    this.selectedOptions = this.options.filter(option => option.selected);
  }

  trackById(index: number, option: Option): string {
    return option.id;
  }
}
