<div class="modal fade" id="fileImageUploadModal" tabindex="-1" aria-labelledby="fileUploadModalLabel"
aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" style="max-width: 400px;">
    <div class="modal-content" style="max-height: 250px;">
      <div class="modal-header">
        <h5 class="modal-title" id="fileUploadModalLabel">Upload Image</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
        <div enctype="multipart/form-data">
          <div class="modal-body">
            <input type="file" (change)="onFileSelected($event)"  multiple />
          </div>
          <div class="modal-footer">
            <button  class="btn btn-primary" (click)="onSubmit()" type="submit"  data-bs-dismiss="modal" aria-label="Close">
                <span class="btnText">Upload</span>
                <i class="uil uil-navigator"></i>
              </button>
              </div>
          </div>
      </div>

      
  </div>
</div>

<!-- <div class="toaster">
  <ng-toast />
</div> -->
