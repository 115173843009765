<div class="body-wrapper">
  <div class="row flex-grow">
    <div class="col-12 grid-margin stretch-card">
      <div class="card card-rounded">
        <div class="card-body">
          <div class="d-sm-flex justify-content-between align-items-start mb-4">
            <div>
              <h4 class="card-title card-title-dash">Edit Organization</h4>
            </div>
          </div>

          <form (ngSubmit)="onSubmit()" #assessmentForm="ngForm">

            <label>Organization Name</label>
            <input type="text" class="form-control mt-3" [(ngModel)]="orgName" name="orgName"
            placeholder="Enter Organization Name" required /> <br>
            <button class="btn btn-primary" [routerLink]="['/organization']">
              <span class="btnText">Back</span>
            </button> &nbsp;&nbsp;
            <button type="submit" class="submit btn btn-primary">
              <span class="btnText">Submit</span>
            </button>

          </form>

        </div>
      </div>
    </div>
  </div>
</div>
<div class="toaster">

  <ng-toast />

</div>