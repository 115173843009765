<!--  Main wrapper -->
<div *ngIf="isLoading" class="loading-spinner">
  <div class="spinner"></div>
</div>
<div class="row">  
      <div class="row">
     
        <div class="col-sm-8 flex-column d-flex stretch-card">
          <div class="row">
            <div class="col-lg-4 d-flex grid-margin stretch-card">
              <div class="card bg-blue">
                <div class="card-body text-white">
                  <h3 class="font-weight-bold mb-3">{{ averageOverallPercentage }}%</h3>
                  <div class="progress mb-3">
                    <!-- Use Angular's property binding to set the width of the progress bar -->
                    <div class="progress-bar bg-warning" role="progressbar" [style.width.%]="averageOverallPercentage"
                      [attr.aria-valuenow]="averageOverallPercentage" aria-valuemin="0" aria-valuemax="100">
                    </div>
                  </div>
                  <p class="pb-0 mb-0">Overall Score</p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 d-flex grid-margin stretch-card">
              <div class="card player-diffrence-border">
                <div class="card-body">
                  <h2 class="text-dark mb-2 font-weight-bold"> {{ overallCount }}</h2>
                  <h4 class="card-title mb-2">Players Count</h4>

                </div>
              </div>
            </div>
            <div class="col-lg-4 d-flex grid-margin stretch-card">
              <!-- <div class="card justify-content-center player-diffrence-border">
                <div class="card-body ms-4">
                  <img class="pro-img"
                  src="{{TopPerformer[1]?.image}}">
                </div>
              </div> -->
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 grid-margin d-flex stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex align-items-center justify-content-between">
                    <h4 class="card-title mb-2">{{GraphHeader}} Performers</h4>
                    <!-- <div class="select-wrapper">
                      <select (change)="filterPerformers($event, 'overall')" class="form-select">
                        <option selected disabled>Select Option</option>
                        <option value="All">All</option>
                        <option value="excellent">Excellent</option>
                        <option value="good">Good</option>
                        <option value="average">Average</option>
                        <option value="poor">Poor</option>
                      </select>
                    </div> -->
                    <div class="dropdown">
                      <a href="#" class="text-success btn btn-link dropdown-toggle dropdown-arrow-none"
                        data-bs-toggle="dropdown" id="settingsDropdownsales">
                        <i class="mdi mdi-dots-horizontal"></i></a>
                        <div class="dropdown-menu dropdown-menu-right navbar-dropdown"
                        aria-labelledby="settingsDropdownsales">
                        <a class="dropdown-item" (click)="filterPerformers('All', 'overall')">
                          <i class="mdi mdi-grease-pencil text-primary"></i>
                          All
                        </a>
                        <a class="dropdown-item" (click)="filterPerformers('excellent', 'overall')">
                          <i class="mdi mdi-grease-pencil text-primary"></i>
                          Excellent
                        </a>
                        <a class="dropdown-item" (click)="filterPerformers('good', 'overall')">
                          <i class="mdi mdi-grease-pencil text-primary"></i>
                          Good
                        </a>
                        <a class="dropdown-item" (click)="filterPerformers('average', 'overall')">
                          <i class="mdi mdi-grease-pencil text-primary"></i>
                          Average
                        </a>
                        <a class="dropdown-item" (click)="filterPerformers('poor', 'overall')">
                          <i class="mdi mdi-grease-pencil text-primary"></i>
                          Poor
                        </a>
                      </div>
                    </div>
                  </div>
                  <div id="overall-chart"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-4 flex-column d-flex stretch-card">
          <div class="row flex-grow">
            <div class="col-sm-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body" *ngIf="TopPerformers.length > 0">
                  <div class="row">
                    <div>
                      <h4 class="card-title fw-semibold">Top Performer</h4>
                      <h4  class="font-weight-bold text-name">Name : {{ TopPerformers[0]?.usrFullName }}</h4>
                      <h4 class="font-weight-bold text-id">ID :
                        {{ TopPerformers[0]?.pfaAthlete }}
                      </h4>
                      <p class="text-dark">{{ TopPerformers[0]?.naCreatedOn | date:'dd-MM-yyyy' }}</p>
                    </div>
                    <!-- <div class="col-lg-4">
                      <div class="position-relative">
                        <img src="{{nutritionTop5Performer[0].imageUrl?.changingThisBreaksApplicationSecurity}}"
                          class="w-100" alt="">
                        <div class="live-info badge badge-success">Live</div>
                      </div>
                    </div> -->
                  </div>
                  <div class="donut-container d-flex align-items-center justify-content-center mb-4">
                    <div class="donut-circle"
                         [ngClass]="{
                           'dark-green': TopPerformers[0]?.totalScore >= 85,
                           'light-green': TopPerformers[0]?.totalScore >= 70,
                           'yellow': TopPerformers[0]?.totalScore >= 40,
                           'red': TopPerformers[0]?.totalScore < 40
                         }">
                      <div class="inner-circle">
                        <h3 class="font-weight-bold">{{ TopPerformers[0]?.totalScore| number: '1.2-2' }}%</h3>
                      </div>
                    </div>
                    <!-- <p class="score-tag">Overall Score</p> -->
                  </div>
                 
                  <table class="score-table">
                    <thead>
                      <tr>
                        <th>Test</th>
                        <th class="text-center">Score</th>
                      </tr>
                    </thead>
                    <tbody>
                        <tr>
                          <td class="score-title">BMI (kg/m²)</td>
                          <td class="score-value">{{ TopPerformers[0]?.pfaBMI || 0 }}</td>
                        </tr>
                        <tr>
                          <td class="score-title">MedBall (M)</td>
                          <td class="score-value">{{ TopPerformers[0]?.pfaSeatedMedBallThrow || 0 }}</td>
                        </tr>
                        <tr>
                          <td class="score-title">Speed (m/s)</td>
                          <td class="score-value">{{ TopPerformers[0]?.pfaSpeed || 0 }}</td>
                        </tr>
                        <tr>
                          <td class="score-title">Yo-Yo Test (M)</td>
                          <td class="score-value">{{ TopPerformers[0]?.pfaYoYoTest || 0 }}</td>
                        </tr>
                        <tr>
                          <td class="score-title">PushUps (min)</td>
                          <td class="score-value">{{ TopPerformers[0]?.pfaPushups || 0 }}</td>
                        </tr>
                    </tbody>
                  </table>
         
                </div>
                <div class="card-body d-flex align-items-center justify-content-center" *ngIf="TopPerformers.length === 0">
                  <h4 class="text-muted">No Data Available</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row row-cards row-deck">
          <div class="d-sm-flex justify-content-between align-items-center mb-2">
            <h4 class="card-title fw-semibold" >{{header}}</h4>
            <div>
              <div class="select-wrapper">
                <!-- <i class="bx bx-cog select-icon"></i> -->
                <select [(ngModel)]="selectedStatus" (change)="onStatusChange()" class="form-select">
                  <option selected disabled>Select Option</option>
                  <option value="0">All </option>
                  <option value="1">Excellent </option>
                  <option value="2">Good</option>
                  <option value="3">Average</option>
                  <option value="4">Poor</option>
                </select>
              </div>
            </div>
          </div>

          <!-- Top Performers Section -->
          <div class="col-12">
            <div class="card">
              <h4 class="m-3" *ngIf="chat">Athlete Data</h4>
              <div class="table-responsive">
                <table class="table table-hover table-outline table-vcenter text-nowrap card-table">
                  <thead>
                    <tr>
                      <th>Profile</th>
                      <th>Athletes</th>
                      <th>Overall Score%</th>
                      <th>BMI (kg/m²)</th>
                      <th>GripLeft (Kgs)</th>
                      <th>GripRight (Kgs)</th>
                      <th>Lower Body Force (N)</th>
                      <th>Lower Body Power (W)</th>
                      <th>Upper Body Force (N)</th>
                      <th>Upper Body Power (W)</th>
                      <th>VO2 (ml/kg/min)</th>
                     
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let performer of topPerformers">
                      <td class="text-center">
       
                        <img class="avatar"
                        src="{{performer.image}}">
                       
                      </td>
                      <td>
                        <div>{{ performer.usrFullName}}</div>
                        <div class="small text-muted">ID: {{ performer.pfaAthlete }}</div>
                      </td>
                      <td>
                      <div class="clearfix">
                          <div class="float-left"><strong>{{ performer.totalScore % 1 === 0 
                            ? performer.totalScore 
                            : (performer.totalScore | number: '1.2-2') }}%</strong></div>
                        </div>
                        <div class="progress progress-xs">
                          <div class="progress-bar" [ngClass]="{
                            'bg-lightgreen': performer.totalScore >= 85,
                            'bg-green':  performer.totalScore >= 70 &&  performer.totalScore < 85,
                            'bg-yellow':  performer.totalScore >= 40 &&  performer.totalScore  < 70,
                            'bg-red': performer.totalScore < 40
                              }" role="progressbar" [style.width.%]="performer.totalScore"></div>
                        </div>
                      </td>
                      <td>{{ performer.pfaBMI }}</td>
                      <td>{{ performer.pfaGripStrengthLeft }}</td>
                      <td class="text-center">{{ performer.pfaGripStrengthRight }}</td>
                      <td class="text-center">{{ performer.pfaLowerBodyForce }}</td>
                      <td class="text-center">{{ performer.pfaLowerBodyPower }}</td>
                      <td class="text-center">{{ performer.pfaUpperBodyForce }}</td>
                      <td class="text-center">{{ performer.pfaUpperBodyPower }}</td>
                      <td class="text-center">{{ performer.pfaVO2 }}</td>
                     </tr>
                  </tbody>
                  <!-- Template for when there is no data -->
                  <ng-template #noData>
                    <tr>
                      <td colspan="8" class="text-center">Data not available</td>
                    </tr>
                  </ng-template>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
   
</div>