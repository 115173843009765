import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-user-details',
  standalone: true,
  imports: [CommonModule,RouterOutlet],
  templateUrl: './user-details.component.html',
  styleUrl: './user-details.component.css'
})
export class UserDetailsComponent {
constructor(private router: Router,private route: ActivatedRoute) {}
  goToProfile() {  
    this.router.navigate(['personalInformation']);
  }
}
