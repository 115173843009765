import { AgGridAngular } from "ag-grid-angular";
import { ColDef } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { Component, NgZone } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ActivatedRoute, Router, RouterLink, RouterModule, RouterOutlet } from "@angular/router";
import { AssessmentService } from "../../../services/assessment.service";
import { PrivilegeService } from "../../../services/privilage.service";
import { Privilege } from "../../../model/Privillage";
import { UserService } from "../../../services/user.service";
import { User } from "../../../model/User";

// Row Data Interface
interface IRow {
  bmaId:string;
  playername: string;
  Assessor: string;
  Speed: string;
  Recommendation: string;
  date:string;
}
@Component({
  selector: 'app-biomechanics-detail-list',
  standalone: true,
  imports: [ CommonModule,AgGridAngular,RouterLink,RouterModule,RouterOutlet],
  templateUrl: './biomechanics-detail-list.component.html',
  styleUrl: './biomechanics-detail-list.component.css'
})
export class BiomechanicsDetailListComponent {
  user: User | null = null;
  isAdmin: boolean = false; 
  isPlayer: boolean = false;
  id:any;
  privilege:Privilege;
  Isplayer: boolean;
  Admin: boolean;
  name: any;
  constructor(private ngZone: NgZone,private router: Router, private route: ActivatedRoute,private assessmentService: AssessmentService,private priService:PrivilegeService,private userService:UserService) {
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
      console.log(this.id,"ID");
    });
    this.privilege = this.priService.getPrivileges();
    this.Isplayer = this.privilege.isPlayer;
    this.Admin = this.privilege.isAdmin;
  
  }

  themeClass = "ag-theme-quartz";

  rowData: IRow[] = [];


  colDefs: ColDef[] = [];

  defaultColDef: ColDef = {
    flex: 1,
    sortable: true,
    filter: true
  };

  ngOnInit() {
    this.user = this.userService.getUser();
    if(this.id){
    this.assessmentService.fetchAtheletesBMByID(this.id).subscribe(response => {
      console.log('Assessment submitted', response);
      this.name = response[0].athleteName;
      this.rowData = response.map((item:any) => ({
        bmaId: `${item.bmaId}`,
        playerId:`${item.bmaAthlete}`,
        playername: `${item.athleteName}`,
        Assessor: `Assessor ${item.assessorName}`,
        Speed: `${item.bmaForceOutput}`,
        Strength: `${item.bmaPowerOutput}`,
        ReactionTime: `${item.bmaSymmetry}`,
        date: new Date(item.bmaDate).toLocaleDateString('en-US'),
        priority: this.getPriority(item)
      }));
 
      this.colDefs = this.getColumnDefinitions();
    }, error => {
      console.error('Error submitting assessment', error);
    });
  }
   
  }
 
  getPriority(item: any): string {
    if (item.pfaSpeed > 0 || item.pfaAgility > 0) {
      return 'High';
    } else {
      return 'Low';
    }
  }

  getColumnDefinitions(): ColDef[] {
    const columns: ColDef[] = [
      //{ field: "bmaId" }, 
      //{ field: "playername" }, 
      { field: "playername" }, 
      { field: "Assessor" },
      { field: "Speed" },
      { field: "Recommendation" },
      { field: "date" },
      {
        headerName: 'Action',
        cellRenderer: (_params: any) => {
          const container = document.createElement('div');
          container.style.display = 'flex';
          container.style.justifyContent = 'space-around';
 
          // View button with eye icon
          const viewButton = document.createElement('button');
          viewButton.classList.add('btn');
          viewButton.innerHTML = '<i class="fa fa-eye"></i>';
          viewButton.addEventListener('click', () => {
            this.ngZone.run(() => {
              this.router.navigate(['/biomachanicsdetail'], { queryParams: { id: _params.data.bmaId } })
            })
          });
 
          // Edit button with edit icon
          const editButton = document.createElement('button');
          editButton.classList.add('btn');
          editButton.innerHTML = '<i class="fa fa-edit"></i>';
          editButton.addEventListener('click', () => {
            this.ngZone.run(() => {
            this.router.navigate(['/biomachanics'], {queryParams: { id: _params.data.bmaId } })
            })
          });
 
          // Delete button with delete icon
          // const deleteButton = document.createElement('button');
          // deleteButton.classList.add('btn');
          // deleteButton.innerHTML = '<i class="fa fa-trash"></i>';
          // deleteButton.addEventListener('click', () => {
            // Handle delete action
          // });
 
          container.appendChild(viewButton);
          if (!this.Isplayer) {
          container.appendChild(editButton);
          }
          // container.appendChild(deleteButton);
 
          return container;
        },
        width: 120
      }
    ];

    if (this.isAdmin) {
      return columns;
    } else if (this.isPlayer) {
      return columns.filter(col => col.field !== 'playername');
    } else {
      return columns.filter(col => col.field !== 'Assessor');
    }
  }
}
