<!-- <div class="text-center" *ngIf="this.athleteReport.length === 0">
  No Data!
</div> -->
<div class="row" >
   <div class="col-sm-8 flex-column d-flex stretch-card">
     <div class="row">
    
       <div class="col-lg-4 d-flex grid-margin stretch-card">
         <div class="card bg-blue">
           <div class="card-body text-white">
             <h3 class="font-weight-bold mb-3">{{ averageOverallPercentage }}%</h3>
             <div class="progress mb-3">
               <!-- Use Angular's property binding to set the width of the progress bar -->
               <div class="progress-bar bg-warning" role="progressbar" [style.width.%]="averageOverallPercentage"
                 [attr.aria-valuenow]="averageOverallPercentage" aria-valuemin="0" aria-valuemax="100">
               </div>
             </div>
             <p class="pb-0 mb-0">Overall Score</p>
           </div>
         </div>
       </div>
       <div class="col-lg-4 d-flex grid-margin stretch-card">
         <div class="card player-diffrence-border">
           <div class="card-body">
             <h2 class="text-dark mb-2 font-weight-bold"> {{ cognitiveCount }}</h2>
             <h4 class="card-title mb-2">Players Count</h4>
           </div>
         </div>
       </div>
       <div class="col-lg-4 d-flex grid-margin stretch-card">
        <!-- <div class="card justify-content-center player-diffrence-border" >
          <div class="card-body ms-4">
            <img class="pro-img"
            src="{{cognitiveTop5Performers[0]?.image}}">
          </div>
        </div> -->
      </div>
     </div>
     <div class="row">
       <div class="col-sm-12 grid-margin d-flex stretch-card">
         <div class="card">
           <div class="card-body">
             <div class="d-flex align-items-center justify-content-between">
               <h4 class="card-title mb-2">{{this.reportTitle}} Performers In Cognitive</h4>
               <div class="dropdown">
                 <a href="#" class="text-success btn btn-link dropdown-toggle dropdown-arrow-none"
                   data-bs-toggle="dropdown" id="settingsDropdownsales">
                   <i class="mdi mdi-dots-horizontal"></i></a>
                 <div class="dropdown-menu dropdown-menu-right navbar-dropdown"
                   aria-labelledby="settingsDropdownsales">
                   <a class="dropdown-item" (click)="filterPerformers('All','overall')">
                     <i class="mdi mdi-grease-pencil text-primary"></i>
                     All
                   </a>
                   <a class="dropdown-item" (click)="filterPerformers('Excellent','overall')">
                     <i class="mdi mdi-grease-pencil text-primary"></i>
                     Excellent
                   </a>
                   <a class="dropdown-item"  (click)="filterPerformers('Good','overall')">
                     <i class="mdi mdi-delete text-primary"></i>
                     Good
                   </a>
                   <a class="dropdown-item"  (click)="filterPerformers('Average','overall')">
                     <i class="mdi mdi-delete text-primary"></i>
                     Average
                   </a>
                   <a class="dropdown-item"  (click)="filterPerformers('Poor','overall')">
                     <i class="mdi mdi-delete text-primary"></i>
                     Poor
                   </a>
                 </div>
               </div>
             </div>
             <div id="cognitive-chart"></div>
           </div>
         </div>
       </div>
     </div>
   </div>
   <div class="col-sm-4 flex-column d-flex stretch-card">
     <div class="row flex-grow">
       <div class="col-sm-12 grid-margin stretch-card">
         <div class="card">
           <div class="card-body" *ngIf="cognitiveTop5Performers.length > 0">
             <div class="row">
              <div>
                <h4 class="card-title fw-semibold">Top Performer</h4>
                <h4  *ngIf="!sciStaff"  class="font-weight-bold text-name">Name : {{ cognitiveTop5Performers[0]?.usrFullName }}</h4>
                <h4 class="font-weight-bold text-id">ID :
                  {{ cognitiveTop5Performers[0]?.cnaAthlete }}
                </h4>
                <p class="text-dark">{{ cognitiveTop5Performers[0]?.cnaCreatedOn | date:'dd-MM-yyyy' }}</p>
              </div>
               </div>
               <div class="donut-container d-flex align-items-center justify-content-center mb-4">
                <div class="donut-circle" [ngClass]="{
                           'dark-green': cognitiveTop5Performers[0]?.overallZScore >= 85,
                           'light-green': cognitiveTop5Performers[0]?.overallZScore >= 70 && cognitiveTop5Performers[0]?.overallZScore < 85,
                           'yellow': cognitiveTop5Performers[0]?.overallZScore >= 40 && cognitiveTop5Performers[0]?.overallZScore < 70,
                           'red': cognitiveTop5Performers[0]?.overallZScore < 40,
                           'transparent-border': cognitiveTop5Performers[0]?.overallZScore == null
                         }">
                  <div class="inner-circle">
                    <h3 class="font-weight-bold">{{ cognitiveTop5Performers[0]?.overallZScore| number: '1.2-2' }}%</h3>
                  </div>
                </div>
                <!-- <p class="score-tag">Overall Score</p> -->
              </div>
              <table class="score-table">
                <tr>
                  <th>Test</th>
                  <th>Score</th>
                </tr>
                <tr>
                  <td class="score-title">Blazepods</td>
                  <td class="score-value">{{ ( cognitiveTop5Performers[0]?.cnaBlazepods % 1 === 0) 
                    ?  cognitiveTop5Performers[0]?.cnaBlazepods 
                    : ( cognitiveTop5Performers[0]?.cnaBlazepods | number: '1.2-2') }}</td>
                </tr>
                <tr>
                  <td class="score-title">BlazepodsTime (sec)</td>
                  <td class="score-value">{{ ( cognitiveTop5Performers[0]?.cnaBlazepodsTime % 1 === 0) 
                    ?  cognitiveTop5Performers[0]?.cnaBlazepodsTime 
                    : ( cognitiveTop5Performers[0]?.cnaBlazepodsTime | number: '1.2-2') }}</td>
                </tr>
                <tr>
                  <td class="score-title">WallToss</td>
                  <td class="score-value">{{ ( cognitiveTop5Performers[0]?.cnaWallToss % 1 === 0) 
                    ?  cognitiveTop5Performers[0]?.cnaWallToss 
                    : ( cognitiveTop5Performers[0]?.cnaWallToss | number: '1.2-2') }}</td>
                </tr>
                <tr>
                  <td class="score-title">PlateTaping (sec)</td>
                  <td class="score-value">{{ ( cognitiveTop5Performers[0]?.cnaPlateTaping % 1 === 0) 
                    ?  cognitiveTop5Performers[0]?.cnaPlateTaping 
                    : ( cognitiveTop5Performers[0]?.cnaPlateTaping | number: '1.2-2') }}</td>
                </tr>
                <tr>
                  <td class="score-title">RulerDrop (cm)</td>
                  <td class="score-value">{{ ( cognitiveTop5Performers[0]?.cnaRulerDrop % 1 === 0) 
                    ?  cognitiveTop5Performers[0]?.cnaRulerDrop 
                    : ( cognitiveTop5Performers[0]?.cnaRulerDrop | number: '1.2-2') }}</td>
                </tr>
              </table>
           </div>
           <div  class="card-body d-flex align-items-center justify-content-center" *ngIf="cognitiveTop5Performers.length== 0">
            <h4 class="text-muted">No Data Available</h4>
          </div>
         </div>
       </div>
     </div>
   </div>
   <div class="row row-cards row-deck">
     <div class="col-12">
       <div class="card">
        <h4  class="card-title fw-semibold">International Benchmark (IBM)</h4>
         <div class="table-responsive">
           <table class="table table-hover table-outline table-vcenter text-nowrap card-table">
             <thead>
               <tr>
                 <th>Test</th>
                 <th>IBM Male</th>
                 <th>IBM Female</th>
                 <th>Team Average Score</th>
                 <th>Team Average PCTL</th>
               </tr>
             </thead>
             <tbody *ngIf="cognitiveTop5Performers && cognitiveTop5Performers.length > 0; else noData">
               <tr>
                 <td>Ruler Drop (cm)</td>
                 <td>0.25</td>
                 <td>0.25</td>
                 <td>{{ this.averageCNaRulerDrop| number: '1.2-2' }}</td>
                 <td>
                  <div class="circular-progress mb-2">
                    <svg width="60" height="60" class="progress-circle">
                      <!-- Background Circle -->
                      <circle class="background" cx="30" cy="30" r="27" stroke="green" stroke-width="6" fill="none"></circle>
                      <!-- Foreground Circle (Percentage) -->
                      <circle class="circle"
                              cx="30" cy="30" r="27"
                              [attr.stroke]="getColor(averageCNaRulerDropz)"
                              stroke-width="6"
                              stroke-dasharray="169.65"
                              stroke-dashoffset="42.41"
                              stroke-linecap="round">
                      </circle>
                      <!-- Hardcoded Percentage Text in Center -->
                      <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="#000" font-size="12">
                        {{ averageCNaRulerDropz | number: '1.2-2' }}
                      </text>
                    </svg>
                  </div>
                 
                 
                </td>
               </tr>
               <!-- <tr>
                 <td>Blaze Pods</td>
                 <td></td>
                 <td></td>
                 <td>{{ this.averageCNaBlazePods.toFixed(2) }}</td>
               </tr> -->
               <!-- <tr>
                 <td>Blaze Pods Time</td>
                 <td></td>
                 <td></td>
                 <td>{{ this.averageCNaBlazepodsTime.toFixed(2) }}</td>
               </tr> -->
               <tr>
                 <td>Wall Toss</td>
                 <td>30-40</td>
                 <td>30-40</td>
                 <td>{{ this.averageCNaWallToss.toFixed(2) }}</td>
                 <td>
                  <div class="circular-progress mb-2">
                    <svg width="60" height="60" class="progress-circle">
                      <!-- Background Circle -->
                      <circle class="background" cx="30" cy="30" r="27" stroke="green" stroke-width="6" fill="none"></circle>
                      <!-- Foreground Circle (Percentage) -->
                      <circle class="circle"
                              cx="30" cy="30" r="27"
                              [attr.stroke]="getColor(averageCNaBlazePodsz)"
                              stroke-width="6"
                              stroke-dasharray="169.65"
                              stroke-dashoffset="42.41"
                              stroke-linecap="round">
                      </circle>
                      <!-- Hardcoded Percentage Text in Center -->
                      <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="#000" font-size="12">
                        {{ averageCNaBlazePodsz | number: '1.2-2' }}
                      </text>
                    </svg>
                  </div>
                 
                 
                </td>
               </tr>
               <!-- <tr>
                 <td>Plate Taping</td>
                 <td></td>
                 <td></td>
                 <td>{{ this.averageCNaPlateTaping.toFixed(2) }}</td>
               </tr> -->
             </tbody>
             <ng-template #noData>
               <tr>
                 <td colspan="8" class="text-center">Data not available</td>
               </tr>
             </ng-template>
           </table>
         </div>
       </div>
     </div>
   </div>
   <div class="row row-cards row-deck">
     <div class="d-sm-flex justify-content-between align-items-center mb-2">
       <h4 class="card-title fw-semibold" >{{header}} Performers</h4>
       <div>
         <div class="select-wrapper">
           <select [(ngModel)]="selectedStatus" (change)="onCognitiveStatusChange()" class="form-select">
             <option selected disabled>Select Option</option>
             <option value="0">All</option>
             <option value="1">Excellent </option>
             <option value="2">Good</option>
             <option value="3">Average </option>
             <option value="4">Poor</option>
           </select>
         </div>
       </div>
     </div>
     <!-- Top Performers Section -->
     <div class="col-12">
       <div class="card">
         <h4 class="m-3" *ngIf="chat">Athlete Data</h4>
         <div class="table-responsive">
           <table class="table table-hover table-outline table-vcenter text-nowrap card-table">
             <thead>
               <tr>
                 <th>Profile</th>
                 <th>Athletes</th>
                 <th>overall%</th>
                 <th>Ruler Drop (cm)</th>
                 <th>Blaze pods</th>
                 <th>Blaze pods Time (sec)</th>
                 <th>Wall Toss</th>
                 <th>Plate Taping (sec)</th>
                 
                 <!-- <th>AssessmentDate</th> -->
               </tr>
             </thead>
             <tbody *ngIf="CognitivePerformers && CognitivePerformers.length > 0; else noData">
               <tr *ngFor="let performer of CognitivePerformers">
                 <td class="text-center">
                  <img class="avatar"
                  src="{{performer.image}}">
                 </td>
                 <td>
                   <div>{{ performer.usrFullName }}</div>
                   <div class="small text-muted">ID: {{ performer.cnaAthlete }}</div>
                 </td>
                 <td>
                  <div class="clearfix">
                    <div class="float-left"><strong>{{performer.overallZScore}}%</strong></div>
                  </div>
                  <div class="progress progress-xs">
                    <div class="progress-bar" [ngClass]="{
                      'bg-lightgreen': performer.overallZScore >= 85,
                      'bg-green':  performer.overallZScore >= 70 &&  performer.overallZScore < 85,
                      'bg-yellow':  performer.overallZScore >= 40 &&  performer.overallZScore  < 70,
                      'bg-red': performer.overallZScore < 40
                        }" role="progressbar" [style.width.%]="performer.overallZScore"></div>
                  </div>
                </td>
                
                 <td>
                   <div class="clearfix">
                     <div class="float-left"><strong>{{ performer.cnaRulerDrop }}</strong></div>
                   </div>
                   <!-- <div class="progress progress-xs">
                 <div class="progress-bar" [ngClass]="{
             'bg-green': performer.loaAverageRange >= 80,
             'bg-yellow': performer.loaAverageRange >= 50 && performer.loaAverageRange < 80,
             'bg-red': performer.loaAverageRange < 50
           }" role="progressbar" [style.width.%]="performer.loaAverageRange"></div>
               </div> -->
                 </td>
                 <td>{{ performer.cnaBlazepods }}</td>
                 <td>{{ performer.cnaBlazepodsTime }}</td>
                 <td>{{ performer.cnaWallToss }}</td>
                 <td>{{ performer.cnaPlateTaping }}</td>
                 
                 
                 <!-- <td>{{performer.cnaCreatedOn | date:'dd/MM/yyyy'}}</td> -->
               </tr>
             </tbody>
             <!-- Template for when there is no data -->
             <ng-template #noData>
               <tr>
                 <td colspan="8" class="text-center">Data not available</td>
               </tr>
             </ng-template>
           </table>
         </div>
       </div>
     </div>
   </div>
 </div>