import { CommonModule } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { UserRegisterService } from '../../services/user-register.service';
import { AssessmentService } from '../../services/assessment.service';
import { DomSanitizer } from '@angular/platform-browser';
import { catchError, firstValueFrom, map, Observable, of } from 'rxjs';
import { PrivilegeService } from '../../services/privilage.service';
import { Privilege } from '../../model/Privillage';
import { RouterOutlet } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { NgToastModule } from 'ng-angular-popup';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { FileuploadComponent } from '../../assessment/fileupload/fileupload.component';
import { NotificationService } from '../../services/Notifications.service';
import { SingleImageuploadComponent } from '../../assessment/imageupload/single-imageupload/single-imageupload.component';


@Component({
  selector: 'app-user-profile',
  standalone: true,
  imports: [CommonModule, RouterOutlet, HttpClientModule, FormsModule, NgToastModule,RecaptchaModule,RecaptchaFormsModule,FileuploadComponent,SingleImageuploadComponent],
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']  // Correct this to 'styleUrls' if it's multiple stylesheets
})
export class UserProfileComponent {

  @ViewChild('fileImageUploadModal') fileImageUploadModal: SingleImageuploadComponent | undefined;
  detail: any;
  UserId: any;
  oldPassword:any;
  newPassword:any;
  confirmPassword:any;
  Response: any;
  Isplayer: boolean=false;
  privilege:Privilege;
  profileImageUrl: any;
  isLoading = false;
  formData: any = {
    usrId:'',
    usrFullName: '',
    usrDoB: '',
    usrGender: '', 
    usrAddress: '',
    usrPhoneNo: '',
    usrPassword: '',
    usrEmail: '',
    usrECName: '',
    usrECRelationship: '',
    usrECPhoneNo: '',
    usrSport: '',
    usrSupport: '',
    usrOrganization: '',
    usrActive:1,
    usrRole:''
  }
  trainerDetail: any = {
    usrId:null,
    usrFullName: null,
    usrAddress: null,
    usrPhoneNo: null
};
  UserRole: any;
  imageUrl: any;
constructor(
    private sharedService: SharedService,
    private userRegisterService: UserRegisterService,
    private assessmentService: AssessmentService,
    private notify: NotificationService,
    private sanitizer: DomSanitizer,private priService:PrivilegeService
    
  ) {
    this.sharedService.detail$.subscribe((data: any) => {
      this.detail = data;
      this.UserId = this.detail.user.usrId;
      this.UserRole = this.detail.user.usrRole;
    });
    this.privilege = this.priService.getPrivileges();
    this.Isplayer = this.privilege.isPlayer;
  }

  ngOnInit() {
    this.getUser();
    this.getprofileImage();
  }


  getprofileImage() {
     
    this.assessmentService.getEmptyImage(this.UserId).subscribe(
      (response: any) => {
        if (response ) {      
          this.profileImageUrl = "assets/image/profileimg.jpg"
                  
        }
      },
      (error: any) => {
        this.assessmentService.getImage(this.UserId).subscribe(
          (imageBlob) => {
        const objectURL = URL.createObjectURL(imageBlob);
        this.imageUrl = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        this.profileImageUrl = this.imageUrl.changingThisBreaksApplicationSecurity
        this.isLoading = false;
        
          }
      );
        console.error('Error fetching image', error);
      }
    );
  }
  

  getUser() {
    this.userRegisterService.fetchUser(this.UserId).subscribe(response => {
      this.Response = response;
      console.log(this.Response, "Response");
  const userData = this.Response[0]
   
      // Populate the trainerDetail object with the response data
      this.trainerDetail.usrId = userData.usrId;
      this.trainerDetail.usrDoB = userData.usrDoB ? userData.usrDoB.split('T')[0] : '';
      this.trainerDetail.usrFullName = userData.usrFullName;
      this.trainerDetail.usrAddress = userData.usrAddress;
      this.trainerDetail.usrPhoneNo = userData.usrPhoneNo;
    });
  }
  

  openImageModal(): void {
    const modalElement1 = document.getElementById('fileImageUploadModal')!;
    const modal1 = new (window as any).bootstrap.Modal(modalElement1);
    modal1.show();
  }

  
  getProfileImage(userId: any): Observable<any> {
    return this.assessmentService.getImage(userId).pipe(
      map((imageBlob: Blob | MediaSource) => {
        const objectURL = URL.createObjectURL(imageBlob);
        return this.sanitizer.bypassSecurityTrustUrl(objectURL);
      }),
      catchError((error) => {
        console.error('Error fetching image', error);
        return of(''); 
      })
    );
  }

  onSubmit() {
  // Ensure phone number is a string
  this.trainerDetail.usrPhoneNo = this.trainerDetail.usrPhoneNo.toString();
  
  // Call the service to update the profile
  this.userRegisterService.updateProfile(this.trainerDetail).subscribe(
    (response: any) => {
      this.Response = response; 
      
      // Display success notification
      this.notify.success('Profile Data Saved successfully'); 
      this.sharedService.updateDetail({ user: this.trainerDetail });
      
      // Instead of reloading, directly update local variables if needed
      // For example, if 'Response' is used elsewhere in the component to reflect updates:
      this.detail.user = { ...this.detail.user, ...this.trainerDetail };
      
      // Optionally, update UI to reflect changes without reload
      // Remove 'location.reload();' to keep the notification visible
    },
    (error) => {
      // Handle any error responses
      this.notify.failed('Failed to save profile data');
      console.error('Error updating profile:', error);
    }
  );
}

  onSave() {
    // Initialize a variable to hold error message
    let errorMessage = '';

    // Check if new password and confirmation match
    if (this.newPassword !== this.confirmPassword) {
        errorMessage = 'New password and confirmation do not match';
    } 
    // Check if old password and new password are the same
    else if (this.oldPassword === this.newPassword) {
        errorMessage = 'New password cannot be the same as the old password';
    }
    if (errorMessage) {
        this.notify.failed(errorMessage);
        return; 
    }

    // Call changePassword method from userRegisterService if no errors
    this.userRegisterService.changePassword(this.UserId, this.oldPassword, this.newPassword).subscribe(
        (response: any) => {
            this.notify.success('Password Updated Successfully');
        },
        (error) => {
            // Handle error response
            this.notify.failed('Failed to change password');
        }
    );
}
validatePhoneNumber(event: any) {
  const input = event.target.value;
  const filteredValue = input.replace(/[^0-9]/g, ''); 
  event.target.value = filteredValue;
if (filteredValue.length > 0 && filteredValue.startsWith('0')) {
  event.target.value = ''; 
  return; 
}
  this.trainerDetail.usrPhoneNo = event.target.value;
}

validateAlphabet(event: KeyboardEvent) {
  const charCode = event.charCode;
  // Allow only letters (A-Z, a-z) and spaces (charCode 32)
  if (
      (charCode < 65 || charCode > 90) && // A-Z
      (charCode < 97 || charCode > 122) && // a-z
      charCode !== 32 // space
  ) {
      event.preventDefault();
  }
}
  
}
