<!-- <div class="content" style="width: 100%; height: 100%;">
  <ag-grid-angular
    class="ag-theme-quartz"
    style="height: 500px;"
    [rowData]="rowData"
    [columnDefs]="colDefs"
    [defaultColDef]="defaultColDef"
    [pagination]="true"
  />
</div> -->


<div class="body-wrapper">  
  <div class="row flex-grow">
    <div class="col-12 grid-margin stretch-card">
      <div class="card card-rounded">
        <div class="card-body">
          <div class="d-sm-flex justify-content-between align-items-start">
            <div>
              <h4 class="card-title card-title-dash">Player ID :{{UsrId}}</h4>
              <p class="card-subtitle card-subtitle-dash">Fitness report</p>
            </div>
 
          </div>
 
          <div class="content" style="width: 100%; height: 100%;">
              <!-- The AG Grid component, with Dimensions, CSS Theme, Row Data, and Column Definition -->
              <ag-grid-angular
              class="ag-theme-quartz"
              style="height: 500px;"
              [rowData]="rowData"
              [columnDefs]="colDefs"
              [defaultColDef]="defaultColDef"
              [pagination]="true"
            />
            </div>
 
 
 
        </div>
      </div>
    </div>
  </div>
</div>