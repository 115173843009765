import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID , ViewChild} from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { AssessmentService } from '../services/assessment.service';
import { catchError, firstValueFrom, map, Observable, of } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { PrivilegeService } from '../services/privilage.service';
import { UserService } from '../services/user.service';
import { SharedService } from '../services/shared.service';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { ChatService } from '../services/chat.service';
import { PlayerDashboardComponent } from '../player-dashboard/player-dashboard.component';
import { AssessorDashboardComponent } from '../assessor-dashboard/assessor-dashboard.component'
import c3 from 'c3';
import {PhysicalFitnessDashboardComponent } from '../component/physical-fitness-dashboard/physical-fitness-dashboard.component';
import { NutritionalDashboardComponent } from '../component/nutritional-dashboard/nutritional-dashboard.component';
import { CognitiveDashboardComponent } from '../component/cognitive-dashboard/cognitive-dashboard.component';
import { InjuryPreventionDashboardComponent } from '../component/injury-prevention-dashboard/injury-prevention-dashboard.component';
import { LevelOneDashboardComponent } from '../component/level-one-dashboard/level-one-dashboard.component';
import { SportPerformanceComponent } from '../component/sport-performance/sport-performance.component'
import { OverallDashboardComponent } from '../component/overall-dashboard/overall-dashboard.component';
 
@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [CommonModule, PlayerDashboardComponent, HttpClientModule, FormsModule, PhysicalFitnessDashboardComponent, NutritionalDashboardComponent,CognitiveDashboardComponent,InjuryPreventionDashboardComponent,LevelOneDashboardComponent, SportPerformanceComponent,OverallDashboardComponent,AssessorDashboardComponent],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.css'
})
export class DashboardComponent {
  @ViewChild(OverallDashboardComponent) overallComponent!: OverallDashboardComponent;
  @ViewChild(PhysicalFitnessDashboardComponent) physicalFitnessComponent!: PhysicalFitnessDashboardComponent;
  @ViewChild(NutritionalDashboardComponent) nutritionComponent!: NutritionalDashboardComponent;
  @ViewChild(CognitiveDashboardComponent) cognitiveComponent!: CognitiveDashboardComponent;
  @ViewChild(InjuryPreventionDashboardComponent) injuryPreventionAnalysisComponent!: InjuryPreventionDashboardComponent;
  @ViewChild(SportPerformanceComponent) sportsPerformanceComponent!: SportPerformanceComponent;
  user: any;
  apiResponse: any;
  topPerformers: any;
  athletedata: any;
  leastPerformers: any;
  selectedImageUrl: any;
  selectedStatus: number = 0;
  selectedGender: string = '';
  userMessage: string = ""; // Default message
  List: any;
  isLoading = false;
  c3: any;
  Response: any;
  chat: any;
  Performers: any;
  header: any;
  privilege: any;
  Isplayer: boolean = false;
  Admin: boolean = false;
  OrgAdmin: boolean = false;
  selectedOption: string = 'All'; // Default value
  public imageUrls: any[] = [];
  detail: any;
  UserId: any;
  org: any;
  ID: any;
  role: any;
  athleteReport: any[] = [];
  reportCount: number = 0;  // Variable to store the count
  topPerformer: any = null;  // Add this line
  averageOverallPercentage: any = null;  // Add this line
  nutrionReport: any[] = [];
  nutritiontopPerformer: any = null;
  averageNutritionOverallPercentage: any = null;
  reportNutritionCount: number = 0;
  fitnessTopPerformer: any[] = [];
  nutritionTop5Performer: any[] = [];
  FitnessPerformers: any[] = [];
  Cardio: boolean = false;
  physiologyReport: any[] = [];
  reportphysiologyCount: number = 0;
  physiologytopPerformer: any = null;
  averagePhysiologyOverallPercentage: any = null;
  physiologyTop5Performer: any[] = [];
  physioloyPerformers: any[] = [];
  admitcardReport: any[] = [];
  msg: any;
  nutResponse: any;
  pfaResponse: any;
  nutPerformers: any;
  pfaPerformers: any;
  plaResponse: any;
  plaPerformers: any;
  VideoAnalysis: boolean = false;
  PhysicalFitness: boolean = false;
  Nutrition: boolean = false;
  averageOverallZScore: number = 0;
  Cognitive: boolean = false;
  CognitivePerformers: any[] = [];
  cognitiveResponse: any[] = [];
  Orgainsation: any;
  selectedOrg: any = '0';
  trainerId: any;
  selectedTrainer: string = '';
  Coach: boolean = false;
  Trainer: any;
  fitnessOverallPercentage: any;
  cognitiveTop5Performers: any[] = [];
  selectedOptions: string = 'CSS';
  InjuryPrevention = false;
  sportsPerformance = false;
  sciStaff: boolean = false;
  assessor: boolean = false;
  selectedTeamId:any = 0;
  profileImageUrl = "assets/image/profileimg.jpg"
  teams:any;
  admitcard: any[]=[];
  admitcarddata: any[]=[];
  isButtonDisabled: boolean=false;
 constructor(
    private router: Router,
    private assessmentService: AssessmentService,
    private priService: PrivilegeService,
    private userService: UserService,
    private sharedService: SharedService,
    private sanitizer: DomSanitizer,
    private chatService: ChatService, @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.sharedService.detail$.subscribe((data: any) => {
      this.detail = data;
      this.UserId = this.detail.user.usrId;
      this.org = this.detail.user.usrOrganization;
    });
    this.privilege = this.priService.getPrivileges();
    this.Isplayer = this.privilege?.isPlayer ?? false;
    this.Admin = this.privilege?.isAdmin ?? false;
    this.OrgAdmin = this.privilege?.isOrgAdmin ?? false;
    this.VideoAnalysis = this.privilege?.isVideoAnalysis ?? false;
    this.Nutrition = this.privilege?.isNutrition ?? false;
    this.PhysicalFitness = this.privilege?.isPhysicalFitness ?? false;
    this.Cognitive = this.privilege?.isCognitive ?? false;
    this.Coach = this.privilege?.isCoach ?? false;
    this.sciStaff = this.privilege.isScienceStaff ?? false;
    this.assessor = this.privilege.isAssessor ?? false;
    this.selectOrganisation();
  }
 
 
  async ngOnInit(): Promise<void> {
    this.user = this.userService.getUser();
    this.assessmentService.getTeams().subscribe((data: any[]) => {
      if(this.org != '0'){
        this.teams = data.filter((item:any)=> item.teamOrganization === Number(this.org));
      }else{
        this.teams = data;
       
      }      
    });
    this.assessmentService.getOrgainsation().subscribe(
      (data) => {
        this.Orgainsation = data;
      },
      (error) => {
        console.error('Error fetching coaches:', error);
      }
    );
 
    this.assessmentService.getTrainer().subscribe(
      (data) => {
        this.Trainer = data;
      },
      (error) => {
        console.error('Error fetching coaches:', error);
      }
    );
    this.selectOrganisation();
  }
 
  async selectOrganisation() {
    // Reset Team and Category dropdowns when organization is changed
    this.selectedTeamId = '0'; // Reset Team dropdown
    this.selectedOption = 'All'; // Reset Category dropdown
    
    // When selectedOrg is '0' (i.e., "All"), reset Types dropdown to "CSS Health & Fitness"
    if (this.selectedOrg === '0') {
      this.selectedOptions = 'CSS'; // Set Type dropdown to "CSS Health & Fitness"
    }
  
    // Fetch and filter teams based on the selected organization
    this.assessmentService.getTeams().subscribe((data: any[]) => {
      if (this.selectedOrg !== '0') {
        // Filter teams by organization ID
        this.teams = data.filter((item: any) => item.teamOrganization === Number(this.selectedOrg));
        
        // Filter admit card data by selected organization ID
        this.admitcarddata = this.admitcardReport.filter((item: any) => 
          item.usrOrganization === Number(this.selectedOrg)
        );
  
        this.orgStudents(); // Fetch students based on selected organization
      } else {
        this.teams = data;
        this.admitcarddata = [];
        this.admitcardReport = [];
      }
  
      if (this.OrgAdmin) {
        this.orgStudents();
      }
  
      // Enable or disable button based on the availability of admit card data
      this.isButtonDisabled = this.admitcarddata.length === 0;
    });
  
    // Reset other components related to the selection
    this.updateChildComponents();
    this.chat = null;
    this.athletedata = null;
  }
  
  orgStudents() {
    this.chat = null;
    this.athletedata = null;
  
    if (this.Admin || this.OrgAdmin) {
      this.assessmentService.getAdmitCard(this.selectedOrg).subscribe((response: any) => {
        this.admitcardReport = response;
        
        // Filter admit card report based on selected organization
        if (this.selectedOrg !== '0') {
          if(this.selectedTeamId !== '0'){
            this.admitcarddata = this.admitcardReport.filter((item: any) => 
              item.usrOrganization === Number(this.selectedOrg) &&item.asiCurrentClubTeam === this.selectedTeamId
            ); 
          }else{
            this.admitcarddata = this.admitcardReport.filter((item: any) => 
              item.usrOrganization === Number(this.selectedOrg) 
            );
          } } else {
          this.admitcarddata = [];
        }
  
        // Update button state based on admit card data availability
        this.isButtonDisabled = this.admitcarddata.length === 0;
      });
    }
  }
  
  selectAssessment(){
    this.chat=null;
    this.athletedata=null;
    if (this.selectedOption === 'All') {
      this.PhysicalFitness = this.Nutrition = this.Cognitive = this.InjuryPrevention = this.sportsPerformance = false;
      this.overallComponent.sendMessage();
    } else if (this.selectedOption === 'Nutrition') {
      this.Nutrition = true;
      this.PhysicalFitness = this.Cognitive = this.InjuryPrevention = this.sportsPerformance = false;
      this.nutritionComponent.sendMessage();
    } else if (this.selectedOption === 'PhysicalFitness') {
      this.PhysicalFitness = true;
      this.Nutrition = this.Cognitive = this.InjuryPrevention = this.sportsPerformance = false;
    } else if (this.selectedOption === 'Cognitive') {
      this.Cognitive = true;
      this.Nutrition = this.PhysicalFitness = this.InjuryPrevention = this.sportsPerformance = false;
      this.cognitiveComponent.sendMessage();
    } else if (this.selectedOption === 'Injury Prevention Analysis') {
      this.InjuryPrevention = true;
      this.Nutrition = this.PhysicalFitness = this.Cognitive = this.sportsPerformance = false;
      this.injuryPreventionAnalysisComponent.sendMessage();
    } else if (this.selectedOption === 'Sports Performance Analysis') {
      this.sportsPerformance = true;
      this.Nutrition = this.PhysicalFitness = this.Cognitive = this.InjuryPrevention = false;
      this.sportsPerformanceComponent.sendMessage();
    }
  }
 
  handleMessageSend() {
    this.resetAnalysisFlags();
   
    switch (this.selectedOption) {
      case 'Nutrition':
        this.Nutrition = true;
        this.nutritionComponent.sendMessage();
        break;
      case 'PhysicalFitness':
        this.PhysicalFitness = true;
        this.physicalFitnessComponent.sendMessage();
        break;
      case 'Cognitive':
        this.Cognitive = true;
        this.cognitiveComponent.sendMessage();
        break;
      case 'Injury Prevention Analysis':
        this.InjuryPrevention = true;
        this.injuryPreventionAnalysisComponent.sendMessage();
        break;
      case 'Sports Performance Analysis':
        this.sportsPerformance = true;
        this.sportsPerformanceComponent.sendMessage();
        break;
      default:
        this.overallComponent.sendMessage();
    }
  }
 
  resetAnalysisFlags() {
    this.PhysicalFitness = this.Nutrition = this.Cognitive = this.InjuryPrevention = this.sportsPerformance = false;
  }
 
  updateChildComponents() {
    switch (this.selectedOption) {
      case 'All':
        this.overallComponent.onTeamSelected(this.selectedTeamId);
        break;
      case 'Nutrition':
        this.nutritionComponent.onTeamSelected(this.selectedTeamId);
        break;
      case 'PhysicalFitness':
        this.physicalFitnessComponent.onTeamSelected(this.selectedTeamId);
        break;
      case 'Cognitive':
        this.cognitiveComponent.onTeamSelected(this.selectedTeamId);
        break;
      case 'Injury Prevention Analysis':
        this.injuryPreventionAnalysisComponent.onTeamSelected(this.selectedTeamId);
        break;
      case 'Sports Performance Analysis':
        this.sportsPerformanceComponent.onTeamSelected(this.selectedTeamId);
        break;
    }
  }
  handleChatFromChild(chat: string) {
    this.chat = chat;
  }
 
  getFilteredChat(): string {
    // Assuming "JSON Format" starts from a specific keyword you want to hide
    const jsonIndex = this.chat.indexOf("In JSON format:");
    if (jsonIndex !== -1) {
      return this.chat.substring(0, jsonIndex);  // Return text before "JSON Format"
    }
    return this.chat;  // If "JSON Format" is not found, return the full text
  }
 
  formnavigation() {
    this.router.navigate(['/nutrition']);
  }
 
  getProfileImage(userId: any): Observable<any> {
    return this.assessmentService.getImage(userId).pipe(
      map((imageBlob: Blob | MediaSource) => {
        const objectURL = URL.createObjectURL(imageBlob);
        return this.sanitizer.bypassSecurityTrustUrl(objectURL);
      }),
      catchError((error) => {
        console.error('Error fetching image', error);
        return of(''); // Return an empty string or placeholder image URL on error
      })
    );
  }
 
  openImageModal(imageUrl: string) {
    this.selectedImageUrl = imageUrl;
    const modalElement = document.getElementById('imageModal');
    const modal = new (window as any).bootstrap.Modal(modalElement);
    modal.show();
  }
 
  fetchImageUrls() {
    this.imageUrls = this.apiResponse.map((athlete: any) => {
      if (athlete.usrImage) {
        return this.sanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + athlete.usrImage);
      } else {
        return this.profileImageUrl;
      }
    });
  }
 
  showTable(data: any, ageGroups: number[]) {
    const table = document.getElementById('dataTable');
    const age = ageGroups[data.index];
    const category = data.name;
    let filteredAthletes = this.apiResponse.filter((item: any) => {
      if (item.aggAge !== age) return false;
      if (category === 'Good' && item.loaAverageRange >= 80) return true;
      if (category === 'Average' && item.loaAverageRange >= 60 && item.loaAverageRange < 80) return true;
      if (category === 'Below Average' && item.loaAverageRange  < 60) return true;
 
      return false;
    });
 
    this.fetchImageUrls();
    let tableRows = filteredAthletes.map((athlete: any) => `
      <tr>
        <td>${athlete.usrFullName}</td>  <!-- Person's name -->
        <td>${athlete.aggAge}</td>   <!-- Age -->
        <td>${athlete.loaHeight}</td>  <!-- Height -->
        <td>${athlete.loaWeight}</td>  <!-- Weight -->
        <td>${athlete.loaAverageRange}</td>  <!-- Performance range -->
      </tr>
    `).join('');
 
    // Dynamically update table content
    if (table) {
      table.innerHTML = `
        <table class="table">
          <thead>
            <tr>
           
              <th>Name</th>
              <th>Age</th>
              <th>Height</th>
              <th>Weight</th>
              <th>Average Range</th>
            </tr>
          </thead>
          <tbody>
            ${tableRows}
          </tbody>
        </table>
      `;
      table.style.display = 'block'; // Make the table visible
    }
  }
 
  async selectTrainer(){
   
  }
 
  async onDropdownChange() {
    this.chat=null;
    this.athletedata=null;
   if(this.selectedOptions === 'CSS'){
         this.selectedOption = 'All';
   }    
   if (this.selectedOptions === 'Level One') {
    this.Nutrition = false;
    this.PhysicalFitness = this.Cognitive = this.InjuryPrevention = this.sportsPerformance = false;
  }
  }
 
  DownloadClick() {
    if (this.Admin || this.OrgAdmin) {
      const organizationFilter = this.OrgAdmin ? this.org : this.selectedOrg;
    
      // Filter admitcard based on the user's organization
      this.admitcard = this.admitcardReport.filter((item: any) =>
          item.usrOrganization == organizationFilter
      );
      // Then, if a team is selected, filter by selected team
      if (this.selectedTeamId != 0 && this.selectedTeamId != '0') {
        this.admitcard = this.admitcard.filter((item: any) =>
          item.asiCurrentClubTeam == this.selectedTeamId
        );
      }
 
      // Map the image URLs for multiple athletes
      this.imageUrls = this.admitcard.map((athlete: any) => {
        if (athlete.usrImage) {
          return this.sanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + athlete.usrImage);
        } else {
          return this.profileImageUrl; // Handle cases where there is no image
        }
      });
 
    } else if (this.Isplayer) {
      this.assessmentService.getAdmitCardById(this.UserId).subscribe((response: any) => {
        this.admitcardReport = response;
        this.admitcard = this.admitcardReport;
 
        this.imageUrls = this.admitcard.map((athlete: any) => {
          if (athlete.usrImage) {
            return this.sanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + athlete.usrImage);
          } else {
            return this.profileImageUrl; // Handle cases where there is no image
          }
        });
      });
    }
  }
 
 
  downloadPDF(): void {
    const DATA = document.getElementById('pdf-content');
    if (DATA) {
      const pdf = new jsPDF('p', 'mm', 'a4'); // Initialize pdf once
      const promises: Promise<void>[] = []; // Create an array to hold promises
 
      this.admitcard.forEach((athlete: any, index: number) => {
        const athleteElement = DATA.querySelectorAll('.modal-body')[index]; // Target each athlete
        if (athleteElement) {
          const promise = html2canvas(athleteElement as HTMLElement, {
            scale: 2, // Higher scale for better quality
          }).then(canvas => {
            const imgData = canvas.toDataURL('image/jpeg', 0.7);
 
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
 
            const imgProps = pdf.getImageProperties(imgData);
            const imgWidth = imgProps.width;
            const imgHeight = imgProps.height;
 
            const widthScale = (pdfWidth * 0.5) / imgWidth;
            const heightScale = pdfHeight / imgHeight;
            const scale = Math.min(widthScale, heightScale); // Scale based on the smaller of the two
 
            const newImgWidth = imgWidth * scale;
            const newImgHeight = imgHeight * scale;
 
            const marginX = (pdfWidth - newImgWidth) / 2;
            const marginY = (pdfHeight - newImgHeight) / 2;
 
            if (index > 0) {
              pdf.addPage();
            }
 
            pdf.addImage(
              imgData,
              'JPEG',
              marginX,
              marginY,
              newImgWidth,
              newImgHeight,
              undefined,
              'FAST'
            );
          });
 
          promises.push(promise); // Push each promise into the array
        }
      });
 
      // After all promises are resolved, save the PDF
      Promise.all(promises).then(() => {
        pdf.save('AdmitCard.pdf');
      }).catch((error) => {
        console.error('Error generating PDF:', error);
      });
    }
  }
 
 
}