import { Component, Input, OnInit } from '@angular/core';
import { NotificationService } from '../../services/Notifications.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment.prod';
import { Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AssessmentService } from '../../services/assessment.service';
import * as XLSX from 'xlsx';
import { NgToastModule } from 'ng-angular-popup';
import { NgSelectModule } from '@ng-select/ng-select';

@Component({
  selector: 'app-fileupload',
  standalone: true,
  imports: [FormsModule, CommonModule, NgToastModule, NgSelectModule],
  templateUrl: './fileupload.component.html',
  styleUrls: ['./fileupload.component.css']
})
export class FileuploadComponent implements OnInit {
  @Input() name: any;
  selectedFiles: any;
  selectedAssessment: any;
  assessments: any[] = []; // Declare an array to hold assessments
  assessmentPlayers: any;
  assessmentTests:any;
  assessment:any;

  constructor(
    private http: HttpClient,
    private notify: NotificationService,
    private router: Router,
    private assessmentService: AssessmentService
  ) {}

  ngOnInit() {
    if (this.name === 'CSS Health & Fitness') {
      this.fetchAssessments(); // Fetch assessments on initialization
    }
  }

  fetchAssessments() {
    this.assessmentService.getAllAssessment().subscribe(
      response => {
        this.assessments = response;
        this.assessments = this.assessments.filter((item:any)=> item.asmStatus === 0);
        console.log("this.assessments:",this.assessments)
      },
      error => {
        console.error('Error submitting assessment', error);
      }
    );
  }

  onAssessmentSelect() {
    this.assessmentService.getTestByAssessment(this.selectedAssessment).subscribe(response => {
      this.assessmentTests = response;
    });
    this.assessmentService.getPlayerByAssessmentId(this.selectedAssessment).subscribe(response => {
      this.assessmentPlayers = response;
    });
  }

  onFileSelected(event: any) {
    this.selectedFiles = event.target.files;
  }

  onUpload() {
    const formData = new FormData();
    
    if (this.selectedFiles && this.selectedFiles.length > 0) {
      // Perform filename validation only for 'CSS Health & Fitness'
      if (this.name === 'CSS Health & Fitness') {
        const matchingAssessment = this.assessments.find(
          (item: any) => item.asmId === Number(this.selectedAssessment)
        );        
        const assessmentNameInFile = matchingAssessment.asmName.replace(/[\s_-]/g, '');
        for (let i = 0; i < this.selectedFiles.length; i++) {
          const file = this.selectedFiles[i];
          if (!file.name.includes(assessmentNameInFile)) {
            this.notify.failed(`Please select a valid file`);
            return;
          }
        }
      }
  
      const fileKey = (this.name === 'CSS Health & Fitness' || this.name === 'levelone') ? 'file' : 'files';
      for (let i = 0; i < this.selectedFiles.length; i++) {
        formData.append(fileKey, this.selectedFiles[i], this.selectedFiles[i].name);
      }
  
      let uploadUrl = '';
      if (this.name === 'CSS Health & Fitness') {
        uploadUrl = `${environment.BaseUrl}${environment.uploadAssessment}${this.selectedAssessment}`;
      } else if (this.name !== 'levelone') {
        uploadUrl = `${environment.BaseUrl}${environment.UploadFiles}`;
      } else {
        uploadUrl = `${environment.BaseUrl}${environment.LevelOnefileUpload}`;
      }
  
      this.http.post(uploadUrl, formData, { 
        headers: this.assessmentService.getAuthHeaders(),
        responseType: 'text'
      }).subscribe(
        (response: string) => {
          this.notify.success(response);
          if (this.name === 'levelone') {
            setTimeout(() => this.router.navigate(['levelonelist']), 1000);
          }
        },
        (error: any) => {
          console.error('File upload failed!', error);
          this.notify.failed('File upload failed!' + error.error);
        }
      );
    } else {
      this.notify.failed('No files selected!');
    }
  }

  closeModal() {
    this.selectedAssessment = null;
    this.selectedFiles = null;
  }
  

  downloadTemplate(fileName: string) {
    if (this.name === 'CSS Health & Fitness') {
      const fileUrl = 'assets/Templates/assessmentForm_Template.xlsx';
      this.http.get(fileUrl, { responseType: 'blob' }).subscribe(async (blob: Blob) => {
        const arrayBuffer = await blob.arrayBuffer();
        const workbook = XLSX.read(arrayBuffer, { type: 'array' });
  
        // Define sheet names mapping based on testCategory
        const sheetMapping: { [key: number]: string } = {
          4: 'PhysicalFitness',
          5: 'Nutritional',
          7: 'Cardio',
          8: 'Cognitive',
          10: 'InjuryPreventionAnalysis',
          11: 'SportsPerFormanceAnalysis'
        };
  
        // Define column letters for A, B, C
        const athleteNameColumn = 'A';
        const emailColumn = 'B';
        const athleteIdColumn = 'C';
        const startRow = 2;
  
        for (const [category, sheetName] of Object.entries(sheetMapping)) {
          const worksheet = workbook.Sheets[sheetName];
          if (worksheet) {
            // Track existing players in the worksheet to avoid duplicates
            const existingPlayers = new Set<string>();
            let rowIndex = startRow;
            let playerIdCellAddress = `${athleteIdColumn}${rowIndex}`;
            while (worksheet[playerIdCellAddress]) {
              existingPlayers.add(worksheet[playerIdCellAddress].v.toString());
              rowIndex++;
              playerIdCellAddress = `${athleteIdColumn}${rowIndex}`;
            }
  
            // Update rows A, B, C with player details if not already present
            this.assessmentPlayers.forEach((player: any) => {
              if (!existingPlayers.has(player.aplPlayer.toString())) {
                const fullNameCellAddress = `${athleteNameColumn}${rowIndex}`;
                const emailCellAddress = `${emailColumn}${rowIndex}`;
                const playerIdCellAddress = `${athleteIdColumn}${rowIndex}`;
                XLSX.utils.sheet_add_aoa(worksheet, [[player.usrFullName, player.usrEmail, player.aplPlayer]], { origin: fullNameCellAddress });
                existingPlayers.add(player.aplPlayer.toString());
                rowIndex++;
              }
            });
  
            // Track added tests for the category to avoid duplicates
            const addedTests = new Set<string>();
            const testsForCategory = this.assessmentTests.filter((test: any) => test.testCategory === +category);
            let lastColumnIndex = XLSX.utils.decode_col(athleteIdColumn) + 1; // Start after column C
            const initialLastColumnIndex = lastColumnIndex;
  
            testsForCategory.forEach((test: any) => {
              if (!addedTests.has(test.testName)) { // Check if testName has already been added
                const newColumnLetter = XLSX.utils.encode_col(lastColumnIndex);
                const headerCellAddress = `${newColumnLetter}1`;
  
                // Set header for testColumnName
                worksheet[headerCellAddress] = { v: test.testName + (test.testUnit ? ` ${test.testUnit}` : '') };
  
                // Populate each row with placeholders (or default value if required)
                rowIndex = startRow;
                this.assessmentPlayers.forEach(() => {
                  const cellAddress = `${newColumnLetter}${rowIndex}`;
                  worksheet[cellAddress] = { v: '' };
                  rowIndex++;
                });
  
                addedTests.add(test.testName); // Mark testName as added
                lastColumnIndex++; // Move to the next column for the next testColumnName
              }
            });
  
            // Check if any new columns were added; hide or remove the worksheet if none
            if (lastColumnIndex === initialLastColumnIndex) {
              delete workbook.Sheets[sheetName];
              workbook.SheetNames = workbook.SheetNames.filter(name => name !== sheetName);
            }
          }
        }
  
        // Create a Blob from the modified workbook and trigger the download
        const modifiedBlob = new Blob([XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })], {
          type: 'application/octet-stream'
        });
        const matchingAssessment = this.assessments.find(
          (item: any) => item.asmId === Number(this.selectedAssessment)
        );
  
        if (matchingAssessment) {
          this.assessment = matchingAssessment.asmName.replace(/[\s_-]/g, '');
        } else {
          this.assessment = '';
        }
        const filename = `${this.assessment}_Template.xlsx`;
        saveAs(modifiedBlob, filename);
      }, error => {
        console.error('Error downloading or modifying file', error);
      });
    } else {
      const fileUrl = `assets/Templates/${fileName}.xlsx`;
      this.http.get(fileUrl, { responseType: 'blob' }).subscribe((blob: Blob) => {
        saveAs(blob, 'levelone.xlsx');
      }, error => {
        console.error('Error downloading file', error);
      });
    }
  }
  
  
  
   
}
