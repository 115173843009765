<div class="container">
    <h4 class="font-size38 sm-font-size32 xs-font-size30">Personal Information</h4>
   
   
    <div class="container-1">
 
      <div class="contact-info-section margin-40px-tb">
        <ul class="list-style9 no-margin">
          <li>
            <div class="row">
              <div class="col-md-5">
                <i class="fas fa-graduation-cap text-orange"></i>
                <p> Full Name :</p>
              </div>
              <div class="col-md-7">
                <p>Tarun Krishna</p>
              </div>
            </div>
          </li>
          <li>
            <div class="row">
              <div class="col-md-5">
                <i class="fas fa-graduation-cap text-orange"></i>
                <p> Date of Birth :</p>
              </div>
              <div class="col-md-7">
                <p>24-10-2001</p>
              </div>
            </div>
          </li>
          <li>
            <div class="row">
              <div class="col-md-5 ">
                <i class="fas fa-graduation-cap text-orange"></i>
                <p> Gender :</p>
              </div>
              <div class="col-md-7">
                <p>Male</p>
              </div>
            </div>
          </li>
          <li>
            <div class="row">
              <div class="col-md-5 ">
                <i class="fas fa-graduation-cap text-orange"></i>
                <p>Address :</p>
              </div>
              <div class="col-md-7">
                <p>xxx-yyy-zzz</p>
              </div>
            </div>
          </li>
          <li>
            <div class="row ">
              <div class="col-md-5 ">
                <i class="fas fa-graduation-cap text-orange"></i>
                <p>Email Address :</p>
              </div>
              <div class="col-md-7">
                <p>tarun&#64;gmail.com</p>
              </div>
            </div>
        </li>
        
        
        <span class="title">Emergency Contact</span>
        
        
       
            <div class="row">
              <div class="col-md-5">
                <i class="fas fa-graduation-cap text-orange"></i>
                <p>Name :</p>
              </div>
              <div class="col-md-7">
                <p>John</p>
              </div>
            </div>
          
 
          
            <div class="row">
              <div class="col-md-5">
                <i class="fas fa-graduation-cap text-orange"></i>
                <p>Relationship :</p>
              </div>
              <div class="col-md-7">
                <p>Father</p>   
              </div>
            </div>
          
 
         
          
            <div class="row">
              <div class="col-md-5">
                <i class="fas fa-graduation-cap text-orange"></i>
                <p>Phone Number :</p>
              </div>
              <div class="col-md-7">
                <p>1234567890</p>
              </div>
            </div>
         
         
          <!-- <li>
            <div class="row">
              <div class="col-md-5">
                <i class="fas fa-graduation-cap text-orange"></i>
                <p> Muscle Strength:</p>
              </div>
              <div class="col-md-7">
                <p>good</p>
              </div>
            </div>
          </li> -->
         
          <!-- <li>
            <div class="row">
              <div class="col-md-5">
                <i class="fas fa-graduation-cap text-orange"></i>
                <p> Treatment Plan:</p>
              </div>
              <div class="col-md-7">
                <p>bad</p>
              </div>
            </div>
          </li> -->
          <!-- <li>
            <div class="row">
              <div class="col-md-5">
                <i class="fas fa-graduation-cap text-orange"></i>
                <p> Follow-up Date:</p>
              </div>
              <div class="col-md-7">
                <p>24-07-2024</p>
              </div>
            </div>
          </li> -->
        </ul>
        <div class=" text-center">
            <button class="btn btn-primary">Download</button>
          </div>
      </div>
    </div>
  </div>