<div class="body-wrapper">
  <div class="team-single">
    <div class="d-flex justify-content-between mb-3">
      <div>
        <button class="btn btn-primary" (click)="onBack()">Back</button>
      </div>
      <div>
        <button type="button" class="btn btn-primary" (click)="downloadPDF()">Download</button>
      </div>
    </div>
    <div class="contact-info-section margin-40px-tb">
      <div id="pdf-content">
        <div class="row">
          <div class="col-lg-4 col-md-5 xs-margin-30px-bottom">
            <div class="col-lg-12">
              <div class="card mb-4">
                <div class="content-header">
                  <h2 class="sub-title">Physical Fitness Analysis</h2>
                </div>
                <div class="content-body">
                  <!-- Sports Activity -->
                  <div class="mb-4" *ngFor="let activity of activities">
                    <div class="clearfix">
                      <div class="d-flex justify-content-between align-items-center mb-3">
                        <p class="small-text">{{ activity.name }}</p>
                        <strong>{{ activity.percentage }}%</strong>
                      </div>
                    </div>
                    <div class="progress progress-xs">
                      <div class="progress-bar" [ngClass]="getProgressColor(activity.percentage)" role="progressbar"
                        [style.width]="activity.percentage + '%'" [attr.aria-valuenow]="activity.percentage"
                        aria-valuemin="0" aria-valuemax="100">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="bg-light-blue">
                <h4 class="side-Title">{{athleteFullName}}</h4>
                <p class="side-content">Athlete</p>
              </div>
            </div>
          </div>
          <div class="col-lg-8 card col-md-7">
            <div class="card-body">
              <div class="team-single-text padding-50px-left sm-no-padding-left">
                <div class="mobile-res d-flex justify-content-between mb-4">
                  <div>
                    <h4 class="Title">Athlete Details</h4>
                  </div>
                  <!-- <div>
                      <p>Test Taken Date:</p>
                      <h4 class="sub-title">{{date | date: 'dd-MM-yyyy'}}</h4>
                    </div> -->
                  <div>
                    <p>Assessment Date:</p>
                    <h4 class="sub-title">{{assessmentDate | date: 'dd-MM-yyyy'}}</h4>

                  </div>
                </div>
                <div class="contact-info-section margin-40px-tb">
                  <ul class="list-style9 no-margin">
                    <li *ngIf="athleteId">
                      <div class="row">
                        <div class="col-md-5 col-5">
                          <strong class="margin-10px-left">Athlete ID:</strong>
                        </div>
                        <div class="col-md-7 col-7">
                          <p>{{athleteId}}</p>
                        </div>
                      </div>
                    </li>
                    <li *ngIf="pfaHeight">
                      <div class="row">
                        <div class="col-md-5 col-5">
                          <strong class="margin-10px-left">Height :</strong>
                        </div>
                        <div class="col-md-7 col-7">
                          <p>{{pfaHeight}}</p>
                        </div>
                      </div>
                    </li>
                    <li *ngIf="pfaWeight">
                      <div class="row">
                        <div class="col-md-5 col-5">
                          <strong class="margin-10px-left">Weight :</strong>
                        </div>
                        <div class="col-md-7 col-7">
                          <p>{{pfaWeight}}</p>
                        </div>
                      </div>
                    </li>
                    <li *ngIf="pfaBMI">
                      <div class="row">
                        <div class="col-md-5 col-5">
                          <strong class="margin-10px-left">BMI :</strong>
                        </div>
                        <div class="col-md-7 col-7">
                          <p>{{pfaBMI}}</p>
                        </div>
                      </div>
                    </li>
                    <li *ngIf="pfaSpeed">
                      <div class="row">
                        <div class="col-md-5 col-5">
                          <strong class="margin-10px-left">Speed :</strong>
                        </div>
                        <div class="col-md-7 col-7">
                          <p>{{pfaSpeed}}</p>
                        </div>
                      </div>
                    </li>
                    <li *ngIf="pfaYoYoTest">
                      <div class="row">
                        <div class="col-md-5 col-5">
                          <strong class="margin-10px-left">YoYo Test :</strong>
                        </div>
                        <div class="col-md-7 col-7">
                          <p>{{pfaYoYoTest}}</p>
                        </div>
                      </div>
                    </li>
                    <li *ngIf="pfaSingleLegBalanceLeftEyesOpen">
                      <div class="row">
                        <div class="col-md-5 col-5">
                          <strong class="margin-10px-left">Single Leg Balance Left Eyes Open:</strong>
                        </div>
                        <div class="col-md-7 col-7">
                          <p>{{pfaSingleLegBalanceLeftEyesOpen}}</p>
                        </div>
                      </div>
                    </li>
                    <li *ngIf="pfaSingleLegBalanceLeftEyesClose">
                      <div class="row">
                        <div class="col-md-5 col-5">
                          <strong class="margin-10px-left">Single Leg Balance Left Eyes Close:</strong>
                        </div>
                        <div class="col-md-7 col-7">
                          <p>{{pfaSingleLegBalanceLeftEyesClose}}</p>
                        </div>
                      </div>
                    </li>
                    <li *ngIf="pfaSingleLegBalanceRightEyesOpen">
                      <div class="row">
                        <div class="col-md-5 col-5">
                          <strong class="margin-10px-left">Single Leg Balance Right Eyes Open:</strong>
                        </div>
                        <div class="col-md-7 col-7">
                          <p>{{pfaSingleLegBalanceRightEyesOpen}}</p>
                        </div>
                      </div>
                    </li>
                    <li *ngIf="pfaSingleLegBalanceRightEyesClose">
                      <div class="row">
                        <div class="col-md-5 col-5">
                          <strong class="margin-10px-left">Single Leg Balance Right Eyes Close:</strong>
                        </div>
                        <div class="col-md-7 col-7">
                          <p>{{pfaSingleLegBalanceRightEyesClose}}</p>
                        </div>
                      </div>
                    </li>
                    <li *ngIf="pfaYBalanceTest1">
                      <div class="row">
                        <div class="col-md-5 col-5">
                          <strong class="margin-10px-left">Y Balance Test1:</strong>
                        </div>
                        <div class="col-md-7 col-7">
                          <p>{{pfaYBalanceTest1}}</p>
                        </div>
                      </div>
                    </li>
                    <li *ngIf="pfaYBalanceTest2">
                      <div class="row">
                        <div class="col-md-5 col-5">
                          <strong class="margin-10px-left">Y Balance Test2:</strong>
                        </div>
                        <div class="col-md-7 col-7">
                          <p>{{pfaYBalanceTest2}}</p>
                        </div>
                      </div>
                    </li>
                    <li *ngIf="pfaYBalanceTest3">
                      <div class="row">
                        <div class="col-md-5 col-5">
                          <strong class="margin-10px-left">Y Balance Test3:</strong>
                        </div>
                        <div class="col-md-7 col-7">
                          <p>{{pfaYBalanceTest3}}</p>
                        </div>
                      </div>
                    </li>
                    <li *ngIf="pfaStarExcursionTest1">
                      <div class="row">
                        <div class="col-md-5 col-5">
                          <strong class="margin-10px-left">Star Excursion Test1:</strong>
                        </div>
                        <div class="col-md-7 col-7">
                          <p>{{pfaStarExcursionTest1}}</p>
                        </div>
                      </div>
                    </li>
                    <li *ngIf="pfaStarExcursionTest2">
                      <div class="row">
                        <div class="col-md-5 col-5">
                          <strong class="margin-10px-left">Star Excursion Test2:</strong>
                        </div>
                        <div class="col-md-7 col-7">
                          <p>{{pfaStarExcursionTest2}}</p>
                        </div>
                      </div>
                    </li>
                    <li *ngIf="pfaStarExcursionTest3">
                      <div class="row">
                        <div class="col-md-5 col-5">
                          <strong class="margin-10px-left">Star Excursion Test3:</strong>
                        </div>
                        <div class="col-md-7 col-7">
                          <p>{{pfaStarExcursionTest3}}</p>
                        </div>
                      </div>
                    </li>
                    <li *ngIf="pfaPushups">
                      <div class="row">
                        <div class="col-md-5 col-5">
                          <strong class="margin-10px-left">Push ups:</strong>
                        </div>
                        <div class="col-md-7 col-7">
                          <p>{{pfaPushups}}</p>
                        </div>
                      </div>
                    </li>
                    <li *ngIf="pfaPullups">
                      <div class="row">
                        <div class="col-md-5 col-5">
                          <strong class="margin-10px-left">Pull ups:</strong>
                        </div>
                        <div class="col-md-7 col-7">
                          <p>{{pfaPullups}}</p>
                        </div>
                      </div>
                    </li>
                    <li *ngIf="pfaPullAndHang">
                      <div class="row">
                        <div class="col-md-5 col-5">
                          <strong class="margin-10px-left">Pull and Hang:</strong>
                        </div>
                        <div class="col-md-7 col-7">
                          <p>{{pfaPullAndHang}}</p>
                        </div>
                      </div>
                    </li>
                    <li *ngIf="pfaSquat">
                      <div class="row">
                        <div class="col-md-5 col-5">
                          <strong class="margin-10px-left">Squat:</strong>
                        </div>
                        <div class="col-md-7 col-7">
                          <p>{{pfaSquat}}</p>
                        </div>
                      </div>
                    </li>
                    <li *ngIf="pfaSingleLegSquat">
                      <div class="row">
                        <div class="col-md-5 col-5">
                          <strong class="margin-10px-left">Single Leg Squat:</strong>
                        </div>
                        <div class="col-md-7 col-7">
                          <p>{{pfaSingleLegSquat}}</p>
                        </div>
                      </div>
                    </li>
                    <li *ngIf="pfaPartialCurlUp">
                      <div class="row">
                        <div class="col-md-5 col-5">
                          <strong class="margin-10px-left">Partial Curl up:</strong>
                        </div>
                        <div class="col-md-7 col-7">
                          <p>{{pfaPartialCurlUp}}</p>
                        </div>
                      </div>
                    </li>
                    <li *ngIf="pfaGripStrengthRight">
                      <div class="row">
                        <div class="col-md-5 col-5">
                          <strong class="margin-10px-left">Grip Strength Right:</strong>
                        </div>
                        <div class="col-md-7 col-7">
                          <p>{{pfaGripStrengthRight}}</p>
                        </div>
                      </div>
                    </li>
                    <li *ngIf="pfaGripStrengthLeft">
                      <div class="row">
                        <div class="col-md-5 col-5">
                          <strong class="margin-10px-left">Grip Strength Left:</strong>
                        </div>
                        <div class="col-md-7 col-7">
                          <p>{{pfaGripStrengthLeft}}</p>
                        </div>
                      </div>
                    </li>
                    <li *ngIf="pfaSitNReach">
                      <div class="row">
                        <div class="col-md-5 col-5">
                          <strong class="margin-10px-left">Sit N reach:</strong>
                        </div>
                        <div class="col-md-7 col-7">
                          <p>{{pfaSitNReach}}</p>
                        </div>
                      </div>
                    </li>
                    <li *ngIf="pfaAppleysScratchTest">
                      <div class="row">
                        <div class="col-md-5 col-5">
                          <strong class="margin-10px-left">Apple Scratch Test:</strong>
                        </div>
                        <div class="col-md-7 col-7">
                          <p>{{pfaAppleysScratchTest}}</p>
                        </div>
                      </div>
                    </li>
                    <li *ngIf="pfa50mSprint">
                      <div class="row">
                        <div class="col-md-5 col-5">
                          <strong class="margin-10px-left">50m Sprint:</strong>
                        </div>
                        <div class="col-md-7 col-7">
                          <p>{{pfa50mSprint}}</p>
                        </div>
                      </div>
                    </li>
                    <li *ngIf="pfa30mSprint">
                      <div class="row">
                        <div class="col-md-5 col-5">
                          <strong class="margin-10px-left">30m Sprint:</strong>
                        </div>
                        <div class="col-md-7 col-7">
                          <p>{{pfa30mSprint}}</p>
                        </div>
                      </div>
                    </li>
                    <li *ngIf="pfa10mShuttleRun">
                      <div class="row">
                        <div class="col-md-5 col-5">
                          <strong class="margin-10px-left">10m ShuttleRun:</strong>
                        </div>
                        <div class="col-md-7 col-7">
                          <p>{{pfa10mShuttleRun}}</p>
                        </div>
                      </div>
                    </li>
                    <li *ngIf="pfaTTest">
                      <div class="row">
                        <div class="col-md-5 col-5">
                          <strong class="margin-10px-left">TTest:</strong>
                        </div>
                        <div class="col-md-7 col-7">
                          <p>{{pfaTTest}}</p>
                        </div>
                      </div>
                    </li>
                    <li *ngIf="pfaStandingBroadJump">
                      <div class="row">
                        <div class="col-md-5 col-5">
                          <strong class="margin-10px-left">Standing BroadJump:</strong>
                        </div>
                        <div class="col-md-7 col-7">
                          <p>{{pfaStandingBroadJump}}</p>
                        </div>
                      </div>
                    </li>
                    <li *ngIf="pfaVerticalJump">
                      <div class="row">
                        <div class="col-md-5 col-5">
                          <strong class="margin-10px-left">Vertical Jump:</strong>
                        </div>
                        <div class="col-md-7 col-7">
                          <p>{{pfaVerticalJump}}</p>
                        </div>
                      </div>
                    </li>
                    <li *ngIf="pfaSeatedMedBallThrow">
                      <div class="row">
                        <div class="col-md-5 col-5">
                          <strong class="margin-10px-left">Seated MedBall Throw:</strong>
                        </div>
                        <div class="col-md-7 col-7">
                          <p>{{pfaSeatedMedBallThrow}}</p>
                        </div>
                      </div>
                    </li>
                    <li *ngIf="pfaUpperBodyForce">
                      <div class="row">
                        <div class="col-md-5 col-5">
                          <strong class="margin-10px-left">Upper Body Force:</strong>
                        </div>
                        <div class="col-md-7 col-7">
                          <p>{{pfaUpperBodyForce}}</p>
                        </div>
                      </div>
                    </li>
                    <li *ngIf="pfaUpperBodyPower">
                      <div class="row">
                        <div class="col-md-5 col-5">
                          <strong class="margin-10px-left">Upper Body Power:</strong>
                        </div>
                        <div class="col-md-7 col-7">
                          <p>{{pfaUpperBodyPower}}</p>
                        </div>
                      </div>
                    </li>
                    <li *ngIf="pfaLowerBodyPower">
                      <div class="row">
                        <div class="col-md-5 col-5">
                          <strong class="margin-10px-left">Lower Body Power:</strong>
                        </div>
                        <div class="col-md-7 col-7">
                          <p>{{pfaLowerBodyPower}}</p>
                        </div>
                      </div>
                    </li>
                    <li *ngIf="pfaLowerBodyForce">
                      <div class="row">
                        <div class="col-md-5 col-5">
                          <strong class="margin-10px-left">Lower Body Force:</strong>
                        </div>
                        <div class="col-md-7 col-7">
                          <p>{{pfaLowerBodyForce}}</p>
                        </div>
                      </div>
                    </li>
                    <li *ngIf="pfaLevel">
                      <div class="row">
                        <div class="col-md-5 col-5">
                          <strong class="margin-10px-left">Level:</strong>
                        </div>
                        <div class="col-md-7 col-7">
                          <p>{{pfaLevel}}</p>
                        </div>
                      </div>
                    </li>
                    <li *ngIf="pfaVO2">
                      <div class="row">
                        <div class="col-md-5 col-5">
                          <strong class="margin-10px-left">VO2:</strong>
                        </div>
                        <div class="col-md-7 col-7">
                          <p>{{pfaVO2}}</p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> <!-- end of pdf-content -->
    </div> <!-- end of contact-info-section -->
  </div> <!-- end of team-single -->
</div> <!-- end of body-wrapper -->