import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Input, NgZone, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { AgGridAngular } from 'ag-grid-angular';
import { FileuploadComponent } from '../../fileupload/fileupload.component';
import { User } from '../../../model/User';
import { ColDef } from 'ag-grid-community';
import { PrivilegeEnum } from '../../../model/PrivilageEnum';
import { Privilege } from '../../../model/Privillage';
import { AssessmentService } from '../../../services/assessment.service';
import { PrivilegeService } from '../../../services/privilage.service';
import { UserService } from '../../../services/user.service';
import { SharedService } from '../../../services/shared.service';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { catchError, map, Observable, of } from 'rxjs';
import { ImageuploadComponent } from '../../imageupload/imageupload.component';
import { ChatService } from '../../../services/chat.service';
 
 
interface IRow {
  playername: string;
  loaId:string;
  Height:string;
  Weight: string;
  RulerDrop: string;
  HandGrip: string;
  SeatedMedBall: string;
  SitnReach: string;
  VerticalJump: string;
}
 
@Component({
  selector: 'app-levelone-list',
  standalone: true,
  imports: [CommonModule,RouterOutlet, RouterLink, RouterModule, AgGridAngular,FileuploadComponent,ImageuploadComponent],
  templateUrl: './levelone-list.component.html',
  styleUrl: './levelone-list.component.css'
})
export class LeveloneListComponent {
  @ViewChild('fileUploadModal') fileUploadModal: FileuploadComponent | undefined;
  @ViewChild('fileImageUploadModal') fileImageUploadModal: ImageuploadComponent | undefined;
  //@ViewChild('imgModal') imgModal: ImagepopupComponent | undefined;
  @Input() name: any;
  selectedImageUrl: any;
  selectedId: any ;
  selectedDob: any;

  excelFile: any;
 
  user: User | null = null;
 
  themeClass = 'ag-theme-quartz';
 
  rowData: IRow[] = [];
 
  colDefs: ColDef[] = [];
 
  ramPrivilege: PrivilegeEnum | undefined;
 
  defaultColDef: ColDef = {
    flex: 1,
    sortable: true,
    filter: true,
  };
  privilege: Privilege;
  Isplayer: boolean = false;
  Admin: boolean;
  detail: any;
  org: any;
  bulkdownload : boolean = false;
  players: any[] = [];
  allrowData: any[] = [];
  message = '';
  chatHistory: string[] = []
  userid: any;
  OrgAdmin: boolean = false;
  Coach: boolean= false;
  constructor(private cdr: ChangeDetectorRef,private route: ActivatedRoute,private assessmentService: AssessmentService, private router: Router, private sharedService: SharedService,
      private ngZone: NgZone, private userService: UserService, private priService: PrivilegeService, private chatService: ChatService, private sanitizer: DomSanitizer) {
        this.route.queryParams.subscribe(params => {
          this.userid = params['id'];
        });
    this.privilege = this.priService.getPrivileges();
    this.Isplayer = this.privilege.isPlayer;
    this.Coach = this.privilege?.isCoach ?? false;
    this.Admin = this.privilege.isAdmin;
    this.OrgAdmin = this.privilege.isOrgAdmin;
    }
 
  ngOnInit() {
    this.sharedService.detail$.subscribe((data: any) => {
      this.detail = data;
      this.org=this.detail.user.usrOrganization;
      console.log(this.detail,"org")
    });
    this.user = this.userService.getUser();
    this.ramPrivilege = this.userService.getRamPrivilege();
    console.log(this.ramPrivilege,"Datapri");
    if(this.userid){
      if(this.Coach){
        this.assessmentService.getLeveloneByCoachId(this.userid).subscribe(response => {
          console.log('list', response);
          this.players = response;
    
          this.rowData = response.map((item:any) => {
            let formattedDate = '';
           
            if (item.loaAsessmentDate) {
              const assessmentDate = new Date(item.loaAsessmentDate);
              // Check if the date is valid
              if (!isNaN(assessmentDate.getTime())) {
                formattedDate = assessmentDate.toLocaleDateString('en-GB'); // 'en-GB' gives day-month-year format
              }
            }
           
            return {
              playername: `${item.usrFullName}`,
              loaId: `${item.loaAthlete}`,
              Id: `${item.loaAthlete}`,
              Height: `${item.loaHeight}`,
              Weight: ` ${item.loaWeight}`,
              RulerDrop: `${item.loaRulerDrop}`,
              HandGrip: `${item.loaHandGrip}`,
              SeatedMedBall: `${item.loaSeatedMedBall}`,
              SitnReach: `${item.loaSitnReach}`,
              VerticalJump: `${item.loaVerticalJump}`,
              AssessmentDate: formattedDate, // Assign the formatted date here
              OverallScore: `${item.loaAverageRange}`,
              Remark: this.getRemark(item)
            };
          });
     
          this.colDefs = this.getColumnDefinitions();
    
          this.players.forEach(player => {
            player.rowData = this.transformPlayerData(player);
          });
          
        }, error => {
          console.error('Error submitting assessment', error);
        });
      }else{
      this.assessmentService.getLevelOneByAthleteId(this.userid).subscribe(response => {
        console.log('list', response);
        this.players = response;
  
        this.rowData = response.map((item:any) => {
          let formattedDate = '';
         
          if (item.loaAsessmentDate) {
            const assessmentDate = new Date(item.loaAsessmentDate);
            // Check if the date is valid
            if (!isNaN(assessmentDate.getTime())) {
              formattedDate = assessmentDate.toLocaleDateString('en-GB'); // 'en-GB' gives day-month-year format
            }
          }
         
          return {
            playername: `${item.usrFullName}`,
            loaId: `${item.loaAthlete}`,
              Id: `${item.loaAthlete}`,
            Height: `${item.loaHeight}`,
            Weight: ` ${item.loaWeight}`,
            RulerDrop: `${item.loaRulerDrop}`,
            HandGrip: `${item.loaHandGrip}`,
            SeatedMedBall: `${item.loaSeatedMedBall}`,
            SitnReach: `${item.loaSitnReach}`,
            VerticalJump: `${item.loaVerticalJump}`,
            AssessmentDate: formattedDate, // Assign the formatted date here
            OverallScore: `${item.loaAverageRange}`,
            Remark: this.getRemark(item)
          };
        });
   
        this.colDefs = this.getColumnDefinitions();
  
        this.players.forEach(player => {
          player.rowData = this.transformPlayerData(player);
        });
        
      }, error => {
        console.error('Error submitting assessment', error);
      });
    }
    }else{
    this.assessmentService.getLevelOneList(this.org).subscribe(response => {
      console.log('list', response);
      this.players = response;

      this.rowData = response.map((item:any) => {
        let formattedDate = '';
       
        if (item.loaAsessmentDate) {
          const assessmentDate = new Date(item.loaAsessmentDate);
          // Check if the date is valid
          if (!isNaN(assessmentDate.getTime())) {
            formattedDate = assessmentDate.toLocaleDateString('en-GB'); // 'en-GB' gives day-month-year format
          }
        }
       
        return {
          playername: `${item.usrFullName}`,
          loaId: `${item.loaAthlete}`,
          Id: `${item.loaAthlete}`,
          Height: `${item.loaHeight}`,
          Weight: ` ${item.loaWeight}`,
          RulerDrop: `${item.loaRulerDrop}`,
          HandGrip: `${item.loaHandGrip}`,
          SeatedMedBall: `${item.loaSeatedMedBall}`,
          SitnReach: `${item.loaSitnReach}`,
          VerticalJump: `${item.loaVerticalJump}`,
          AssessmentDate: formattedDate, // Assign the formatted date here
          OverallScore: `${item.loaAverageRange}`,
          Remark: this.getRemark(item)
        };
      });
 
      this.colDefs = this.getColumnDefinitions();

      this.players.forEach(player => {
        player.rowData = this.transformPlayerData(player);
      });
      
    }, error => {
      console.error('Error submitting assessment', error);
    });
  }
}
  getRemark(item: any): string {
    if (item.loaAverageRange > 80) {
      return 'Good';
    } else if(item.loaAverageRange > 50) {
      return 'Average';
    }else{
      return 'Below Average';
    }
  }
 
  getColumnDefinitions(): ColDef[] {
    const columns: ColDef[] = [
      // {
      //   headerName: 'Image',
      //   field: 'Image',
      //   width: 75,
      //   sortable: false,
      //   cellRenderer: (_params: any) => {
      //     const img = document.createElement('img');
      //     img.width = 30;
      //     img.height = 30;
      
      //     this.getProfileImage(_params.data.loaId).subscribe((imageUrl) => {
      //       img.src = imageUrl.changingThisBreaksApplicationSecurity as string;
      //       img.style.cursor = 'pointer';  // Change cursor to pointer on hover
      
      //       img.addEventListener('click', () => {
      //         this.showImageModal(imageUrl, _params.data.loaId, _params.data.loaDob);
      //       });
      //     });
      
      //     return img;
      //   }
      // },
      {
        headerName: 'Image',
        field: 'Image',
        width: 75,
        sortable: false,
        cellRenderer: (_params: any) => {
          const img = document.createElement('img');
          img.width = 30;
          img.height = 30;
      
          this.getProfileImage(_params.data.loaId).subscribe((imageUrl) => {
            img.src = imageUrl.changingThisBreaksApplicationSecurity as string;
            img.style.cursor = 'pointer'; // Change cursor to pointer on hover
      
            // Add mouseover event to show the popup
            // img.addEventListener('mouseover', (event) => {
            //   this.showHoverImagePopup(event, imageUrl.changingThisBreaksApplicationSecurity as string);
            // });
      
            // Add mouseout event to hide the popup
            // img.addEventListener('mouseout', () => {
            //   this.hideHoverImagePopup();
            // });
      
            // Optional: Add click event to show the image modal
            img.addEventListener('click', () => {
              this.showImageModal(imageUrl, _params.data.loaId, _params.data.loaDob);
            });
          });
      
          return img;
        }
      },
      { field: 'playername' },
      {field: 'Id'},
      { field: 'RulerDrop' },
      { field: 'HandGrip' },
      { field: 'SeatedMedBall' },
      {field: 'SitnReach'},
      // {field: 'VerticalJump'},
      {field: 'AssessmentDate'},
      {field: 'OverallScore'},
      {
        field: 'Remark',
        cellStyle: params => {
          if (params.value === 'Good') {
            return { color: 'green' };
          } else if(params.value === 'Average'){
            return { color: 'Orange' };
          }else{
            return { color: 'red' };
          }
        }
      },
      {
        headerName: 'Action',
        cellRenderer: (_params: any) => {
          const container = document.createElement('div');
          container.style.display = 'flex';
          container.style.justifyContent = 'space-around';
 
          // View button with eye icon
          const viewButton = document.createElement('button');
          viewButton.classList.add('btn');
          viewButton.innerHTML = '<i class="fa fa-eye"></i>';
          viewButton.addEventListener('click', () => {
              this.ngZone.run(() => {
              this.router.navigate(['/LeveloneReport'], {queryParams: { id: _params.data.loaId } })
              })
          });
 
          // Edit button with edit icon
         
          const editButton = document.createElement('button');
          editButton.classList.add('btn');
          editButton.innerHTML = '<i class="fa fa-edit"></i>';
          editButton.addEventListener('click', () => {
            this.ngZone.run(() => {
            this.router.navigate(['/LevelOneForm'], {queryParams: { id: _params.data.loaId } })
            })
          });
 
          container.appendChild(viewButton);
          if (!this.Isplayer && !this.OrgAdmin && !this.Coach) {
          container.appendChild(editButton);
          }
          //container.appendChild(deleteButton);
 
          return container;
        },
        width: 120
      }];
 
    if (this.Admin || this.OrgAdmin) {
      return columns;
    } else{
      return columns.filter(col => col.field !== 'playername');
    }
  }
 
  openModal(): void {
    // Use Bootstrap's modal function to open the modal
    const modalElement = document.getElementById('fileUploadModal')!;
    const modal = new (window as any).bootstrap.Modal(modalElement);
    modal.show();
  }

  openImageModal(): void {
    // Use Bootstrap's modal function to open the modal
    const modalElement1 = document.getElementById('fileImageUploadModal')!;
    const modal1 = new (window as any).bootstrap.Modal(modalElement1);
    modal1.show();
  }


  showImageModal(imageUrl: any, loaId: string, loaDob: string): void {
    this.selectedImageUrl = imageUrl.changingThisBreaksApplicationSecurity as string; // Store the image URL
    this.selectedId = loaId;          // Store the ID
    this.selectedDob = loaDob;        // Store the DOB
    
    // Trigger change detection manually
    this.cdr.detectChanges();
    
    const modalElement = document.getElementById('imageModal');
    const modal = new (window as any).bootstrap.Modal(modalElement);
    modal.show();
  }
  
 
  getProgressBarClass(RangePercentage: number): string {
    if (RangePercentage >= 90) {
        return 'bg-green'; // Red segment (Bootstrap class)
      } else if (RangePercentage >= 50 && RangePercentage < 90) {
        return 'bg-yellow'; // Yellow segment (Bootstrap class)
      } else {
        return 'bg-red'; // Green segment (Bootstrap class)
    }
  }
 
 
  getProgressText(percentage: number): string {
    if (percentage >= 90) {
      return 'Good';
    } else if (percentage >= 70 && percentage < 90) {
      return 'Average';
    } else {
      return 'Below Average';
    }
  }
 
  getTextColorClass(percentage: number): string {
    if (percentage >= 90) {
      return 'text-success';
    } else if (percentage >= 70 && percentage < 90) {
      return 'text-warning';
    } else {
      return 'text-danger';
    }
  }
 
  transformPlayerData(player: any): any[] {
    return [
      { SportName: 'Height', RangePercentage: player.loaHeightRange },
      { SportName: 'Weight', RangePercentage: player.loaWeightRange },
      { SportName: 'Ruler Drop', RangePercentage: player.loaRulerDropRange },
      { SportName: 'Hand Grip', RangePercentage: player.loaHandGripRange },
      { SportName: 'Seated Med Ball', RangePercentage: player.loaSeatedMedBallRange },
      { SportName: 'Sit and Reach', RangePercentage: player.loaSitnReachRange },
      { SportName: 'Vertical Jump', RangePercentage: player.loaVerticalJumpRange },
      { SportName: 'Wall Toss', RangePercentage: player.loaWallTossRange },
      { SportName: '30m Sprint', RangePercentage: player.loa30mSprintRange },
      { SportName: '6x10 Shuttle Run', RangePercentage: player.loa6x10ShuttleRunRange },
      { SportName: '800m Run', RangePercentage: player.loa800mRunRange },
      // Add other metrics as needed
    ];
  }
 
  downloadPDF(): void {
    const pdf = new jsPDF('p', 'mm', 'a5'); // Use A5 page size
    const promises: Promise<void>[] = []; // Store promises for each canvas generation

    this.players.forEach((player, index) => {
        const element = document.getElementById(`pdf-content-${index}`);
        if (element) {
            const promise = html2canvas(element, {
                scale: 1.0, // Reduce scale to 1.0 for faster rendering
                useCORS: true, // Enable CORS for cross-origin images
                logging: false, // Disable logging for better performance
                backgroundColor: null, // Skip background to reduce processing time
            }).then((canvas) => {
                const imgData = canvas.toDataURL('image/jpeg',0.7); 
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const imgWidth = pdfWidth * 0.8; // 80% of the page width
                const imgHeight = (canvas.height * imgWidth) / canvas.width;
                const margin = (pdfWidth - imgWidth) / 2;
                const topMargin = (pdf.internal.pageSize.getHeight() - imgHeight) / 2; // Center vertically

                pdf.addImage(imgData, 'JPEG', margin, topMargin, imgWidth, imgHeight, undefined, 'FAST');

                if (index < this.players.length - 1) {
                    pdf.addPage(); // Add a new page for the next player
                }
            });
            promises.push(promise); // Add the promise to the array
        }
    });

    // Process all promises in parallel
    Promise.all(promises).then(() => {
        pdf.save('Levelone-Report.pdf');
    });
}

getProfileImage(userId: any): Observable<any> {
  return this.assessmentService.getImage(userId).pipe(
    map((imageBlob: Blob | MediaSource) => {
      const objectURL = URL.createObjectURL(imageBlob);
      return this.sanitizer.bypassSecurityTrustUrl(objectURL);
    })
  );
}


showHoverImagePopup(event: MouseEvent, imageUrl: string): void {
  const hoverImageModal = document.getElementById('hover-image-modal');
  const hoverImage = document.getElementById('hover-image') as HTMLImageElement;

  if (hoverImageModal && hoverImage) {
    // Set the image URL
    hoverImage.src = imageUrl;

    // Position the popup near the cursor
    hoverImageModal.style.display = 'block';
    hoverImageModal.style.left = event.pageX + 10 + 'px'; // Offset from cursor
    hoverImageModal.style.top = event.pageY + 10 + 'px';  // Offset from cursor
  }
}

hideHoverImagePopup(): void {
  const hoverImageModal = document.getElementById('hover-image-modal');
  if (hoverImageModal) {
    hoverImageModal.style.display = 'none';
  }
}

downloadImage(imageUrl: any): void {
  const link = document.createElement('a');
  link.href = imageUrl.changingThisBreaksApplicationSecurity as string;
  link.download = 'profile-image.jpg'; // Set the desired download filename
  link.click();
}

}