import { Routes } from '@angular/router';
import { NutritionFormComponent } from './assessment/nutrition/nutrition-form/nutrition-form.component';
import { SidenavbarComponent } from './sidenavbar/sidenavbar.component';
import { PhysiotherapyDetailComponent } from './assessment/physiotherapy/physiotherapy-detail/physiotherapy-detail.component';
import { BiomechanicsPhysiologyFormComponent } from './assessment/biomechanical/biomechanics-form/biomechanics-physiology-form.component';
import { LoginComponent } from './users/login/login.component';
import { BiomechanicsPhysiologyListComponent } from './assessment/biomechanical/biomechanics-list/biomechanics-physiology-list.component';
import { NutritionListComponent } from './assessment/nutrition/nutrition-list/nutrition-list.component';
import { PhysicalfitnessComponent } from './assessment/physicalfitness/physicalfitness-form/physicalfitness.component';
import { PhysiotherapyListComponent } from './assessment/physiotherapy/physiotherapy-list/physiotherapy-list.component';
import { NutritionDetailComponent } from './assessment/nutrition/nutrition-detail/nutrition-detail.component';
import { BiomechanicsPhysiologyDetailComponent } from './assessment/biomechanical/biomechanics-detail/biomechanics-physiology-detail.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PhysiologyDetailComponent } from './assessment/physiology/physiology-detail/physiology-detail.component';
import { PhysiologyListComponent } from './assessment/physiology/physiology-list/physiology-list.component';
import { PhysicalfitnessListComponent } from './assessment/physicalfitness/physicalfitness-list/physicalfitness-list.component';
import { PhysicalfitnessDetailComponent } from './assessment/physicalfitness/physicalfitness-detail/physicalfitness-detail.component';
import { PhysiotherapyFormComponent } from './assessment/physiotherapy/physiotherapy-form/physiotherapy-form.component';
import { AssessmentFormComponent } from './assessment/assessment-form/assessment-form.component';
import { PlayerRegistrationComponent } from './users/player-registration/player-registration.component';
import { RegistrationComponent } from './users/registration/registration.component';
import { PlayerDashboardComponent } from './player-dashboard/player-dashboard.component';
import { PersonalInformationDetailComponent } from './admin/personalInformation/personal-information-detail/personal-information-detail.component';
import { UserDetailsComponent } from './admin/userDetails/user-details/user-details.component';
import { IndividualDashboardComponent } from './individual-dashboard/individual-dashboard.component';
import { NutritionDetailListComponent } from './assessment/nutrition/nutrition-detail-list/nutrition-detail-list.component';

import { AssessmentComponent } from './admin/assessment/assessment.component';
import { PhysiologyDetailListComponent } from './assessment/physiology/physiology-detail-list/physiology-detail-list.component';
import { PhysicalfitnessDetailListComponent } from './assessment/physicalfitness/physicalfitness-detail-list/physicalfitness-detail-list.component';
import { PhysiotherapyDetailListComponent } from './assessment/physiotherapy/physiotherapy-detail-list/physiotherapy-detail-list.component';
import { BiomechanicsDetailListComponent } from './assessment/biomechanical/biomechanics-detail-list/biomechanics-detail-list.component';
import { HeaderComponent } from './header/header.component';
import { VideoAnalysisListComponent } from './assessment/videoAnalysis/video-analysis-list/video-analysis-list.component';
import { VideoAnalysisDetailComponent } from './assessment/videoAnalysis/video-analysis-detail/video-analysis-detail.component';
import { VideoAnalysisFormComponent } from './assessment/videoAnalysis/video-analysis-form/video-analysis-form.component';
import { PhysicalfitnessEditformComponent } from './assessment/physicalfitness/physicalfitness-editform/physicalfitness-editform.component';
import { NutritionEditformComponentComponent } from './assessment/nutrition/nutrition-editform-component/nutrition-editform-component.component';
import { BiomechanicalEditformComponent } from './assessment/biomechanical/biomechanical-editform/biomechanical-editform.component';
import { PhysiologyEditformComponent } from './assessment/physiology/physiology-editform/physiology-editform.component';
import { PhysiotherapyEditformComponent } from './assessment/physiotherapy/physiotherapy-editform/physiotherapy-editform.component';
import { VideoanalysiseditformComponent } from './assessment/videoAnalysis/videoanalysiseditform/videoanalysiseditform.component';
import { VideoAnalysisDetailListComponent } from './assessment/videoAnalysis/video-analysis-detail-list/video-analysis-detail-list.component';
import { PhysiologyformComponent } from './assessment/physiology/physiology-form/physiology-form.component';
import { RegisterComponent } from './admin/userregisterdetails/Register-Details/register.component';
import { PhysiotherapyGenericformComponent } from './assessment/physiotherapy/physiotherapy-genericform/physiotherapy-genericform.component';
import { LeveloneFormComponent } from './assessment/levelone/levelone-form/levelone-form.component';
import { ChangePasswordComponent } from './users/change-password/change-password.component';
import { UserProfileComponent } from './users/user-profile/user-profile.component';
import { LeveloneReportComponent } from './assessment/levelone/levelone-report/levelone-report.component';
import { LeveloneListComponent } from './assessment/levelone/levelone-list/levelone-list.component';
import { CognativeFormComponent } from './cognative/cognative-form/cognative-form.component';
import { CognativeListComponent } from './cognative/cognative-list/cognative-list.component';
import { CognativeDetailComponent } from './cognative/cognative-detail/cognative-detail.component';
import { PendingAssessmentComponent } from './assessment/pending-assessment/pending-assessment.component';
import { PendingAssessmentDetailsComponent } from './assessment/pending-assessment-details/pending-assessment-details.component';
import { CardiologyFormComponent } from './cardiology/cardiology-form/cardiology-form.component';
import { CardiologyListComponent } from './cardiology/cardiology-list/cardiology-list.component';
import { AssignAssessmentComponent } from './admin/assign-assessment/assign-assessment.component';
import { AssignTeamCoachComponent } from './admin/assign-team-coach/assign-team-coach.component';
import { TeamPlayerDetailsComponent } from './admin/team-player-details/team-player-details.component';
import { Component } from '@angular/core';
import { ReportCardComponent } from './assessment/report-card/report-card.component';
import { SportsPsychologyListComponent } from './sports-psychology/sports-psychology-list/sports-psychology-list.component';
import { PendingAsessmentFormComponent } from './pending-assessment/pending-asessment-form/pending-asessment-form.component';
import { CognativeDetailListComponent } from './cognative/cognative-detail-list/cognative-detail-list.component';
import { CardiologyDetailListComponent } from './cardiology/cardiology-detail-list/cardiology-detail-list.component';
import { CardiologyDetailComponent } from './cardiology/cardiology-detail/cardiology-detail.component';
import { PlayerListComponent } from './assessment/player/player-list/player-list.component';
import { OrganizationComponent } from './assessment/organisation/organization/organization.component';
import { EditorganizationComponent } from './assessment/organisation/editorganization/editorganization.component';
import { AddOrganizationComponent } from './assessment/organisation/add-organization/add-organization.component';
import { TeamComponent } from './assessment/teams/team/team.component';
import { AddTeamComponent } from './assessment/teams/add-team/add-team.component';
import { EditTeamComponent } from './assessment/teams/edit-team/edit-team.component';
import { AssignTeamComponent } from './assessment/teams/assign-team/assign-team.component';
import { CSSHealthFitnessComponent } from './assessment/csshealth-fitness/csshealth-fitness.component';
import { SAIHealthFitnessComponent } from './assessment/levelone/saihealth-fitness/saihealth-fitness.component';
import { CardiologyAssessmentComponent } from './cardiology/cardiology-assessment/cardiology-assessment.component';
import { CardiologyAssessmentListComponent } from './cardiology/cardiology-assessment-list/cardiology-assessment-list.component';
import { CagnativeAssessmentComponent } from './cognative/cagnative-assessment/cagnative-assessment.component';
import { PhysicalfitnessAssessmentComponent } from './assessment/physicalfitness/physicalfitness-assessment/physicalfitness-assessment.component';
import { CSSHealthFitnessDetailComponent } from './assessment/csshealth-fitness-detail/csshealth-fitness-detail.component';
import { CagnativeAssessmentListComponent } from './cognative/cagnative-assessment-list/cagnative-assessment-list.component';
import { PhysicalfitnessAssessmentListComponent } from './assessment/physicalfitness/physicalfitness-assessment-list/physicalfitness-assessment-list.component';
import { SAIHealthFitnessDetailComponent } from './assessment/levelone/saihealth-fitness-detail/saihealth-fitness-detail.component';
import { SleepQuestionComponent } from './sleep-question/sleep-question.component';
import { HydrationQuestionComponent } from './hydration-question/hydration-question.component';
import { SleepandrestAssessmentComponent } from './cardiology/sleepandrest-assessment/sleepandrest-assessment.component';
import { SleepandrestAssessmentListComponent } from './cardiology/sleepandrest-assessment-list/sleepandrest-assessment-list.component';
import { HydrationandnutritionAssessmentComponent } from './cardiology/hydrationandnutrition-assessment/hydrationandnutrition-assessment.component';
import { HydrationandnutritionAssessmentListComponent } from './cardiology/hydrationandnutrition-assessment-list/hydrationandnutrition-assessment-list.component';
import { RecallNutritionFormComponent } from './assessment/recall/recall-nutrition-form/recall-nutrition-form.component';
import { RecallListComponent } from './assessment/recall/recall-list/recall-list.component';
import { RecalldetailListComponent } from './assessment/recall/recalldetail-list/recalldetail-list.component';
import { InjuryPreventionAnalysisFormComponent } from './assessment/InjuryPreventionAnalysis/injury-prevention-analysis-form/injury-prevention-analysis-form.component';
import { InjuryPreventionAnalysisListComponent } from './assessment/InjuryPreventionAnalysis/injury-prevention-analysis-list/injury-prevention-analysis-list.component';
import { InjuryPreventionAnalysisAssessmentComponent } from './assessment/InjuryPreventionAnalysis/injury-prevention-analysis-assessment/injury-prevention-analysis-assessment.component';
import { SportsPerformanceAnalysisAssessmentComponent } from './admin/sports-performance-analysis/sports-performance-analysis-assessment/sports-performance-analysis-assessment.component';
import { SportsPerformanceAnalysisComponent } from './admin/sports-performance-analysis/sports-performance-analysis/sports-performance-analysis.component';
import { SportsPerformaceAnalysisListComponent } from './admin/sports-performance-analysis/sports-performace-analysis-list/sports-performace-analysis-list.component';
import { NutritionAssessmentComponent } from './assessment/nutrition/nutrition-assessment/nutrition-assessment.component';
import { NutritionAssessmentlistComponent } from './assessment/nutrition/nutrition-assessmentlist/nutrition-assessmentlist.component';
import { InjuryPreventionAnalysisDetailsComponent } from './assessment/InjuryPreventionAnalysis/injury-prevention-analysis-details/injury-prevention-analysis-details.component';
import { SportsPerformanceAnalysisDetailsComponent } from './admin/sports-performance-analysis/sports-performance-analysis-details/sports-performance-analysis-details.component';
import { InjuryPreventionAnalysisDetailListComponent } from './assessment/InjuryPreventionAnalysis/injury-prevention-analysis-detail-list/injury-prevention-analysis-detail-list.component';
import { SportsPerformanceAnalysisDetailListComponent } from './admin/sports-performance-analysis/sports-performance-analysis-detail-list/sports-performance-analysis-detail-list.component';
import { RecallPlayerListComponent } from './assessment/recall/recall-player-list/recall-player-list.component';
export const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'register', component: AssessmentFormComponent },
  { path: 'player-registration', component: PlayerRegistrationComponent },
  { path: 'registration', component: RegistrationComponent },
  { path: 'change-password', component: ChangePasswordComponent },

  {
    path: '', component: SidenavbarComponent, children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'dashboard', component: DashboardComponent },
      { path: 'profile', component: UserProfileComponent },
      { path: 'header', component: HeaderComponent },
      { path: 'physiotherapy', component: PhysiotherapyFormComponent },
      { path: 'physiotherapylist', component: PhysiotherapyListComponent },
      { path: 'physiotherapydetail', component: PhysiotherapyDetailComponent },
      { path: 'physiotherapyeditform', component: PhysiotherapyEditformComponent },
      { path: 'physicalfitness', component: PhysicalfitnessComponent },
      { path: 'physicalfitnesslist', component: PhysicalfitnessListComponent },
      { path: 'physicalfitnessdetail', component: PhysicalfitnessDetailComponent },
      { path: 'physicalfitnessedit/:id', component: PhysicalfitnessEditformComponent },
      { path: 'physicalfitnessedit', component: PhysicalfitnessEditformComponent },
      { path: 'biomachanics', component: BiomechanicsPhysiologyFormComponent },
      { path: 'biomachanicslist', component: BiomechanicsPhysiologyListComponent },
      { path: 'biomachanicsdetail', component: BiomechanicsPhysiologyDetailComponent },
      { path: 'biomechanicsdetaillist', component: BiomechanicsDetailListComponent },
      { path: 'physiologylist', component: PhysiologyListComponent },
      { path: 'physiologydetail', component: PhysiologyDetailComponent },
      { path: 'physiology', component: PhysiologyformComponent },
      { path: 'physiology', component: PhysiologyformComponent },
      { path: 'physiologydetaillist', component: PhysiologyDetailListComponent },
      { path: 'physicalfitnessdetaillist', component: PhysicalfitnessDetailListComponent },
      { path: 'physicalfitnessdetaillist/:id', component: PhysicalfitnessDetailListComponent },
      { path: 'videoanalysis', component: VideoAnalysisDetailComponent },
      { path: 'videoanalysislist', component: VideoAnalysisListComponent },
      { path: 'videoanalysisform', component: VideoAnalysisFormComponent },
      { path: 'nutrition', component: NutritionFormComponent },
      { path: 'nutritionlist', component: NutritionListComponent },
      { path: 'nutritiondetail', component: NutritionDetailComponent },
      { path: 'nutritiondetaillist', component: NutritionDetailListComponent },
      { path: 'nutritiondetaillist/:id', component: NutritionDetailListComponent },
      { path: 'biomachanics', component: BiomechanicsPhysiologyFormComponent },
      { path: 'biomachanicslist', component: BiomechanicsPhysiologyListComponent },
      { path: 'biomachanicsdetail', component: BiomechanicsPhysiologyDetailComponent },
      { path: 'physiologylist', component: PhysiologyListComponent },
      { path: 'physiologydetail', component: PhysiologyDetailComponent },
      { path: 'playerdashboard', component: PlayerDashboardComponent },
      { path: 'add-assessment', component: AssessmentComponent },
      { path: 'Physiotherapydetaillist', component: PhysiotherapyDetailListComponent },
      { path: 'biomechanicsdetaillist', component: BiomechanicsDetailListComponent },
      { path: 'personalInformation', component: PersonalInformationDetailComponent },
      { path: 'userdetails', component: UserDetailsComponent },
      { path: 'registerrequest', component: RegisterComponent },
      { path: 'registerrequest/:id', component: RegisterComponent },
      { path: 'videoanalysislist', component: VideoAnalysisListComponent },
      { path: 'videoanalysisdetail', component: VideoAnalysisDetailComponent },
      { path: 'nurtritionedit/:id', component: NutritionEditformComponentComponent },
      { path: 'nurtritionedit', component: NutritionEditformComponentComponent },
      { path: 'VideoAnalysisDetailList', component: VideoAnalysisDetailListComponent },
      { path: 'VideoAnalysisDetailList/:id', component: VideoAnalysisDetailListComponent },
      { path: 'VideoAnaalysisedit', component: VideoanalysiseditformComponent },
      { path: 'biomachaniceditform', component: BiomechanicalEditformComponent },
      { path: 'physiologyeditform', component: PhysiologyEditformComponent },
      { path: 'Genericform', component: PhysiotherapyGenericformComponent },
      { path: 'LevelOneForm', component: LeveloneFormComponent },
      { path: 'LeveloneReport', component: LeveloneReportComponent },
      { path: 'levelonelist', component: LeveloneListComponent },
      { path: 'cognitive', component: CognativeFormComponent },
      { path: 'cognitivelist', component: CognativeListComponent },
      { path: 'cognitivedetail', component: CognativeDetailComponent },
      { path: 'cognitivedetaillist', component: CognativeDetailListComponent },
      { path: 'manage_team_coach', component: AssignTeamCoachComponent },
      { path: 'team-player', component: TeamPlayerDetailsComponent },
      { path: 'assignassessment', component: AssignAssessmentComponent },
      { path: 'PendingAssessment', component: PendingAssessmentComponent },
      { path: 'PendingAssessmentdetail', component: PendingAssessmentDetailsComponent },
      { path: 'PendingAssessmentdetail/:id', component: PendingAssessmentDetailsComponent },
      { path: 'report-card', component: ReportCardComponent },
      { path: 'report', component: SportsPsychologyListComponent },
      { path: 'CardiologyForm', component: CardiologyFormComponent },
      { path: 'CardiologyList', component: CardiologyListComponent },
      { path: 'CardiologyDetailList', component: CardiologyDetailListComponent },
      { path: 'CardiologyDetail', component: CardiologyDetailComponent },
      { path: 'playerlist', component: PlayerListComponent },
      { path: 'pendingform', component: PendingAsessmentFormComponent },
      { path: 'team', component: TeamComponent },
      { path: 'organization', component: OrganizationComponent },
      { path: 'edit-organization', component: EditorganizationComponent },
      { path: 'add-organization', component: AddOrganizationComponent },
      { path: 'add-team', component: AddTeamComponent },
      { path: 'edit-team', component: EditTeamComponent },
      { path: 'assign-team', component: AssignTeamComponent },
      { path: 'Fitness', component: CSSHealthFitnessComponent },
      { path: 'fitnessDetail', component: CSSHealthFitnessDetailComponent },
      { path: 'saifitness', component: SAIHealthFitnessComponent },
      { path: 'CardiologyAssessment', component: CardiologyAssessmentComponent },
      { path: 'CognitiveAssessment', component: CagnativeAssessmentComponent },
      { path: 'CardiologyAssessmentList', component: CardiologyAssessmentListComponent },
      { path: 'PhysicalfitnessAssessment', component: PhysicalfitnessAssessmentComponent },
      { path: 'CognativeAssessmentList', component: CagnativeAssessmentListComponent },
      { path: 'PhysicalfitnessAssessmentList', component: PhysicalfitnessAssessmentListComponent },
      { path: 'saisportdetail', component: SAIHealthFitnessDetailComponent },
      { path: 'NurtitionAssessment', component: NutritionAssessmentComponent },
      { path: 'NutritionAssessmentList', component: NutritionAssessmentlistComponent },
      { path: 'SleepQuestion', component: SleepQuestionComponent },
      { path: 'HydrationQuestion', component: HydrationQuestionComponent },
      { path: 'SleepandRestAssessment', component: SleepandrestAssessmentComponent },
      { path: 'SleepandRestAssessmentList', component: SleepandrestAssessmentListComponent },
      { path: 'HydrationandNutritionAssessment', component: HydrationandnutritionAssessmentComponent },
          { path: 'HydrationandNutritionAssessmentList', component: HydrationandnutritionAssessmentListComponent },
          { path: 'RecallNutritionComponent', component: RecallNutritionFormComponent },
          { path: 'RecallListComponent', component: RecallListComponent },
          { path: 'RecalldetailListComponent', component: RecalldetailListComponent },
          { path: 'Injuryanalysis', component: InjuryPreventionAnalysisListComponent },
          { path: 'InjuryanalysisForm', component: InjuryPreventionAnalysisFormComponent },
          { path: 'InjuryanalysisDetailList', component: InjuryPreventionAnalysisDetailListComponent },
          { path: 'InjuryanalysisDetail', component: InjuryPreventionAnalysisDetailsComponent },
          { path: 'InjuryanalysisAssessment', component: InjuryPreventionAnalysisAssessmentComponent },
          { path: 'SportsPerformanceAnalysis', component: SportsPerformanceAnalysisComponent },
          { path: 'SportsPerformanceAnalysisAssessment', component: SportsPerformanceAnalysisAssessmentComponent },
          { path: 'SportsPerformanceAnalysisList', component: SportsPerformaceAnalysisListComponent },
          { path: 'SportsPerformanceAnalysisDetail', component: SportsPerformanceAnalysisDetailsComponent },
          { path: 'SportsPerformanceAnalysisDetaillist', component: SportsPerformanceAnalysisDetailListComponent },
     { path: 'SportsPerformanceAnalysisDetail', component: SportsPerformanceAnalysisDetailsComponent },
     { path: 'RecallPlayerListComponent', component: RecallPlayerListComponent },

    ],
  },
];
