import { CommonModule } from '@angular/common';
import { Component, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { Router, RouterLink, RouterModule } from '@angular/router';
import { AssessmentService } from '../../../services/assessment.service';
import { Privilege } from '../../../model/Privillage';
import { PrivilegeService } from '../../../services/privilage.service';
import { UserService } from '../../../services/user.service';
import { User } from '../../../model/User';
import { SharedService } from '../../../services/shared.service';
import { FileuploadComponent } from '../../fileupload/fileupload.component';

interface IRow {
  playerName: string;
  naId:string;
  naAthlete:string;
  Assessor: string;
  weight: string;
  height: string;
  Hemoglobin: string;
  date: string;
  priority: string;
}

@Component({
  selector: 'app-nutrition-list',
  standalone: true,
  imports: [CommonModule, RouterLink, RouterModule, AgGridAngular,FileuploadComponent],
  templateUrl: './nutrition-list.component.html',
  styleUrls: ['./nutrition-list.component.css']
})
export class NutritionListComponent implements OnInit {
  user: User | null = null;
  @ViewChild('fileUploadModal') fileUploadModal: FileuploadComponent | undefined;
  @Input() name: any;
  isAdmin: boolean = false;
  isPlayer: boolean = false;
  OrgAdmin: boolean = false;
  privilege: Privilege;

  themeClass = 'ag-theme-quartz';

  rowData: IRow[] = [];

  colDefs: ColDef[] = [];

  defaultColDef: ColDef = {
    flex: 1,
    sortable: true,
    filter: true,
  };
  Isplayer: boolean;
  Admin: boolean;
  detail: any;
  org: any;
  Coach: boolean =false;
  usrId: any;

  constructor(private assessmentService: AssessmentService,private ngZone: NgZone, private router: Router,private priService:PrivilegeService, private sharedService: SharedService,private userService:UserService) {
    this.privilege = this.priService.getPrivileges();
    this.Isplayer = this.privilege.isPlayer;
    this.Admin = this.privilege.isAdmin;
    this.Coach = this.privilege?.isCoach ?? false;
    this.OrgAdmin = this.privilege.isOrgAdmin;}

  ngOnInit() {
    this.sharedService.detail$.subscribe((data: any) => {
      this.detail = data;
      this.org=this.detail.user.usrOrganization;
      this.usrId=this.detail.user.usrId;
    });
    this.user = this.userService.getUser();
if(this.Coach){
  this.assessmentService.getnutByCoachId(this.usrId).subscribe(response => {
    console.log('Assessment data', response);

    this.rowData = response.map((item: any) => ({
      playername: item.athleteFullName,
      naId:item.naId,
      naAthlete:item.naAthlete,
      ID:item.naAthlete,
      Assessor: `${item.AssessorFullName}`,
      weight: item.naBodyWeight,
      height: item.naBodyHeight,
      Hemoglobin: item.naHemoglobin,
      date: new Date(item.naDate).toLocaleDateString(),
      priority: this.getPriority(item)
    }));

    this.colDefs = this.getColumnDefinitions();
  }, error => {
    console.error('Error fetching assessment data', error);
  });
}else{
  this.assessmentService.getNutritional(this.org).subscribe(response => {
    console.log('Assessment data', response);

    this.rowData = response.map((item: any) => ({
      playername: item.athleteFullName,
      naId:item.naId,
      naAthlete:item.naAthlete,
      ID:item.naAthlete,
      Assessor: `${item.AssessorFullName}`,
      weight: item.naBodyWeight,
      height: item.naBodyHeight,
      Hemoglobin: item.naHemoglobin,
      date: new Date(item.naDate).toLocaleDateString(),
      priority: this.getPriority(item)
    }));

    this.colDefs = this.getColumnDefinitions();
  }, error => {
    console.error('Error fetching assessment data', error);
  });
}


    this.setUserRole();
  }

  getPriority(item: any): string {
    return (item.naBodyHeight > 0 || item.naBodyWeight > 0) ? 'High' : 'Low';
  }

  setUserRole() {
   
  }

  getColumnDefinitions(): ColDef[] {
    const columns: ColDef[] = [
      { field: 'playername' },
       { field: 'ID' },
     // { field: 'naAthlete' },
      { field: 'Assessor' },
      { field: 'date' },
      { field:'height'},
      { field:'weight'},
      { field:'Hemoglobin'},{
        field: 'priority',
        cellStyle: params => ({
          color: params.value === 'Low' ? 'red' : 'green'
        })
      },
      {
        headerName: 'Action',
        cellRenderer: (_params: any) => {
          const container = document.createElement('div');
          container.style.display = 'flex';
          container.style.justifyContent = 'space-around';
 
          // View button with eye icon
          const viewButton = document.createElement('button');
          viewButton.classList.add('btn');
          viewButton.innerHTML = '<i class="fa fa-eye"></i>';
          viewButton.addEventListener('click', () => {
            if (this.isPlayer) {
              this.ngZone.run(() => {
              this.router.navigate(['/nutritiondetail'])
              })
            } else {
              this.ngZone.run(() => {
              this.router.navigate(['/nutritiondetaillist'], { queryParams: { id: _params.data.naAthlete } })
              })
            }
          });
 
          // Edit button with edit icon
          const editButton = document.createElement('button');
          editButton.classList.add('btn');
          editButton.innerHTML = '<i class="fa fa-edit"></i>';
          editButton.addEventListener('click', () => {
            this.ngZone.run(() => {
            this.router.navigate(['/nutrition'], {queryParams: { id: _params.data.naId } })
          })
          });
 
          // Delete button with delete icon
          // const deleteButton = document.createElement('button');
          // deleteButton.classList.add('btn');
          // deleteButton.innerHTML = '<i class="fa fa-trash"></i>';
          // deleteButton.addEventListener('click', () => {
          //   // Handle delete action
          // });
 
          container.appendChild(viewButton);
          if (!this.Isplayer && !this.OrgAdmin && !this.Coach) {
            container.appendChild(editButton);
            }
         // container.appendChild(deleteButton);
 
          return container;
        },
        width: 120
      }
    ];

    if (this.isAdmin || this.OrgAdmin) {
      return columns;
    } else if (this.isPlayer) {
      return columns.filter(col => col.field !== 'playername');
    } else {
      return columns.filter(col => col.field !== 'Assessor' && col.field !== 'playername');
    }
  }
  openModal(): void {
    // Use Bootstrap's modal function to open the modal
    const modalElement = document.getElementById('fileUploadModal')!;
    const modal = new (window as any).bootstrap.Modal(modalElement);
    modal.show();
  }
}
