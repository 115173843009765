import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { AbstractControl, FormsModule, ValidationErrors } from '@angular/forms';
import { AssessmentService } from '../../services/assessment.service';
import { Router } from '@angular/router';
import { NotificationService } from '../../services/Notifications.service';
import { NgToastModule } from 'ng-angular-popup';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { RoleService } from '../../services/role.service';

@Component({
  selector: 'app-registration',
  standalone: true,
  imports: [CommonModule, RouterOutlet, HttpClientModule, FormsModule, NgToastModule,RecaptchaModule,
    RecaptchaFormsModule],
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {
  isCoach: boolean = false;
  isSupport: boolean = false;
  today: string;
  Sports: any[] = [];
  Supportstaff: any[] = [];
  Orgainsation: any[] = [];
  captchaResponse: boolean = false;
  formSubmitted: boolean = false;
  orgId: any;
  roleId:any;
  roles:any;
  isManager: boolean = false;
  isAssessor: boolean = false;
  roleName: any;
  confirmPassword: any;
  showPassword: boolean = false;
  showConfirmPassword: boolean = false;

  constructor(private assessmentService: AssessmentService,
    private roleService: RoleService,
     private route: ActivatedRoute, private router: Router, private notify: NotificationService) {


    this.route.queryParams.subscribe(params => {
      this.orgId = params['Org'];
      this.roleId = params['id']
      console.log(this.orgId,"Org");
    });
    const now = new Date();
    this.today = now.toISOString().split('T')[0];
   }

  trainerDetail: any = {
    usrId:0,
    usrFullName: '',
    usrDoB: '',
    usrGender: '',
    usrReligion:'',
    usrCaste:'',
    usrAddress: '',
    usrPhoneNo: '',
    usrPassword: '',
    usrEmail: '',
    usrECName: '',
    usrECRelationship: '',
    usrECPhoneNo: '',
    usrSport: '',
    usrSupport: '',
    usrOrganization: '',
    usrActive:''
  }
  get passwordsDoNotMatch(): boolean {
    return this.trainerDetail.usrPassword !== this.confirmPassword;
  }
  
  get isConfirmPasswordEmpty(): boolean {
    return !this.confirmPassword;
  }
  
  
  ngOnInit() {
    if (this.orgId != 0) {
      this.trainerDetail.usrOrganization = this.orgId;
    }
    if (typeof window !== 'undefined' && typeof localStorage !== 'undefined') {
    const isSupport = localStorage.getItem('isSupport');
    const isCoach = localStorage.getItem('isCoach');
    const isManager = localStorage.getItem('isManager');
    const isAssessor = localStorage.getItem('isAssessor');
    this.loadOrgainsation()
    if (isSupport === 'true') {
      this.isSupport = true;
      this.isCoach = false;
      this.isManager = false;
      this.isAssessor = false;
      this.loadSupportsatff()
    } else if (isCoach === 'true') {
      this.isSupport = false;
      this.isCoach = true;
      this.isManager = false;
      this.isAssessor = false;
      this.loadCoaches();
    }
  }
  this.getRoles();
  }
  onSupportStaffChange(event: any) {
    const selectedValue = event.target.value;
    this.trainerDetail.usrSupport = selectedValue;
  }

  private loadCoaches() {
    this.assessmentService.getSport().subscribe(
      (data: any[]) => {
        this.Sports = data;
      },
      (error: any) => {
        console.error('Error fetching coaches:', error);
      }
    );
  }
  private loadOrgainsation() {
    this.assessmentService.getOrgainsation().subscribe(
      (data) => {
        this.Orgainsation = data;
      },
      (error) => {
        console.error('Error fetching Organization:', error);
      }
    );
  }
  private loadSupportsatff() {
    this.assessmentService.getSupportStaff().subscribe(
      (data: any[]) => {
        this.Supportstaff = data;
      },
      (error: any) => {
        console.error('Error fetching coaches:', error);
      }
    );
  }
  onSubmit() {
    if (this.roleId == 3|| this.roleId ==7) {
      this.trainerDetail.usrSupport = 0;
      this.trainerDetail.usrSport = 0;
    } else if (this.roleId == 6) {
      this.trainerDetail.usrSupport = 0;
    } else {
      this.trainerDetail.usrSport = 0;
    }
    this.trainerDetail.usrActive = this.orgId !== undefined ? 1 : 0;
    this.trainerDetail.usrPhoneNo = this.trainerDetail.usrPhoneNo.toString();
    this.trainerDetail.usrECPhoneNo = this.trainerDetail.usrECPhoneNo.toString();
    this.trainerDetail.usrRole = this.roleId
    if(this.orgId === undefined ){
    if (this.captchaResponse) {
      this.formSubmitted = true;
      if (this.isFormValid()) {
        
        this.assessmentService.addTrainer([this.trainerDetail]).subscribe(
          response => {
            this.notify.success("Data submitted successfully!");
            setTimeout(() => {
              this.router.navigate(['']);
            }, 1000);
          },
          error => {
            // Check if the backend provides a specific error message
            const errorMessage = error.error ? error.error : 'Error submitting form';
            
            // Display the error message using your notification system
            this.notify.failed(errorMessage);
            
            setTimeout(() => {
              // Optional: Add any additional actions after the error is shown
            }, 1000);
        
            console.error('Error submitting form', errorMessage);
          }
        );
        
      } else {
        console.log('Form is not valid');
      }
    } else {
      this.notify.failed("Invalid captcha");
      setTimeout(() => {

      }, 1000);

    }
  }else{
    this.formSubmitted = true;
    if (this.isCoach) {
      this.trainerDetail.usrRole = this.roleId
    }
    this.trainerDetail.usrRole = this.roleId
    if (this.isFormValid()) {
      this.assessmentService.addTrainer([this.trainerDetail]).subscribe(
        response => {
          console.log('Trainer added successfully', response);
          this.notify.success("Data submitted successfully!");
          setTimeout(() => {
            this.router.navigate(['registerrequest']);
          }, 1000);
        },
        error => {
          // Check if the backend provides a specific error message
          const errorMessage = error.error ? error.error : 'Error submitting form';
          
          // Display the error message using your notification system
          this.notify.failed(errorMessage);
          
          setTimeout(() => {
            // Optional: Add any additional actions after the error is shown
          }, 1000);
      
          console.error('Error submitting form', errorMessage);
        }
      );
    } else {
      console.log('Form is not valid');
    }
  }
  }

  isFormValid(): boolean {
    return Object.keys(this.trainerDetail).every(key => {
      return this.trainerDetail[key] !== '' && this.trainerDetail[key] !== null;
    });
  }

  resolvedCaptcha(captchaResponse: any) {
    this.captchaResponse = (captchaResponse && captchaResponse.length > 0) ? true : false
    console.log(`Resolved captcha with response: ${captchaResponse}`);
  }
  getRoles() {
    this.roleService.getRoles().subscribe(
      (data: any[]) => {
        this.roles = data;
        // Assume Trainer role has id === 1
        const trainerRole = this.roles.find((role: { roleId: any; }) => role.roleId == this.roleId);
        if (trainerRole) {
          this.roleName = trainerRole.roleName;
        }
      },
      (error) => {
        console.error('Error fetching roles:', error);
      }
    );
  }


validatePhoneNumber(event: any) {
  const input = event.target.value;
  const filteredValue = input.replace(/[^0-9]/g, ''); 
  event.target.value = filteredValue;
if (filteredValue.length > 0 && filteredValue.startsWith('0')) {
  event.target.value = ''; 
  return; 
}
  this.trainerDetail.usrPhoneNo = event.target.value;
}
validateEcPhoneNumber(event: any) {
  const input = event.target.value;
  const filteredValue = input.replace(/[^0-9]/g, ''); 
  event.target.value = filteredValue;
if (filteredValue.length > 0 && filteredValue.startsWith('0')) {
  event.target.value = ''; 
  return; 
}
  this.trainerDetail.usrECPhoneNo = event.target.value;
}
}
