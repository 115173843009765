<div class="background-video">
    <!-- <video autoplay muted loop>
        <source src="https://cdn.pixabay.com/video/2022/08/16/128098-740186760_large.mp4" type="video/mp4">
    </video> -->
   
    <div class="container1 full-height d-flex align-items-center justify-content-center">
        <div class="row w-100 align-items-center">
            <!-- First Column -->
            <div class="col-lg-4 col-md-12 mb-4 header">
                <h2 class="mb-3">Select Roles</h2>
                <p>This is the description below the title. It will stay in the first column on larger screens and stack on top of the cards on smaller screens.</p>
               
              </div>
   
            <!-- Second Column -->
            <div class="col-lg-8 col-md-12">
                <div class="row">
                   
                    <!-- Custom Card 2 -->
                    <div class="col-md-4 col-sm-6 col-12 mb-4">
                        <div class="card">
                            <div class="content"data-target="player-item"
                            [ngClass]="{'active': activeItem === 'player-item'}"
                            (click)="setActive('player-item')" routerLink="/player-registration">
                                <svg fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20 9V5H4V9H20ZM20 11H4V19H20V11ZM3 3H21C21.5523 3 22 3.44772 22 4V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 3 3 3 3ZM5 12H8V17H5V12ZM5 6H7V8H5V6ZM9 6H11V8H9V6Z"></path>
                                </svg>
                                <p class="para">Player</p>
                            </div>
                        </div>
                    </div>
                    <!-- Custom Card 3 -->
                    <div class="col-md-4 col-sm-6 col-12 mb-4">
                      <div class="card">
                        <div class="content" data-target="trainer-item"
                             [ngClass]="{'active': activeItem === 'trainer-item'}"
                             (click)="setActive('trainer-item'); setgeneral()"
                             [routerLink]="['/registration']"
                             [queryParams]="{ id: 6 }">
                          <svg fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20 9V5H4V9H20ZM20 11H4V19H20V11ZM3 3H21C21.5523 3 22 3.44772 22 4V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 3 3 3 3ZM5 12H8V17H5V12ZM5 6H7V8H5V6ZM9 6H11V8H9V6Z"></path>
                          </svg>
                          <p class="para">Coach</p>
                        </div>
                      </div>
                    </div>
                   
                    <!-- Custom Card 4 -->
                    <!-- <div class="col-md-4 col-sm-6 col-12 mb-4">
                        <div class="card">
                            <div class="content" data-target="manager-item"
                            [ngClass]="{'active': activeItem === 'manager-item'}"
                            (click)="setActive('manager-item'); setgeneral()"
                            [routerLink]="['/registration']"
                             [queryParams]="{ id: 4 }">
                                <svg fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20 9V5H4V9H20ZM20 11H4V19H20V11ZM3 3H21C21.5523 3 22 3.44772 22 4V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2 3 3 3ZM5 12H8V17H5V12ZM5 6H7V8H5V6ZM9 6H11V8H9V6Z"></path>
                                </svg>
                                <p class="para">Manager</p>
                            </div>
                        </div>
                    </div> -->
                    <!-- Custom Card 5 -->
                    <div class="col-md-4 col-sm-6 col-12 mb-4">
                        <div class="card">
                            <div class="content" data-target="staff-item"
                            [ngClass]="{'active': activeItem === 'staff-item'}"
                            (click)="setActive('staff-item'); setSupport()"
                            routerLink="/registration" [queryParams]="{ id: 5 }">
                                <svg fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20 9V5H4V9H20ZM20 11H4V19H20V11ZM3 3H21C21.5523 3 22 3.44772 22 4V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2 3 3 3ZM5 12H8V17H5V12ZM5 6H7V8H5V6ZM9 6H11V8H9V6Z"></path>
                                </svg>
                                <p class="para">Science Staff</p>
                            </div>
                        </div>
                    </div>
                    <!-- Custom Card 6 -->
                    <div class="col-md-4 col-sm-6 col-12 mb-4">
                        <div class="card">
                            <div class="content" data-target="assessor-item"
                            [ngClass]="{'active': activeItem === 'assessor-item'}"
                            (click)="setActive('assessor-item'); setgeneral()"
                            [routerLink]="['/registration']"
                            [queryParams]="{ id: 3 }">
                                <svg fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20 9V5H4V9H20ZM20 11H4V19H20V11ZM3 3H21C21.5523 3 22 3.44772 22 4V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 3 3 3 3ZM5 12H8V17H5V12ZM5 6H7V8H5V6ZM9 6H11V8H9V6Z"></path>
                                </svg>
                                <p class="para">Assessor</p>
                            </div>
                        </div>
                    </div>
                 
           
                </div>
            </div>
        </div>
    </div>
    </div>