import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { environment } from '../../environments/environment.prod';
import { PrivilegeAccess } from '../model/PrivlageAcess';
import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root' // Ensure service is provided in root
})
export class AssessmentService {

  constructor(private http: HttpClient, private sharedService: SharedService) { }

  public getAuthHeaders(): HttpHeaders {
    const token = this.sharedService.getToken();
    return new HttpHeaders({
      'Authorization': token ? `Bearer ${token}` : ''
    });
  }

  addvideoAnalysis(VideoAnalysisform: any): Observable<any> {
    return this.http.post<any>(
      `${environment.BaseUrl}${environment.VideoAnalysisUrl}`,
      VideoAnalysisform,
      { headers: this.getAuthHeaders() }
    );
  }

  fetchAtheletesBMfitness(id: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.fetchAtheletesBMFitness}${id}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }

  fetchAtheletesBMByID(id: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.fetchBMById}${id}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }
  // Method to add physiology details
  getNutritional(org: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getAllNutritional}${org}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }

  //Physiology
  getphysiologyByAthleteId(id: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getPhysiologyByAthleteId}${id}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }

  fetchAtletePhysiology(id: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.fetchAtheletesPhysiology}${id}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }

  addPhysiologyDetail(assessment: any): Observable<any> {
    return this.http.post<any>(
      `${environment.BaseUrl}${environment.PhysiologyDetail}`,
      assessment,
      { headers: this.getAuthHeaders() }
    );
  }

  //physicalfitness

  addPhysicalFitnessDetail(assessment: any): Observable<any> {
    return this.http.post<any>(
      `${environment.BaseUrl}${environment.apiUrl}`,
      assessment,
      { headers: this.getAuthHeaders() }
    );
  }

  fetchAtletefitness(id: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.fetchAtheletesfitness}${id}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }

  addPhysiotherapy(assessment: any): Observable<any> {
    return this.http.post<any>(
      `${environment.BaseUrl}${environment.postphysiotherapyUrl}`,
      assessment,
      { headers: this.getAuthHeaders() }
    );
  }

  getPhysiotherapy(org: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getphysiotherapyUrl}${org}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }

  fetchphysiotherapybyid(id: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.fetchphysiotherapy}${id}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }

  getPTAtheleteById(id: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.fetchptaByIdUrl}${id}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }

  //Nutritional

  getPhysiologyDetail(org: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getPhysiology}${org}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }

  getAthletesPhysiology(searchTerm: string = ''): Observable<any[]> {
    const params = new HttpParams().set('search', searchTerm);
    return this.http.get<any[]>(`${environment.BaseUrl}${environment.getAllAthletesPhy}`, { headers: this.getAuthHeaders(), params });
  }

  addnutritional(assessment: any): Observable<any> {
    return this.http.post<any>(
      `${environment.BaseUrl}${environment.saveNurtitional}`,
      assessment,
      { headers: this.getAuthHeaders() }
    );
  }

  fetchAtleteNutritional(id: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.fetchAtheletesNutritional}${id}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }

  getNutritionalByAsm(AthleteID: any, asmID: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getNutritionalByAsm}?AthleteID=${AthleteID}&AssessmentId=${asmID}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }

  geteNutritionalByAthleteId(id: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getNutritionalByAthleteId}${id}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }

  geteInjuryPrventionByAthleteId(id: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getInjuryPreventionAnalysisAthleteId}${id}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }

  getSportsPerformaceDataByAthleteId(id: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getSportsPerformaceAnalysisAthleteId}${id}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }

  getBiomechanical(org: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getBioMechanicalUrl}${org}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }

  getvideoAnalysis(searchTerm: string = ''): Observable<any[]> {
    const params = new HttpParams().set('search', searchTerm);
    return this.http.get<any[]>(`${environment.BaseUrl}${environment.GetvideoAnalysis}`, { headers: this.getAuthHeaders(), params });
  }

  fetchAtleteVideoAnalysis(id: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.fetchAtheletesVideoAnalysis}${id}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }

  getVideoAnalysisAthelete(id: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getVideoAnalysisAtheletebyId}${id}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }

  getPhysicalFitnessDetail(orgId: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getfitnessreport}${orgId}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }

  getInjuryPreventionAnalysisByOrg(orgId: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.GetInjuryPreventionAnalysisByOrg}${orgId}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }

  getSportsperformanceByOrg(orgId: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.GetSportsperformanceByOrg}${orgId}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }

  getFitnessByAthleteId(id: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getPhysicalFitnessByAthleteId}${id}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }


  userLogin(email: string, password: string): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const body = {
      "usrEmail": email,
      "usrPassword": password
    };
    return this.http.post<any>(environment.BaseUrl + environment.LoginUrl, body, { headers });
  }

  getAthlete(searchTerm: string = ''): Observable<any[]> {
    const params = new HttpParams().set('search', searchTerm);
    return this.http.get<any[]>(environment.BaseUrl + environment.getAtheletes, { headers: this.getAuthHeaders(), params });
  }

  getPrivilageById(id: any): Observable<PrivilegeAccess[]> {
    const url = `${environment.BaseUrl}${environment.getPrivilagebyId}${id}`;
    return this.http.get<PrivilegeAccess[]>(url, { headers: this.getAuthHeaders() });
  }

  resetPassword(email: string): Observable<any> {
    const url = `${environment.BaseUrl}${environment.resetPassword}${email}`;
    return this.http.get<any>(url);
  }

  sendEmail(email: string): Observable<any> {
    const url = `${environment.BaseUrl}${environment.sendmail}${email}`;
    return this.http.post<any>(url, email);
  }

  changePassword(email: string, password: string): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const body = {
      "usrEmail": email,
      "usrPassword": password
    };
    return this.http.post<any>(environment.BaseUrl + environment.resetPassword, body, { headers });
  }

  // sendEmail(emailData: any) {
  //   return this.http.post(`${environment.BaseUrl}${environment.resetPassword}/send`, emailData);
  // }
  getOrgainsation(): Observable<any> {
    return this.http.get<any>(environment.BaseUrl + environment.GetOrganisation);
  }

  addTrainer(trainerDetail: any): Observable<any> {
    return this.http.post<any>(environment.BaseUrl + environment.SaveTrainer, trainerDetail, { headers: this.getAuthHeaders() });
  }

  getSport(): Observable<any> {
    return this.http.get<any>(environment.BaseUrl + environment.getallsport);
  }

  getSupportStaff(): Observable<any> {
    return this.http.get<any>(environment.BaseUrl + environment.GetSupportStaff);
  }

  addBiomechanical(bioMechanicalform: any): Observable<any> {
    return this.http.post<any>(environment.BaseUrl + environment.BioMechanicalUrl, bioMechanicalform, { headers: this.getAuthHeaders() });
  }

  getAthletesBM(searchTerm: string = ''): Observable<any[]> {
    const params = new HttpParams().set('search', searchTerm);
    return this.http.get<any[]>(environment.BaseUrl + environment.fetchAtheletesBMFitness, { params, headers: this.getAuthHeaders() });
  }

  addLevelOneDetail(levelone: any): Observable<any> {
    return this.http.post<any>(environment.BaseUrl + environment.SAVELEVELONE, levelone, { headers: this.getAuthHeaders() });
  }

  getLevelOneList(id: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.GETLEVELONEDATA}${id}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }

  getLeveloneByCoachId(id: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.GETLEVELONEBYCOACH}${id}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }

  getfitnessByCoachId(id: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.GETFITNESSBYCOACH}${id}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }

  getplaByCoachId(id: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.GETPHYSIOLOGYBYCOACH}${id}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }

  getptaByCoachId(id: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.GETPHYSIOTHERAPYBYCOACH}${id}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }

  getnutByCoachId(id: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.GETNUTRITIONALBYCOACH}${id}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }

  getTrainer(): Observable<any[]> {
    return this.http.get<any[]>(environment.BaseUrl + environment.getcoachName, { headers: this.getAuthHeaders() });
  }

  getOrgTrainer(roleId: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getOrgTrainer}${roleId}`;
    return this.http.get(url, { headers: this.getAuthHeaders() });
  }

  getTeams(): Observable<any[]> {
    return this.http.get<any[]>(environment.BaseUrl + environment.getTeamName, { headers: this.getAuthHeaders() });
  }

  getLevelOneListById(id: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.LEVELONEBYID}${id}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }

  getLevelOneBySport(id: any, sportName: string, loaAsessmentDate: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.LEVELONEBYSPORT}`;
    const params = new HttpParams()
      .set('id', id.toString())
      .set('sportName', sportName.toString())
      .set('assessment', loaAsessmentDate.toString());

    return this.http.get<any>(url, { headers: this.getAuthHeaders(), params });
  }

  getLevelOneByAthleteId(id: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.LEVELONEBYATHLETEID}`;
    const params = new HttpParams().set('id', id.toString());

    return this.http.get<any>(url, { params, headers: this.getAuthHeaders() });
  }

  getImage(id: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getProfileimagebyId}${id}`;
    return this.http.get(url, { responseType: 'blob', headers: this.getAuthHeaders() });
  }

  getEmptyImage(id: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getProfileimagebyId}${id}`;
    return this.http.get(url, { headers: this.getAuthHeaders() });
  }

  getOrgImage(orgId: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getOrgimagebyId}${orgId}`;
    return this.http.get(url, { responseType: 'blob', headers: this.getAuthHeaders() });
  }

  getEmptyOrgImage(orgId: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getOrgimagebyId}${orgId}`;
    return this.http.get(url, { headers: this.getAuthHeaders() });
  }

  getAdmitCardById(id: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getAdmitCardById}${id}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }

  getAdmitCard(OrgId: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getAdmitCard}${OrgId}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }

  addcognitive(savecognitive: any): Observable<any> {
    return this.http.post<any>(environment.BaseUrl + environment.SAVECOGNITIVE, savecognitive, { headers: this.getAuthHeaders() });
  }

  getcognitiveDetail(orgId: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.GETCOGNITIVE}${orgId}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }

  fetchcognitive(id: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.fetchAtheletescatdio}${id}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }

  getTests(assessmentId: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.GETTESTS}${assessmentId}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }

  assignAssignment(assessment: any): Observable<any> {
    return this.http.post<any>(environment.BaseUrl + environment.SAVEASSESSMENT, assessment, { headers: this.getAuthHeaders() });
  }

  updateUserSelection(selectionData: any): Observable<any> {
    return this.http.post<any>(environment.BaseUrl + environment.UpdatSportInfo, selectionData, { headers: this.getAuthHeaders() });
  }

  AddTeam(teamId: any, orgId: any, teamName: string, teamSport: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.AddTeam}?teamId=${teamId}&orgId=${orgId}&teamName=${teamName}&teamSport=${teamSport}`;
    return this.http.post(url, {}, { headers: this.getAuthHeaders() });
  }

  AddOrganisation(orgId: any, orgName: string): Observable<any> {
    const url = `${environment.BaseUrl}${environment.AddOrganisation}?orgId=${orgId}&orgName=${orgName}`;
    return this.http.post(url, {}, { headers: this.getAuthHeaders() });
  }

  getcardioetaillist(id: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getcardioByOrgId}${id}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }

  getcognitiveDetaillist(id: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getcognitiveByAtheleteId}${id}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }


  getPendingAssessmentById(Id: any, Status: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getPendingAssessmentById}`;
    const params = new HttpParams()
      .set('Id', Id.toString())
      .set('Status', Status.toString());

    return this.http.get<any>(url, { headers: this.getAuthHeaders(), params });
  }

  getpendingAssessment(): Observable<any[]> {
    return this.http.get<any[]>(environment.BaseUrl + environment.getPendingAssessment, { headers: this.getAuthHeaders() });
  }

  getTestDetails(teamid: any, assesserId: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getTestsName}`;
    const params = new HttpParams()
      .set('teamid', teamid.toString())
      .set('assesserId', assesserId.toString());
    return this.http.get<any>(url, { headers: this.getAuthHeaders(), params });
  }

  getAllTest(id: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getAllTestsName}${id}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }

  getcardioByOrgId(id: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getcardioByOrgId}${id}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }

  getcardioByAthleteId(id: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getcardioByAthleteId}${id}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }

  getcardioById(id: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getcardioById}${id}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }

  addcordio(SAVECORDIO: any): Observable<any> {
    console.log(SAVECORDIO)
    return this.http.post<any>(environment.BaseUrl + environment.SAVECORDIO, SAVECORDIO, { headers: this.getAuthHeaders() });
  }

  getcategories(): Observable<any[]> {
    return this.http.get<any[]>(environment.BaseUrl + environment.GETCATEGORY, { headers: this.getAuthHeaders() });
  }

  getAssessmentBycategory(category: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getAssessmentBycat}${category}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }

  getAssessmentTest(asmId: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getAssessmentTest}${asmId}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }

  getPendingAllAssessment(id: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getPendingAllAssessment}${id}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }

  getTeamByAssessmentId(id: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getTeamByAssessmentId}${id}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }

  levelonereference(age: any, gender: any, sport?: string): Observable<any> {
    if (sport) {
        const url = `${environment.BaseUrl}${environment.leveloneReference}?age=${age}&gender=${gender}&sport=${sport}`;
        return this.http.get<any>(url, { headers: this.getAuthHeaders() });
    } else {
        return EMPTY; 
    }
}


  getPlayerByAssessmentId(asmId: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getPlayerByAssessment}${asmId}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }

  getAllAssessment(): Observable<any[]> {
    return this.http.get<any[]>(environment.BaseUrl + environment.getAllAssessment, { headers: this.getAuthHeaders() });
  }

  getFitnessByAsm(AthleteID: any, asmID: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getfitnessByAssessment}?AthleteID=${AthleteID}&asmID=${asmID}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }

  getCardioByAsm(AthleteID: any, asmID: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getCardioByAssessment}?AthleteID=${AthleteID}&asmID=${asmID}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }

  getCognitiveByAsm(AthleteID: any, asmID: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getCognitiveByAssessment}?AthleteID=${AthleteID}&asmID=${asmID}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }

  updateAssessmentStatus(playerId: number, asmId: number, catId: number, Assessor: number): Observable<any> {
    const url = `${environment.BaseUrl}${environment.updateAssessmentStatus}?playerId=${playerId}&asmId=${asmId}&catId=${catId}&Assessor=${Assessor}`;
    console.log({ playerId, asmId }, 'active');
    return this.http.put(url, {}, { headers: this.getAuthHeaders() });
  }

  checkUserSummary(playerId: any, asmId: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.checkUsrSummary}?playerId=${playerId}&asmId=${asmId}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }

  saveSummary(summary: any): Observable<any> {
    console.log(summary)
    return this.http.post<any>(environment.BaseUrl + environment.saveUserSummary, summary, { headers: this.getAuthHeaders() });
  }

  getPlayerByTeam(teamId: any): Observable<any> {
    console.log(teamId)
    const url = `${environment.BaseUrl}${environment.GETPLAYERSBYTEAM}${teamId}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }

  getLevelOneByAsm(AthleteID: any, asmID: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getLevelOneByAsmId}?loaAthlete=${AthleteID}&asmId=${asmID}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }

  addIPADetails(Ipa: any): Observable<any> {
    console.log(Ipa)
    return this.http.post<any>(environment.BaseUrl + environment.InjuryPrevention, Ipa, { headers: this.getAuthHeaders() });
  }

  getInjuryByAsm(AthleteID: any, asmID: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getInjuryByAssessment}?AthleteID=${AthleteID}&asmID=${asmID}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }

  getSportsperformanceByAsm(AthleteID: any, asmID: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getSportsperformanceByAsmId}?AthleteID=${AthleteID}&asmID=${asmID}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }

  getSportsperformanceByAthleteId(id: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.GetSportsperformanceByAthleteId}${id}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }

  getInjuryById(id: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getcardioById}${id}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }

  getInjuryByAthleteId(id: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getInjuryByAthleteId}${id}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }

  saveSleepandRest(sleepandrest: any): Observable<any> {
    console.log(sleepandrest)
    return this.http.post<any>(environment.BaseUrl + environment.SaveSleepandRest, sleepandrest, { headers: this.getAuthHeaders() });
  }

  saveHydrationandNutrition(hydrationandnutrition: any): Observable<any> {
    console.log(hydrationandnutrition)
    return this.http.post<any>(environment.BaseUrl + environment.SaveHydrationandNutrition, hydrationandnutrition, { headers: this.getAuthHeaders() });
  }

  getSleepandRestByAsm(AthleteID: any, asmID: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.GetSleepandRestByAsmId}?AthleteID=${AthleteID}&asmID=${asmID}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }

  getHydrationandNutritionByAsm(AthleteID: any, asmID: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.GetHydrationandNutritionByAsmId}?AthleteID=${AthleteID}&asmID=${asmID}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }

  getAthleteByOrg(orgId: any): Observable<any> {
    console.log(orgId)
    const url = `${environment.BaseUrl}${environment.GETATHLETEBYORG}${orgId}`
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }

  uploadOrganizationLogo(formData: FormData): Observable<any> {
    const url = `${environment.BaseUrl}${environment.uploadorganizationlogo}`;
    return this.http.post<any>(url, formData, { headers: this.getAuthHeaders() });
  }

  saveRecallAssessments(formData: any): Observable<any> {
    return this.http.post<any>(environment.BaseUrl + environment.SaveRecallAssessments, formData, { headers: this.getAuthHeaders() });
  }

  getRecallAssessments(atheleteId: any, assessorId: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.GetRecallAssessments}?atheleteId=${atheleteId}&assessorId=${assessorId}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });;
  }

  SaveSportsperformance(summary: any): Observable<any> {
    console.log(summary);
    return this.http.post<any>(environment.BaseUrl + environment.SaveSportsperformanceanalysis, summary, { headers: this.getAuthHeaders() });
  }

  getRecallAssessmentByAtheleteId(Id: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getRecallAssessmentByAtheleteId}${Id}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });;
  }

  getPhysicalFitnessByAsm(atheleteId: any, asmId: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getPhysicalFitnessByAsm}?AthleteID=${atheleteId}&asmID=${asmId}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });;
  }

  getNutritionByAsm(atheleteId: any, asmId: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getNutritionaByAsm}?AthleteId=${atheleteId}&AssessmentId=${asmId}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });;
  }

  getCognitiveReportByAsm(atheleteId: any, asmId: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getcognitiveByAsm}?AthleteID=${atheleteId}&asmId=${asmId}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });;
  }

  getcardioByAsm(atheleteId: any, asmId: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getcardioByAsm}?athleteId=${atheleteId}&assessmentId=${asmId}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });;
  }

  getinjuryPeventionByAsm(atheleteId: any, asmId: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getinjurypreventionByAsm}?athleteId=${atheleteId}&assessmentId=${asmId}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });;
  }

  getsportPerformanceByAsm(atheleteId: any, asmId: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getSportsPerformaceByAsm}?AthleteID=${atheleteId}&asmId=${asmId}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });;
  }

  deleteAssessmentById(asmId: number): Observable<any> {
    const url = `${environment.BaseUrl}${environment.deleteAssessment}?asmId=${asmId}`;
    console.log({ asmId }, 'active');
    return this.http.put(url, {}, { headers: this.getAuthHeaders() });
  }

  getAllPhysicalFitnessByAssessment(): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getAllPhysicalFitnessByAssessment}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });;
  }

  getAllNutritionalByAssessment(): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getAllNutritionalByAssessment}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });;
  }

  getallcognitiveByassessment(): Observable<any> {

    const url = `${environment.BaseUrl}${environment.getAllCognitiveByAssessment}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });;
  }

  getallinjurypreventionByAsm(Id: any): Observable<any> {
    console.log(Id)
    const url = `${environment.BaseUrl}${environment.getallinjurypreventionByAsm}${Id}`
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });;
  }
  getAllSportAnalysisByAssessment(): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getAllSportAnalysisByAssessment}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });;
  }

  getInterNationalBenchMark(gender: any, sport?: any): Observable<any> {
    if(sport){
      const url = `${environment.BaseUrl}${environment.getInterNationalBenchMark}?&gender=${gender}&sport=${sport}`;
      return this.http.get<any>(url, { headers: this.getAuthHeaders() });
    }else{
      return EMPTY;
    }
  }


  getIBMReference(ageStatus:any,gender: any, sport?: any): Observable<any> {
    if(sport){
      const url = `${environment.BaseUrl}${environment.getIBMReference}?status=${ageStatus}&gender=${gender}&sport=${sport}`;
      return this.http.get<any>(url, { headers: this.getAuthHeaders() });
    }else{
      return EMPTY;
    }
  }

  getAssessor(): Observable<any> {
    return this.http.get<any>(environment.BaseUrl + environment.getAssessor, { headers: this.getAuthHeaders() });
  }

  playerUpload(playerForm: any): Observable<any> {
    return this.http.post<any>(
      `${environment.BaseUrl}${environment.RegisterUploaded}`,
      playerForm,
      { headers: this.getAuthHeaders() }
    );
  }
  registerUpload(playerForm: any): Observable<any> {
    return this.http.post<any>(
      `${environment.BaseUrl}${environment.RegistrationUploaded}`,
      playerForm,
      { headers: this.getAuthHeaders() }
    );
  }
  updateAssessment(asmId: number, asmName: string, asmDescription: string, asmDate: any, asmUpdatedBy: number): Observable<any> {
    const url = `${environment.BaseUrl}${environment.updateAssessment}?asmId=${asmId}&asmName=${asmName}&asmDescription=${asmDescription}&asmDate=${asmDate}&asmUpdatedBy=${asmUpdatedBy}`;
    return this.http.post(url, {}, { headers: this.getAuthHeaders() });
  }
  imageupload(image: any): Observable<any> {
    return this.http.post<any>(
      `${environment.BaseUrl}${environment.uploadImageUrl}`,
      image,
      { headers: this.getAuthHeaders() }
    );
  }
  getTestByAssessment(Id: any): Observable<any> {
    console.log(Id)
    const url = `${environment.BaseUrl}${environment.getTestByAssessment}${Id}`
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });;
  }

  getPlayerAssessmentByAtheleteId(id: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getPlayerAssessmentByAtheleteId}${id}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }
  getNutritionalByTest(asmId: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getNutritionalByTest}${asmId}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }
  getFitnessByTest(asmId: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getFitnessByTest}${asmId}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }
  getCardioByTest(asmId: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getCardioByTest}${asmId}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }
  getSportsPerformanceByTest(asmId: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getSportsPerformanceByTest}${asmId}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }
  getInjuryByTest(asmId: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getInjuryByTest}${asmId}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }
  getCognitiveByTest(asmId: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getCognitiveByTest}${asmId}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }
  getNutritionalByTestAssessor(asmId: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getNutritionalByTestAssessor}${asmId}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }
  getFitnessByTestAssessor(asmId: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getFitnessByTestAssessor}${asmId}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }
  getCardioByTestAssessor(asmId: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getCardioByTestAssessor}${asmId}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }
  getSportsPerformanceByTestAssessor(asmId: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getSportsPerformanceByTestAssessor}${asmId}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }
  getInjuryByTestAssessor(asmId: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getInjuryByTestAssessor}${asmId}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }
  getCognitiveByTestAssessor(asmId: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getCognitiveByTestAssessor}${asmId}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }
  getLevelOneByTest(asmId: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getLevelOneByTest}${asmId}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }
  getLevelOneByTestAssessor(asmId: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getLevelOneByTestAssessor}${asmId}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }
  getOverallPlayerData(athleteId: any): Observable<any>{
    const url = `${environment.BaseUrl}${environment.getOverallPlayerDetails}${athleteId}`;
    return this.http.get<any>(url, { headers: this.getAuthHeaders() });
  }
}