import { CommonModule, DatePipe} from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink, RouterOutlet } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { NotificationService } from '../../../services/Notifications.service';
import { NgToastModule } from 'ng-angular-popup';
import { AssessmentService } from '../../../services/assessment.service';
import { MultiSelectDropdownComponent } from "../../multi-select-dropdown/multi-select-dropdown.component";
import { DomSanitizer } from '@angular/platform-browser';
import { catchError, map, Observable, of } from 'rxjs';
import { ChatService } from '../../../services/chat.service';
interface Option {
  id: string;
  label: string;
  selected: boolean;
  value?: string; 
}
@Component({
  selector: 'app-levelone-form',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterOutlet, RouterLink, HttpClientModule, NgToastModule, MultiSelectDropdownComponent],
  templateUrl: './levelone-form.component.html',
  styleUrl: './levelone-form.component.css'
})
export class LeveloneFormComponent {
  @Input() label: string = '';
  Sports: any[] = [];
  userid:any;
  checkAge: boolean = false;
  message: string = '';
  chatHistory: string[] = [];
  selectedFile: File | null = null;
  options: Option[] = [
    { id: 'Height (Cm)', label: 'Height (Cm)', selected: false },
    { id: 'Weight (Kg)', label: 'Weight (Kg)', selected: false },
    { id: 'Ruler Drop (Cm)', label: 'Ruler Drop (Cm)', selected: false },
    { id: 'Hand Grip (Kg)', label: 'Hand Grip (Kg)', selected: false },
    { id: 'Sit & Reach (Cm)', label: 'Sit & Reach (Cm)', selected: false },
    { id: 'Med Ball Throw (meters)', label: 'Med Ball Throw (meters)', selected: false },
    { id: 'Vertical Jump (cm)', label: 'Vertical Jump (cm)', selected: false },
    { id: 'Wall Toss', label: 'Wall Toss', selected: false },
    { id: '30m Sprint (Sec)', label: '30m Sprint (Sec)', selected: false },
    { id: '6*10m Shuttle Run (Sec)', label: '6*10m Shuttle Run (Sec)', selected: false },
    { id: '800m Run (Min)', label: '800m Run (Min)', selected: false },
  ];
  loading: boolean = false;
  ImageUrl: any;
  showImage: boolean = false;
  constructor(private notify:NotificationService,private route: ActivatedRoute,private router: Router,private assessmentService:AssessmentService,private sanitizer: DomSanitizer,private chatService: ChatService){
    this.route.queryParams.subscribe(params => {
      this.userid = params['id'];
    });
    this.FetchAthelete();
  }
  formSubmitted: boolean = false;
  levelone : any = {
    loaId:0,
    loaAthlete:'',
    sportName:'',
    loaHeight:null,
    loaWeight:null,
    loaRulerDrop:null,
    loaHandGrip:null,
    loaSeatedMedBall:null,
    loaSitnReach:null,
    loaVerticalJump:null,
    loaWallToss:null,
    loa30mSprint:null,
    loa6x10ShuttleRun:null,
    loa800mRun:null,
    loaAsessmentDate:''
  }
  ngOnInit(){
  this.assessmentService.getSport().subscribe(
    (data: any[]) => {
      this.Sports = data;
    },
    (error: any) => {
      console.error('Error fetching coaches:', error);
    }
  );

 }
  onSubmit(): void {
      this.assessmentService.addLevelOneDetail([this.levelone]).subscribe(response =>{
        console.log('Assessment submitted', response);
        this.formSubmitted = true;
        this.notify.success("Form submitted successfully");
        setTimeout(() => {
          this.router.navigate(['levelonelist']);
        }, 1000);
      }, error => {
        console.error('Error submitting assessment', error);
 
      });
  }
  FetchAthelete(){
    if(this.userid != null || this.userid != 'undefined'){
    this.assessmentService.getLevelOneListById(this.userid).subscribe((response: any) => {
      if (response && response.length > 0) {
        console.log(response,'data');
        const data = response[0];
        this.levelone.loaId = data.loaId;
        this.levelone.loaName = data.loaName;
        this.levelone.loaAthlete = data.loaAthlete;
        this.levelone.loaRegisterNumber = data.loaRegisterNumber;
        this.levelone.aggAge = data.aggAge;
        this.levelone.sportName = data.sportName;
        this.levelone.aggGender = data.aggGender;
        this.levelone.genderName = data.genderName;
        this.levelone.loaHeight = data.loaHeight;
        this.levelone.loaWeight = data.loaWeight;
        this.levelone.loaRulerDrop = data.loaRulerDrop;
        this.levelone.loaHandGrip = data.loaHandGrip;
        this.levelone.loaSeatedMedBall = data.loaSeatedMedBall;
        this.levelone.loaSitnReach = data.loaSitnReach;
        this.levelone.loaVerticalJump = data.loaVerticalJump;
        this.levelone.loaWallToss = data.loaWallToss;
        this.levelone.loa30mSprint = data.loa30mSprint;
        this.levelone.loa6x10ShuttleRun = data.loa6x10ShuttleRun;
        this.levelone.loa800mRun = data.loa800mRun;
        this.levelone.loaAsessmentDate = data.loaAsessmentDate ? data.loaAsessmentDate.split('T')[0] : '';
      }
    },
    (error: any) => {
      console.error('Error fetching assessment data', error);
    }
  );
}
  }
  validateAge() {
    const AgeValue =this.levelone.aggAge;
    this.checkAge = AgeValue < 10 || AgeValue > 16;
  }
  validateUsrId(event: Event) {
    this.assessmentService.getLevelOneBySport(this.levelone.loaAthlete,this.levelone.sportName,this.levelone.loaAsessmentDate).subscribe(
      (response: any) => {
        if (response) {
        this.loading = false;
        console.log(response,'user data')
        const data = response[0];
        this.levelone.loaId = data.loaId;
        this.levelone.loaName = data.loaName;
        this.levelone.loaRegisterNumber = data.loaRegisterNumber;
        this.levelone.aggAge = data.aggAge;
        this.levelone.sportName = data.sportName;
        this.levelone.aggGender = data.aggGender;
        this.levelone.genderName = data.genderName;
        this.levelone.loaHeight = data.loaHeight;
        this.levelone.loaWeight = data.loaWeight;
        this.levelone.loaRulerDrop = data.loaRulerDrop;
        this.levelone.loaHandGrip = data.loaHandGrip;
        this.levelone.loaSeatedMedBall = data.loaSeatedMedBall;
        this.levelone.loaSitnReach = data.loaSitnReach;
        this.levelone.loaVerticalJump = data.loaVerticalJump;
        this.levelone.loaWallToss = data.loaWallToss;
        this.levelone.loa30mSprint = data.loa30mSprint;
        this.levelone.loa6x10ShuttleRun = data.loa6x10ShuttleRun;
        this.levelone.loa800mRun = data.loa800mRun;
        this.levelone.loaAsessmentDate = data.loaAsessmentDate ? data.loaAsessmentDate.split('T')[0] : '';
        } else {
        }
      },
      (error) => {
        this.loading = false;
        console.error('Error fetching data', error);
      }
    );
  }
  getProfileImage() {
    this.ImageUrl = null;
    this.showImage = false;
  
    this.assessmentService.getImage(this.levelone.loaAthlete).subscribe(
      (imageBlob) => {
        if (imageBlob) {
          const objectURL = URL.createObjectURL(imageBlob);
          this.ImageUrl = this.sanitizer.bypassSecurityTrustUrl(objectURL);
          this.showImage = true; 
          console.log(this.ImageUrl, "ImageUrl");
        } else {
          this.notify.failed("invalid athlete number.");
        }
      },
      (error) => {
        this.ImageUrl = null;
        this.showImage = false;
        this.notify.failed("invalid athlete number");
        console.error('Error fetching image', error);
      }
    );
  }
  
  sendMessage() {
    if (this.message.trim()) {
      this.chatHistory.push(`You: ${this.message}`);

      // Check if a file has been selected
      if (this.selectedFile) {
        // Send message and file together
        this.chatService.sendMessageWithFile(this.message, this.selectedFile).subscribe(response => {
          const botMessage = response.choices[0]?.message?.content;
          if (botMessage) {
            this.chatHistory.push(`Bot: ${botMessage}`);
          }
        });
      } else {
        // Only send the message if no file is selected
        this.chatService.getChatResponse(this.message).subscribe(response => {
          const botMessage = response.choices[0]?.message?.content;
          if (botMessage) {
            this.chatHistory.push(`Bot: ${botMessage}`);
          }
        });
      }

      this.message = '';
      this.selectedFile = null; // Reset the file after sending
    }
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      this.selectedFile = file; // Store the selected file
    }
  }
}