import { CommonModule } from '@angular/common';
import { Component, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { AgGridAngular } from "ag-grid-angular";
import { ColDef } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { Router, RouterLink, RouterModule, RouterOutlet } from "@angular/router";
import { AssessmentService } from '../../../services/assessment.service';
import { PrivilegeService } from '../../../services/privilage.service';
import { Privilege } from '../../../model/Privillage';
import { UserService } from '../../../services/user.service';
import { User } from '../../../model/User';
import { SharedService } from '../../../services/shared.service';
import { FileuploadComponent } from '../../fileupload/fileupload.component';

// Row Data Interface
interface IRow {
  playername: string;
  Assessor: string;
  Result: string;
  Recommendation: string;
  date:string;
}

@Component({
  selector: 'app-biomechanics-physiology-list',
  standalone: true,
  imports:  [CommonModule, RouterOutlet,AgGridAngular,RouterLink,RouterModule,FileuploadComponent],
  templateUrl: './biomechanics-physiology-list.component.html',
  styleUrl: './biomechanics-physiology-list.component.css'
})
export class BiomechanicsPhysiologyListComponent implements OnInit {
  @ViewChild('fileUploadModal') fileUploadModal: FileuploadComponent | undefined;
  @Input() name: any;
  user: User | null = null;
  isAdmin: boolean = false; 
  isPlayer: boolean = false;
  privilege: Privilege;
  Isplayer: boolean;
  Admin: boolean;
  detail: any;
  org: any;

  constructor(private ngZone: NgZone,private sharedService: SharedService,private router: Router,private assessmentService: AssessmentService,private priService:PrivilegeService,private userService:UserService) {
    this.privilege = this.priService.getPrivileges();
    this.Isplayer = this.privilege.isPlayer;
    this.Admin = this.privilege.isAdmin;
  }

  themeClass = "ag-theme-quartz";

  rowData: IRow[] = [];
  colDefs: ColDef[] = [];

  defaultColDef: ColDef = {
    flex: 1,
    sortable: true,
    filter: true
  };

  ngOnInit() {
    this.sharedService.detail$.subscribe((data: any) => {
      this.detail = data;
      this.org=this.detail.user.usrOrganization;
    });
    this.user = this.userService.getUser();
 
    this.assessmentService.getBiomechanical(this.org).subscribe(response => {
      console.log('Assessment submitted', response);
 
      this.rowData = response.map((item:any) => ({
        Id: `${item.bmaId}`,
        playerId:`${item.bmaAthlete}`,
        playername: `${item.athleteName}`,
        Assessor: `Assessor ${item.assessorName}`,
        Result: `${item.bmaInterpreationResults}`,
        Recommendation: `${item.bmaRecommendations}`,
        date: new Date(item.bmaDate).toLocaleDateString(),
        priority: this.getPriority(item)
      }));
 
      this.colDefs = this.getColumnDefinitions();
    }, error => {
      console.error('Error submitting assessment', error);
    });
 
   
  }
 
  getPriority(item: any): string {
    if (item.pfaSpeed > 0 || item.pfaAgility > 0) {
      return 'High';
    } else {
      return 'Low';
    }
  }

  getColumnDefinitions(): ColDef[] {
    const columns: ColDef[] = [
      { field: "playername" }, 
      { field: "Assessor" },
      { field: "Result" },
      { field: "Recommendation" },
      { field: "date" },
      // { field: "priority",
      //   cellStyle: params => {
      //     // Change color based on the priority value
      //     if (params.value === 'Low') {
      //       return { color: 'red' };
      //     } else {
      //       return { color: 'green' };
      //     }
      //   }
      // },
      {
        headerName: 'Action',
        cellRenderer: (_params: any) => {
          const container = document.createElement('div');
          container.style.display = 'flex';
          container.style.justifyContent = 'space-around';
 
          // View button with eye icon
          const viewButton = document.createElement('button');
          viewButton.classList.add('btn');
          viewButton.innerHTML = '<i class="fa fa-eye"></i>';
          viewButton.addEventListener('click', () => {
            this.ngZone.run(() => {
            if (this.isPlayer) {
              this.ngZone.run(() => {
              this.router.navigate(['/biomachanicsdetail'])
              })
            } else {
              this.ngZone.run(() => {
              this.router.navigate(['/biomechanicsdetaillist'], { queryParams: { id: _params.data.playerId } })
              })
            }
          })
          });
 
          // Edit button with edit icon
          const editButton = document.createElement('button');
          editButton.classList.add('btn');
          editButton.innerHTML = '<i class="fa fa-edit"></i>';
          editButton.addEventListener('click', () => {
            this.ngZone.run(() => {
            this.router.navigate(['/biomachanics'], {queryParams: { id: _params.data.Id } })
            })
          });
          container.appendChild(viewButton);
          if (!this.Isplayer) {
          container.appendChild(editButton);
          }
          // container.appendChild(deleteButton);
 
          return container;
        },
        width: 120
      }
    ];

    if (this.isAdmin) {
      return columns;
    } else if (this.isPlayer) {
      return columns.filter(col => col.field !== 'playername');
    } else {
      return columns.filter(col => col.field !== 'Assessor');
    }
  }
  openModal(): void {
    // Use Bootstrap's modal function to open the modal
    const modalElement = document.getElementById('fileUploadModal')!;
    const modal = new (window as any).bootstrap.Modal(modalElement);
    modal.show();
  }
}
