import { ColDef } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { Component, NgZone } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ActivatedRoute, Router, RouterLink, RouterModule, RouterOutlet } from "@angular/router";
import { NgToastModule } from "ng-angular-popup";
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { AgGridAngular } from "ag-grid-angular";
import { AssessmentService } from "../../../services/assessment.service";
import { UserRegisterService } from "../../../services/user-register.service";

interface IRow {
  OrgName: string;
  TeamName: string;
  CoachName: string;
  SportName: string;
}

@Component({
  selector: 'app-team',
  standalone: true,
  imports: [CommonModule, RouterOutlet, AgGridAngular, RouterLink, RouterModule, FormsModule, NgToastModule, NgSelectModule],
  templateUrl: './team.component.html',
  styleUrl: './team.component.css'
})
export class TeamComponent {
  rowData: IRow[] = [];
  colDefs: ColDef[] = [];
  selectedOrgId: string | null = null;
  defaultColDef: ColDef = {
    flex: 1,
    sortable: true,
    filter: true,
  };

  Trainer: any[] = [];
  statusOptions: any[] = [];
  statusPlayer: any[] = [];
  Orgainsation: any[] = [];
  gridApi: any;
  selectedOrgFilter:any;
  orgid:any;

  constructor(
    private assessmentService: AssessmentService,
    private userRegisterService: UserRegisterService,
    private route: ActivatedRoute,
    private ngZone: NgZone,
    private router: Router
  ) {
    this.route.queryParams.subscribe((params:any) => {
      this.selectedOrgFilter = params['id'];
      this.orgid = params['orgId'];
    

    });
  }

  ngOnInit() {
    this.loadOrgainsation();
    this.colDefs = this.getColumnDefinitions(); // Initialize column definitions
  }

  // Called when the organization dropdown value changes
  onOrganizationChange() {
    this.selectedOrgId = this.selectedOrgId || this.Orgainsation[0]?.orgId;  // Ensure a valid selection
    this.loadOrgainsationUsers();
  }

  // Loads organizations and sets the default selected organization
  loadOrgainsation() {
    this.assessmentService.getOrgainsation().subscribe(
      (data: any) => {
        this.Orgainsation = data;
        if (this.Orgainsation.length > 0) {
          this.selectedOrgId = this.selectedOrgFilter ||this.orgid || this.Orgainsation[0].orgId;
          this.loadOrgainsationUsers(); // Load grid data after setting the default organization
        }
      },
      (error: any) => {
        console.error('Error fetching Organization:', error);
      }
    );
  }

  // Loads organization-related users and updates the grid
  loadOrgainsationUsers() {
    const orgId = this.selectedOrgId;

    // Clear existing data before loading new data
    this.Trainer = [];
    this.statusOptions = [];
    this.statusPlayer = [];
    this.rowData =[];
    this.userRegisterService.getOrgTeams(orgId).subscribe(      
      (response: any) => {
        this.rowData = response.map((item: any) => {
          return {
            TeamId:`${item.teamId}`,
            TeamName: `${item.teamName}`,
            SportName: `${item.sportName}`,
            CoachName: `${ item.usrFullName}`,
            SportId: `${ item.teamSport}`,
            CoachId: `${item.teamCoach}`,
            TeamOrganization: `${item.teamOrganization}`
          };
        });

        this.updateGridData();
      },
      (error: any) => {
        console.error('Error fetching organization teams:', error);
      }
    );
  }

  // Method to update the grid with the new data
  updateGridData() {
    if (this.gridApi) {
      if (this.rowData.length === 0) {
        this.gridApi.showNoRowsOverlay();
      } else {
        this.gridApi.applyTransaction({ add: this.rowData }); // Use applyTransaction to add the new row data
        this.gridApi.hideOverlay();
      }
    }
  }

  getOrgNameById(orgId: any): any | undefined {
    const org = this.Orgainsation.find(o => o.orgId === orgId);
    return org ? org.orgName : undefined; // Return the orgName if found
  }
  // Sets the column definitions for the AG Grid
  getColumnDefinitions(): ColDef[] {
    return [
      { field: "TeamName", headerName: "Team Name" },
      { field: "SportName", headerName: "Sport Name" },
      { field: "CoachName", headerName: "Coach Name" },
      {
        headerName: 'Action',
        cellRenderer: (_params: any) => {
          const container = document.createElement('div');
          container.style.display = 'flex';
          container.style.justifyContent = 'space-around';
  
          const viewButton = document.createElement('button');
          viewButton.classList.add('btn');
          viewButton.innerHTML = '<i class="fa fa-eye"></i>';
          viewButton.title = "View";
          viewButton.addEventListener('click', () => {
            this.ngZone.run(() => {
              const orgName = this.getOrgNameById(this.selectedOrgId);
              this.router.navigate(['/team-player'], {
                queryParams: { coachName: _params.data.CoachName , teamId: _params.data.TeamId, orgName: orgName, orgId: _params.data.TeamOrganization , teamName: _params.data.TeamName },
              });
            });
          });


          const editButton = document.createElement('button');
          editButton.classList.add('btn');
          editButton.innerHTML = '<i class="fa fa-edit"></i>';
          editButton.addEventListener('click', () => {
            this.ngZone.run(() => {
            this.router.navigate(['/edit-team'], { 
              queryParams: {
                teamId: _params.data.TeamId,
                teamName: _params.data.TeamName,
                teamOrganization: _params.data.TeamOrganization,
                SportId: _params.data.SportId,
              } })
            })
          });
           
          container.appendChild(viewButton);
          container.appendChild(editButton);
          return container;
        },
        width: 120
      }
    ];
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    params.api.sizeColumnsToFit(); // Auto-resize columns
  }
}
