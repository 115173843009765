import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { AssessmentService } from '../../../services/assessment.service';
import { FormsModule } from '@angular/forms';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { NgToastModule } from 'ng-angular-popup';
import { NotificationService } from '../../../services/Notifications.service';
import { SharedService } from '../../../services/shared.service';



@Component({
  selector: 'app-video-analysis-form',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterOutlet, RouterLink, HttpClientModule,NgToastModule],
  templateUrl: './video-analysis-form.component.html',
  styleUrl: './video-analysis-form.component.css'
})
export class VideoAnalysisFormComponent {

  videoUrl: string | ArrayBuffer | null | undefined = null;
  base64String: string | ArrayBuffer | null = null;
  formSubmitted: boolean = false;
  selectedFiles: File[] = [];
  assessment: any = { 
    vuUploader: '',
    vuVideo: null,
    vuTitle: null,
    vuDate: null,
    vuSportActivity: null,
    vuTechniqueAnalysis: null,
    vuPerformanceAnalysis: null,

  };
  detail: any;
  UserId: any;

  constructor(private router: Router, private sharedService: SharedService, private assessmentService: AssessmentService,private notify:NotificationService) {}
  ngOnInit() {
    this.sharedService.detail$.subscribe((data: any) => {
      this.detail = data;
      this.UserId=this.detail.user.usrId;
    });
  }
  
  onSubmit(): void {
    this.assessment.vuUploader = this.UserId;
    if (this.isFormValid()) {
    this.assessmentService.addvideoAnalysis([this.assessment]).subscribe(response => {
      console.log('Assessment submitted', response);
      this.notify.success("data saved successfully");  
      setTimeout(() => {
         this.router.navigate(['videoanalysislist']);
      }, 1000);      
    }, error => {
      console.error('Error submitting assessment', error);
    });
  }
}

isFormValid(): boolean {
  return Object.keys(this.assessment).every(key => {
    return this.assessment[key] !== '' && this.assessment[key] !== null;
  });
}


onFileSelected(event: Event): void {
  const input = event.target as HTMLInputElement;
  if (input.files && input.files.length > 0) {
    const file = input.files[0];
    this.previewVideo(file);
    this.convertToBase64(file);
  }
}

previewVideo(file: File): void {
  const reader = new FileReader();
  reader.onload = (e: ProgressEvent<FileReader>) => {
    this.videoUrl = e.target?.result;
  };
  reader.readAsDataURL(file);
}

private convertToBase64(file: File): void {
  const reader = new FileReader();
  
  reader.onload = () => {
    const base64String = (reader.result as string).split(',')[1];

    this.assessment.vuVideo = base64String;
  };
  
  reader.readAsDataURL(file);
}

}
