<div class="container" [ngClass]="{'secActive': formSubmitted}">
    <header>Cardiology</header><br>
    <form (ngSubmit)="onSubmit()" #form="ngForm">
        <div class="form first">
            <div class="details personal">
                <span class="title">Player Details</span>
                <img *ngIf="showImage && ImageUrl"  src="{{this.ImageUrl.changingThisBreaksApplicationSecurity}}">
                <div class="fields">
                    <div class="input-field">
                        <label>Player ID</label>
                        <input type="number" [(ngModel)]="assessment.coaAthlete" name="coaAthlete" (blur)="getProfileImage()" placeholder="Enter player id" required>
                    </div>
                  
                </div>
            </div>
            <div class="details">
                <span class="title">Cardiovascular Endurance</span>
                <div class="fields">
                    
                    <div class="input-field">
                        <label>800 m Run <span>(Sec)</span></label>
                        <input type="number" [(ngModel)]="assessment.coa800mRun" name="coa800mRun" placeholder="Enter 800 m Run" required>
                    </div>
                    <div class="input-field">
                        <label>Yo-Yo Test <span>(m)</span></label>
                        <input type="number" [(ngModel)]="assessment.coaYoYoTest" name="coaYoYoTest" placeholder="Enter Yo-Yo Test" required>
                    </div>

                    <div class="input-field">
                        <label>Rast (6 BOx) <span>(Sec)</span></label>
                        <input type="number" [(ngModel)]="assessment.coaRAST" name="coaRAST" placeholder="Enter Rast (6 BOx)" required>
                    </div>

                    <div class="input-field">
                        <label>Fatigue Index <span>(Sec)</span></label>
                        <input type="number" [(ngModel)]="assessment.coaFatigueIndex" name="coaFatigueIndex" placeholder="Enter Fatigue Index" required>
                    </div>
                    
                </div>
                </div>
                    <div class="button">
                        <button type="submit" class="submit">
                          <span class="btnText">Submit</span>
                          <i class="uil uil-navigator"></i>
                        </button>
                      </div>
        </div>
     
    </form>
       
  </div>
   

  <div class="toaster">
    <ng-toast />        
   </div>
   
