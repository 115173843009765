
<div id="pdf-content">
  <div class="body-wrapper">
    <div>
    <button class="btn btn-primary mb-2" (click)="onBack()">Back</button>
  </div>
    <img  src="{{image}}" >
    <div class="container-fluid">
      <div class="row row-cards">
        <div class="col-6 col-sm-6 col-lg-2" *ngIf="(usrRole === 12) || (usrRole === 2)|| (usrRole === 1) ||(usrRole == 5)">
          <div class="card1">
            <div class="card-body1 p-3 text-center">
              <div class="text-muted mb-2">Full Name</div>
              <div class="Title">{{this.player.usrFullName}}</div>
            </div>
          </div>
        </div>
        <div class="col-6 col-sm-6 col-lg-2">
          <div class="card1">
            <div class="card-body1 p-3 text-center">
              <div class="text-muted mb-2">Register Id</div>
              <div class="Title">{{player.loaAthlete}}</div>
 
            </div>
          </div>
        </div>
        <div class="col-6 col-sm-6 col-lg-1">
          <div class="card1">
            <div class="card-body1 p-3 text-center">
              <div class="text-muted mb-2">Age</div>
              <div class="Title">{{player.aggAge}}</div>
            </div>
          </div>
        </div>
        <div class="col-6 col-sm-6 col-lg-2">
          <div class="card1">
            <div class="card-body1 p-3 text-center">
              <div class="text-muted mb-2">Sport</div>
              <div class="Title">{{player.sportName}}</div>
 
            </div>
          </div>
        </div>
        <div class="col-6 col-sm-6 col-lg-3">
          <div class="card1">
            <div class="card-body1 p-3 text-center">
              <div class="text-muted mb-2">Assessment Date</div>
              <div class="Title">{{this.assessment}}</div>
            </div>
          </div>
        </div>
        <div class="col-6 col-sm-6 col-lg-2">
          <div class="card1">
            <div class="card-body1 p-3 text-center">
              <div class="text-muted mb-2">Gender</div>
              <div class="Title">{{player.genderName}}</div>
 
            </div>
          </div>
        </div>
 
      </div>
      <div class="row">

        <div class="col-xl-5 grid-margin stretch-card flex-column mt-4" style="height: 350px;">
          <div class="row h-100">
            <div class="col-md-12 stretch-card">
              <div class="card z-index-2">
                <div class="card-body p-3 d-flex flex-column justify-content-between h-100">
                  <div class="bg-gradient-dark border-radius-lg p-3 mb-3 d-flex flex-column justify-content-center align-items-center" style="height: 300px;">
                    <h4 class="Title-white text-center">OVERALL SCORE</h4>
        
                    <div class="circular-progress mb-2" style=" display: flex; justify-content: center; align-items: center; height: 100%; width: 100%;">
                      <svg width="100%" height="100%" viewBox="0 0 100 100" class="progress-circle">
                        <circle class="background" cx="50" cy="50" r="45" stroke="#e6e6e6" stroke-width="8" fill="none"></circle>
                        <circle class="circle" cx="50" cy="50" r="45" stroke="#ff5521" stroke-width="8" fill="none"
                          [attr.stroke-dasharray]="282.74"
                          [attr.stroke-dashoffset]="282.74 - (player.loaAverageRange / 100 * 282.74)"
                          stroke-linecap="round">
                        </circle>
                      </svg>
        
                      <div class="progress-value">
                        <div class="range">{{ player.loaAverageRange }} %</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
 
        <div class="col-xl-7 grid-margin stretch-card flex-column mt-4">
          <div class="row">
            <!-- Loop through the data -->
            <div class="col-md-6 grid-margin stretch-card" *ngFor="let item of rowData">
              <div class="card">
                <div class="card-body d-flex flex-column">
                  <div class="d-flex justify-content-between align-items-center mb-2">
                    <p class="mb-0 text-muted">{{ item.SportName }}</p>
                  </div>
                  <div class="progress1">
                    <div class="progress-bar1"
                      [style.width.%]="item.RangePercentage === 100 ? item.RangePercentage - 2 : item.RangePercentage">
                      <div class="progress-value1">{{ item.RangePercentage }}%</div>
                    </div>
                  </div>

                  <div class="mt-2 text-center">
                    <strong [ngClass]="getTextColorClass(item.RangePercentage)">
                      {{ getProgressText(item.RangePercentage) }}
                    </strong>
                  </div>

                </div>
 
              </div>
            </div>
          </div>
        </div>
 
      </div>
    </div>
  </div>
</div>
<div class="btn-class mb-3">
  <button type="button" class="accept" (click)="downloadPDF()">Download</button>
</div>