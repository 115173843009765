import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Component, NgModule, NgZone } from '@angular/core';
import { ActivatedRoute, Router, RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { User } from '../../../model/User';
import { PrivilegeEnum } from '../../../model/PrivilageEnum';
import { AssessmentService } from '../../../services/assessment.service';
import { SharedService } from '../../../services/shared.service';
import { PrivilegeService } from '../../../services/privilage.service';
import { Privilege } from '../../../model/Privillage';
import { Location } from '@angular/common';
interface IRow {}

@Component({
  selector: 'app-saihealth-fitness-detail',
  standalone: true,
  imports: [CommonModule, RouterLink, RouterModule, HttpClientModule, AgGridAngular],
  templateUrl: './saihealth-fitness-detail.component.html',
  styleUrl: './saihealth-fitness-detail.component.css'
})
export class SAIHealthFitnessDetailComponent {
  Response: any;
  detail: any;
  Id: any;
  Status: any;
  isTableVisible: boolean = false;
  coachData: any;
  public asmId: any;
  public testNames: any;
  public assesserName: any;
  public CoachName: any;
  public teamName: any;
  public created: any;
  public CoachId: any;
  assessments: any;
  user: User | null = null;
  groupedData: { [key: string]: any[] } = {};

  themeClass = 'ag-theme-quartz';
  rowData: IRow[] = [];
  colDefs: ColDef[] = [];
  ramPrivilege: PrivilegeEnum | undefined;
  defaultColDef: ColDef = {
    flex: 1,
    sortable: true,
    filter: true,
  };
  Isplayer: boolean | undefined;
  Admin: boolean | undefined;
  IsCoach:boolean=false;
  org: any;
  role: any;
  category: any;
  test: any;
  selectdata: any;
  assessor: any;
  privilege: Privilege;
  OrgAdmin: boolean;
  ScienceStaff: boolean;
  isLoading:boolean= false;
  status: any;
  constructor(
    private assessmentService: AssessmentService,
    private sharedService: SharedService,private location: Location,
    private ngZone: NgZone, private priService:PrivilegeService,
    private router: Router ,private route: ActivatedRoute
  ) {
    this.privilege = this.priService.getPrivileges();
    this.Isplayer = this.privilege.isPlayer;
    this.Admin = this.privilege.isAdmin;
    this.IsCoach = this.privilege.isCoach;
    this.OrgAdmin = this.privilege?.isOrgAdmin ?? false;
    this.ScienceStaff= this.privilege?. isScienceStaff;
    this.route.queryParams.subscribe(params => {
      this.asmId = params['asmId'];
      this.category = params['category'];
      this.assessor = params ['assessor'];
      this.status = params['selectStatus'];
 
    });
  }

  ngOnInit() {
    this.sharedService.detail$.subscribe((data: any) => {
      this.detail = data;
      this.Id = this.detail.user.usrId;
      this.role=this.detail.user.usrRole;
      this.Status = 0;
    });

    this.isLoading=true;
    this.getPendingAssessment();
    this.assessmentService.getPlayerByAssessmentId(this.asmId).subscribe(response => {
      console.log('Assessment submitted', response);
      this.selectdata =this.Admin || this.OrgAdmin || this.ScienceStaff? response.filter((assessment: any) => assessment.aplCategory == this.category):response.filter((assessment: any) => assessment.aplCategory == this.category && assessment.apIAssesser==this.Id);
      const uniqueAssessments = new Map<number, any>();
      this.selectdata.forEach((item: any) => {
        // Only add the item if it does not exist in the map
        if (!uniqueAssessments.has(item.aplPlayer)) {
          // Initialize the player entry with the item values
          uniqueAssessments.set(item.aplPlayer, {
              "Player Name": `${item.usrFullName}`,
              PlayerId: `${item.aplPlayer}`,
              teamId: `${item.teamId}`,
              id: `${item.aplAssessment}`,
              "Email": `${item.usrEmail}`,
              "Phone No": `${item.usrPhoneNo}`,
              "status": `${item.aplStatus}`,  // Initialize status
              "DOB": new Date(item.usrDoB).toLocaleDateString('en-US'),
          });
      } else {
          // If the player already exists, update the status if any item has status 0
          const existingEntry = uniqueAssessments.get(item.aplPlayer);
          existingEntry.status = existingEntry.status === '1' && item.aplStatus === 1 ? '1' : '0';
      }
      });
      this.rowData = Array.from(uniqueAssessments.values());
  
      
 
      this.colDefs = this.getColumnDefinitions();
    }, error => {
      console.error('Error submitting assessment', error);
    });
  }

  getPendingAssessment() {

      this.assessmentService.getAssessmentTest(this.asmId).subscribe(
        (response) => {
          this.test=response;
          this.groupDataBySsName();
          console.log(response,"test")
          const uniqueAssessments = new Map<number, any>();
        },
        (error) => {
          console.error('Error retrieving pending assessments', error);
        }
      );

  }
  groupDataBySsName() {
    this.groupedData = this.test.reduce((acc: { [x: string]: any[]; }, item: { ssName: string | number; }) => {
      // Check if the group exists, if not, create it
      if (!acc[item.ssName]) {
        acc[item.ssName] = [];
      }
      // Add the current item to the group
      acc[item.ssName].push(item);
      return acc;
    }, {});
    console.log(this.groupedData,"data");
  }

  getColumnDefinitions(): ColDef[] {
    const columns: ColDef[] = [
      { field: 'PlayerId' },
      { field: 'Player Name' },
      { field: 'Email' },
      { field: 'DOB'},
      { field: 'Phone No' },
      {
        field: 'Status',
        cellRenderer: (_params: any) => {
          const statusElement = document.createElement('span');
          if (_params.data.status == 0) {
            statusElement.textContent = 'Not Completed';
            statusElement.style.color = 'red'; // Text color red for "Not Completed"
          } else if (_params.data.status == 1) {
            statusElement.textContent = 'Completed';
            statusElement.style.color = 'green'; // Text color green for "Completed"
          }
          return statusElement;
        }
      },
      {
        headerName: 'Action',
        cellRenderer: (_params: any) => {
          const container = document.createElement('div');
          container.style.display = 'flex';
          container.style.justifyContent = 'space-around';
          // View button with eye icon
          const viewButton = document.createElement('button');
          viewButton.classList.add('btn');
          viewButton.innerHTML = '<i class="fa fa-eye"></i>';
          if (_params.data.status == 0) {
            viewButton.style.cssText = 'color: grey; cursor: not-allowed; opacity: 0.6;';
          }
          viewButton.addEventListener('click', () => {
            this.ngZone.run(() => {
              if (_params.data.status == 1) {
                this.router.navigate(['/LeveloneReport'], {
                  queryParams: {
                    // teamId: _params.data.teamId, 
                    athleteId: _params.data.PlayerId,
                    id: this.asmId,
                    selectStatus: this.status
                  }
                });
              }
            });
          });

          const editButton = document.createElement('button');
          editButton.classList.add('btn');
          editButton.innerHTML = '<i class="fa fa-edit"></i>';
          editButton.addEventListener('click', () => {
            this.ngZone.run(() => {
              this.router.navigate(['/pendingform'], {
                queryParams: {
                  teamId: _params.data.teamId,
                  athleteId: _params.data.PlayerId,
                  id: this.asmId,
                  category:this.category,
                  assessor: _params.data.assessor,
                }
              });
            });
          });

 
           container.appendChild(viewButton);
           if((this.role != 6) && (this.role != 7)){
            container.appendChild(editButton);
           }
        
          return container;
        },
        width: 120
      }
    
    ];
    this.isLoading=false;
 
    if (this.Admin || this.OrgAdmin || this.ScienceStaff) {
      return columns;
    } else if (this.Isplayer) {
      return columns.filter(col => col.field !== 'playername');
    } else if (this.IsCoach) {
      return columns;
    } else {
      return columns.filter(col =>col.field !== 'Assessor'&& col.field !== 'Player Name' && col.field !== 'Email' && col.field !== 'DOB' && col.field !== 'Phone No');
    }
  }
  onBack():void{
    this.router.navigate(['/saifitness'], {
      queryParams: {
        statusId: this.status
      }
    });
  }
}
