<div *ngIf="isLoading" class="loading-spinner">
  <div class="spinner"></div>
</div>
<div class="body-wrapper">  
    <div class="row flex-grow">
      <div class="col-12 grid-margin stretch-card">
        <div class="card card-rounded">
          <div class="card-body">
            <div class="d-sm-flex justify-content-between align-items-start">
              <div>
                <h4 class="card-title card-title-dash">SAI Health & Fitness</h4>
                <div *ngIf="!Isplayer && !Coach && !IsOrgAdmin && !IsAssessor">
                  <button class="btn btn-primary" type="button" (click)="openModal()">Upload</button>
                </div>
                <p class="card-subtitle card-subtitle-dash">Assessment report</p>
              </div>
           
              <div class="select-wrapper">
                <ng-select
                class="ng-select-custom"
                [items]="statusOptions"
                bindLabel="label"
                bindValue="value"
                [(ngModel)]="selectedStatus"
                (change)="getPendingAssessment()"
                [searchable]="false"
                [clearable]="false" >
              <ng-template ng-label-tmp let-item="item">
                <span>{{ item.label }}</span>
                <i class="fa fa-chevron-down"></i>
              </ng-template>
            </ng-select>
              </div>
              
   </div>
   <app-fileupload #fileUploadModal [name]="'SAI Health & Fitness Assessment'"></app-fileupload>
            <div class="content" style="width: 100%; height: 100%;">
                <!-- The AG Grid component, with Dimensions, CSS Theme, Row Data, and Column Definition -->
                <ag-grid-angular
                class="ag-theme-quartz"
                style="height: 500px;"
                [rowData]="rowData"
                [columnDefs]="colDefs"
                [defaultColDef]="defaultColDef"
                [pagination]="true"
              />
              </div>
   
   
   
          </div>
        </div>
      </div>
    </div>
  </div>