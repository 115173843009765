<div class="modal fade" id="fileUploadModal" tabindex="-1" aria-labelledby="fileUploadModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" style="max-width: 400px;">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="fileUploadModalLabel">{{ name }} Files Upload</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()"></button>
      </div>
      <div class="modal-body">
        <div class="mb-3" *ngIf="name === 'CSS Health & Fitness'">
          <ng-select
            class="ng-select-custom"
            [(ngModel)]="selectedAssessment"
            [items]="assessments"
            bindLabel="asmName"
            bindValue="asmId"
            placeholder="Select Assessment"
            [searchable]="true"
            (change)="onAssessmentSelect()"
          ></ng-select>
        </div>
        <div class="file-input-container">
          <input type="file" multiple (change)="onFileSelected($event)" class="form-control" />
        </div>
      </div>
      <div class="modal-footer justify-content-between">
        <button
          class="btn btn-primary"
          (click)="downloadTemplate(name)"
          [disabled]="!selectedAssessment"
        >
          Download Template
        </button>
        <button
          type="submit"
          class="btn btn-primary"
          (click)="onUpload()"
          [disabled]="!selectedFiles || selectedFiles.length === 0"
          title="Please ensure that you have chosen the correct file for the selected assessment"
        >
          <span class="btnText">Upload</span>
          <i class="uil uil-navigator"></i>
        </button>
      </div>
    </div>
  </div>
</div>
<div class="toaster">
  <ng-toast></ng-toast>
</div>
