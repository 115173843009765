import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractControl, FormsModule, NgForm } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink, RouterOutlet } from '@angular/router';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgToastModule } from 'ng-angular-popup';
import { AssessmentService } from '../../services/assessment.service';
import { UserRegisterService } from '../../services/user-register.service';
import { NotificationService } from '../../services/Notifications.service';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { RoleService } from '../../services/role.service';
 
 
@Component({
  selector: 'app-player-registration',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterOutlet, RouterLink, HttpClientModule,NgToastModule,RecaptchaModule,
    RecaptchaFormsModule],
  templateUrl: './player-registration.component.html',
  styleUrl: './player-registration.component.css'
})
 
export class PlayerRegistrationComponent implements OnInit {
  isCoach: boolean = false;
  isSupport: boolean = false;
  activeStep: number = 1;
  today: string;
  steps!: NodeListOf<Element>;
  formSteps!: NodeListOf<Element>;
  formSubmitted: boolean = false;
  captchaResponse: boolean = false;
  orgId:any;
    formData: any = {
      UsrId:0,
      usrFullName: null,
      usrDoB: null,
      // roleName: null,
      usrAddress: null,
      usrPhoneNo: null,
      usrReligion: null,
      usrCaste: null,
      usrHeight: null,
      usrWeight: null,
      usrOrganization:null,
      usrRole:1,
      // orgName:null,
      usrGender:null,
      usrEmail: null,
      usrPassword: null,
      usrECName: null,
      usrActive:null,
      usrECRelationship: null,
      usrECPhoneNo: null,
      usrClass:null,
      umhAllergies: null,
      umhCurrentMedications: null,
      umhPreviousInjuries: null,
      umhChronicConditions: null,
      umhSurgeries: null,
      umhFamilyMedicalHistory: null,
      uliSleepPatterns: null,
      uliDiet: null,
      uliHydration: null,
      uliStressLevels: null,
      asiPrimarySport: null,
      asiSecondarySport: null,
     // asiCurrentClubTeam: null,
      asiCoach: null,
      asiTrainingFrequency: null,
      asiCompetitionLevel: null,
      asiCareerHighlights: null,
      asiShortTermGoals: null,
      asiLongTermGoals: null,
      asiEvent:null,
      asiCategory:null
  };
  trainer: any[] = [];
  selectedAthleteId: string | null = null;
  Orgainsation: any[] = [];
  Trainer: any;
  isSubmitted: boolean = false;
  confirmPassword: any;
  showPassword: boolean = false;
  showConfirmPassword: boolean = false;

  constructor(private assessmentService :AssessmentService,private http: HttpClient, private route: ActivatedRoute, private userRegisterService: UserRegisterService,private roleService: RoleService, private notify:NotificationService,private router: Router) {
    this.route.queryParams.subscribe(params => {
      this.orgId = params['Org'];
    });
    const now = new Date();
    this.today = now.toISOString().split('T')[0];
  }
 
  ngOnInit(): void {
    if (this.orgId !=0) {
      this.formData.usrOrganization = this.orgId;
    }
    this.roleService.getCoachName().subscribe(response =>{
    });
    if (typeof window !== 'undefined' && typeof localStorage !== 'undefined') {
 
    const isSupport = localStorage.getItem('isSupport');
    const isCoach = localStorage.getItem('isCoach');
 
    if (isSupport === 'true') {
      this.isSupport = true;
    } else if (isCoach === 'true') {
      this.isCoach = true;
    }
  }
    this.loadOrgainsation();
    this.steps = document.querySelectorAll('.step');
    this.formSteps = document.querySelectorAll('.form-step');
    this.updateProgress();
    this.assessmentService.getOrgTrainer("6").subscribe(
      (data) => {
        this.Trainer = data;
      },
      (error) => {
        console.error('Error fetching coaches:', error);
      }
    );
  }
 

  validateEcPhoneNumber(event: any) {
    const input = event.target.value;
    const filteredValue = input.replace(/[^0-9]/g, ''); 
    event.target.value = filteredValue;
  if (filteredValue.length > 0 && filteredValue.startsWith('0')) {
    event.target.value = ''; 
  
  }
    this.formData.usrECPhoneNo = event.target.value;
  }
  
  validatePhoneNumber(event: any) {
    const input = event.target.value;
    const filteredValue = input.replace(/[^0-9]/g, ''); 
    event.target.value = filteredValue;
  if (filteredValue.length > 0 && filteredValue.startsWith('0')) {
    event.target.value = ''; 
    return; 
  }
    this.formData.usrPhoneNo = event.target.value;
  }
  get passwordsDoNotMatch(): boolean {
    return this.formData.usrPassword !== this.confirmPassword;
  }
  
  get isConfirmPasswordEmpty(): boolean {
    return !this.confirmPassword;
  }
  
  private loadOrgainsation() {
    this.assessmentService.getOrgainsation().subscribe(
      (data) => {
        this.Orgainsation = data;
      },
      (error) => {
        console.error('Error fetching coaches:', error);
      }
    );
  }
  nextStep(form: NgForm): void {
    this.isSubmitted = true;
    if (this.isStepValid()) {
      this.activeStep++;
      if (this.activeStep > this.steps.length) {
        this.activeStep = this.steps.length;
      }
      this.updateProgress();
      this.isSubmitted = false;
    } else {
      // Trigger validation display for invalid fields
      form.control.markAllAsTouched();
    }
  }
 
  prevStep(): void {
    this.activeStep--;
    if (this.activeStep < 1) {
      this.activeStep = 1;
    }
    this.updateProgress();
  }
 
  updateProgress(): void {
    this.steps.forEach((step, i) => {
      if (i === (this.activeStep - 1)) {
        step.classList.add('active');
        this.formSteps[i].classList.add('active');
      } else {
        step.classList.remove('active');
        this.formSteps[i].classList.remove('active');
      }
    });
 
    const prevButton = document.querySelector('.btn-prev') as HTMLButtonElement;
    const nextButton = document.querySelector('.btn-next') as HTMLButtonElement;
 
    if (this.activeStep === 1) {
      prevButton.disabled = true;
    } else if (this.activeStep === this.steps.length) {
      nextButton.disabled = true;
    } else {
      prevButton.disabled = false;
      nextButton.disabled = false;
    }
  }
  resolvedCaptcha(captchaResponse: any) {
    this.captchaResponse = (captchaResponse && captchaResponse.length > 0) ? true : false
  }
  submitForm(): void {
    if (this.selectedAthleteId) {
      this.formData.asiCoach = this.selectedAthleteId;
    }
    this.formData.usrECPhoneNo = this.formData.usrECPhoneNo.toString();
    this.formData.usrPhoneNo = this.formData.usrPhoneNo.toString();
    this.formData.usrActive = this.orgId !== undefined ? 1 : 0;
    if(this.orgId === undefined ){
      if(this.captchaResponse){
        this.formSubmitted = true;
        if (this.isFormValid()) {
        this.userRegisterService.userRegister([this.formData]).subscribe(response =>{
            this.formSubmitted = true;
            this.notify.success("Form submitted successfully");
            setTimeout(() => {
              this.router.navigate(['']);
            }, 1000);
          }, error => {
             // Check if the backend provides a specific error message
          const errorMessage = error.error ? error.error : 'Error submitting form';
          
          // Display the error message using your notification system
          this.notify.failed(errorMessage);
            console.error('Error submitting form', error);
          });
      }else {
      }
    }else{
      this.notify.failed("Invalid captcha");
      setTimeout(() => {
       
      }, 1000);
     
    }
    }
    else{
    this.formSubmitted = true;
    if (this.isFormValid()) {
    this.userRegisterService.userRegister([this.formData]).subscribe(response =>{
        this.formSubmitted = true;
        this.notify.success("Form submitted successfully");
        setTimeout(() => {
          this.router.navigate(['registerrequest']);
        }, 1000);
      }, error => {
        // Check if the backend provides a specific error message
        const errorMessage = error.error ? error.error : 'Error submitting form';
          
        // Display the error message using your notification system
        this.notify.failed(errorMessage);
        console.error('Error submitting form', error);
      });
  }else {
  }
}
}
  isFormValid(): boolean {
    return Object.keys(this.formData).every(key => {
      return this.formData[key] !== '' && this.formData[key] !== null;
    });
  }
  fetchCoach(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement) {
      const query = inputElement.value;
      this.roleService.getCoachName(query).subscribe(data => {
        this.trainer = data;
      }, error => {
        console.error('Error fetching athletes', error);
      });
    }
  }
  onAthleteSelected(event: Event): void {
    const inputElement = event.target as HTMLInputElement | null;
    if (inputElement) {
      const selectedAthlete = this.trainer.find(trainer => trainer.usrFullName === inputElement.value);
      if (selectedAthlete) {
        this.selectedAthleteId = selectedAthlete.usrId;
      }
    }
  }
 
  isStepValid(): boolean {
    // Validate the fields only for the current step
    switch(this.activeStep) {
      case 1:
        return this.isPersonalInfoStepValid();
      case 2:
        return this.validateMedicalHistoryFields();
      case 3:
        return this.validateSportsInfoFields();
      // Add other cases for additional steps if needed
      default:
        return true;
    }
  }
 
  // Example validation for the first step (Medical History)
  validateMedicalHistoryFields(): boolean {
    return this.formData.umhAllergies &&
           this.formData.umhCurrentMedications &&
           this.formData.umhPreviousInjuries &&
           this.formData.umhChronicConditions &&
           this.formData.umhSurgeries &&
           this.formData.umhFamilyMedicalHistory &&
           this.formData.uliSleepPatterns &&
           this.formData.uliDiet &&
           this.formData.uliHydration &&
           this.formData.uliStressLevels;
  }
 
  // Example validation for the second step (Sports Information)
  validateSportsInfoFields(): boolean {
    return this.formData.asiPrimarySport &&
           this.formData.asiRole &&
           this.formData.asiSecondarySport &&
           this.formData.asiEvent &&
           this.formData.asiCategory &&
           this.formData.asiCoach &&
           this.formData.asiTrainingFrequency &&
           this.formData.asiTrainingDuration &&
           this.formData.asiCompetitionLevel &&
           this.formData.asiCareerHighlights &&
           this.formData.asiShortTermGoals &&
           this.formData.asiLongTermGoals;
  }
 
  isPersonalInfoStepValid(): boolean {
    return this.formData.usrFullName &&
           this.formData.usrDoB &&
           this.formData.usrGender &&
           this.formData.usrHeight &&
           this.formData.usrWeight &&
           this.formData.usrReligion &&
           this.formData.usrCaste &&
           this.formData.usrAddress &&
           this.formData.usrPhoneNo &&
           this.formData.usrEmail &&
           this.formData.usrPassword &&
           this.formData.usrECName &&
           this.formData.usrECRelationship &&
           this.formData.usrECPhoneNo &&
           this.formData.usrOrganization &&
           this.formData.usrClass;
  }
 
}