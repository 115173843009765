import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink, RouterModule } from '@angular/router';
import { SharedService } from '../../services/shared.service';
import { AssessmentService } from '../../services/assessment.service';
import { NotificationService } from '../../services/Notifications.service';
import { DomSanitizer } from '@angular/platform-browser';
import { NgToastModule } from 'ng-angular-popup';

@Component({
  selector: 'app-cardiology-form',
  standalone: true,
  imports: [CommonModule, RouterModule, RouterLink, FormsModule,NgToastModule],
  templateUrl: './cardiology-form.component.html',
  styleUrl: './cardiology-form.component.css'
})
export class CardiologyFormComponent {
  formSubmitted: boolean = false;
  assessment: any = {
    coaId: 0,
    coaAthlete: '',
    coaAssessor: '',
    coa800mRun: '',
    coaYoYoTest: '',
    coaRAST: '',
    coaFatigueIndex: '',
    coaCreatedBy:'',
    // coaCreatedOn: '',
    // coaUpdatedBy: '',
    // coaUpdatedOn: '',

  };

  detail: any;
  UserId: any;
  ImageUrl: any;
  id:any;
  showImage: boolean = false;
 
  constructor(private router: Router,
    private sharedService: SharedService,
    private assessmentService: AssessmentService,
    private route: ActivatedRoute,
    private notify: NotificationService,private sanitizer: DomSanitizer) {

      this.route.queryParams.subscribe(params => {
        this.id = params['id'];
      });
      this.getCardiology();
    }
 
  ngOnInit() {
    this.sharedService.detail$.subscribe((data: any) => {
      this.detail = data;
      this.UserId = this.detail.user.usrId;
    });
  }
  getProfileImage() {
    this.ImageUrl = null;
    this.showImage = false;
  
    this.assessmentService.getImage(this.assessment.coaAthlete).subscribe(
      (imageBlob) => {
        if (imageBlob) {
          const objectURL = URL.createObjectURL(imageBlob);
          this.ImageUrl = this.sanitizer.bypassSecurityTrustUrl(objectURL);
          this.showImage = true; 
          console.log(this.ImageUrl, "ImageUrl");
        } else {
          this.notify.failed("invalid athlete number.");
        }
      },
      (error) => {
        this.ImageUrl = null;
        this.showImage = false;
        this.notify.failed("invalid athlete number");
        console.error('Error fetching image', error);
      }
    );
  }

  getCardiology(){
    if(this.id){
    this.assessmentService.getcardioById(this.id).subscribe((response: any) => {
      if (response && response.length > 0) {
        console.log(response,'data');
        const data = response[0];
        this.assessment.coaId = data.coaId;
        this.assessment.coaAthlete = data.coaAthlete;
        this.assessment.coaAssessor = data.coaAssessor;
        this.assessment.coa800mRun = data.coa800mRun;
        this.assessment.coaYoYoTest = data.coaYoYoTest;
        this.assessment.coaRAST = data.coaRAST;
        this.assessment.coaFatigueIndex = data.coaFatigueIndex;
        this.assessment.coaCreatedBy = data.coaCreatedBy;
      }
      this.getProfileImage() ;
    },
    (error: any) => {
      console.error('Error fetching assessment data', error);
    }
  );
}
}




  onSubmit(): void {
    this.assessment.coaAssessor = this.UserId;
    this.assessment.coaCreatedBy = this.UserId;
    this.assessmentService.addcordio([this.assessment]).subscribe(response => {
      console.log('Assessment submitted', response);
      this.formSubmitted = true;
      this.notify.success("Form submitted successfully");
      setTimeout(() => {
        this.router.navigate(['CardiologyList']);
      }, 1000);
    }, error => {
      console.log('Error submitting assessment', error);
    });
  }
 
  isFormValid(): boolean {
    return Object.keys(this.assessment).every(key => {
      return this.assessment[key] !== '' && this.assessment[key] !== null;
    });
  }
}
