import { CommonModule } from '@angular/common';
import { Component, NgZone, OnInit } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { ActivatedRoute, Router, RouterLink, RouterModule } from '@angular/router';
import { AssessmentService } from '../../../services/assessment.service';
import { PrivilegeService } from '../../../services/privilage.service';
import { Privilege } from '../../../model/Privillage';
import { UserService } from '../../../services/user.service';
import { User } from '../../../model/User';

interface IRow {
  playerName: string;
  naId:string;
  naAthlete:string;
  Assessor: string;
  weight: string;
  height: string;
  Hemoglobin: string;
  date: string;
  priority: string;
}

@Component({
  selector: 'app-nutrition-detail-list',
  standalone: true,
  imports: [CommonModule,RouterModule,RouterLink,AgGridAngular],
  templateUrl: './nutrition-detail-list.component.html',
  styleUrl: './nutrition-detail-list.component.css'
})
export class NutritionDetailListComponent {
  user: User | null = null;
  isAdmin: boolean = false;
  isPlayer: boolean = false;
  OrgAdmin: boolean = false;

  themeClass = 'ag-theme-quartz';

  rowData: IRow[] = [];

  colDefs: ColDef[] = [];

  defaultColDef: ColDef = {
    flex: 1,
    sortable: true,
    filter: true,
  };
  id: any;
  name: any;
  privilege: Privilege;
  UsrId: any;

  constructor(private assessmentService: AssessmentService,private ngZone: NgZone, private router: Router,private route:ActivatedRoute,private priService:PrivilegeService,private userService:UserService) { 
    this.route.queryParams.subscribe(params => {
    this.id = params['id'];
    console.log(this.id,"ID");
  })
  this.privilege = this.priService.getPrivileges();
  this.isPlayer = this.privilege.isPlayer;
  this.OrgAdmin = this.privilege.isOrgAdmin;
}

  ngOnInit() {
    this.user = this.userService.getUser();
    if(this.id){
    this.assessmentService.geteNutritionalByAthleteId(this.id).subscribe(response => {
      console.log('Assessment data', response);
      this.name = response[0].athleteFullName;
      this.UsrId=response[0].naAthlete;
      this.rowData = response.map((item: any) => ({
        playername: item.athleteFullName,
        naId:item.naId,
        naAthlete:item.naAthlete,
        Assessor: `${item.AssessorFullName}`,
        weight: item.naBodyWeight,
        height: item.naBodyHeight,
        Hemoglobin: item.naHemoglobin,
        date: new Date(item.naDate).toLocaleDateString('en-US'),
        priority: this.getPriority(item)
      }));

      this.colDefs = this.getColumnDefinitions();
    }, error => {
      console.error('Error fetching assessment data', error);
    });
  }
    this.setUserRole();
  }

  getPriority(item: any): string {
    return (item.naBodyHeight > 0 || item.naBodyWeight > 0) ? 'High' : 'Low';
  }

  setUserRole() {
   
  }

  getColumnDefinitions(): ColDef[] {
    const columns: ColDef[] = [
      { field: 'Assessor' },
      { field: 'date' },
      { field:'height'},
      { field:'weight'},
      { field:'Hemoglobin'},{
        field: 'priority',
        cellStyle: params => ({
          color: params.value === 'Low' ? 'red' : 'green'
        })
      },
      {
        headerName: 'Action',
        cellRenderer: (_params: any) => {
          const container = document.createElement('div');
          container.style.display = 'flex';
          container.style.justifyContent = 'space-around';
 
          // View button with eye icon
          const viewButton = document.createElement('button');
          viewButton.classList.add('btn');
          viewButton.innerHTML = '<i class="fa fa-eye"></i>';
          viewButton.addEventListener('click', () => {           
            // if (this.isPlayer) {
            //   this.ngZone.run(() => {
            //   this.router.navigate(['/nutritiondetail'])
            //   })
            // } else {
              this.ngZone.run(() => {
              this.router.navigate(['/nutritiondetail'], { queryParams: { id: _params.data.naId } })
            })
          //}
          });
 
          // Edit button with edit icon
          const editButton = document.createElement('button');
          editButton.classList.add('btn');
          editButton.innerHTML = '<i class="fa fa-edit"></i>';
          editButton.addEventListener('click', () => {
            this.ngZone.run(() => {
            this.router.navigate(['/nutrition'], {queryParams: { id: _params.data.naId } })
            });
          });
 
          // Delete button with delete icon
          // const deleteButton = document.createElement('button');
          // deleteButton.classList.add('btn');
          // deleteButton.innerHTML = '<i class="fa fa-trash"></i>';
          // deleteButton.addEventListener('click', () => {
          //   // Handle delete action
          // });
 
          container.appendChild(viewButton);
          if (!this.isPlayer && !this.OrgAdmin) {
          container.appendChild(editButton);
          }
         // container.appendChild(deleteButton);
 
          return container;
        },
        width: 120
      }
    ];

    if (this.privilege.isAdmin) {
      return columns;
    } else if (this.privilege.isPlayer) {
      return columns.filter(col => col.field !== 'playerName');
    } else {
      return columns.filter(col => col.field !== 'Assessor');
    }
  }
}
