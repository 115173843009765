import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, Router, RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { AssessmentService } from '../../../services/assessment.service';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { NotificationService } from '../../../services/Notifications.service';
import { NgToastModule } from 'ng-angular-popup';
import { SharedService } from '../../../services/shared.service';
 
@Component({
  selector: 'app-biomechanics-physiology-form',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterOutlet, RouterLink, HttpClientModule,NgToastModule],
  templateUrl: './biomechanics-physiology-form.component.html',
  styleUrl: './biomechanics-physiology-form.component.css'
})
export class BiomechanicsPhysiologyFormComponent  {  
 
  formSubmitted: boolean = false;
  personalDetailsFilled: boolean = false;
  physiotherapy: boolean = false;
  physiology: boolean = false;
 
  assessment: any = {
bmaAssessor : '',
bmaAssessorname:'akash',
bmaAthlete : null,
bmaDate : null,
bmaForceOutput : null,
bmaPowerOutput : null,
bmaSymmetry : null,
bmaImbalance : null,
bmaInterpreationResults : '',
bmaRecommendations : '',
bmaFollowUpDate : null,
 }
 
  athletes: any[] = [];
  selectedAthleteId: string | null = null;
  detail: any;
  UserId: any;
  id: any;
 
 
 constructor(private router: Router,private route: ActivatedRoute, private sharedService: SharedService, private assessmentService: AssessmentService,private notify:NotificationService) {
 this.route.queryParams.subscribe(params => {
  this.id = params['id'];
});
 this.FetchAthelete();
}
 ngOnInit() {
  this.sharedService.detail$.subscribe((data: any) => {
    this.detail = data;
    this.UserId=this.detail.user.usrId;
  });
}
  checkPersonalDetailsFilled(): void {
    const personalDetailsInputs = document.querySelectorAll(".form.first input");
    this.personalDetailsFilled = Array.from(personalDetailsInputs).every(input => {
      const inputElement = input as HTMLInputElement;
      return inputElement.value.trim() !== '';
    });
  }
 
 
  onSubmit(): void {
    this.assessment.bmaAssessor = this.UserId;
    if (this.selectedAthleteId) {
      this.assessment.bmaAthlete = this.selectedAthleteId;
    }
    if(this.isFormValid()){
    this.assessmentService.addBiomechanical([this.assessment]).subscribe(response => {
      console.log('Assessment submitted', response);
      this.notify.success("data");
      setTimeout(() => {
        this.router.navigate(['biomachanicslist']);
      }, 1000);
    }, error => {
      console.error('Error submitting assessment', error);
    });
  }
  }
  isFormValid(): boolean {
    return Object.keys(this.assessment).every(key => {
      // Exclude 'OtherNutrientLevels' from validation
   
      return this.assessment[key] !== '' && this.assessment[key] !== null;
    });
  }
  fetchAthletes(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement) {
      const query = inputElement.value;
      this.assessmentService.getAthlete(query).subscribe(data => {
        this.athletes = data;
      }, error => {
        console.error('Error fetching athletes', error);
      });
    }
  }
 
  onAthleteSelected(event: Event): void {
    const inputElement = event.target as HTMLInputElement | null;
    if (inputElement) {
      const selectedAthlete = this.athletes.find(athlete => athlete.usrFullName === inputElement.value);
      if (selectedAthlete) {
        this.selectedAthleteId = selectedAthlete.usrId;
      }
    }
  }
  FetchAthelete(){
    if(this.id){
    this.assessmentService.fetchAtheletesBMfitness(this.id).subscribe((response: any) => {
      if (response && response.length > 0) {
        console.log(response,'data');
        const data = response[0];
        this.assessment.bmaId = data.bmaId;
        this.assessment.bmaAssessorName = data.assessorName;
        this.assessment.bmaAthleteName = data.athleteName;
        this.assessment.bmaAssessor = data.bmaAssessor;
        this.assessment.bmaAthlete = data.bmaAthlete;
        this.assessment.bmaDate = data.bmaDate ? data.bmaDate.split('T')[0] : '';
        this.assessment.bmaForceOutput = data.bmaForceOutput;
        this.assessment.bmaPowerOutput = data.bmaPowerOutput;
        this.assessment.bmaSymmetry = data.bmaSymmetry;
        this.assessment.bmaImbalance = data.bmaImbalance;
        this.assessment.bmaInterpreationResults = data.bmaInterpreationResults;
        this.assessment.bmaRecommendations = data.bmaRecommendations;
        this.assessment.bmaFollowupDate = data.bmaFollowUpDate ? data.bmaFollowUpDate.split('T')[0] : '';
       
      }
    },
    (error: any) => {
      console.error('Error fetching assessment data', error);
    }
  );
 
  }
}
}