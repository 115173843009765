import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AssessmentService } from '../../../services/assessment.service';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-physiotherapy-detail',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl:'./physiotherapy-detail.component.html',
  styleUrls: ['./physiotherapy-detail.component.css']
})
export class PhysiotherapyDetailComponent implements OnInit {
  id: any;
  athleteReport: any[] = [];
  constructor(private router: Router, private route: ActivatedRoute,private assessmentService: AssessmentService) {
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
      console.log(this.id,"playerID");
    });
 
  }
  ngOnInit(): void { 
    if(this.id){
    this.assessmentService.fetchphysiotherapybyid(this.id).subscribe((response: any) => {
      this.athleteReport = response;
      console.log(  this.athleteReport,'response')
    });
  }
  }
  downloadPDF(): void {
    const DATA = document.getElementById('pdf-content');
    if (DATA) {
      html2canvas(DATA).then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgProps = pdf.getImageProperties(imgData);
        const imgWidth = pdfWidth * 0.8; // 80% of the page width
        const imgHeight = (imgProps.height * imgWidth) / imgProps.width;
        const margin = (pdfWidth - imgWidth) / 2;
        const topMargin = 20; // Add top margin

        pdf.addImage(imgData, 'PNG', margin, topMargin, imgWidth, imgHeight);
        pdf.save('physiotherapy-details.pdf');
      });
    }
  }
  
}