<div class="body-wrapper">  
    <div class="row flex-grow">
      <div class="col-12 grid-margin stretch-card">
        <div class="card card-rounded">
          <div class="card-body">
            <div class="d-sm-flex justify-content-between align-items-start">
              <div>
                <h4 class="card-title card-title-dash">Level One</h4>
                <p class="card-subtitle card-subtitle-dash">Report List</p>
              </div>
              <div>
                <div>
                  <div class="d-flex">
                    <div>
                      <button class="btn btn-primary" type="button" (click)="openModal()">Upload</button>
                    </div>&nbsp;
                    <div >
                      <button class="btn btn-primary" type="button" (click)="downloadPDF()">Download All</button>
                    </div>&nbsp;
                    <div *ngIf="Admin || OrgAdmin">
                      <button class="btn btn-primary" type="button" (click)="openImageModal()">UploadImage</button>
                    </div>
                  </div>
                </div>
                
                <!-- Include the modal component -->
                <app-fileupload #fileUploadModal [name]="'levelone'"></app-fileupload>
                <app-imageupload #fileImageUploadModal></app-imageupload>
              </div>
            </div>
            <div class="content" style="width: 100%; height: 100%;">
                <!-- The AG Grid component, with Dimensions, CSS Theme, Row Data, and Column Definition -->
                <ag-grid-angular
                class="ag-theme-quartz"
                style="height: 500px;"
                [rowData]="rowData"
                [columnDefs]="colDefs"
                [defaultColDef]="defaultColDef"
                [pagination]="true"
              />
              </div>   
          </div>
        </div>
      </div>
    </div>
  </div>


  <div *ngFor="let player of players; let i = index"  [ngStyle]="{position: 'absolute', top: '-10000px', left: '-10000px'}">
    <div id="pdf-content-{{i}}" class="body-wrapper1">
      <div class="container-fluid">
        <div class="row row-cards">
          <div class="col-6 col-sm-6 col-lg-3">
            <div class="card1">
              <div class="card-body1 p-3 text-center">
                <div class="text-muted mb-2">Full Name</div>
                <div class="Title">{{player.loaName}}</div>
              </div>
            </div>
          </div>
          <div class="col-6 col-sm-6 col-lg-3">
            <div class="card1">
              <div class="card-body1 p-3 text-center">
                <div class="text-muted mb-2">Age</div>
                <div class="Title">{{player.aggAge}}</div>
              </div>
            </div>
          </div>
          <div class="col-6 col-sm-6 col-lg-3">
            <div class="card1">
              <div class="card-body1 p-3 text-center">
                <div class="text-muted mb-2">Number</div>
                <div class="Title">{{player.loaRegisterNumber}}</div>
  
              </div>
            </div>
          </div>
          <!-- <div class="col-6 col-sm-6 col-lg-3">
            <div class="card1">
              <div class="card-body1 p-3 text-center">
                <div class="text-muted mb-2">Assessment Date <div>
                <div class="Title">{{player.loaAsessmentDate}}</div>
              </div>
            </div>
          </div> -->
          <div class="col-6 col-sm-6 col-lg-3">
            <div class="card1">
              <div class="card-body1 p-3 text-center">
                <div class="text-muted mb-2">Gender</div>
                <div class="Title">{{player.aggGender}}</div>
  
              </div>
            </div>
          </div>
  
        </div>
        <div class="row">
  
          <div class="col-xl-5 grid-margin stretch-card flex-column mt-4 ">
  
            <div class="row h-100">
              <div class="col-md-12 stretch-card">
                <div class="card z-index-2">
                  <div class="card-body p-3 d-flex flex-column justify-content-between">
                    <div
                      class="bg-gradient-dark border-radius-lg p-3 pe-1 mb-3 d-flex flex-column justify-content-center">
                      <h4 class="Title-white text-center"> OVERALL SCORE</h4>
                      <div class="progress-circle blue">
                        <span class="progress-left">
                          <span class="progress-bar"></span>
                        </span>
                        <span class="progress-right">
                          <span class="progress-bar"></span>
                        </span>
                        <div class="progress-value">{{ player.loaAverageRange }} %</div>            
                      </div>
                    </div>
                    <!-- <div>
                    <h6 class="Text-red ms-2 mt-4 mb-0">Poor</h6>
                    <p class="text-sm ms-2 mt-2"> Performance falls significantly below average across all
                      metrics.</p>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <div class="col-xl-7 grid-margin stretch-card flex-column mt-4">
            <div class="row">           
              <!-- Loop through the data -->
              <div class="col-md-6 grid-margin stretch-card" *ngFor="let item of player.rowData">
                <div class="card">
                  <div class="card-body d-flex flex-column">
                    <div class="d-flex justify-content-between align-items-center mb-2">
                      <p class="mb-0 text-muted">{{ item.SportName }}</p>
                    </div>
                    <div class="progress1">
                      <div class="progress-bar1" [style.width.%]="item.RangePercentage === 100 ? item.RangePercentage - 2 : item.RangePercentage">
                          <div class="progress-value1" >{{ item.RangePercentage }}%</div>
                      </div>
                  </div>
                  
                  <div class="mt-2 text-center">
                    <strong [ngClass]="getTextColorClass(item.RangePercentage)">
                      {{ getProgressText(item.RangePercentage) }}
                    </strong>
                  </div>
  
                  </div>
  
                </div>
              </div>
            </div>
          </div>
  
        </div>
      </div>
    </div>
  </div>

  <div id="hover-image-modal" class="image-popup" [ngStyle]="{ display: 'none' }">
    <img id="hover-image" [src]="''" alt="Profile Image" />
  </div>

  <div class="modal fade" id="imgModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="card1">
            <div class="logo-content">
              <div>
                <h2>AMS SPORTS</h2>
                <p>Lorem Ipsum dolor sit</p>
              </div>
              <div class="logo-circle">
                <img src="" alt="Logo" class="logo-img">
              </div>
            </div>
            <div class="img">
              <!-- Display the selected image -->
              <img [src]="selectedImageUrl" alt="Image Description" class="clickable-img" (click)="downloadImage(selectedImageUrl)">
            </div>
            <h2 class="title">
              <span>ID: </span> {{ selectedId }} <!-- Corrected this part -->
            </h2>

          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="imageModal" tabindex="-1" role="dialog" aria-labelledby="imageModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="imageModalLabel">Profile Image</h5>
          <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button> -->
        </div>
        <div class="modal-body text-center m-3">
          <img [src]="selectedImageUrl" class="img-fluid" alt="Profile Image">
        </div>
      </div>
    </div>
  </div>