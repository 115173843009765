<div *ngIf="isLoading" class="loading-spinner">
  <div class="spinner"></div>
</div>   
<!-- <div class="text-center" *ngIf="this.athleteReport.length === 0">
  No Data!
</div> -->
<div class="row" *ngIf="!Isplayer">
  
   
      <div class="row d-flex align-items-stretch">
      <div class="col-sm-9 flex-column d-flex stretch-card">
        <div class="row">
          <div class="col-sm-12 grid-margin d-flex stretch-card">
            <div class="card">
              <div class="card-body">
                <div class="d-flex align-items-center justify-content-between">
                  <h4 class="card-title mb-2">{{ reportTitle }}</h4> 
                  <div class="dropdown">
                    <a href="#" class="text-success btn btn-link px-1 dropdown-toggle dropdown-arrow-none"
                       data-bs-toggle="dropdown" id="settingsDropdownsales">
                      <i class="mdi mdi-dots-horizontal"></i>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right navbar-dropdown"
                         aria-labelledby="settingsDropdownsales">
                         <a class="dropdown-item" (click)="filterPerformers('All','overall')">
                          <i class="mdi mdi-grease-pencil text-primary"></i>
                          All
                        </a>
                      <a class="dropdown-item" (click)="filterPerformers('Excellent','overall')">
                        <i class="mdi mdi-grease-pencil text-primary"></i>
                        Excellent
                      </a>
                      <a class="dropdown-item" (click)="filterPerformers('Good','overall')">
                        <i class="mdi mdi-delete text-primary"></i>
                        Good
                      </a>
                       <a class="dropdown-item" (click)="filterPerformers('Average','overall')">
                        <i class="mdi mdi-grease-pencil text-primary"></i>
                        Average
                      </a>
                      <a class="dropdown-item" (click)="filterPerformers('Poor','overall')">
                        <i class="mdi mdi-delete text-primary"></i>
                        Poor
                      </a>
                    </div>
                  </div>
                </div>
                <div id="new-chart"></div>
              </div>
              
            </div>
          </div>
        </div>
       
      </div>
      <div class="col-sm-3 flex-column d-flex stretch-card">
        <div class="">   
          <div class="col-lg-12 d-flex grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <div class="d-flex align-items-center justify-content-between mb-3">
                <img src="assets/image/icon1.png" class="img-fluid" alt="icon1">
                <p class="pb-0">Players Count</p>
                </div>
                <h2 class="font-weight-bold mb-2"> {{ injuryCount }}</h2>
                <button (click)="selectRole('Player', null)" class="btn btn-primary" *ngIf="Admin || OrgAdmin">Add New Player</button>
              </div>
            </div>
          </div>
          <div class="col-lg-12 d-flex grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <div class="d-flex align-items-center justify-content-between mb-3">
                <img src="assets/image/icon2.png" class="img-fluid" alt="icon1">
                <p class="pb-0">Overall Score</p>
                </div>
                <h2 class="font-weight-bold mb-3">{{ averageOverallPercentage  }}%</h2>
                <div class="progress">
                  <div class="progress-bar bg-warning" role="progressbar"   [style.width.%]="averageOverallPercentage > 0 ? averageOverallPercentage : 0"
                    [attr.aria-valuenow]="averageOverallPercentage" aria-valuemin="0" aria-valuemax="100">
                  </div>
                </div>
               
              </div>
            </div>
          </div>
          
    
        </div>
       
      </div>
      </div>

      <div class="row row-cards row-deck">
        <div class="col-sm-9">
          <div class="d-sm-flex justify-content-between align-items-center mb-2">
            <h4 class="card-title fw-semibold" >{{header}} Performers</h4>
            <div>
              <div class="select-wrapper">
                <ng-select
                [items]="selectOptions"
          bindLabel="label"
          bindValue="value"
          [(ngModel)]="selectedStatus"
           (change)="onFitnessStatusChange()"  class="ng-select-custom"  [searchable]="false"
                [clearable]="false">
                <ng-template ng-label-tmp let-item="item">
                  <i class="fa fa-chevron-down"></i> {{ item.label }}
                </ng-template>
                <ng-template ng-option-tmp let-item="item">
                  {{ item.label }}
                </ng-template>
                </ng-select>
              
               
              </div>
            </div>
          </div>
          <!-- Top Performers Section -->
          <div class="col-12">
            <div class="card py-3">
              <h4 class="m-3" *ngIf="chat">Athlete Data</h4>
              <div class="table-responsive custom-scroll">
                <table class="table table-hover table-outline table-vcenter text-nowrap card-table">
                  <thead>
                      <tr>
                      
                          <th>Athletes</th>
                          <th>overall%</th>
                          <th>Deep Squat (cm)</th>
                          <th>Inline Lunge (cm)</th>
                          <th>Hurdle Step (cm)</th>
                          <th>Active SLR (cm)</th>
                          <th>Push Up (count)</th>
                          <th>Rotary Stability (count)</th>
                          
                        </tr>
                  </thead>
                  <tbody *ngIf="InjuryPerformers && InjuryPerformers.length > 0; else noData">
                    <tr *ngFor="let performer of InjuryPerformers">
                     
                      <td>
                        <div class="d-flex align-items-center">
                        <img class="avatar"
                        src="{{performer.image}}">
                        <div>
                          {{ performer.usrFullName }}
                        <div class="small text-muted">ID: {{ performer.ipaAthlete }}</div>
                      </div>
                        </div>
                      </td>
                      <td>
                        <div class="clearfix">
                          <div class="float-left"><strong>{{ performer.overallZScore| number: '1.2-2' }} %</strong></div>
                        </div>
                        <div class="progress progress-xs">
                          <div class="progress-bar" [ngClass]="{
                                'bg-lightgreen':  performer.overallZScore >= 85,
                                'bg-green':  performer.overallZScore >= 70 &&  performer.overallZScore < 85,
                                'bg-yellow':  performer.overallZScore >= 40 &&  performer.overallZScore  < 70,
                                'bg-red': performer.overallZScore < 40
                              }" role="progressbar" [style.width.%]=" performer.overallZScore"></div>
                        </div>
                      </td>
                      <td>{{ performer.ipaDeepSquat }}</td>
                      <td>{{ performer.ipaInlineLunge }}</td>
                      <td>{{ performer.ipaHurdleStep }}</td>
                      <td>{{ performer.ipaActiveSLR }}</td>
                      <td>{{ performer.ipaPushUp }}</td>
                      <td>{{ performer.ipaRotaryStability }}</td>   
                    </tr>
                  </tbody>
  
                  <!-- Template for when there is no data -->
                  <ng-template #noData>
                    <tr>
                      <td colspan="8" class="text-center">Data not available</td>
                    </tr>
                  </ng-template>
  
                </table>
  
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="row flex-grow">
            <div class="col-sm-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body" *ngIf="topPerformer.length > 0">
                  <div class="row">
                    <div>
                      <h4 class="card-title fw-semibold">Top Performer</h4>
                      <h4  *ngIf="!sciStaff"  class="font-weight-bold text-name">Name : {{ topPerformer[0]?.usrFullName }}</h4>
                      <h4 class="font-weight-bold text-id">ID :
                        {{ topPerformer[0]?.ipaAthlete }}
                      </h4>
                    </div>
                  </div>
                  <div class="donut-container d-flex align-items-center justify-content-center mb-4">
                    <div class="donut-circle"
                         [ngClass]="{
                           'dark-green': topPerformer[0]?.overallZScore >= 85,
                           'light-green':  topPerformer[0]?.overallZScore >= 70 &&  topPerformer[0]?.overallZScore < 85,
                           'yellow':  topPerformer[0]?.overallZScore >= 40 &&  topPerformer[0]?.overallZScore < 70,
                           'red':  topPerformer[0]?.overallZScore < 40,
                           'transparent-border':  topPerformer[0]?.overallZScore == null
                         }">
                      <div class="inner-circle">
                        <h3 class="font-weight-bold">{{   topPerformer[0]?.overallZScore| number: '1.2-2' }}%</h3>
                      </div>
                    </div>
                    <!-- <p class="score-tag">Overall Score</p> -->
                  </div>
                  <table class="score-table">
                    <thead>
                    <tr>
                      <th>Test</th>
                      <th >Score</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="score-title">Deep Squat</td>
                      <td class="score-value">{{ ( topPerformer[0]?.ipaDeepSquat % 1 === 0) 
                        ?  topPerformer[0]?.ipaDeepSquat 
                        : ( topPerformer[0]?.ipaDeepSquat | number: '1.2-2') }}</td>
                    </tr>
                    <tr>
                      <td class="score-title">Inline Lunge</td>
                      <td class="score-value">{{ ( topPerformer[0]?.ipaInlineLunge % 1 === 0) 
                        ?  topPerformer[0]?.ipaInlineLunge 
                        : ( topPerformer[0]?.ipaInlineLunge | number: '1.2-2') }}</td>
                    </tr>
                    <tr>
                      <td class="score-title">Hurdle Step</td>
                      <td class="score-value">{{ ( topPerformer[0]?.ipaHurdleStep % 1 === 0) 
                        ?  topPerformer[0]?.ipaHurdleStep 
                        : ( topPerformer[0]?.ipaHurdleStep | number: '1.2-2') }}</td>
                    </tr>
                    <tr>
                      <td class="score-title">Push Up</td>
                      <td class="score-value">{{ ( topPerformer[0]?.ipaPushUp % 1 === 0) 
                        ?  topPerformer[0]?.ipaPushUp 
                        : ( topPerformer[0]?.ipaPushUp | number: '1.2-2') }}</td>
                    </tr>
                  </tbody>
                  </table>
                  
                  
                </div>
                <div  class="card-body d-flex align-items-center justify-content-center" *ngIf="topPerformer.length== 0">
                  <h4 class="text-muted">No Data Available</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
     
      </div>
   
  </div>