<div class="body-wrapper">
    <div class="row flex-grow">
        <div class="col-12 grid-margin stretch-card">
            <div class="card card-rounded">
                <div class="card-body">
                    <div class="d-sm-flex justify-content-between align-items-start mb-4">
                        <div>
                            <h4 class="card-title card-title-dash">Create Team</h4>
                        </div>
                    </div>

                    <div class="d-flex justify-content-between align-items-center mb-4">
                        <div class="d-flex gap-4">
                            <ng-select
                            class="ng-select-custom"
                            [items]="dropdownItems"
                            bindLabel="orgName"
                            bindValue="orgId"
                            [(ngModel)]="selectedOrgFilter"
                            [searchable]="false"
                            [clearable]="false">
                            <ng-template ng-label-tmp let-item="item">
                              <span>{{ item.orgName }}</span>
                              <i class="fa fa-chevron-down"></i>
                            </ng-template>
                            
                            <ng-template ng-option-tmp let-item="item">
                                <span [ngClass]="{'disabled-option': item.disabled}">{{ item.orgName }}</span>
                            </ng-template>
                          </ng-select>
                      

                            <input type="text" class="custom-input-height" [(ngModel)]="this.teamName"
                                placeholder="Enter Team Name" />
                                <ng-select
                                class="ng-select-custom"
                                [items]="sportItems"
                                bindLabel="sportName"
                                bindValue="sportId"
                                [(ngModel)]="selectedSportFilter"
                                [searchable]="false"
                                [clearable]="false">
                                <ng-template ng-label-tmp let-item="item">
                                  <span>{{ item.sportName }}</span>
                                  <i class="fa fa-chevron-down"></i>
                                </ng-template>
                                
                                <ng-template ng-option-tmp let-item="item">
                                    <span [ngClass]="{'disabled-option': item.disabled}">{{ item.sportName }}</span>
                                </ng-template>
                              </ng-select>
                           

                        </div>
                    </div>
                    <div class="buttons">
                        <button class="btn btn-primary" (click)="SelectedOrganisation()">
                            <span class="btnText">Back</span>
                        </button> &nbsp;&nbsp;
                        <button type="submit" class="submit btn btn-primary" (click)="onSubmit()">
                            <span class="btnText">Submit</span>
                            <!-- <i class="uil uil-navigator"></i> -->
                        </button>
                         
                    </div>
                      
                    <br>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="toaster">
    <ng-toast />
</div>