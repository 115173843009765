import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Component, NgZone } from '@angular/core';
import { ActivatedRoute, Router, RouterLink, RouterModule } from '@angular/router';
import { AgGridAngular } from 'ag-grid-angular';
import { User } from '../../model/User';
import { ColDef } from 'ag-grid-community';
import { PrivilegeEnum } from '../../model/PrivilageEnum';
import { AssessmentService } from '../../services/assessment.service';
import { SharedService } from '../../services/shared.service';
import { UserRegisterService } from '../../services/user-register.service';
import { map, Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { Location } from '@angular/common';


interface IRow {}

@Component({
  selector: 'app-team-player-details',
  standalone: true,
  imports: [CommonModule, RouterLink, RouterModule, HttpClientModule, AgGridAngular],
  templateUrl: './team-player-details.component.html',
  styleUrl: './team-player-details.component.css'
})
export class TeamPlayerDetailsComponent {
  Response: any;
  detail: any;
  Id: any;
  Status: any;
  isTableVisible: boolean = false;
  coachData: any;
  public asmId: any;
  public testNames: any;
  public assesserName: any;
  public CoachName: any;
  public teamName: any;
  public created: any;
  public coachName: any;
  assessments: any;
  user: User | null = null;

  themeClass = 'ag-theme-quartz';
  rowData: IRow[] = [];
  colDefs: ColDef[] = [];
  ramPrivilege: PrivilegeEnum | undefined;
  defaultColDef: ColDef = {
    flex: 1,
    sortable: true,
    filter: true,
  };
  Isplayer: boolean | undefined;
  Admin: boolean | undefined;
  orgName: any;
  orgId : any;
  selectedPlayer: any;
  selectedImageUrl: any;
  TeamId:any;

  constructor(
    private userRegisterService: UserRegisterService,
    private sharedService: SharedService,
    private route: ActivatedRoute,
    private ngZone: NgZone,
    private router: Router,private assessmentService: AssessmentService, private sanitizer: DomSanitizer,private location: Location
  ) {
    this.route.queryParams.subscribe(params => {
      this.coachName = params['coachName'];
      this.TeamId = params['teamId'];
      this.orgName = params['orgName'];
      this.teamName = params['teamName'];
      this.orgId = params['orgId']
    });
  }

  ngOnInit() {
    this.sharedService.detail$.subscribe((data: any) => {
      this.detail = data;
      this.Id = this.detail.user.usrId;
      this.Status = 0;
    });

    this.getCoachdata();
  }

  getCoachdata() {
    this.assessmentService.getPlayerByTeam(this.TeamId).subscribe(
      response => {
        console.log(response,"response")
        this.rowData = response        
        .filter((item: any) => item.usrOrganization === Number(this.orgId))
        .map((item: any) => ({
          PlayerId: item.usrId,
          PlayerName: item.athleteName,
          Email: item.usrEmail,
          CoachName: item.coachName,
          Address: item.usrAddress,
          PhoneNo: item.usrPhoneNo
        }));
        this.colDefs = this.getColumnDefinitions();
      },
      error => {
        console.error('Error loading players', error);
      }
    );
  }

  getColumnDefinitions(): ColDef[] {
    return [
      { field: 'PlayerId' },
      { field: 'PlayerName'},
      { field: 'Email' },
      { field: 'Address' },
      { field: 'PhoneNo' },
      { field: 'PreviousInjuries' },
      {
        headerName: 'Action',
        cellRenderer: (_params: any) => {
          const container = document.createElement('div');
          container.style.display = 'flex';
          container.style.justifyContent = 'space-around';

          const viewButton = document.createElement('button');
          viewButton.classList.add('btn');
          viewButton.innerHTML = '<i class="fa fa-eye"></i>';
          viewButton.addEventListener('click', () => {
            // Set the selected player
            this.selectedPlayer = _params.data;
            this.getProfileImage(_params.data.PlayerId).subscribe((imageUrl:any) => {
             this.selectedImageUrl = imageUrl.changingThisBreaksApplicationSecurity as string;
        
            });

            // Manually trigger change detection to ensure modal content is updated
            this.ngZone.run(() => {
              // Show the modal
              const modalElement = document.getElementById('playerDetailModal');
              if (modalElement) {
                const modalInstance = new (window as any).bootstrap.Modal(modalElement);
                modalInstance.show();
              }
            });
          });
          container.appendChild(viewButton);
          return container;
        },
        width: 120,
      },
    ];
  }

  getProfileImage(userId: any): Observable<any> {
    return this.assessmentService.getImage(userId).pipe(
      map((imageBlob: Blob | MediaSource) => {
        const objectURL = URL.createObjectURL(imageBlob);
        return this.sanitizer.bypassSecurityTrustUrl(objectURL);
      })
    );
  }

 
  onBack(): void {
    this.router.navigate(['team'], { queryParams: { orgId: this.orgId } });
  }
}
