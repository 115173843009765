<div class="container" [ngClass]="{'secActive': formSubmitted}">
  <div class="container1">
    <header>Nutritional Assessment</header><br>
    <form (ngSubmit)="onSubmit()" #nutritionForm="ngForm">
      <div class="form first">
        <!-- Personal Details -->
        <div class="details personal">
          <div class="fields">
            <!-- <div class="input-field">
              <label>Assessor's Name</label>
              <input type="text" style="background-color: lightgray;" readonly placeholder="Enter assessor name"
                required [(ngModel)]="nutrition.naAssessorName" name="assessorName" #assessorName="ngModel" [ngClass]="{
                  'invalid-field': nutritionForm.submitted && assessorName.invalid,
                  'has-value': nutrition.naAssessor
                }">
              <div *ngIf="nutritionForm.submitted && assessorName.invalid" class="error-message">
                
              </div>
            </div> -->
            <div class="input-field">
              <label>Player ID</label>
              <input type="text" placeholder="Enter Player Name" required [(ngModel)]="nutrition.naAthlete"
                name="playerName" list="athleteList" 
                (change)="onAthleteSelected($event)" #playerName="ngModel" [ngClass]="{
                  'invalid-field': nutritionForm.submitted && playerName.invalid,
                  'has-value': nutrition.naAthlete
                }">
              <datalist id="athleteList">
                <option *ngFor="let athlete of athletes" [value]="athlete.usrFullName"></option>
              </datalist>
              <div *ngIf="nutritionForm.submitted && playerName.invalid" class="error-message">
                <!-- Player Name error message -->
              </div>
            </div>

            <div class="input-field">
              <label>Date of Assessment</label>
              <input type="date" placeholder="Enter date of assessment" required [(ngModel)]="nutrition.naDate"
                name="dateOfAssessment" #dateOfAssessment="ngModel" [ngClass]="{
                  'invalid-field': nutritionForm.submitted && dateOfAssessment.invalid,
                  'has-value': nutrition.naDate
                }">
              <div *ngIf="nutritionForm.submitted && dateOfAssessment.invalid" class="error-message">
                <!-- Date of Assessment error message -->
              </div>
            </div>
          </div>
          <br>
          <div class="fields">


          </div>
        </div>

        <!-- Body Composition -->
        <div class="details ID">
          <span class="title">Body Composition</span>
          <div class="fields">
            <div class="input-field">
              <label>Weight</label>
              <input type="number" placeholder="Enter Weight" required [(ngModel)]="nutrition.naBodyWeight"
                name="weight" #weight="ngModel" [ngClass]="{
                  'invalid-field': nutritionForm.submitted && weight.invalid,
                  'has-value': nutrition.naBodyWeight
                }">
              <div *ngIf="nutritionForm.submitted && weight.invalid" class="error-message">
                <!-- Weight error message -->
              </div>
            </div>
            <div class="input-field">
              <label>Height</label>
              <input type="number" placeholder="Enter Height" required [(ngModel)]="nutrition.naBodyHeight"
                name="height" #height="ngModel" [ngClass]="{
                  'invalid-field': nutritionForm.submitted && height.invalid,
                  'has-value': nutrition.naBodyHeight
                }">
              <div *ngIf="nutritionForm.submitted && height.invalid" class="error-message">
                <!-- Height error message -->
              </div>
            </div>
            <div class="input-field">
              <label>BMI</label>
              <input type="number" placeholder="Enter BMI" required [(ngModel)]="nutrition.naBMI" name="bmi"
                #bmi="ngModel" [ngClass]="{
                  'invalid-field': nutritionForm.submitted && bmi.invalid,
                  'has-value': nutrition.naBMI
                }">
              <div *ngIf="nutritionForm.submitted && bmi.invalid" class="error-message">
                <!-- BMI error message -->
              </div>
            </div>
            <div class="input-field">
              <label>Body Fat Percentage</label>
              <input type="number" placeholder="Enter Body Fat Percentage" required [(ngModel)]="nutrition.naBodyFat"
                name="bodyFatPercentage" #bodyFatPercentage="ngModel" [ngClass]="{
                  'invalid-field': nutritionForm.submitted && bodyFatPercentage.invalid,
                  'has-value': nutrition.naBodyFat
                }">
              <div *ngIf="nutritionForm.submitted && bodyFatPercentage.invalid" class="error-message">
                <!-- Body Fat Percentage error message -->
              </div>
            </div>
            <div class="input-field">
              <label>FatFreeWeight</label>
              <input type="number" placeholder="Enter Muscle Mass" required [(ngModel)]="nutrition.naFatFreeWeight"
                name="FatFreeWeight" #FatFreeWeight="ngModel" [ngClass]="{
                  'invalid-field': nutritionForm.submitted && FatFreeWeight.invalid,
                  'has-value': nutrition.naFatFreeWeight
                }">
              <div *ngIf="nutritionForm.submitted && FatFreeWeight.invalid" class="error-message">
                <!-- Muscle Mass error message -->
              </div>
            </div>
          </div>
        </div>

        <!-- Blood Reports -->
        <div class="details ID">
          <span class="title">Blood Reports</span>
          <div class="fields">
            <div class="input-field">
              <label>SubcutaneousFat</label>
              <input type="number" placeholder="Enter naSubcutaneousFat" required [(ngModel)]="nutrition.naSubcutaneousFat"
                name="SubcutaneousFat" #SubcutaneousFat="ngModel" [ngClass]="{
                  'invalid-field': nutritionForm.submitted && SubcutaneousFat.invalid,
                  'has-value': nutrition.naSubcutaneousFat
                }">
              <div *ngIf="nutritionForm.submitted && SubcutaneousFat.invalid" class="error-message">
                <!-- Hemoglobin error message -->
              </div>
            </div>
            <div class="input-field">
              <label>VisceralFat</label>
              <input type="number" placeholder="Enter VisceralFat" required [(ngModel)]="nutrition.naVisceralFat"
                name="VisceralFat" #VisceralFat="ngModel" [ngClass]="{
                  'invalid-field': nutritionForm.submitted && VisceralFat.invalid,
                  'has-value': nutrition.naVisceralFat
                }">
              <div *ngIf="nutritionForm.submitted && VisceralFat.invalid" class="error-message">
                <!-- Iron Level error message -->
              </div>
            </div>
            <div class="input-field">
              <label>BodyWater</label>
              <input type="number" placeholder="Enter naBodyWater" required [(ngModel)]="nutrition.naBodyWater"
                name="BodyWater" #BodyWater="ngModel" [ngClass]="{
                  'invalid-field': nutritionForm.submitted && BodyWater.invalid,
                  'has-value': nutrition.naBodyWater
                }">
              <div *ngIf="nutritionForm.submitted && BodyWater.invalid" class="error-message">
                <!-- Vitamin D Level error message -->
              </div>
            </div>
            <div class="input-field">
              <label>SkeletalMuscle</label>
              <input type="number" placeholder="Enter SkeletalMuscle" required
                [(ngModel)]="nutrition.naSkeletalMuscle" name="SkeletalMuscle" #SkeletalMuscle="ngModel" [ngClass]="{
                  'invalid-field': nutritionForm.submitted && SkeletalMuscle.invalid,
                  'has-value': nutrition.naSkeletalMuscle
                }">
              <div *ngIf="nutritionForm.submitted && SkeletalMuscle.invalid" class="error-message">
                <!-- Vitamin B12 Level error message -->
              </div>
            </div>
            <div class="input-field">
              <label>LeanMass</label>
              <input type="number" id="otherNutritionalLevels" placeholder="Enter LeanMass" required
                [(ngModel)]="nutrition.naLeanMass" name="LeanMass"
                #LeanMass="ngModel" [ngClass]="{
                  'invalid-field': nutritionForm.submitted && LeanMass.invalid,
                  'has-value': nutrition.naLeanMass
                }">
              <div *ngIf="nutritionForm.submitted && LeanMass.invalid" class="error-message">
                <!-- Other Nutritional Levels error message -->
              </div>
            </div>
          </div>
        </div>

        <!-- Dietary Analysis -->
        <div class="details ID">
          <span class="title">Dietary Analysis</span>
          <div class="fields">
            <div class="input-field">
              <label>BoneMass</label>
              <input type="number" placeholder="Enter BoneMass" required [(ngModel)]="nutrition.naBoneMass"
                name="BoneMass" #BoneMass="ngModel" [ngClass]="{
                  'invalid-field': nutritionForm.submitted && BoneMass.invalid,
                  'has-value': nutrition.naBoneMass
                }">
              <div *ngIf="nutritionForm.submitted && BoneMass.invalid" class="error-message">
                <!-- Current Diet error message -->
              </div>
            </div>
            <div class="input-field">
              <label>Protein</label>
              <input type="number" placeholder="Enter Protein" required
                [(ngModel)]="nutrition.naProtein" name="Protein"
                #Protein="ngModel" [ngClass]="{
                  'invalid-field': nutritionForm.submitted && Protein.invalid,
                  'has-value': nutrition.naProtein
                }">
              <div *ngIf="nutritionForm.submitted && Protein.invalid" class="error-message">
                <!-- Nutritional Deficiencies error message -->
              </div>
            </div>
          </div>
        </div>

        <!-- Recommendations and Follow-up Date -->
        <div class="fields">
          <div class="input-field">
            <label>BMR</label>
            <input type="number" placeholder="Enter BMR" required [(ngModel)]="nutrition.naBMR"
              name="BMR" #BMR="ngModel" [ngClass]="{
                'invalid-field': nutritionForm.submitted && BMR.invalid,
                'has-value': nutrition.naBMR
              }">
            <div *ngIf="nutritionForm.submitted && BMR.invalid" class="error-message">
              <!-- Follow-up Date error message -->
            </div>
          </div>
          <div class="input-field">
            <label>MetabolicAge</label>
            <input type="number" placeholder="Enter MetabolicAge" required [(ngModel)]="nutrition.naMetabolicAge"
              name="MetabolicAge" #MetabolicAge="ngModel" [ngClass]="{
                'invalid-field': nutritionForm.submitted && MetabolicAge.invalid,
                'has-value': nutrition.naMetabolicAge
              }">
            <div *ngIf="nutritionForm.submitted && MetabolicAge.invalid" class="error-message">
              <!-- Follow-up Date error message -->
            </div>
          </div>

          <div class="input-field">
            <label>HealthScore</label>
            <input type="number" placeholder="Enter HealthScore" required [(ngModel)]="nutrition.naHealthScore"
              name="HealthScore" #HealthScore="ngModel" [ngClass]="{
                'invalid-field': nutritionForm.submitted && HealthScore.invalid,
                'has-value': nutrition.naHealthScore
              }">
            <div *ngIf="nutritionForm.submitted && HealthScore.invalid" class="error-message">
              <!-- Follow-up Date error message -->
            </div>
          </div>
        </div>

        <!-- Submit Button -->
        <div>
          <button type="submit" class="submit">Submit</button>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="toaster">

  <ng-toast />

</div>