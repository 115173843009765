import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { RouterModule } from '@angular/router'; // Import RouterModule
import { CommonModule } from '@angular/common';
import { NgToastModule } from 'ng-angular-popup';
import { FooterComponent } from './footer/footer.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterModule, NgToastModule, FooterComponent],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Athlete';
  showFooter: boolean = true;

  constructor(private router: Router) {
    // Listen to route changes to toggle the footer visibility
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Check the current URL and hide the footer for login and register routes
        const currentRoute = event.url;
        this.showFooter = !(currentRoute.includes('') || currentRoute.includes('/register'));
      }
    });
  }
}
