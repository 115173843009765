<div class="body-wrapper">
  <div class="row flex-grow">
    <div class="col-12 grid-margin stretch-card">
      <div class="card card-rounded">
        <div class="card-body">
          <div class="d-sm-flex justify-content-between align-items-start">
            <div>
              <h4 class="card-title card-title-dash">Register Details</h4>
            </div>

            <div class="d-sm-flex justify-content-end align-items-start">
              <div class="select-wrapper">
                <label for="statusFilter"> Status:</label>
                <select id="statusFilter" [(ngModel)]="selectedStatus" (change)="onStatusChange()" class="form-select">
                  <option value="0">Pending</option>
                  <option value="1">Accepted</option>
                  <option value="2">Declined</option>
                  <option value="3">Password Request</option>
                </select>
              </div>
              <div class="d-flex m-2" *ngIf="showCheckboxes">

                <ng-select [items]="statusOptions" bindLabel="teamName" bindValue="teamId" [(ngModel)]="selectedTeam"
                  (change)="onSelectTeam()" placeholder="Search/Select Team" [searchable]="true" class="team-select">
                </ng-select>

                &nbsp;
                <select class="form-select" [(ngModel)]="selectedCoach">
                  <option value="" disabled>Select Trainer</option>
                  <option *ngFor="let trainer of Trainer" [value]="trainer.usrId">{{ trainer.usrFullName }}</option>
                </select>
                <div *ngIf="selectedRows.length > 0">
                  <button class="btn btn-primary" type="button" (click)="updateSelection()">
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex m-2">
              <div class="dropdown">
               
                <button class="btn btn-primary dropdown-toggle d-flex align-items-center" type="button" id="dropdownMenuButton1"
                  data-bs-toggle="dropdown" aria-expanded="false">
                  <i class='bx bx-plus'></i>
                  Create New
                </button>
                <ul class="dropdown-menu w-75" aria-labelledby="dropdownMenuButton1">
                  <li><a class="dropdown-item" (click)="selectRole('Trainer', 6)">Coach</a></li>
                  <!-- <li><a class="dropdown-item" (click)="selectRole('Manager', 4)">Manager</a></li> -->
                  <li><a class="dropdown-item" (click)="selectRole('Science Staff', 5)">Science Staff</a></li>
                  <li><a class="dropdown-item" (click)="selectRole('Assessor', 3)">Assessor</a></li>

                  <li><a class="dropdown-item" (click)="selectRole('Player', null)">Player</a></li>
                  <li><a class="dropdown-item" (click)="selectRole('orgAdmin', 7)">Org Admin</a></li>
                </ul>
                
              </div>&nbsp;
              <div>
                <button class="btn btn-primary d-flex align-items-center" type="button" (click)="openFileUploadModal()">
                  <i class='bx bx-upload' ></i>
                  Upload
                </button>
              </div>&nbsp;
              <!-- <div>
                <button class="btn btn-primary" type="button" (click)="onAssignTeamCoachClick()">
                  Assign Team/Coach
                </button>
              </div> -->
          </div>
          <br>
          <div class="content" style="width: 100%; height: 100%;">
            <ag-grid-angular class="ag-theme-quartz" style="height: 500px;" [rowData]="rowData" [columnDefs]="colDefs"
              [defaultColDef]="defaultColDef" [gridOptions]="gridOptions" [pagination]="true"
              (gridReady)="onGridReady($event)">
            </ag-grid-angular>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="customModal" tabindex="-1" aria-labelledby="customModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" style="max-width: 1000px;">
    <div class="modal-content" style="height: 500px;">
      <div class="modal-body p-0 h-100">
        <div class="row g-0 h-100">
          <!-- Image Section -->
          <div class="col-lg-3 col-md-3 col-sm-12 h-100">
            <img src="/assets/image/img1.jpg" alt="Image" class="img-fluid h-100 w-100" style="object-fit: cover;">
          </div>
          <!-- Text Section -->
          <div class="col-lg-9 col-md-9 col-sm-12 d-flex align-items-center h-100">
            <div class="p-4 w-100" style="overflow-y: auto; max-height: 100%;">
              <h5 class="modal-title mb-5" id="customModalLabel">User Data</h5>
              <!-- Row 1 -->
              <form action="" id="userForm">
                <div class="form-one form-step active">
                  <div class="row mb-4">
                    <div class="col-lg-4 col-md-6 col-sm-6 mb-3 mb-md-0">
                      <label for="name1">Name</label>
                      <input type="text" name="usrFullName" class="form-control" [value]="userDetail.usrFullName"
                        [attr.disabled]="!isEditMode ? '' : null">
                      <input type="hidden" name="usrId" class="form-control" [value]="userDetail.usrId"
                        [attr.disabled]="!isEditMode ? '' : null">
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6 mb-3 mb-md-0">
                      <label for="role1">Role</label>
                      <input type="text" name="roleName" class="form-control" [value]="userDetail.roleName" disabled>
                      <input type="hidden" name="usrRole" class="form-control" [value]="userDetail.usrRole"
                        [attr.disabled]="!isEditMode ? '' : null">
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6">
                      <label for="email">Email</label>
                      <input type="email" name="usrEmail" class="form-control" [value]="userDetail.usrEmail"
                        [attr.disabled]="!isEditMode ? '' : null">
                    </div>
                  </div>

                  <!-- Row 2 -->
                  <div class="row mb-4">
                    <div class="col-lg-4 col-md-6 col-sm-6 mb-3 mb-md-0">
                      <label for="name2">Gender</label>
                      <select class="form-control" name="usrGender" [attr.disabled]="!isEditMode ? '' : null">
                        <option value="1" [selected]="userDetail.usrGender ===1"> Male </option>
                        <option value="2" [selected]="userDetail.usrGender ===2"> Female </option>
                        <option value="3" [selected]="userDetail.usrGender ===3"> Others </option>
                      </select>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6 mb-3 mb-md-0">
                      <label for="dob">DOB</label>
                      <input type="date" name="usrDoB" class="form-control"
                        [value]="userDetail.usrDoB ?userDetail.usrDoB.split('T')[0] : ''"
                        [attr.disabled]="!isEditMode ? '' : null">
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6">
                      <label for="location2">Address</label>
                      <input type="text" name="usrAddress" class="form-control" [value]="userDetail.usrAddress"
                        [attr.disabled]="!isEditMode ? '' : null">
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6">
                      <label for="phone">Phone Number</label>
                      <input type="text" name="usrPhoneNo" class="form-control" [value]="userDetail.usrPhoneNo"
                        [attr.disabled]="!isEditMode ? '' : null" maxlength="10" pattern="[0-9]*"
                        title="Please enter a valid 10-digit phone number" (keypress)="validatePhoneNumber($event)">
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6">
                      <label for="religion">Religion</label>

                      <!-- Display religion when not in edit mode -->
                      <div *ngIf="!isEditMode">
                        <input type="text" name="usrReligion" class="form-control" [value]="this.userDetail.usrReligion"
                          readonly disabled>
                      </div>

                      <!-- Input for entering religion when in edit mode -->
                      <div *ngIf="isEditMode">
                        <input type="text" name="usrReligion" class="form-control"
                          [(ngModel)]="this.userDetail.usrReligion" required>
                      </div>
                    </div>


                    <div class="col-lg-4 col-md-6 col-sm-6">
                      <label>Category</label>

                      <!-- Display category when not in edit mode -->
                      <div *ngIf="!isEditMode">
                        <input type="text" class="form-control"
                          [value]="userDetail.usrCaste === 1 ? 'General' : userDetail.usrCaste === 2 ? 'SC' : userDetail.usrCaste === 3 ? 'ST' : userDetail.usrCaste === 4 ? 'OBC' : 'undefined'"
                          readonly disabled>
                      </div>

                      <!-- Dropdown for selecting category when in edit mode -->
                      <div *ngIf="isEditMode">
                        <select class="form-control" [(ngModel)]="userDetail.usrCaste" name="usrCaste" required
                          [attr.disabled]="!isEditMode ? '' : null">
                          <option value="" disabled selected>Select Category</option>
                          <option value="1">General</option>
                          <option value="2">SC</option>
                          <option value="3">ST</option>
                          <option value="4">OBC</option>
                        </select>
                      </div>
                    </div>

                  </div>
                  <!---ROw-->
                  <div class="row mb-4">
                    <!-- Organization Field -->
                    <div class="col-lg-4 col-md-6 col-sm-6">
                      <label for="organization">Organization</label>

                      <!-- Display organization name when not in edit mode -->
                      <div *ngIf="!isEditMode">
                        <input type="text" class="form-control"
                          [value]=" getOrganizationName(userDetail.usrOrganization)" readonly disabled>
                      </div>

                      <!-- Dropdown for selecting organization when in edit mode -->
                      <div *ngIf="isEditMode">
                        <select class="form-control" [(ngModel)]="userDetail.usrOrganization" name="usrOrganization">
                          <option value="" disabled>Select Organization</option>
                          <option *ngFor="let org of organization" [value]="org.orgId">{{ org.orgName }}</option>
                        </select>
                      </div>
                    </div>

                    <div *ngIf="isCoach" class="col-lg-4 col-md-6 col-sm-6">
                      <label for="Sports Name">Sports Name</label>

                      <!-- Display organization name when not in edit mode -->
                      <div *ngIf="!isEditMode">
                        <input type="text" class="form-control"
                          [value]=" getsportName(userDetail.usrSport)" readonly disabled>
                      </div>

                      <!-- Dropdown for selecting organization when in edit mode -->
                      <div *ngIf="isEditMode">
                        <select class="form-control" [(ngModel)]="userDetail.usrSport" name="usrSport">
                          <option value="" disabled>Select Sports</option>
                          <option *ngFor="let sport of sport" [value]="sport.sportId">{{ sport.sportName }}</option>
                        </select>
                      </div>
                    </div>
                    <!-- Class Field -->
                    <div class="col-lg-4 col-md-6 col-sm-6" *ngIf="isAthlete">
                      <label for="class">Class</label>

                      <!-- Display class name when not in edit mode -->
                      <div *ngIf="!isEditMode">
                        <input type="text" class="form-control" [value]="getClassName(userDetail.usrClass)" readonly
                          disabled>
                      </div>

                      <!-- Dropdown for selecting class when in edit mode -->
                      <div *ngIf="isEditMode">
                        <select class="form-control" [(ngModel)]="userDetail.usrClass" name="usrClass">
                          <option value="" disabled>Select Class</option>
                          <option value="1">Middle School</option>
                          <option value="2">High School</option>
                          <option value="3">College</option>
                        </select>
                      </div>
                    </div>
                    <!-- <div class="col-lg-4 col-md-6 col-sm-6" *ngIf="isCoach">
                      <label for="class">Sport</label>

                      <div *ngIf="!isEditMode">
                        <input type="text" class="form-control" [value]="getsportName(userDetail.usrSport)" readonly
                          disabled>
                      </div>

                     
                      <div *ngIf="isEditMode">
                        <select class="form-control" [(ngModel)]="userDetail.usrSport" name="usrSport">
                          <option *ngFor="let sports of sport" [selected]="sports.sportId == userDetail.usrSport"
                            [value]="sports.sportId">{{ sports.sportName }}</option>

                        </select>
                      </div>
                    </div> -->

                    <div class="col-lg-4 col-md-6 col-sm-6" *ngIf="isSupport">
                      <label for="supportStaff">SupportStaff</label>

                      <div *ngIf="!isEditMode">
                        <input type="text" class="form-control"
                          [value]=" getSupportName(userDetail.usrSupport)" readonly disabled>
                      </div>
  
                      <div *ngIf="isEditMode">
                        <select class="form-control" (change)="onSupportStaffChange($event)" [(ngModel)]="userDetail.usrSupport" name="usrSupport">
                          <option value="" disabled>Select Support Staff</option>
                          <option *ngFor="let supportstaff of supportStaff" [value]="supportstaff.ssId">{{ supportstaff.ssName}}</option>
                        </select>
                      </div>
                    </div>

                    
                  </div>

                  <!-- Row 3 -->
                  <div class="row mb-4">
                    <label for="name3">EmergencyContact</label>
                    <div class="col-lg-4 col-md-6 col-sm-6 mb-3 mb-md-0">
                      <label for="name3">Name</label>
                      <input type="text" name="usrECName" class="form-control" [value]="userDetail.usrECName"
                        [attr.disabled]="!isEditMode ? '' : null">
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6 mb-3 mb-md-0">
                      <label for="relationship">Relationship</label>
                      <input type="text" name="usrECRelationship" class="form-control"
                        [value]="userDetail.usrECRelationship" [attr.disabled]="!isEditMode ? '' : null">
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6">
                      <label for="phone">ECPhone Number</label>
                      <input type="text" name="usrECPhoneNo" class="form-control" [value]="userDetail.usrECPhoneNo"
                        [attr.disabled]="!isEditMode ? '' : null" maxlength="10" pattern="[0-9]*"
                        title="Please enter a valid 10-digit phone number" (keypress)="validateECPhoneNumber($event)">
                    </div>

                  </div>
                </div>
                <div class="form-two form-step">
                  <div class="row mb-4">
                    <div class="col-lg-4 col-md-6 col-sm-6 mb-3 mb-md-0">
                      <label for="umhAllergies">Allergies</label>
                      <input type="text" name="umhAllergies" class="form-control" [value]="userDetail.umhAllergies"
                        [attr.disabled]="!isEditMode ? '' : null">
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6 mb-3 mb-md-0">
                      <label for="umhCurrentMedications">Current Medications</label>
                      <input type="text" name="umhCurrentMedications" class="form-control"
                        [value]="userDetail.umhCurrentMedications" [attr.disabled]="!isEditMode ? '' : null">
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6">
                      <label for="umhPreviousInjuries">Previous Injuries</label>
                      <input type="text" name="umhPreviousInjuries" class="form-control"
                        [value]="userDetail.umhPreviousInjuries" [attr.disabled]="!isEditMode ? '' : null">
                    </div>
                  </div>

                  <!-- Row 2 -->
                  <div class="row mb-4">
                    <div class="col-lg-4 col-md-6 col-sm-6 mb-3 mb-md-0">
                      <label for="umhChronicConditions">Chronic Conditions</label>
                      <input type="text" name="umhChronicConditions" class="form-control"
                        [value]="userDetail.umhChronicConditions" [attr.disabled]="!isEditMode ? '' : null">
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6 mb-3 mb-md-0">
                      <label for="umhSurgeries">Surgeries</label>
                      <input type="text" name="umhSurgeries" class="form-control" [value]="userDetail.umhSurgeries"
                        [attr.disabled]="!isEditMode ? '' : null">
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6">
                      <label for="location2">Family Medical History</label>
                      <input type="text" name="umhFamilyMedicalHistory" class="form-control"
                        [value]="userDetail.umhFamilyMedicalHistory" [attr.disabled]="!isEditMode ? '' : null">
                    </div>
                  </div>

                  <!-- Row 3 -->
                  <div class="row mb-4">
                    <div class="col-lg-4 col-md-6 col-sm-6 mb-3 mb-md-0">
                      <label for="name1">Sleep Patterns</label>
                      <input type="text" name="uliSleepPatterns" class="form-control"
                        [value]="userDetail.uliSleepPatterns" [attr.disabled]="!isEditMode ? '' : null">
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6 mb-3 mb-md-0">
                      <label for="role1">Diet</label>
                      <input type="text" name="uliDiet" class="form-control" [value]="userDetail.uliDiet"
                        [attr.disabled]="!isEditMode ? '' : null">
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6">
                      <label for="uliHydration">Hydration</label>
                      <input type="text" name="uliHydration" class="form-control" [value]="userDetail.uliHydration"
                        [attr.disabled]="!isEditMode ? '' : null">
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6">
                      <label for="uliStressLevels">Stress Levels</label>
                      <input type="text" name="uliStressLevels" class="form-control"
                        [value]="userDetail.uliStressLevels" [attr.disabled]="!isEditMode ? '' : null">
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6">
                      <label for="height">Height</label>
                      <input type="text" name="usrHeight" class="form-control" [value]="userDetail.usrHeight"
                        [attr.disabled]="!isEditMode ? '' : null">
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6">
                      <label for="weight">Weight</label>
                      <input type="text" name="usrWeight" class="form-control" [value]="userDetail.usrWeight"
                        [attr.disabled]="!isEditMode ? '' : null">
                    </div>
                  </div>
                </div>

                <div class="form-three form-step">
                  <div class="row mb-4">
                    <div class="col-lg-4 col-md-6 col-sm-6 mb-3 mb-md-0">
                      <label for="asiPrimarySport">Primary Sport</label>
                      <input type="text" name="asiPrimarySport" class="form-control"
                        [value]="userDetail.asiPrimarySport" [attr.disabled]="!isEditMode ? '' : null">
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6 mb-3 mb-md-0">
                      <label for="asiRole">Position/Role</label>
                      <input type="text" name="asiRole" class="form-control" [value]="userDetail.asiRole"
                        [attr.disabled]="!isEditMode ? '' : null">
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6">
                      <label for="asiSecondarySport">Secondary Sport</label>
                      <input type="text" name="asiSecondarySport" class="form-control"
                        [value]="userDetail.asiSecondarySport" [attr.disabled]="!isEditMode ? '' : null">
                    </div>
                  </div>

                  <!-- Row 2 -->
                  <div class="row mb-4">
                    <div class="col-lg-4 col-md-6 col-sm-6 mb-3 mb-md-0">
                      <label for="asiCurrentClubTeam">Current Club/Team</label>
                      <div *ngIf="!isEditMode">
                        <input type="text" class="form-control" [value]="getTeamNameById(userDetail.asiCurrentClubTeam)"
                          readonly disabled>
                      </div>
                      <div *ngIf="isEditMode">
                        <select class="form-control" [(ngModel)]="userDetail.asiCurrentClubTeam"
                          name="asiCurrentClubTeam" [attr.disabled]="!isEditMode ? '' : null">
                          <option value="" disabled>Select Team</option>
                          <option *ngFor="let team of teams" [selected]="userDetail.asiCurrentClubTeam === team.teamId"
                            [value]="team.teamId">
                            {{ team.teamName }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6 mb-3 mb-md-0">
                      <label for="asiCoach">Coach’s Name</label>
                      <div *ngIf="!isEditMode">
                        <input type="text" class="form-control" [value]="getCoachNameById(userDetail.asiCoach)" readonly
                          disabled>
                      </div>
                      <div *ngIf="isEditMode">
                        <select class="form-control" [(ngModel)]="userDetail.asiCoach" name="asiCoach"
                          [attr.disabled]="!isEditMode ? '' : null">
                          <option value="" disabled>Select Coach</option>
                          <option *ngFor="let tra of trainer" [selected]="userDetail.asiCoach === tra.usrId"
                            [value]="tra.usrId"> {{ tra.usrFullName }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6">
                      <label for="asiTrainingFrequency">Frequency Per Week</label>
                      <input type="number" name="asiTrainingFrequency" class="form-control"
                        [value]="userDetail.asiTrainingFrequency" [attr.disabled]="!isEditMode ? '' : null">
                    </div>
                  </div>

                  <!-- Row 3 -->
                  <div class="row mb-4">
                    <div class="col-lg-4 col-md-6 col-sm-6 mb-3 mb-md-0">
                      <label for="asiTrainingDuration">Duration Per Session</label>
                      <input type="number" name="asiTrainingDuration" class="form-control"
                        [value]="userDetail.asiTrainingDuration" [attr.disabled]="!isEditMode ? '' : null">
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6 mb-3 mb-md-0">
                      <label for="asiCompetitionLevel">Competition Level</label>
                      <input type="text" name="asiCompetitionLevel" class="form-control"
                        [value]="userDetail.asiCompetitionLevel" [attr.disabled]="!isEditMode ? '' : null">
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6">
                      <label for="asiCareerHighlights">Career Highlights</label>
                      <input type="text" name="asiCareerHighlights" class="form-control"
                        [value]="userDetail.asiCareerHighlights" [attr.disabled]="!isEditMode ? '' : null">
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-lg-4 col-md-6 col-sm-6 mb-3 mb-md-0">
                      <label for="asiShortTermGoals">Short-term Goals</label>
                      <input type="text" name="asiShortTermGoals" class="form-control"
                        [value]="userDetail.asiShortTermGoals" [attr.disabled]="!isEditMode ? '' : null">
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6 mb-3 mb-md-0">
                      <label for="asiLongTermGoals">Long-term Goals</label>
                      <input type="text" name="asiLongTermGoals" class="form-control"
                        [value]="userDetail.asiLongTermGoals" [attr.disabled]="!isEditMode ? '' : null">
                    </div>
                  </div>

                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <!-- Left side: Previous and Next buttons -->
                  <div class="btn-group">
                    <button type="button" class="btn-prev" *ngIf="isAthlete" (click)="prevStep()"
                      [disabled]="activeStep === 1">
                      <i class='bx bx-left-arrow-alt'></i>
                    </button>
                    <button type="button" class="btn-next" *ngIf="isAthlete" (click)="nextStep()"
                      [disabled]="activeStep === formSteps.length">
                      <i class='bx bx-right-arrow-alt'></i>
                    </button>
                  </div>

                  <!-- Right side: Update button -->
                  <div class="btn-group">
                    <button *ngIf="isEditMode && !isAthlete" type="submit" class="btn btn-submit"
                      data-bs-dismiss="modal" aria-label="Close" (click)="saveUserDetail($event)">Update</button>

                    <button *ngIf="isEditMode" type="submit" class="btn btn-submit" data-bs-dismiss="modal"
                      aria-label="Close" (click)="saveUserDetails()" [hidden]="activeStep !== 3">Update</button>
                  </div>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
      <a class="popup__close" data-bs-dismiss="modal" aria-label="Close" (click)="onModalClose()">X</a>
    </div>
  </div>
</div>


<!-- Trigger Button -->
<!-- <button type="button" class="btn btn-primary" (click)="openModal()">Show Duplicates</button> -->

<!-- File Upload Modal -->
<div class="modal fade" id="fileUploadModal" tabindex="-1" aria-labelledby="fileUploadModalLabel" aria-hidden="true"
data-bs-backdrop="static" (hidden.bs.modal)="clearSelectedFiles(fileInput)">
  <div class="modal-dialog modal-dialog-centered" style="max-width: 400px;">
    <div class="modal-content" style="max-height: 350px;">
      <div class="modal-header">
        <h5 class="modal-title" id="fileUploadModalLabel">Upload File</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="clearRole()"></button>
      </div>
      <div class="modal-body">
        <div class="mb-3">
          <select [(ngModel)]="selectedRole" class="form-select mt-2">
            <option value="" disabled>Select Role</option> <!-- Placeholder option -->
            <option value="1">Player</option>
            <option value="OrgAdmin">Org Admin</option>
            <option value="Assessor">Assessor</option>
            <option value="Coach">Coach</option>
            <option value="Science Staff">Science Staff</option>
          </select>
        </div>  
        <!-- Added #fileInput to get a reference -->
        <input type="file" (change)="onFileSelected($event)" #fileInput />
      </div>
      <div class="modal-footer">
        <button class="btn btn-primary" (click)="downloadTemplate()" [disabled]="!selectedRole">Download Template</button>
        <button type="submit" data-bs-dismiss="modal" aria-label="Close" class="btn btn-primary"
        [disabled]="!filesSelected || !selectedRole" (click)="registerUpload()">
          <span class="btnText">Upload</span>
        </button>

      </div>
    </div>
  </div>
</div>




<!-- Modal Structure -->
<div class="modal fade" id="duplicateModal" tabindex="-1" aria-labelledby="duplicateModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" style="max-width: 800px;" >
    <div class="modal-content" style=" height: auto;padding: 5px;">
      <div class="modal-header" style="padding: 0px 15px;">
        <div>
          <h5 class="modal-title" id="duplicateModalLabel">Upload Issues</h5>
          <p><strong>Mandatory:</strong> <span *ngIf="this.selectedRole == 1"> FullName, PhoneNo, Email, Password, Organisation, Team, CoachName, CoachEmail</span>
            <span *ngIf="this.selectedRole == 'Coach'"> FullName, PhoneNo, Email, Password, Organisation, Sport Name</span>
            <span *ngIf="this.selectedRole == 'Science Staff'"> FullName, PhoneNo, Email, Password, Organisation, Staff Role</span>
            <span *ngIf="this.selectedRole == 'OrgAdmin' || this.selectedRole == 'Assessor'"> FullName, PhoneNo, Email, Password, Organisation</span></p>
        </div>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="clearRole()"></button>
      </div>
      <div class="modal-body" style="padding: 0px 15px;">
        <!-- AG Grid Table -->
        <ag-grid-angular
          class="ag-theme-quartz"
          style="width: 100%; height: 350px;"
          [rowData]="issueData"
          [columnDefs]="issueColumns"
          [gridOptions]="gridOptions" 
          [pagination]="true"
          [defaultColDef]="defaultColDef">
        </ag-grid-angular>
      </div>
      <div class="modal-footer d-flex justify-content-end">
        <button type="button" class="btn btn-primary" (click)="downloadPDF()">Download</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="errorModal" tabindex="-1" aria-labelledby="errorModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" style="max-width: 350px;">
    <div class="modal-content" style="max-height: 200px;">
      <div class="modal-header">
        <h5 class="modal-title" id="errorModalModalLabel">File Error</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        {{ errorMessage }}  
      </div>
    </div>
  </div>
</div>

<div class="toaster">
  <ng-toast />
</div>