<div class="container-fluid">
  <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="col-sm-6 col-md-7 intro-section">
        <div class="brand-wrapper">
          <img src="../../../assets/image/amslogo.png">
        </div>
        <div class="intro-content-wrapper">
          <h1 class="intro-title">Athlete Management System</h1>
          <p class="intro-text">The Athlete Management System (AMS) is a powerful platform designed to streamline
            athlete performance, health, and development management. Ideal for sports organizations, coaches, and sports
            scientists, AMS offers a comprehensive solution for tracking fitness metrics, training schedules, medical
            history, and performance assessments.</p>
          <!-- <a href="#!" class="btn btn-read-more">Read more</a> -->
        </div>

      </div>
      <div class="col-sm-6 col-md-5 form-section" *ngIf="!resetpassword">
        <div class="login-wrapper">
          <div class="text-container mb-4">
            <h3 class="title mb-2 mt-2">Sign In</h3>
            <div class="d-flex link">
              <p>Don’t have an account?&nbsp;<a routerLink="/register">Create One Now!</a>
              </p>
            </div>
          </div>
          <div class="form-group">
            <label for="email">Email</label>
            <!-- <input type="email" id="email" class="form-control" [(ngModel)]="email" name="email" required> -->
            <input type="email" id="email" formControlName="email" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors?.['required']">Email is required</div>
              <div *ngIf="f.email.errors?.['email']">Enter a valid email address</div>
            </div>
          </div>
          <div class="form-group">
            <label for="password">Password</label>
            <!-- <input type="password" id="password" class="form-control" [(ngModel)]="password" name="password" required> -->
            <input type="password" id="password" formControlName="password" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.password.invalid }" />
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
              <div *ngIf="f.password.errors?.['required']">Password is required</div>
            </div>
            <!-- <p class="resetpwd" (click)="resetPassword()">forgot password</p> -->
          </div>
          <div class="form-group mt-5">
            <button type="submit" class="btn btn-primary">
              <small><i class="far fa-user pr-2"></i>Login</small>
            </button>
          </div>
        </div>
      </div>

      <div class="col-sm-6 col-md-5 form-section" *ngIf="resetpassword">
        <div class="login-wrapper">
          <div class="text-container mb-4">
            <h3 class="title mb-2 mt-2">Reset Password</h3>
          </div>
          <div class="form-group">
            <label for="name">Name</label>
            <input type="name" id="name" class="form-control" [(ngModel)]="name" name="name" required>
          </div>
          <div class="form-group">
            <label for="email">Email</label>
            <input type="email" id="email" class="form-control" [(ngModel)]="email" name="email" required>
          </div>
          <div class="form-group mt-5">
            <button type="submit" (click)="pwdReq()" class="btn btn-primary">
              <small><i class="far fa-user pr-2"></i>Get Mail</small>
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="toaster">

  <ng-toast />

</div>