<div class="body-wrapper">
    <div class="d-flex justify-content-between mb-3 gap-4">
        <!-- <div>
    <select class="form-select" [(ngModel)]="selectedMonth" (change)="filterReportByMonth()">
        <option value="" disabled>Filter Reports</option>
        <option *ngFor="let month of monthList">{{ month }}</option>
      </select>
    </div> -->
        <div>
            <button class="btn btn-primary" (click)="onBack()">Back</button>
        </div>
        <div>
            <button type="button" class="btn btn-primary" (click)="downloadPDF()">Download</button>
        </div>
    </div>
    <div class="contain" id="pdf-content">
        <div class="border-cont">
        <header>
            <div class="headerSection">

                <div class="logoAndName d-flex justify-content-between align-items-center">
                    <div class="image">
                        <img src="/assets/image/logo.jpg" alt="image">
                    </div>
                    <h1 style="font-size: 33px;">AMS Report Card</h1>
                    <div class="logo-image">
                        <img src="/assets/image/new_id.jpg" alt="image">
                    </div>
                </div>

            </div>

        </header>

        <main>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="row d-flex h-100">
                            <div class="col-xl-8 d-flex flex-column p-0">
                  

                                    <div class="card1 flex-grow-1">
                                        <div class="card-body d-flex ">
                                            <div class="row d-flex align-items-center justify-content-center">
                                                <div class="col-xl-4 col-sm-6 d-flex">
                                                    <div class="diagram">
                                                        <img *ngIf="showImage && image"
                                                             src="{{image}}">
                                                    </div>
                                                </div>
                                                <div class="col-xl-8 col-sm-6">
                                                    <div class="items ">
                                                        <div class="col-xl-12 mb-2">

                                                            <h4>
                                                                {{this.userDetail[0]?.usrFullName}}</h4>
                                                                <h5 class="customemail">{{this.userDetail[0]?.usrEmail}}</h5>
                                                        </div>
                                                        <div class="d-flex align-items-start gap-2">
                                                            <div class="col-xl-6">
                                                                <!-- <div class="mb-3">
                                                                    <h3>Email: <span>
                                                                        {{this.userDetail[0]?.usrEmail}}
                                                                        </span></h3>
                                                                </div> -->
                                                                <div class="mb-3">
                                                                    <h3>Age: <span>
                                                                            {{this.age}}
                                                                        </span></h3>


                                                                </div>
                                                                <div class="mb-3">
                                                                    <h3>Gender: <span
                                                                            *ngIf="this.userDetail[0]?.usrGender == 1">Male</span>
                                                                        <span
                                                                            *ngIf="this.userDetail[0]?.usrGender == 2">Female</span>
                                                                        <span
                                                                            *ngIf="this.userDetail[0]?.usrGender != 1 && this.userDetail[0].usrGender != 2">Other</span>
                                                                    </h3>


                                                                </div>
                                                              
                                                                <div class="mb-3">
                                                                    <h3>Height: 
                                                                         <span>{{this.userDetail[0]?.usrHeight ??this.nutrition?.naBodyHeight}}</span>
                                                                    </h3>


                                                                </div>
                                                                <div class="mb-3">
                                                                    <h3>Weight: 
                                                                         <span>{{this.userDetail[0]?.usrWeight ?? this.nutrition?.naBodyWeight}}</span>
                                                                    </h3>


                                                                </div>

                                                            </div>
                                                            <div class="col-xl-6">
                                                                <div class="mb-3">
                                                                    <h3>Sport: <span>
                                                                            {{this.userDetail[0]?.asiPrimarySport}}
                                                                        </span></h3>

                                                                </div>

                                                                <div class="mb-3">
                                                                    <h3>Phone:
                                                                        <span>
                                                                            {{this.userDetail[0]?.usrPhoneNo}}
                                                                        </span></h3>

                                                                </div>
                                                               
                                                                <div class="mb-3">
                                                                    <h3>Events: <span>
                                                                            {{this.userDetail[0]?.asiEvent}}
                                                                        </span></h3>
    
                                                                </div>

                                                                <div class="mb-3">
                                                                    <h3>Team Name: 
                                                                         <span>{{this.allTeams[0]?.teamName}}</span>
                                                                    </h3>
        
        
                                                                </div>
                                                            </div>
                                                        </div>
                                                      
                                                        <div class="col-xl-12">
                                                            <div class="mb-3">
                                                                <h3>DYES/Area: <span>
                                                                        {{this.userDetail[0]?.usrAddress}}
                                                                    </span></h3>

                                                            </div>
                                                        </div>
                                                        <div class="col-xl-12">
                                                        <div class="mb-3">
                                                            <h3>Coach: 
                                                                 <span>{{this.coachName[0]?.usrFullName}}</span>
                                                            </h3>


                                                        </div>
                                                    </div>
                                                    <!-- <div class="col-xl-12">
                                                        <div class="mb-3">
                                                            <h3>Team Name: 
                                                                 <span>{{this.allTeams[0]?.teamName}}</span>
                                                            </h3>


                                                        </div>
                                                        </div> -->

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                           
                            </div>
                            <div class="col-xl-4 d-flex flex-column p-0">
                                    <div class="card2 flex-grow-1">
                                        <div class="card-body">
                                            <div class="items d-flex flex-column justify-content-between align-items-center">
                                                <span class="fs-14 font-w400" *ngIf="this.summarydetails">{{this.summarydetails[0].ausRemark}}</span>
                                                    <div class="text-center">
                                                <h2 class="fs-18 font-w700 mb-0">{{calculateOverallAverage()}}%</h2>
                                                <p>Overall Score</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                
                            </div>

                        </div>
                    </div>
                </div>

                <!-- Physical Fitness and Performance Analysis -->

                <div class="row mb-2">
                    <div class="col-xl-12">
                        <div class="row">
                            <div class="container p-0">
                                <div class="row justify-content-center">
                                    <div class="col-md-12">
                                        <div class="card-custom">
                                            <div class="header-cont mb-5">
                                                <h2>Physical Fitness and Performance Analysis</h2>
                                            </div>
                                            <div class="row px-4">
                                                <div class="bench-tag d-flex justify-content-end align-items-center">
                                                    <img src="/assets/newimages/bench-mark.png" alt="IBM" style="width: 25px; height: 25px;" />
                                                  <h5> IBM :
                                                    <span>
                                                        International BenchMark
                                                     
                                                    </span>
                                                  </h5>
                                                </div>
                                              </div>
                                            <div class="row px-4">
                                                <div class="mb-5">
                                                    <div class="main-title">
                                                        <h4>Endurance</h4>
                                                    </div>
                                                    <div class="subtitle mb-2">
                                                        <h6>Yo-Yo Test

                                                        </h6>
                                                    </div>
                                                    <div class="col-md-12 d-flex equal-height p-0">
                                                        <div class="col-md-9 p-0">
                                                            <table #fitnessTable class="custom-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Test</th>
                                                                        <th>Score</th>
                                                                        <th>pctl</th>
                                                                        <th>Rating</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            Distance Covered (m)
                                                                            <p class="desc">IBM : {{ getBenchMarkRange('coaYoYoTest') }}</p>
                                                                            <!-- <img class="table-img"
                                                                                src="/assets/newimages/med-ball.jpg"> -->
                                                                        </td>
                                                                        <td>{{ this.cardio?.coaYoYoTest ?? 0 }}</td>
                                                                        <td>{{this.cardioReport?.yoYoZScorePercent ??
                                                                            this.cardio?.coaYoYoTest ?? 0}} %</td>
                                                                        <td [ngClass]="(this.cardioReport?.yoYoZScorePercent ??
                                                                                this.cardio?.coaYoYoTest) >= 85 ? 'dark-green' :
                                                                            (this.cardioReport?.yoYoZScorePercent ??
                                                                                this.cardio?.coaYoYoTest) >= 70 ? 'light-green' :
                                                                            (this.cardioReport?.yoYoZScorePercent ??
                                                                                this.cardio?.coaYoYoTest) >= 40 ? 'yellow' :
                                                                            'red'" class="rating">
                                                                            {{getScoreLabel(this.cardioReport?.yoYoZScorePercent
                                                                            ??
                                                                            this.cardio?.coaYoYoTest ?? 0)}}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Level
                                                                            <p class="desc">IBM : {{ getBenchMarkRange('pfaLevel') }}</p>
                                                                        </td>
                                                                        <td>{{ this.fitness?.pfaLevel ?? 0
                                                                            }}</td>
                                                                        <td>{{ this.fitness?.pfaLevel ?? 0
                                                                            }} %</td>
                                                                        <td [ngClass]="(this.fitness?.pfaLevel ) >= 15 ? 'dark-green' :
                                                                            ( this.fitness?.pfaLevel ) >= 12 ? 'light-green' :
                                                                            ( this.fitness?.pfaLevel ) >= 10 ? 'yellow' :
                                                                            'red'" class="rating">
                                                                            {{getLevelScoreLabel(this.fitness?.pfaLevel
                                                                            ?? 0)}}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            VO2 Max (ml/kg/min)
                                                                            <p class="desc">IBM : {{ getBenchMarkRange('pfaVO2') }}</p>
                                                                            <!-- <img class="table-img"
                                                                                src="/assets/newimages/med-ball.jpg"> -->
                                                                        </td>

                                                                        <td>{{ this.fitness?.pfaVO2 ?? 0}}</td>
                                                                        <td>{{ this.fitness?.pfaVO2 ?? 0}} %</td>
                                                                        <td [ngClass]="(this.fitness?.pfaVO2) >= 85 ? 'dark-green' :
                                                                            (this.fitness?.pfaVO2) >= 70 ? 'light-green' :
                                                                             (this.fitness?.pfaVO2) >= 40 ? 'yellow' :'red'"
                                                                            class="rating">
                                                                            {{getScoreLabel(this.fitness?.pfaVO2 ?? 0)}}
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div class="col-md-3 p-0">
                                                            <div
                                                                class="donut-container d-flex align-items-center justify-content-center">
                                                                <div class="donut-circle"
                                                                    [ngClass]="(this.fitness?.pfaVO2) >= 85 ? 'dark-green' :
                                                                            (this.fitness?.pfaVO2) >= 70 ? 'light-green' :
                                                                             (this.fitness?.pfaVO2) >= 40 ? 'yellow' :'red'">
                                                                    <div class="inner-circle">
                                                                        <h2>{{ this.fitness?.pfaVO2 ?? 0}}%</h2>
                                                                    </div>

                                                                </div>
                                                                <p class="score-tag">Z-score</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="mb-5">
                                                    <div class="subtitle mb-2">
                                                        <h6>speed

                                                        </h6>
                                                    </div>
                                                    <div class="col-md-12 d-flex equal-height p-0">
                                                        <div class="col-md-9 p-0">
                                                            <table #fitnessTable class="custom-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Test</th>
                                                                        <th>Score</th>
                                                                        <th>pctl</th>
                                                                        <th>Rating</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>

                                                                    <tr>
                                                                        <td>
                                                                            Speed
                                                                            <p class="desc">IBM : {{ getBenchMarkRange('pfaSpeed') }}</p>
                                                                        </td>

                                                                        <td>{{this.fitness?.pfaSpeed ?? 0}}</td>
                                                                        <td>{{ this.percentages.pfaSpeed ??
                                                                            this.fitness?.pfaSpeed ?? 0}} %</td>
                                                                        <td [ngClass]="(this.percentages.pfaSpeed ?? this.fitness?.pfaSpeed ) >= 85 ? 'dark-green' :
                                                                            (this.percentages.pfaSpeed ?? this.fitness?.pfaSpeed ) >= 70 ? 'light-green' :
                                                                            (this.percentages.pfaSpeed ?? this.fitness?.pfaSpeed ) >= 40 ? 'yellow' :
                                                                            'red'" class="rating">
                                                                            {{getScoreLabel(this.percentages.pfaSpeed ??
                                                                            this.fitness?.pfaSpeed ?? 0)}}
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div class="col-md-3 p-0">
                                                            <div
                                                                class="donut-container d-flex align-items-center justify-content-center">
                                                                <div class="donut-circle" [ngClass]="(calculateSpeedAverage()) >= 85 ? 'dark-green' :
                                                                                        (calculateSpeedAverage()) >= 70 ? 'light-green' :
                                                                                        (calculateSpeedAverage()) >= 40 ? 'yellow' :
                                                                                        'red'">
                                                                    <div class="inner-circle">
                                                                        <h2>{{calculateSpeedAverage()}}%</h2>
                                                                    </div>
                                                                    <p class="score-tag">Z-score</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="mb-5">

                                                    <div class="main-title">
                                                        <h4>Agility</h4>
                                                    </div>
                                                    <div class="subtitle mb-2">
                                                        <h6>T-test

                                                        </h6>
                                                    </div>
                                                    <div class="col-md-12 d-flex equal-height p-0">
                                                        <div class="col-md-9 p-0">
                                                            <table #fitnessTable class="custom-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Test</th>
                                                                        <th>Score</th>
                                                                        <th>pctl</th>
                                                                        <th>Rating</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>

                                                                    <tr>
                                                                        <td>
                                                                            T-test (sec)
                                                                            <p class="desc">IBM : {{ getBenchMarkRange('pfaTTest') }}</p>
                                                                        </td>

                                                                        <td>{{this.fitness?.pfaTTest ?? 0}}</td>
                                                                        <td>{{ this.percentages.pfaTTest ??
                                                                            this.fitness?.pfaTTest ?? 0}} %</td>
                                                                        <td [ngClass]="(this.percentages.pfaTTest ?? this.fitness?.pfaTTest ) >= 85 ? 'dark-green' :
                                                                            (this.percentages.pfaTTest ?? this.fitness?.pfaTTest ) >= 70 ? 'light-green' :
                                                                            (this.percentages.pfaTTest ?? this.fitness?.pfaTTest ) >= 40 ? 'yellow' :
                                                                            'red'" class="rating">
                                                                            {{getScoreLabel(this.percentages.pfaTTest ??
                                                                            this.fitness?.pfaTTest ?? 0)}}
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div class="col-md-3 p-0">
                                                            <div
                                                                class="donut-container d-flex align-items-center justify-content-center">
                                                                <div class="donut-circle" [ngClass]="(calculateTTestAverage()) >= 85 ? 'dark-green' :
                                                                                        (calculateTTestAverage()) >= 70 ? 'light-green' :
                                                                                        (calculateTTestAverage()) >= 40 ? 'yellow' :
                                                                                        'red'">
                                                                    <div class="inner-circle">
                                                                        <h2>{{calculateTTestAverage()}}%</h2>
                                                                    </div>
                                                                    <p class="score-tag">Z-score</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="mb-5">
                                                    <div class="main-title">
                                                        <h4>Strength</h4>
                                                    </div>
                                                    <div class="subtitle mb-2">
                                                        <h6>Upper Body

                                                        </h6>
                                                    </div>
                                                    <div class="col-md-12 mb-4 d-flex equal-height p-0">
                                                        <div class="col-md-9 p-0">
                                                            <table #fitnessTable class="custom-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Test</th>
                                                                        <th>Score</th>
                                                                        <th>pctl</th>
                                                                        <th>Rating</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td >
                                                                            <div class="d-flex align-items-center justify-content-between">
                                                                            <div>
                                                                            Push Ups (min)
                                                                            <p class="desc">IBM : {{ getBenchMarkRange('pfaPushups') }}</p>
                                                                    </div>
                                                                            <img class="table-img"
                                                                                src="/assets/image/push.jpg">
                                                                            </div>
                                                                        </td>
                                                                        <td>{{ this.fitness?.pfaPushups ?? 0 }}</td>
                                                                        <td>{{this.percentages.pfaPushups ?? this.fitnessReport?.pushupZScorePercent
                                                                            ??
                                                                            this.fitness?.pfaPushups ?? 0}} %</td>
                                                                        <td [ngClass]="(this.percentages.pfaPushups ?? this.fitnessReport?.pushupZScorePercent ?? this.fitness?.pfaPushups) >= 85 ? 'dark-green' :
                                                                            (this.percentages.pfaPushups ?? this.fitnessReport?.pushupZScorePercent ?? this.fitness?.pfaPushups) >= 70 ? 'light-green' :
                                                                            (this.percentages.pfaPushups ?? this.fitnessReport?.pushupZScorePercent ?? this.fitness?.pfaPushups) >= 40 ? 'yellow' :
                                                                            'red'" class="rating">
                                                                            {{getScoreLabel(this.percentages.pfaPushups ?? this.fitnessReport?.pushupZScorePercent
                                                                            ??
                                                                            this.fitness?.pfaPushups ?? 0)}}
                                                                        </td>
                                                                    </tr>

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div class="col-md-3 p-0">
                                                            <div
                                                                class="donut-container d-flex align-items-center justify-content-center">
                                                                <div class="donut-circle" [ngClass]="(calculatePushUpsAverage()) >= 85 ? 'dark-green' :
                                                                            (calculatePushUpsAverage()) >= 70 ? 'light-green' :
                                                                            (calculatePushUpsAverage()) >= 40 ? 'yellow' :
                                                                            'red'">
                                                                    <div class="inner-circle">
                                                                        <h2>{{ calculatePushUpsAverage()}}%</h2>
                                                                    </div>
                                                                    <p class="score-tag">Z-score</p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="subtitle mb-2">
                                                        <h6>Lower Body

                                                        </h6>
                                                    </div>
                                                    <div class="col-md-12 mb-4 d-flex equal-height p-0">
                                                        <div class="col-md-9 p-0">
                                                            <table #fitnessTable class="custom-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Test</th>
                                                                        <th>Score</th>
                                                                        <th>pctl</th>
                                                                        <th>Rating</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>

                                                                    <tr>
                                                                        <td>
                                                                            <div class="d-flex align-items-center justify-content-between">
                                                                                <div>
                                                                            Squats (min)
                                                                            <p class="desc">IBM : {{ getBenchMarkRange('pfaSquat') }}</p>
                                                                        </div>
                                                                            <img class="table-img"
                                                                            src="/assets/newimages/squat.jpg">
                                                                            </div>
                                                                        </td>
                                                                        <td>{{ this.fitness?.pfaSquat ?? 0
                                                                            }}</td>
                                                                        <td>{{ this.percentages.pfaSquat ??
                                                                            this.fitnessReport?.squatZScorePercent
                                                                            ??
                                                                            this.fitness?.pfaSquat ?? 0}} %</td>
                                                                        <td [ngClass]="( this.percentages.pfaSquat ?? this.fitnessReport?.squatZScorePercent
                                                                        ??
                                                                        this.fitness?.pfaSquat) >= 85 ? 'dark-green' :
                                                                            (this.percentages.pfaSquat ?? this.fitnessReport?.squatZScorePercent
                                                                            ??
                                                                            this.fitness?.pfaSquat) >= 70 ? 'light-green' :
                                                                            (this.percentages.pfaSquat ?? this.fitnessReport?.squatZScorePercent
                                                                            ??
                                                                            this.fitness?.pfaSquat) >= 40 ? 'yellow' :
                                                                            'red'" class="rating">
                                                                            {{getScoreLabel(this.percentages.pfaSquat ?? this.fitnessReport?.squatZScorePercent
                                                                            ??
                                                                            this.fitness?.pfaSquat ?? 0)}}</td>
                                                                    </tr>
                                                                 
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div class="col-md-3 p-0">
                                                            <div
                                                                class="donut-container d-flex align-items-center justify-content-center">
                                                                <div class="donut-circle" [ngClass]="( calculateFitnessAverages()) >= 85 ? 'dark-green' :
                                                                (calculateFitnessAverages()) >= 70 ? 'light-green' :
                                                                (calculateFitnessAverages()) >= 40 ? 'yellow' :
                                                                'red'">
                                                                    <div class="inner-circle">
                                                                        <h2>{{ calculateFitnessAverages() }}%</h2>
                                                                    </div>
                                                                    <p class="score-tag">Z-score</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="subtitle mb-2">
                                                        <h6>abdominal/Core strength

                                                        </h6>
                                                    </div>
                                                    <div class="col-md-12 mb-4 d-flex equal-height p-0">
                                                        <div class="col-md-9 p-0">
                                                            <table #fitnessTable class="custom-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Test</th>
                                                                        <th>Score</th>
                                                                        <th>pctl</th>
                                                                        <th>Rating</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td> 
                                                                            <div class="d-flex align-items-center justify-content-between">
                                                                                <div>
                                                                            Partial Curl Ups (min)
                                                                            <p class="desc">IBM : {{ getBenchMarkRange('pfaPartialCurlUp') }}</p>
                                                                        </div>
                                                                            <img class="table-img"
                                                                                src="/assets/newimages/curl.jpg">
                                                                                </div>
                                                                        </td>

                                                                        <td>{{this.fitness?.pfaPartialCurlUp ?? 0}}</td>
                                                                        <td>{{this.fitness?.pfaPartialCurlUp ?? 0}} %
                                                                        </td>
                                                                        <td [ngClass]="( this.fitness?.pfaPartialCurlUp) >= 85 ? 'dark-green' :
                                                                            (  this.fitness?.pfaPartialCurlUp) >= 70 ? 'light-green' :
                                                                            ( this.fitness?.pfaPartialCurlUp) >= 40 ? 'yellow' :
                                                                            'red'" class="rating">
                                                                            {{getScoreLabel(this.fitness?.pfaPartialCurlUp
                                                                            ?? 0)}}
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div class="col-md-3 p-0">
                                                            <div
                                                                class="donut-container d-flex align-items-center justify-content-center">
                                                                <div class="donut-circle" [ngClass]="( calculateCurlUpAverages()) >= 85 ? 'dark-green' :
                                                                (calculateCurlUpAverages()) >= 70 ? 'light-green' :
                                                                (calculateCurlUpAverages()) >= 40 ? 'yellow' :
                                                                'red'">
                                                                    <div class="inner-circle">
                                                                        <h2>{{ calculateCurlUpAverages() }}%</h2>
                                                                    </div>
                                                                    <p class="score-tag">Z-score</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="subtitle mb-2">
                                                        <h6>Grip Strength

                                                        </h6>
                                                    </div>
                                                    <div class="col-md-12 d-flex equal-height p-0">
                                                        <div class="col-md-9 p-0">
                                                            <table #fitnessTable class="custom-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Test</th>
                                                                        <th>Score</th>
                                                                        <th>pctl</th>
                                                                        <th>Rating</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <div class="d-flex align-items-center justify-content-between">
                                                                                <div>
                                                                            Right Grip (kgs)
                                                                            <p class="desc">IBM : {{ getBenchMarkRange('pfaGripStrengthRight') }}</p>
                                                                        </div>
                                                                            <img class="table-img"
                                                                                src="/assets/image/grip.jpg">
                                                                                </div>
                                                                        </td>
                                                                        <td>{{this.fitness?.pfaGripStrengthRight ?? 0}}
                                                                        </td>
                                                                        <td>{{this.percentages.pfaGripStrengthRight ?? this.fitness?.pfaGripStrengthRight ?? 0}}
                                                                            %</td>
                                                                        <td [ngClass]="(this.percentages.pfaGripStrengthRight ?? this.fitness?.pfaGripStrengthRight) >= 85 ? 'dark-green' :
                                                                                (this.percentages.pfaGripStrengthRight ?? this.fitness?.pfaGripStrengthRight) >= 70 ? 'light-green' :
                                                                                (this.percentages.pfaGripStrengthRight ?? this.fitness?.pfaGripStrengthRight) >= 40 ? 'yellow' :
                                                                                'red'" class="rating">
                                                                            {{getScoreLabel(this.percentages.pfaGripStrengthRight ?? this.fitness?.pfaGripStrengthRight
                                                                            ?? 0)}}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <div class="d-flex align-items-center justify-content-between">
                                                                                <div>
                                                                            Left Grip (kgs)
                                                                            <p class="desc">IBM : {{ getBenchMarkRange('pfaGripStrengthLeft') }}</p>
                                                                        </div>
                                                                            <img class="table-img"
                                                                                src="/assets/image/grip.jpg">
                                                                                </div>
                                                                        </td>
                                                                        <td>{{ this.percentages.pfaGripStrengthLeft ??
                                                                            this.fitness?.pfaGripStrengthLeft ?? 0
                                                                            }}</td>
                                                                        <td>{{
                                                                            this.percentages.pfaGripStrengthLeft ??
                                                                            this.fitness?.pfaGripStrengthLeft ?? 0}} %
                                                                        </td>
                                                                        <td [ngClass]="(this.percentages.pfaGripStrengthLeft ?? this.fitness?.pfaGripStrengthLeft) >= 85 ? 'dark-green' :
                                                                                (this.percentages.pfaGripStrengthLeft ?? this.fitness?.pfaGripStrengthLeft) >= 70 ? 'light-green' :
                                                                                (this.percentages.pfaGripStrengthLeft ?? this.fitness?.pfaGripStrengthLeft) >= 40 ? 'yellow' :
                                                                                'red'" class="rating">
                                                                            {{getScoreLabel(this.percentages.pfaGripStrengthLeft
                                                                            ?? this.fitness?.pfaGripStrengthLeft ?? 0)}}
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div class="col-md-3 p-0">
                                                            <div
                                                                class="donut-container d-flex align-items-center justify-content-center">
                                                                <div class="donut-circle" [ngClass]="(calculateGripStrengthAverages()) >= 85 ? 'dark-green' :
                                                                           (calculateGripStrengthAverages()) >= 70 ? 'light-green' :
                                                                           (calculateGripStrengthAverages()) >= 40 ? 'yellow' :
                                                                           'red'">
                                                                    <div class="inner-circle">
                                                                        <h2>{{(calculateGripStrengthAverages())}}%
                                                                        </h2>
                                                                    </div>
                                                                    <p class="score-tag">Z-score</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="mb-5">
                                                    <div class="main-title">
                                                        <h4>Flexibility</h4>
                                                    </div>
                                                    <div class="subtitle mb-2">
                                                        <h6>Upper Body

                                                        </h6>
                                                    </div>
                                                    <div class="col-md-12 mb-4 d-flex equal-height p-0">
                                                        <div class="col-md-9 p-0">
                                                            <table #fitnessTable class="custom-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Test</th>
                                                                        <th>Score</th>
                                                                        <th>pctl</th>
                                                                        <th>Rating</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>

                                                                    <tr>
                                                                        <td>
                                                                            <div class="d-flex align-items-center justify-content-between">
                                                                                <div>
                                                                            Apley's Scratch Test (cm)
                                                                            <p class="desc">IBM : {{ getBenchMarkRange('pfaAppleysScratchTest') }}</p>
                                                                        </div>
                                                                            <img class="table-img"
                                                                                src="/assets/image/apley.jpg">
                                                                                </div>
                                                                        </td>
                                                                        <td>{{
                                                                            this.fitness?.pfaAppleysScratchTest
                                                                            ?? 0}}</td>
                                                                        <td>{{
                                                                            this.fitness?.pfaAppleysScratchTest
                                                                            ?? 0}} %</td>
                                                                        <td [ngClass]="(this.fitness?.pfaAppleysScratchTest ) >= 85 ? 'dark-green' :
                                                                            (this.fitness?.pfaAppleysScratchTest ) >= 70 ? 'light-green' :
                                                                            (this.fitness?.pfaAppleysScratchTest ) >= 40 ? 'yellow' :
                                                                            'red'" class="rating">{{getScoreLabel(
                                                                            this.fitness?.pfaAppleysScratchTest
                                                                            ?? 0)}}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div class="col-md-3 p-0">
                                                            <div
                                                                class="donut-container d-flex align-items-center justify-content-center">
                                                                <div class="donut-circle" [ngClass]="( 
                                                                    this.fitness?.pfaAppleysScratchTest
                                                                    ?? 0) >= 85 ? 'dark-green' :
                                                                       ( 
                                                                        this.fitness?.pfaAppleysScratchTest
                                                                        ?? 0) >= 70 ? 'light-green' :
                                                                       ( 
                                                                        this.fitness?.pfaAppleysScratchTest
                                                                        ?? 0) >= 40 ? 'yellow' :
                                                                       'red'">
                                                                    <div class="inner-circle">
                                                                        <h2>{{ 
                                                                            this.fitness?.pfaAppleysScratchTest
                                                                            ?? 0 }}%
                                                                        </h2>
                                                                    </div>
                                                                    <p class="score-tag">Z-score</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                   
                                                    <div class="subtitle mb-2">
                                                        <h6>Lower Body

                                                        </h6>
                                                    </div>
                                                    <div class="col-md-12  d-flex equal-height p-0">
                                                        <div class="col-md-9 p-0">
                                                            <table #fitnessTable class="custom-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Test</th>
                                                                        <th>Score</th>
                                                                        <th>pctl</th>
                                                                        <th>Rating</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <div class="d-flex align-items-center justify-content-between">
                                                                                <div>
                                                                            Sit and Reach (cm)
                                                                            <p class="desc">IBM : {{ getBenchMarkRange('pfaSitNReach') }}</p>
                                                                        </div>
                                                                            <img class="table-img"
                                                                                src="/assets/image/sit.jpg">
                                                                                </div>
                                                                        </td>
                                                                        <td>{{this.fitness?.pfaSitNReach ?? 0}}</td>
                                                                        <td>{{this.percentages.pfaSitNReach ?? this.fitness?.pfaSitNReach ?? 0}} %</td>
                                                                        <td [ngClass]="(this.percentages.pfaSitNReach ?? this.fitness?.pfaSitNReach ) >= 85 ? 'dark-green' :
                                                                            (this.percentages.pfaSitNReach ??this.fitness?.pfaSitNReach ) >= 70 ? 'light-green' :
                                                                            (this.percentages.pfaSitNReach ??this.fitness?.pfaSitNReach ) >= 40 ? 'yellow' :
                                                                            'red'" class="rating">
                                                                            {{getScoreLabel(this.percentages.pfaSitNReach
                                                                            ?? this.fitness?.pfaSitNReach ?? 0)}}
                                                                        </td>
                                                                    </tr>

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div class="col-md-3 p-0">
                                                            <!-- <div
                                                                class="donut-container d-flex align-items-center justify-content-center">
                                                                <div class="donut-circle" [ngClass]="(averagePctlValues[7]) >= 85 ? 'dark-green' :
                                                                       (averagePctlValues[7]) >= 70 ? 'light-green' :
                                                                       (averagePctlValues[7]) >= 40 ? 'yellow' :
                                                                       'red'">
                                                                    <div class="inner-circle">
                                                                        <h2>{{ averagePctlValues[7] }}%
                                                                        </h2>
                                                                    </div>

                                                                </div>
                                                            </div> -->
                                                            <div
                                                                class="donut-container d-flex align-items-center justify-content-center">
                                                                <div class="donut-circle" [ngClass]="(this.percentages.pfaSitNReach ?? this.fitness?.pfaSitNReach ?? 0) >= 85 ? 'dark-green' :
                                                                       (this.percentages.pfaSitNReach ?? this.fitness?.pfaSitNReach ?? 0) >= 70 ? 'light-green' :
                                                                       (this.percentages.pfaSitNReach ?? this.fitness?.pfaSitNReach ?? 0) >= 40 ? 'yellow' :
                                                                       'red'">
                                                                    <div class="inner-circle">
                                                                        <h2>{{ this.percentages.pfaSitNReach ?? this.fitness?.pfaSitNReach ?? 0 }}%
                                                                        </h2>
                                                                    </div>
                                                                    <p class="score-tag">Z-score</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>

                                                <div class="mb-5">
                                                    <div class="main-title mb-2">
                                                        <h4>Balance</h4>
                                                    </div>
                                                    <div class="col-md-12 d-flex equal-height p-0">
                                                        <div class="col-md-9 p-0">
                                                            <table #fitnessTable class="custom-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Test</th>
                                                                        <th>Score</th>
                                                                        <th>pctl</th>
                                                                        <th>Rating</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <div class="d-flex align-items-center justify-content-between">
                                                                                <div>
                                                                            Static Balance Right Eyes Open (min/sec)
                                                                            <p class="desc">IBM : {{ getBenchMarkRange('pfaSingleLegBalanceRightEyesOpen') }}</p>
                                                                        </div>
                                                                            <img class="table-img"
                                                                                src="/assets/image/balance-r.jpg">
                                                                                </div>
                                                                        </td>
                                                                        <td>{{this.fitness?.pfaSingleLegBalanceRightEyesOpen
                                                                            ?? 0}}</td>
                                                                        <td>{{this.percentages.pfaSingleLegBalanceRightEyesOpen ??
                                                                            this.fitness?.pfaSingleLegBalanceRightEyesOpen
                                                                            ?? 0 }} %</td>
                                                                        <td [ngClass]="( this.percentages.pfaSingleLegBalanceRightEyesOpen ?? this.fitness?.pfaSingleLegBalanceRightEyesOpen ) >= 85 ? 'dark-green' :
                                                                            ( this.percentages.pfaSingleLegBalanceRightEyesOpen ?? this.fitness?.pfaSingleLegBalanceRightEyesOpen ) >= 70 ? 'light-green' :
                                                                            ( this.percentages.pfaSingleLegBalanceRightEyesOpen ?? this.fitness?.pfaSingleLegBalanceRightEyesOpen ) >= 40 ? 'yellow' :
                                                                            'red'" class="rating">
                                                                            {{getScoreLabel(this.percentages.pfaSingleLegBalanceRightEyesOpen ??
                                                                            this.fitness?.pfaSingleLegBalanceRightEyesOpen
                                                                            ?? 0)}}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <div class="d-flex align-items-center justify-content-between">
                                                                                <div>
                                                                            Static Balance Left Eyes Open (min/sec)
                                                                            <p class="desc">IBM : {{ getBenchMarkRange('pfaSingleLegBalanceLeftEyesOpen') }}</p>
                                                                        </div>
                                                                            <img class="table-img"
                                                                                src="/assets/image/balance-s.jpg">
                                                                                </div>
                                                                        </td>
                                                                        <td>{{this.fitness?.pfaSingleLegBalanceLeftEyesOpen
                                                                            ?? 0}}</td>
                                                                        <td>{{this.percentages.pfaSingleLegBalanceLeftEyesOpen ??
                                                                            this.fitness?.pfaSingleLegBalanceLeftEyesOpen
                                                                            ?? 0 }} %</td>
                                                                        <td [ngClass]="(this.percentages.pfaSingleLegBalanceLeftEyesOpen ?? this.fitness?.pfaSingleLegBalanceLeftEyesOpen ) >= 85 ? 'dark-green' :
                                                                            (this.percentages.pfaSingleLegBalanceLeftEyesOpen ?? this.fitness?.pfaSingleLegBalanceLeftEyesOpen ) >= 70 ? 'light-green' :
                                                                            (this.percentages.pfaSingleLegBalanceLeftEyesOpen ?? this.fitness?.pfaSingleLegBalanceLeftEyesOpen ) >= 40 ? 'yellow' :
                                                                            'red'" class="rating">
                                                                            {{getScoreLabel(this.percentages.pfaSingleLegBalanceLeftEyesOpen ??
                                                                            this.fitness?.pfaSingleLegBalanceLeftEyesOpen
                                                                            ?? 0 )}}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            Static Balance Right Eyes Close (min/sec)
                                                                            <p class="desc">IBM : {{ getBenchMarkRange('pfaSingleLegBalanceRightEyesClose') }}</p>
                                                                        </td>
                                                                        <td>{{
                                                                            this.fitness?.pfaSingleLegBalanceRightEyesClose
                                                                            ?? 0}}</td>
                                                                        <td>{{this.percentages.pfaSingleLegBalanceRightEyesClose ??
                                                                            this.fitness?.pfaSingleLegBalanceRightEyesClose
                                                                            ?? 0 }} %</td>
                                                                        <td [ngClass]="(this.percentages.pfaSingleLegBalanceRightEyesClose?? this.fitness?.pfaSingleLegBalanceRightEyesClose ) >= 85 ? 'dark-green' :
                                                                            (this.percentages.pfaSingleLegBalanceRightEyesClose?? this.fitness?.pfaSingleLegBalanceRightEyesClose ) >= 70 ? 'light-green' :
                                                                            (this.percentages.pfaSingleLegBalanceRightEyesClose?? this.fitness?.pfaSingleLegBalanceRightEyesClose ) >= 40 ? 'yellow' :
                                                                            'red'" class="rating">
                                                                            {{getScoreLabel(this.percentages.pfaSingleLegBalanceRightEyesClose??
                                                                            this.fitness?.pfaSingleLegBalanceRightEyesClose
                                                                            ?? 0)}}</td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td>
                                                                            Static Balance Left Eyes Close (min/sec)
                                                                            <p class="desc">IBM : {{ getBenchMarkRange('pfaSingleLegBalanceLeftEyesClose') }}</p>
                                                                        </td>
                                                                        <td>{{
                                                                            this.fitness?.pfaSingleLegBalanceLeftEyesClose
                                                                            ?? 0}}</td>
                                                                        <td>{{this.percentages.pfaSingleLegBalanceLeftEyesClose ??
                                                                            this.fitness?.pfaSingleLegBalanceLeftEyesClose
                                                                            ?? 0 }} %</td>
                                                                        <td [ngClass]="(this.percentages.pfaSingleLegBalanceLeftEyesClose ?? this.fitness?.pfaSingleLegBalanceLeftEyesClose ) >= 85 ? 'dark-green' :
                                                                            (this.percentages.pfaSingleLegBalanceLeftEyesClose ?? this.fitness?.pfaSingleLegBalanceLeftEyesClose ) >= 70 ? 'light-green' :
                                                                            (this.percentages.pfaSingleLegBalanceLeftEyesClose ?? this.fitness?.pfaSingleLegBalanceLeftEyesClose ) >= 40 ? 'yellow' :
                                                                            'red'" class="rating">
                                                                            {{getScoreLabel(this.percentages.pfaSingleLegBalanceLeftEyesClose ??
                                                                            this.fitness?.pfaSingleLegBalanceLeftEyesClose
                                                                            ?? 0)}}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div class="col-md-3 p-0">
                                                            <div
                                                                class="donut-container d-flex align-items-center justify-content-center">
                                                                <div class="donut-circle" [ngClass]="(calculateAveragePctl()) >= 85 ? 'dark-green' :
                                                                       (calculateAveragePctl()) >= 70 ? 'light-green' :
                                                                       (calculateAveragePctl()) >= 40 ? 'yellow' :
                                                                       'red'">
                                                                    <div class="inner-circle">
                                                                        <h2>{{  calculateAveragePctl() }}%
                                                                        </h2>
                                                                    </div>
                                                                    <p class="score-tag">Z-score</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Cognitive Skills -->
                <div class="row mb-2">
                    <div class="col-xl-12">
                        <div class="row">
                            <div class="container p-0">
                                <div class="row justify-content-center">
                                    <div class="col-md-12">
                                        <div class="card-custom">
                                            <div class="header-cont mb-5">
                                                <h2>Cognitive Skills</h2>
                                            </div>

                                            <div class="row px-4">
                                                <div class="col-md-12 d-flex flex-column">
                                                    <div class="mb-5">
                                                        <!-- <h2 class="sub-title">Flexibility</h2> -->
                                                        <div class="col-md-12 d-flex equal-height p-0">
                                                            <div class="col-md-9 p-0">
                                                                <table #fitnessTable class="custom-table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Test</th>
                                                                            <th>Score</th>
                                                                            <th>pctl</th>
                                                                            <th>Rating</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>
                                                                                Reaction Time (ms)
                                                                                <p class="desc">IBM : {{ getBenchMarkRange('pfaSpeed') }}</p>
                                                                            </td>
                                                                            <td>{{ this.cognitive?.cnaRulerDrop ?? 0}}
                                                                            </td>
                                                                            <td>{{this.cognitiveReport?.rulerDropZScorePercent ?? this.cognitivePercentages.cnaRulerDrop ?? this.cognitive?.cnaRulerDrop ?? 0}} %</td>
                                                                            <td [ngClass]="(this.leveloneReport?.lorRulerDropMarkRange ?? this.cognitivePercentages.cnaRulerDrop ?? this.cognitiveReport?.rulerDropZScorePercent ?? this.cognitivePercentages.cnaRulerDrop ?? this.cognitive?.cnaRulerDrop) >= 85 ? 'dark-green' :
                                                                                (this.leveloneReport?.lorRulerDropMarkRange ?? this.cognitivePercentages.cnaRulerDrop ?? this.cognitiveReport?.rulerDropZScorePercent ?? this.cognitivePercentages.cnaRulerDrop ?? this.cognitive?.cnaRulerDrop) >= 70 ? 'light-green' :
                                                                                (this.leveloneReport?.lorRulerDropMarkRange ?? this.cognitivePercentages.cnaRulerDrop ?? this.cognitiveReport?.rulerDropZScorePercent ?? this.cognitivePercentages.cnaRulerDrop ?? this.cognitive?.cnaRulerDrop) >= 40 ? 'yellow' :
                                                                                'red'" class="rating">
                                                                                {{getScoreLabel(this.leveloneReport?.lorRulerDropMarkRange
                                                                                ??
                                                                                this.cognitiveReport?.rulerDropZScorePercent
                                                                                ??
                                                                                this.cognitivePercentages?.cnaRulerDrop ?? this.cognitive?.cnaRulerDrop)}}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Co-ordination wall test (min)
                                                                                <p class="desc">IBM : {{ getBenchMarkRange('cnaWallToss') }}</p>
                                                                            </td>
                                                                            <td>{{this.cognitive?.cnaWallToss ?? 0}}</td>
                                                                            <td>{{
                                                                                this.leveloneReport?.lorWallTossMarkRange
                                                                                ??
                                                                                this.cognitiveReport?.wallTossZScorePercent
                                                                                ??
                                                                                this.cognitive?.cnaWallToss ?? 0}} %</td>
                                                                            <td [ngClass]="(this.leveloneReport?.lorWallTossMarkRange ?? this.cognitiveReport?.wallTossZScorePercent ?? this.cognitive?.cnaWallToss) >= 85 ? 'dark-green' :
                                                                                (this.leveloneReport?.lorWallTossMarkRange ?? this.cognitiveReport?.wallTossZScorePercent ?? this.cognitive?.cnaWallToss) >= 70 ? 'light-green' :
                                                                                (this.leveloneReport?.lorWallTossMarkRange ?? this.cognitiveReport?.wallTossZScorePercent ?? this.cognitive?.cnaWallToss) >= 40 ? 'yellow' :
                                                                                'red'" class="rating">
                                                                                {{getScoreLabel(this.leveloneReport?.lorWallTossMarkRange
                                                                                ??
                                                                                this.cognitiveReport?.wallTossZScorePercent
                                                                                ??
                                                                                this.cognitive?.cnaWallToss ?? 0)}}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div class="col-md-3 p-0">
                                                                <div
                                                                    class="donut-container d-flex align-items-center justify-content-center">
                                                                    <div class="donut-circle" [ngClass]="(calculateCognitiveAveragePctl()) >= 85 ? 'dark-green' :
                                                                                (calculateCognitiveAveragePctl()) >= 70 ? 'light-green' :
                                                                                (calculateCognitiveAveragePctl()) >= 40 ? 'yellow' :
                                                                                'red'">
                                                                        <div class="inner-circle">
                                                                            <h2>{{ calculateCognitiveAveragePctl() }}%
                                                                            </h2>
                                                                        </div>
                                                                        <p class="score-tag">Z-score</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Injury & Injury Prevention Analysis -->
                <div class="row mb-2">
                    <div class="col-xl-12">
                        <div class="row">
                            <div class="container p-0">
                                <div class="row justify-content-center">
                                    <div class="col-md-12">
                                        <div class="card-custom">
                                            <div class="header-cont mb-5">
                                                <h2>Injury Prevention Analysis
                                                </h2>
                                            </div>

                                            <div class="row px-4">
                                                <div class="col-md-12 d-flex flex-column">
                                                    <div class="mb-5">
                                                        <div class="main-title mb-2">
                                                            <h4>Functional Movement Screening</h4>
                                                        </div>

                                                        <div class="col-md-12 d-flex equal-height p-0">
                                                            <div class="col-md-9 p-0">
                                                                <table #fitnessTable class="custom-table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Test</th>
                                                                            <th>Score</th>
                                                                            <th>pctl</th>
                                                                            <th>Rating</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>
                                                                                Deep Squat
                                                                            </td>
                                                                            <td>{{this.injury?.ipaDeepSquat?? 0}}</td>
                                                                            <td>{{(this.injury?.ipaDeepSquat / 3) * 100
                                                                                | number: '1.0-2' }} %</td>
                                                                            <td [ngClass]="((this.injury?.ipaDeepSquat / 3) * 100) >= 85 ? 'dark-green' :
                                                                                ((this.injury?.ipaDeepSquat / 3) * 100) >= 70 ? 'light-green' :
                                                                                ((this.injury?.ipaDeepSquat / 3) * 100) >= 40 ? 'yellow' :
                                                                                'red'" class="rating">
                                                                                {{getScoreLabel((this.injury?.ipaDeepSquat / 3) * 100)}}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Inline Lunge
                                                                            </td>
                                                                            <td>{{
                                                                                this.injury?.ipaInlineLunge?? 0}}</td>
                                                                            <td>{{
                                                                                (this.injury?.ipaInlineLunge / 3) * 100
                                                                                | number: '1.0-2' }} %</td>
                                                                            <td [ngClass]="((this.injury?.ipaInlineLunge / 3) * 100) >= 85 ? 'dark-green' :
                                                                                ((this.injury?.ipaInlineLunge / 3) * 100 ) >= 70 ? 'light-green' :
                                                                                ((this.injury?.ipaInlineLunge / 3) * 100 ) >= 40 ? 'yellow' :
                                                                                'red'" class="rating">
                                                                                {{getScoreLabel((this.injury?.ipaInlineLunge / 3) * 100)}}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Hurdle Step
                                                                            </td>
                                                                            <td>{{this.injury?.ipaHurdleStep?? 0}}</td>
                                                                            <td>{{(this.injury?.ipaHurdleStep / 3) * 100
                                                                                | number: '1.0-2' }} %</td>
                                                                            <td [ngClass]="((this.injury?.ipaHurdleStep / 3) * 100 ) >= 85 ? 'dark-green' :
                                                                                ((this.injury?.ipaHurdleStep / 3) * 100 ) >= 70 ? 'light-green' :
                                                                                ((this.injury?.ipaHurdleStep / 3) * 100 ) >= 40 ? 'yellow' :
                                                                                'red'" class="rating">
                                                                                {{getScoreLabel((this.injury?.ipaHurdleStep / 3) * 100 )}}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Active SLR
                                                                            </td>
                                                                            <td>{{
                                                                                this.injury?.ipaActiveSLR?? 0}}</td>
                                                                            <td>{{
                                                                                (this.injury?.ipaActiveSLR / 3) * 100 |
                                                                                number: '1.0-2' }} %</td>
                                                                            <td [ngClass]="( (this.injury?.ipaActiveSLR / 3) * 100 ) >= 85 ? 'dark-green' :
                                                                                ( (this.injury?.ipaActiveSLR / 3) * 100 ) >= 70 ? 'light-green' :
                                                                                ( (this.injury?.ipaActiveSLR / 3) * 100 ) >= 40 ? 'yellow' :
                                                                                'red'" class="rating">
                                                                                {{getScoreLabel((this.injury?.ipaActiveSLR / 3) * 100 )}}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Shoulder Mobility
                                                                            </td>
                                                                            <td>{{this.injury?.ipaShoulderMobility?? 0}}
                                                                            </td>
                                                                            <td>{{(this.injury?.ipaShoulderMobility / 3)
                                                                                * 100 | number: '1.0-2' }} %</td>
                                                                            <td [ngClass]="((this.injury?.ipaShoulderMobility / 3)
                                                                            * 100) >= 85 ? 'dark-green' :
                                                                                ((this.injury?.ipaShoulderMobility / 3)
                                                                                * 100 ) >= 70 ? 'light-green' :
                                                                                ((this.injury?.ipaShoulderMobility / 3)
                                                                                * 100 ) >= 45 ? 'yellow' :
                                                                                'red'" class="rating">
                                                                                {{getScoreLabel((this.injury?.ipaShoulderMobility / 3) * 100)}}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Push Up
                                                                            </td>
                                                                            <td>{{
                                                                                this.injury?.ipaPushUp ?? 0}}</td>
                                                                            <td>{{
                                                                                (this.injury?.ipaPushUp / 3) * 100 |
                                                                                number: '1.0-2' }} %</td>
                                                                            <td [ngClass]="((this.injury?.ipaPushUp / 3) * 100) >= 85 ? 'dark-green' :
                                                                                ((this.injury?.ipaPushUp / 3) * 100) >= 70 ? 'light-green' :
                                                                                ((this.injury?.ipaPushUp / 3) * 100) >= 40 ? 'yellow' :
                                                                                'red'" class="rating">
                                                                                {{getScoreLabel((this.injury?.ipaPushUp / 3)* 100)}}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Rotary Stability
                                                                            </td>
                                                                            <td>{{this.injury?.ipaRotaryStability?? 0}}
                                                                            </td>
                                                                            <td>{{(this.injury?.ipaRotaryStability / 3)
                                                                                * 100 | number: '1.0-2' }} %</td>
                                                                            <td [ngClass]="((this.injury?.ipaRotaryStability / 3)
                                                                            * 100 ) >= 85 ? 'dark-green' :
                                                                                ((this.injury?.ipaRotaryStability / 3)
                                                                                * 100 ) >= 70 ? 'light-green' :
                                                                                ((this.injury?.ipaRotaryStability / 3)
                                                                                * 100 ) >= 40 ? 'yellow' :
                                                                                'red'" class="rating">
                                                                                {{getScoreLabel((this.injury?.ipaRotaryStability / 3)* 100)}}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Total Score
                                                                            </td>
                                                                            <td>{{(this.injury?.ipaTotalScore || (this.injury?.ipaDeepSquat + this.injury?.ipaInlineLunge + this.injury?.ipaHurdleStep + this.injury?.ipaActiveSLR + this.injury?.ipaShoulderMobility + this.injury?.ipaPushUp + this.injury?.ipaRotaryStability)) ?? 0}}</td>
                                                                            <td>{{((this.injury?.ipaTotalScore || (this.injury?.ipaDeepSquat + this.injury?.ipaInlineLunge + this.injury?.ipaHurdleStep + this.injury?.ipaActiveSLR + this.injury?.ipaShoulderMobility + this.injury?.ipaPushUp + this.injury?.ipaRotaryStability)) / 21) *
                                                                                100 | number: '1.0-2' }} %</td>
                                                                            <td [ngClass]="(((this.injury?.ipaTotalScore || (this.injury?.ipaDeepSquat + this.injury?.ipaInlineLunge + this.injury?.ipaHurdleStep + this.injury?.ipaActiveSLR + this.injury?.ipaShoulderMobility + this.injury?.ipaPushUp + this.injury?.ipaRotaryStability)) / 21) * 100 ) >= 85 ? 'dark-green' :
                                                                                (((this.injury?.ipaTotalScore || (this.injury?.ipaDeepSquat + this.injury?.ipaInlineLunge + this.injury?.ipaHurdleStep + this.injury?.ipaActiveSLR + this.injury?.ipaShoulderMobility + this.injury?.ipaPushUp + this.injury?.ipaRotaryStability)) / 21) * 100 ) >= 70 ? 'light-green' :
                                                                                (((this.injury?.ipaTotalScore || (this.injury?.ipaDeepSquat + this.injury?.ipaInlineLunge + this.injury?.ipaHurdleStep + this.injury?.ipaActiveSLR + this.injury?.ipaShoulderMobility + this.injury?.ipaPushUp + this.injury?.ipaRotaryStability)) / 21) * 100 ) >= 40 ? 'yellow' :
                                                                                'red'" class="rating">
                                                                                {{getScoreLabel(((this.injury?.ipaTotalScore || (this.injury?.ipaDeepSquat + this.injury?.ipaInlineLunge + this.injury?.ipaHurdleStep + this.injury?.ipaActiveSLR + this.injury?.ipaShoulderMobility + this.injury?.ipaPushUp + this.injury?.ipaRotaryStability))
                                                                                / 21) * 100)}}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div class="col-md-3 p-0">
                                                                <div
                                                                    class="donut-container d-flex align-items-center justify-content-center">
                                                                    <div class="donut-circle" [ngClass]="(((this.injury?.ipaTotalScore || (this.injury?.ipaDeepSquat + this.injury?.ipaInlineLunge + this.injury?.ipaHurdleStep + this.injury?.ipaActiveSLR + this.injury?.ipaShoulderMobility + this.injury?.ipaPushUp + this.injury?.ipaRotaryStability)) / 21) * 100 ) >= 85 ? 'dark-green' :
                                                                                (((this.injury?.ipaTotalScore || (this.injury?.ipaDeepSquat + this.injury?.ipaInlineLunge + this.injury?.ipaHurdleStep + this.injury?.ipaActiveSLR + this.injury?.ipaShoulderMobility + this.injury?.ipaPushUp + this.injury?.ipaRotaryStability)) / 21) * 100 ) >= 70 ? 'light-green' :
                                                                                (((this.injury?.ipaTotalScore || (this.injury?.ipaDeepSquat + this.injury?.ipaInlineLunge + this.injury?.ipaHurdleStep + this.injury?.ipaActiveSLR + this.injury?.ipaShoulderMobility + this.injury?.ipaPushUp + this.injury?.ipaRotaryStability)) / 21) * 100 ) >= 40 ? 'yellow' :
                                                                                'red'">
                                                                        <div class="inner-circle">
                                                                            <h2>{{ ((this.injury?.ipaTotalScore || (this.injury?.ipaDeepSquat + this.injury?.ipaInlineLunge + this.injury?.ipaHurdleStep + this.injury?.ipaActiveSLR + this.injury?.ipaShoulderMobility + this.injury?.ipaPushUp + this.injury?.ipaRotaryStability)) / 21) *
                                                                                100 | number: '1.0-2' }}%
                                                                            </h2>
                                                                        </div>
                                                                        <p class="score-tag">Z-score</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row d-flex col-md-12">

                                                        <div class="row d-flex align-items-center col-md-12">





                                                        </div>


                                                    </div>

                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

                <!-- sportss Performance Analysis -->
                <div class="row mb-2">
                    <div class="col-xl-12">
                        <div class="row">
                            <div class="container p-0">
                                <div class="row justify-content-center">
                                    <div class="col-md-12">
                                        <div class="card-custom">
                                            <div class="header-cont mb-5">
                                                <h2>Sports Performance Analysis</h2>
                                            </div>
                                            <div class="row px-4">
                                                <div class="col-md-12 d-flex flex-column">


                                                    <div class="mb-4">
                                                        <div class="subtitle mb-2">
                                                            <h6>Isokinetic Dynamometer

                                                            </h6>
                                                        </div>
                                                        <div class="col-md-12 d-flex equal-height p-0">
                                                            <div class="col-md-9 p-0">
                                                                <table #fitnessTable class="custom-table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Test</th>
                                                                            <th>Score</th>
                                                                            <th>pctl</th>
                                                                            <th>Rating</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>Right Average Torque (Extension/Flexion)
                                                                                <p class="desc">IBM : {{ getBenchMarkRange('spaAverageTorqueIR') }}</p>
                                                                            </td>
                                                                            <td>{{ this.sports?.spaAverageTorqueIR ?? 0 }}</td>
                                                                            <td>{{ this.spaPercentages?.spaAverageTorqueIR_avg ??this.sports?.spaAverageTorqueIR ?? 0  }} %</td>
                                                                            <td [ngClass]="(this.spaPercentages?.spaAverageTorqueIR_avg ?? this.sports?.spaAverageTorqueIR) >= 85 ? 'dark-green' :
                                                                                           (this.spaPercentages?.spaAverageTorqueIR_avg ?? this.sports?.spaAverageTorqueIR) >= 70 ? 'light-green' :
                                                                                           (this.spaPercentages?.spaAverageTorqueIR_avg ?? this.sports?.spaAverageTorqueIR) >= 40 ? 'yellow' :
                                                                                           'red'" class="rating">
                                                                              {{ getScoreLabel(this.spaPercentages?.spaAverageTorqueIR_avg ?? this.sports?.spaAverageTorqueIR ??this.sports?.spaAverageTorqueIR ?? 0 ) }}
                                                                            </td>
                                                                          </tr>
                                                                      
                                                                          <!-- Right Peak Torque (Extension/Flexion) -->
                                                                          <tr>
                                                                            <td>Right Peak Torque (Extension/Flexion)
                                                                                <p class="desc">IBM : {{ getBenchMarkRange('spaPeakTorqueIR') }}</p>
                                                                            </td>
                                                                            <td>{{ this.sports?.spaPeakTorqueIR ?? 0 }}</td>
                                                                            <td>{{ this.spaPercentages?.spaPeakTorqueIR_avg ?? this.sports?.spaPeakTorqueIR ?? 0 }} %</td>
                                                                            <td [ngClass]="(this.spaPercentages?.spaPeakTorqueIR_avg ?? this.sports?.spaPeakTorqueIR) >= 85 ? 'dark-green' :
                                                                                           (this.spaPercentages?.spaPeakTorqueIR_avg ?? this.sports?.spaPeakTorqueIR) >= 70 ? 'light-green' :
                                                                                           (this.spaPercentages?.spaPeakTorqueIR_avg ?? this.sports?.spaPeakTorqueIR) >= 40 ? 'yellow' :
                                                                                           'red'" class="rating">
                                                                              {{ getScoreLabel(this.spaPercentages?.spaPeakTorqueIR_avg ?? this.sports?.spaPeakTorqueIR ?? this.sports?.spaPeakTorqueIR ?? 0) }}
                                                                            </td>
                                                                          </tr>
                                                                      
                                                                          <!-- Left Average Torque (Extension/Flexion) -->
                                                                          <tr>
                                                                            <td>Left Average Torque (Extension/Flexion)
                                                                                <p class="desc">IBM : {{ getBenchMarkRange('spaAverageTorqueIL') }}</p>
                                                                            </td>
                                                                            <td>{{ this.sports?.spaAverageTorqueIL ?? 0 }}</td>
                                                                            <td>{{ this.spaPercentages?.spaAverageTorqueIL_avg ?? (this.sports?.spaAverageTorqueIL > 100 ? 100 : this.sports?.spaAverageTorqueIL) ?? 0}} %</td>
                                                                            <td [ngClass]="(this.spaPercentages?.spaAverageTorqueIL_avg ?? this.sports?.spaAverageTorqueIL) >= 85 ? 'dark-green' :
                                                                                           (this.spaPercentages?.spaAverageTorqueIL_avg ?? this.sports?.spaAverageTorqueIL) >= 70 ? 'light-green' :
                                                                                           (this.spaPercentages?.spaAverageTorqueIL_avg ?? this.sports?.spaAverageTorqueIL) >= 40 ? 'yellow' :
                                                                                           'red'" class="rating">
                                                                              {{ getScoreLabel(this.spaPercentages?.spaAverageTorqueIL_avg ?? (this.sports?.spaAverageTorqueIL > 100 ? 100 : this.sports?.spaAverageTorqueIL) ?? 0) }}
                                                                            </td>
                                                                          </tr>
                                                                      
                                                                          <!-- Left Peak Torque (Extension/Flexion) -->
                                                                          <tr>
                                                                            <td>Left Peak Torque (Extension/Flexion)
                                                                                <p class="desc">IBM : {{ getBenchMarkRange('spaPeakTorqueIL') }}</p>
                                                                            </td>
                                                                            <td>{{ this.sports?.spaPeakTorqueIL ?? 0 }}</td>
                                                                            <td>{{ this.spaPercentages?.spaPeakTorqueIL_avg ?? (this.sports?.spaPeakTorqueIL > 100 ? 100 : this.sports?.spaPeakTorqueIL) ?? 0 }} %</td>
                                                                            <td [ngClass]="(this.spaPercentages?.spaPeakTorqueIL_avg ?? this.sports?.spaPeakTorqueIL) >= 85 ? 'dark-green' :
                                                                                           (this.spaPercentages?.spaPeakTorqueIL_avg ?? this.sports?.spaPeakTorqueIL) >= 70 ? 'light-green' :
                                                                                           (this.spaPercentages?.spaPeakTorqueIL_avg ?? this.sports?.spaPeakTorqueIL) >= 40 ? 'yellow' :
                                                                                           'red'" class="rating">
                                                                              {{ getScoreLabel(this.spaPercentages?.spaPeakTorqueIL_avg ?? (this.sports?.spaPeakTorqueIL > 100 ? 100 : this.sports?.spaPeakTorqueIL) ?? 0) }}
                                                                            </td>
                                                                          </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div class="col-md-3 p-0">
                                                                <div
                                                                    class="donut-container d-flex align-items-center justify-content-center">
                                                                    <div class="donut-circle" [ngClass]=" (calculateTorqueAveragePctl()) >= 85 ? 'dark-green' :
                                                                        (calculateTorqueAveragePctl()) >= 70 ? 'light-green' :
                                                                        (calculateTorqueAveragePctl()) >= 40 ? 'yellow' :
                                                                       'red'">
                                                                        <div class="inner-circle">
                                                                            <h2>{{ calculateTorqueAveragePctl() }}%
                                                                            </h2>
                                                                        </div>
                                                                        <p class="score-tag">Z-score</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row px-4">
                                                <div class="col-md-12 d-flex flex-column">


                                                    <div class="mb-4">
                                                        <div class="subtitle mb-2">
                                                            <h6>Concentric

                                                            </h6>
                                                        </div>
                                                        <div class="col-md-12 d-flex equal-height p-0">
                                                            <div class="col-md-9 p-0">
                                                                <table #fitnessTable class="custom-table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Test</th>
                                                                            <th>Score</th>
                                                                            <th>pctl</th>
                                                                            <th>Rating</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>Right Average Torque (Extension/Flexion)
                                                                                <p class="desc">IBM : {{ getBenchMarkRange('spaAverageTorqueCR') }}</p>
                                                                            </td>
                                                                            <td>{{ this.sports?.spaAverageTorqueCR ?? 0 }}</td>
                                                                            <td>{{ this.spaPercentages?.spaAverageTorqueCR_avg ?? (this.sports?.spaAverageTorqueCR > 100 ? 100 : this.sports?.spaAverageTorqueCR) ?? 0 }} %</td>
                                                                            <td [ngClass]="(this.spaPercentages?.spaAverageTorqueCR_avg ?? this.sports?.spaAverageTorqueCR) >= 85 ? 'dark-green' :
                                                                                           (this.spaPercentages?.spaAverageTorqueCR_avg ?? this.sports?.spaAverageTorqueCR) >= 70 ? 'light-green' :
                                                                                           (this.spaPercentages?.spaAverageTorqueCR_avg ?? this.sports?.spaAverageTorqueCR) >= 40 ? 'yellow' :
                                                                                           'red'" class="rating">
                                                                              {{ getScoreLabel(this.spaPercentages?.spaAverageTorqueCR_avg ?? (this.sports?.spaAverageTorqueCR > 100 ? 100 : this.sports?.spaAverageTorqueCR) ?? 0) }}
                                                                            </td>
                                                                          </tr>
                                                                      
                                                                          <!-- Right Peak Torque (Extension/Flexion) -->
                                                                          <tr>
                                                                            <td>Right Peak Torque (Extension/Flexion)
                                                                                <p class="desc">IBM : {{ getBenchMarkRange('spaPeakTorqueCR') }}</p>
                                                                            </td>
                                                                            <td>{{ this.sports?.spaPeakTorqueCR ?? 0 }}</td>
                                                                            <td>{{ this.spaPercentages?.spaPeakTorqueCR_avg ?? (this.sports?.spaPeakTorqueCR > 100 ? 100 : this.sports?.spaPeakTorqueCR) ?? 0 }} %</td>
                                                                            <td [ngClass]="(this.spaPercentages?.spaPeakTorqueCR_avg ?? this.sports?.spaPeakTorqueCR) >= 85 ? 'dark-green' :
                                                                                           (this.spaPercentages?.spaPeakTorqueCR_avg ?? this.sports?.spaPeakTorqueCR) >= 70 ? 'light-green' :
                                                                                           (this.spaPercentages?.spaPeakTorqueCR_avg ?? this.sports?.spaPeakTorqueCR) >= 40 ? 'yellow' :
                                                                                           'red'" class="rating">
                                                                              {{ getScoreLabel(this.spaPercentages?.spaPeakTorqueCR_avg ?? (this.sports?.spaPeakTorqueCR > 100 ? 100 : this.sports?.spaPeakTorqueCR) ?? 0) }}
                                                                            </td>
                                                                          </tr>
                                                                      
                                                                          <!-- Left Average Torque (Extension/Flexion) -->
                                                                          <tr>
                                                                            <td>Left Average Torque (Extension/Flexion)
                                                                                <p class="desc">IBM : {{ getBenchMarkRange('spaAverageTorqueCL') }}</p>
                                                                            </td>
                                                                            <td>{{ this.sports?.spaAverageTorqueCL ?? 0 }}</td>
                                                                            <td>{{ this.spaPercentages?.spaAverageTorqueCL_avg ?? (this.sports?.spaAverageTorqueCL > 100 ? 100 : this.sports?.spaAverageTorqueCL) ?? 0}} %</td>
                                                                            <td [ngClass]="(this.spaPercentages?.spaAverageTorqueCL_avg ?? this.sports?.spaAverageTorqueCL) >= 85 ? 'dark-green' :
                                                                                           (this.spaPercentages?.spaAverageTorqueCL_avg ?? this.sports?.spaAverageTorqueCL) >= 70 ? 'light-green' :
                                                                                           (this.spaPercentages?.spaAverageTorqueCL_avg ?? this.sports?.spaAverageTorqueCL) >= 40 ? 'yellow' :
                                                                                           'red'" class="rating">
                                                                              {{ getScoreLabel(this.spaPercentages?.spaAverageTorqueCL_avg ?? (this.sports?.spaAverageTorqueCL > 100 ? 100 : this.sports?.spaAverageTorqueCL) ?? 0) }}
                                                                            </td>
                                                                          </tr>
                                                                      
                                                                          <!-- Left Peak Torque (Extension/Flexion) -->
                                                                          <tr>
                                                                            <td>Left Peak Torque (Extension/Flexion)
                                                                                <p class="desc">IBM : {{ getBenchMarkRange('spaPeakTorqueCL') }}</p>
                                                                            </td>
                                                                            <td>{{ this.sports?.spaPeakTorqueCL ?? 0 }}</td>
                                                                            <td>{{ this.spaPercentages?.spaPeakTorqueCL_avg ?? (this.sports?.spaPeakTorqueCL > 100 ? 100 : this.sports?.spaPeakTorqueCL) ?? 0 }} %</td>
                                                                            <td [ngClass]="(this.spaPercentages?.spaPeakTorqueCL_avg ?? this.sports?.spaPeakTorqueCL) >= 85 ? 'dark-green' :
                                                                                           (this.spaPercentages?.spaPeakTorqueCL_avg ?? this.sports?.spaPeakTorqueCL) >= 70 ? 'light-green' :
                                                                                           (this.spaPercentages?.spaPeakTorqueCL_avg ?? this.sports?.spaPeakTorqueCL) >= 40 ? 'yellow' :
                                                                                           'red'" class="rating">
                                                                              {{ getScoreLabel(this.spaPercentages?.spaPeakTorqueCL_avg ?? (this.sports?.spaPeakTorqueCL > 100 ? 100 : this.sports?.spaPeakTorqueCL) ?? 0) }}
                                                                            </td>
                                                                          </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div class="col-md-3 p-0">
                                                                <div
                                                                    class="donut-container d-flex align-items-center justify-content-center">
                                                                    <div class="donut-circle" [ngClass]=" (calculateTorqueAveragePctlForCRandCL()) >= 85 ? 'dark-green' :
                                                                                                (calculateTorqueAveragePctlForCRandCL()) >= 70 ? 'light-green' :
                                                                                                (calculateTorqueAveragePctlForCRandCL()) >= 40 ? 'yellow' :
                                                                                               'red'">
                                                                        <div class="inner-circle">
                                                                            <h2>{{ calculateTorqueAveragePctlForCRandCL() }}%
                                                                            </h2>
                                                                        </div>
                                                                        <p class="score-tag">Z-score</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row px-4">
                                                <div class="col-md-12 d-flex flex-column">
                                            <div class="mb-4">

                                                <div class="main-title">
                                                    <h4>Power</h4>
                                                </div>
                                                <div class="subtitle mb-2">
                                                    <h6>Upper Body

                                                    </h6>
                                                </div>
                                                <div class="col-md-12 mb-4 d-flex equal-height p-0">
                                                    <div class="col-md-9 p-0">
                                                        <table #fitnessTable class="custom-table">
                                                            <thead>
                                                                <tr>
                                                                    <th>Test</th>
                                                                    <th>Score</th>
                                                                    <th>pctl</th>
                                                                    <th>Rating</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                            <div class="d-flex align-items-center justify-content-between">
                                                                                <div>
                                                                                    Seated Med Ball Throws (m)
                                                                            <p class="desc">IBM : {{ getBenchMarkRange('pfaSeatedMedBallThrow') }}</p>
                                                                        </div>
                                                                            <img class="table-img"
                                                                                src="/assets/image/med-ball.jpg">
                                                                                </div>
                                                                    </td>
                                                                    <td>{{this.fitness?.pfaSeatedMedBallThrow ?? 0}}
                                                                    </td>
                                                                    <td>{{this.leveloneReport?.lorSeatedMedBallMarkRange
                                                                        ?? this.percentages.pfaSeatedMedBallThrow ??
                                                                        this.fitness?.pfaSeatedMedBallThrow ?? 0}} %
                                                                    </td>
                                                                    <td [ngClass]="(this.leveloneReport?.lorSeatedMedBallMarkRange ?? this.percentages.pfaSeatedMedBallThrow ?? this.fitness?.pfaSeatedMedBallThrow ) >= 85 ? 'dark-green' :
                                                                        (this.leveloneReport?.lorSeatedMedBallMarkRange ?? this.percentages.pfaSeatedMedBallThrow ?? this.fitness?.pfaSeatedMedBallThrow ) >= 70 ? 'light-green' :
                                                                        (this.leveloneReport?.lorSeatedMedBallMarkRange ?? this.percentages.pfaSeatedMedBallThrow ?? this.fitness?.pfaSeatedMedBallThrow) >= 40 ? 'yellow' :
                                                                        'red'" class="rating">
                                                                        {{getScoreLabel(this.leveloneReport?.lorSeatedMedBallMarkRange
                                                                        ?? this.percentages.pfaSeatedMedBallThrow ??
                                                                        this.fitness?.pfaSeatedMedBallThrow)}}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Force (N)
                                                                        <p class="desc">IBM : {{ getBenchMarkRange('pfaUpperBodyForce') }}</p>

                                                                    </td>
                                                                    <td>{{this.fitness?.pfaUpperBodyForce?? 0}}</td>
                                                                    <td>{{ this.percentages?.pfaUpperBodyForce ??
                                                                        this.fitness?.pfaUpperBodyForce?? 0}} %</td>
                                                                    <td [ngClass]="( this.percentages?.pfaUpperBodyForce ?? this.fitness?.pfaUpperBodyForce) >= 85 ? 'dark-green' :
                                                                                        ( this.percentages?.pfaUpperBodyForce ?? this.fitness?.pfaUpperBodyForce) >= 70 ? 'light-green' :
                                                                                        ( this.percentages?.pfaUpperBodyForce ?? this.fitness?.pfaUpperBodyForce) >= 40 ? 'yellow' :
                                                                                        'red'" class="rating">
                                                                        {{getScoreLabel(this.performanceReport?.forceZScorePercent
                                                                        ?? this.percentages?.pfaUpperBodyForce ??
                                                                        this.fitness?.pfaUpperBodyForce ?? 0)}}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Power (W)
                                                                        <p class="desc">IBM : {{ getBenchMarkRange('pfaUpperBodyPower') }}</p>

                                                                    </td>
                                                                    <td>{{this.fitness?.pfaUpperBodyPower?? 0}}</td>
                                                                    <td>{{ this.percentages?.pfaUpperBodyPower ??
                                                                        this.sports?.pfaUpperBodyPower?? 0}} %</td>
                                                                    <td [ngClass]="( this.percentages?.pfaUpperBodyPower ?? this.sports?.pfaUpperBodyPower) >= 85 ? 'dark-green' :
                                                                                        ( this.percentages?.pfaUpperBodyPower ?? this.sports?.pfaUpperBodyPower) >= 70 ? 'light-green' :
                                                                                        ( this.percentages?.pfaUpperBodyPower ?? this.sports?.spaForce) >= 40 ? 'yellow' :
                                                                                        'red'" class="rating">
                                                                        {{getScoreLabel(this.percentages?.pfaUpperBodyPower ??
                                                                        this.sports?.pfaUpperBodyPower ?? 0)}}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div class="col-md-3 p-0">
                                                        <div
                                                            class="donut-container d-flex align-items-center justify-content-center">
                                                            <div class="donut-circle" [ngClass]="(calculateSeatedMedBallAverage()) >= 85 ? 'dark-green' :
                                                            (calculateSeatedMedBallAverage()) >= 70 ? 'light-green' :
                                                            (calculateSeatedMedBallAverage()) >= 40 ? 'yellow' :
                                                            'red'">
                                                                <div class="inner-circle">
                                                                    <h2>{{calculateSeatedMedBallAverage()}}%
                                                                    </h2>
                                                                </div>
                                                                <p class="score-tag">Z-score</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="subtitle mb-2">
                                                    <h6>Lower Body

                                                    </h6>
                                                </div>
                                                <div class="col-md-12 d-flex equal-height p-0">
                                                    <div class="col-md-9 p-0">
                                                        <table #fitnessTable class="custom-table">
                                                            <thead>
                                                                <tr>
                                                                    <th>Test</th>
                                                                    <th>Score</th>
                                                                    <th>pctl</th>
                                                                    <th>Rating</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>


                                                                <tr>
                                                                    <td>
                                                                        <div class="d-flex align-items-center justify-content-between">
                                                                            <div>
                                                                        Broad Jump (ft)
                                                                        <p class="desc">IBM : {{ getBenchMarkRange('pfaStandingBroadJump') }}</p>
                                                                    </div>
                                                                        <img class="table-img"
                                                                            src="/assets/image/jump.jpg">
                                                                            </div>
                                                                    </td>
                                                                    <td>{{ this.fitness?.pfaStandingBroadJump ?? 0}}</td>
                                                                    <td>{{
                                                                        this.percentages.pfaStandingBroadJump
                                                                        ??
                                                                        this.fitness?.pfaStandingBroadJump
                                                                        ?? 0}} %</td>
                                                                    <td [ngClass]="(this.percentages.pfaStandingBroadJump ?? this.fitness?.pfaStandingBroadJump ) >= 85 ? 'dark-green' :
                                                                        (this.percentages.pfaStandingBroadJump ?? this.fitness?.pfaStandingBroadJump ) >= 70 ? 'light-green' :
                                                                        (this.percentages.pfaStandingBroadJump ?? this.fitness?.pfaStandingBroadJump ) >= 40 ? 'yellow' :
                                                                        'red'" class="rating">
                                                                        {{getScoreLabel(this.percentages.pfaStandingBroadJump
                                                                        ??
                                                                        this.fitness?.pfaStandingBroadJump
                                                                        ?? 0)}}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Force (N)
                                                                        <p class="desc">IBM : {{ getBenchMarkRange('pfaLowerBodyForce') }}</p>

                                                                    </td>
                                                                    <td>{{this.fitness?.pfaLowerBodyForce?? 0}}</td>
                                                                    <td>{{ this.percentages?.pfaLowerBodyForce ??
                                                                        this.fitness?.pfaLowerBodyForce?? 0}} %</td>
                                                                    <td [ngClass]="( this.percentages?.pfaLowerBodyForce ?? this.fitness?.pfaLowerBodyForce) >= 85 ? 'dark-green' :
                                                                                        ( this.percentages?.pfaLowerBodyForce ?? this.fitness?.pfaLowerBodyForce) >= 70 ? 'light-green' :
                                                                                        ( this.percentages?.pfaLowerBodyForce ?? this.fitness?.pfaLowerBodyForce) >= 40 ? 'yellow' :
                                                                                        'red'" class="rating">
                                                                        {{getScoreLabel(this.percentages?.pfaLowerBodyForce ??
                                                                        this.fitness?.pfaLowerBodyForce ?? 0)}}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Power (W)
                                                                        <p class="desc">IBM : {{ getBenchMarkRange('pfaLowerBodyPower') }}</p>

                                                                    </td>
                                                                    <td>{{this.fitness?.pfaLowerBodyPower?? 0}}</td>
                                                                    <td>{{this.percentages?.pfaLowerBodyPower ??
                                                                        this.fitness?.pfaLowerBodyPower?? 0}} %</td>
                                                                    <td [ngClass]="(this.percentages?.pfaLowerBodyPower ?? this.fitness?.pfaLowerBodyPower) >= 85 ? 'dark-green' :
                                                                                        (this.percentages?.pfaLowerBodyPower ?? this.fitness?.pfaLowerBodyPower) >= 70 ? 'light-green' :
                                                                                        (this.percentages?.pfaLowerBodyPower ?? this.fitness?.pfaLowerBodyPower) >= 40 ? 'yellow' :
                                                                                        'red'" class="rating">
                                                                        {{getScoreLabel(this.percentages?.pfaLowerBodyPower ??
                                                                        this.fitness?.pfaLowerBodyPower ?? 0)}}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div class="col-md-3 p-0">
                                                        <div
                                                            class="donut-container d-flex align-items-center justify-content-center">
                                                            <div class="donut-circle" [ngClass]="(calculateFitnesslowerAverage()) >= 85 ? 'dark-green' :
                                                                        (calculateFitnesslowerAverage()) >= 70 ? 'light-green' :
                                                                        (calculateFitnesslowerAverage()) >= 40 ? 'yellow' :
                                                                        'red'">
                                                                <div class="inner-circle">
                                                                    <h2>{{calculateFitnesslowerAverage()}}%
                                                                    </h2>
                                                                </div>
                                                                <p class="score-tag">Z-score</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Nutrition Analysis -->

                <div class="row mb-2">
                    <div class="col-xl-12">
                        <div class="row">
                            <div class="container p-0">
                                <div class="row justify-content-center">
                                    <div class="col-md-12">
                                        <div class="card-custom">
                                            <div class="header-cont mb-5">
                                                <h2>Nutrition Analysis</h2>
                                            </div>
                                            <div class="row px-4">
                                                <div class="col-md-12 d-flex flex-column">
                                                    <div class="mb-4">
                                                        <div class="subtitle mb-2">
                                                            <h6>Body Composition

                                                            </h6>
                                                        </div>
                                                        <div class="col-md-12 d-flex equal-height p-0">
                                                            <div class="col-md-9 p-0">
                                                                <table #fitnessTable class="custom-table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Test</th>
                                                                            <th>Score</th>
                                                                            <th>pctl</th>
                                                                            <th>Rating</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>
                                                                                Height (cm)
                                                                                <p class="desc">IBM : {{ getBenchMarkRange('naBodyHeight') }}</p>

                                                                            </td>
                                                                            <td>{{this.nutrition?.naBodyHeight?? 0}}
                                                                            </td>
                                                                            <td>{{this.leveloneReport?.lorHeightMarkRange ?? this.nutritionReport?.bodyHeightZScorePercent
                                                                                ?? this.nutritionPercentages.naBodyHeight ??
                                                                                this.nutrition?.naBodyHeight ?? 0 }} %
                                                                            </td>
                                                                            <td [ngClass]="(this.leveloneReport?.lorHeightMarkRange
                                                                            ??this.nutritionReport?.bodyHeightZScorePercent?? this.nutritionPercentages.naBodyHeight??this.nutrition?.naBodyHeight ?? 0) >= 85 ? 'dark-green' :
                                                                                (this.leveloneReport?.lorHeightMarkRange??
                                                                                this.nutritionReport?.bodyHeightZScorePercent?? this.nutritionPercentages.naBodyHeight??
                                                                                this.nutrition?.naBodyHeight ?? 0) >= 70 ? 'light-green' :
                                                                                (this.leveloneReport?.lorHeightMarkRange??this.nutritionReport?.bodyHeightZScorePercent?? this.nutritionPercentages.naBodyHeight??
                                                                                this.nutrition?.naBodyHeight ?? 0) >= 40 ? 'yellow' :
                                                                                'red'" class="rating">
                                                                                {{getScoreLabel(this.leveloneReport?.lorHeightMarkRange
                                                                                    ?? this.nutritionReport?.bodyHeightZScorePercent ?? this.nutritionPercentages.naBodyHeight?? this.nutrition?.naBodyHeight ?? 0)}}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Weight (kgs)
                                                                                <p class="desc">IBM : {{ getBenchMarkRange('naBodyWeight') }}</p>

                                                                            </td>
                                                                            <td>{{this.nutrition?.naBodyWeight?? 0}}
                                                                            </td>
                                                                            <td>{{this.leveloneReport?.lorWeightMarkRange
                                                                                ??
                                                                                this.nutritionPercentages.naBodyWeight
                                                                                ?? this.nutrition?.naBodyWeight ?? 0}} %
                                                                            </td>
                                                                            <td [ngClass]="(this.leveloneReport?.lorWeightMarkRange ?? this.nutritionPercentages.naBodyWeight ?? this.nutrition?.naBodyWeight) >= 85 ? 'dark-green' :
                                                                                (this.leveloneReport?.lorWeightMarkRange ?? this.nutritionPercentages.naBodyWeight ?? this.nutrition?.naBodyWeight) >= 70 ? 'light-green' :
                                                                                (this.leveloneReport?.lorWeightMarkRange ?? this.nutritionPercentages.naBodyWeight ?? this.nutrition?.naBodyWeight) >= 40 ? 'yellow' :
                                                                                'red'" class="rating">
                                                                                {{getScoreLabel(this.leveloneReport?.lorWeightMarkRange
                                                                                ??
                                                                                this.nutritionPercentages.naBodyWeight
                                                                                ?? this.nutrition?.naBodyWeight ?? 0)}}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                BMI (kg/m²)
                                                                                <p class="desc">IBM : {{ getBenchMarkRange('naBMI') }}</p>

                                                                            </td>
                                                                            <td>{{this.nutrition?.naBMI?? 0}}</td>
                                                                            <td>{{this.nutritionPercentages.naBMI ??
                                                                                this.nutrition?.naBMI ?? 0}} %</td>
                                                                            <td [ngClass]="(this.nutritionPercentages.naBMI ?? this.nutrition?.naBMI) >= 85 ? 'dark-green' :
                                                                                (this.nutritionPercentages.naBMI ?? this.nutrition?.naBMI) >= 70 ? 'light-green' :
                                                                                (this.nutritionPercentages.naBMI ?? this.nutrition?.naBMI) >= 40 ? 'yellow' :
                                                                                'red'" class="rating">
                                                                                {{getScoreLabel(this.nutritionPercentages.naBMI
                                                                                ?? this.nutrition?.naBMI ?? 0)}}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Body Fat (%)
                                                                                <p class="desc">IBM : {{ getBenchMarkRange('naBodyFat') }}</p>

                                                                            </td>
                                                                            <td>{{this.nutrition?.naBodyFat?? 0}}</td>
                                                                            <td>{{this.nutritionPercentages.naBodyFat ??
                                                                                this.nutrition?.naBodyFat}} %</td>
                                                                            <td [ngClass]="(this.nutritionPercentages.naBodyFat ?? this.nutrition?.naBodyFat) >= 85 ? 'dark-green' :
                                                                                (this.nutritionPercentages.naBodyFat ?? this.nutrition?.naBodyFat) >= 70 ? 'light-green' :
                                                                                (this.nutritionPercentages.naBodyFat ?? this.nutrition?.naBodyFat) >= 40 ? 'yellow' : 'red'" class="rating">
                                                                                {{getScoreLabel(this.nutritionPercentages.naBodyFat ?? this.nutrition?.naBodyFat ?? 0)}}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Fat Free Weight (kg)
                                                                                <p class="desc">IBM : {{ getBenchMarkRange('naFatFreeWeight') }}</p>

                                                                            </td>
                                                                            <td>{{this.nutrition?.naFatFreeWeight?? 0}}
                                                                            </td>
                                                                            <td>{{this.nutritionPercentages.naFatFreeWeight
                                                                                ?? this.nutrition?.naFatFreeWeight
                                                                                ?? 0}} %</td>
                                                                            <td [ngClass]="(this.nutritionPercentages.naFatFreeWeight ?? this.nutrition?.naFatFreeWeight ) >= 85 ? 'dark-green' :
                                                                                (this.nutritionPercentages.naFatFreeWeight ?? this.nutrition?.naFatFreeWeight ) >= 70 ? 'light-green' :
                                                                                (this.nutritionPercentages.naFatFreeWeight ?? this.nutrition?.naFatFreeWeight ) >= 40 ? 'yellow' :
                                                                                'red'" class="rating">
                                                                                {{getScoreLabel(this.nutritionPercentages.naFatFreeWeight
                                                                                ?? this.nutrition?.naFatFreeWeight
                                                                                ?? 0)}}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Subcutaneous Fat (kg)
                                                                                <p class="desc">IBM : {{ getBenchMarkRange('naSubcutaneousFat') }}</p>

                                                                            </td>
                                                                            <td>{{this.nutrition?.naSubcutaneousFat??
                                                                                0}}</td>
                                                                            <td>{{this.nutritionPercentages.naSubcutaneousFat
                                                                                ?? this.nutrition?.naSubcutaneousFat??
                                                                                0}} %</td>
                                                                            <td [ngClass]="(this.nutritionPercentages.naSubcutaneousFat ?? this.nutrition?.naSubcutaneousFat) >= 85 ? 'dark-green' :
                                                                                (this.nutritionPercentages.naSubcutaneousFat ?? this.nutrition?.naSubcutaneousFat) >= 70 ? 'light-green' :
                                                                                (this.nutritionPercentages.naSubcutaneousFat ?? this.nutrition?.naSubcutaneousFat) >= 40 ? 'yellow' :'red'"
                                                                                class="rating">
                                                                                {{getScoreLabel(this.nutritionPercentages.naSubcutaneousFat
                                                                                ?? this.nutrition?.naSubcutaneousFat ??
                                                                                0)}}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Visceral Fat (kg)
                                                                                <p class="desc">IBM : {{ getBenchMarkRange('naVisceralFat') }}</p>

                                                                            </td>
                                                                            <td>{{this.nutrition?.naVisceralFat ?? 0}}
                                                                            </td>
                                                                            <td>{{this.nutritionPercentages.naVisceralFat
                                                                                ?? this.nutrition?.naVisceralFat ?? 0 }}
                                                                                %</td>
                                                                            <td [ngClass]="(this.nutritionPercentages.naVisceralFat
                                                                            ?? this.nutrition?.naVisceralFat) >= 85 ? 'dark-green' :
                                                                                (this.nutritionPercentages.naVisceralFat
                                                                                ?? this.nutrition?.naVisceralFat) >= 70 ? 'light-green' :
                                                                                (this.nutritionPercentages.naVisceralFat ?? this.nutrition?.naVisceralFat) >= 40 ? 'yellow' :
                                                                                                                                           'red'"
                                                                                class="rating">
                                                                                {{getScoreLabel(this.nutritionPercentages.naVisceralFat ?? this.nutrition?.naVisceralFat)}}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Body Water (%)
                                                                                <p class="desc">IBM : {{ getBenchMarkRange('naBodyWater') }}</p>

                                                                            </td>
                                                                            <td>{{this.nutrition?.naBodyWater ?? 0}}
                                                                            </td>
                                                                            <td>{{this.nutritionPercentages.naBodyWater
                                                                                ?? this.nutrition?.naBodyWater
                                                                                ?? 0 }} %</td>
                                                                            <td [ngClass]="(this.nutritionPercentages.naBodyWater ?? this.nutrition?.naBodyWater ) >= 85 ? 'dark-green' :
                                                                                (this.nutritionPercentages.naBodyWater ?? this.nutrition?.naBodyWater ) >= 70 ? 'light-green' :
                                                                                (this.nutritionPercentages.naBodyWater ?? this.nutrition?.naBodyWater ) >= 40 ? 'yellow' :
                                                                                'red'" class="rating">
                                                                                {{getScoreLabel(this.nutritionPercentages.naBodyWater
                                                                                ?? this.nutrition?.naBodyWater
                                                                                ?? 0)}}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Skeletal Muscle (%)
                                                                                <p class="desc">IBM : {{ getBenchMarkRange('naSkeletalMuscle') }}</p>

                                                                            </td>
                                                                            <td>{{this.nutrition?.naSkeletalMuscle ??
                                                                                0}}</td>
                                                                            <td>{{this.nutritionPercentages.naSkeletalMuscle
                                                                                ?? this.nutrition?.naSkeletalMuscle ?? 0
                                                                                }} %</td>
                                                                            <td [ngClass]="(this.nutritionPercentages.naSkeletalMuscle ?? this.nutrition?.naSkeletalMuscle) >= 85 ? 'dark-green' :
                                                                                (this.nutritionPercentages.naSkeletalMuscle ?? this.nutrition?.naSkeletalMuscle) >= 70 ? 'light-green' :
                                                                                (this.nutritionPercentages.naSkeletalMuscle ?? this.nutrition?.naSkeletalMuscle) >= 40 ? 'yellow' :'red'"
                                                                                class="rating">
                                                                                {{getScoreLabel(this.nutritionPercentages.naSkeletalMuscle
                                                                                ?? this.nutrition?.naSkeletalMuscle ??
                                                                                0)}}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Lean Mass (kg)
                                                                                <p class="desc">IBM : {{ getBenchMarkRange('naLeanMass') }}</p>

                                                                            </td>
                                                                            <td>{{this.nutrition?.naLeanMass ?? 0}}</td>
                                                                            <td>{{this.nutritionPercentages.naLeanMass
                                                                                ?? this.nutrition?.naLeanMass ?? 0 }} %
                                                                            </td>
                                                                            <td [ngClass]="(this.nutritionPercentages.naLeanMass ?? this.nutrition?.naLeanMass) >= 85 ? 'dark-green' :
                                                                                (this.nutritionPercentages.naLeanMass ?? this.nutrition?.naLeanMass) >= 70 ? 'light-green' :
                                                                                (this.nutritionPercentages.naLeanMass ?? this.nutrition?.naLeanMass) >= 40 ? 'yellow' :'red'"
                                                                                class="rating">
                                                                                {{getScoreLabel(this.nutritionPercentages.naLeanMass
                                                                                ?? this.nutrition?.naLeanMass ?? 0)}}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Bone Mass (kg)
                                                                                <p class="desc">IBM : {{ getBenchMarkRange('naBoneMass') }}</p>

                                                                            </td>
                                                                            <td>{{this.nutrition?.naBoneMass?? 0}}</td>
                                                                            <td>{{this.nutritionPercentages.naBoneMass
                                                                                ?? this.nutrition?.naBoneMass ?? 0 }} %
                                                                            </td>
                                                                            <td [ngClass]="(this.nutritionPercentages.naBoneMass ?? this.nutrition?.naBoneMass) >= 85 ? 'dark-green' :
                                                                                (this.nutritionPercentages.naBoneMass ?? this.nutrition?.naBoneMass) >= 70 ? 'light-green' :
                                                                                (this.nutritionPercentages.naBoneMass ?? this.nutrition?.naBoneMass) >= 40 ? 'yellow' :'red'"
                                                                                class="rating">
                                                                                {{getScoreLabel(this.nutritionPercentages.naBoneMass
                                                                                ?? this.nutrition?.naBoneMass ?? 0)}}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Protein (%)
                                                                                <p class="desc">IBM : {{ getBenchMarkRange('naProtein') }}</p>

                                                                            </td>
                                                                            <td>{{this.nutrition?.naProtein?? 0}}</td>
                                                                            <td>{{this.nutritionPercentages.naProtein ??
                                                                                this.nutrition?.naProtein?? 0 }} %</td>
                                                                            <td [ngClass]="(this.nutritionPercentages.naProtein ?? this.nutrition?.naProtein) >= 85 ? 'dark-green' :
                                                                                (this.nutritionPercentages.naProtein ?? this.nutrition?.naProtein) >= 70 ? 'light-green' :
                                                                                (this.nutritionPercentages.naProtein ?? this.nutrition?.naProtein) >= 40 ? 'yellow' :'red'"
                                                                                class="rating">
                                                                                {{getScoreLabel(this.nutritionPercentages.naProtein
                                                                                ?? this.nutrition?.naProtein?? 0)}}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                BMR (kcal/day)
                                                                                <p class="desc">IBM : {{ getBenchMarkRange('naBMR') }}</p>

                                                                            </td>
                                                                            <td>{{this.nutrition?.naBMR ?? 0}}</td>
                                                                            <td>{{this.nutritionPercentages.naBMR ??
                                                                                this.nutrition?.naBMR ?? 0 }} %</td>
                                                                            <td [ngClass]="(this.nutritionPercentages.naBMR ?? this.nutrition?.naBMR) >= 85 ? 'dark-green' :
                                                                                (this.nutritionPercentages.naBMR ?? this.nutrition?.naBMR) >= 70 ? 'light-green' :
                                                                                (this.nutritionPercentages.naBMR ?? this.nutrition?.naBMR) >= 40 ? 'yellow' : 'red'"
                                                                                class="rating">
                                                                                {{getScoreLabel(this.nutritionPercentages.naBMR
                                                                                ?? this.nutrition?.naBMR ?? 0)}}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Metabolic Age (years)
                                                                                <p class="desc">IBM : {{ getBenchMarkRange('naMetabolicAge') }}</p>

                                                                            </td>
                                                                            <td>{{this.nutrition?.naMetabolicAge ?? 0}}
                                                                            </td>
                                                                            <td>{{this.nutritionPercentages.naMetabolicAge
                                                                                ?? this.nutrition?.naMetabolicAge ?? 0
                                                                                }} %</td>
                                                                            <td [ngClass]="(this.nutritionPercentages.naMetabolicAge ?? this.nutrition?.naMetabolicAge ) >= 85 ? 'dark-green' :
                                                                                (this.nutritionPercentages.naMetabolicAge ?? this.nutrition?.naMetabolicAge) >= 70 ? 'light-green' :
                                                                                (this.nutritionPercentages.naMetabolicAge ?? this.nutrition?.naMetabolicAge ) >= 40 ? 'yellow' :
                                                                                'red'" class="rating">
                                                                                {{getScoreLabel(this.nutritionPercentages.naMetabolicAge
                                                                                ?? this.nutrition?.naMetabolicAge ?? 0
                                                                                )}}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Health Score

                                                                            </td>
                                                                            <td>{{this.nutrition?.naHealthScore ?? 0}}
                                                                            </td>
                                                                            <td>{{this.nutritionPercentages.naHealthScore
                                                                                ?? this.nutrition?.naHealthScore ?? 0 }}
                                                                                %</td>
                                                                            <td [ngClass]="(this.nutritionPercentages.naHealthScore ?? this.nutrition?.naHealthScore) >= 85 ? 'dark-green' :
                                                                                (this.nutritionPercentages.naHealthScore ?? this.nutrition?.naHealthScore) >= 70 ? 'light-green' :
                                                                                (this.nutritionPercentages.naHealthScore ?? this.nutrition?.naHealthScore) >= 40 ? 'yellow' : 'red'"
                                                                                class="rating">
                                                                                {{getScoreLabel(this.nutritionPercentages.naHealthScore
                                                                                ?? this.nutrition?.naHealthScore ?? 0)}}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div class="col-md-3 p-0">
                                                                <div
                                                                    class="donut-container d-flex align-items-center justify-content-center">
                                                                    <div class="donut-circle" [ngClass]="(calculateNutritionMetricsAverage() ) >= 85 ? 'dark-green' :
                                                                                (calculateNutritionMetricsAverage() ) >= 70 ? 'light-green' :
                                                                                (calculateNutritionMetricsAverage() ) >= 40 ? 'yellow' :
                                                                                'red'">
                                                                        <div class="inner-circle">
                                                                            <h2>{{ calculateNutritionMetricsAverage()}}%
                                                                            </h2>
                                                                        </div>
                                                                        <p class="score-tag">Z-score</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="subtitle mb-2">
                                                        <h6>Sleep and Recovery

                                                        </h6>
                                                    </div>
                                                    <div class="col-md-12 mb-4 d-flex equal-height p-0">
                                                        <div class="col-md-9 p-0">
                                                            <table #fitnessTable class="custom-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Test</th>
                                                                        <th>Score</th>
                                                                        <th>pctl</th>
                                                                        <th>Rating</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            Sleep and Recovery
                                                                        </td>
                                                                        <td>{{ this.SleepandRest ?? 0 }}</td>
                                                                        <td>{{this.SleepandRest ?? 0}} %</td>
                                                                        <td [ngClass]="(this.SleepandRest) >= 85 ? 'dark-green' :
                                                                (this.SleepandRest) >= 70 ? 'light-green' :
                                                                (this.SleepandRest) >= 40 ? 'yellow' : 'red'"
                                                                            class="rating">
                                                                            {{getScoreLabel(this.SleepandRest ?? 0)}}
                                                                        </td>
                                                                    </tr>

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div class="col-md-3 p-0">
                                                            <div
                                                                class="donut-container d-flex align-items-center justify-content-center">
                                                                <div class="donut-circle" [ngClass]="(calculateSleepAndRestAverage()) >= 85 ? 'dark-green' :
                                                    (calculateSleepAndRestAverage()) >= 70 ? 'light-green' :
                                                    (calculateSleepAndRestAverage()) >= 40 ? 'yellow' : 'red'">
                                                                    <div class="inner-circle">
                                                                        <h2>{{ calculateSleepAndRestAverage() }}%</h2>
                                                                    </div>
                                                                    <p class="score-tag">Z-score</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div class="subtitle mb-2">
                                                        <h6>Nutrition and Hydration

                                                        </h6>
                                                    </div>
                                                    <div class="col-md-12 mb-4 d-flex equal-height p-0">
                                                        <div class="col-md-9 p-0">
                                                            <table #fitnessTable class="custom-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Test</th>
                                                                        <th>Score</th>
                                                                        <th>pctl</th>
                                                                        <th>Rating</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            Nutrition and Hydration
                                                                        </td>
                                                                        <td>{{ this.hydration ?? 0 }}</td>
                                                                        <td>{{this.hydration ?? 0}} %</td>
                                                                        <td [ngClass]="(this.hydration ) >= 85 ? 'dark-green' :
                                                                (this.hydration) >= 70 ? 'light-green' :
                                                                (this.hydration ) >= 40 ? 'yellow' :
                                                                'red'" class="rating">{{getScoreLabel(this.hydration ??
                                                                            0)}}
                                                                        </td>
                                                                    </tr>

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div class="col-md-3 p-0">
                                                            <div
                                                                class="donut-container d-flex align-items-center justify-content-center">
                                                                <div class="donut-circle" [ngClass]="(calculateHydrationAverage() ) >= 85 ? 'dark-green' :
                                                                (calculateHydrationAverage()) >= 70 ? 'light-green' :
                                                                (calculateHydrationAverage() ) >= 40 ? 'yellow' :
                                                                'red'">
                                                                    <div class="inner-circle">
                                                                        <h2>{{ calculateHydrationAverage() }}%</h2>
                                                                    </div>
                                                                    <p class="score-tag">Z-score</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div class="subtitle mb-2">
                                                        <h6>24-Hour Recall Assessment</h6>
                                                    </div>
                                                    <div class="col-md-12 mb-4 d-flex equal-height p-0">
                                                        <div class="col-md-9 p-0">
                                                            <table #fitnessTable class="custom-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Test</th>
                                                                        <th>Score</th>
                                                                        <th>pctl</th>
                                                                        <th>Rating</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            24 Hours Recall Questionaries
                                                                        </td>
                                                                        <td>{{ this.questionary ?? 0 }}</td>
                                                                        <td>{{this.questionary ?? 0}} %</td>
                                                                        <td [ngClass]="(this.questionary) >= 85 ? 'dark-green' :
                                                                (this.questionary) >= 70 ? 'light-green' :
                                                                (this.questionary) >= 40 ? 'yellow' : 'red'"
                                                                            class="rating">
                                                                            {{getScoreLabel(this.questionary ?? 0)}}
                                                                        </td>
                                                                    </tr>

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div class="col-md-3 p-0">
                                                            <div
                                                                class="donut-container d-flex align-items-center justify-content-center">
                                                                <div class="donut-circle" [ngClass]="(calculateQuestionaryAverage()) >= 85 ? 'dark-green' :
                                                    (calculateQuestionaryAverage()) >= 70 ? 'light-green' :
                                                    (calculateQuestionaryAverage()) >= 40 ? 'yellow' : 'red'">
                                                                    <div class="inner-circle">
                                                                        <h2>{{ calculateQuestionaryAverage() }}%</h2>
                                                                    </div>
                                                                    <p class="score-tag">Z-score</p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

                <div class="row mb-2">
                    <div class="col-xl-12">
                        <div class="row">
                            <div class="container p-0">
                                <div class="row justify-content-center">
                                    <div class="col-md-12">
                                        <div class="card-custom">
                                            <div class="header-cont mb-3">
                                                <h2>Area of Improvement</h2>
                                            </div>
                                            <div class="row px-4 col-md-12 Summary">


                                                {{this.summarydetails[0].ausSummary}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mb-2">
                    <div class="col-xl-12">
                        <div class="row">
                            <div class="container p-0">
                                <div class="row justify-content-center">
                                    <div class="col-md-12">
                                        <div class="card-custom">
                                            <div class="header-cont mb-3">
                                                <h2>Recommendation Plan</h2>
                                            </div>
                                            <div class="row px-4 col-md-12 Summary">

                                                {{this.summarydetails[0].ausActionPlan}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </main>
    </div>

    </div>

</div>
