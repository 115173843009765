import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { AssessmentService } from '../../../services/assessment.service';
import { Location } from '@angular/common';
import { Privilege } from '../../../model/Privillage';
import { SharedService } from '../../../services/shared.service';
import { PrivilegeService } from '../../../services/privilage.service';
@Component({
  selector: 'app-sports-performance-analysis-details',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './sports-performance-analysis-details.component.html',
  styleUrl: './sports-performance-analysis-details.component.css'
})
export class SportsPerformanceAnalysisDetailsComponent {


  id: any;
  athleteReport: any;
  athleteId: any;
  privilege: Privilege;
  IsAdmin: boolean = false;
  isScienceStaff: boolean = false;
  role: any;
  detail: any;
  userId: any;
  selectdata: any;
  athleteFullName: any;
  date: any;
  assessmentDate: any;
  spaAverageTorqueCL: any;
  spaAverageTorqueCR: any;
  spaAverageTorqueIL: any;
  spaAverageTorqueIR: any;
  spaFlightTime: any;
  spaForce: any;
  spaJumpHeight: any;
  spaPeakTorqueCL: any;
  spaPeakTorqueCR: any;
  spaPeakTorqueIL: any;
  spaPeakTorqueIR: any;
  spaPower: any;
  spaVelocity: any;

  constructor(private router: Router,
     private route: ActivatedRoute,
     private assessmentService: AssessmentService,private location: Location,
     private sharedService: SharedService,private priService:PrivilegeService) {
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
      this.athleteId = params['athleteId'];
      console.log(this.id,"ID");
    });
    this.privilege = this.priService.getPrivileges();
    this.IsAdmin = this.privilege.isAdmin;
    this.isScienceStaff=this.privilege.isScienceStaff
  }
 
  activities = [
    { name: 'Sports Activity', percentage: 82 },
    { name: 'Technique Analysis', percentage: 42 },
    { name: 'Performance Analysis', percentage: 100 }
  ];
 
  // Dynamically return the color class based on percentage
  getProgressColor(percentage: number): string {
    if (percentage >= 75) {
      return 'bg-green';
    } else if (percentage >= 40) {
      return 'bg-yellow';
    } else {
      return 'bg-red';
    }
  }
 
  ngOnInit(): void {
    this.sharedService.detail$.subscribe((data: any) => {
      this.detail = data;
      this.role = this.detail.user.usrRole;
      this.userId = this.detail.user.usrId;
    });
  
    const getAthleteReport = (this.IsAdmin || this.isScienceStaff ) 
      ? this.assessmentService.getSportsPerformanceByTest(this.id)
      : this.assessmentService.getSportsPerformanceByTestAssessor(this.id);
  
    getAthleteReport.subscribe((response: any) => {
      this.athleteReport = response;
      this.selectdata = response.filter((assessment: any) =>
        (this.IsAdmin || this.isScienceStaff )  ? assessment.athlete == this.athleteId : assessment.athlete == this.athleteId && assessment.assessorId == this.userId
      );
  
      this.selectdata.forEach((assessment: any) => {
        switch (assessment.testColumnName) {
          case "spaAverageTorqueCR":
            this.spaAverageTorqueCR = assessment.matchedColumnData;
            break;
          case "spaAverageTorqueCL":
            this.spaAverageTorqueCL = assessment.matchedColumnData;
            break;
          case "spaPeakTorqueCR":
            this.spaPeakTorqueCR = assessment.matchedColumnData;
            break;
          case "spaPeakTorqueCL":
            this.spaPeakTorqueCL = assessment.matchedColumnData;
            break;
          case "spaAverageTorqueIR":
            this.spaAverageTorqueIR = assessment.matchedColumnData;
            break;
          case "spaAverageTorqueIL":
            this.spaAverageTorqueIL = assessment.matchedColumnData;
            break;
          case "spaPeakTorqueIR":
            this.spaPeakTorqueIR = assessment.matchedColumnData;
            break;
          case "spaPeakTorqueIL":
            this.spaPeakTorqueIL = assessment.matchedColumnData;
            break;
          case "spaJumpHeight":
            this.spaJumpHeight = assessment.matchedColumnData;
            break;
          case "spaFlightTime":
            this.spaFlightTime = assessment.matchedColumnData;
            break;
          case "spaVelocity":
            this.spaVelocity = assessment.matchedColumnData;
            break;
          case "spaForce":
            this.spaForce = assessment.matchedColumnData;
            break;
          case "spaPower":
            this.spaPower = assessment.matchedColumnData;
            break;
          }
      });
  
      // Assign common athlete information
      if (this.selectdata.length > 0) {
        const firstData = this.selectdata[0];
        this.athleteFullName = firstData.athleteFullName;
        this.date = firstData.date;
        this.assessmentDate = firstData.assessmentDate;
        this.athleteId = firstData.athlete;
      }
    });
  }
  
 
 
  downloadPDF(): void {
    const DATA = document.getElementById('pdf-content');
    if (DATA) {
      html2canvas(DATA).then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgProps = pdf.getImageProperties(imgData);
        const imgWidth = pdfWidth * 0.8; // 80% of the page width
        const imgHeight = (imgProps.height * imgWidth) / imgProps.width;
        const margin = (pdfWidth - imgWidth) / 2;
        const topMargin = 20; // Add top margin
 
        pdf.addImage(imgData, 'PNG', margin, topMargin, imgWidth, imgHeight);
        pdf.save('Cognitive-details.pdf');
      });
    }
  }

  onBack():void{
    this.location.back();
  }

}
