<div class="container-fluid">
    <!--  Row 1 -->
    <div class="col-lg-11 align-items-stretch">
      <div class="card w-100">
        <div class="card-body p-2">
            <div class="d-flex justify-content-between align-items-center mb-4">
                <h5 class="card-title fw-semibold mb-0">Admin Table</h5>
                <select class="form-select w-auto">
                    <option value="option1">Coach</option>
                    <option value="option2">Athletes</option>
                    <option value="option3">Admin</option>
                    <option value="option3">Manager</option>
                    <option value="option3">Support Staff of Sports Science</option>
                    <option value="option3">Assessor</option>  
                </select>
            </div>
          <div class="table-responsive">
            <table class="table text-nowrap mb-0">
              <thead class="text-dark fs-4">
                <tr>
                  <th class="border-bottom-0">
                    <h6 class="fw-semibold mb-0">S.No</h6>
                  </th>
                  <th class="border-bottom-0">
                    <h6 class="fw-semibold mb-0">Name</h6>
                  </th>
                  <th class="border-bottom-0">
                    <h6 class="fw-semibold mb-0">Role</h6>
                  </th>
                  <!-- <th class="border-bottom-0">
                    <h6 class="fw-semibold mb-0">Injury Priority</h6>
                  </th> -->
                  <th class="border-bottom-0">
                    <h6 class="fw-semibold mb-0">Actions</h6>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="border-bottom-0"><h6 class="fw-semibold mb-0">1</h6></td>
                  <td class="border-bottom-0"><h6 class="mb-0 fw-normal">Sunil Joshi</h6></td>
                  <td class="border-bottom-0"><p class="mb-0 fw-normal">Coach</p></td>
                  <!-- <td class="border-bottom-0">
                    <div class="d-flex align-items-center gap-2">
                      <span class="badge bg-primary rounded-3 fw-semibold">Low</span>
                    </div>
                  </td> -->
                  <td class="border-bottom-0">
                    <button class="btn btn-primary" (click)="goToProfile()">View</button>
                  </td>
                </tr>
                <tr>
                  <td class="border-bottom-0"><h6 class="fw-semibold mb-0">2</h6></td>
                  <td class="border-bottom-0"><h6 class="mb-0 fw-normal">Andrew McDownland</h6></td>
                  <td class="border-bottom-0"><p class="mb-0 fw-normal">Athletes</p></td>
                  <!-- <td class="border-bottom-0">
                    <div class="d-flex align-items-center gap-2">
                      <span class="badge bg-secondary rounded-3 fw-semibold">Medium</span>
                    </div>
                  </td> -->
                  <td class="border-bottom-0">
                    <button class="btn btn-primary" (click)="goToProfile()">View</button>
                  </td>
                </tr>
                <tr>
                  <td class="border-bottom-0"><h6 class="fw-semibold mb-0">3</h6></td>
                  <td class="border-bottom-0"><h6 class="mb-0 fw-normal">Christopher Jamil</h6></td>
                  <td class="border-bottom-0"><p class="mb-0 fw-normal">Admin</p></td>
                  <!-- <td class="border-bottom-0">
                    <div class="d-flex align-items-center gap-2">
                      <span class="badge bg-danger rounded-3 fw-semibold">High</span>
                    </div>
                  </td> -->
                  <td class="border-bottom-0">
                    <button class="btn btn-primary" (click)="goToProfile()">View</button>
                  </td>
                </tr>
                <tr>
                  <td class="border-bottom-0"><h6 class="fw-semibold mb-0">5</h6></td>
                  <td class="border-bottom-0"><h6 class="mb-0 fw-normal">Nirav Joshi</h6></td>
                  <td class="border-bottom-0"><p class="mb-0 fw-normal">Manager</p></td>
                  <!-- <td class="border-bottom-0">
                    <div class="d-flex align-items-center gap-2">
                      <span class="badge bg-success rounded-3 fw-semibold">Critical</span>
                    </div>
                  </td> -->
                  <td class="border-bottom-0">
                    <button class="btn btn-primary" (click)="goToProfile()">View</button>
                  </td>
                </tr>
                <tr>
                    <td class="border-bottom-0"><h6 class="fw-semibold mb-0">6</h6></td>
                    <td class="border-bottom-0"><h6 class="mb-0 fw-normal">Nirav Joshi</h6></td>
                    <td class="border-bottom-0"><p class="mb-0 fw-normal">Support Staff of Sports Science</p></td>
                    <!-- <td class="border-bottom-0">
                      <div class="d-flex align-items-center gap-2">
                        <span class="badge bg-success rounded-3 fw-semibold">Critical</span>
                      </div>
                    </td> -->
                    <td class="border-bottom-0">
                        <button class="btn btn-primary" (click)="goToProfile()">View</button>
                      </td>
                  </tr>
                  <tr>
                    <td class="border-bottom-0"><h6 class="fw-semibold mb-0">7</h6></td>
                    <td class="border-bottom-0"><h6 class="mb-0 fw-normal">Tarun Krishna</h6></td>
                    <td class="border-bottom-0"><p class="mb-0 fw-normal">Assessor</p></td>
                    <td class="border-bottom-0">
                        <button class="btn btn-primary" (click)="goToProfile()">View</button>
                      </td>
                  </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>