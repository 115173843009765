<!--  Main wrapper -->
<div *ngIf="isLoading" class="loading-spinner">
  <div class="spinner"></div>
</div>
<!-- <div class="text-center1" *ngIf="this.nutrionReport.length === 0">
  No Data!
</div> -->
<div>
  <div class="row d-flex align-items-stretch">  
  <div class="col-sm-9 flex-column d-flex stretch-card">
    <div class="row">
      <div class="col-sm-12 grid-margin d-flex stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-center justify-content-between">
              <h4 class="card-title mb-2">{{GraphHeader}} Performers In Nutrition</h4>
              <!-- <div class="select-wrapper">
                <select (change)="filterPerformers($event, 'overall')" class="form-select">
                  <option selected disabled>Select Option</option>
                  <option value="All">All</option>
                  <option value="excellent">Excellent</option>
                  <option value="good">Good</option>
                  <option value="average">Average</option>
                  <option value="poor">Poor</option>
                </select>
              </div> -->
              <div class="dropdown">
                <a href="#" class="text-success btn btn-link dropdown-toggle dropdown-arrow-none"
                  data-bs-toggle="dropdown" id="settingsDropdownsales">
                  <i class="mdi mdi-dots-horizontal"></i></a>
                <div class="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="settingsDropdownsales">
                  <a class="dropdown-item" (click)="filterPerformers('All', 'overall')">
                    <i class="mdi mdi-grease-pencil text-primary"></i>
                    All
                  </a>
                  <a class="dropdown-item" (click)="filterPerformers('excellent', 'overall')">
                    <i class="mdi mdi-grease-pencil text-primary"></i>
                    Excellent
                  </a>
                  <a class="dropdown-item" (click)="filterPerformers('good', 'overall')">
                    <i class="mdi mdi-grease-pencil text-primary"></i>
                    Good
                  </a>
                  <a class="dropdown-item" (click)="filterPerformers('average', 'overall')">
                    <i class="mdi mdi-grease-pencil text-primary"></i>
                    Average
                  </a>
                  <a class="dropdown-item" (click)="filterPerformers('poor', 'overall')">
                    <i class="mdi mdi-grease-pencil text-primary"></i>
                    Poor
                  </a>
                </div>
              </div>
            </div>
            <div id="nutrition-chart"></div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div class="col-sm-3 flex-column d-flex stretch-card">
    <div class="">
      <div class="col-lg-12 d-flex grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-center justify-content-between mb-3">
            <img src="assets/image/icon1.png" class="img-fluid" alt="icon1">
            <p class="pb-0">Players Count</p>

            </div>
            <h2 class="font-weight-bold"> {{ nutritionCount }}</h2>
            <button (click)="selectRole('Player', null)" class="btn btn-primary" *ngIf="Admin || OrgAdmin">Add New Player</button>

          </div>
        </div>
      </div>
      <div class="col-lg-12 d-flex grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-center justify-content-between mb-3">
            <img src="assets/image/icon2.png" class="img-fluid" alt="icon1">
            <p class="pb-0">Overall Score</p>
            </div>
            <h2 class="font-weight-bold mb-3">{{ averageOverallPercentage }}%</h2>
            <div class="progress">
              <!-- Use Angular's property binding to set the width of the progress bar -->
              <div class="progress-bar bg-warning" role="progressbar" [style.width.%]="averageOverallPercentage"
                [attr.aria-valuenow]="averageOverallPercentage" aria-valuemin="0" aria-valuemax="100">
              </div>
            </div>
          </div>
        </div>
      </div>
     
     
    </div>
  </div>
</div>

  <div class="row row-cards row-deck">
    <div class="col-sm-9">
      <div class="col-12">
        <h4 class="card-title fw-semibold mb-2">International Benchmark (IBM)</h4>
        <div class="card py-3">
         
          <div class="table-responsive">
            <table class="table table-hover table-outline table-vcenter text-nowrap card-table">
              <thead>
                <tr>
                  <th>Test</th>
                  <th>IBM Male</th>
                  <th>IBM Female</th>
                  <th>Team Average Score</th>
                  <th>Team Average PCTL</th>
    
    
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>BMI</td>
                  <td>18.5–24.9</td>
                  <td>18.5–24.9</td>
                  <td>{{ this.averageNaBMI | number: '1.2-2' }}</td>
                  <td>
                    <div class="circular-progress mb-2">
                      <svg width="60" height="60" class="progress-circle">
                        <!-- Background Circle -->
                        <circle class="background" cx="30" cy="30" r="27" stroke="green" stroke-width="6" fill="none">
                        </circle>
                        <!-- Foreground Circle (Percentage) -->
                        <circle class="circle" cx="30" cy="30" r="27" [attr.stroke]="getColor(averageBMI)" stroke-width="6"
                          stroke-dasharray="169.65" stroke-dashoffset="42.41" stroke-linecap="round">
                        </circle>
                        <!-- Hardcoded Percentage Text in Center -->
                        <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="#000" font-size="12">
                          {{ averageBMI | number: '1.2-2' }}
                        </text>
                      </svg>
                    </div>
    
    
                  </td>
                </tr>
    
    
    
                <tr>
                  <td>Body Fat</td>
                  <td>7-12</td>
                  <td>12-18</td>
                  <td>{{ averageNaBodyFat | number: '1.2-2' }}</td>
                  <td>
                    <div class="circular-progress mb-2">
                      <svg width="60" height="60" class="progress-circle">
                        <!-- Background Circle -->
                        <circle class="background" cx="30" cy="30" r="27" stroke="green" stroke-width="6" fill="none">
                        </circle>
                        <!-- Foreground Circle (Percentage) -->
                        <circle class="circle" cx="30" cy="30" r="27" [attr.stroke]="getColor(averageBodyFat)"
                          stroke-width="6" stroke-dasharray="169.65" stroke-dashoffset="42.41" stroke-linecap="round">
                        </circle>
                        <!-- Hardcoded Percentage Text in Center -->
                        <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="#000" font-size="12">
                          {{ averageBodyFat | number: '1.2-2' }}
                        </text>
                      </svg>
                    </div>
    
    
                  </td>
                </tr>
                <tr>
                  <td>Fat Free Weight</td>
                  <td>40-60</td>
                  <td>30-50</td>
                  <td>{{ averageNaFatFreeWeight | number: '1.2-2' }}</td>
                  <td>
                    <div class="circular-progress mb-2">
                      <svg width="60" height="60" class="progress-circle">
                        <!-- Background Circle -->
                        <circle class="background" cx="30" cy="30" r="27" stroke="green" stroke-width="6" fill="none">
                        </circle>
                        <!-- Foreground Circle (Percentage) -->
                        <circle class="circle" cx="30" cy="30" r="27" [attr.stroke]="getColor(averageFatFreeWeight)"
                          stroke-width="6" stroke-dasharray="169.65" stroke-dashoffset="42.41" stroke-linecap="round">
                        </circle>
                        <!-- Hardcoded Percentage Text in Center -->
                        <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="#000" font-size="12">
                          {{ averageFatFreeWeight | number: '1.2-2' }}
                        </text>
                      </svg>
                    </div>
    
    
                  </td>
                </tr>
                <tr>
                  <td>Body Water</td>
                  <td>50-65</td>
                  <td>45–60</td>
                  <td>{{ averageNaBodyWater | number: '1.2-2' }}</td>
                  <td>
                    <div class="circular-progress mb-2">
                      <svg width="60" height="60" class="progress-circle">
                        <!-- Background Circle -->
                        <circle class="background" cx="30" cy="30" r="27" stroke="green" stroke-width="6" fill="none">
                        </circle>
                        <!-- Foreground Circle (Percentage) -->
                        <circle class="circle" cx="30" cy="30" r="27" [attr.stroke]="getColor(averageBodyWater)"
                          stroke-width="6" stroke-dasharray="169.65" stroke-dashoffset="42.41" stroke-linecap="round">
                        </circle>
                        <!-- Hardcoded Percentage Text in Center -->
                        <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="#000" font-size="12">
                          {{ averageBodyWater | number: '1.2-2' }}
                        </text>
                      </svg>
                    </div>
    
    
                  </td>
                </tr>
                <tr>
                  <td>Bone Mass</td>
                  <td>2.5-4.0</td>
                  <td>1.8–3.0</td>
                  <td>{{ averageNaBoneMass | number: '1.2-2' }}</td>
                  <td>
                    <div class="circular-progress mb-2">
                      <svg width="60" height="60" class="progress-circle">
                        <!-- Background Circle -->
                        <circle class="background" cx="30" cy="30" r="27" stroke="green" stroke-width="6" fill="none">
                        </circle>
                        <!-- Foreground Circle (Percentage) -->
                        <circle class="circle" cx="30" cy="30" r="27" [attr.stroke]="getColor(averageBoneMass)"
                          stroke-width="6" stroke-dasharray="169.65" stroke-dashoffset="42.41" stroke-linecap="round">
                        </circle>
                        <!-- Hardcoded Percentage Text in Center -->
                        <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="#000" font-size="12">
                          {{ averageBoneMass | number: '1.2-2' }}
                        </text>
                      </svg>
                    </div>
    
    
                  </td>
                </tr>
                <tr>
                  <td>Protein</td>
                  <td>16-20</td>
                  <td>16–20</td>
                  <td>{{ averageNaProtein | number: '1.2-2' }}</td>
                  <td>
                    <div class="circular-progress mb-2">
                      <svg width="60" height="60" class="progress-circle">
                        <!-- Background Circle -->
                        <circle class="background" cx="30" cy="30" r="27" stroke="green" stroke-width="6" fill="none">
                        </circle>
                        <!-- Foreground Circle (Percentage) -->
                        <circle class="circle" cx="30" cy="30" r="27" [attr.stroke]="getColor(averageProtein)"
                          stroke-width="6" stroke-dasharray="169.65" stroke-dashoffset="42.41" stroke-linecap="round">
                        </circle>
                        <!-- Hardcoded Percentage Text in Center -->
                        <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="#000" font-size="12">
                          {{ averageProtein | number: '1.2-2' }}
                        </text>
                      </svg>
                    </div>
    
    
                  </td>
                </tr>
                <tr>
                  <td>BMR </td>
                  <td>1600-1800</td>
                  <td>1300–1500</td>
                  <td>{{ averageNaBMR | number: '1.2-2' }}</td>
                  <td>
                    <div class="circular-progress mb-2">
                      <svg width="60" height="60" class="progress-circle">
                        <!-- Background Circle -->
                        <circle class="background" cx="30" cy="30" r="27" stroke="green" stroke-width="6" fill="none">
                        </circle>
                        <!-- Foreground Circle (Percentage) -->
                        <circle class="circle" cx="30" cy="30" r="27" [attr.stroke]="getColor(averageBMR)" stroke-width="6"
                          stroke-dasharray="169.65" stroke-dashoffset="42.41" stroke-linecap="round">
                        </circle>
                        <!-- Hardcoded Percentage Text in Center -->
                        <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="#000" font-size="12">
                          {{ averageBMR | number: '1.2-2' }}
                        </text>
                      </svg>
                    </div>
    
    
                  </td>
                </tr>
              </tbody>
              <!-- Template for when there is no data -->
              <ng-template #noData>
                <tr>
                  <td colspan="8" class="text-center">Data not available</td>
                </tr>
              </ng-template>
            </table>
          </div>
        </div>
      </div>
   
  </div>
<div class="col-sm-3">
  <div class="row flex-grow">
    <div class="col-sm-12 grid-margin stretch-card">
      <div class="card">
        <div class="card-body" *ngIf="TopPerformer.length > 0">
          <div class="row">
            <div>
              <h4 class="card-title fw-semibold">Top Performer</h4>
              <h4  *ngIf="!sciStaff"  class="font-weight-bold text-name">Name : {{ TopPerformer[0]?.usrFullName }}</h4>
              <h4 class="font-weight-bold text-id">ID :
                {{ TopPerformer[0]?.naAthlete }}
              </h4>
              <p class="text-dark">{{ TopPerformer[0]?.naCreatedOn | date:'dd-MM-yyyy' }}</p>
            </div>
            <!-- <div class="col-lg-4">
              <div class="position-relative">
                <img src="{{nutritionTop5Performer[0].imageUrl?.changingThisBreaksApplicationSecurity}}"
                  class="w-100" alt="">
                <div class="live-info badge badge-success">Live</div>
              </div>
            </div> -->
          </div>
          <div class="donut-container d-flex align-items-center justify-content-center mb-4">
            <div class="donut-circle"
                 [ngClass]="{
                   'dark-green': TopPerformer[0]?.overallZScore >= 85,
                   'light-green': TopPerformer[0]?.overallZScore >= 70 && TopPerformer[0]?.overallZScore < 85,
                   'yellow': TopPerformer[0]?.overallZScore >= 40 && TopPerformer[0]?.overallZScore < 70,
                   'red': TopPerformer[0]?.overallZScore < 40,
                   'transparent-border': TopPerformer[0]?.overallZScore == null
                 }">
              <div class="inner-circle">
                <h3 class="font-weight-bold">{{  TopPerformer[0]?.overallZScore| number: '1.2-2' }}%</h3>
              </div>
            </div>
            <!-- <p class="score-tag">Overall Score</p> -->
          </div>
          <table class="score-table">
            <thead>
            <tr>
              <th>Test</th>
              <th class="text-center">Score</th>
            </tr>
          </thead>
         <tbody>
            <tr>
              <td class="score-title">BMI (kg/m²)</td>
              <td class="score-value">{{ ( TopPerformer[0]?.naBMI % 1 === 0) 
                ?  TopPerformer[0]?.naBMI 
                : ( TopPerformer[0]?.naBMI | number: '1.2-2') }}</td>
            </tr>
            <tr>
              <td class="score-title">Body Fat (%)</td>
              <td class="score-value">{{ ( TopPerformer[0]?.naBodyFat % 1 === 0) 
                ?  TopPerformer[0]?.naBodyFat 
                : ( TopPerformer[0]?.naBodyFat | number: '1.2-2') }}</td>
            </tr>
            <tr>
              <td class="score-title">Skeletal Muscle (%)</td>
              <td class="score-value">{{ ( TopPerformer[0]?.naSkeletalMuscle % 1 === 0) 
                ?  TopPerformer[0]?.naSkeletalMuscle 
                : ( TopPerformer[0]?.naSkeletalMuscle | number: '1.2-2') }}</td>
            </tr>
            <tr>
              <td class="score-title">Bone Mass (kg)</td>
              <td class="score-value">{{ ( TopPerformer[0]?.naBoneMass % 1 === 0) 
                ?  TopPerformer[0]?.naBoneMass 
                : ( TopPerformer[0]?.naBoneMass | number: '1.2-2') }}</td>
            </tr>
            <tr>
              <td class="score-title">Protein (%)</td>
              <td class="score-value">{{ ( TopPerformer[0]?.naProtein % 1 === 0) 
                ?  TopPerformer[0]?.naProtein 
                : ( TopPerformer[0]?.naProtein | number: '1.2-2') }}</td>
            </tr>
          </tbody>
          </table>
        </div>
        <div  class="card-body d-flex align-items-center justify-content-center" *ngIf="TopPerformer.length== 0">
          <h4 class="text-muted">No Data Available</h4>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="d-sm-flex justify-content-between align-items-center mb-2">
  <h4 class="card-title fw-semibold">{{header}}</h4>
  <div>
    <div class="select-wrapper">
      <!-- <i class="bx bx-cog select-icon"></i> -->

      <ng-select [(ngModel)]="selectedStatus" [items]="selectOptions" bindLabel="label" bindValue="value"
        (change)="onNutritionStatusChange()" class="ng-select-custom" [searchable]="false" [clearable]="false">
        <ng-template ng-label-tmp let-item="item">
          <i class="fa fa-chevron-down"></i> {{ item.label }}
        </ng-template>
        <ng-template ng-option-tmp let-item="item">
          {{ item.label }}
        </ng-template>
      </ng-select>

    </div>
  </div>
</div>
<!-- Top Performers Section -->
<div class="col-12">
  <div class="card py-3">
    <h4 class="m-3" *ngIf="chat">Athlete Data</h4>
    <div class="table-responsive">
      <table class="table table-hover table-outline table-vcenter text-nowrap card-table">
        <thead>
          <tr>
          
            <th>Athletes</th>
            <th>Overall%</th>

            <th>Height (cm)</th>
            <th>Weight (Kgs)</th>
            <th>BMI (kg/m²)</th>
            <th>Body Fat(%)</th>
            <th>Fat Free Weight (kg)</th>
            <th>Subcutaneous Fat (kg)</th>
            <th>Visceral Fat (kg)</th>
            <th>Body Water (%)</th>
            <th>Skeletal Muscle (%)</th>
            <th>Lean Mass (kg)</th>
            <th>Bone Mass (kg)</th>
            <th>Protein (%)</th>
            <th>BMR (kcal/day)</th>
            <th>Metabolic Age (years)</th>
            <th>Health Score</th>

          </tr>
        </thead>
        <tbody *ngIf="nutritiontopPerformer && nutritiontopPerformer.length > 0; else noData">
          <tr *ngFor="let performer of nutritiontopPerformer">
     
            <td>
              <div class="d-flex align-items-center">
              <img class="avatar" src="{{performer.image}}">
              <div>
            {{ performer.usrFullName}}
              <div class="small text-muted">ID: {{ performer.naAthlete }}</div>
            </div>
              </div>
            </td>
            <td>
              <div class="clearfix">
                <div class="float-left"><strong>{{ performer.overallZScore| number: '1.2-2' }} %</strong></div>
              </div>
              <div class="progress progress-xs">
                <div class="progress-bar" [ngClass]="{
                              'bg-lightgreen':  performer.overallZScore >= 85,
                              'bg-green':  performer.overallZScore >= 70 &&  performer.overallZScore < 85,
                              'bg-yellow':  performer.overallZScore >= 40 &&  performer.overallZScore  < 70,
                              'bg-red': performer.overallZScore < 40
                            }" role="progressbar" [style.width.%]=" performer.overallZScore"></div>
              </div>
            </td>
            <td>{{ performer.naBodyHeight }}</td>
            <td>{{ performer.naBodyWeight }}</td>
            <td class="text-center">{{ performer.naBMI }}</td>
            <td class="text-center">{{ performer.naBodyFat }}</td>
            <td class="text-center">{{ performer.naFatFreeWeight }}</td>
            <td class="text-center">{{ performer.naSubcutaneousFat }}</td>
            <td class="text-center">{{ performer.naVisceralFat }}</td>
            <td class="text-center">{{ performer.naBodyWater }}</td>
            <td class="text-center">{{ performer.naSkeletalMuscle }}</td>
            <td class="text-center">{{ performer.naLeanMass }}</td>
            <td class="text-center">{{ performer.naBoneMass }}</td>
            <td class="text-center">{{ performer.naProtein }}</td>
            <td class="text-center">{{ performer.naBMR }}</td>
            <td class="text-center">{{ performer.naMetabolicAge }}</td>
            <td class="text-center">{{ performer.naHealthScore }}</td>

          </tr>
        </tbody>
        <!-- Template for when there is no data -->
        <ng-template #noData>
          <tr>
            <td colspan="8" class="text-center">Data not available</td>
          </tr>
        </ng-template>
      </table>
    </div>
  </div>
</div>




  </div>


</div>