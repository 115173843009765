import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Location } from '@angular/common';
import { AssessmentService } from '../../../services/assessment.service';
import { SharedService } from '../../../services/shared.service';
import { DomSanitizer } from '@angular/platform-browser';
import { NotificationService } from '../../../services/Notifications.service';
import { PrivilegeService } from '../../../services/privilage.service';
import { Privilege } from '../../../model/Privillage';
 
interface IRow {
  SportName: string;
  Range: number;
  RangePercentage: number;
}

@Component({
  selector: 'app-levelone-report',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './levelone-report.component.html',
  styleUrl: './levelone-report.component.css'
})
export class LeveloneReportComponent {
  id: any;
  leveloneReport: any;
  rowData!: IRow[];
  player: any;
  assessment: any;
  detail: any;
  usrRole: any;
  ImageUrl: any;
  showImage: boolean = false;
  athleteId: any;
  image: any;
  status: any;
  privilege: Privilege;
  Isplayer: boolean;
  constructor(private route: ActivatedRoute,private location: Location,private sanitizer: DomSanitizer,private notify:NotificationService,private assessmentService: AssessmentService,private sharedService: SharedService,private router:Router,private priService:PrivilegeService) {
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
      this.athleteId = params['athleteId'];
      this.status = params['selectStatus'];
 
    });
    this.privilege = this.priService.getPrivileges();
    this.Isplayer = this.privilege.isPlayer;
    this.getProfileImage();
  }
  

  ngOnInit(): void {
 
    this.sharedService.detail$.subscribe((data: any) => {
      this.detail = data;
      this.usrRole=this.detail.user.usrRole;
    });
    // if(this.athleteId){
      this.assessmentService.getLevelOneByAsm(this.athleteId,this.id).subscribe((response: any) => {
     
        const data = response[0];
        console.log(data,"levelone")
        this.assessment = data.loaAsessmentDate.split('T')[0];
        const dateParts = this.assessment.split('-'); 
        this.assessment = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`; 
        this.player = response[0];
  
        this.rowData = [
          { SportName: "Height", Range: data.loaHeight, RangePercentage: data.loaHeightRange },
          { SportName: "Weight", Range: data.loaWeight, RangePercentage: data.loaWeightRange },
          { SportName: "Ruler Drop", Range: data.loaRulerDrop, RangePercentage:data.loaRulerDropRange},
          { SportName: "Hand Grip", Range: data.loaHandGrip, RangePercentage:data.loaHandGripRange},
          { SportName: "Seated Med Ball", Range: data.loaSeatedMedBall, RangePercentage:data.loaSeatedMedBallRange},
          { SportName: "Sit & Reach", Range: data.loaSitnReach, RangePercentage:data.loaSitnReachRange},
          { SportName: "Vertical Jump", Range: data.loaVerticalJump, RangePercentage:data.loaVerticalJumpRange},
          { SportName: "Wall Toss", Range: data.loaWallToss, RangePercentage:data.loaWallTossRange},
          { SportName: "30m Sprint", Range: data.loa30mSprint, RangePercentage:data.loa30mSprintRange},
          { SportName: "6x10m Shuttle Run", Range: data.loa6x10ShuttleRun, RangePercentage:data.loa6x10ShuttleRunRange},
          { SportName: "800m Run", Range: data.loa800mRun, RangePercentage:data.loa800mRunRange},
        ].filter(item => item.Range !== null && item.RangePercentage !== 0);
      });
    // }else{
    //   this.assessmentService.getLevelOneListById(this.id).subscribe((response: any) => {
    //     const data = response[0];
    //     this.assessment = data.loaAsessmentDate.split('T')[0];
    //     const dateParts = this.assessment.split('-'); 
    //     this.assessment = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`; 
    //     this.player = response[0];
  
    //     this.rowData = [
    //       { SportName: "Height", Range: data.loaHeight, RangePercentage: data.loaHeightRange },
    //       { SportName: "Weight", Range: data.loaWeight, RangePercentage: data.loaWeightRange },
    //       { SportName: "Ruler Drop", Range: data.loaRulerDrop, RangePercentage:data.loaRulerDropRange},
    //       { SportName: "Hand Grip", Range: data.loaHandGrip, RangePercentage:data.loaHandGripRange},
    //       { SportName: "Seated Med Ball", Range: data.loaSeatedMedBall, RangePercentage:data.loaSeatedMedBallRange},
    //       { SportName: "Sit & Reach", Range: data.loaSitnReach, RangePercentage:data.loaSitnReachRange},
    //       { SportName: "Vertical Jump", Range: data.loaVerticalJump, RangePercentage:data.loaVerticalJumpRange},
    //       { SportName: "Wall Toss", Range: data.loaWallToss, RangePercentage:data.loaWallTossRange},
    //       { SportName: "30m Sprint", Range: data.loa30mSprint, RangePercentage:data.loa30mSprintRange},
    //       { SportName: "6x10m Shuttle Run", Range: data.loa6x10ShuttleRun, RangePercentage:data.loa6x10ShuttleRunRange},
    //       { SportName: "800m Run", Range: data.loa800mRun, RangePercentage:data.loa800mRunRange},
    //     ];
    //   });
    // }
  }
  
getProfileImage() {
  this.ImageUrl = null;
  this.showImage = false;

  this.assessmentService.getEmptyImage(this.athleteId).subscribe(
    (response) => {
      if (response) {
        this.showImage = true;
         this.image = "assets/image/profileimg.jpg"
      
        
      }
    },
    (error) => {
      this.assessmentService.getImage(this.athleteId).subscribe(
        (imageBlob) => {
      const objectURL = URL.createObjectURL(imageBlob);
      this.ImageUrl = this.sanitizer.bypassSecurityTrustUrl(objectURL);
      this.image = this.ImageUrl.changingThisBreaksApplicationSecurity
     
      this.showImage = true;
        }
    );
      console.error('Error fetching image', error);
    }
  );
}

 
  getProgressBarClass(RangePercentage: number): string {
    if (RangePercentage >= 90) {
     
        return 'bg-green'; // Red segment (Bootstrap class)
      } else if (RangePercentage == 50 && RangePercentage < 90) {
        return 'bg-yellow'; // Yellow segment (Bootstrap class)
      } else {
        return 'bg-red'; // Green segment (Bootstrap class)
 
       
    }
  }
 
 
  getProgressText(percentage: number): string {
    if (percentage >= 90) {
      return 'Good';
    } else if (percentage == 50 && percentage < 90) {
      return 'Average';
    } else {
      return 'Below Average';
    }
  }
 
  getTextColorClass(percentage: number): string {
    if (percentage >= 90) {
      return 'text-success';
    } else if (percentage == 50 && percentage < 90) {
      return 'text-warning';
    } else {
      return 'text-danger';
    }
  }
 
  downloadPDF(): void {
    const DATA = document.getElementById('pdf-content');
    if (DATA) {
        html2canvas(DATA, {
            scale: 2, // Increase the scale to improve quality
        }).then(canvas => {
            const context = canvas.getContext('2d');
  
            if (context) {
                // Find the progress bar and progress value elements
                const progressBar = document.querySelector('.progress-circle1 .progress-bar') as HTMLElement;
                const progressValueElement = document.querySelector('.progress-circle1 .progress-value') as HTMLElement;
                const progressValue = parseFloat(progressBar?.style.getPropertyValue('--progress') || '0');
                const rect = progressBar?.getBoundingClientRect();
  
                if (rect) {
                    // Calculate the center of the progress circle
                    const centerX = rect.left + rect.width / 2;
                    const centerY = rect.top + rect.height / 2;
                    const radius = rect.width / 2;
  
                    // Draw background circle (full)
                    context.beginPath();
                    context.arc(centerX, centerY, radius, 0, 2 * Math.PI);
                    context.fillStyle = '#323860'; // Background color
                    context.fill();
  
                    // Draw the progress arc
                    const startAngle = -Math.PI / 2;
                    const endAngle = startAngle + (2 * Math.PI * (progressValue / 100));
                    context.beginPath();
                    context.arc(centerX, centerY, radius, startAngle, endAngle);
                    context.lineTo(centerX, centerY); // Connect the arc to the center
                    context.fillStyle = 'white'; // Progress color
                    context.fill();
  
                    // Draw the progress value text
                    context.font = '24px sans-serif';
                    context.textAlign = 'center';
                    context.textBaseline = 'middle';
                    context.fillStyle = 'white';

                    // Adjust position to be slightly above the center
                    const valueY = centerY - radius * 0.3; // Position the value above the center
                    context.fillText(progressValueElement.textContent || '', centerX, valueY);
                }
  
                const imgData = canvas.toDataURL('image/jpeg', 0.7);
                const pdf = new jsPDF('p', 'mm', 'a4');
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = pdf.internal.pageSize.getHeight();
                const imgProps = pdf.getImageProperties(imgData);
                const imgWidth = pdfWidth * 0.8;
                const imgHeight = (imgProps.height * imgWidth) / imgProps.width;
                const margin = (pdfWidth - imgWidth) / 2;
                const topMargin = 40;
  
                pdf.addImage(imgData, 'JPEG', margin, topMargin, imgWidth, imgHeight, undefined, 'FAST');
                pdf.save('Levelone-Report.pdf');
            }
        });
    }
}
onBack():void{
  if( this.Isplayer ){
    this.router.navigate(['/saifitness'], {
      queryParams: {
        statusId: this.status
      }
    });
  }else{
    this.location.back();
}
  
  
} 
  
}
