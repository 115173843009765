import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AssessmentService } from './assessment.service';
 
@Injectable({
  providedIn: 'root'
})
export class IbmService {
 
  constructor(private assessmentService: AssessmentService) { }
 
  calculateIBM(
    genderId: number,
    asiCurrentClubTeam: string,
    age:any,
    players: any
  ): Observable<any[]> {
    return new Observable((observer) => {
      // Normalize players to always be an array
      const playerArray = Array.isArray(players) ? players : [players];
 
      this.assessmentService.getIBMReference(age,genderId, asiCurrentClubTeam,)
      .subscribe((response: any[]) => {
        playerArray.forEach((player: any) => {
          const playerIBM: Record<string, number> = {};
   
          response.forEach((ibm) => {
            const columnName = ibm.testColumnName; // e.g., 'naBodyWater'
            let ibmRange = ibm.ibmRange; // e.g., "7–12" or ">12"
   
            if (player[columnName] !== undefined && ibmRange) {
              // Normalize the range
              ibmRange = ibmRange.replace(/–/g, '-');
   
              // Check if the range contains "<" or ">"
              if (ibmRange.includes('<') || ibmRange.includes('>') || ibmRange.includes('≤') || ibmRange.includes('≥')) {
                const rangeValue = parseFloat(ibmRange.replace(/[<>≤≥]/g, '').trim());
                
                if (!isNaN(rangeValue)) {
                  let value = player[columnName];
   
                  // Check if the value is a ratio (e.g., "12/12")
                  if (typeof value === 'string' && value.includes('/')) {
                    const parts = value.split('/').map(Number);
                    if (parts.length === 2 && !isNaN(parts[0]) && !isNaN(parts[1])) {
                      value = (parts[0] + parts[1]) / 2; // Calculate the average
                    }
                  }
   
                  // Calculate percentage
                  let percentage = ((value / rangeValue) * 100);
                  percentage = Math.max(0, Math.min(percentage, 100));
                  percentage = parseFloat(percentage.toFixed(2));
   
                  playerIBM[columnName] = percentage;
                }
              } else {
                // Validate and parse the range
                const trimmedRange = ibmRange.trim();
                const rangeMatch = trimmedRange.match(/^([\d.]+)-([\d.]+)$/);
    
                if (rangeMatch) {
                  const lower = parseFloat(rangeMatch[1]);
                  const upper = parseFloat(rangeMatch[2]);
   
                  if (!isNaN(lower) && !isNaN(upper)) {
                    let value = player[columnName];
   
                    // Check if the value is a ratio (e.g., "12/12")
                    if (typeof value === 'string' && value.includes('/')) {
                      const parts = value.split('/').map(Number);
                      if (parts.length === 2 && !isNaN(parts[0]) && !isNaN(parts[1])) {
                        value = (parts[0] + parts[1]) / 2; // Calculate the average
                      }
                    }
   
                    // Calculate percentage
                    let percentage = ((value - lower) / (upper - lower)) * 100;
   
                    percentage = Math.max(0, Math.min(percentage, 100));
                    percentage = parseFloat(percentage.toFixed(2));
   
                    playerIBM[columnName] = percentage;
                  }
                }
              }
            }
          });
   
          player['IBM'] = playerIBM;
        });
   
        observer.next(playerArray);
        observer.complete();
      });
   
    });
  }
 
  calculateOverAllIBM(players: any[]): Observable<any[]> {
    return new Observable((observer) => {
      // Normalize players to always be an array
      const playerArray = Array.isArray(players) ? players : [players];
 
      // Fetch the sports information
      this.assessmentService.getSport().subscribe(
        (sports: any[]) => {
          playerArray.forEach((player: any) => {
            // Derive genderId
            const genderId = player.genderId;
 
            // Derive age
            const dob = new Date(player.usrDob);
            const ageInYears = new Date().getFullYear() - dob.getFullYear();
            const age = ageInYears >= 18 ? 1 : 0;
 
            // Derive asiCurrentClubTeam based on asiPrimarySport
            const matchingSport = sports.find((sport: any) => sport.sportName === player.asiPrimarySport);
            const asiCurrentClubTeam = matchingSport ? matchingSport.sportId : null;
 
            if (asiCurrentClubTeam !== null) {
              // Fetch IBM reference data
              this.assessmentService
                .getIBMReference(age, genderId, asiCurrentClubTeam)
                .subscribe((response: any[]) => {
                  const playerIBM: Record<string, number> = {};
                  let totalIBM = 0; // To store the sum of all IBM values
                  let ibmCount = 0; // To count the number of IBM values
 
                  response.forEach((ibm) => {
                    const columnName = ibm.testColumnName; // e.g., 'naBodyWater'
                    let ibmRange = ibm.ibmRange; // e.g., "7–12" or ">12"
 
                    if (player[columnName] !== undefined && ibmRange) {
                      // Normalize the range
                      ibmRange = ibmRange.replace(/–/g, '-');
 
                      // Check if the range contains "<" or ">"
                      if (ibmRange.includes('<') || ibmRange.includes('>')) {
                        const rangeValue = parseFloat(ibmRange.replace(/[<>]/g, '').trim());
 
                        if (!isNaN(rangeValue)) {
                          let value = player[columnName];
 
                          // Check if the value is a ratio (e.g., "12/12")
                          if (typeof value === 'string' && value.includes('/')) {
                            const parts = value.split('/').map(Number);
                            if (parts.length === 2 && !isNaN(parts[0]) && !isNaN(parts[1])) {
                              value = (parts[0] + parts[1]) / 2; // Calculate the average
                            }
                          }
 
                          // Calculate percentage
                          let percentage = (value / rangeValue) * 100;
                          percentage = Math.max(0, Math.min(percentage, 100));
                          percentage = parseFloat(percentage.toFixed(2));
 
                          playerIBM[columnName] = percentage;
                          totalIBM += percentage; // Add to total
                          ibmCount++; // Increment the count
                        }
                      } else {
                        // Validate and parse the range
                          const trimmedRange = ibmRange.trim();
                          const rangeMatch = trimmedRange.match(/^([\d.]+)-([\d.]+)$/);
 
                        if (rangeMatch) {
                          const lower = parseFloat(rangeMatch[1]);
                          const upper = parseFloat(rangeMatch[2]);
 
                          if (!isNaN(lower) && !isNaN(upper)) {
                            let value = player[columnName];
 
                            // Check if the value is a ratio (e.g., "12/12")
                            if (typeof value === 'string' && value.includes('/')) {
                              const parts = value.split('/').map(Number);
                              if (parts.length === 2 && !isNaN(parts[0]) && !isNaN(parts[1])) {
                                value = (parts[0] + parts[1]) / 2; // Calculate the average
                              }
                            }
 
                            // Calculate percentage
                            let percentage = ((value - lower) / (upper - lower)) * 100;
 
                            percentage = Math.max(0, Math.min(percentage, 100));
                            percentage = parseFloat(percentage.toFixed(2));
 
                            playerIBM[columnName] = percentage;
                            totalIBM += percentage; // Add to total
                            ibmCount++; // Increment the count
                          }
                        }
                      }
                    }
                  });
 
                  player['IBM'] = playerIBM;
                  // Calculate and store the average IBM value
                  player['ibmOverall'] = ibmCount > 0 ? parseFloat((totalIBM / ibmCount).toFixed(2)) : 0;
                });
            }
          });
 
          observer.next(playerArray);
          observer.complete();
        },
        (error: any) => {
          observer.error(error);
        }
      );
    });
  }
 
 
}
 