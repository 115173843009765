import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink, RouterOutlet } from '@angular/router';
import { HttpClientModule } from '@angular/common/http'; 
import { AssessmentService } from '../../../services/assessment.service';
import { NotificationService } from '../../../services/Notifications.service';
import { NgToastModule } from 'ng-angular-popup';
import { SharedService } from '../../../services/shared.service';

@Component({
  selector: 'app-physiology-form',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterOutlet, RouterLink, HttpClientModule,NgToastModule], // Add HttpClientModule here
  templateUrl: './physiology-form.component.html',
  styleUrls: ['./physiology-form.component.css']
})
export class PhysiologyformComponent {
  formSubmitted: boolean = false;
  personalDetailsFilled: boolean = false;
  physiotherapy: boolean = false;
  physiology: boolean = false;
  isReadOnly: boolean = false;

  assessment: any = {
      //plaId: '',
      plaAthlete: '',
      plaDate: '',
      plaAssessor: '',
      plaVO2Max: '',
      plaLactateThreshold: '',
      plaHeartRateVariablity: '',
      plaRestingHeartRate: '',
      plaTestResults: '',
      plaInterpretationResults: '',
      plaRecommendations: '',
      plaFollowUpDate: ''
  };
  athletes: any[] = [];
  selectedAthleteId: string | null = null;
  detail: any;
  UserId: any;
  id: any;
  constructor(private assessmentService: AssessmentService,private route: ActivatedRoute, private sharedService: SharedService,private router: Router, private notify:NotificationService) {
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
    });
    this.FetchAthelete();
  }

  ngOnInit() {
    this.sharedService.detail$.subscribe((data: any) => {
      this.detail = data;
      this.UserId=this.detail.user.usrId;
    });
  }

  checkPersonalDetailsFilled(): void {
    const personalDetailsInputs = document.querySelectorAll(".form.first input");
    this.personalDetailsFilled = Array.from(personalDetailsInputs).every(input => {
      const inputElement = input as HTMLInputElement;
      return inputElement.value.trim() !== '';
    });
  }

  Physiotherapy() {
    this.physiotherapy = true;
    this.physiology = false;
  }

  Physiology() {
    this.physiotherapy = false;
    this.physiology = true;
  }

  next(): void {
    this.checkPersonalDetailsFilled();
    if (this.personalDetailsFilled) {
      console.log(this.personalDetailsFilled);
      this.formSubmitted = true;
    }
  }

  back(): void {
    this.formSubmitted = false;
  }
  onSubmit(): void {
    this.assessment.plaAssessor = this.UserId;
    if (this.selectedAthleteId) {
      this.assessment.plaAthlete = this.selectedAthleteId;
    }
    if (this.isFormValid()) {
      this.assessmentService.addPhysiologyDetail([this.assessment]).subscribe(response =>{
        console.log('Assessment submitted', response);
        this.formSubmitted = true;
        this.notify.success("Form submitted successfully");
        setTimeout(() => {
          this.router.navigate(['physiologylist']);
        }, 1000);
      }, error => {
        console.error('Error submitting assessment', error);
 
      });
    } else {
      console.log('Form is not valid');
    }
  }

  isFormValid(): boolean {
    return Object.keys(this.assessment).every(key => {
      return this.assessment[key] !== '' && this.assessment[key] !== null;
    });
  }
  fetchAthletes(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement) {
      const query = inputElement.value;
      this.assessmentService.getAthlete(query).subscribe(data => {
        this.athletes = data;
        console.log(this.athletes,"name")
      }, error => {
        console.error('Error fetching athletes', error);
      });
    }
  }
  FetchAthelete(){
    if(this.id){
    this.assessmentService.fetchAtletePhysiology(this.id).subscribe((response: any) => {
      if (response && response.length > 0) {
        console.log(response,'data');
        const data = response[0];
        this.assessment.plaId = data.plaId;
        this.assessment.plaAthletename = data.athleteName;
        this.assessment.plaAthlete = data.plaAthlete;
        this.assessment.plaDate =data.plaDate ? data.plaDate.split('T')[0] : ''; 
        this.assessment.plaAssessorname = data.assessorName;
        this.assessment.plaAssessor = data.plaAssessor;
        this.assessment.plaVO2Max = data.plaVO2Max;
        this.assessment.plaLactateThreshold = data.plaLactateThreshold;
        this.assessment.plaHeartRateVariablity = data.plaHeartRateVariablity;
        this.assessment.plaRestingHeartRate = data.plaRestingHeartRate;
        this.assessment.plaTestResults = data.plaTestResults;
        this.assessment.plaInterpretationResults = data.plaInterpretationResults;
        this.assessment.plaRecommendations = data.plaRecommendations;
        this.assessment.plaFollowUpDate = data.plaFollowUpDate ? data.plaFollowUpDate.split('T')[0] : '';
        this.assessment.plaCreatedBy = data.plaCreatedBy;
        this.assessment.plaCreatedOn = new Date(data.plaCreatedOn);
        this.assessment.plaUpdatedBy = data.plaUpdatedBy;
        this.assessment.plaUpdatedOn = new Date(data.plaUpdatedOn);
      }
    },
    (error: any) => {
      console.error('Error fetching assessment data', error);
    }
  );
}
}
  onAthleteSelected(event: Event): void {
    const inputElement = event.target as HTMLInputElement | null;
    if (inputElement) {
      const selectedAthlete = this.athletes.find(athlete => athlete.usrFullName === inputElement.value);
      if (selectedAthlete) {
        this.selectedAthleteId = selectedAthlete.usrId;
      }
    }
  }
}
