import { AgGridAngular } from "ag-grid-angular";
import { ColDef } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { Component, NgZone } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ActivatedRoute, Router, RouterLink, RouterModule, RouterOutlet } from "@angular/router";
import { AssessmentService } from "../../../services/assessment.service";
import { User } from "../../../model/User";
import { UserService } from "../../../services/user.service";
import { PrivilegeService } from "../../../services/privilage.service";
import { Privilege } from "../../../model/Privillage";

interface IRow {
  playername: string;
  pfaId:string;
  pfaAthlete:string;
  Assessor: string;
  Speed: string;
  Strength: string;
  ReactionTime: string;
  priority: string;
  date: string;
}
@Component({
  selector: 'app-physicalfitness-detail-list',
  standalone: true,
  imports: [CommonModule,RouterModule,RouterLink,AgGridAngular],
  templateUrl: './physicalfitness-detail-list.component.html',
  styleUrl: './physicalfitness-detail-list.component.css'
})
export class PhysicalfitnessDetailListComponent {
  user: User | null = null;
  privilege: Privilege;
  OrgAdmin: boolean = false;
  Isplayer: boolean = false;

  themeClass = 'ag-theme-quartz';

  rowData: IRow[] = [];

  colDefs: ColDef[] = [];

  defaultColDef: ColDef = {
    flex: 1,
    sortable: true,
    filter: true,
  };
  id: any;
  name: any;
  UsrId: any;

  constructor(private router: Router, private route: ActivatedRoute,private assessmentService: AssessmentService,private ngZone: NgZone,
  private userService:UserService,private priService:PrivilegeService
  ) {
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
      console.log(this.id,"ID");
    });
    this.privilege = this.priService.getPrivileges();
    this.OrgAdmin = this.privilege.isOrgAdmin;
    this.Isplayer = this.privilege.isPlayer;
  }

  ngOnInit() {
    this.user = this.userService.getUser();
    if(this.id){
    this.assessmentService.getFitnessByAthleteId(this.id).subscribe((response:any) => {
      console.log('Assessment submitted', response);
      this.name = response[0].athleteName;
      this.UsrId=response[0].pfaAthlete;
      console.log(this.name);
      this.rowData = response.map((item:any) => ({
        playername: `${item.athleteName}`, 
        pfaId:`${item.pfaId}`, 
        pfaAthlete:`${item.pfaAthlete}`, 
        Assessor: `${item.assessorName}`, 
        Height: `${item.pfaHeight}`, 
        Weight: `${item.pfaWeight}`, 
        BMI: `${item.pfaBMI}`, 
        date: new Date(item.pfaDate).toLocaleDateString('en-US'),
      }));

      this.colDefs = this.getColumnDefinitions();
    }, (error: any) => {
      console.error('Error submitting assessment', error);
    });
  }
  }



  getColumnDefinitions(): ColDef[] {
    const columns: ColDef[] = [
      { field: 'Assessor' },
      { field: 'Height' },
      { field: 'Weight' },
      { field: 'BMI' },
      { field: 'date' },
      {
        headerName: 'Action',
        cellRenderer: (_params: any) => {
          const container = document.createElement('div');
          container.style.display = 'flex';
          container.style.justifyContent = 'space-around';
  
          // View button with eye icon
          const viewButton = document.createElement('button');
          viewButton.classList.add('btn');
          viewButton.innerHTML = '<i class="fa fa-eye"></i>';
          viewButton.addEventListener('click', () => {
            // if (this.isPlayer) {
            //   this.router.navigate(['/physicalfitnessdetail'])
            // } else {
              this.ngZone.run(() => {
              this.router.navigate(['/physicalfitnessdetail'], {queryParams: { id: _params.data.pfaId } })
              })
              //}
          });
  
          // Edit button with edit icon
          const editButton = document.createElement('button');
          editButton.classList.add('btn');
          editButton.innerHTML = '<i class="fa fa-edit"></i>';
          editButton.addEventListener('click', () => {
            this.ngZone.run(() => {
            this.router.navigate(['/physicalfitness'], {queryParams: { id: _params.data.pfaId } })
            })
          });
  
          // Delete button with delete icon
          // const deleteButton = document.createElement('button');
          // deleteButton.classList.add('btn');
          // deleteButton.innerHTML = '<i class="fa fa-trash"></i>';
          // deleteButton.addEventListener('click', () => {
          //   // Handle delete action
          // });
  
          container.appendChild(viewButton);
          if (!this.Isplayer && !this.OrgAdmin) {
          container.appendChild(editButton);
          }
          //container.appendChild(deleteButton);
  
          return container;
        },
        width: 120
      }
    ];
  
    if (this.privilege.isAdmin) {
      return columns;
    } else if (this.privilege.isPlayer) {
      return columns.filter(col => col.field !== 'playername');
    } else {
      return columns.filter(col => col.field !== 'Assessor');
    }
  }
}