import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router, RouterLink, RouterModule } from '@angular/router';
import { AssessmentService } from '../../../services/assessment.service';
import { NotificationService } from '../../../services/Notifications.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgToastModule } from 'ng-angular-popup';
import { SharedService } from '../../../services/shared.service';

@Component({
  selector: 'app-sports-performance-analysis',
  standalone: true,
  imports: [CommonModule, RouterModule, RouterLink, FormsModule,NgToastModule],
  templateUrl: './sports-performance-analysis.component.html',
  styleUrl: './sports-performance-analysis.component.css'
})
export class SportsPerformanceAnalysisComponent {
  constructor(private router: Router,
    private sharedService: SharedService,
    private assessmentService: AssessmentService,
    private route: ActivatedRoute,
    private notify: NotificationService,private sanitizer: DomSanitizer) {

      this.route.queryParams.subscribe(params => {
        this.id = params['id'];
      });
   
    }
    detail: any;
    UserId: any;
    ImageUrl: any;
    id:any;
    showImage: boolean = false;
   
  formSubmitted: boolean = false;
  assessment: any = {
    spaId: 0,
    spaAthlete: '',
    spaAssessor: 11,
    spaAssessment: 1,
    spaDate: '',
    spaAverageTorqueCR: '',
    spaAverageTorqueCL: '',
    spaPeakTorqueCR:'',
    spaPeakTorqueCL: '',
    spaAverageTorqueIR:'',
    spaAverageTorqueIL: '',
    spaPeakTorqueIR:'',
    spaPeakTorqueIL:'',
    spaJumpHeight:'',
    spaFlightTime:'',
    spaVelocity:'',
    spaForce:'',
    spaPower:'',
    // spaCreatedOn:'',
    // spaUpdatedOn:'',

  
}
getProfileImage() {
  this.ImageUrl = null;
  this.showImage = false;

  this.assessmentService.getImage(this.assessment.spaAthlete).subscribe(
    (imageBlob) => {
      if (imageBlob) {
        const objectURL = URL.createObjectURL(imageBlob);
        this.ImageUrl = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        this.showImage = true; 
        console.log(this.ImageUrl, "ImageUrl");
      } else {
        this.notify.failed("invalid athlete number.");
      }
    },
    (error) => {
      this.ImageUrl = null;
      this.showImage = false;
      this.notify.failed("invalid athlete number");
      console.error('Error fetching image', error);
    }
  );
}
onSubmit(){
  this.assessmentService.SaveSportsperformance([this.assessment]).subscribe(response => {
    console.log('Assessment submitted', response);
    this.formSubmitted = true;
    this.notify.success("Form submitted successfully");
    setTimeout(() => {
      this.router.navigate(['SportsPerformanceAnalysisList']);
    }, 1000);
  }, error => {
    console.log('Error submitting assessment', error);
  });   
}
}
