import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router, RouterLink, RouterOutlet } from '@angular/router';
import { AssessmentService } from '../../../services/assessment.service';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NotificationService } from '../../../services/Notifications.service';
import { NgToastModule } from 'ng-angular-popup';
import { UserRegisterService } from '../../../services/user-register.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-nutrition-editform-component',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterOutlet, RouterLink, HttpClientModule, NgToastModule],
  templateUrl: './nutrition-editform-component.component.html',
  styleUrl: './nutrition-editform-component.component.css'
})
export class NutritionEditformComponentComponent {
  id: any;
  formSubmitted: boolean = false;
  isReadOnly: boolean = false;
  selectedAthlete: any;
  athletes: any[] = [];
  selectedAthleteId: any;

  nutrition: any = {
    naId: null,
    naDate: null,
    naAssessor: null,
    naAthlete: null,
    naBodyWeight: null,
    naBodyHeight: null,
    naBMI: null,
    naBodyFatPct: null,
    naMuscleMass: null,
    naHemoglobin: null,
    naIronLevels: null,
    naVitaminDLevel: null,
    naVitaminB12Level: null,
    OtherNutrientLevels: null,
    naCurrentDiet: '',
    naNutriDeficiencies: '',
    naRecommendations: '',
    naFollowupDate: null,
    naAthleteName: null,
    naAssessorName: null
  };

  players: any;
  product: any;
  teamId: any;
  athleteId:any;
  testNames: any;
  Response:any;
  UserId: any;
  selectedFiles: FileList | null = null;
  ImageUrl: any;
  Status:any;
  showImage: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private assessmentService: AssessmentService,
    private userRegisterService: UserRegisterService,
    private sanitizer: DomSanitizer,
    private notify: NotificationService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
      console.log(this.id, "ID");
    });

    this.route.queryParams.subscribe(params => {
      this.athleteId = params['athleteId'];
      this.teamId = params['teamId'];
  
      if (this.athleteId) {
        this.UserId = this.athleteId; 
        this.getProfileImage(); 
      } else {
        console.log('Athlete ID not found in query params');
      }
  
      console.log(this.athleteId, "Athlete ID");
      console.log(this.teamId, "Team ID");
  
      this.FetchAthelete();
    });

    this.FetchAthelete();
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.isReadOnly = localStorage.getItem('athelete') !== '';
    } else {
      this.isReadOnly = false;
    }
    this.getTests();
  }

  onPlayerChange(event: any) {
    console.log(event.target.value, "athelete");
   
  }

  onSubmit(): void {
    if (this.isFormValid()) {
      this.assessmentService.addnutritional([this.nutrition]).subscribe(response => {
        console.log('Assessment submitted', response);
        this.formSubmitted = true;
        this.notify.success("Data Updated Successfully");
      }, error => {
        console.error('Error submitting assessment', error);
      });
    } else {
      console.log('Form is not valid');
    }
  }

  isFormValid(): boolean {
    return Object.keys(this.nutrition).every(key => {
      return this.nutrition[key] !== '' && this.nutrition[key] !== null;
    });
  }

  onAthleteSelected(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement) {
      const selectedAthlete = this.athletes.find((athlete: any) => athlete.usrFullName === inputElement.value);
      if (selectedAthlete) {
        this.selectedAthleteId = selectedAthlete.usrId;
      }
    }
  }

  fetchAthletes(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const query = inputElement.value.trim();
    if (query) {
      this.assessmentService.getAthlete(query).subscribe(data => {
        this.athletes = data;
      }, error => {
        console.error('Error fetching athletes', error);
      });
    }
  }

  getProfileImage() {
    this.ImageUrl = null;
    this.showImage = false;
    this.assessmentService.getImage(this.athleteId).subscribe(
      (imageBlob) => {
        if (imageBlob) {
          const objectURL = URL.createObjectURL(imageBlob);
          this.ImageUrl = this.sanitizer.bypassSecurityTrustUrl(objectURL);
          this.showImage = true;
          console.log(this.ImageUrl, "ImageUrl");
        } else {
          this.notify.failed("Invalid athlete number.");
        }
      },
    );
  }

  FetchAthelete() {
    console.log(this.id, "init", this.formSubmitted);
    if (this.id) {
      this.assessmentService.fetchAtleteNutritional(this.id).subscribe((response: any) => {
        if (response && response.length > 0) {
          console.log(response, 'data');
          const data = response[0];
          this.product = response[0];
          this.nutrition.naId = data.naId;
          this.nutrition.naDate = data.naDate ? data.naDate.split('T')[0] : '';
          this.nutrition.naAssessor = data.naAssessor;
          this.nutrition.naAthlete = data.naAthlete;
          this.nutrition.naAssessorName = data.assessorFullName;
          this.nutrition.naAthleteName = data.athleteFullName;
          this.nutrition.naBodyWeight = data.naBodyWeight;
          this.nutrition.naBodyHeight = data.naBodyHeight;
          this.nutrition.naBMI = data.naBMI;
          this.nutrition.naBodyFatPct = data.naBodyFatPct;
          this.nutrition.naMuscleMass = data.naMuscleMass;
          this.nutrition.naHemoglobin = data.naHemoglobin;
          this.nutrition.naIronLevels = data.naIronLevels;
          this.nutrition.naVitaminDLevel = data.naVitaminDLevel;
          this.nutrition.naVitaminB12Level = data.naVitaminB12Level;
          this.nutrition.OtherNutrientLevels = data.OtherNutrientLevels;
          this.nutrition.naCurrentDiet = data.naCurrentDiet;
          this.nutrition.naNutriDeficiencies = data.naNutriDeficiencies;
          this.nutrition.naRecommendations = data.naRecommendations;
          this.nutrition.naFollowupDate = data.naFollowupDate ? data.naFollowupDate.split('T')[0] : '';
        }
      },
        (error: any) => {
          console.error('Error fetching Assessment data', error);
        }
      );
    }
  }

  getTests() {
    this.userRegisterService.getTests(this.teamId).subscribe(response => {
      this.Response = response;

     this.testNames = response.map((item: any) => item.testName);

      console.log(this.Response,"Response");
    });
  }
}
