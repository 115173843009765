<div *ngIf="isLoading" class="loading-spinner">
    <div class="spinner"></div>
</div>


<div class="body-wrapper">
    <div class="container emp-profile">
        <div class="row">
            <div class="profile-head">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link  active" id="home-tab" data-bs-toggle="tab" href="#home" role="tab">Profile
                            Data</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link " id="security-tab" data-bs-toggle="tab" href="#security"
                            role="tab">Security</a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="tab-content profile-tab" id="myTabContent">
            <div class="tab-pane fade show active" id="home" role="tabpanel">
                <div class="container">
                    <div class="row flex-lg-nowrap">
                        <div class="col">
                            <div class="row">
                                <div class="col mb-3">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="e-profile">
                                                <div class="row">
                                                    <div class="col-12 col-sm-auto mb-3">
                                                        <div class="mx-auto" style="width: 140px;">
                                                            <div class="d-flex justify-content-center align-items-center"
                                                                style="width: 140px; height: 140px; background-color: rgb(255, 255, 255);">
                                                                <img [src]="profileImageUrl"
                                                                    style="width: 100%; height: 100%; object-fit: cover; padding: 5px;">

                                                            </div>
                                                        </div>


                                                    </div>
                                                    <div
                                                        class="col d-flex flex-column flex-sm-row justify-content-between mb-3">
                                                        <div class="text-sm-left mb-2 mb-sm-0">
                                                            <h4 class="pt-sm-2 pb-1 mb-0 text-nowrap Title">
                                                                {{Response[0].usrFullName}}</h4>
                                                            <p class="mb-0">{{Response[0].usrEmail}}</p>

                                                            <div class="mt-2">
                                                                <button class="btn btn-edit" type="button"
                                                                    (click)="openImageModal()">
                                                                    <i class='bx bxs-edit-alt'></i>
                                                                    <span>Edit</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div class="text-end text-sm-right">
                                                            <span>Date of Birth </span>
                                                            <div class="text-muted">
                                                                <small>{{trainerDetail.usrDoB}}</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <form class="form" novalidate (ngSubmit)="onSubmit()">
                                                    <div class="row">
                                                        <div class="col">
                                                            <div class="row mb-3">
                                                                <div class="col">
                                                                    <div class="form-group">
                                                                        <label for="usrFullName">Full Name</label>
                                                                        <input class="form-control" id="usrFullName"
                                                                            type="text"
                                                                            [(ngModel)]="trainerDetail.usrFullName"
                                                                            name="usrFullName" required (keypress)="validateAlphabet($event)">
                                                                    </div>
                                                                </div>
                                                                <div class="col">
                                                                    <!-- <div class="form-group">
                                                                        <label for="usrDoB">Date of Birth</label>
                                                                        <input class="form-control" id="usrDoB" type="date" [(ngModel)]="trainerDetail.usrDoB" name="usrDoB">
                                                                      </div> -->
                                                                    <div class="col">
                                                                        <div class="form-group">
                                                                            <label for="usrPhoneNo">Phone Number</label>
                                                                            <input class="form-control" id="usrPhoneNo"
                                                                                type="text" pattern="^[1-9][0-9]{9}$"
                                                                                maxlength="10"
                                                                                [(ngModel)]="trainerDetail.usrPhoneNo"
                                                                                name="usrPhoneNo" required
                                                                                (keypress)="validatePhoneNumber($event)"
                                                                                #phoneInput="ngModel">

                                                                            <!-- Validation Error Message -->
                                                                            <div
                                                                                *ngIf="phoneInput.invalid && phoneInput.touched">
                                                                                <small class="text-danger">Phone number
                                                                                    must be 10 digits .</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                            <div class="row mb-3">
                                                                <div class="col mb-3">
                                                                    <div class="form-group">
                                                                        <label for="usrAddress">Address</label>
                                                                        <textarea class="form-control" id="usrAddress"
                                                                            rows="5"
                                                                            [(ngModel)]="trainerDetail.usrAddress"
                                                                            name="usrAddress"></textarea>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col d-flex justify-content-end">
                                                            <button class="btn btn-primary" type="submit">Update
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="security" role="tabpanel">
                <h5 class="mb-0 Title">Change Password</h5>
                <p>These settings are helps you keep your account secure.</p>
                <div class="container mt-4">
                    <div class="row flex-lg-nowrap">
                        <div class="col">
                            <div class="row">
                                <div class="col mb-3">
                                    <div class="e-profile">
                                        <form class="form" novalidate="" (ngSubmit)="onSave()">

                                            <div class="row">
                                                <div class="col-12 col-sm-6 mb-3 p-0">
                                                    <div class="row mb-3">
                                                        <div class="col">
                                                            <div class="form-group">
                                                                <label class="mb-2">Current Password</label>
                                                                <input class="form-control" type="password"
                                                                    name="oldPassword" [(ngModel)]="oldPassword"
                                                                    placeholder="">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row mb-3">
                                                        <div class="col">
                                                            <div class="form-group">
                                                                <label class="mb-2">New Password</label>
                                                                <input class="form-control" type="password"
                                                                    name="newPassword" [(ngModel)]="newPassword"
                                                                    placeholder="">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row mb-3">
                                                        <div class="col">
                                                            <div class="form-group">
                                                                <label class="mb-2">Confirm Password</label>
                                                                <input class="form-control" type="password"
                                                                    name="confirmPassword" [(ngModel)]="confirmPassword"
                                                                    placeholder="">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button class="btn btn-primary">Save</button>
                                                </div>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-single-imageupload #fileImageUploadModal>

</app-single-imageupload>

<div class="toaster">
    <ng-toast />
</div>