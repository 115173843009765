<div class="container" [ngClass]="{'secActive': formSubmitted}">
    <div class="container1">
    <header>Biomechanics</header><br>
    <form (ngSubmit)="onSubmit()" #bioMechanicalform="ngForm">
    <div class="form first">
    <div class="details personal">
    <div class="fields">
    <div class="input-field">
    <label>Player Name</label>
    <input type="text" readonly placeholder="Enter Player Name" required [(ngModel)]="assessment.bmaAthleteName" name="playerName"
                    list="athleteList" (input)="fetchAthletes($event)" (change)="onAthleteSelected($event)" 
                    #playerName="ngModel" 
                    [ngClass]="{'invalid-field': bioMechanicalform.submitted && playerName.invalid, 'has-value': assessment.bmaAthleteName}">
    <datalist id="athleteList">
    <option *ngFor="let athlete of athletes" [value]="athlete.usrFullName"></option>
    </datalist>
    <div *ngIf="bioMechanicalform.submitted && playerName.invalid" class="error-message">
    <!-- Player Name error message -->
    </div>
    </div>
    <div class="input-field">
    <label>Assessor Name</label>
    <input type="text" readonly placeholder="Enter Assessor Name" required [(ngModel)]="assessment.bmaAssessorName"
                    name="bmaAssessorname"
                    #bmaAssessorname="ngModel"
                    [ngClass]="{'invalid-field': bioMechanicalform.submitted && bmaAssessorname.invalid, 'has-value': assessment.bmaAssessorname}">
    <div *ngIf="bioMechanicalform.submitted && bmaAssessorname.invalid" class="error-message">
    <!-- Assessor Name error message -->
    </div>
    </div>
    </div>
    <div class="fields">
    <div class="input-field">
    <label>Date of Assessment</label>
    <input type="date" placeholder="Enter date of assessment" required [(ngModel)]="assessment.bmaDate" name="bmaDate"
                    #bmaDate="ngModel"
                    [ngClass]="{'invalid-field': bioMechanicalform.submitted && bmaDate.invalid, 'has-value': assessment.bmaDate}">
    <div *ngIf="bioMechanicalform.submitted && bmaDate.invalid" class="error-message">
    <!-- Date of Assessment error message -->
    </div>
    </div>
    </div>
     
              <div class="details ID">
    <span class="title">Test Result</span>
    <div class="fields">
    <div class="input-field">
    <label>Force OutPut</label>
    <input type="number" placeholder="Enter Force OutPut" required [(ngModel)]="assessment.bmaForceOutput" name="bmaForceOutput"
                      #bmaForceOutput="ngModel"
                      [ngClass]="{'invalid-field': bioMechanicalform.submitted && bmaForceOutput.invalid, 'has-value': assessment.bmaForceOutput}">
    <div *ngIf="bioMechanicalform.submitted && bmaForceOutput.invalid" class="error-message">
    <!-- Force OutPut error message -->
    </div>
    </div>
    <div class="input-field">
    <label>Power OutPut</label>
    <input type="number" placeholder="Enter Power OutPut" required [(ngModel)]="assessment.bmaPowerOutput" name="bmaPowerOutput"
                      #bmaPowerOutput="ngModel"
                      [ngClass]="{'invalid-field': bioMechanicalform.submitted && bmaPowerOutput.invalid, 'has-value': assessment.bmaPowerOutput}">
    <div *ngIf="bioMechanicalform.submitted && bmaPowerOutput.invalid" class="error-message">
    <!-- Power OutPut error message -->
    </div>
    </div>
    <div class="input-field">
    <label>Symmetry</label>
    <input type="number" placeholder="Enter Symmetry" required [(ngModel)]="assessment.bmaSymmetry" name="bmaSymmetry"
                      #bmaSymmetry="ngModel"
                      [ngClass]="{'invalid-field': bioMechanicalform.submitted && bmaSymmetry.invalid, 'has-value': assessment.bmaSymmetry}">
    <div *ngIf="bioMechanicalform.submitted && bmaSymmetry.invalid" class="error-message">
    <!-- Symmetry error message -->
    </div>
    </div>
    <div class="input-field">
    <label>Imbalance</label>
    <input type="number" placeholder="Enter Imbalance" required [(ngModel)]="assessment.bmaImbalance" name="bmaImbalance"
                      #bmaImbalance="ngModel"
                      [ngClass]="{'invalid-field': bioMechanicalform.submitted && bmaImbalance.invalid, 'has-value': assessment.bmaImbalance}">
    <div *ngIf="bioMechanicalform.submitted && bmaImbalance.invalid" class="error-message">
    <!-- Imbalance error message -->
    </div>
    </div>
    </div>
    </div>
     
              <div class="fields">
    <div class="input-field">
    <label>Interpretation of Result</label>
    <input type="text" placeholder="Enter Interpretation of Result" required [(ngModel)]="assessment.bmaInterpreationResults" name="bmaInterpreationResults"
                    #bmaInterpreationResults="ngModel"
                    [ngClass]="{'invalid-field': bioMechanicalform.submitted && bmaInterpreationResults.invalid, 'has-value': assessment.bmaInterpreationResults}">
    <div *ngIf="bioMechanicalform.submitted && bmaInterpreationResults.invalid" class="error-message">
    <!-- Interpretation of Result error message -->
    </div>
    </div>
    <div class="input-field">
    <label>Recommendation</label>
    <input type="text" placeholder="Enter Recommendation" required [(ngModel)]="assessment.bmaRecommendations" name="bmaRecommendations"
                    #bmaRecommendations="ngModel"
                    [ngClass]="{'invalid-field': bioMechanicalform.submitted && bmaRecommendations.invalid, 'has-value': assessment.bmaRecommendations}">
    <div *ngIf="bioMechanicalform.submitted && bmaRecommendations.invalid" class="error-message">
    <!-- Recommendation error message -->
    </div>
    </div>
    <div class="input-field">
    <label>Follow-up Date</label>
    <input type="date" placeholder="Enter Follow-up Date" required [(ngModel)]="assessment.bmaFollowupDate" name="bmaFollowupDate"
                    #bmaFollowupDate="ngModel"
                    [ngClass]="{'invalid-field': bioMechanicalform.submitted && bmaFollowupDate.invalid, 'has-value': assessment.bmaFollowupDate}">
    <div *ngIf="bioMechanicalform.submitted && bmaFollowupDate.invalid" class="error-message">
    <!-- Follow-up Date error message -->
    </div>
    </div>
    </div>
    </div>
    <div class="main">
    <button type="submit">Submit</button>
    </div>
    </div>
    </form>
    </div>
    </div>
    <div class="toaster">
 
        <ng-toast />
         
        </div>