<div class="container" [ngClass]="{'secActive': formSubmitted}">
    <header>Video Analysis</header><br>
    <form (ngSubmit)="onSubmit()" #videoForm="ngForm">
      <div class="form first">
        <div class="details personal">
          <div class="fields">
            <div class="input-field">
              <label>Uploader's Name</label>
              <input type="text" placeholder="Enter Uploader's Name" required [(ngModel)]="assessment.vuUploader" name="uploaderName">
            </div>
            <div class="input-field">
              <label>Date of Upload</label>
              <input type="date" placeholder="Enter Date of Upload" required [(ngModel)]="assessment.vuCreatedOn" name="dateOfUpload">
            </div>
          
            <div class="input-field">
              <label>Video Title</label>
              <input type="text" placeholder="Enter Video Title" required [(ngModel)]="assessment.vuTitle" name="videoTitle">
            </div>
            <div class="input-field">
              <label>Date of Recording</label>
              <input type="date" placeholder="Enter Date of Recording" required [(ngModel)]="assessment.vuDate" name="dateOfRecording">
            </div>
            <div class="input-field">
              <label>Sport/Activity</label>
              <input type="text" placeholder="Enter Sport/Activity" required [(ngModel)]="assessment.vuSportActivity" name="sportActivity">
            </div>
          </div>
          <div class="details">
            <span class="title">Key Focus Points</span>
            <div class="fields">
              <div class="input-field">
                <label>Technique Analysis</label>
                <input type="text" placeholder="Enter Technique Analysis" required [(ngModel)]="assessment.vuTechniqueAnalysis" name="techniqueAnalysis">
              </div>
              <div class="input-field">
                <label>Performance Analysis</label>
                <input type="text" placeholder="Enter Performance Analysis" required [(ngModel)]="assessment.vuPerformanceAnalysis" name="performanceAnalysis">
              </div>
            </div>
          </div>
        </div>
        <div class="buttons">
          <button type="submit" class="submit">
            <span class="btnText">Submit</span>
            <i class="uil uil-navigator"></i>
          </button>
        </div>
      </div>
    </form>
  </div>
  
  <div class="toaster">
 
    <ng-toast />
     
    </div>
  