import { CommonModule } from '@angular/common';
import { Component, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { Router, RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { AssessmentService } from '../../../services/assessment.service';
import { UserService } from '../../../services/user.service';
import { User } from '../../../model/User';
import { PrivilegeService } from '../../../services/privilage.service';
import { Privilege } from '../../../model/Privillage';
import { PrivilegeEnum } from '../../../model/PrivilageEnum';
import { SharedService } from '../../../services/shared.service';
import { FileuploadComponent } from '../../fileupload/fileupload.component';

interface IRow {
  playername: string;
  pfaId:string;
  pfaAthlete:string;
  Assessor: string;
  Speed: string;
  Strength: string;
  ReactionTime: string;
  priority: string;
  date: string;
}

@Component({
  selector: 'app-physicalfitness-list',
  standalone: true,
  imports: [CommonModule,RouterOutlet, RouterLink, RouterModule, AgGridAngular,FileuploadComponent],
  templateUrl: './physicalfitness-list.component.html',
  styleUrls: ['./physicalfitness-list.component.css']
})
export class PhysicalfitnessListComponent implements OnInit {
  @ViewChild('fileUploadModal') fileUploadModal: FileuploadComponent | undefined;
  @Input() name: any;

  user: User | null = null;

  themeClass = 'ag-theme-quartz';

  rowData: IRow[] = [];

  colDefs: ColDef[] = [];

  ramPrivilege: PrivilegeEnum | undefined;

  defaultColDef: ColDef = {
    flex: 1,
    sortable: true,
    filter: true,
  };
  privilege: Privilege;
  Isplayer: boolean;
  Admin: boolean;
  OrgAdmin: boolean = false;
  detail: any;
  org: any;
  Coach: boolean = false;
  usrId: any;

  constructor(private assessmentService: AssessmentService, private router: Router, private sharedService: SharedService,
    private ngZone: NgZone,private userService:UserService,private priService:PrivilegeService) {
    this.privilege = this.priService.getPrivileges();
    this.Isplayer = this.privilege.isPlayer;
    this.Admin = this.privilege.isAdmin;
    this.OrgAdmin = this.privilege.isOrgAdmin;
    this.Coach = this.privilege?.isCoach ?? false;
    }

  ngOnInit() {
    this.sharedService.detail$.subscribe((data: any) => {
      this.detail = data;
      this.org=this.detail.user.usrOrganization;
      this.usrId=this.detail.user.usrId;
      console.log(this.org,"org")
    });
    this.user = this.userService.getUser();
    this.ramPrivilege = this.userService.getRamPrivilege();
    console.log(this.ramPrivilege,"Datapri");
    if(this.Coach){
      this.assessmentService.getfitnessByCoachId(this.usrId).subscribe(response =>{
        console.log('Assessment submitted', response);

        this.rowData = response.map((item:any) => ({
          playername: `${item.athleteName}`, 
          pfaId:`${item.pfaId}`, 
          pfaAthlete:`${item.pfaAthlete}`, 
          Id:`${item.pfaAthlete}`, 
          Height: ` ${item.pfaHeight}`, 
          Weight: `${item.pfaWeight}`, 
          SingleLegBalanceEyesOpen: `${item.pfaSingleLegBalanceEyesOpen}`, 
          SingleLegBalanceEyesClose: `${item.pfaSingleLegBalanceEyesClose}`, 
          date: new Date(item.pfaDate).toLocaleDateString(),
          //priority: this.getPriority(item)
        }));
  
        this.colDefs = this.getColumnDefinitions();
      }, error => {
        console.error('Error submitting assessment', error);
      })
    }else{
   
    this.assessmentService.getPhysicalFitnessDetail(this.org).subscribe(response => {
      console.log('Assessment submitted', response);

      this.rowData = response.map((item:any) => ({
        playername: `${item.athleteName}`, 
        pfaId:`${item.pfaId}`, 
        pfaAthlete:`${item.pfaAthlete}`, 
        Id:`${item.pfaAthlete}`, 
        Height: ` ${item.pfaHeight}`, 
        Weight: `${item.pfaWeight}`, 
        SingleLegBalanceEyesOpen: `${item.pfaSingleLegBalanceEyesOpen}`, 
        SingleLegBalanceEyesClose: `${item.pfaSingleLegBalanceEyesClose}`, 
        date: new Date(item.pfaDate).toLocaleDateString(),
      }));

      this.colDefs = this.getColumnDefinitions();
    }, error => {
      console.error('Error submitting assessment', error);
    });
  }
  }


  getColumnDefinitions(): ColDef[] {
    const columns: ColDef[] = [
      { field: 'playername' },
      { field: 'Id' },
      { field: 'Height' },
      { field: 'Weight' },
      { field: 'SingleLegBalanceEyesOpen' },
      { field: 'SingleLegBalanceEyesClose' },
      { field: 'date' },
      {
        headerName: 'Action',
        cellRenderer: (_params: any) => {
          const container = document.createElement('div');
          container.style.display = 'flex';
          container.style.justifyContent = 'space-around';
  
          // View button with eye icon
          const viewButton = document.createElement('button');
          viewButton.classList.add('btn');
          viewButton.innerHTML = '<i class="fa fa-eye"></i>';
          viewButton.addEventListener('click', () => {
              this.ngZone.run(() => {
              this.router.navigate(['/physicalfitnessdetaillist'], {queryParams: { id: _params.data.pfaAthlete } })
              })
          });
  
          // Edit button with edit icon
          
          const editButton = document.createElement('button');
          editButton.classList.add('btn');
          editButton.innerHTML = '<i class="fa fa-edit"></i>';
          editButton.addEventListener('click', () => {
            this.ngZone.run(() => {
            this.router.navigate(['/physicalfitness'], {queryParams: { id: _params.data.pfaId } })
            })
          });
  
      
  
          container.appendChild(viewButton);
          if (!this.Isplayer && !this.OrgAdmin) {
          container.appendChild(editButton);
          }

  
          return container;
        },
        width: 120
      }
    ];
  
    if (this.Admin || this.OrgAdmin) {
      return columns;
    } else if (this.Isplayer) {
      return columns.filter(col => col.field !== 'playername');
    } else {
      return columns.filter(col => col.field !== 'Assessor' && col.field !== 'playername');
    }
  }

  openModal(): void {
    // Use Bootstrap's modal function to open the modal
    const modalElement = document.getElementById('fileUploadModal')!;
    const modal = new (window as any).bootstrap.Modal(modalElement);
    modal.show();
  }
}
