import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {  Router, RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { ColDef, GridOptions } from 'ag-grid-community';
import { AssessmentService } from '../../../services/assessment.service';
import { SharedService } from '../../../services/shared.service';
import { NotificationService } from '../../../services/Notifications.service';
import { AgGridAngular } from 'ag-grid-angular';
import { FormsModule } from '@angular/forms';
import { NgToastModule } from 'ng-angular-popup';
import { NgSelectModule } from '@ng-select/ng-select';
import { Location } from '@angular/common';
interface IRow {
  UserId: string;
  AthleteName: string;
  CoachName: string;
  TeamName: string;
  AthleteEmail: string;
  PhoneNumber: string
}
@Component({
  selector: 'app-assign-team',
  standalone: true,
  imports: [CommonModule, RouterOutlet, AgGridAngular, RouterLink, RouterModule, FormsModule, NgToastModule, NgSelectModule],
  templateUrl: './assign-team.component.html',
  styleUrl: './assign-team.component.css'
})
export class AssignTeamComponent implements OnInit {
  detail: any;
  org: any;
  orgTeams: any[] = [];
  athletes: any[] = [];
  Trainer: any[] = [];

  allTrainers: any[] = [];
  allTeams: any[] = [];
  showCheckboxes: boolean = false;
  selectedTeam: any= null;
  selectedPlayer: any;
  selectedCoach: string = '';
  selectedOrg: string = '';
  organization: any;
  colDefs: ColDef[] = [];
  rowData: IRow[] = [];
  teamName: any;
  Organisation: boolean = false;
  gridOptions: GridOptions = {
    rowSelection: 'multiple', // Enable multi-row selection
    suppressRowClickSelection: true,
  };
  users: any;
  defaultColDef: ColDef = {
    flex: 1,
    sortable: true,
    filter: true,
  };

  organizations: any[] = [];
  isEditMode: boolean = false;
  selectedOrgId: number | null = null;
  SelectOrg: boolean = false;
  trainerName: any;
  selectedTeamId: number | null = null;
  selectedOrgFilter: any;
  filteredTeams: any;
  fiterOrg: boolean = false;
  gridApi: any;
  selectedImageUrl: any;
  coachData: any;

  constructor(private assessmentService: AssessmentService, private router: Router, private notify: NotificationService,
    private sharedService: SharedService, private location:Location) {
  }

  ngOnInit() {
    this.sharedService.detail$.subscribe((data: any) => {
      this.detail = data;
      this.org = this.detail.user.usrOrganization;
    });    
    this.loadOrgainsation();
    this.loadCoachAndTeam();
  }

  isFormValid(): boolean {
    return this.selectedOrg && this.selectedTeam && this.selectedCoach;
  }

  loadCoachAndTeam() {
    this.assessmentService.getOrgTrainer("6").subscribe(
      (data) => {
        this.allTrainers = data; // Save original data        
      },
      (error) => {
        console.error('Error fetching coaches:', error);
      }
    );

    this.assessmentService.getTeams().subscribe(
      (data) => {
        this.allTeams = data; // Save original data
      },
      (error) => {
        console.error('Error fetching teams:', error);
      }
    );


  }

  loadOrgainsation() {
    this.assessmentService.getOrgainsation().subscribe(
      (data) => {
        this.organization = data;
      },
      (error) => {
        console.error('Error fetching coaches:', error);
      }
    );
  }

  isPlayerDropdownEnabled(): boolean {
    return this.selectedOrg && this.selectedCoach && this.selectedTeam;
  }

  updateSelection() {
    if (!this.isPlayerDropdownEnabled()) {
        this.notify.failed('Please select an Organization, Coach, and Team before selecting Players.');
        return; // Exit the method if validation fails
    }

    // Collect the selected team and coach values
    const selectedTeam = this.selectedTeam;
    const selectedCoach = this.selectedCoach;
    const athleteIds = this.selectedPlayer.map((user: any) => user.UserId);

    // Prepare the data object to be passed
    const updateData = {
        TeamId: selectedTeam,
        CoachId: selectedCoach,
        AthleteIds: athleteIds // This contains the data of the selected rows
    };

    // Now, pass this data to a service or handle it as needed
    this.assessmentService.updateUserSelection(updateData).subscribe(
        response => {
            console.log(response, "response on update");
            this.notify.success(response.message);
            // Reload the row data after the successful update
            this.reloadRowData();
        },
        error => {
            this.notify.failed('Please select the athletes');
            console.error('Error updating selection:', error);
        }
    );
}

reloadRowData() {
    // Fetch athletes based on the selected organization, team, and coach
    this.assessmentService.getAthleteByOrg(this.selectedOrg).subscribe(
        (athletesData) => {
            // Update the athletes list with the new data
            this.athletes = athletesData;

            // Prepare row data for the grid
            this.rowData = this.athletes.map((athlete: any) => ({
                UserId: `${athlete.usrId}`,
                AthleteName: `${athlete.athleteName}`,
                AthleteEmail: `${athlete.usrEmail}`,
                PhoneNumber: `${athlete.usrPhoneNo}`,
                CoachName: `${athlete.coachName}`,
                TeamName: `${athlete.teamName}`
            }));
            this.getTeamPlayers()
            // Update the grid column definitions if necessary
            this.colDefs = this.getColumnDefinitions();
            if (this.gridApi) {
                this.gridApi.setRowData(this.rowData); // Update grid with new row data
            }
        },
        (error) => {
            console.error('Error fetching athletes:', error);
        }
    );
}

  onSelectTeam() {
    if (!this.selectedTeam) {
      // If no team is selected, clear all selections
      this.clearSelection();  // This method will deselect all checkboxes
      return;
    }
    
    // Reload the organization users based on the selected team
    this.getTeamPlayers();
    this.colDefs = this.getColumnDefinitions();
  }

  onSelectPlayers() {

  }

  onSelectOrg() {    
    // Clear previous selections and state
    this.resetSelections();
    
    // Filter Trainer and Teams based on selected organization
    const selectedOrgId = Number(this.selectedOrg);
    this.Trainer = this.allTrainers.filter(trainer => trainer.usrOrganization === selectedOrgId);
    this.orgTeams = this.allTeams.filter(team => team.teamOrganization === selectedOrgId);
    
    // Fetch athletes based on the selected organization
    this.assessmentService.getAthleteByOrg(this.selectedOrg).subscribe(
      (athletesData) => {
        // Update the athletes list with the new data
        this.athletes = athletesData;
  
        // Prepare row data for the grid
        this.rowData = this.athletes.map((athlete: any) => ({
          UserId: `${athlete.usrId}`,
          AthleteName: `${athlete.athleteName}`,
          AthleteEmail: `${athlete.usrEmail}`,
          PhoneNumber: `${athlete.usrPhoneNo}`,
          CoachName: `${athlete.coachName}`,
          TeamName: `${athlete.teamName}`
        }));
  
        // Update the grid column definitions and flag to show checkboxes
        this.Organisation = true;
        this.colDefs = this.getColumnDefinitions();
      },
      (error) => {
        console.error('Error fetching athletes:', error);
      }
    );
  }
  
  // Helper function to reset selections and clear UI state
  resetSelections() {
    this.showCheckboxes = true; // Enable checkboxes
    this.selectedCoach = ''; // Clear selected coach
    this.selectedTeam = null; // Clear selected team
    this.selectedPlayer = []; // Clear selected players
  }
  


  getTeamPlayers() {
    this.assessmentService.getPlayerByTeam(this.selectedTeam).subscribe(
      (response) => {
        // Clear previous selections
        this.clearSelection();
  
        const playerData = response.map((player: any) => player.usrId.toString());
  
        // Reorder grid data to place selected players at the top
        this.rowData = this.rowData.map((row: any) => ({
          ...row,
          isSelected: playerData.includes(row.UserId.toString())
        })).sort((a, b) => (a.isSelected === b.isSelected) ? 0 : a.isSelected ? -1 : 1);
  
        // Set the reordered data to the grid
        this.gridApi.setRowData(this.rowData);
  
        // Iterate through each node and select if UserId matches
        setTimeout(() => {
          this.gridApi.forEachNode((node: any) => {
            if (node.data.isSelected) {
              node.setSelected(true); // Select the node
            }
          });
        }, 0);
  
        // Update column definitions to display checkboxes
        this.colDefs = this.getColumnDefinitions();
      },
      (error) => {
        this.clearSelection();
        console.error('Error fetching players:', error);
      }
    );
  }
  

  updateSelectedPlayers() {
    // Get all selected nodes (rows) from the grid
    const selectedNodes = this.gridApi.getSelectedNodes();

    // Map selected rows to player data
    this.selectedPlayer = selectedNodes.map((node: any) => node.data);
  }

  // Grid's onGridReady event handler to get gridApi reference
  onGridReady(params: any) {
    this.gridApi = params.api;

    // Listen to row selection events and update selectedPlayer array
    this.gridApi.addEventListener('rowSelected', (event: any) => {
      this.updateSelectedPlayers();
    });
  }


  clearSelection() {
    this.selectedPlayer = [];
    if (this.gridApi) {
      this.gridApi.deselectAll();  // Deselect all rows in the grid
    }
  }

  isSelected(item: any): boolean {
    return this.selectedPlayer && this.selectedPlayer.includes(item);
  }

  getColumnDefinitions(): ColDef[] {
    const columns: ColDef[] = [];

    if (this.showCheckboxes) {
      // Add the checkbox column if showCheckboxes is true
      columns.push({
        headerCheckboxSelection: true,  // Enable header checkbox selection
        checkboxSelection: true,        // Enable row selection with checkbox
        width: 80,                      // Set the desired width for the checkbox column
        minWidth: 80,                   // Set the minimum width to prevent expansion
        maxWidth: 80,                   // Set the maximum width to prevent resizing
      });
    }

    columns.push(
      { field: "AthleteName", headerName: "Athlete Name" },
      { field: "AthleteEmail", headerName: "Athlete Email" },
      { field: "PhoneNumber", headerName: "Phone Number" },
      { field: "TeamName", headerName: "Team Name" },
      { field: "CoachName", headerName: "Coach Name" }
    );

    return columns;
  }
  onBack():void{
    this.location.back();
  }
}