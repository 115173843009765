<div class="body-wrapper">
    <div class="team-single">
      <div class="d-flex justify-content-between mb-3 gap-4">
        <div>
          <button class="btn btn-primary" (click)="onBack()">Back</button>
      </div>
      <div>
        <button type="button" class="accept"(click)="downloadPDF()">Download</button>  
      </div>
      </div>
      <div class="contact-info-section margin-40px-tb">
        <div id="pdf-content">
          <div class="row">
            <div class="col-lg-4 col-md-5 xs-margin-30px-bottom">
                <div class="col-lg-12">
                    <div class="card mb-4">
                      <div class="content-header">
                        <h2 class="sub-title">Injury prevention analysis</h2>
                      </div>
                      <div class="content-body">
                        <!-- Sports Activity -->
                        <div class="mb-4" *ngFor="let activity of activities">
                          <div class="clearfix">
                            <div class="d-flex justify-content-between align-items-center mb-3">
                              <p class="small-text">{{ activity.name }}</p>
                              <strong>{{ activity.percentage }}%</strong>
                            </div>
                          </div>
                          <div class="progress progress-xs">
                            <div class="progress-bar"
                                 [ngClass]="getProgressColor(activity.percentage)"
                                 role="progressbar"
                                 [style.width]="activity.percentage + '%'"
                                 [attr.aria-valuenow]="activity.percentage"
                                 aria-valuemin="0"
                                 aria-valuemax="100">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                 
              <div class="col-lg-12">
                <div class="bg-light-blue">
                  <h4 class="side-Title">{{athleteFullName}}</h4>
                  <p class="side-content">Athlete</p>
                </div>
              </div>
            </div>
            <div class="col-lg-8 card col-md-7">
              <div class="card-body">
                <div class="team-single-text padding-50px-left sm-no-padding-left">
                  <div class="mobile-res d-flex justify-content-between mb-4">
                    <div>
                      <h4 class="Title">Athlete Details</h4>
                    </div>
                    <!-- <div>
                      <p>Test Taken Date:</p>
                      <h4 class="sub-title">{{ date | date: 'dd-MM-yyyy' }}</h4>
                    </div> -->
                    <div>
                      <p>Assessment Date:</p>
                      <h4 class="sub-title">{{ assessmentDate | date: 'dd-MM-yyyy' }}</h4>
                    </div>
                  </div>
                  <div class="contact-info-section margin-40px-tb">
                    <ul class="list-style9 no-margin">
                      <li *ngIf="athleteId">
                        <div class="row">
                          <div class="col-md-5 col-5">
                            <strong class="margin-10px-left">Athlete ID:</strong>
                          </div>
                          <div class="col-md-7 col-7">
                            <p>{{athleteId}}</p>
                          </div>
                        </div>
                      </li>
                      <li *ngIf="ipaDeepSquat">
                        <div class="row">
                          <div class="col-md-5 col-5">
                            <strong class="margin-10px-left">Deep Squat :</strong>
                          </div>
                          <div class="col-md-7 col-7">
                            <p>{{ipaDeepSquat}}</p>
                          </div>
                        </div>
                      </li>
                      <li *ngIf="ipaInlineLunge">
                        <div class="row">
                          <div class="col-md-5 col-5">
                            <strong class="margin-10px-left">Inline Lunge :</strong>
                          </div>
                          <div class="col-md-7 col-7">
                            <p>{{ipaInlineLunge}}</p>
                          </div>
                        </div>
                      </li>
                      <li *ngIf="ipaHurdleStep">
                        <div class="row">
                          <div class="col-md-5 col-5">
                            <strong class="margin-10px-left">Hurdle Step:</strong>
                          </div>
                          <div class="col-md-7 col-7">
                            <p>{{ipaHurdleStep}}</p>
                          </div>
                        </div>
                      </li>
                      <li *ngIf="ipaActiveSLR">
                        <div class="row">
                          <div class="col-md-5 col-5">
                            <strong class="margin-10px-left">Active SLR:</strong>
                          </div>
                          <div class="col-md-7 col-7">
                            <p>{{ipaActiveSLR}}</p>
                          </div>
                        </div>
                      </li>
                      <li *ngIf="ipaShoulderMobility">
                        <div class="row">
                          <div class="col-md-5 col-5">
                            <strong class="margin-10px-left">Shoulder Mobility:</strong>
                          </div>
                          <div class="col-md-7 col-7">
                            <p>{{ipaShoulderMobility}}</p>
                          </div>
                        </div>
                      </li>
                      <li *ngIf="ipaPushUp">
                        <div class="row">
                          <div class="col-md-5 col-5">
                            <strong class="margin-10px-left">Push Up:</strong>
                          </div>
                          <div class="col-md-7 col-7">
                            <p>{{ipaPushUp}}</p>
                          </div>
                        </div>
                      </li>
                      <li *ngIf="ipaRotaryStability">
                        <div class="row">
                          <div class="col-md-5 col-5">
                            <strong class="margin-10px-left">Rotary Stability:</strong>
                          </div>
                          <div class="col-md-7 col-7">
                            <p>{{ipaRotaryStability}}</p>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> <!-- end of pdf-content -->
      </div> <!-- end of contact-info-section -->
    </div> <!-- end of team-single -->
  </div>