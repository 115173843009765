
<div class="container" [ngClass]="{'secActive': formSubmitted}">
    <div class="d-sm-flex justify-content-between align-items-start ipa">
      <header>Injury Prevention Analysis</header>
      
    </div><br>
<form (ngSubmit)="onSubmit()" #ipaForm="ngForm">
    <img *ngIf="showImage && ImageUrl"  src="{{this.ImageUrl.changingThisBreaksApplicationSecurity}}">

    <div class="fields">
      <div class="input-field">
        <label>Register Id<span style="color: red;">*</span></label>
        <input type="number" placeholder="Enter Id " required [disabled]="this.userid" name="name"
          [(ngModel)]="ipa.ipaAthlete" #loaAthlete="ngModel" (blur)="getProfileImage()" >
        
      </div>
      <!-- <div *ngIf="loading" class="loading-spinner">
        <div class="spinner"></div>
      </div> -->
     
      <div class="input-field">
        <label>Assessment Date <span style="color: red;">*</span></label>
        <input type="date" placeholder="Enter Assessment Date" required name="ipaAssessmentDate"
          [(ngModel)]="ipa.ipaAssessmentDate" #ipaAssessmentDate="ngModel">
        <div *ngIf="ipaForm.submitted && ipaAssessmentDate.invalid" class="error-message">
            
        </div>
      </div>
      <div class ="row">
        <div class="fields">
      <div class="input-field">
        <label>Deep Squat Score</label>
        <select name="ipaDeepSquat" [(ngModel)]="ipa.ipaDeepSquat" required>
            <option *ngFor="let option of scoreOptions" [value]="option.value">{{ option.label }}</option>
        </select>
    </div>

    <!-- Inline Lunge Score -->
    <div class="input-field">
        <label>Inline Lunge Score</label>
        <select name="ipaInlineLunge" [(ngModel)]="ipa.ipaInlineLunge" required>
            <option *ngFor="let option of scoreOptions" [value]="option.value">{{ option.label }}</option>
        </select>
    </div>

    <!-- Hurdle Step Score -->
    <div class="input-field">
        <label>Hurdle Step Score</label>
        <select name="ipaHurdleStep" [(ngModel)]="ipa.ipaHurdleStep" required>
            <option *ngFor="let option of scoreOptions" [value]="option.value">{{ option.label }}</option>
        </select>
    </div>
    </div>
    <div class="fields">
    <!-- Active SLR Score -->
    <div class="input-field">
        
        <label>Active SLR Score</label>
        <select name="ipaActiveSLR" [(ngModel)]="ipa.ipaActiveSLR" required>
            <option *ngFor="let option of scoreOptions" [value]="option.value">{{ option.label }}</option>
        </select>
    </div>

    <!-- Shoulder Mobility Score -->
    <div class="input-field">
        <label>Shoulder Mobility Score</label>
        <select name="ipaShoulderMobility" [(ngModel)]="ipa.ipaShoulderMobility" required>
            <option *ngFor="let option of scoreOptions" [value]="option.value">{{ option.label }}</option>
        </select>
    </div>

    <!-- Push Up Score -->
    <div class="input-field">
        <label>Push Up Score</label>
        <select name="ipaPushUp" [(ngModel)]="ipa.ipaPushUp" required>
            <option *ngFor="let option of scoreOptions" [value]="option.value">{{ option.label }}</option>
        </select>
    </div>
</div>
    <!-- Rotary Stability Score -->
    <div class="input-field">
        <label>Rotary Stability Score</label>
        <select name="ipaRotaryStability" [(ngModel)]="ipa.ipaRotaryStability" required>
            <option *ngFor="let option of scoreOptions" [value]="option.value">{{ option.label }}</option>
        </select>
    </div>
</div>
</div>
<button type="submit" class="submit-btn">Submit</button>
    
</form>


<div class="toaster">
    <ng-toast />        
          </div>