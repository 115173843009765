<div class="container" [ngClass]="{'secActive': formSubmitted}">
  <div class="container1">
    <header>Nutritional Assessment</header><br>
    <form (ngSubmit)="onSubmit()" #nutritionForm="ngForm">
      <div class="form first">
        <!-- Personal Details -->
        <div class="details personal">
          <div class="fields">
            <div class="input-field">
              <label>Player Name</label>
              <input type="text" style="background-color: lightgray;" readonly placeholder="Enter Player Name" required
                [(ngModel)]="nutrition.naAthleteName" name="playerName" list="athleteList"
                (input)="fetchAthletes($event)" (change)="onAthleteSelected($event)" #playerName="ngModel" [ngClass]="{
                  'invalid-field': nutritionForm.submitted && playerName.invalid,
                  'has-value': nutrition.naAthlete
                }">
              <datalist id="athleteList">
                <option *ngFor="let athlete of athletes" [value]="athlete.usrFullName"></option>
              </datalist>
              <div *ngIf="nutritionForm.submitted && playerName.invalid" class="error-message">
                <!-- Player Name error message -->
              </div>
            </div>
            <div class="input-field">
              <label>Date of Assessment</label>
              <input type="date" placeholder="Enter date of assessment" required [(ngModel)]="nutrition.naDate"
                name="dateOfAssessment" #dateOfAssessment="ngModel" [ngClass]="{
                  'invalid-field': nutritionForm.submitted && dateOfAssessment.invalid,
                  'has-value': nutrition.naDate
                }">
              <div *ngIf="nutritionForm.submitted && dateOfAssessment.invalid" class="error-message">
                <!-- Date of Assessment error message -->
              </div>
            </div>
            
          </div>
          <br>
        </div>

        <div class="details ID">
          <span class="title">Body Composition</span>
          <div class="fields">
            <div class="input-field">
            <div *ngFor="let testName of testNames" >
              <div *ngIf="testName === 'Weight'">
                  <label>Weight</label>
                  <input type="number" placeholder="Enter Weight" required [(ngModel)]="nutrition.naBodyWeight"
                    name="weight" #weight="ngModel" [ngClass]="{
                      'invalid-field': nutritionForm.submitted && weight.invalid,
                      'has-value': nutrition.naBodyWeight
                    }">
                  <div *ngIf="nutritionForm.submitted && weight.invalid" class="error-message">
                    <!-- Weight error message -->
                  </div>
                </div>
                <div *ngIf="testName === 'Height'">
                  <label>Height</label>
                  <input type="number" placeholder="Enter Height" required [(ngModel)]="nutrition.naBodyHeight"
                    name="height" #height="ngModel" [ngClass]="{
                      'invalid-field': nutritionForm.submitted && height.invalid,
                      'has-value': nutrition.naBodyHeight
                    }">
                  <div *ngIf="nutritionForm.submitted && height.invalid" class="error-message">
                    <!-- Height error message -->
                  </div>
                </div>
                <div *ngIf="testName === 'BMI'">
                  <label>BMI</label>
                  <input type="number" placeholder="Enter BMI" required [(ngModel)]="nutrition.naBMI" name="bmi"
                    #bmi="ngModel" [ngClass]="{
                      'invalid-field': nutritionForm.submitted && bmi.invalid,
                      'has-value': nutrition.naBMI
                    }">
                  <div *ngIf="nutritionForm.submitted && bmi.invalid" class="error-message">
                    <!-- BMI error message -->
                  </div>
                </div>
                <div *ngIf="testName === 'ABody Fat Percentage'">
                  <label>Body Fat Percentage</label>
                  <input type="number" placeholder="Enter Body Fat Percentage" required [(ngModel)]="nutrition.naBodyFatPct"
                    name="bodyFatPercentage" #bodyFatPercentage="ngModel" [ngClass]="{
                      'invalid-field': nutritionForm.submitted && bodyFatPercentage.invalid,
                      'has-value': nutrition.naBodyFatPct
                    }">
                  <div *ngIf="nutritionForm.submitted && bodyFatPercentage.invalid" class="error-message">
                    <!-- Body Fat Percentage error message -->
                  </div>
                </div>
                <div *ngIf="testName === 'Muscle Mass'">
                  <label>Muscle Mass</label>
                  <input type="number" placeholder="Enter Muscle Mass" required [(ngModel)]="nutrition.naMuscleMass"
                    name="muscleMass" #muscleMass="ngModel" [ngClass]="{
                      'invalid-field': nutritionForm.submitted && muscleMass.invalid,
                      'has-value': nutrition.naMuscleMass
                    }">
                  <div *ngIf="nutritionForm.submitted && muscleMass.invalid" class="error-message">
                    <!-- Muscle Mass error message -->
                  </div>
                </div>
                </div>
           </div>
          </div>
        </div>
        

        <!-- Blood Reports -->
         
        <div class="details ID">
          <span class="title">Blood Reports</span>
          <div class="fields">
            <div class="input-field">
              <label>Hemoglobin</label>
              <input type="number" placeholder="Enter Hemoglobin" required [(ngModel)]="nutrition.naHemoglobin"
                name="hemoglobin" #hemoglobin="ngModel" [ngClass]="{
                  'invalid-field': nutritionForm.submitted && hemoglobin.invalid,
                  'has-value': nutrition.naHemoglobin
                }">
              <div *ngIf="nutritionForm.submitted && hemoglobin.invalid" class="error-message">
                <!-- Hemoglobin error message -->
              </div>
            </div>
            <div class="input-field">
              <label>Iron Level</label>
              <input type="number" placeholder="Enter Iron Level" required [(ngModel)]="nutrition.naIronLevels"
                name="ironLevel" #ironLevel="ngModel" [ngClass]="{
                  'invalid-field': nutritionForm.submitted && ironLevel.invalid,
                  'has-value': nutrition.naIronLevels
                }">
              <div *ngIf="nutritionForm.submitted && ironLevel.invalid" class="error-message">
                <!-- Iron Level error message -->
              </div>
            </div>
            <div class="input-field">
              <label>Vitamin D Level</label>
              <input type="number" placeholder="Enter Vitamin D Level" required [(ngModel)]="nutrition.naVitaminDLevel"
                name="vitaminDLevel" #vitaminDLevel="ngModel" [ngClass]="{
                  'invalid-field': nutritionForm.submitted && vitaminDLevel.invalid,
                  'has-value': nutrition.naVitaminDLevel
                }">
              <div *ngIf="nutritionForm.submitted && vitaminDLevel.invalid" class="error-message">
                <!-- Vitamin D Level error message -->
              </div>
            </div>
            <div class="input-field">
              <label>Vitamin B12 Level</label>
              <input type="number" placeholder="Enter Vitamin B12 Level" required
                [(ngModel)]="nutrition.naVitaminB12Level" name="vitaminB12Level" #vitaminB12Level="ngModel" [ngClass]="{
                  'invalid-field': nutritionForm.submitted && vitaminB12Level.invalid,
                  'has-value': nutrition.naVitaminB12Level
                }">
              <div *ngIf="nutritionForm.submitted && vitaminB12Level.invalid" class="error-message">
                <!-- Vitamin B12 Level error message -->
              </div>
            </div>
            <div class="input-field">
              <label>Other Nutritional Levels</label>
              <input type="text" id="otherNutritionalLevels" placeholder="Enter Other Nutritional Levels" required
                [(ngModel)]="nutrition.OtherNutrientLevels" name="otherNutritionalLevels"
                #otherNutritionalLevels="ngModel" [ngClass]="{
                  'invalid-field': nutritionForm.submitted && otherNutritionalLevels.invalid,
                  'has-value': nutrition.OtherNutrientLevels
                }">
              <div *ngIf="nutritionForm.submitted && otherNutritionalLevels.invalid" class="error-message">
                <!-- Other Nutritional Levels error message -->
              </div>
            </div>
          </div>
        </div>

        <!-- Dietary Analysis -->
        <div class="details ID">
          <span class="title">Dietary Analysis</span>
          <div class="fields">
            <div class="input-field">
              <label>Current Diet</label>
              <input type="text" placeholder="Enter Current Diet" required [(ngModel)]="nutrition.naCurrentDiet"
                name="currentDiet" #currentDiet="ngModel" [ngClass]="{
                  'invalid-field': nutritionForm.submitted && currentDiet.invalid,
                  'has-value': nutrition.naCurrentDiet
                }">
              <div *ngIf="nutritionForm.submitted && currentDiet.invalid" class="error-message">
                <!-- Current Diet error message -->
              </div>
            </div>
            <div class="input-field">
              <label>Nutritional Deficiencies</label>
              <input type="text" placeholder="Enter Nutritional Deficiencies" required
                [(ngModel)]="nutrition.naNutriDeficiencies" name="nutritionalDeficiencies"
                #nutritionalDeficiencies="ngModel" [ngClass]="{
                  'invalid-field': nutritionForm.submitted && nutritionalDeficiencies.invalid,
                  'has-value': nutrition.naNutriDeficiencies
                }">
              <div *ngIf="nutritionForm.submitted && nutritionalDeficiencies.invalid" class="error-message">
                <!-- Nutritional Deficiencies error message -->
              </div>
            </div>
          </div>
        </div>

        <!-- Recommendations and Follow-up Date -->
        <div class="fields">
          <div class="input-field">
            <label>Recommendation</label>
            <textarea placeholder="Enter Recommendations" required [(ngModel)]="nutrition.naRecommendations"
              name="recommendations" #recommendations="ngModel" [ngClass]="{
                'invalid-field': nutritionForm.submitted && recommendations.invalid,
                'has-value': nutrition.naRecommendations
              }"></textarea>
            <div *ngIf="nutritionForm.submitted && recommendations.invalid" class="error-message">
              <!-- Recommendation error message -->
            </div>
          </div>
          <div class="input-field">
            <label>Follow-up Date</label>
            <input type="date" placeholder="Enter Follow-up Date" required [(ngModel)]="nutrition.naFollowupDate"
              name="followUpDate" #followUpDate="ngModel" [ngClass]="{
                'invalid-field': nutritionForm.submitted && followUpDate.invalid,
                'has-value': nutrition.naFollowupDate
              }">
            <div *ngIf="nutritionForm.submitted && followUpDate.invalid" class="error-message">
              <!-- Follow-up Date error message -->
            </div>
          </div>
        </div>

        <!-- Submit Button -->
        <div>
          <button type="submit" class="submit">Submit</button>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="toaster">

  <ng-toast />

</div>