import { AgGridAngular } from "ag-grid-angular";
import { ColDef } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { Component, Input, NgZone, OnInit, ViewChild } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Router, RouterLink, RouterModule, RouterOutlet } from "@angular/router";
import { AssessmentService } from "../../../services/assessment.service";
import { PrivilegeService } from "../../../services/privilage.service";
import { Privilege } from "../../../model/Privillage";
import { UserService } from "../../../services/user.service";
import { User } from "../../../model/User";
import { SharedService } from "../../../services/shared.service";
import { FileuploadComponent } from "../../fileupload/fileupload.component";

// Row Data Interface
interface IRow {
  playername: string;
  Assessor: string;
  date:string;
  priority: string;

}

@Component({
  selector: 'app-physiotherapy-list',
  standalone: true,
  imports: [CommonModule, RouterOutlet,AgGridAngular,RouterLink,RouterModule,FileuploadComponent],
  templateUrl: './physiotherapy-list.component.html',
  styleUrls: ['./physiotherapy-list.component.css']
})
export class PhysiotherapyListComponent implements OnInit {
  user: User | null = null;
  @ViewChild('fileUploadModal') fileUploadModal: FileuploadComponent | undefined;
  @Input() name: any;
  isAdmin: boolean = false;
  isPlayer: boolean = false;
  privilege:Privilege;
  Isplayer: boolean;
  Admin: boolean;
  detail: any;
  org: any;
  Coach: boolean=false;
  usrId: any;
  OrgAdmin: boolean =false;
 
  constructor(private userService:UserService,private router: Router, private sharedService: SharedService,private assessmentService: AssessmentService,private priService:PrivilegeService,private ngZone: NgZone) {
    this.privilege = this.priService.getPrivileges();
    this.Isplayer = this.privilege.isPlayer;
    this.Admin = this.privilege.isAdmin;
    this.Coach = this.privilege.isCoach;
    this.OrgAdmin=this.privilege.isOrgAdmin;
  }
 
  themeClass = "ag-theme-quartz";
 
  rowData: IRow[] = [ ];
 
  colDefs: ColDef[] = [];
 
  defaultColDef: ColDef = {
    flex: 1,
    sortable: true,
    filter: true
  };
  ngOnInit() {
    this.sharedService.detail$.subscribe((data: any) => {
      this.detail = data;
      this.org=this.detail.user.usrOrganization;
      this.usrId=this.detail.user.usrId;
    });
    this.user = this.userService.getUser();
 if(this.Coach){
  this.assessmentService.getptaByCoachId(this.usrId).subscribe(response => {
    console.log('Assessment submitted', response);

    this.rowData = response.map((item:any) => ({
      Id: `${item.ptaId}`,
      playerId: `${item.ptaAthlete}`,
      playername: `${item.athleteName}`,
      Assessor: ` ${item.assessorName}`,
      InjuryHistory: `${item.ptaInjuryHistory}`,
      TreatmentPlan: `${item.ptaTreatmentPlan}`,
      date: new Date(item.ptaDate).toLocaleDateString('en-US'),
      //priority: this.getPriority(item)
    }));

    this.colDefs = this.getColumnDefinitions();
  }, error => {
    console.error('Error submitting assessment', error);
  });
 }else{
  this.assessmentService.getPhysiotherapy(this.org).subscribe(response => {
    console.log('Assessment submitted', response);

    this.rowData = response.map((item:any) => ({
      Id: `${item.ptaId}`,
      playerId: `${item.ptaAthlete}`,
      playername: `${item.athleteName}`,
      Assessor: ` ${item.assessorName}`,
      InjuryHistory: `${item.ptaInjuryHistory}`,
      TreatmentPlan: `${item.ptaTreatmentPlan}`,
      date: new Date(item.ptaDate).toLocaleDateString('en-US'),
      //priority: this.getPriority(item)
    }));

    this.colDefs = this.getColumnDefinitions();
  }, error => {
    console.error('Error submitting assessment', error);
  });
 }
    
 
   
  }
 

 
  getColumnDefinitions(): ColDef[] {
    const columns: ColDef[] = [
      { field: "playername" },
      { field: "Assessor" },
      { field: "InjuryHistory" },
      { field: "TreatmentPlan" },
      { field: "date" },

      {
        headerName: 'Action',
        cellRenderer: (_params: any) => {
          const container = document.createElement('div');
          container.style.display = 'flex';
          container.style.justifyContent = 'space-around';
 
          // View button with eye icon
          const viewButton = document.createElement('button');
          viewButton.classList.add('btn');
          viewButton.innerHTML = '<i class="fa fa-eye"></i>';
          viewButton.addEventListener('click', () => {
            this.ngZone.run(() => {
            if (this.isPlayer) {
              this.router.navigate(['/physiotherapydetail']);
            } else {
              this.router.navigate(['/Physiotherapydetaillist'], { queryParams: { id: _params.data.playerId } })
            }
          });
          });
 
          // Edit button with edit icon
          const editButton = document.createElement('button');
          editButton.classList.add('btn');
          editButton.innerHTML = '<i class="fa fa-edit"></i>';
          editButton.addEventListener('click', () => {
            this.ngZone.run(() => {
            this.router.navigate(['/physiotherapy'], {queryParams: { id: _params.data.Id } })
            });
          });
          container.appendChild(viewButton);
          if (!this.Isplayer && !this.OrgAdmin && !this.Coach) {
            container.appendChild(editButton);
            }
 
          return container;
        },
        width: 120
      }
         ];
    if (this.Admin || this.OrgAdmin) {
      return columns;
    } else{
      return columns.filter(col => col.field !== 'playername');
    }
  }
  openModal(): void {
    // Use Bootstrap's modal function to open the modal
    const modalElement = document.getElementById('fileUploadModal')!;
    const modal = new (window as any).bootstrap.Modal(modalElement);
    modal.show();
  }
}