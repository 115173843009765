import { CommonModule } from '@angular/common';
import { Component, Input, NgZone, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink, RouterModule } from '@angular/router';
import { AgGridAngular } from 'ag-grid-angular';
import { FileuploadComponent } from '../../assessment/fileupload/fileupload.component';
import { User } from '../../model/User';
import { ColDef } from 'ag-grid-community';
import { PrivilegeEnum } from '../../model/PrivilageEnum';
import { AssessmentService } from '../../services/assessment.service';
import { PrivilegeService } from '../../services/privilage.service';
import { UserService } from '../../services/user.service';
import { Privilege } from '../../model/Privillage';
import { SharedService } from '../../services/shared.service';



interface IRow {
  playername: string;
  pfaId:string;
  pfaAthlete:string;
  Assessor: string;
  Speed: string;
  Strength: string;
  ReactionTime: string;
  priority: string;
  date: string;
}

@Component({
  selector: 'app-cardiology-detail-list',
  standalone: true,
  imports: [CommonModule, RouterModule, RouterLink, FormsModule,AgGridAngular,FileuploadComponent],
  templateUrl: './cardiology-detail-list.component.html',
  styleUrl: './cardiology-detail-list.component.css'
})
export class CardiologyDetailListComponent {

  @ViewChild('fileUploadModal') fileUploadModal: FileuploadComponent | undefined;
  @Input() name: any;
 
  user: User | null = null;
 
  themeClass = 'ag-theme-quartz';
 
  rowData: IRow[] = [];
 
  colDefs: ColDef[] = [];
 
  ramPrivilege: PrivilegeEnum | undefined;
 
  defaultColDef: ColDef = {
    flex: 1,
    sortable: true,
    filter: true,
  };
  privilege: Privilege;
  Isplayer: boolean;
  Admin: boolean;
  detail: any;
  org: any;
  id:any;
 
  constructor(private assessmentService: AssessmentService, private router: Router, private sharedService: SharedService,
    private ngZone: NgZone, private route: ActivatedRoute,private userService:UserService,private priService:PrivilegeService) {
    this.privilege = this.priService.getPrivileges();
    this.Isplayer = this.privilege.isPlayer;
    this.Admin = this.privilege.isAdmin;

    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
      console.log(this.id,"ID");
    })
    }
 
  ngOnInit() {
    this.sharedService.detail$.subscribe((data: any) => {
      this.detail = data;
      this.org=this.detail.user.usrOrganization;
      console.log(this.org,"org")
    });
    this.user = this.userService.getUser();
    this.ramPrivilege = this.userService.getRamPrivilege();
    console.log(this.ramPrivilege,"Datapri");
    this.assessmentService.getcardioByAthleteId(this.id).subscribe(response => {
      console.log('Assessment submitted', response);
 
      this.rowData = response.map((item:any) => ({
        playername: `${item.athleteName}`,
        Id:`${item.coaId}`,
        "800mRun" :`${item.coa800mRun}`,
        "YO-YO Test": `${item.coaYoYoTest}`,
        "Rast (6,BOx)": `${item.coaRAST}`,
        FatigueIndex: `${item.coaFatigueIndex}`,
        date: new Date(item.coaCreatedOn).toLocaleDateString('en-US'),
        // priority: this.getPriority(item)
      }));
 
      this.colDefs = this.getColumnDefinitions();
    }, error => {
      console.error('Error submitting assessment', error);
    });
  }
 
 
  getColumnDefinitions(): ColDef[] {
    const columns: ColDef[] = [
      { field: 'playername' },
      //{ field: 'pfaId' },
      { field: '800mRun' },
      { field: 'YO-YO Test' },
      { field: 'Rast (6,BOx)' },
      { field: 'FatigueIndex' },
      { field: 'date' },
      {
        headerName: 'Action',
        cellRenderer: (_params: any) => {
          const container = document.createElement('div');
          container.style.display = 'flex';
          container.style.justifyContent = 'space-around';
 
          // View button with eye icon
          const viewButton = document.createElement('button');
          viewButton.classList.add('btn');
          viewButton.innerHTML = '<i class="fa fa-eye"></i>';
          viewButton.addEventListener('click', () => {
              this.ngZone.run(() => {
              this.router.navigate(['/CardiologyDetail'], {queryParams: { id: _params.data.Id } })
              })
          });
 
          // Edit button with edit icon
         
          const editButton = document.createElement('button');
          editButton.classList.add('btn');
          editButton.innerHTML = '<i class="fa fa-edit"></i>';
          editButton.addEventListener('click', () => {
            this.ngZone.run(() => {
            this.router.navigate(['/CardiologyDetail'], {queryParams: { id: _params.data.Id } })
            })
          });
 
          container.appendChild(viewButton);
          if (!this.Isplayer || this.ramPrivilege === PrivilegeEnum.WRITE) {
          container.appendChild(editButton);
          }
          //container.appendChild(deleteButton);
 
          return container;
        },
        width: 120
      }
    ];
 
    if (this.Admin) {
      return columns;
    } else if (this.Isplayer) {
      return columns.filter(col => col.field !== 'playername');
    } else {
      return columns.filter(col => col.field !== 'Assessor');
    }
  }
 
  openModal(): void {
    // Use Bootstrap's modal function to open the modal
    const modalElement = document.getElementById('fileUploadModal')!;
    const modal = new (window as any).bootstrap.Modal(modalElement);
    modal.show();
  }


}
