import { CommonModule } from '@angular/common';
import { Component, SimpleChanges, NgZone, OnChanges, Input } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { AssessmentService } from '../services/assessment.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { PrivilegeService } from '../services/privilage.service';
import { UserService } from '../services/user.service';
import { ColDef } from 'ag-grid-community';
import { SharedService } from '../services/shared.service';
import { AgGridAngular } from 'ag-grid-angular';
import { NotificationService } from '../../app/services/Notifications.service';
import { SupportStaffService } from '../services/support-staff.service';
import { response } from 'express';

interface IRow { }

@Component({
  selector: 'app-assessor-dashboard',
  standalone: true,
  imports: [CommonModule, AgGridAngular, HttpClientModule, FormsModule],
  templateUrl: './assessor-dashboard.component.html',
  styleUrl: './assessor-dashboard.component.css'
})
export class AssessorDashboardComponent {
  isLoading = false;
  selectedOrg: any = '0';
  selectedTeamId: any = 0;
  Orgainsation: any;
  teams: any;
  user: any;
  detail: any;
  UserId: any;
  org: any;
  privilege: any;
  Isplayer: boolean = false;
  Admin: boolean = false;
  OrgAdmin: boolean = false;
  VideoAnalysis: boolean = false;
  PhysicalFitness: boolean = false;
  Nutrition: boolean = false;
  Cognitive: boolean = false;
  Coach: boolean = false;
  sciStaff: boolean = false;
  assessor: boolean = false;
  allAssessmentResponse: any;
  rowData: IRow[] = [];
  colDefs: ColDef[] = [];
  defaultColDef: ColDef = {
    flex: 1,
    sortable: true,
    filter: true,
  };
  role: any;
  Id: any;
  gridApi: any;
  overallCount: any;
  completedCount: any;
  pendingCount: any;
  nutritionOverallCount: any;
  nutritionPendingCount: any;
  nutritionCompletedCount: any;
  physicalfitnessOverallCount: any;
  physicalfitnessPendingCount: any;
  physicalfitnessCompletedCount: any;
  cognitiveOverallCount: any;
  cognitivePendingCount: any;
  cognitiveCompletedCount: any;
  cardioOverallCount: any;
  cardioPendingCount: any;
  cardioCompletedCount: any;
  injuryOverallCount: any;
  injuryPendingCount: any;
  injuryCompletedCount: any;
  sportsOverallCount: any;
  sportsPendingCount: any;
  sportsCompletedCount: any;
  sleepOverallCount: any;
  sleepPendingCount: any;
  sleepCompletedCount: any;
  hydrationOverallCount: any;
  hydrationPendingCount: any;
  hydrationCompletedCount: any;
  hoursOverallCount: any;
  hoursPendingCount: any;
  hoursCompletedCount: any;
  nutritionCompletedPlayerCount: any;
  nutritionPendingPlayerCount: any;
  physicalfitnessCompletedPlayerCount: any;
  physicalfitnessPendingPlayerCount: any;
  cognitiveCompletedPlayerCount: any;
  cognitivePendingPlayerCount: any;
  cardioCompletedPlayerCount: any;
  cardioPendingPlayerCount: any;
  injuryCompletedPlayerCount: any;
  injuryPendingPlayerCount: any;
  sportsCompletedPlayerCount: any;
  sportsPendingPlayerCount: any;
  sleepCompletedPlayerCount: any;
  sleepPendingPlayerCount: any;
  hydrationCompletedPlayerCount: any;
  hydrationPendingPlayerCount: any;
  hoursCompletedPlayerCount: any;
  hoursPendingPlayerCount: any;
  Status: any;
  assessments: any;
  filteredResponse: any;
  filteredPlayerResponse: any;
  private previousSelectedTeamId: any = null;
  private previousSelectedOrgId: any = null;
  asmId: number = 0;
  categoryCounts: { CategoryName: string; Overall: any; Completed: any; Pending: any; CompletedPlayers: any; PendingPlayers: any; }[] = [];
  NutritionId: number | undefined;
  PhysicalFitnessId: number | undefined;
  CognitiveId: number | undefined;
  CardioId: number | undefined;
  InjuryId: number | undefined;
  SportsId: number | undefined;
  SleepId: number | undefined;
  HydrationId: number | undefined;
  HoursId: number | undefined;
  filteredOrgPlayers: any;
  filteredOrgAssessments: any;
  constructor(
    private router: Router,
    private assessmentService: AssessmentService,
    private priService: PrivilegeService,
    private userService: UserService,
    private sharedService: SharedService,
    private sanitizer: DomSanitizer,
    private ngZone: NgZone,
    private notify: NotificationService,
    private supportStaffService: SupportStaffService
  ) {
    this.sharedService.detail$.subscribe((data: any) => {
      this.detail = data;
      this.UserId = this.detail.user.usrId;
      this.org = this.detail.user.usrOrganization;
      this.Status = 0;
    });
    this.privilege = this.priService.getPrivileges();
    this.Isplayer = this.privilege?.isPlayer ?? false;
    this.Admin = this.privilege?.isAdmin ?? false;
    this.OrgAdmin = this.privilege?.isOrgAdmin ?? false;
    this.VideoAnalysis = this.privilege?.isVideoAnalysis ?? false;
    this.Nutrition = this.privilege?.isNutrition ?? false;
    this.PhysicalFitness = this.privilege?.isPhysicalFitness ?? false;
    this.Cognitive = this.privilege?.isCognitive ?? false;
    this.Coach = this.privilege?.isCoach ?? false;
    this.sciStaff = this.privilege.isScienceStaff ?? false;
    this.assessor = this.privilege.isAssessor ?? false;
  }
  async ngOnInit(): Promise<void> {
    this.user = this.userService.getUser();
    this.isLoading = true;
    this.selectOrganisation();
    this.assessmentService.getOrgainsation().subscribe(
      (data) => {
        this.Orgainsation = data;
      },
      (error) => {
        console.error('Error fetching coaches:', error);
      }
    );
    this.supportStaffService.getSupportStaff().subscribe();
    this.NutritionId = this.supportStaffService.getSsIdBySsName("Nutrition");
    this.PhysicalFitnessId = this.supportStaffService.getSsIdBySsName("PhysicalFitness");
    this.CognitiveId = this.supportStaffService.getSsIdBySsName("Cognitive");
    this.CardioId = this.supportStaffService.getSsIdBySsName("Cardio");
    this.InjuryId = this.supportStaffService.getSsIdBySsName("Injury Prevention Analysis");
    this.SportsId = this.supportStaffService.getSsIdBySsName("Sports Performance Analysis");
    this.SleepId = this.supportStaffService.getSsIdBySsName("Sleep questionnaire");
    this.HydrationId = this.supportStaffService.getSsIdBySsName("Hydration questionaire");
    this.HoursId = this.supportStaffService.getSsIdBySsName("24 Hours Recall questionaire");
    await this.getPlayerAssessment();
    await this.getAssessment();
    this.isLoading = false;
  }

  async selectOrganisation() {
    this.selectedTeamId = '0';
    //this.clearCounts();
    this.isLoading = true;
    await this.getPlayerAssessment();
    await this.getAssessment();
    this.isLoading = false;
  }

  async selectTeams() {
    this.isLoading = true;
    await this.getPlayerAssessment();
    await this.getAssessment();
    this.isLoading = false;
  }

  // clearCounts() {
  //   this.overallCount = 0;
  //   this.completedCount = 0;
  //   this.pendingCount = 0;

  //   this.nutritionOverallCount = 0;
  //   this.nutritionCompletedCount = 0;
  //   this.nutritionPendingCount = 0;
  //   this.nutritionCompletedPlayerCount = 0;
  //   this.nutritionPendingPlayerCount = 0;

  //   this.physicalfitnessOverallCount = 0;
  //   this.physicalfitnessCompletedCount = 0;
  //   this.physicalfitnessPendingCount = 0;
  //   this.physicalfitnessCompletedPlayerCount = 0;
  //   this.physicalfitnessPendingPlayerCount = 0;

  //   this.cognitiveOverallCount = 0;
  //   this.cognitiveCompletedCount = 0;
  //   this.cognitivePendingCount = 0;
  //   this.cognitiveCompletedPlayerCount = 0;
  //   this.cognitivePendingPlayerCount = 0;

  //   this.cardioOverallCount = 0;
  //   this.cardioCompletedCount = 0;
  //   this.cardioPendingCount = 0;
  //   this.cardioCompletedPlayerCount = 0;
  //   this.cardioPendingPlayerCount = 0;

  //   this.injuryOverallCount = 0;
  //   this.injuryCompletedCount = 0;
  //   this.injuryPendingCount = 0;
  //   this.injuryCompletedPlayerCount = 0;
  //   this.injuryPendingPlayerCount = 0;

  //   this.sportsOverallCount = 0;
  //   this.sportsCompletedCount = 0;
  //   this.sportsPendingCount = 0;
  //   this.sportsCompletedPlayerCount = 0;
  //   this.sportsPendingPlayerCount = 0;

  //   this.sleepOverallCount = 0;
  //   this.sleepCompletedCount = 0;
  //   this.sleepPendingCount = 0;
  //   this.sleepCompletedPlayerCount = 0;
  //   this.sleepPendingPlayerCount = 0;

  //   this.hydrationOverallCount = 0;
  //   this.hydrationCompletedCount = 0;
  //   this.hydrationPendingCount = 0;
  //   this.hydrationCompletedPlayerCount = 0;
  //   this.hydrationPendingPlayerCount = 0;

  //   this.hoursOverallCount = 0;
  //   this.hoursCompletedCount = 0;
  //   this.hoursPendingCount = 0;
  //   this.hoursCompletedPlayerCount = 0;
  //   this.hoursPendingPlayerCount = 0;
  // }

  async getPlayerAssessment() {
    this.assessmentService.getPlayerByAssessmentId(this.asmId).subscribe(
      (playerResponse) => {
        // Filter assessments where astAssesser matches UserId and astStatus is not 2
        const filteredPlayers = playerResponse.filter((item: any) => {
          return item.apIAssesser == this.UserId && item.aplStatus !== 2;
        });

        if (this.selectedTeamId == '0' && this.selectedOrg == '0') {
          this.filteredOrgPlayers = filteredPlayers
        } else if (this.selectedOrg != '0' && this.selectedTeamId == '0') {
          this.filteredOrgPlayers = filteredPlayers.filter((item: any) => {
            return item.usrOrganization == this.selectedOrg
          });
        } else {
          this.filteredOrgPlayers = filteredPlayers.filter((item: any) => {
            return item.teamId == this.selectedTeamId
          });
        }

        const uniquePlayers = new Map<number, any>();
        this.filteredOrgPlayers.forEach((item: any) => {
          // Only add the item if it does not exist in the map
          if (!uniquePlayers.has(item.aplPlayer)) {
            uniquePlayers.set(item.aplPlayer, {
              "Player Name": `${item.usrFullName}`,
              PlayerId: `${item.aplPlayer}`,
              teamId: `${item.teamId}`,
              id: `${item.aplAssessment}`,
              "Email": `${item.usrEmail}`,
              "Phone No": `${item.usrPhoneNo}`,
              "DOB": new Date(item.usrDoB).toLocaleDateString(),
              aplStatus: item.aplStatus,
              category: [item.aplCategory],
              team: [item.aplTeam],
              organization: [item.usrOrganization],
            });
          } else {
            const existingAssessment = uniquePlayers.get(item.aplPlayer);
            if (!existingAssessment.category.includes(item.aplCategory)) {
              existingAssessment.category.push(item.aplCategory);
            }
          }
        });
        
        this.filteredPlayerResponse = Array.from(uniquePlayers.values());;

        this.nutritionCompletedPlayerCount = this.filteredPlayerResponse.filter((item: any) => item.aplStatus == 1 && item.category.includes(this.NutritionId)).length;
        this.nutritionPendingPlayerCount = this.filteredPlayerResponse.filter((item: any) => item.aplStatus == 0 && item.category.includes(this.NutritionId)).length;

        this.physicalfitnessCompletedPlayerCount = this.filteredPlayerResponse.filter((item: any) => item.aplStatus == 1 && item.category.includes(this.PhysicalFitnessId)).length;
        this.physicalfitnessPendingPlayerCount = this.filteredPlayerResponse.filter((item: any) => item.aplStatus == 0 && item.category.includes(this.PhysicalFitnessId)).length;

        this.cognitiveCompletedPlayerCount = this.filteredPlayerResponse.filter((item: any) => item.aplStatus == 1 && item.category.includes(this.CognitiveId)).length;
        this.cognitivePendingPlayerCount = this.filteredPlayerResponse.filter((item: any) => item.aplStatus == 0 && item.category.includes(this.CognitiveId)).length;

        this.cardioCompletedPlayerCount = this.filteredPlayerResponse.filter((item: any) => item.aplStatus == 1 && item.category.includes(this.CardioId)).length;
        this.cardioPendingPlayerCount = this.filteredPlayerResponse.filter((item: any) => item.aplStatus == 0 && item.category.includes(this.CardioId)).length;

        this.injuryCompletedPlayerCount = this.filteredPlayerResponse.filter((item: any) => item.aplStatus == 1 && item.category.includes(this.InjuryId)).length;
        this.injuryPendingPlayerCount = this.filteredPlayerResponse.filter((item: any) => item.aplStatus == 0 && item.category.includes(this.InjuryId)).length;

        this.sportsCompletedPlayerCount = this.filteredPlayerResponse.filter((item: any) => item.aplStatus == 1 && item.category.includes(this.SportsId)).length;
        this.sportsPendingPlayerCount = this.filteredPlayerResponse.filter((item: any) => item.aplStatus == 0 && item.category.includes(this.SportsId)).length;

        this.sleepCompletedPlayerCount = this.filteredPlayerResponse.filter((item: any) => item.aplStatus == 1 && item.category.includes(this.SleepId)).length;
        this.sleepPendingPlayerCount = this.filteredPlayerResponse.filter((item: any) => item.aplStatus == 0 && item.category.includes(this.SleepId)).length;

        this.hydrationCompletedPlayerCount = this.filteredPlayerResponse.filter((item: any) => item.aplStatus == 1 && item.category.includes(this.HydrationId)).length;
        this.hydrationPendingPlayerCount = this.filteredPlayerResponse.filter((item: any) => item.aplStatus == 0 && item.category.includes(this.HydrationId)).length;

        this.hoursCompletedPlayerCount = this.filteredPlayerResponse.filter((item: any) => item.aplStatus == 1 && item.category.includes(this.HoursId)).length;
        this.hoursPendingPlayerCount = this.filteredPlayerResponse.filter((item: any) => item.aplStatus == 0 && item.category.includes(this.HoursId)).length;
        
       }
    );
  }

  async getAssessment() {
    this.assessmentService.getpendingAssessment().subscribe(
      (assessmentResponse) => {

        const organizationSet = new Map<string, string>();
        const teamSet = new Map<string, { teamName: string; teamOrganization: string }>();

        const filteredAssessments = assessmentResponse.filter((item: any) => {
          return item.astAssesser == this.UserId && item.astStatus !== 2;
        });

        filteredAssessments.forEach((item: any) => {
          organizationSet.set(item.teamOrganization, item.orgName);
          teamSet.set(item.asmTeam, { teamName: item.teamName, teamOrganization: item.teamOrganization });
        });

        this.Orgainsation = Array.from(organizationSet, ([teamOrganization, orgName]) => ({ orgName, teamOrganization }));

        if (this.selectedOrg !== '0') {
          this.teams = Array.from(teamSet).filter(([_, { teamOrganization }]) => teamOrganization == this.selectedOrg)
            .map(([asmTeam, { teamName }]) => ({ teamName, asmTeam }));
        } else {
          this.teams = Array.from(teamSet, ([asmTeam, { teamName }]) => ({ teamName, asmTeam }));
        }

        if (this.selectedTeamId == '0' && this.selectedOrg == '0') {
          this.filteredOrgAssessments = filteredAssessments
        } else if (this.selectedOrg != '0' && this.selectedTeamId == '0') {
          this.filteredOrgAssessments = filteredAssessments.filter((item: any) => {
            return item.asmTeam == this.selectedTeamId || item.teamOrganization == this.selectedOrg
          });
        } else {
          this.filteredOrgAssessments = filteredAssessments.filter((item: any) => {
            return item.asmTeam == this.selectedTeamId
          });
        }

        // Map for unique assessments
        const uniqueAssessments = new Map<number, any>();

        // Populate uniqueAssessments map with filtered data
        this.filteredOrgAssessments.forEach((item: any) => {
          if (!uniqueAssessments.has(item.asmId)) {
            uniqueAssessments.set(item.asmId, {
              AssessmentName: item.asmName,
              TeamName: item.teamName,
              CoachName: item.coachName,
              organization: [item.teamOrganization],
              Description: item.asmDescription,
              category: [item.testCategory],
              Date: new Date(item.asmCreatedOn).toLocaleDateString(),
              testNames: [item.testName],
              CoachId: item.asmCoach,
              id: item.asmId,
              sscategory: item.sscategory,
              astStatus: item.astStatus,
              ssNames: [item.ssName],
              team: [item.asmTeam]
            });

          } else {
            const existingAssessment = uniqueAssessments.get(item.asmId);
            if (!existingAssessment.testNames.includes(item.testName)) {
              existingAssessment.testNames.push(item.testName);
            }
            if (!existingAssessment.ssNames.includes(item.ssName)) {
              existingAssessment.ssNames.push(item.ssName);
            }
            if (!existingAssessment.category.includes(item.testCategory)) {
              existingAssessment.category.push(item.testCategory);
            }
            if (!existingAssessment.organization.includes(item.teamOrganization)) {
              existingAssessment.organization.push(item.teamOrganization);
            }
            if (!existingAssessment.team.includes(item.asmTeam)) {
              existingAssessment.team.push(item.asmTeam);
            }
          }
        });

        this.filteredResponse = Array.from(uniqueAssessments.values());;

        // if (this.previousSelectedTeamId != this.selectedTeamId || this.previousSelectedOrgId != this.selectedOrg) {

          this.nutritionOverallCount = this.filteredResponse.filter((item: any) => item.category.includes(this.NutritionId)).length;
          this.nutritionCompletedCount = this.filteredResponse.filter((item: any) => item.astStatus == 1 && item.category.includes(this.NutritionId)).length;
          this.nutritionPendingCount = this.filteredResponse.filter((item: any) => item.astStatus == 0 && item.category.includes(this.NutritionId)).length;

          this.physicalfitnessOverallCount = this.filteredResponse.filter((item: any) => item.category.includes(this.PhysicalFitnessId)).length;
          this.physicalfitnessCompletedCount = this.filteredResponse.filter((item: any) => item.astStatus == 1 && item.category.includes(this.PhysicalFitnessId)).length;
          this.physicalfitnessPendingCount = this.filteredResponse.filter((item: any) => item.astStatus == 0 && item.category.includes(this.PhysicalFitnessId)).length;

          this.cognitiveOverallCount = this.filteredResponse.filter((item: any) => item.category.includes(this.CognitiveId)).length;
          this.cognitiveCompletedCount = this.filteredResponse.filter((item: any) => item.astStatus == 1 && item.category.includes(this.CognitiveId)).length;
          this.cognitivePendingCount = this.filteredResponse.filter((item: any) => item.astStatus == 0 && item.category.includes(this.CognitiveId)).length;

          this.cardioOverallCount = this.filteredResponse.filter((item: any) => item.category.includes(this.CardioId)).length;
          this.cardioCompletedCount = this.filteredResponse.filter((item: any) => item.astStatus == 1 && item.category.includes(this.CardioId)).length;
          this.cardioPendingCount = this.filteredResponse.filter((item: any) => item.astStatus == 0 && item.category.includes(this.CardioId)).length;

          this.injuryOverallCount = this.filteredResponse.filter((item: any) => item.category.includes(this.InjuryId)).length;
          this.injuryCompletedCount = this.filteredResponse.filter((item: any) => item.astStatus == 1 && item.category.includes(this.InjuryId)).length;
          this.injuryPendingCount = this.filteredResponse.filter((item: any) => item.astStatus == 0 && item.category.includes(this.InjuryId)).length;

          this.sportsOverallCount = this.filteredResponse.filter((item: any) => item.category.includes(this.SportsId)).length;
          this.sportsCompletedCount = this.filteredResponse.filter((item: any) => item.astStatus == 1 && item.category.includes(this.SportsId)).length;
          this.sportsPendingCount = this.filteredResponse.filter((item: any) => item.astStatus == 0 && item.category.includes(this.SportsId)).length;

          this.sleepOverallCount = this.filteredResponse.filter((item: any) => item.category.includes(this.SleepId)).length;
          this.sleepCompletedCount = this.filteredResponse.filter((item: any) => item.astStatus == 1 && item.category.includes(this.SleepId)).length;
          this.sleepPendingCount = this.filteredResponse.filter((item: any) => item.astStatus == 0 && item.category.includes(this.SleepId)).length;

          this.hydrationOverallCount = this.filteredResponse.filter((item: any) => item.category.includes(this.HydrationId)).length;
          this.hydrationCompletedCount = this.filteredResponse.filter((item: any) => item.astStatus == 1 && item.category.includes(this.HydrationId)).length;
          this.hydrationPendingCount = this.filteredResponse.filter((item: any) => item.astStatus == 0 && item.category.includes(this.HydrationId)).length;

          this.hoursOverallCount = this.filteredResponse.filter((item: any) => item.category.includes(this.HoursId)).length;
          this.hoursCompletedCount = this.filteredResponse.filter((item: any) => item.astStatus == 1 && item.category.includes(this.HoursId)).length;
          this.hoursPendingCount = this.filteredResponse.filter((item: any) => item.astStatus == 0 && item.category.includes(this.HoursId)).length;

          this.overallCount = this.nutritionOverallCount + this.physicalfitnessOverallCount + this.cognitiveOverallCount + this.cardioOverallCount + this.injuryOverallCount + this.sportsOverallCount + this.sleepOverallCount + this.hydrationOverallCount + this.hoursOverallCount;
          this.completedCount = this.nutritionCompletedCount + this.physicalfitnessCompletedCount + this.cognitiveCompletedCount + this.cardioCompletedCount + this.injuryCompletedCount + this.sportsCompletedCount + this.sleepCompletedCount + this.hydrationCompletedCount + this.hoursCompletedCount;
          this.pendingCount = this.nutritionPendingCount + this.physicalfitnessPendingCount + this.cognitivePendingCount + this.cardioPendingCount + this.injuryPendingCount + this.sportsPendingCount + this.sleepPendingCount + this.hydrationPendingCount + this.hoursPendingCount;

        //   this.previousSelectedTeamId = this.selectedTeamId;
        //   this.previousSelectedOrgId = this.selectedOrg;
        // }

        this.categoryCounts = [
          {
            CategoryName: 'Nutrition',
            Overall: this.nutritionOverallCount,
            Completed: this.nutritionCompletedCount,
            Pending: this.nutritionPendingCount,
            CompletedPlayers: this.nutritionCompletedPlayerCount,
            PendingPlayers: this.nutritionPendingPlayerCount
          },
          {
            CategoryName: 'Physical Fitness',
            Overall: this.physicalfitnessOverallCount,
            Completed: this.physicalfitnessCompletedCount,
            Pending: this.physicalfitnessPendingCount,
            CompletedPlayers: this.physicalfitnessCompletedPlayerCount,
            PendingPlayers: this.physicalfitnessPendingPlayerCount
          },
          {
            CategoryName: 'Cognitive',
            Overall: this.cognitiveOverallCount,
            Completed: this.cognitiveCompletedCount,
            Pending: this.cognitivePendingCount,
            CompletedPlayers: this.cognitiveCompletedPlayerCount,
            PendingPlayers: this.cognitivePendingPlayerCount
          },
          {
            CategoryName: 'Cardio',
            Overall: this.cardioOverallCount,
            Completed: this.cardioCompletedCount,
            Pending: this.cardioPendingCount,
            CompletedPlayers: this.cardioCompletedPlayerCount,
            PendingPlayers: this.cardioPendingPlayerCount
          },
          {
            CategoryName: 'Injury Prevention Analysis',
            Overall: this.injuryOverallCount,
            Completed: this.injuryCompletedCount,
            Pending: this.injuryPendingCount,
            CompletedPlayers: this.injuryCompletedPlayerCount,
            PendingPlayers: this.injuryPendingPlayerCount
          },
          {
            CategoryName: 'Sports Performance Analysis',
            Overall: this.sportsOverallCount,
            Completed: this.sportsCompletedCount,
            Pending: this.sportsPendingCount,
            CompletedPlayers: this.sportsCompletedPlayerCount,
            PendingPlayers: this.sportsPendingPlayerCount
          },
          {
            CategoryName: 'Sleep questionnaire',
            Overall: this.sleepOverallCount,
            Completed: this.sleepCompletedCount,
            Pending: this.sleepPendingCount,
            CompletedPlayers: this.sleepCompletedPlayerCount,
            PendingPlayers: this.sleepPendingPlayerCount
          },
          {
            CategoryName: 'Hydration questionaire',
            Overall: this.hydrationOverallCount,
            Completed: this.hydrationCompletedCount,
            Pending: this.hydrationPendingCount,
            CompletedPlayers: this.hydrationCompletedPlayerCount,
            PendingPlayers: this.hydrationPendingPlayerCount
          },
          {
            CategoryName: '24 Hours Recall questionaire',
            Overall: this.hoursOverallCount,
            Completed: this.hoursCompletedCount,
            Pending: this.hoursPendingCount,
            CompletedPlayers: this.hoursCompletedPlayerCount,
            PendingPlayers: this.hoursPendingPlayerCount
          }
        ];

        this.rowData = this.categoryCounts;
        console.log(this.rowData,'this.rowData')
        this.colDefs = this.getColumnDefinitions();
      },
      (error) => {
      }
    );
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
  }

  getColumnDefinitions(): ColDef[] {
    const columns: ColDef[] = [
      { field: 'CategoryName', headerName: 'Category' },
      { field: 'Overall', headerName: 'Overall Assessment' },
      { field: 'Completed', headerName: 'Completed Assessment' },
      { field: 'Pending', headerName: 'Pending Assessment' },
      { field: 'CompletedPlayers', headerName: 'Completed Players' },
      { field: 'PendingPlayers', headerName: 'Pending Players' },
      {
        headerName: 'Action',
        cellRenderer: (_params: any) => {
          const container = document.createElement('div');
          container.style.display = 'flex';
          container.style.justifyContent = 'space-around';

          // Create View Button
          const viewButton = document.createElement('button');
          viewButton.classList.add('btn');
          viewButton.innerHTML = '<i class="fa fa-eye"></i>';

          // Disable viewButton if role == 1 and astStatus == 0
          if (this.role === 1 && _params.data.astStatus === 0) {
            viewButton.disabled = true;
          }

          // View Button click event
          viewButton.addEventListener('click', () => {
            this.ngZone.run(() => {
              let routePath = ''; // Variable to hold the dynamic route path

              // Determine route path based on the CategoryName
              switch (_params.data.CategoryName) {
                case 'Nutrition':
                  routePath = '/NurtitionAssessment';
                  break;
                case 'Physical Fitness':
                  routePath = '/PhysicalfitnessAssessment';
                  break;
                case 'Cognitive':
                  routePath = '/CognitiveAssessment';
                  break;
                case 'Cardio':
                  routePath = '/CardiologyAssessment';
                  break;
                case 'Injury Prevention Analysis':
                  routePath = '/InjuryanalysisAssessment';
                  break;
                case 'Sports Performance Analysis':
                  routePath = '/SportsPerformanceAnalysisAssessment';
                  break;
                case 'Sleep questionnaire':
                  routePath = '/SleepandRestAssessment';
                  break;
                case 'Hydration questionaire':
                  routePath = '/HydrationandNutritionAssessment';
                  break;
                case '24 Hours Recall questionaire':
                  routePath = '/RecallListComponent';
                  break;
                default:
                  console.error('Unknown CategoryName:', _params.data.CategoryName);
                  return; // Exit if category is unknown
              }

              // Navigate to the determined route path
              this.router.navigate([routePath]);
            });
          });


          container.appendChild(viewButton);
          return container;
        },
        width: 160, // Increase width to accommodate the additional icons
      },
    ];

    return columns;
    
  }
}
