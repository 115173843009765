<!-- 
      <div class="container-fluid">

          <ul>
            <li class="nav-item active">
              <a class="nav-link" aria-current="page" href="#">Home</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">Dashboard</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" (click)="formnavigation()">FormReport</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">About</a>
            </li>
          </ul>
      </div>
      <div id="chart"></div> -->
      <script src="https://unpkg.com/ag-grid-community/dist/ag-grid-community.min.js"></script>
      <script src="https://unpkg.com/ag-grid-community/dist/ag-grid-community.min.js"></script>

      <ag-grid-angular
      [rowData]="rowData"
      [columnDefs]="colDefs" 
      class="ag-theme-quartz"
 style="height: 500px;"/>

 <div id="divDrillDownData" class="ag-theme-alpine" style="height: 500px; width: 70%; margin-left: 15%;"></div>

 <script>
  
   const gridOptions = {
    columnDefs: colDefs,
    rowData: rowData,
    defaultColDef: { sortable: true, filter: true },
    onCellClicked: params => {
        console.log('cell was clicked', params);
    },
};

const eGridDiv = document.getElementById("divDrillDownData");
new agGrid.Grid(eGridDiv, gridOptions);

// Set the accumulated rowData after initializing the grid
gridOptions.api.setRowData(rowData);
 </script>

