<div class="container" [ngClass]="{'secActive': formSubmitted}">
  <header>Physiology</header><br>
  <form (ngSubmit)="onSubmit()" #assessmentForm="ngForm">
    <div class="form first">
      <div class="details personal">
        <div class="fields">
          <div class="input-field">
             <label>Player Name</label>
            <input
              type="text"
              placeholder="Enter Player Name"
              required
              [(ngModel)]="assessment.plaAthletename"
              name="playerName"
              list="athleteList"
              (input)="fetchAthletes($event)"
              (change)="onAthleteSelected($event)"
              #playerName="ngModel"
              [ngClass]="{
                'invalid-field': assessmentForm.submitted && playerName.invalid,
                'has-value':assessment.plaAthlete
              }"
              [value]=assessment.naAthlete
            >
            <datalist id="athleteList">
              <option *ngFor="let athlete of athletes" [value]="athlete.usrFullName"></option>
            </datalist>
            <div *ngIf="assessmentForm.submitted && playerName.invalid" class="error-message">
             
            </div>
          </div>
          <!-- <div class="input-field">
            <label>Player Name</label>
            <input type="text" placeholder="Enter Player Name" required [(ngModel)]="assessment.naAthleteName"
              name="playerName" list="athleteList" (input)="fetchAthletes($event)"
              (change)="onAthleteSelected($event)" #playerName="ngModel" [ngClass]="{
                'invalid-field': assessmentForm.submitted && playerName.invalid,
                'has-value': assessment.naAthlete
              }">
            <datalist id="athleteList">
              <option *ngFor="let athlete of athletes" [value]="athlete.usrFullName"></option>
            </datalist>
            <div *ngIf="assessmentForm.submitted && playerName.invalid" class="error-message">
             
            </div>
          </div> -->
        </div><br>
        <div class="fields">
          <div class="input-field">
            <label>Date of Assessment</label>
            <input
              type="date"
              placeholder="Enter date of assessment"
              required
              [(ngModel)]="assessment.plaDate"
              name="dateOfAssessment"
              #dateOfAssessment="ngModel"
              [ngClass]="{
                'invalid-field': assessmentForm.submitted && dateOfAssessment.invalid,
                'has-value': assessment.plaDate
              }"
            >
            <div *ngIf="assessmentForm.submitted && dateOfAssessment.invalid" class="error-message">
              <!-- Date of Assessment error message -->
            </div>
          </div>
          <div class="input-field">
            <!-- <label>Assessor's Name</label> -->
            <input
              type="hidden"
              placeholder="Enter assessor's name"
              required
              [(ngModel)]="assessment.plaAssessor"
              name="assessorName"
              #assessorName="ngModel"
              [ngClass]="{
                'invalid-field': assessmentForm.submitted && assessorName.invalid,
                'has-value': assessment.plaAssessor
              }"
            >
            <div *ngIf="assessmentForm.submitted && assessorName.invalid" class="error-message">
              <!-- Assessor's Name error message -->
            </div>
          </div>
        </div>
        <div class="details">
          <span class="title">Assessment Type</span>
          <div class="fields">
            <div class="input-field">
              <label>VO2 Max</label>
              <input
                type="number"
                placeholder="Enter VO2 Max"
                required
                [(ngModel)]="assessment.plaVO2Max"
                name="vo2Max"
                #vo2Max="ngModel"
                [ngClass]="{
                  'invalid-field': assessmentForm.submitted && vo2Max.invalid,
                  'has-value': assessment.plaVO2Max
                }"
              >
              <div *ngIf="assessmentForm.submitted && vo2Max.invalid" class="error-message">
                <!-- VO2 Max error message -->
              </div>
            </div>
            <div class="input-field">
              <label>Lactate Threshold</label>
              <input
                type="number"
                placeholder="Enter Lactate Threshold"
                required
                [(ngModel)]="assessment.plaLactateThreshold"
                name="lactateThreshold"
                #lactateThreshold="ngModel"
                [ngClass]="{
                  'invalid-field': assessmentForm.submitted && lactateThreshold.invalid,
                  'has-value': assessment.plaLactateThreshold
                }"
              >
              <div *ngIf="assessmentForm.submitted && lactateThreshold.invalid" class="error-message">
                <!-- Lactate Threshold error message -->
              </div>
            </div>
            <div class="input-field">
              <label>Heart Rate Variability</label>
              <input
                type="number"
                placeholder="Enter Heart Rate Variability"
                required
                [(ngModel)]="assessment.plaHeartRateVariablity"
                name="heartRateVariability"
                #heartRateVariability="ngModel"
                [ngClass]="{
                  'invalid-field': assessmentForm.submitted && heartRateVariability.invalid,
                  'has-value': assessment.plaHeartRateVariablity
                }"
              >
              <div *ngIf="assessmentForm.submitted && heartRateVariability.invalid" class="error-message">
                <!-- Heart Rate Variability error message -->
              </div>
            </div>
            <div class="input-field">
              <label>Resting Heart Rate</label>
              <input
                type="number"
                placeholder="Enter Resting Heart Rate"
                required
                [(ngModel)]="assessment.plaRestingHeartRate"
                name="restingHeartRate"
                #restingHeartRate="ngModel"
                [ngClass]="{
                  'invalid-field': assessmentForm.submitted && restingHeartRate.invalid,
                  'has-value': assessment.plaRestingHeartRate
                }"
              >
              <div *ngIf="assessmentForm.submitted && restingHeartRate.invalid" class="error-message">
                <!-- Resting Heart Rate error message -->
              </div>
            </div>
          </div>
        </div><br>
        <div class="fields">
          <div class="input-field">
            <label>Test Results</label>
            <input
              type="text"
              placeholder="Enter Test Results"
              required
              [(ngModel)]="assessment.plaTestResults"
              name="testResults"
              #testResults="ngModel"
              [ngClass]="{
                'invalid-field': assessmentForm.submitted && testResults.invalid,
                'has-value': assessment.plaTestResults
              }"
            >
            <div *ngIf="assessmentForm.submitted && testResults.invalid" class="error-message">
              <!-- Test Results error message -->
            </div>
          </div>
          <div class="input-field">
            <label>Interpretation of Results</label>
            <textarea
              type="text"
              placeholder="Enter Interpretation of Results"
              required
              [(ngModel)]="assessment.plaInterpretationResults"
              name="interpretationOfResults"
              #interpretationOfResults="ngModel"
              [ngClass]="{
                'invalid-field': assessmentForm.submitted && interpretationOfResults.invalid,
                'has-value': assessment.plaInterpretationResults
              }"
            ></textarea>
            <div *ngIf="assessmentForm.submitted && interpretationOfResults.invalid" class="error-message">
              <!-- Interpretation of Results error message -->
            </div>
          </div>
          <div class="input-field">
            <label>Recommendations</label>
            <textarea
              type="text"
              placeholder="Enter Recommendations"
              required
              [(ngModel)]="assessment.plaRecommendations"
              name="recommendations"
              #recommendations="ngModel"
              [ngClass]="{
                'invalid-field': assessmentForm.submitted && recommendations.invalid,
                'has-value': assessment.plaRecommendations
              }"
            ></textarea>
            <div *ngIf="assessmentForm.submitted && recommendations.invalid" class="error-message">
              <!-- Recommendations error message -->
            </div>
          </div>
          <div class="input-field">
            <label>Follow-up Date</label>
            <input
              type="date"
              placeholder="Enter Follow-up Date"
              required
              [(ngModel)]="assessment.plaFollowUpDate"
              name="followUpDate"
              #followUpDate="ngModel"
              [ngClass]="{
                'invalid-field': assessmentForm.submitted && followUpDate.invalid,
                'has-value': assessment.plaFollowUpDate
              }"
            >
            <div *ngIf="assessmentForm.submitted && followUpDate.invalid" class="error-message">
              <!-- Follow-up Date error message -->
            </div>
          </div>
        </div>
      </div>
      <div class="buttons">
        <button type="submit" class="submit">
          <span class="btnText">Submit</span>
          <i class="uil uil-navigator"></i>
        </button>
      </div>
    </div>
  </form>
</div>
<div class="toaster">
  <ng-toast />        
        </div>