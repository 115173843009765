import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, NgZone } from '@angular/core';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { NgToastModule } from 'ng-angular-popup';
import { environment } from '../../../../environments/environment.prod';
import { HttpClient } from '@angular/common/http';
import { SharedService } from '../../../services/shared.service';
import { NotificationService } from '../../../services/Notifications.service';
import { AssessmentService } from '../../../services/assessment.service';

@Component({
  selector: 'app-single-imageupload',
  standalone: true,
  imports: [CommonModule, RouterOutlet, NgToastModule, RouterLink],
  templateUrl: './single-imageupload.component.html',
  styleUrl: './single-imageupload.component.css'
})
export class SingleImageuploadComponent {

  selectedFiles: File[] = [];
  detail: any;
  UserId: any;
  constructor(private http: HttpClient, private sharedService: SharedService, private router: Router, private notify: NotificationService, private assessmentService: AssessmentService,
    private cdRef: ChangeDetectorRef, private ngZone: NgZone
  ) {
    this.sharedService.detail$.subscribe((data: any) => {
      this.detail = data;
      this.UserId = this.detail.user.usrId;
      console.log(this.UserId, "userIdssss")
    });

  }


  onFileSelected(event: any) {
    this.selectedFiles = Array.from(event.target.files) as File[];
    const validExtensions = ['jpg', 'png', 'jpeg'];
    const invalidFiles: File[] = [];
    this.selectedFiles.forEach((file) => {
      const fileExtension = file.name.split('.').pop()?.toLowerCase();
      if (!validExtensions.includes(fileExtension || '')) {
        invalidFiles.push(file);
      } else {
      }
    });
    if (invalidFiles.length > 0) {
      this.ngZone.run(() => {
        this.notify.failed('Only jpg and png files are allowed.');
        this.cdRef.detectChanges();
      });
    }
    console.log('Valid selected files:', this.selectedFiles.filter(file => !invalidFiles.includes(file)));
    console.log('Invalid selected files:', invalidFiles);
  }


  onSubmit() {
    if (this.selectedFiles.length == 0) {
      this.notify.failed("Please choose an image to upload.");
      return;
    }
    const validExtensions = ['jpg', 'png', 'jpeg'];
    const invalidFiles: File[] = [];
    this.selectedFiles.forEach((file) => {
      const fileExtension = file.name.split('.').pop()?.toLowerCase();
      if (!validExtensions.includes(fileExtension || '')) {
        invalidFiles.push(file);
      } else {
      }
    });
    if (invalidFiles.length > 0) {
      this.ngZone.run(() => {
        this.notify.failed('Only jpg and png files are allowed.');
        this.cdRef.detectChanges();
      });
    }

    const formData = new FormData();

    // Ensure `usrId` is assigned correctly before looping
    const usrId = this.detail.user.usrId;
    this.selectedFiles.forEach((file) => {
      // Extract the original file extension and construct the new filename
      const fileExtension = file.name.split('.').pop()?.toLowerCase() || 'jpg';
      formData.append('files', file);
    });

    // Append `usrId` to form data
    formData.append('usrId', usrId);

    formData.forEach((value, key) => { console.log(`${key}:`, value); });

    // Perform the upload
    this.assessmentService.imageupload(formData).subscribe(
      (response) => {
        console.log('Upload response:', response);
        if (response[0].statusCode == 200) {
          this.notify.success(response[0].message);
          location.reload();
          // setTimeout(() => {
          //   location.reload();
          // }, 1000);
        } else {
          this.notify.failed(response[0].message);
        }
      },
      (error) => {
        console.error('Upload error:', error);
        this.notify.failed('An error occurred while uploading the image. Please try again.');
      }
    );
  }
}