import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { NgToastModule } from 'ng-angular-popup';
import { environment } from '../../../../environments/environment.prod';
import { HttpClient } from '@angular/common/http';
import { SharedService } from '../../../services/shared.service';
import { NotificationService } from '../../../services/Notifications.service';
import { AssessmentService } from '../../../services/assessment.service';
 
@Component({
  selector: 'app-single-imageupload',
  standalone: true,
  imports: [CommonModule,RouterOutlet,NgToastModule,RouterLink],
  templateUrl: './single-imageupload.component.html',
  styleUrl: './single-imageupload.component.css'
})
export class SingleImageuploadComponent {
 
  selectedFiles: File[] = [];
  detail: any;
  UserId: any;
  constructor(private http:HttpClient,private sharedService: SharedService,private router:Router,private notify:NotificationService, private assessmentService: AssessmentService,) {
    this.sharedService.detail$.subscribe((data: any) => {
      this.detail = data;
      this.UserId = this.detail.user.usrId;
    });
 
  }
 
 
  onFileSelected(event: any) {
    this.selectedFiles = Array.from(event.target.files) as File[];
  }
 
  onSubmit() {
    if (this.selectedFiles.length == 0) {
      this.notify.failed("Please choose an image to upload.");
      return;
    }
   
    const formData = new FormData();
    this.selectedFiles.forEach((file) => {
      const name = this.UserId + '.jpg' || ' .png' || ' .jpeg';
      formData.append('files', file, name);
      
    });
   
    this.assessmentService.imageupload(formData).subscribe(
        (response) => {
          console.log(response,"response")
          this.notify.success("Uploaded successfully");
          location.reload();
        },
        (error) => {
          console.error(error,"error")
            this.notify.failed("An error occurred while uploading the image. Please try again.");
        }
      );
  }
 
 
 
}