<div class="container" [ngClass]="{'secActive': formSubmitted}">
    <header>Cognitive Skills</header><br>
    <form (ngSubmit)="onSubmit()" #form="ngForm">
        <div class="form first">
            <div class="details personal">
                <span class="title">Player Details</span>
                <img *ngIf="showImage && ImageUrl"  src="{{this.ImageUrl.changingThisBreaksApplicationSecurity}}">
                <div class="fields">
                    <div class="input-field">
                        <label>Player ID</label>
                        <input type="number" [(ngModel)]="assessment.cnaAthlete" name="cnaAthlete" (blur)="getProfileImage()" placeholder="Enter player id" required>
                    </div>                 
                </div>
            </div>
            <div class="details">
                <span class="title">Reaction Time</span>
                <div class="fields">
                    
                    <div class="input-field">
                        <label>Ruler Drop <span>(Cm)</span></label>
                        <input type="number" [(ngModel)]="assessment.cnaRulerDrop" name="cnaRulerDrop" placeholder="Enter Ruler Drop" required>
                    </div>
                    <div class="input-field">
                        <label>Blazepods</label>
                        <input type="number" [(ngModel)]="assessment.cnaBlazepods" name="cnaBlazepods" placeholder="Enter Blazepods Score" required>
                    </div>

                    <div class="input-field">
                        <label>Blazepods Timing <span>(Ms)</span></label>
                        <input type="number" [(ngModel)]="assessment.cnaBlazepodsTime" name="cnaBlazepodsTime" placeholder="Enter Blazepods Time" required>
                    </div>
                    
                </div>

                <div class="details personal">
                    <span class="title">Hand Eye Coordination</span>
                    <div class="fields">
                        <div class="input-field">
                            <label>Wall Toss</label>
                            <input type="number" [(ngModel)]="assessment.cnaWallToss" name="cnaWallToss" placeholder="Enter Wall Toss" required>
                        </div>
                        <div class="input-field">
                            <label>Plate Taping <span>(Sec)</span></label>
                            <input type="number" [(ngModel)]="assessment.cnaPlateTaping" name="cnaPlateTaping" placeholder="Enter Plate Taping" required>
                        </div>
                    </div>
                </div>
                </div>
                    <div class="button">
                        <button type="submit" class="submit">
                          <span class="btnText">Submit</span>
                          <i class="uil uil-navigator"></i>
                        </button>
                      </div>
        </div>
     
    </form>
       
  </div>
  <div class="toaster">
    <ng-toast />        
   </div>
   