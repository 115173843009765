import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ChangeDetectorRef, Component, NgZone, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { NgToastModule } from 'ng-angular-popup';
import { AssessmentService } from '../../../services/assessment.service';
import { NotificationService } from '../../../services/Notifications.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { Privilege } from '../../../model/Privillage';
import { PrivilegeService } from '../../../services/privilage.service';


interface IRow { }

@Component({
  selector: 'app-recall-nutrition-form',
  standalone: true,
  imports: [CommonModule, RouterOutlet, RouterLink, RouterModule, HttpClientModule, FormsModule, NgToastModule],
  templateUrl: './recall-nutrition-form.component.html',
  styleUrl: './recall-nutrition-form.component.css'
})
export class RecallNutritionFormComponent {
  isInputVisible: boolean = false; // Variable to track the visibility of the input field
  ImageUrl: any;
  showImage: boolean = false;
  Response: any;
  athleteId: any;
  assesserId: any;
  selectedStatus: string = '0';
  selectdata: any;
  id: any;
  playerList: any[] = [];
  sport: IRow[] = [];
  privilege: Privilege;
  Admin: boolean;
  playerName: any;


  categoryId: any;
  mode: any;
  teamId: any;
  formData: any = {
    rcaId: '0',
    rcaAthletId: '',
    rcaAssessmentId: '',
    rcaQ1: '',
    rcaQ2: '',
    rcaQ3: '',
    rcaQ4: '',
    rcaQ5: '',
    rcaQ6: '',
    rcaQ7: '',
    rcaQ8: '',
    rcaQ9: '',
    rcaQ10: '',
    rcaQ11: '',
    rcaQ12: '',
    rcaQ13: '',
    rcaQ14: '',
    rcaQ15: '',
    rcaQ16: '',
    rcaQ17: '',
    rcaQ18: '',
    rcaQ19: '',
    rcaQ20: '',
    rcaQ21: '',
    rcaQ22: '',
    rcaQ23: '',
    rcaQ24: '',
    rcaQ25: '',
    rcaQ26: '',
    rcaQ27: '',
    rcaQ28: '',
    rcaQ29: '',
    rcaQ30: '',
    rcaQ31: '',
    rcaQ32: '',
    rcaQ33: '',
    rcaQ34: '',
    rcaQ35: '',
    rcaQ36: '',
    rcaQ37: '',
    rcaQ38: '',
    rcaQ39: '',
    rcaQ40: '',
    rcaQ41: '',
    rcaQ42: '',
    rcaQ43: '',
    rcaQ44: '',
    rcaQ45: '',
    rcaQ46: '',
    rcaQ47: '',
    rcaQ48: '',
    rcaQ49: '',
    rcaQ50: '',
    rcaQ51: '',
    rcaQ52: '',
    rcaQ53: '',
    rcaQ54: '',

  }
  image: any;
  Assessor: boolean;
  constructor(
    private assessmentService: AssessmentService,
    private notify: NotificationService,
    private route: ActivatedRoute,
    private location: Location,
    private sanitizer: DomSanitizer, private router: Router,
    private priService: PrivilegeService,
    private cdRef: ChangeDetectorRef, private ngZone: NgZone
  ) {
    this.privilege = this.priService.getPrivileges();
    this.Admin = this.privilege.isAdmin;
    this.Assessor = this.privilege.isAssessor;
    this.route.queryParams.subscribe(params => {
      this.mode = params['mode'];
      this.teamId = params['teamId'];
      this.athleteId = params['athleteId'];
      this.assesserId = params['assessor'];
      this.categoryId = params['categoryId'];
      this.id = params['id'];
      this.formData['rcaAthletId'] = this.athleteId;
      this.formData['rcaAssessmentId'] = this.id;
    });
  }


  ngOnInit() {
    this.getRecallAssessments();
    this.getProfileImage();
    this.getPlayerByAssessmentId();


  }

  onAthleteChange(event: any) {
    const selectedValue = event.target.value;
    const selectedPlayer = this.playerList.find(player => player.PlayerId === selectedValue);
    if (selectedPlayer) {
      const athleteId = selectedPlayer.PlayerId;
      const assessmentId = selectedPlayer.aplAssessment;
      this.athleteId = selectedPlayer.PlayerId;
      this.getProfileImage();
      this.getRecallAssessments();
      this.getPlayerByAssessmentId();
    }
  }

  getPlayerByAssessmentId() {
    this.assessmentService.getPlayerByAssessmentId(this.id).subscribe(response => {
      this.selectdata = response.filter((assessment: any) => assessment.aplCategory == this.categoryId);
      // Create a map to store unique assessments based on PlayerId (or any unique identifier)
      const uniqueAssessments = new Map<number, any>();
      console.log('Assessment submitted', response);

      // Filter the response to only include unique assessments
      this.selectdata.forEach((item: any) => {
        // Only add the item if it does not exist in the map
        if (!uniqueAssessments.has(item.aplPlayer)) {
          uniqueAssessments.set(item.aplPlayer, {
            "Player Name": `${item.usrFullName}`,
            Name: `${item.usrFullName}`,
            PlayerId: `${item.aplPlayer}`,
            teamId: `${item.teamId}`,
            id: `${item.aplAssessment}`,
            "Email": `${item.usrEmail}`,
            PlayerEmail: `${item.usrEmail}`,
            "Phone No": `${item.usrPhoneNo}`,
            PhoneNo: `${item.usrPhoneNo}`,
            "DOB": new Date(item.usrDoB).toLocaleDateString(),
            "status": `${item.aplStatus}`,
          });
        }
      });

      this.sport = Array.from(uniqueAssessments.values());
      this.playerName = this.sport.filter((assessment: any) => assessment.PlayerId === this.athleteId);
      this.playerList = this.sport;


    }, error => {
      console.error('Error submitting assessment', error);
    });
  }



  getProfileImage() {
    this.ImageUrl = null;
    this.showImage = false;

    this.assessmentService.getEmptyImage(this.athleteId).subscribe(
      (response) => {
        if (response) {
          this.showImage = true;
          this.image = "assets/image/profileimg.jpg"
          console.log(this.image, "image")

        }
      },
      (error) => {
        this.assessmentService.getImage(this.athleteId).subscribe(
          (imageBlob) => {
            const objectURL = URL.createObjectURL(imageBlob);
            this.ImageUrl = this.sanitizer.bypassSecurityTrustUrl(objectURL);
            this.image = this.ImageUrl.changingThisBreaksApplicationSecurity
            console.log(this.image, "this.image")
            this.showImage = true;
          }
        );
        console.error('Error fetching image', error);
      }
    );
  }




  getRecallAssessments() {
    this.assessmentService.getRecallAssessments(this.athleteId, this.id).subscribe(response => {
      this.Response = response;
      console.log(this.Response, "Response");
      const data = response[0];  // Assuming response[0] contains the required data
      this.formData.rcaId = data.rcaId ?? null; // Use null coalescing
      this.formData.rcaAthletId = data.rcaAthletId ?? null;
      this.formData.rcaAssessmentId = data.rcaAssessmentId ?? null;
      // Loop for assigning rcaQ1 to rcaQ50
      for (let i = 1; i <= 54; i++) {
        this.formData[`rcaQ${i}`] = data[`rcaQ${i}`];
      }
    });
  }




  onBeverageChange(beverage: string, isChecked: boolean) {
    switch (beverage) {
      case 'Coffee':
        this.formData.rcaQ30 = isChecked ? beverage : null; // Set to 'Coffee' if checked, otherwise null
        break;
      case 'Tea':
        this.formData.rcaQ34 = isChecked ? beverage : null; // Set to 'Tea' if checked, otherwise null
        break;
      case 'Sports Drinks':
        this.formData.rcaQ38 = isChecked ? beverage : null; // Set to 'Sports Drinks' if checked, otherwise null
        break;
      case 'Soft Drinks':
        this.formData.rcaQ39 = isChecked ? beverage : null; // Set to 'Sports Drinks' if checked, otherwise null
        break;
      case 'Juice':
        this.formData.rcaQ42 = isChecked ? beverage : null; // Set to 'Juice' if checked, otherwise null
        break;
      case 'Alcohol':
        this.formData.rcaQ43 = isChecked ? beverage : null; // Set to 'Alcohol' if checked, otherwise null
        break;
      case 'Milk':
        this.formData.rcaQ44 = isChecked ? beverage : null; // Set to 'Milk' if checked, otherwise null
        break;
      case 'withmilk':
        this.formData.rcaQ31 = isChecked ? beverage : null; // Set to 'Milk' if checked, otherwise null
        break;
      case 'Black':
        this.formData.rcaQ32 = isChecked ? beverage : null; // Set to 'Milk' if checked, otherwise null
        break;

      case 'withSugar':
        this.formData.rcaQ33 = isChecked ? beverage : null; // Set to 'Milk' if checked, otherwise null
        break;



      case 'withmilks':
        this.formData.rcaQ35 = isChecked ? beverage : null; // Set to 'Milk' if checked, otherwise null
        break;
      case 'Blacks':
        this.formData.rcaQ36 = isChecked ? beverage : null; // Set to 'Milk' if checked, otherwise null
        break;

      case 'withSugars':
        this.formData.rcaQ37 = isChecked ? beverage : null; // Set to 'Milk' if checked, otherwise null
        break;
      case 'Whole':
        this.formData.rcaQ45 = isChecked ? beverage : null; // Set to 'Milk' if checked, otherwise null
        break;
      case 'Skimmed':
        this.formData.rcaQ46 = isChecked ? beverage : null; // Set to 'Milk' if checked, otherwise null
        break;
      case 'Almond':
        this.formData.rcaQ47 = isChecked ? beverage : null; // Set to 'Milk' if checked, otherwise null
        break;

      case 'etc':
        this.formData.rcaQ48 = isChecked ? beverage : null; // Set to 'Milk' if checked, otherwise null
        break;
      case 'Diet':
        this.formData.rcaQ41 = isChecked ? beverage : null; // Set to 'Milk' if checked, otherwise null
        break;
      case 'Regular':
        this.formData.rcaQ40 = isChecked ? beverage : null; // Set to 'Milk' if checked, otherwise null
        break;
      default:
        break;
    }
  }

  validateNumber(event: KeyboardEvent) {
    const charCode = event.charCode;
    // Allow only numbers (0-9)
    if (charCode < 48 || charCode > 57) {
        event.preventDefault();
    }
  }

validateAlphabet(event: KeyboardEvent) {
  const charCode = event.charCode;
  // Allow only letters (A-Z, a-z) and spaces (charCode 32)
  if (
      (charCode < 65 || charCode > 90) && // A-Z
      (charCode < 97 || charCode > 122) && // a-z
      charCode !== 32 // space
  ) {
      event.preventDefault();
  }
}



  isSectionComplete(section: number): boolean {
    switch (section) {
      case 1:
        // Check if all rcaQ1 to rcaQ54 are answered (non-empty)
        for (let i = 1; i <= 1; i++) {
          if (!this.formData[`rcaQ${i}`]) {
            return false; // Return false if any question is unanswered
          }
        }
        return true; // Return true if all questions are answered
      default:
        return false;
    }
  }


  onSubmit() {
    const optionalFields = ['rcaQ8','rcaQ9','rcaQ10','rcaQ11','rcaQ12','rcaQ16','rcaQ17','rcaQ18','rcaQ19','rcaQ20','rcaQ24','rcaQ25','rcaQ26',
                            'rcaQ27','rcaQ28','rcaQ30','rcaQ31','rcaQ32','rcaQ33','rcaQ34','rcaQ35','rcaQ36','rcaQ37',
                            'rcaQ38','rcaQ39','rcaQ40','rcaQ41','rcaQ42','rcaQ43','rcaQ44','rcaQ45','rcaQ46',
                            'rcaQ47','rcaQ48'];
    let missingRequiredFields = false;

    for (let key in this.formData) {
        if (this.formData.hasOwnProperty(key) && !optionalFields.includes(key)) {
            console.log(`Checking field: ${key}, Value: ${this.formData[key]}`);

            // Check if rcaQ4 should be required based on rcaQ3's value
            if (key === 'rcaQ4' && this.formData.rcaQ3 !== 'yes') {
              continue; // Skip rcaQ4 if rcaQ3 is not "yes"
            }

            if (key === 'rcaQ50' && this.formData.rcaQ49 !== 'yes') {
              continue; // Skip rcaQ4 if rcaQ3 is not "yes"
            }

            if (key === 'rcaQ54' && this.formData.rcaQ53 !== 'yes') {
              continue; // Skip rcaQ4 if rcaQ3 is not "yes"
            }

            // Check if the key is a required field and not one of the IDs
            if (key !== 'rcaId' && key !== 'rcaAthletId' && key !== 'rcaAssessmentId') {
                // Check if the value is empty or not
                if (this.formData[key] === undefined || this.formData[key] === null || this.formData[key].trim() === '') {
                    console.log(`Field ${key} is required but is unfilled.`);
                    missingRequiredFields = true; // Mark as missing
                }
            }
        }
    }

    // If any required fields are missing, show the notification
    if (missingRequiredFields) {
        this.ngZone.run(() => {
            this.notify.failed('Please fill in all required fields marked with *');
            this.cdRef.detectChanges();
        });
        return; // Stop submission
    }

    // Store form data, including any unanswered questions with empty values
    this.assessmentService.saveRecallAssessments([this.formData]).subscribe((response: any) => {
        this.Response = response;
        this.assessmentService.updateAssessmentStatus(this.athleteId, this.id, this.categoryId, this.assesserId).subscribe(
            response => {}
        );
        this.notify.success('Profile Data Saved successfully');
        this.onBack();
    });
  }

  isReadOnly(): boolean {
    return this.mode === 'view';
  }

  onBack(): void {
    this.location.back();
  }
}
