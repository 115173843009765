<div class="multi-select-wrapper">
  <div class="multi-select-dropdown">
    <label class="subtitle">{{ label }}</label><br>
    <button class="multi-select-btn btn w-100" (click)="toggleDropdown()">
      <span class="btn-text">Select</span>
      <span class="btn-icon">&#9662;</span>
    </button>
    <ul class="multi-select-list form-control" *ngIf="dropdownOpen">
      <li *ngFor="let option of options; trackBy: trackById">
        <input
          type="checkbox"
          [id]="option.id"
          [(ngModel)]="option.selected"
          (change)="onOptionChange()"
          [attr.aria-checked]="option.selected"
        />
        <label [for]="option.id">{{ option.label }}</label>
      </li>
    </ul>
  </div>
  <div class="text-boxes-container">
    <div *ngFor="let selected of selectedOptions; trackBy: trackById" class="text-box">
      <label [for]="selected.id">{{ selected.label }}</label>
      <input class="form-control w-100"
        type="text"
        [id]="selected.id" [name]="selected.label"
        [(ngModel)]="selected.value"
        placeholder="Enter value for {{ selected.label }}"
      />
    </div>
  </div>
</div>
