<div class="body-wrapper">
  <div class="row flex-grow">
    <div class="col-12 grid-margin stretch-card">
      <div class="card card-rounded">
        <div class="card-body">
          <div class="d-sm-flex justify-content-between align-items-start mb-4">
            <div>
              <h4 class="card-title card-title-dash">Edit Team</h4>
            </div>
          </div>

          <form (ngSubmit)="onSubmit()" #assessmentForm="ngForm">
            <div class="col-12 row d-flex">
              <div class="col-md-6">
                        <label>Team Name</label>
                        <input type="text" class="form-control mt-3" [(ngModel)]="teamName" name="teamName"
                        placeholder="Enter Team Name" required/>  
                      </div>
                      <div class="col-md-6">
                        <label>Sport Name</label>
                        <input type="text" value="{{sportName[0].sportName}}"  [disabled]="true" class="form-control mt-3"  readonly />
                      </div>
                      </div>
                        <br>
             
                        <button class="btn btn-primary" [routerLink]="['/team']" [queryParams]="{ orgId: orgId }">
                          <span class="btnText">Back</span>
                        </button> &nbsp;
                        <button type="submit" class="submit btn btn-primary">
                          <span class="btnText">Submit</span>
                        </button>
                      </form>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="toaster">

  <ng-toast />

</div>