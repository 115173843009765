<!-- app-video-upload.component.html -->
<div class="container" [ngClass]="{'secActive': formSubmitted}">
  <header>Video Analysis</header><br>
  <form (ngSubmit)="onSubmit()" #videoForm="ngForm">
    <div class="form first">
      <div class="details personal">
        <div class="fields">
          <!-- <div class="input-field">
            <label>Uploader's Name</label>
            <input type="text" placeholder="Enter Uploader's Name" required [(ngModel)]="assessment.vuUploader"
              name="uploaderName" #uploaderName="ngModel" [ngClass]="{
                  'invalid-field': videoForm.submitted && uploaderName.invalid,
                  'has-value': assessment.vuUploader
                }">
            <div *ngIf="videoForm.submitted && uploaderName.invalid" class="error-message">
             
              Uploader's Name is required.
            </div>
          </div> -->
          <div class="input-field">
            <label>Date of Upload</label>
            <input type="date" placeholder="Enter Date of Upload" required [(ngModel)]="assessment.vuCreatedOn"
              name="dateOfUpload" #dateOfUpload="ngModel" [ngClass]="{
                  'invalid-field': videoForm.submitted && dateOfUpload.invalid,
                  'has-value': assessment.vuCreatedOn
                }">
            <div *ngIf="videoForm.submitted && dateOfUpload.invalid" class="error-message">
              <!-- Date of Upload error message -->
              Date of Upload is required.
            </div>
          </div>
          <div class="input-field">
            <label>Video Title</label>
            <input type="text" placeholder="Enter Video Title" required [(ngModel)]="assessment.vuTitle"
              name="videoTitle" #videoTitle="ngModel" [ngClass]="{
                  'invalid-field': videoForm.submitted && videoTitle.invalid,
                  'has-value': assessment.vuTitle
                }">
            <div *ngIf="videoForm.submitted && videoTitle.invalid" class="error-message">
              <!-- Video Title error message -->
              Video Title is required.
            </div>
          </div>
          <div class="input-field">
            <label>Date of Recording</label>
            <input type="date" placeholder="Enter Date of Recording" required [(ngModel)]="assessment.vuDate"
              name="dateOfRecording" #dateOfRecording="ngModel" [ngClass]="{
                  'invalid-field': videoForm.submitted && dateOfRecording.invalid,
                  'has-value': assessment.vuDate
                }">
            <div *ngIf="videoForm.submitted && dateOfRecording.invalid" class="error-message">
              <!-- Date of Recording error message -->
              Date of Recording is required.
            </div>
          </div>
          <div class="input-field">
            <label>Sport/Activity</label>
            <input type="text" placeholder="Enter Sport/Activity" required [(ngModel)]="assessment.vuSportActivity"
              name="sportActivity" #sportActivity="ngModel" [ngClass]="{
                  'invalid-field': videoForm.submitted && sportActivity.invalid,
                  'has-value': assessment.vuSportActivity
                }">
            <div *ngIf="videoForm.submitted && sportActivity.invalid" class="error-message">
              <!-- Sport/Activity error message -->
              Sport/Activity is required.
            </div>
          </div>
        </div>
        <div class="details">
          <span class="title">Key Focus Points</span>
          <div class="fields">
            <div class="input-field">
              <label>Technique Analysis</label>
              <input type="text" placeholder="Enter Technique Analysis" required
                [(ngModel)]="assessment.vuTechniqueAnalysis" name="techniqueAnalysis" #techniqueAnalysis="ngModel"
                [ngClass]="{
                    'invalid-field': videoForm.submitted && techniqueAnalysis.invalid,
                    'has-value': assessment.vuTechniqueAnalysis
                  }">
              <div *ngIf="videoForm.submitted && techniqueAnalysis.invalid" class="error-message">
                <!-- Technique Analysis error message -->
                Technique Analysis is required.
              </div>
            </div>
            <div class="input-field">
              <label>Performance Analysis</label>
              <input type="text" placeholder="Enter Performance Analysis" required
                [(ngModel)]="assessment.vuPerformanceAnalysis" name="performanceAnalysis" #performanceAnalysis="ngModel"
                [ngClass]="{
                    'invalid-field': videoForm.submitted && performanceAnalysis.invalid,
                    'has-value': assessment.vuPerformanceAnalysis
                  }">
              <div *ngIf="videoForm.submitted && performanceAnalysis.invalid" class="error-message">
                <!-- Performance Analysis error message -->
                Performance Analysis is required.
              </div>
            </div>             
        <div class="buttons">
        <button type="submit" class="submit">
          <span class="btnText">Submit</span>
          <i class="uil uil-navigator"></i>
        </button>
      </div>
          </div>
        </div>
      </div>

      
    
      <div class="input-field">
        <label>Choose Video File</label>
        <input type="file" required (change)="onFileSelected($event)" accept="video/mp4" />
      </div>
      
      <div *ngIf="videoUrl">
        <video width="400" controls>
          <source [src]="videoUrl" type="video/mp4">
          Your browser does not support HTML video.
        </video>
      </div>

    </div>
  </form>
</div>
<div class="toaster">
  <ng-toast></ng-toast>
</div>
