<div class="body-wrapper">  

    <div class="row flex-grow">
      <div class="col-12 grid-margin stretch-card">
        <div class="card card-rounded">
          <div class="card-body">
            <button class="btn btn-primary mb-2" (click)="onBack()">Back</button>
            <div class="d-sm-flex justify-content-between align-items-start">
              <div>
                <h4 class="card-title card-title-dash">{{teamName}} Players</h4>
                <p class="card-subtitle card-subtitle-dash">Players Details</p>
                
              </div>
              
            </div>

   
            <div class="content" style="width: 100%; height: 100%;">
                <!-- The AG Grid component, with Dimensions, CSS Theme, Row Data, and Column Definition -->
                <ag-grid-angular
                class="ag-theme-quartz"
                style="height: 500px;"
                [rowData]="rowData"
                [columnDefs]="colDefs"
                [defaultColDef]="defaultColDef"
                [pagination]="true"
              />
              </div>
   
   
   
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Add this modal popup inside the component template -->
  <div class="modal fade" id="playerDetailModal" tabindex="-1" aria-labelledby="playerDetailModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="playerDetailModalLabel">Player Details</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body m-3">
          <img [src]="selectedImageUrl" alt="Image Description">
          <p><strong>Player ID:</strong> {{ selectedPlayer?.PlayerId }}</p>
          <p><strong>Email:</strong> {{ selectedPlayer?.Email }}</p>
          <p><strong>Coach Name:</strong> {{ selectedPlayer?.CoachName }}</p>
          <p><strong>Address:</strong> {{ selectedPlayer?.Address }}</p>
          <p><strong>Phone No:</strong> {{ selectedPlayer?.PhoneNo }}</p>
          <p><strong>Previous Injuries:</strong> {{ selectedPlayer?.PreviousInjuries }}</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
