import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, Observable, of, throwError, retryWhen, delay, concatMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  private apiUrl = 'https://api.openai.com/v1/chat/completions';
  private apiKey = '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';

  // Store conversation history
  private conversationHistory: { role: string, content: string }[] = [];

  constructor(private http: HttpClient) { }

  // Send only a text message with retry for rate limit handling
  getChatResponse(message: string): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.apiKey}`,
      'Content-Type': 'application/json'
    });
 
    // Define the conversation with just the system and user message
    const body = {
      model: 'gpt-4o-mini', // Or the model you are using
      messages: [
        {
          role: 'system',
          content: 'You are an analyst. Provide only results relevant to my data. If you cannot respond to a question, please inform me that you are unable to do so.'
        },
        {
          role: 'user',
          content: message
        }
      ] // Include only the current message
    };
 
    return this.http.post<any>(this.apiUrl, body, { headers }).pipe(
      catchError(error => {
        console.error('Error occurred:', error);
        return of({ error: 'Failed to get response' });
      })
    );
  }

  // Send a message and a file together with retry logic and conversation history
  sendMessageWithFile(message: string, file: File): Observable<any> {
    return new Observable(observer => {
      const reader = new FileReader();
  
      reader.onload = () => {
        try {
          const fileContent = JSON.parse(reader.result as string);
  
          // Add the file content as a message to the conversation history
          this.conversationHistory.push({ role: 'user', content: message });
          this.conversationHistory.push({ role: 'user', content: JSON.stringify(fileContent) });
  
          // Send the combined content to the API, including the model 'gpt-4'
          const requestBody = {
            model: 'gpt-4o-mini',
            messages: this.conversationHistory
          };
  
          this.sendToChatApi(requestBody).subscribe(response => {
            observer.next(response);
            observer.complete();
          });
        } catch (error) {
          observer.error('Invalid JSON file or file content format');
        }
      };
  
      reader.onerror = () => {
        observer.error('Failed to read the file');
      };
  
      reader.readAsText(file);
    });
  }
  
  // Helper method to send data to the API with retry logic
  private sendToChatApi(body: any): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.apiKey}`,
      'Content-Type': 'application/json'
    });
  
    return this.http.post<any>(this.apiUrl, body, { headers }).pipe(
      retryWhen(errors =>
        errors.pipe(
          concatMap((error, index) => {
            if (error.status === 429 && index < 1) {
              return of(error).pipe(delay(6000));
            }
            return throwError(error);
          })
        )
      ),
      catchError(error => {
        console.error('Error occurred:', error);
        return of({ error: 'Failed to get response' });
      })
    );
  }
  
}
