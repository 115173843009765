import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink, RouterOutlet } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { NgToastModule } from 'ng-angular-popup';
import { NotificationService } from '../../../services/Notifications.service';
import { SharedService } from '../../../services/shared.service';
import { AssessmentService } from '../../../services/assessment.service';
import { MultiSelectDropdownComponent } from "../../multi-select-dropdown/multi-select-dropdown.component";
interface Option {
  id: string;
  label: string;
  selected: boolean;
  value?: string; 
}

@Component({
  selector: 'app-physiotherapy-genericform',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterOutlet, RouterLink, HttpClientModule, NgToastModule, MultiSelectDropdownComponent],
  templateUrl: './physiotherapy-genericform.component.html',
  styleUrl: './physiotherapy-genericform.component.css'
})
export class PhysiotherapyGenericformComponent {
  constructor(private router: Router ,private route: ActivatedRoute,private sharedService: SharedService,private assessmentService: AssessmentService,private notify:NotificationService) {}
  ROMShoulderHip: Option[] = [
    { id: 'Flexion', label: 'Flexion', selected: false },
    { id: 'extension', label: 'extension', selected: false },
    { id: 'abduction', label: 'abduction', selected: false },
    { id: 'adduction', label: 'adduction', selected: false },
    { id: 'internal rotat', label: 'internal rotat', selected: false },
    { id: 'external rotat', label: 'external rotat', selected: false },
  ];
  MMTShoulder: Option[] = [
    { id: 'Flexion', label: 'Flexion', selected: false },
    { id: 'abduction', label: 'abduction', selected: false },
    { id: 'internal and external rotation', label: 'internal and external rotation', selected: false }
  ];
  STshoulder: Option[] = [
    { id: 'Hawkins-Kennedy Test', label: 'Hawkins-Kennedy Test', selected: false },
    { id: 'Neer’s Test', label: 'Neer’s Test', selected: false },
    { id: 'Empty Can Test', label: 'Empty Can Test', selected: false },
    { id: 'Apprehension Test', label: 'Apprehension Test', selected: false },
  ];
  ROMElbow: Option[] = [
    { id: 'Flexion', label: 'Flexion', selected: false },
    { id: 'extension', label: 'extension', selected: false },
    { id: 'supination', label: 'supination', selected: false },
    { id: 'pronation', label: 'pronation', selected: false }
  ];
  MMTElbowWrist: Option[] = [
    { id: 'Flexion', label: 'Flexion', selected: false },
    { id: 'Extension', label: 'Extension', selected: false }
  ];
  STElbow: Option[] = [
    { id: 'Cozen’s Test', label: 'Cozen’s Test', selected: false },
    { id: 'Golfer’s Elbow Test', label: 'Golfer’s Elbow Test', selected: false },
    { id: 'Tinel’s Sign', label: 'Tinel’s Sign', selected: false }
  ];
  ROMWrist: Option[] = [
    { id: 'Flexion', label: 'Flexion', selected: false },
    { id: 'extension', label: 'extension', selected: false },
    { id: 'radial and ulnar deviati', label: 'radial and ulnar deviati', selected: false }
  ];
  STWrist: Option[] = [
    { id: 'Phalen’s Test', label: 'Phalen’s Test', selected: false },
    { id: 'Finkelstein Test', label: 'Finkelstein Test', selected: false },
    { id: 'Tinel’s Sign', label: 'Tinel’s Sign', selected: false }
  ];
  MMTHip: Option[] = [
    { id: 'Flexion', label: 'Flexion', selected: false },
    { id: 'Extension', label: 'Extension', selected: false },
    { id: 'Adduction', label: 'Adduction', selected: false },
    { id: 'Abduction', label: 'Abduction', selected: false }
  ];
  STHip: Option[] = [
    { id: 'FABER Test', label: 'FABER Test', selected: false },
    { id: 'Thomas Test', label: 'Thomas Test', selected: false },
    { id: 'Trendelenburg Test', label: 'Trendelenburg Test', selected: false }
  ];
  ROMMMTKnee: Option[] = [
    { id: 'Flexion', label: 'Flexion', selected: false },
    { id: 'Extension', label: 'Extension', selected: false }
  ];
  STKnee: Option[] = [
    { id: 'Lachman’s Test', label: 'Lachman’s Test', selected: false },
    { id: 'McMurray’s Test', label: 'McMurray’s Test', selected: false },
    { id: 'Patellar Apprehension Test', label: 'Patellar Apprehension Test', selected: false },
    { id: 'Apley’s Compression Test', label: 'Apley’s Compression Test', selected: false },
    { id: 'Drawer Test', label: 'Drawer Test', selected: false },
    { id: 'Varus/Valgus Stress Test', label: 'Varus/Valgus Stress Test', selected: false }
  ];
  ROMAnkle: Option[] = [
    { id: 'Dorsiflexion', label: 'Dorsiflexion', selected: false },
    { id: 'plantarflexion', label: 'plantarflexion', selected: false },
    { id: 'inversion', label: 'inversion', selected: false },
    { id: 'eversion', label: 'eversion', selected: false }
  ];
  MMTAnkle: Option[] = [
    { id: 'Dorsiflexion', label: 'Dorsiflexion', selected: false },
    { id: 'eversion', label: 'eversion', selected: false }
  ];
  STAnkle: Option[] = [
    { id: 'Anterior Drawer Test', label: 'Anterior Drawer Test', selected: false },
    { id: 'Talar Tilt Test', label: 'Talar Tilt Test', selected: false },
    { id: 'Thompson Test', label: 'Thompson Test', selected: false }
  ];
  ROMSpine: Option[] = [
    { id: 'Flexion', label: 'Flexion', selected: false },
    { id: 'Extension', label: 'Extension', selected: false },
    { id: 'lateral flexion', label: 'lateral flexion', selected: false },
    { id: 'rotation', label: 'rotation', selected: false }
  ];
  ROMSpine1: Option[] = [
    { id: 'Flexion', label: 'Flexion', selected: false },
    { id: 'Extension', label: 'Extension', selected: false },
    { id: 'lateral flexion', label: 'lateral flexion', selected: false },
    { id: 'rotation', label: 'rotation', selected: false }
  ];
  ROMSpine2: Option[] = [
    { id: 'Flexion', label: 'Flexion', selected: false },
    { id: 'Extension', label: 'Extension', selected: false },
    { id: 'lateral flexion', label: 'lateral flexion', selected: false },
    { id: 'rotation', label: 'rotation', selected: false }
  ];
  MMTSpine: Option[] = [
    { id: 'Flexion', label: 'Flexion', selected: false },
    { id: 'Extension', label: 'Extension', selected: false }
  ];
  STSpine: Option[] = [
    { id: 'Straight Leg Raise (SLR) Test', label: 'Straight Leg Raise (SLR) Test', selected: false },
    { id: 'Schober’s Test', label: 'Schober’s Test', selected: false },
    { id: 'Spurling’s Test', label: 'Spurling’s Test', selected: false }
  ];
  postureSelection: string = '';
  gaitSelection: string = '';
  swellingSelection: string = '';
  MuscleWastingSelection: string = '';
  temperatureSelection:string='';
  tendernessSelection:string='';
  swellingPLSelection: string = '';
  BonySelection: string = '';
  postureValue: string = '';
  gaitValue: string = '';
  swellingValue: string = '';
  selectedJoints: string[] = [];
  dropdownOpenJoint=false;
  dropdownOpen = false;
  occupation: string = '';
  showSportsHistory: boolean = false;
   showPostureTextbox: boolean = false;
   showGaitTextbox: boolean = false;
   showSwellingTextbox: boolean = false;
   showMuscleWastingTextbox: boolean = false;
   showTendernessTextbox: boolean = false;
   showSwellingPLTextbox: boolean = false;
   showBonyTextbox: boolean = false;
   dropdownOpenSTshoulder: boolean=false;
  grades: number[] = [1, 2, 3, 4, 5];
  restrictions: string[] = ["Special","Negative"];
  jointTabs: string[] = ['Shoulder', 'Elbow', 'Wrist', 'Hip', 'Knee', 'Ankle', 'Spine'];
  selectedGrade: number | null = null;
  activeStep: number = 1;
  formSteps!: NodeListOf<Element>;
  ngOnInit() {
    this.formSteps = document.querySelectorAll('.form-step');
    this.updateProgress();
   
  }
  checkOccupation() {
    const occupationValue = this.occupation.toLowerCase().trim();
    this.showSportsHistory = occupationValue === 'athletes' || occupationValue === 'athlete';
  }
  onJointChange(joint: string, event: any): void {
    if (event.target.checked) {
      this.selectedJoints.push(joint);
    } else {
      this.selectedJoints = this.selectedJoints.filter(j => j !== joint);
    }
  }

  isSelected(joint: string): boolean {
    return this.selectedJoints.includes(joint);
  }
  toggleJoint(): void {
    this.dropdownOpenJoint = !this.dropdownOpenJoint;
  }
  onDropdownChange(dropdownType: string, event: Event): void {
    const selectedValue = (event.target as HTMLSelectElement).value;

    if (dropdownType === 'posture') {
      this.showPostureTextbox = selectedValue === 'yes';
    } else if (dropdownType === 'gait') {
      this.showGaitTextbox = selectedValue === 'yes';
    } else if (dropdownType === 'swelling') {
      this.showSwellingTextbox = selectedValue === 'yes';
    }else if (dropdownType === 'MuscleWasting') {
      this.showMuscleWastingTextbox = selectedValue === 'yes';
    }else if (dropdownType === 'tenderness') {
      this.showTendernessTextbox = selectedValue === 'yes';
    }else if (dropdownType === 'swellingPL') {
      this.showSwellingPLTextbox = selectedValue === 'yes';
    }else if (dropdownType === 'Bony') {
      this.showBonyTextbox = selectedValue === 'yes';
    }
  }
  nextStep(): void {
    this.activeStep++;
    if (this.activeStep > this.formSteps.length) {
      this.activeStep = this.formSteps.length;
    }
    this.updateProgress();
  // }
  }
  prevStep(): void {
    this.activeStep--;
    if (this.activeStep < 1) {
      this.activeStep = 1;
    }
    this.updateProgress();
  }
 
  updateProgress(): void {
    this.formSteps.forEach((step, i) => {
      if (i === (this.activeStep - 1)) {
        step.classList.add('active');
        this.formSteps[i].classList.add('active');
      } else {
        step.classList.remove('active');
        this.formSteps[i].classList.remove('active');
      }
    });
 
    const prevButton = document.querySelector('.btn-prev') as HTMLButtonElement;
    const nextButton = document.querySelector('.btn-next') as HTMLButtonElement;
    const submitButton = document.querySelector('.btn-submit') as HTMLButtonElement;
 
    if (this.activeStep === 1) {
      prevButton.disabled = true;
      submitButton.hidden=true;
    } else if (this.activeStep === this.formSteps.length) {
      nextButton.disabled = true;
      submitButton.hidden=false;
    } else {
      prevButton.disabled = false;
      nextButton.disabled = false;
      submitButton.hidden=true;
    }
  }
  // onSubmit(form: NgForm): void {
  //   if (form.valid) {
  //     const formData = {
  //       // Include the necessary form data here
  //       postureSelection: this.postureSelection,
  //       gaitSelection: this.gaitSelection,
  //       swellingSelection: this.swellingSelection,
  //       MuscleWastingSelection: this.MuscleWastingSelection,
  //       temperatureSelection: this.temperatureSelection,
  //       tendernessSelection: this.tendernessSelection,
  //       swellingPLSelection: this.swellingPLSelection,
  //       BonySelection: this.BonySelection,
  //       postureValue: this.postureValue,
  //       gaitValue: this.gaitValue,
  //       swellingValue: this.swellingValue,
  //       selectedJoints: this.selectedJoints,
  //       occupation: this.occupation,
  //       showSportsHistory: this.showSportsHistory
  //     };
      
  //     this.assessmentService.addTrainer(formData).subscribe(
  //       response => {
  //         this.notify.success('Form submitted successfully');
  //         this.router.navigate(['/success-page']); // Navigate to success page after submission
  //       },
  //       error => {
  //         this.notify.failed('Form submission failed');
  //       }
  //     );
  //   } else {
  //     this.notify.failed('Please fill in all required fields');
  //   }
  // }
}
