import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AssessmentService } from '../../../services/assessment.service';
import { CommonModule } from '@angular/common';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Location } from '@angular/common';
import { Privilege } from '../../../model/Privillage';
import { SharedService } from '../../../services/shared.service';
import { PrivilegeService } from '../../../services/privilage.service';


@Component({
  selector: 'app-nutrition-detail',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './nutrition-detail.component.html',
  styleUrl: './nutrition-detail.component.css'
})
export class NutritionDetailComponent implements OnInit {
  id: any;
  athleteReport: any;
  athleteId: any;
  privilege: Privilege;
  IsAdmin: boolean = false;
  role: any;
  detail: any;
  userId: any;
  selectdata: any;
  athleteFullName: any;
  date: any;
  assessmentDate: any;
  naBodyWeight: any;
  naBodyHeight: any;
  naBMI: any;
  naBodyFat: any;
  naFatFreeWeight: any;
  naSubcutaneousFat: any;
  naVisceralFat: any;
  naBodyWater: any;
  naSkeletalMuscle: any;
  naLeanMass: any;
  naBoneMass: any;
  naProtein: any;
  naBMR: any;
  naMetabolicAge: any;
  naHealthScore: any;
 
  constructor(private router: Router,private location: Location, private route: ActivatedRoute,private assessmentService: AssessmentService,
    private sharedService: SharedService,private priService:PrivilegeService
  ) {
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
      this.athleteId = params['athleteId']; // Ensure athleteId is in query parameters
      console.log(this.id, this.athleteId, "ID and AthleteId");
    });
    this.privilege = this.priService.getPrivileges();
    this.IsAdmin = this.privilege.isAdmin;
  }

  activities = [
    { name: 'Sports Activity', percentage: 82 },
    { name: 'Technique Analysis', percentage: 42 },
    { name: 'Performance Analysis', percentage: 100 }
  ];
 
  // Dynamically return the color class based on percentage
  getProgressColor(percentage: number): string {
    if (percentage >= 75) {
      return 'bg-green';
    } else if (percentage >= 40) {
      return 'bg-yellow';
    } else {
      return 'bg-red';
    }
  }
 
  ngOnInit(): void {
    this.sharedService.detail$.subscribe((data: any) => {
      this.detail = data;
      this.role = this.detail.user.usrRole;
      this.userId = this.detail.user.usrId;
    });

    const getAthleteReport = this.IsAdmin
      ? this.assessmentService.getNutritionalByTest(this.id)
      : this.assessmentService.getNutritionalByTestAssessor(this.id);

    getAthleteReport.subscribe((response: any) => {
      this.athleteReport = response;
      this.selectdata = response.filter((assessment: any) =>
        this.IsAdmin ? assessment.athlete == this.athleteId : assessment.athlete == this.athleteId && assessment.assessorId == this.userId
      );

      this.selectdata.forEach((assessment: any) => {
        switch (assessment.testColumnName) {
          case "naBodyWeight":
            this.naBodyWeight = assessment.matchedColumnData;
            break;
          case "naBodyHeight":
            this.naBodyHeight = assessment.matchedColumnData;
            break;
          case "naBMI":
            this.naBMI = assessment.matchedColumnData;
            break;
          case "naBodyFat":
            this.naBodyFat = assessment.matchedColumnData;
            break;
          case "naFatFreeWeight":
            this.naFatFreeWeight = assessment.matchedColumnData;
            break;
          case "naSubcutaneousFat":
            this.naSubcutaneousFat = assessment.matchedColumnData;
            break;
          case "naVisceralFat":
            this.naVisceralFat = assessment.matchedColumnData;
            break;
          case "naBodyWater":
            this.naBodyWater = assessment.matchedColumnData;
            break;
          case "naSkeletalMuscle":
            this.naSkeletalMuscle = assessment.matchedColumnData;
            break;
          case "naLeanMass":
            this.naLeanMass = assessment.matchedColumnData;
            break;
          case "naBoneMass":
            this.naBoneMass = assessment.matchedColumnData;
            break;
          case "naProtein":
            this.naProtein = assessment.matchedColumnData;
            break;
          case "naBMR":
            this.naBMR = assessment.matchedColumnData;
            break;
          case "naMetabolicAge":
            this.naMetabolicAge = assessment.matchedColumnData;
            break;
          case "naHealthScore":
            this.naHealthScore = assessment.matchedColumnData;
            break;
        }
      });

      // Assign common athlete information
      if (this.selectdata.length > 0) {
        const firstData = this.selectdata[0];
        this.athleteFullName = firstData.athleteFullName;
        this.date = firstData.date;
        this.assessmentDate = firstData.assessmentDate;
        this.athleteId = firstData.athlete;
      }
    });
  }

  downloadPDF(): void {
    const DATA = document.getElementById('pdf-content');
    if (DATA) {
      html2canvas(DATA).then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgProps = pdf.getImageProperties(imgData);
        const imgWidth = pdfWidth * 0.8; // 80% of the page width
        const imgHeight = (imgProps.height * imgWidth) / imgProps.width;
        const margin = (pdfWidth - imgWidth) / 2;
        const topMargin = 20; // Add top margin
 
        pdf.addImage(imgData, 'PNG', margin, topMargin, imgWidth, imgHeight);
        pdf.save('BodyComposition-details.pdf');
      });
    }
  }

  onBack():void{
    this.location.back();
  }
}